import { kycConstants } from '../_constants';
import { kycService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
export const kycActions = {
    login,
    logout,
    updatePassword,
    updateStatusKyc,
    updateStatusKyctwo,
    addKyc,
    uploadImage,
    disableKyc,
    deleteKyc,
    updateKyc,
    getAllKyc,
    getKycList,
    getTutorEducationById
};
function login(data) {
    return dispatch => {
        dispatch(request({ data }));
        kycService.login(data)
            .then(
                kyc => {
                    dispatch(success(kyc));
                    history.push({ pathname: '#/app/dashboard' });
                    window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(kyc) { return { type: kycConstants.LOGIN_REQUEST, kyc } }
    function success(kyc) { return { type: kycConstants.LOGIN_SUCCESS, kyc } }
    function failure(error) { return { type: kycConstants.LOGIN_FAILURE, error } }
}
function logout() {
    kycService.logout();
    return { type: kycConstants.LOGOUT };
}
function getKycList(data) {
    return dispatch => {
        dispatch(request());
        kycService.getKycList(data)
            .then(
                keyfeatures => dispatch(success(keyfeatures)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: kycConstants.GETALL_REQUEST } }
    function success(keyfeatures) { return { type: kycConstants.GETALL_SUCCESS, keyfeatures: keyfeatures } }
    function failure(error) { return { type: kycConstants.GETALL_FAILURE, error } }
}
function getTutorEducationById(data) {
    return dispatch => {
        dispatch(request());
        kycService.getTutorEducationById(data)
            .then(
                keyfeatures => dispatch(success(keyfeatures)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: kycConstants.GET_TUTTOR_EDUCATION_BY_ID_REQUEST } }
    function success(keyfeatures) { return { type: kycConstants.GET_TUTTOR_EDUCATION_BY_ID_SUCCESS, keyfeatures: keyfeatures } }
    function failure(error) { return { type: kycConstants.GET_TUTTOR_EDUCATION_BY_ID_FAILURE, error } }
}
function updateStatusKyctwo(data, reqData) {
    return dispatch => {
        dispatch(request());
        kycService.updateStatusKyc(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Kyc Updated successfully."));
                    dispatch(success(users));
                    dispatch(kycActions.getKycList(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: kycConstants.UPDATE_KYC_PASSWORD_REQUEST } }
    function success(users) { return { type: kycConstants.UPDATE_KYC_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: kycConstants.UPDATE_KYC_PASSWORD_FAILURE, error } }
}
function updateStatusKyc(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
    }
    console.log("datadatadatadatadatadatadata123", reqData);
    let mainData = {
        comment: data.keyWord,
        status: data.status,
        id: data.id,

    }
    return dispatch => {
        dispatch(request());
        kycService.updateStatusKyc(mainData)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    // dispatch(alertActions.success("Password Updated successfully."));
                    dispatch(success(users));
                    dispatch(kycActions.getKycList(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: kycConstants.UPDATE_KYC_PASSWORD_REQUEST } }
    function success(users) { return { type: kycConstants.UPDATE_KYC_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: kycConstants.UPDATE_KYC_PASSWORD_FAILURE, error } }
}

function updatePassword(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "roll_id": 0,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        kycService.updatePassword(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Password Updated successfully."));
                    dispatch(success(users));
                    dispatch(kycActions.getKycList(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: kycConstants.UPDATE_KYC_PASSWORD_REQUEST } }
    function success(users) { return { type: kycConstants.UPDATE_KYC_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: kycConstants.UPDATE_KYC_PASSWORD_FAILURE, error } }
}
function getAllKyc(data) {
    return dispatch => {
        dispatch(request());
        kycService.getAllKyc(data)
            .then(
                keyfeatures => {
                    //console.log("keyfeatures  ",keyfeatures);
                    let returnResppnse =
                        keyfeatures.getAllKyc ? keyfeatures.getAllKyc.map(kyc => ({ value: kyc.id, label: kyc.firstName + " " + kyc.lastName })) : [];
                    let newRespnse = { ...keyfeatures, getAllKyc: returnResppnse }
                    dispatch(success(newRespnse))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: kycConstants.GET_ALL_KYC_REQUEST } }
    function success(keyfeatures) { return { type: kycConstants.GET_ALL_KYC_SUCCESS, keyfeatures } }
    function failure(error) { return { type: kycConstants.GET_ALL_KYC_FAILURE, error } }
}
function addKyc(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        // "roll_id": 0,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        kycService.addKyc(data)
            .then(
                keyfeatures => {
                    dispatch(success(keyfeatures));
                    dispatch(this.getKycList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: kycConstants.ADD_RESTAURANT_KYC_REQUEST } }
    function success(keyfeatures) { return { type: kycConstants.ADD_RESTAURANT_KYC_SUCCESS, keyfeatures } }
    function failure(error) { return { type: kycConstants.ADD_RESTAURANT_KYC_FAILURE, error } }
}
function updateKyc(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        // "roll_id": 0,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        kycService.updateKyc(data)
            .then(
                keyfeatures => {
                    dispatch(success(keyfeatures));
                    dispatch(this.getKycList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: kycConstants.ADD_RESTAURANT_KYC_REQUEST } }
    function success(keyfeatures) { return { type: kycConstants.ADD_RESTAURANT_KYC_SUCCESS, keyfeatures } }
    function failure(error) { return { type: kycConstants.ADD_RESTAURANT_KYC_FAILURE, error } }
}
function disableKyc(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id: data.id
    }
    return dispatch => {
        dispatch(request());
        kycService.disableKyc(maintempdata)
            .then(
                keyfeatures => {
                    dispatch(success(keyfeatures));
                    dispatch(this.getKycList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: kycConstants.ADD_RESTAURANT_KYC_REQUEST } }
    function success(keyfeatures) { return { type: kycConstants.ADD_RESTAURANT_KYC_SUCCESS, keyfeatures } }
    function failure(error) { return { type: kycConstants.ADD_RESTAURANT_KYC_FAILURE, error } }
}
function deleteKyc(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id: data.id
    }
    return dispatch => {
        dispatch(request());
        kycService.deleteKyc(maintempdata)
            .then(
                keyfeatures => {
                    dispatch(success(keyfeatures));
                    dispatch(this.getKycList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: kycConstants.ADD_RESTAURANT_KYC_REQUEST } }
    function success(keyfeatures) { return { type: kycConstants.ADD_RESTAURANT_KYC_SUCCESS, keyfeatures } }
    function failure(error) { return { type: kycConstants.ADD_RESTAURANT_KYC_FAILURE, error } }
}
function uploadImage(data) {
    return dispatch => {
        kycService.uploadImage(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: kycConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: kycConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}
