import React from "react";
import { CgLogIn } from 'react-icons/cg';

import Modal from 'react-modal';
const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};


export default function ViewPendingModal(props) {

  let { addPaymentField, handleMoreDetailsClose, viewRowData, fieldsHoliday, errorsHoliday, inputHolidayChange, createHolidaySubmit
  } = props;
  console.log("viewRowDataModal::", viewRowData);

  return (



    <Modal
      isOpen={addPaymentField}
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-[#FBB00C] modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content  text-left">
            {/*Title*/}
            <div className="flex justify-between items-center  bg-[#FBB00C] px-4 py-4">
              <p className="text-2xl font-bold">Referral Payment</p>
              <div className="modal-close cursor-pointer z-50 bg-white rounded-full p-2">
                <svg onClick={() => handleMoreDetailsClose()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5  overflow-y-auto px-4">
              <form autoComplete="off" className="space-y-1">
                <div className="">
                  <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Amount</label>
                  <div className="mt-1  shadow-sm relative">
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 ${errorsHoliday && !errorsHoliday["amount"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="amount" name="amount" placeholder="Amount" value={fieldsHoliday.amount} type="number" onChange={inputHolidayChange} />
                    {errorsHoliday && errorsHoliday["amount"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsHoliday["amount"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className=" w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-[#FBB00C] w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-[#FBB00C] hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={createHolidaySubmit}>Submit</button>
                </div>
              </form>



            </div>
            {/*Footer*/}
          </div>
        </div >
      </div >


    </Modal >



  );
}
