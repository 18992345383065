import { userConstants, coinConstants } from '../_constants';
import { userService, coinService } from '../_services';
import { alertActions } from './';
// import { toast } from 'react-toastify';
export const coinActions = {

    login,
    logout,
    disableCoin,
    getAllCoin,
    createCoin,
    updateCoin,
    getCoinList,
    deleteCoin,
    uploadImageClear,
    disableCoinCard
    // depositUserBalance
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

// function depositUserBalance(data) {

//     return dispatch => {
//         dispatch(request());
//         coinService.depositUserBalance(data)
//             .then(

//                 users => {
//                     dispatch(alertActions.success("Deposit Balance Successfully."));
//                     dispatch(success(users));
//                     dispatch(this.getWalletList({ id: data.userId }));


//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: coinConstants.DEPOSIT_USER_BALANCE_REQUEST } }
//     function success(users) { return { type: coinConstants.DEPOSIT_USER_BALANCE_SUCCESS, users } }
//     function failure(error) { return { type: coinConstants.DEPOSIT_USER_BALANCE_FAILURE, error } }
// }

function createCoin(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        coinService.createCoin(data)
            .then(

                users => {
                    // toast("Coin Created successfully.")
                    dispatch(alertActions.success("Coin Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getCoinList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.ADD_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.ADD_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.ADD_COIN_FAILURE, error } }
}
function updateCoin(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        coinService.updateCoin(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Coin Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getCoinList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.UPDATE_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.UPDATE_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.UPDATE_COIN_FAILURE, error } }
}
function getAllCoin() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        coinService.getAllCoin()
            .then(
                users => {
                    console.log("getAllCoin $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.GETALL_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.GETALL_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.GETALL_COIN_FAILURE, error } }
}
function getCoinList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        coinService.getCoinList(data)
            .then(
                users => {
                    console.log("getCoinList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.GET_LIST_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.GET_LIST_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.GET_LIST_COIN_FAILURE, error } }
}

function deleteCoin(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        coinService.deleteCoin(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCoinList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.DELETE_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.DELETE_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.DELETE_COIN_FAILURE, error } }
}
function disableCoin(data, paginationData) {

    return dispatch => {
        dispatch(request());
        coinService.disableCoin(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCoinList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.DISABLE_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.DISABLE_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.DISABLE_COIN_FAILURE, error } }
}
function disableCoinCard(data, paginationData) {

    return dispatch => {
        dispatch(request());
        coinService.disableCoinCard(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCoinList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.DISABLE_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.DISABLE_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.DISABLE_COIN_FAILURE, error } }
}
