export const teachcourseConstants = {

    GETALL_COURSE_REQUEST: 'GETALL_COURSE_REQUEST',
    GETALL_COURSE_SUCCESS: 'GETALL_COURSE_SUCCESS',
    GETALL_COURSE_FAILURE: 'GETALL_COURSE_FAILURE',

    MANUAL_TUTOR_ASSIGN_REQUEST: 'MANUAL_TUTOR_ASSIGN_REQUEST',
    MANUAL_TUTOR_ASSIGN_SUCCESS: 'MANUAL_TUTOR_ASSIGN_SUCCESS',
    MANUAL_TUTOR_ASSIGN_FAILURE: 'MANUAL_TUTOR_ASSIGN_FAILURE',

    GET_LIST_COURSE_REQUEST: 'GET_LIST_COURSE_REQUEST',
    GET_LIST_COURSE_SUCCESS: 'GET_LIST_COURSE_SUCCESS',
    GET_LIST_COURSE_FAILURE: 'GET_LIST_COURSE_FAILURE',

    GET_SUGGESTED_TUTOR_REQUEST: 'GET_SUGGESTED_TUTOR_REQUEST',
    GET_SUGGESTED_TUTOR_SUCCESS: 'GET_SUGGESTED_TUTOR_SUCCESS',
    GET_SUGGESTED_TUTOR_FAILURE: 'GET_SUGGESTED_TUTOR_FAILURE',

    UPDATE_TUTOR_DEMO_STATUS_ADMIN_REQUEST: 'UPDATE_TUTOR_DEMO_STATUS_ADMIN_REQUEST',
    UPDATE_TUTOR_DEMO_STATUS_ADMIN_SUCCESS: 'UPDATE_TUTOR_DEMO_STATUS_ADMIN_SUCCESS',
    UPDATE_TUTOR_DEMO_STATUS_ADMIN_FAILURE: 'UPDATE_TUTOR_DEMO_STATUS_ADMIN_FAILURE',


    GET_PENDING_DEMO_LIST_REQUEST: 'GET_PENDING_DEMO_LIST_REQUEST',
    GET_PENDING_DEMO_LIST_SUCCESS: 'GET_PENDING_DEMO_LIST_SUCCESS',
    GET_PENDING_DEMO_LIST_FAILURE: 'GET_PENDING_DEMO_LIST_FAILURE',


    MAKE_DUPLICATE_DEMO_CLASS_REQUEST: 'MAKE_DUPLICATE_DEMO_CLASS_REQUEST',
    MAKE_DUPLICATE_DEMO_CLASS_SUCCESS: 'MAKE_DUPLICATE_DEMO_CLASS_SUCCESS',
    MAKE_DUPLICATE_DEMO_CLASS_FAILURE: 'MAKE_DUPLICATE_DEMO_CLASS_FAILURE',


    UPDATE_DEMO_REQUEST_REQUEST: 'UPDATE_DEMO_REQUEST_REQUEST',
    UPDATE_DEMO_REQUEST_SUCCESS: 'UPDATE_DEMO_REQUEST_SUCCESS',
    UPDATE_DEMO_REQUEST_FAILURE: 'UPDATE_DEMO_REQUEST_FAILURE',

    // DEPOSIT_USER_BALANCE_REQUEST: 'DEPOSIT_USER_BALANCE_REQUEST',
    // DEPOSIT_USER_BALANCE_SUCCESS: 'DEPOSIT_USER_BALANCE_SUCCESS',
    // DEPOSIT_USER_BALANCE_FAILURE: 'DEPOSIT_USER_BALANCE_FAILURE',

    ADD_COURSE_REQUEST: 'ADD_COURSE_REQUEST',
    ADD_COURSE_SUCCESS: 'ADD_COURSE_SUCCESS',
    ADD_COURSE_FAILURE: 'ADD_COURSE_FAILURE',

    APPROVED_DEMO_CLASS_REQUEST: 'APPROVED_DEMO_CLASS_REQUEST',
    APPROVED_DEMO_CLASS_SUCCESS: 'APPROVED_DEMO_CLASS_SUCCESS',
    APPROVED_DEMO_CLASS_FAILURE: 'APPROVED_DEMO_CLASS_FAILURE',

    CHANGE_STATUS_DEMO_CLASS_REQUEST: 'CHANGE_STATUS_DEMO_CLASS_REQUEST',
    CHANGE_STATUS_DEMO_CLASS_SUCCESS: 'CHANGE_STATUS_DEMO_CLASS_SUCCESS',
    CHANGE_STATUS_DEMO_CLASS_FAILURE: 'CHANGE_STATUS_DEMO_CLASS_FAILURE',

    DELETE_COURSE_REQUEST: 'DELETE_COURSE_REQUEST',
    DELETE_COURSE_SUCCESS: 'DELETE_COURSE_SUCCESS',
    DELETE_COURSE_FAILURE: 'DELETE_COURSE_FAILURE',

    UPDATE_COURSE_REQUEST: 'UPDATE_COURSE_REQUEST',
    UPDATE_COURSE_SUCCESS: 'UPDATE_COURSE_SUCCESS',
    UPDATE_COURSE_FAILURE: 'UPDATE_COURSE_FAILURE',

    ASSIGN_TUTOR_REQUEST: 'ASSIGN_TUTOR_REQUEST',
    ASSIGN_TUTOR_SUCCESS: 'ASSIGN_TUTOR_SUCCESS',
    ASSIGN_TUTOR_FAILURE: 'ASSIGN_TUTOR_FAILURE',

    DISABLE_COURSE_REQUEST: 'DISABLE_COURSE_REQUEST',
    DISABLE_COURSE_SUCCESS: 'DISABLE_COURSE_SUCCESS',
    DISABLE_COURSE_FAILURE: 'DISABLE_COURSE_FAILURE',

};
