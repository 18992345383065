export const feesettingConstants = {
    GETALL_REQUEST: 'FEE_SETTING_GETALL_REQUEST',
    GETALL_SUCCESS: 'FEE_SETTING_GETALL_SUCCESS',
    GETALL_FAILURE: 'FEE_SETTING_GETALL_FAILURE',

   
    ADD_FEE_SETTING_REQUEST: 'ADD_FEE_SETTING_REQUEST',
    ADD_FEE_SETTING_SUCCESS: 'ADD_FEE_SETTING_SUCCESS',
    ADD_FEE_SETTING_FAILURE: 'ADD_FEE_SETTING_FAILURE',


    UPDATES_FEE_SETTING_REQUEST: 'UPDATES_FEE_SETTING_REQUEST',
    UPDATES_FEE_SETTING_SUCCESS: 'UPDATES_FEE_SETTING_SUCCESS',
    UPDATES_FEE_SETTING_FAILURE: 'UPDATES_FEE_SETTING_FAILURE',


    DELETE_FEE_SETTING_FAILURE: 'DELETE_FEE_SETTING_FAILURE',
    DELETE_FEE_SETTING_SUCCESS: 'DELETE_FEE_SETTING_SUCCESS',
    DELETE_FEE_SETTING_REQUEST: 'DELETE_FEE_SETTING_REQUEST',


    DISABLE_FEE_SETTING_REQUEST: 'DISABLE_FEE_SETTING_REQUEST',
    DISABLE_FEE_SETTING_SUCCESS: 'DISABLE_FEE_SETTING_SUCCESS',
    DISABLE_FEE_SETTING_FAILURE: 'DISABLE_FEE_SETTING_FAILURE',


    LOGIN_REQUEST: 'FEE_SETTING_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'FEE_SETTING_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'FEE_SETTING_LOGIN_FAILURE',

    LOGOUT: 'FEE_SETTING_LOGOUT',


    GET_ALL_APPSETTING_REQUEST: 'FEE_SETTING_GET_ALL_APPSETTING_REQUEST',
    GET_ALL_APPSETTING_SUCCESS: 'FEE_SETTING_GET_ALL_APPSETTING_SUCCESS',
    GET_ALL_APPSETTING_FAILURE: 'FEE_SETTING_GET_ALL_APPSETTING_FAILURE',    
   

    


};
