import React, { Component } from 'react';
import { connect } from 'react-redux';
import { transactionActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { RiChatCheckLine } from "react-icons/ri";

import { CSVLink } from "react-csv";

class WithdrawPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      password: "",
      size: 10,
      page: 1,
      keyWord: '',
      txId: "",
      selected: false,
      userTxId: [],
    }
  }

  componentDidMount() {
    let tempData = {
      "txType": "WITHDRAW",
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    this.props.dispatch(transactionActions.getTxWithdrawPending(tempData));
    this.props.dispatch(transactionActions.getTxWithdrawPendingAll());
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "txType": "WITHDRAW",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(transactionActions.getTxWithdrawPending(datatemp));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "txType": "WITHDRAW",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(transactionActions.getTxWithdrawPending(data));
  }




  onApprovedViaApi = (data) => {

    console.log('clickedddddddddddddddddddd__________________', data);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
            <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
              <div className="modal-content py-4 text-left px-6">
                <div className="my-5">
                  <div autoComplete="off">
                    <div className="m-2 ">
                      {/* <div className="mt-1  shadow-sm relative">
                        <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                          placeholder="Enter TxId ..." type="text" onChange={this.handleChangeCommentInput} />
                      </div> */}
                      <div className="mt-1  shadow-sm relative">
                        <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                          placeholder="Enter Password ..." type="password" onChange={this.handleChangePassword} />
                      </div>
                    </div>
                    <>
                      <span class="inline-block pl-1">
                        <button class="bg-white-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-black-100 rounded-xl hover:shadow-lg hover:bg-white-700" onClick={onClose}>No</button>
                      </span>
                      <span className="inline-block pl-1">
                        <button class="bg-red-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-red-700" onClick={() => {
                          this.props.dispatch(transactionActions.approveViaApi({
                            "userTxId": data.id,
                            "password": this.state.password,
                            "keyWord": this.state.keyWord,
                            "pageNo": this.state.page,
                            "size": this.state.size,

                          }))
                          onClose();
                        }}>Approved</button>
                      </span>
                    </>


                  </div>

                </div>
                {/*Footer*/}
              </div>
            </div>
          </div>





        );
      }
    });
  }


  checkBox = (data) => {
    // let txId = [];
    let { userTxId } = this.state;
    userTxId.push(data.id)
    console.log('data_+++++++++++++++++++++==', userTxId);
  }
  multipleOpen = (data) => {
    // let txId = [];
    this.setState({ selected: !this.state.selected })
    console.log('this.state___________', this.state.userTxId);
  }
  // approveMultiViaApi = () => {
  //   console.log('this_________________', this.state.userTxId);

  // }
  approveMultiViaApi = (data) => {

    console.log('clickedddddddddddddddddddd__________________', data);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
            <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
              <div className="modal-content py-4 text-left px-6">
                <div className="my-5">
                  <div autoComplete="off">
                    <div className="m-2 ">
                      {/* <div className="mt-1  shadow-sm relative">
                        <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                          placeholder="Enter TxId ..." type="text" onChange={this.handleChangeCommentInput} />
                      </div> */}
                      <div className="mt-1  shadow-sm relative">
                        <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                          placeholder="Enter Password ..." type="password" onChange={this.handleChangePassword} />
                      </div>
                    </div>
                    <>
                      <span class="inline-block pl-1">
                        <button class="bg-white-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-black-100 rounded-xl hover:shadow-lg hover:bg-white-700" onClick={onClose}>No</button>
                      </span>
                      <span className="inline-block pl-1">
                        <button class="bg-red-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-red-700" onClick={() => {
                          this.props.dispatch(transactionActions.approveMultiViaApi({
                            "userTxId": this.state.userTxId,
                            "password": this.state.password,
                            "keyWord": this.state.keyWord,
                            "pageNo": this.state.page,
                            "size": this.state.size,
                          }))
                          onClose();
                        }}>Approved</button>
                      </span>
                    </>


                  </div>

                </div>
                {/*Footer*/}
              </div>
            </div>
          </div>





        );
      }
    });
  }

  onApprovedWithdrawPending = (data) => {

    console.log('clickedddddddddddddddddddd__________________', data);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (

          <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
            <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
              <div className="modal-content py-4 text-left px-6">
                <div className="my-5">
                  <div autoComplete="off">
                    <div className="m-2 ">
                      <div className="mt-1  shadow-sm relative">
                        <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                          placeholder="Enter TxId ..." type="text" onChange={this.handleChangeCommentInput} />
                      </div>
                    </div>
                    <>
                      <span class="inline-block pl-1">
                        <button class="bg-white-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-black-100 rounded-xl hover:shadow-lg hover:bg-white-700" onClick={onClose}>No</button>
                      </span>
                      <span className="inline-block pl-1">
                        <button class="bg-red-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-red-700" onClick={() => {
                          this.props.dispatch(transactionActions.sendCoinApproveDFTC({
                            "userTxId": data.id,
                            "keyWord": this.state.keyWord,
                            "pageNo": this.state.page,
                            "size": this.state.size,
                            "txId": this.state.txId,
                          }))
                          onClose();
                        }}>Approved</button>
                      </span>
                    </>


                  </div>

                </div>
                {/*Footer*/}
              </div>
            </div>
          </div>





        );
      }
    });
  }

  handleChangeCommentInput = (event) => {
    let { value } = event.target;
    this.setState({ txId: value })
  }
  handleChangePassword = (event) => {
    let { value } = event.target;

    this.setState({ password: value })

  }

  // revertToMainBalanceSubmit = (data) => {

  //   let tempData = {
  //     "txType": "WITHDRAW",
  //     "keyWord": this.state.keyWord,
  //     "pageNo": this.state.pageNo,
  //     "size": this.state.size
  //   }

  //   this.props.dispatch(transactionActions.revertToMainBalance({ userTxId: data.id }, tempData));
  // }
  revertToMainBalanceSubmit = (data) => {
    let tempData = {
      "txType": "WITHDRAW",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }


    confirmAlert({
      title: 'Confirm to revert amount to Main Balance?',
      message: 'Are you sure to revert amount to Main Balance ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(transactionActions.revertToMainBalance({ userTxId: data.id }, tempData))
        },
        {
          label: 'No'
        }
      ]
    });


  }

  // revertToPayoutSubmit = (data) => {

  //   let tempData = {
  //     "txType": "WITHDRAW",
  //     "keyWord": this.state.keyWord,
  //     "pageNo": this.state.pageNo,
  //     "size": this.state.size
  //   }
  //   // console.log("id=============>", data);
  //   // console.log("id=============>", data.id);

  //   this.props.dispatch(transactionActions.revertToPayout({ userTxId: data.id }, tempData));
  // }

  revertToPayoutSubmit = (data) => {
    let tempData = {
      "txType": "WITHDRAW",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }


    confirmAlert({
      title: 'Confirm to revert amount to Payout?',
      message: 'Are you sure to revert amount to Payout ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(transactionActions.revertToPayout({ userTxId: data.id }, tempData))
        },
        {
          label: 'No'
        }
      ]
    });


  }



  render() {
    console.log('userTxId_______RENDER', this.state.userTxId);
    // console.log("renderrendertxIdtxIdtxIdtxId.<><><><><>", this.state.txId);
    let { transaction } = this.props;
    let { withdrawitems, withdrawtotal, loading, withdrawitemsAll } = transaction;
    let headers = [
      { label: "Id", key: "id" },
      { label: "Email", key: "email" },
      { label: "User Name", key: "userName" },
      { label: "Status", key: "status" },
      { label: "Amount", key: "amount" },
      { label: "Final Amount", key: "finalamount" },
      { label: "DFTC Address", key: "addr" },
      { label: "DFTC Amount", key: "finalDFTCamount" },
    ];

    return (
      <>
        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>




        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="flex-1 relative ">
            <div className="2xl:p-10 sm:p-5 p-3">
              <div className="max-w-screen-3xl mx-auto ">
                {/* Transaction Section */}
                <section className="bg-white col-span-6 2xl:py-7 py-5 2xl:px-10 sm:px-5 px-2 flex-col rounded-md">
                  <div className="w-full flex flex-wrap  ">
                    <div className="w-full flex flex-wrap  ">
                      <div className="ml-0 mr-auto">
                        <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-widest">Withdraw pending</h3>
                      </div>
                      <div className="relative mt-5 lg:mt-0">
                        <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        </div>
                        <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />

                        {withdrawitemsAll ? <CSVLink data={withdrawitemsAll} headers={headers}>
                          Download me
                        </CSVLink> : null}
                      </div>


                    </div>
                    <div className='flex justify-right'>
                      <button class="bg-blue-500 hover:bg-blue-700 text-white  font-bold py-2 px-4 rounded-full"
                        onClick={() => this.multipleOpen()}
                      >
                        Approve Multiple
                      </button>
                      {this.state.selected ? <>
                        <button class="bg-blue-500 hover:bg-blue-700 text-white  font-bold py-2 px-4 rounded-full"
                          onClick={() => this.approveMultiViaApi()}
                        >
                          Submit
                        </button>
                      </> : null}

                    </div>

                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4 shadow-md">
                    <div className="overflow-x-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-purple-300 purounded-t">
                              <tr className=" ">
                                {this.state.selected ? <>
                                  <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Checked</th>
                                </> : null}
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">#</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Name </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">email</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Transaction Type</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Amount</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Coin</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Description</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Action</th>
                              </tr>
                            </thead>

                            {/* Table Row Section */}
                            <tbody>
                              {
                                withdrawitems && withdrawitems.length > 0 ?
                                  withdrawitems.map((element, index) => (<React.Fragment key={element.id}>



                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      {this.state.selected ? <>
                                        <td className="px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">
                                          <div class="flex justify-center">
                                            <div>
                                              <div class="form-check">
                                                <input class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckChecked" onClick={() => this.checkBox(element)} />

                                              </div>
                                            </div>
                                          </div>
                                          {/* <div class="form-check">
                                          <input class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckIndeterminate" />
                                            <label class="form-check-label inline-block text-gray-800" for="flexCheckIndeterminate">
                                              Indeterminate checkbox
                                            </label>
                                        </div> */}
                                        </td>
                                      </> : null}
                                      <td className="px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">
                                        {this.state.offset + index + 1}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element && element.userId && element.userId.userName ? element.userId.userName : '-'}</td>

                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element && element.userId && element.userId.email ? element.userId.email : '-'}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.txType}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.amount}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element && element.coinId && element.coinId.name ? element.coinId.name : '-'}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.status && element.status === true ? "Success" : "Pending"}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        <span className="flex pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.onApprovedWithdrawPending(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Approved</span>
                                            <RiChatCheckLine className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>
                                        <span className="flex pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.onApprovedViaApi(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>ApproveViaApi</span>
                                            <RiChatCheckLine className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>

                                        {
                                          element && element.revert ?
                                            null :

                                            <>
                                              <span className="flex pl-1">
                                                <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer"
                                                  onClick={() => this.revertToPayoutSubmit(element)}
                                                >
                                                  <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Revert To Payout</span>
                                                  <RiChatCheckLine className='w-5 h-5 block object-contain' />
                                                </div>
                                              </span>
                                              <span className="flex pl-1">
                                                <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer"
                                                  onClick={() => this.revertToMainBalanceSubmit(element)}
                                                >
                                                  <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Revert To Mainbalance</span>
                                                  <RiChatCheckLine className='w-5 h-5 block object-contain' />
                                                </div>
                                              </span>

                                            </>
                                        }





                                      </td>
                                    </tr>


                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          withdrawtotal && withdrawtotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={withdrawtotal / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          withdrawtotal && withdrawtotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={withdrawtotal / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>}
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />
      </>


    );
  }
}
function mapStateToProps(state) {
  const { transaction } = state;
  return {
    transaction
  };
}
export default connect(mapStateToProps)(WithdrawPending);
