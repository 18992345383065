export const transactionConstants = {

    REVERT_TO_PAYOUT_REQUEST: 'REVERT_TO_PAYOUT_REQUEST',
    REVERT_TO_PAYOUT_SUCCESS: 'REVERT_TO_PAYOUT_SUCCESS',
    REVERT_TO_PAYOUT_FAILURE: 'REVERT_TO_PAYOUT_FAILURE',

    REVERT_TO_MAIN_BALANCE_REQUEST: 'REVERT_TO_MAIN_BALANCE_REQUEST',
    REVERT_TO_MAIN_BALANCE_SUCCESS: 'REVERT_TO_MAIN_BALANCE_SUCCESS',
    REVERT_TO_MAIN_BALANCE_FAILURE: 'REVERT_TO_MAIN_BALANCE_FAILURE',

    GET_TX_ALL_ADMIN_REQUEST: 'TRANSACTION_GET_TX_ALL_ADMIN_REQUEST',
    GET_TX_ALL_ADMIN_SUCCESS: 'TRANSACTION_GET_TX_ALL_ADMIN_SUCCESS',
    GET_TX_ALL_ADMIN_FAILURE: 'TRANSACTION_GET_TX_ALL_ADMIN_FAILURE',

    GET_USER_TXLIST_REQUEST: 'TRANSACTION_GET_USER_TXLIST_REQUEST',
    GET_USER_TXLIST_SUCCESS: 'TRANSACTION_GET_USER_TXLIST_SUCCESS',
    GET_USER_TXLIST_FAILURE: 'TRANSACTION_GET_USER_TXLIST_FAILURE',

    SENDCOIN_APPROVED_DFTC_REQUEST: 'TRANSACTION_SENDCOIN_APPROVED_DFTC_REQUEST',
    SENDCOIN_APPROVED_DFTC_SUCCESS: 'TRANSACTION_SENDCOIN_APPROVED_DFTC_SUCCESS',
    SENDCOIN_APPROVED_DFTC_FAILURE: 'TRANSACTION_SENDCOIN_APPROVED_DFTC_FAILURE',

    APPROVED_VIA_API_REQUEST: 'APPROVED_VIA_API_REQUEST',
    APPROVED_VIA_API_SUCCESS: 'APPROVED_VIA_API_SUCCESS',
    APPROVED_VIA_API_FAILURE: 'APPROVED_VIA_API_FAILURE',

    UPDATE_CASH_FLOW_REQUEST: 'UPDATE_CASH_FLOW_REQUEST',
    UPDATE_CASH_FLOW_SUCCESS: 'UPDATE_CASH_FLOW_SUCCESS',
    UPDATE_CASH_FLOW_FAILURE: 'UPDATE_CASH_FLOW_FAILURE',



    TXLIST_WIDTHRAW_SUCESS_REQUEST: 'TRANSACTION_TXLIST_WIDTHRAW_SUCESS_REQUEST',
    TXLIST_WIDTHRAW_SUCESS_SUCCESS: 'TRANSACTION_TXLIST_WIDTHRAW_SUCESS_SUCCESS',
    TXLIST_WIDTHRAW_SUCESS_FAILURE: 'TRANSACTION_TXLIST_WIDTHRAW_SUCESS_FAILURE',


    ALL_TXLIST_WIDTHRAW_SUCESS_REQUEST: 'ALL_TXLIST_WIDTHRAW_SUCESS_REQUEST',
    ALL_TXLIST_WIDTHRAW_SUCESS_SUCCESS: 'ALL_TXLIST_WIDTHRAW_SUCESS_SUCCESS',
    ALL_TXLIST_WIDTHRAW_SUCESS_FAILURE: 'ALL_TXLIST_WIDTHRAW_SUCESS_FAILURE',

    TXLIST_WIDTHRAW_PENDING_REQUEST: 'TRANSACTION_TXLIST_WIDTHRAW_PENDING_REQUEST',
    TXLIST_WIDTHRAW_PENDING_SUCCESS: 'TRANSACTION_TXLIST_WIDTHRAW_PENDING_SUCCESS',
    TXLIST_WIDTHRAW_PENDING_FAILURE: 'TRANSACTION_TXLIST_WIDTHRAW_PENDING_FAILURE',

    TXLIST_WIDTHRAW_PENDING_ALL_REQUEST: 'TRANSACTION_TXLIST_WIDTHRAW_PENDING_ALL_REQUEST',
    TXLIST_WIDTHRAW_PENDING_ALL_SUCCESS: 'TRANSACTION_TXLIST_WIDTHRAW_PENDING_ALL_SUCCESS',
    TXLIST_WIDTHRAW_PENDING_ALL_FAILURE: 'TRANSACTION_TXLIST_WIDTHRAW_PENDING_ALL_FAILURE',

    TXLIST_MAAEXCH_WIDTHRAW_SUCESS_REQUEST: 'TRANSACTION_TXLIST_MAAEXCH_WIDTHRAW_SUCESS_REQUEST',
    TXLIST_MAAEXCH_WIDTHRAW_SUCESS_SUCCESS: 'TRANSACTION_TXLIST_MAAEXCH_WIDTHRAW_SUCESS_SUCCESS',
    TXLIST_MAAEXCH_WIDTHRAW_SUCESS_FAILURE: 'TRANSACTION_TXLIST_MAAEXCH_WIDTHRAW_SUCESS_FAILURE',

};
