
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';

export const userService = {
    login,
    userLogin,
    getProfile,
    logout,
    updateUserPasswordSubmit,
    registerUserAdmin,
    getUserActive,
    getUserListRejectedInterview,
    getAssignedClassesList,
    getUserKycDetailsById,
    getUserPaymentDetails,
    getAssignmentAdminList,
    updateAssignedClassesStatus,
    updateAssignmentAdminStatus,
    markPaidOrUnPaid,
    createAssignedClasses,
    updateAssignedClasses,
    getUserDeactive,
    disableUser,
    changeStatusUserRef,
    registerUserByAdmin,
    updateInterviewStatus,
    deleteUser,
    deleteAssignedClasses,
    verifyEmail,
    loginToThisAccount,
    uploadImage,
    getWalletList,
    getUserRefList,
    addStudent,
    updateStudentDetails,
    depositUserBalance,
    withdrawUserBalance,
    getTuituinListTutorWasi,
    updateStatusPayment,
    addUserBalance,
    getLoginhistory,
    getUserPromoPackageByAdmin,
    getUserPackages,
    getTx,
    getUserInfoByAdmin,
    getSocialMediaById,
    getUserProfile,
    getUserPackagesTeam,
    getTicketList,
    changePassword,
    approvePromoPack,
    updateUserName,
    updateEmail,
    deleteAssignmentAdmin,
    updatePayoutAmount,
    updateAssignmentAdmin,
    getUserPromoPackageList,
    getUserPackageList,
    updateTeamMiningAmount,
    getUserTeamByUserId,
    getUserLevel,
    createAssignmentAdmin,
    removeDftcWalletAddress,
    manageUserPortfolio,
    changeLevelStatus,
    payFastrackOrLifeTimeBonus,
    createSubAdmin,
    getAdminProfile,
    getAdminList,
    updateAdminStatus,
    updateSubAdminPassword,
    getAttendanceListTuitionWasi,
    getTuitionListByTutor,
    addAmountReferralUser,
    getAttendanceListByTutor,
    getTuitionListByDate,
    getFeeHistoryListAdmin,
    updateUnpaidStatus,
    getClassIdByTuitionStudent
};
function logout() {
    window.sessionStorage.removeItem('adminuser');
    // window.location.href = "#/login";
    history.push(`#/login`);
    window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/adminLogin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                window.sessionStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function createSubAdmin(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createSubAdmin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            // if (user.data) {
            //     window.sessionStorage.setItem('adminuser', JSON.stringify(user.data));
            // }

            return userObj;
        });
}
function getAdminProfile() {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAdminProfile`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                adminDetails: user.data
            }

            return userObj;
        });
}
function updateSubAdminPassword(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateSubAdminPassword`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                getAdminList: user.data
            }

            return userObj;
        });
}
function getAdminList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAdminList`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                getSubAdminList: user.data
            }
            console.log('userObj', userObj);
            return userObj;
        });
}
function getUserRefList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserRefList`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                getUserRefList: user.data
            }
            console.log('userObj', userObj);
            return userObj;
        });
}
function userLogin(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/loginUser`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            // if (user.data) {
            //     window.sessionStorage.setItem('adminuser', JSON.stringify(user.data));
            // }

            return userObj;
        });
}

function withdrawUserBalance(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/withdrawUserBalance`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                withdrawUserBalance: data.data
            }
            console.log("I am in service withdrawUserBalance", userObj);

            return userObj;
        });
}

function updateEmail(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUserProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateEmail: data
            }
            console.log("I am in service updateEmail", userObj);

            return userObj;
        });
}

function updateUserName(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUserName`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateUserName: data
            }
            console.log("I am in service updateUserName", userObj);

            return userObj;
        });
}

function addUserBalance(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/addUserBalance`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                addUserBalance: data
            }
            console.log("I am in service addUserBalance", userObj);

            return userObj;
        });
}

function depositUserBalance(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/depositUserBalance`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                depositUserBalance: data.data
            }
            console.log("I am in service depositUserBalance", userObj);

            return userObj;
        });
}

function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res.data
            }
            return userObj;
        });
}

function verifyEmail(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/validateOtpAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                verifyEmail: data.data
            }
            console.log("i am in service''...>> verifyEmail ::", userObj);

            return userObj;
        });
}
function deleteUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteUser: data.data
            }
            console.log("i am in service''...>> deleteUser ::", userObj);

            return userObj;
        });
}
function deleteAssignedClasses(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteAssignedClasses`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteAssignedClasses: data.data
            }
            console.log("i am in service''...>> deleteAssignedClasses ::", userObj);

            return userObj;
        });
}
function updateAdminStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateAdminStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateAdminStatus: data.data
            }
            console.log("i am in service''...>> updateAdminStatus ::", userObj);

            return userObj;
        });
}
function disableUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUserStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                disableUser: data.data
            }
            console.log("i am in service''...>> disableUser ::", userObj);

            return userObj;
        });
}
function changeStatusUserRef(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/changeStatusUserRef`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                changeStatusUserRef: data.data
            }
            console.log("i am in service''...>> changeStatusUserRef ::", userObj);

            return userObj;
        });
}
function registerUserByAdmin(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/registerUserByAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                registerUserByAdmin: data.data
            }
            console.log("i am in service''...>> registerUserByAdmin ::", userObj);

            return userObj;
        });
}
function updateInterviewStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateInterviewStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateInterviewStatus: data.data
            }
            console.log("i am in service''...>> updateInterviewStatus ::", userObj);

            return userObj;
        });
}
function updateAssignedClassesStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateAssignedClassesStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateAssignedClassesStatus: data.data
            }
            console.log("i am in service''...>> updateAssignedClassesStatus ::", userObj);

            return userObj;
        });
}
function updateAssignmentAdminStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateAssignmentAdminStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateAssignmentAdminStatus: data.data
            }
            console.log("i am in service''...>> updateAssignmentAdminStatus ::", userObj);

            return userObj;
        });
}
function changeLevelStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/changeLevelStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                changeLevelStatus: data.data
            }
            console.log("i am in service''...>> changeLevelStatus ::", userObj);

            return userObj;
        });
}
function getTuituinListTutorWasi(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTuituinListTutorWasi`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTuituinListTutorWasi: data.data
            }
            // console.log("i am in service getTuituinListTutorWasi ::", userObj);

            return userObj;
        });
}

function getAttendanceListTuitionWasi(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAttendanceListTuitionWasi`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAttendanceListTuitionWasi: data.data
            }
            // console.log("i am in service getAttendanceListTuitionWasi ::", userObj);

            return userObj;
        });
}
function updateStatusPayment(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateStatusPayment`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateStatusPayment: data.data
            }
            console.log("i am in service updateStatusPayment ::", userObj);

            return userObj;
        });
}
function getUserListRejectedInterview(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserListRejectedInterview`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserListRejectedInterview: data.data
            }
            console.log("i am in service getUserListRejectedInterview ::", userObj);

            return userObj;
        });
}
function getUserActive(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserActive: data.data
            }
            console.log("i am in service getUserActive ::", userObj);

            return userObj;
        });
}
function getTuitionListByTutor(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTuitionListByTutor`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTuitionListByTutor: data.data
            }
            console.log("i am in service getTuitionListByTutor ::", userObj);

            return userObj;
        });
}
function addAmountReferralUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/addAmountReferralUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                addAmountReferralUser: data.data
            }
            console.log("i am in service addAmountReferralUser ::", userObj);

            return userObj;
        });
}
function getAttendanceListByTutor(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAttendanceListByTutor`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAttendanceListByTutor: data.data
            }
            console.log("i am in service getAttendanceListByTutor ::", userObj);

            return userObj;
        });
}
function getUserKycDetailsById(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserKycDetailsById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserKycDetailsById: data.data
            }
            console.log("i am in service getUserKycDetailsById ::", userObj);

            return userObj;
        });
}
function getAssignedClassesList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAssignedClassesList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAssignedClassesList: data.data
            }
            console.log("i am in service getAssignedClassesList ::", userObj);

            return userObj;
        });
}
function getTuitionListByDate(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTuitionListByDate`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTuitionListByDate: data.data
            }
            console.log("i am in service getTuitionListByDate ::", userObj);

            return userObj;
        });
}
function getFeeHistoryListAdmin(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getFeeHistoryListAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getFeeHistoryListAdmin: data.data
            }
            console.log("i am in service getFeeHistoryListAdmin ::", userObj);

            return userObj;
        });
}
function getUserPaymentDetails(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserPaymentDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserPaymentDetails: data.data
            }
            console.log("i am in service getUserPaymentDetails ::", userObj);

            return userObj;
        });
}

function getAssignmentAdminList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAssignmentAdminList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAssignmentAdminList: data.data
            }
            console.log("i am in service getAssignmentAdminList ::", userObj);

            return userObj;
        });
}
function getUserDeactive(data) {
    console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllUserList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserDeactive: data.data
            }
            console.log("i am in service getUserDeactive ::", userObj);

            return userObj;
        });
}

function registerUserAdmin(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/addTutor`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                registerUserAdmin: data.data
            }
            // console.log("I am in service registerUserAdmin", userObj);

            return userObj;
        });
}
function updateUserPasswordSubmit(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/UpdatePasswordUser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                UpdatePasswordUser: data.data
            }
            console.log("I am in service UpdatePasswordUser", userObj);

            return userObj;
        });
}

function loginToThisAccount(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/loginToUserAccount`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                loginToThisAccount: data.data
            }
            console.log("i am in service loginToThisAccount {{{{{{{}}}}}}}::", userObj);

            return userObj;
        });
}

function getProfile(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getProfile: data.data
            }
            console.log("i am in service loginToThisAccount {{{{{{{}}}}}}}::", userObj);

            return userObj;
        });
}

function addStudent(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/userRegisterDemo`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                addStudent: data.data
            }
            console.log("i am in service addStudent::", userObj);

            return userObj;
        });
}

function updateStudentDetails(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateStudentDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateStudentDetails: data.data
            }
            console.log("i am in service updateStudentDetails::", userObj);

            return userObj;
        });
}

function removeDftcWalletAddress(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/removeDftcWalletAddress`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                removeDftcWalletAddress: data.data
            }
            console.log("i am in service removeDftcWalletAddress::", userObj);

            return userObj;
        });
}
function createAssignmentAdmin(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createAssignmentAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createAssignmentAdmin: data.data
            }
            console.log("i am in service createAssignmentAdmin::", userObj);

            return userObj;
        });
}
function createAssignedClasses(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createAssignedClasses`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createAssignedClasses: data.data
            }
            console.log("i am in service createAssignedClasses::", userObj);

            return userObj;
        });
}
function markPaidOrUnPaid(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/markPaidOrUnPaid`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                markPaidOrUnPaid: data.data
            }
            console.log("i am in service markPaidOrUnPaid::", userObj);

            return userObj;
        });
}
function updateAssignedClasses(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateAssignedClasses`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateAssignedClasses: data.data
            }
            console.log("i am in service updateAssignedClasses::", userObj);

            return userObj;
        });
}
function payFastrackOrLifeTimeBonus(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/payFastrackOrLifeTimeBonus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                payFastrackOrLifeTimeBonus: data.data
            }
            console.log("i am in service payFastrackOrLifeTimeBonus::", userObj);

            return userObj;
        });
}
function manageUserPortfolio(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/manageUserPortfolio`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                manageUserPortfolio: data.data
            }
            console.log("i am in service manageUserPortfolio::", userObj);

            return userObj;
        });
}

function updatePayoutAmount(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePayoutAmount`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updatePayoutAmount: data
            }
            console.log("i am in service updatePayoutAmount::", userObj);

            return userObj;
        });
}

function updateTeamMiningAmount(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTeamMiningAmount`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateTeamMiningAmount: data
            }
            console.log("i am in service updateTeamMiningAmount::", userObj);

            return userObj;
        });
}

function updateAssignmentAdmin(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateAssignmentAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateAssignmentAdmin: data
            }
            console.log("i am in service updateAssignmentAdmin::", userObj);

            return userObj;
        });
}

function deleteAssignmentAdmin(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteAssignmentAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteAssignmentAdmin: data.data
            }
            console.log("i am in service deleteAssignmentAdmin::", userObj);

            return userObj;
        });
}

function approvePromoPack(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/approvePromoPack`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                approvePromoPack: data
            }
            console.log("i am in service approvePromoPack::", userObj);

            return userObj;
        });
}

function getWalletList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getWalletList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getWalletList: data.data
            }
            console.log("i am in service getWalletList::", userObj);

            return userObj;
        });
}
function getLoginhistory(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getLoginhistory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getLoginhistory: data.data
            }
            console.log("i am in service getLoginhistory::", userObj);

            return userObj;
        });
}
function getUserPromoPackageList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserPromoPackageList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserPromoPackageList: data.data
            }
            console.log("i am in service getUserPromoPackageList::", userObj);

            return userObj;
        });
}
function getUserPackageList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserPackageList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserPackageList: data.data
            }
            console.log("i am in service getUserPackageList::", userObj);

            return userObj;
        });
}
function getUserPromoPackageByAdmin(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserPromoPackageByAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserPromoPackageByAdmin: data.data
            }
            console.log("i am in service getUserPromoPackageByAdmin::", userObj);

            return userObj;
        });
}
function getUserPackages(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserPackages`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserPackages: data.data
            }
            console.log("i am in service get UserPackages::", userObj);

            return userObj;
        });
}
function getUserLevel(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserLevel`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserLevel: data.data
            }
            console.log("i am in service get getUserLevel::", userObj);

            return userObj;
        });
}
function getTx(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTx`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTx: data.data
            }
            console.log("i am in service getTx::", userObj);

            return userObj;
        });
}
function getUserInfoByAdmin(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserInfoByAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserInfoByAdmin: data.data
            }
            console.log("i am in service getUserInfoByAdmin::", userObj);

            return userObj;
        });
}
function getSocialMediaById(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getSocialMediaById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getSocialMediaById: data.data
            }
            console.log("i am in service getSocialMediaById::", userObj);

            return userObj;
        });
}
function getUserProfile(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserProfile: data.data
            }
            console.log("i am in service getUserProfile::", userObj);

            return userObj;
        });
}
function getUserPackagesTeam(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserPackagesTeam`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserPackagesTeam: data.data
            }
            console.log("i am in service getUserPackagesTeam::", userObj);

            return userObj;
        });
}
function getUserTeamByUserId(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserTeamByUserId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserTeamByUserId: data.data
            }
            console.log("i am in service getUserTeamByUserId::", userObj);

            return userObj;
        });
}
function getTicketList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTicketList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTicketList: data.data
            }
            console.log("i am in service getTicketList::", userObj);

            return userObj;
        });
}
function updateUnpaidStatus(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateUnpaidStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateUnpaidStatus: data.data
            }
            console.log("i am in service updateUnpaidStatus::", userObj);

            return userObj;
        });
}
function getClassIdByTuitionStudent(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getClassIdByTuitionStudent`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getClassIdByTuitionStudent: data.data
            }
            console.log("i am in service getClassIdByTuitionStudent::", userObj);

            return userObj;
        });
}
function changePassword(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/changePassword`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                changePassword: data.data
            }
            console.log("i am in service changePassword::", userObj);

            return userObj;
        });
}

function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}