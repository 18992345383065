import {
  loginhistoryConstants
} from '../_constants';

export function loginhistory(state = {}, action) {

  switch (action.type) {

    case loginhistoryConstants.GET_LOGIN_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case loginhistoryConstants.GET_LOGIN_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.loginhistory.getLoginHistoryList.list,
        total: action.loginhistory.getLoginHistoryList.total
      };
    case loginhistoryConstants.GET_LOGIN_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}