import {
  packageConstants
} from '../_constants';

export function packages(state = {}, action) {

  switch (action.type) {
    // case packageConstants.DEPOSIT_USER_BALANCE_REQUEST:
    //   return {
    //     ...state
    //   };
    // case packageConstants.DEPOSIT_USER_BALANCE_SUCCESS:
    //   return {
    //     ...state,
    //     addUserSuccess: true
    //   };
    // case packageConstants.DEPOSIT_USER_BALANCE_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };
    case packageConstants.ADD_PACKAGE_REQUEST:
      return {
        ...state
      };
    case packageConstants.ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case packageConstants.ADD_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case packageConstants.GET_LIST_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case packageConstants.GET_LIST_PACKAGE_SUCCESS:
      return {
        ...state,

        loading: false,
        items: action.users.getPackageList.list,
        total: action.users.getPackageList.total
      };
    case packageConstants.GET_LIST_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case packageConstants.GETALL_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.GETALL_PACKAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getAllPackage,
        total: action.users.getAllPackage
      };
    case packageConstants.GETALL_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case packageConstants.GET_USER_LIST_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.GET_USER_LIST_PACKAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        UserPackageItems: action.users.getUserPackage.list,
        UserPackageTotal: action.users.getUserPackage.total
      };
    case packageConstants.GET_USER_LIST_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case packageConstants.UPDATE_PACKAGE_REQUEST:
      return {
        ...state
      };
    case packageConstants.UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case packageConstants.UPDATE_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case packageConstants.DELETE_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case packageConstants.DELETE_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case packageConstants.DISABLE_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case packageConstants.DISABLE_PACKAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case packageConstants.DISABLE_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}