import React from "react";

import Modal from 'react-modal';


export default function ImageViewModal(props) {

  let { viewImageCreateModal, viewhandleClose, viewImageData,
    //  errorsAddUser, inputAddUserChange, AddUserSubmit, fieldsAddUser
  } = props;
  //console.log("userCreateModal  ",userCreateModal);
  // console.log("modal k inside :::::", viewImageData);
  return (

    <Modal
      isOpen={viewImageCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">IMAGE</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => viewhandleClose()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">
              <img src={viewImageData} alt=" " height={400} width={400} style={{ paddingBottom: "20px" }}></img>
            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
