import {
  indexConstants
} from '../_constants';

export function index(state = {}, action) {

  switch (action.type) {
    case indexConstants.ADD_INDEX_REQUEST:
      return {
        ...state
      };
    case indexConstants.ADD_INDEX_SUCCESS:
      return {
        ...state,
        addIndexSuccess: true
      };
    case indexConstants.ADD_INDEX_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case indexConstants.GET_LIST_INDEX_REQUEST:
      return {
        ...state,
        loading: true,
        addIndexSuccess: false,
        updateIndexSuccess: false
      };
    case indexConstants.GET_LIST_INDEX_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
        items: action.users.getIndexList.list,
        total: action.users.getIndexList.total
      };
    case indexConstants.GET_LIST_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case indexConstants.GETALL_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case indexConstants.GETALL_INDEX_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        indexItems: action.users.getAllIndex,
        indexTotal: action.users.getAllIndex
      };
    case indexConstants.GETALL_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case indexConstants.UPDATE_INDEX_REQUEST:
      return {
        ...state
      };
    case indexConstants.UPDATE_INDEX_SUCCESS:
      return {
        ...state,
        updateIndexSuccess: true
      };
    case indexConstants.UPDATE_INDEX_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case indexConstants.DELETE_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case indexConstants.DELETE_INDEX_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case indexConstants.DELETE_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case indexConstants.DISABLE_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case indexConstants.DISABLE_INDEX_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case indexConstants.DISABLE_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      


    default:
      return state
  }
}