export const welcomeBonusConstants = {

    GETALL_WELCOME_BONUS_REQUEST: 'GETALL_WELCOME_BONUS_REQUEST',
    GETALL_WELCOME_BONUS_SUCCESS: 'GETALL_WELCOME_BONUS_SUCCESS',
    GETALL_WELCOME_BONUS_FAILURE: 'GETALL_WELCOME_BONUS_FAILURE',

    GET_LIST_WELCOME_BONUS_REQUEST: 'GET_LIST_WELCOME_BONUS_REQUEST',
    GET_LIST_WELCOME_BONUS_SUCCESS: 'GET_LIST_WELCOME_BONUS_SUCCESS',
    GET_LIST_WELCOME_BONUS_FAILURE: 'GET_LIST_WELCOME_BONUS_FAILURE',

    // DEPOSIT_USER_BALANCE_REQUEST: 'DEPOSIT_USER_BALANCE_REQUEST',
    // DEPOSIT_USER_BALANCE_SUCCESS: 'DEPOSIT_USER_BALANCE_SUCCESS',
    // DEPOSIT_USER_BALANCE_FAILURE: 'DEPOSIT_USER_BALANCE_FAILURE',

    ADD_WELCOME_BONUS_REQUEST: 'ADD_WELCOME_BONUS_REQUEST',
    ADD_WELCOME_BONUS_SUCCESS: 'ADD_WELCOME_BONUS_SUCCESS',
    ADD_WELCOME_BONUS_FAILURE: 'ADD_WELCOME_BONUS_FAILURE',

    DELETE_WELCOME_BONUS_REQUEST: 'DELETE_WELCOME_BONUS_REQUEST',
    DELETE_WELCOME_BONUS_SUCCESS: 'DELETE_WELCOME_BONUS_SUCCESS',
    DELETE_WELCOME_BONUS_FAILURE: 'DELETE_WELCOME_BONUS_FAILURE',

    UPDATE_WELCOME_BONUS_REQUEST: 'UPDATE_WELCOME_BONUS_REQUEST',
    UPDATE_WELCOME_BONUS_SUCCESS: 'UPDATE_WELCOME_BONUS_SUCCESS',
    UPDATE_WELCOME_BONUS_FAILURE: 'UPDATE_WELCOME_BONUS_FAILURE',

    DISABLE_WELCOME_BONUS_REQUEST: 'DISABLE_WELCOME_BONUS_REQUEST',
    DISABLE_WELCOME_BONUS_SUCCESS: 'DISABLE_WELCOME_BONUS_SUCCESS',
    DISABLE_WELCOME_BONUS_FAILURE: 'DISABLE_WELCOME_BONUS_FAILURE',

};
