import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
// import LoadingOverlay from 'react-loading-overlay';


class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
    }
  }

  inputChange(e) {
    e.preventDefault();
    console.log("22222222222222222", e.target.value);
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  }


  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email, password, } = this.state.fieldslogin;
      console.log("asdfasdf  ", email, password);
      this.props.dispatch(userActions.login({ userName: email, password: password, }, this.props));
    }

  }

  // resetForm = (e) => {
  //   e.preventDefault();
  //   this.setState({
  //     fieldslogin: {},
  //     errorslogin: {},
  //   })
  //   this.hideErrorMessage();
  // }


  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Email
    if (!fieldslogin["email"] || fieldslogin["email"] === "") {
      formIsValid = false;
      errorslogin["email"] = "Cannot be empty";
    }

    //Password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Please enter password!";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  componentDidMount() {

  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  render() {
    // let { users } = this.props;
    // let { loading } = users;

    return (
      <>

        {/* <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div> */}

        {/* Whole Structure */}
        <div className="overflow-hidden">
          {/*Header Section */}
          <div className="bg-dark-400 relative">
            {/* <div class="absolute inset-0 z-10"><img src="/dist/img/bg1.jpg" alt="" class="object-cover h-full w-full" /></div> */}
            <div className="min-h-screen flex flex-col justify-center py-12 px-3 sm:px-6 lg:px-8 relative z-20">
              <div className=" mx-auto w-full  max-w-lg">
                <div className="bg-gray-100 border border-gray-800 py-16 shadow rounded-xl px-6 sm:px-12">
                  <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mb-12 text-center text-3xl leading-9 font-bold capitalize text-dark-400 tracking-widest">Login</h2>
                  </div>

                  <form autoComplete="off">
                    <div className="{otpSent?'disableArea':''}">
                      <div className="mt-1  shadow-sm relative">
                        <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!this.state.errorslogin["email"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                          id="email" name="email" placeholder="User name" type="text" onChange={this.inputChange} />
                        {this.state.errorslogin["email"] ?
                          <div className="invalid-feedback text-yellow-600">
                            {this.state.errorslogin["email"]}
                          </div>
                          : null}
                        {/* icon email */}
                        <span className="absolute top-4 left-5 text-gray-500"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg></span>
                      </div>
                    </div>

                    <div className="mt-6">
                      <div className="mt-1 rounded-md shadow-sm relative">
                        <input className={`border-1 px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white text-black rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!this.state.errorslogin["password"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                          id="password" name="password" placeholder="Password" type="password" onChange={this.inputChange} />
                        {this.state.errorslogin["password"] ?
                          <div className="invalid-feedback text-yellow-600">
                            {this.state.errorslogin["password"]}
                          </div>
                          : null}
                        {/* Icon password */}
                        <span className="absolute top-4 left-5 text-gray-500 "><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                        </svg></span>
                      </div>
                    </div>

                    <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                      <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="submit" onClick={this.loginSubmit}>Sign in</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Whole Structure End*/}


      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users
  };
}
export default connect(mapStateToProps)(Login);
