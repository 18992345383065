import {
  welcomeBonusConstants
} from '../_constants';

export function welcomeBonus(state = {}, action) {

  switch (action.type) {
    // case welcomeBonusConstants.DEPOSIT_USER_BALANCE_REQUEST:
    //   return {
    //     ...state
    //   };
    // case welcomeBonusConstants.DEPOSIT_USER_BALANCE_SUCCESS:
    //   return {
    //     ...state,
    //     addUserSuccess: true
    //   };
    // case welcomeBonusConstants.DEPOSIT_USER_BALANCE_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };
    case welcomeBonusConstants.ADD_WELCOME_BONUS_REQUEST:
      return {
        ...state
      };
    case welcomeBonusConstants.ADD_WELCOME_BONUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case welcomeBonusConstants.ADD_WELCOME_BONUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case welcomeBonusConstants.GET_LIST_WELCOME_BONUS_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case welcomeBonusConstants.GET_LIST_WELCOME_BONUS_SUCCESS:
      return {
        ...state,
       
        loading: false,
        items: action.users.getWelcomeBonusList.list,
        total: action.users.getWelcomeBonusList.total
      };
    case welcomeBonusConstants.GET_LIST_WELCOME_BONUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case welcomeBonusConstants.GETALL_WELCOME_BONUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case welcomeBonusConstants.GETALL_WELCOME_BONUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getAllWelcomeBonus,
        total: action.users.getAllWelcomeBonus
      };
    case welcomeBonusConstants.GETALL_WELCOME_BONUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case welcomeBonusConstants.UPDATE_WELCOME_BONUS_REQUEST:
      return {
        ...state
      };
    case welcomeBonusConstants.UPDATE_WELCOME_BONUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case welcomeBonusConstants.UPDATE_WELCOME_BONUS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case welcomeBonusConstants.DELETE_WELCOME_BONUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case welcomeBonusConstants.DELETE_WELCOME_BONUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case welcomeBonusConstants.DELETE_WELCOME_BONUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case welcomeBonusConstants.DISABLE_WELCOME_BONUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case welcomeBonusConstants.DISABLE_WELCOME_BONUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case welcomeBonusConstants.DISABLE_WELCOME_BONUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}