import {
  supportConstants
} from '../_constants';

export function support(state = {}, action) {
  console.log('action1111111111111111111111111', action);

  switch (action.type) {

    case supportConstants.GET_TICKET_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case supportConstants.GET_TICKET_LIST_SUCCESS:
      return {
        ...state,
        isTicketCreated: false,
        loading: false,
        dashboardSupportData: action.support.getTicketList.list,
        items: action.support.getTicketList.list,
        total: action.support.getTicketList.total
      };

    case supportConstants.GET_TICKET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case supportConstants.STATUS_TICKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case supportConstants.STATUS_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        isTicketCreated: true,
      };

    case supportConstants.STATUS_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case supportConstants.MESSAGE_LIST_TICKET_REQUEST:
      return {
        ...state,
        loading: true
      };

    case supportConstants.MESSAGE_LIST_TICKET_SUCCESS:
      return {
        ...state,
        isTicketCreated: false,
        loading: false,
        itemssssss: action.support.MsgListTicketById
      };

    case supportConstants.MESSAGE_LIST_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case supportConstants.UPDATE_TICKET_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case supportConstants.UPDATE_TICKET_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isTicketCreated: true,
      };
    case supportConstants.UPDATE_TICKET_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}