import React, { Component } from 'react';
import { connect } from 'react-redux';
import { supportActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import { MdClose } from "react-icons/md";
import { MdMarkEmailRead } from "react-icons/md";
import { IoIosRefresh, IoMdSend } from "react-icons/io";
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment'

class Supprt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldssupport: {},
      errorssupport: {},
      validDataRes: {},
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      reply: '',
      fieldsAddUser: {},
      fieldMassage: {},
      fieldTicketIdFiled: false,
      // fieldTicketId: '',
      fieldTicketId: null,
    }
  }
  componentDidMount() {
    let temp = {
      "keyWord": "",
      "sortBy": " ",
      "sortOrder": " ",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(supportActions.getTicketList(temp));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.support.isTicketCreated) {
      console.log('nextProps.support.isTicketCreated', nextProps.support.isTicketCreated);
      return {
        ...nextProps,
        fieldsAddUser: {},
        errorsticket: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }



  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected + 1 });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(supportActions.getTicketList(datatemp));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log('event.target', event.target);
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(supportActions.getTicketList(data));
  }

  handleChangeCommentInput = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    this.setState({ fieldsAddUser, errorsAddUser });
  }

  closeTicketSubmit = (data) => {

    let datatemp = {
      "id": data.id,
    }


    let temp = {
      "keyWord": "",
      "sortBy": " ",
      "sortOrder": " ",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    // this.props.dispatch(supportActions.getTicketList(temp));
    confirmAlert({


      title: 'Confirm to resolved ticket?',
      message: `Are you sure to resolved ${data && data.userId && data.userId.fullName ? data.userId.fullName : data && data.userId && data.userId.firstName ? data.userId.firstName : 'User'} ticket?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(supportActions.resolvedTicket(datatemp, temp))
        },
        {
          label: 'No'
        }
      ]
    });
  }



  handleRes = () => {
    let datatemp = {
      ticketId: this.state.fieldTicketId,
      msg: this.state.fieldsAddUser.msg
    }
    console.log("1111111111111111111111111", datatemp);
    this.props.dispatch(supportActions.replyMsgAdminTicket(datatemp))
    this.state({ fieldsAddUser: {} })
  }


  updateTicketStatus = (data) => {

    this.setState({ fieldTicketId: data.id, fieldMassage: data })
    // this.handleRes()


    let resDataLast = {
      ticketId: data.id,
    }

    this.props.dispatch(supportActions.MsgListTicketById(resDataLast))
    // let resDataLast = {
    //   id: data.id,
    // }

    // this.props.dispatch(supportActions.resolvedTicket(resDataLast))

    // console.log("resDataLastresDataLastresDataLast", resDataLast);


  }
  updateTicketStatusRef = (data) => {

    // this.setState({ fieldTicketId: data.id, fieldMassage: data })
    // this.handleRes()


    let resDataLast = {
      ticketId: this.state.fieldTicketId,
    }

    this.props.dispatch(supportActions.MsgListTicketById(resDataLast))

  }

  onClose = (data) => {
    this.setState({ fieldTicketId: null })
  }

  opneHandelRes = (data) => {
    console.log('data)______________', data);
    this.setState({ fieldTicketIdFiled: true, validDataRes: data })
  }

  closeHandelRes = () => {
    this.setState({ fieldTicketIdFiled: false })
  }



  render() {

    let { support } = this.props;
    let { items, total, loading, itemssssss } = support;
    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5  rounded-md 2xl:py-7 2xl:px-6 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">Help & Support</h3>
                    </div>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md bg-white p-1">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-[#fff] rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#000] capitalize whitespace-nowrap">Name & Mobile number</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#000] capitalize whitespace-nowrap">Subject</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#000] capitalize whitespace-nowrap">Reply</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#000] capitalize whitespace-nowrap">Change status</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <p>{element.userId && element.userId.firstName ? element.userId.firstName + " " + element.userId.lastName : element.userId && element.userId.fullName ? element.userId.fullName : '-'}</p>
                                        <p> {element.userId && element.userId.mobNo ? element.userId.mobNo : '-'}</p>
                                        <p> ({element.userId && element.userId.userType && element.userId.userType === 'TUTOR' ? 'Tutor' : element.userId && element.userId.userType && element.userId.userType === 'STUDENT' ? 'Student' : '-'})</p>
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.title && element.title.substring(0, 10) ? (element.title).substring(0, 10) : null}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <MdMarkEmailRead size={24} className='w-14 h-14 text-[#000]' onClick={() => this.updateTicketStatus(element)} />
                                      </td>
                                      <td className="flex px-2 py-3 text-gray-600 whitespace-nowrap">
                                        <span className="flex justify-center pl-1">
                                          {
                                            element && element.isResolve === true ? <div className="pt-1 text-left">
                                              <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 w-28 rounded-full" >Resolved</button>

                                            </div> :
                                              <div className="pt-1 text-left">
                                                <button class="bg-[#FBB00C] hover:bg-[#fb9b0c] text-white font-bold py-1 px-2 w-28 rounded-full" onClick={() => this.closeTicketSubmit(element)}>Pending</button>
                                              </div>
                                          }
                                        </span>
                                      </td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76 ">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>



        {/* <DialogExample /> */}
        {this.state.fieldTicketId ?
          <>
            <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
              <div className="flex flex-col jus bg-white border shadow-md w-96 h-96">
                <div className="flex items-center justify-between p-2 border-b">
                  <div className="font-semibold">
                    {/* <a className="hover:underline" href="#"> User</a> */}
                    <p>Subject : {this.state.fieldMassage && this.state.fieldMassage.title ? this.state.fieldMassage.title : null} </p>
                  </div>
                  <div className='flex items-center space-x-2'>

                    <button className="rounded-full bg-indigo-100 p-1 hover:bg-indigo-50" type="button">
                      <IoIosRefresh onClick={this.updateTicketStatusRef} />
                    </button>
                    <button className="rounded-full bg-indigo-100 p-1 hover:bg-indigo-50" type="button">
                      <MdClose onClick={this.onClose} />
                    </button>
                  </div>
                </div>
                <div className="flex-1 px-4 py-4 overflow-y-auto">

                  < div className="flex items-center mb-4">
                    <div className="flex flex-col items-center flex-none mr-4 space-y-1">
                      <a href="#" className="block text-xs hover:underline">{this.state.fieldMassage && this.state.fieldMassage.userId.fullName ? this.state.fieldMassage.userId.fullName : this.state.fieldMassage && this.state.fieldMassage.userId.firstName ? this.state.fieldMassage.userId.firstName : "client"}</a>
                    </div>
                    <div className="relative flex-1 p-2 mb-2 text-white bg-indigo-400 rounded-lg">
                      <div className=' flex justify-between'>
                        <p>{this.state.fieldMassage && this.state.fieldMassage.msg ? this.state.fieldMassage.msg : null}
                        </p>
                        <p className='pt-6 text-xs flex justify-end items-end whitespace-nowrap'>{moment(new Date(parseInt(this.state.fieldMassage.createdAt))).utcOffset("+05:30").format("D MMM HH:MM")}</p>
                      </div>
                      <div className="absolute left-0 w-2 h-2 transform rotate-45 -translate-x-1/2 bg-indigo-400 top-1/2" />
                    </div>
                  </div>

                  {itemssssss && itemssssss.length > 0 ?
                    itemssssss.map((element, index) =>

                      <>

                        {element && element.isAdmin && element.isAdmin == 1 ?
                          < div className="flex items-center mb-4">
                            <div className="flex flex-col items-center flex-none mr-4 space-y-1">
                              <a href="#" className="block text-xs hover:underline">{element && element.userId && element.userId.fullName ? element.userId.fullName : element && element.userId && element.userId.firstName ? element.userId.firstName : "client"}</a>
                            </div>
                            <div className="relative flex-1 p-2 mb-2 text-white bg-indigo-400 rounded-lg">
                              <div className=' flex justify-between'>
                                <p>{element && element.msg ? element.msg : null}
                                </p>
                                <p className='pt-6 text-xs flex justify-end items-end whitespace-nowrap'>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("D MMM HH:MM")}</p>
                              </div>
                              <div className="absolute left-0 w-2 h-2 transform rotate-45 -translate-x-1/2 bg-indigo-400 top-1/2" />
                            </div>
                          </div>
                          : null}

                        {element && element.isAdmin && element.isAdmin == 0 ?
                          < div className="flex flex-row-reverse items-center mb-4" >
                            <div className="flex flex-col items-center flex-none ml-4 space-y-1">
                              <a href="#" className="block text-xs hover:underline">Admin</a>
                            </div>
                            <div className="relative flex-1 p-2 mb-2 text-gray-800 bg-indigo-100 rounded-lg">
                              {/* <div>{element && element.msg ? element.msg : null}</div> */}
                              <div className=' flex justify-between'>
                                <p>{element && element.msg ? element.msg : null}
                                </p>
                                <p className='pt-6 text-xs flex justify-end items-end whitespace-nowrap'>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("D MMM HH:MM")}</p>
                              </div>
                              <div className="absolute right-0 w-2 h-2 transform rotate-45 translate-x-1/2 bg-indigo-100 top-1/2" />
                            </div>
                          </div> : null}
                      </>
                    )
                    : null
                  }
                </div>
                <div className="flex items-center p-2 border-t">

                  <div className="w-full mx-2">
                    <input className="w-full border border-gray-200 rounded-full px-2 py-1" id='msg' name='msg' type="text" placeholder="message" value={this.state.fieldsAddUser && this.state.fieldsAddUser['msg'] ? this.state.fieldsAddUser['msg'] : ''}

                      onChange={this.handleChangeCommentInput} />
                  </div>

                  <button className="inline-flex p-2 rounded-full hover:bg-indigo-50 bg-indigo-100" type="button">
                    <IoMdSend onClick={() => this.handleRes()} />
                  </button>
                  <div>
                  </div>
                </div>
              </div>
            </div>
          </>
          :
          null
        }
        {this.state.fieldTicketIdFiled ?
          <>
            <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
              <div className="flex flex-col jus bg-white border shadow-md w-96 h-96">
                <div className="flex items-center justify-between p-2 border-b">
                  {/* <div className="font-semibold">
                    <a className="hover:underline" href="#"> User</a>
                  </div> */}
                  <div className='flex items-center space-x-2'>
                    {/* <a className="rounded-full bg-indigo-100 p-1 hover:bg-indigo-50" href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                      </svg>
                    </a> */}
                    <button className="rounded-full bg-indigo-100 p-1 hover:bg-indigo-50" type="button">
                      <MdClose onClick={this.closeHandelRes} />
                    </button>
                  </div>
                </div>
                <div className="flex-1 px-4 py-4 overflow-y-auto">




                  < div className="flex flex-row-reverse items-center mb-4" >
                    {/* <div className="flex flex-col items-center flex-none ml-4 space-y-1">
                              <a href="#" className="block text-xs hover:underline">Admin</a>
                            </div> */}
                    {/* <div className="relative flex-1 p-2 mb-2 text-gray-800 bg-indigo-100 rounded-lg"> */}
                    <div className="relative flex-1 p-2 mb-2 text-gray-800 ">{this.state.validDataRes && this.state.validDataRes.msg ? this.state.validDataRes.msg : null}</div>
                    {/* <div className="absolute right-0 w-2 h-2 transform rotate-45 translate-x-1/2 bg-indigo-100 top-1/2" /> */}
                    {/* </div> */}
                  </div>

                </div>

              </div>
            </div>
          </>
          :
          null
        }



      </>

    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, support } = state;
  return {
    users,
    support,
    authentication
  };
}
export default connect(mapStateToProps)(Supprt);
