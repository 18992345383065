export const cityConstants = {

    GETALL_CITY_REQUEST: 'GETALL_CITY_REQUEST',
    GETALL_CITY_SUCCESS: 'GETALL_CITY_SUCCESS',
    GETALL_CITY_FAILURE: 'GETALL_CITY_FAILURE',

    GET_LIST_CITY_REQUEST: 'GET_LIST_CITY_REQUEST',
    GET_LIST_CITY_SUCCESS: 'GET_LIST_CITY_SUCCESS',
    GET_LIST_CITY_FAILURE: 'GET_LIST_CITY_FAILURE',

    GET_ALLENDANCE_MONTH_DATA_REQUEST: 'GET_ALLENDANCE_MONTH_DATA_REQUEST',
    GET_ALLENDANCE_MONTH_DATA_SUCCESS: 'GET_ALLENDANCE_MONTH_DATA_SUCCESS',
    GET_ALLENDANCE_MONTH_DATA_FAILURE: 'GET_ALLENDANCE_MONTH_DATA_FAILURE',

    ADD_CITY_REQUEST: 'ADD_CITY_REQUEST',
    ADD_CITY_SUCCESS: 'ADD_CITY_SUCCESS',
    ADD_CITY_FAILURE: 'ADD_CITY_FAILURE',

    DELETE_CITY_REQUEST: 'DELETE_CITY_REQUEST',
    DELETE_CITY_SUCCESS: 'DELETE_CITY_SUCCESS',
    DELETE_CITY_FAILURE: 'DELETE_CITY_FAILURE',

    UPDATE_CITY_REQUEST: 'UPDATE_CITY_REQUEST',
    UPDATE_CITY_SUCCESS: 'UPDATE_CITY_SUCCESS',
    UPDATE_CITY_FAILURE: 'UPDATE_CITY_FAILURE',

    DISABLE_CITY_REQUEST: 'DISABLE_CITY_REQUEST',
    DISABLE_CITY_SUCCESS: 'DISABLE_CITY_SUCCESS',
    DISABLE_CITY_FAILURE: 'DISABLE_CITY_FAILURE',

    GETALL_CLASS_REQUEST: 'GETALL_CLASS_REQUEST',
    GETALL_CLASS_SUCCESS: 'GETALL_CLASS_SUCCESS',
    GETALL_CLASS_FAILURE: 'GETALL_CLASS_FAILURE',

    GET_LIST_CLASS_REQUEST: 'GET_LIST_CLASS_REQUEST',
    GET_LIST_CLASS_SUCCESS: 'GET_LIST_CLASS_SUCCESS',
    GET_LIST_CLASS_FAILURE: 'GET_LIST_CLASS_FAILURE',

    ADD_CLASS_REQUEST: 'ADD_CLASS_REQUEST',
    ADD_CLASS_SUCCESS: 'ADD_CLASS_SUCCESS',
    ADD_CLASS_FAILURE: 'ADD_CLASS_FAILURE',

    DELETE_CLASS_REQUEST: 'DELETE_CLASS_REQUEST',
    DELETE_CLASS_SUCCESS: 'DELETE_CLASS_SUCCESS',
    DELETE_CLASS_FAILURE: 'DELETE_CLASS_FAILURE',

    UPDATE_CLASS_REQUEST: 'UPDATE_CLASS_REQUEST',
    UPDATE_CLASS_SUCCESS: 'UPDATE_CLASS_SUCCESS',
    UPDATE_CLASS_FAILURE: 'UPDATE_CLASS_FAILURE',

    DISABLE_CLASS_REQUEST: 'DISABLE_CLASS_REQUEST',
    DISABLE_CLASS_SUCCESS: 'DISABLE_CLASS_SUCCESS',
    DISABLE_CLASS_FAILURE: 'DISABLE_CLASS_FAILURE',

    GETALL_BOARD_REQUEST: 'GETALL_BOARD_REQUEST',
    GETALL_BOARD_SUCCESS: 'GETALL_BOARD_SUCCESS',
    GETALL_BOARD_FAILURE: 'GETALL_BOARD_FAILURE',

    GET_LIST_BOARD_REQUEST: 'GET_LIST_BOARD_REQUEST',
    GET_LIST_BOARD_SUCCESS: 'GET_LIST_BOARD_SUCCESS',
    GET_LIST_BOARD_FAILURE: 'GET_LIST_BOARD_FAILURE',

    ADD_BOARD_REQUEST: 'ADD_BOARD_REQUEST',
    ADD_BOARD_SUCCESS: 'ADD_BOARD_SUCCESS',
    ADD_BOARD_FAILURE: 'ADD_BOARD_FAILURE',

    DELETE_BOARD_REQUEST: 'DELETE_BOARD_REQUEST',
    DELETE_BOARD_SUCCESS: 'DELETE_BOARD_SUCCESS',
    DELETE_BOARD_FAILURE: 'DELETE_BOARD_FAILURE',

    UPDATE_BOARD_REQUEST: 'UPDATE_BOARD_REQUEST',
    UPDATE_BOARD_SUCCESS: 'UPDATE_BOARD_SUCCESS',
    UPDATE_BOARD_FAILURE: 'UPDATE_BOARD_FAILURE',

    DISABLE_BOARD_REQUEST: 'DISABLE_BOARD_REQUEST',
    DISABLE_BOARD_SUCCESS: 'DISABLE_BOARD_SUCCESS',
    DISABLE_BOARD_FAILURE: 'DISABLE_BOARD_FAILURE',

};
