import React from "react";
import { CgLogIn } from 'react-icons/cg';

export default function CreatePackageModal(props) {

  let { handlePackageHideModal, inputChange,
    PinCodeCreateModal,
    errorsBanner,
    PinCodeubmit,
    handleFile,
  } = props;
  return (

    <div className={PinCodeCreateModal ? "fixed w-full top-0 left-0 h-full inset-0 z-50 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto mt-2">
        {/*modal header*/}
        <div className="flex items-center justify-between p-6 py-2 bg-[#FBB00C] border-b">
          <p className="text-[16px] font-bold text-white capitalize">Add Bnaner</p>
          <div className="rounded-full cursor-pointer modal-close bg-white p-1">
            <svg onClick={() => handlePackageHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>

        <div className="p-4">
          <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">

            <div className="w-full ">
              <div className="relative mt-1 shadow-sm">
                <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">User Type</label>
                <select className="px-3 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 border border-gray-400" onChange={inputChange}
                  id="name"
                  // value={fieldsUpdatePassword && fieldsUpdatePassword.name ? fieldsUpdatePassword.name : ''}
                  name="name">
                  <option value="">Please Choose User Type&hellip;</option>
                  <option value="STUDENT">Student</option>
                  <option value="TUTOR">Tutor</option>
                </select>
              </div>
            </div>



            <div className="w-full">
              <label class="block mb-2 text-sm font-bold text-gray-900" for="username">Banner Name: </label>
              <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsBanner && !errorsBanner["image"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                id="image" name="image" placeholder="Upload banner image" type="file" onChange={handleFile} />
              {errorsBanner && errorsBanner["image"] ?
                <div className="text-xs text-red-500 invalid-feedback">
                  {errorsBanner["image"]}
                </div>
                : null}
            </div>
            <div className="flex justify-end py-0 space-x-4">
              <button className="bg-[#FBB00C]  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center font-bold" type="button"
                onClick={PinCodeubmit}>Submit
                <CgLogIn size={18} className="ml-2" />
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>




  );
}
