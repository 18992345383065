import React, { Component } from 'react';
import { connect } from 'react-redux';
import { coinActions, userActions, teachcourseActions, cityActions, tutionActions, alertActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import ViewCoinModal from "./components/ViewCoinModal/ViewCoinModal";
import CreateCoinModal from "./components/CreateCoinModal/CreateCoinModal";
import UpdateCoinModal from "./components/UpdateCoinModal/UpdateCoinModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import CreateAddUserModal from '../DemoClassPending/components/CreateAddUserModal/CreateAddUserModal';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FaArrowCircleRight, FaPlusCircle } from "react-icons/fa";
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';

class Coin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fieldskyc: {},
      // errorskyc: {},
      fieldsCoin: {},
      errorsCoin: {},
      studentData: {},
      fieldsAddUser: {},
      errorsAddUser: {},
      fieldsUpdateCoin: {},
      errorsUpdateCoin: {},
      viewRowData: {},
      endTimeFeild: null,
      startTimeField: null,
      allDataValue: {},
      fieldsCoinRes: {},
      selectCourseId: [],
      addUserCreateModal: false,
      coinCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCoinModal: false,
      loginToThisAccountModal: false,
      approveStatus: false,
      moreDetailsHandel: false,
      elementValue: '',
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "status": "0",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));
    let data2 = {
      "userType": "STUDENT",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActiveStudent(data2));



    this.props.dispatch(teachcourseActions.getTeachCourseList());
    this.props.dispatch(cityActions.getAllClass());
    let data1 = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(data1));
    this.props.dispatch(cityActions.getBoardList(data1));
    // this.props.dispatch(tutionActions.getAllPinCode1(data1));
    this.props.dispatch(cityActions.getAllCity(data1));


    // this.props.dispatch(coinActions.createSport(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.teachcourse.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        coinCreateModal: false,
        fieldsCoinRes: {},
        errorsCoin: {},
        fieldsCoin: {},
        startTimeField: null,
        endTimeFeild: null

      }
    }
    else if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        addUserCreateModal: false,
        studentData: {},
        fieldsAddUser: {},
        errorsAddUser: {},
        startTimeField: null,
        endTimeFeild: null
      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "status": "0",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "status": "0",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));
  }
  disableCoin = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.name} coin?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.updateTeachCourseStatus(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  inputAddUserChange = (e) => {
    e.preventDefault();
    // if (e.target.value.mobNo > 999999999) {

    // }
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    // console.log("inputAddUserChange ::::: ", value);
    errorsAddUser[name] = "";

    if (fieldsAddUser[name] === "mobNo" && fieldsAddUser.value.length !== 10) {
      // setErrorsAddUser((prevErrors) => ({
      //   ...prevErrors,
      //   [name]: "Mobile number must be 10 digits.",
      // }));
      errorsAddUser[name] = "Mobile number must be 10 digits."
    }

    this.setState({ fieldsAddUser, errorsAddUser });
    if (this.state.fieldsAddUser.cityId) {
      let data = {
        cityId: [this.state.fieldsAddUser.cityId]
      }
      this.props.dispatch(tutionActions.getAllPinCode1(data));
    }



  }
  handleAddUserHideModal = () => {
    this.props.history.push('/app/pendingdemoclass')
    // this.setState({ addUserCreateModal: false, fieldsAddUser: {}, startTimeField: '', endTimeFeild: '' });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ addUserCreateModal: true });
  }


  handleValidationDemoRequest = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //Email
    if (!fieldsAddUser["firstName"] || fieldsAddUser["firstName"] === "") {
      formIsValid = false;
      errorsAddUser["firstName"] = "Cannot be empty first name";
    }
    const expectedLength = 10;

    if (!fieldsAddUser["mobNo"] || fieldsAddUser["mobNo"] === "") {
      formIsValid = false;
      errorsAddUser["mobNo"] = "Mobile number cannot be empty";
    } else if (!/^\d{10}$/.test(fieldsAddUser["mobNo"])) {
      formIsValid = false;
      errorsAddUser["mobNo"] = "Mobile number must be a 10-digit number";
    }

    if (!fieldsAddUser["demoDate"] || fieldsAddUser["demoDate"] === "") {
      formIsValid = false;
      errorsAddUser["demoDate"] = "Cannot be empty demo date";
    }

    if (!fieldsAddUser["requiredGender"] || fieldsAddUser["requiredGender"] === "") {
      formIsValid = false;
      errorsAddUser["requiredGender"] = "Cannot be empty gender";
    }

    if (!fieldsAddUser["cityId"] || fieldsAddUser["cityId"] === "") {
      formIsValid = false;
      errorsAddUser["cityId"] = "Cannot be empty preferred city";
    }

    if (!fieldsAddUser["board"] || fieldsAddUser["board"] === "") {
      formIsValid = false;
      errorsAddUser["board"] = "Cannot be empty preferred board";
    }

    if (!fieldsAddUser["class"] || fieldsAddUser["class"] === "") {
      formIsValid = false;
      errorsAddUser["class"] = "Cannot be empty preferred class";
    }


    if (!fieldsAddUser["pinCodeId"] || fieldsAddUser["pinCodeId"] === "") {
      formIsValid = false;
      errorsAddUser["pinCodeId"] = "Cannot be empty preferred pincode";
    }

    if (!fieldsAddUser["address"] || fieldsAddUser["address"] === "") {
      formIsValid = false;
      errorsAddUser["address"] = "Cannot be empty preferred address";
    }

    if (!fieldsAddUser["teachingMode"] || fieldsAddUser["teachingMode"] === "") {
      formIsValid = false;
      errorsAddUser["teachingMode"] = "Cannot be empty preferred teaching mode";
    }

    if (!fieldsAddUser["landmark"] || fieldsAddUser["landmark"] === "") {
      formIsValid = false;
      errorsAddUser["landmark"] = "Cannot be empty preferred landmark";
    }


    console.log('errorsAddUser__________________________', errorsAddUser);

    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  addUserSubmit = () => {
    if (this.handleValidationDemoRequest()) {
      let dataRes = {
        "status": "0",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      {
        let reqData = {
          "firstName": this.state.fieldsAddUser.firstName,
          "mobNo": this.state.fieldsAddUser.mobNo,
          "date": this.state.fieldsAddUser.demoDate,
          // "requiredGender": this.state.studentData['gender'],
          "requiredGender": this.state.fieldsAddUser.requiredGender,
          "cityId": this.state.fieldsAddUser.cityId,
          "board": this.state.fieldsAddUser.board,
          "class": this.state.fieldsAddUser.class,
          "course": this.state.studentData['Subject'],
          "startTime": this.state.startTimeField,
          "endTime": this.state.endTimeFeild,
          "pinCodeId": this.state.fieldsAddUser.pinCodeId,
          "address": this.state.fieldsAddUser.address,
          "teachingMode": this.state.fieldsAddUser.teachingMode,
          "landmark": this.state.fieldsAddUser.landmark,
        }
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);
        this.props.dispatch(userActions.addStudent(reqData, dataRes));
      }
    }
  }

  // disableCoinCard = (data) => {
  //   let datatemp = {
  //     "id": data.id,
  //   }
  //   let paginationdata = {
  //     "keyWord": "",
  //     "pageNo": 1,
  //     "size": this.state.size
  //   }
  //   confirmAlert({


  //     title: 'Confirm to disable Card?',
  //     message: `Are you sure to disable ${data.name} Coin Card?`,
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => this.props.dispatch(coinActions.disableCoinCard(datatemp, paginationdata))
  //       },
  //       {
  //         label: 'No'
  //       }
  //     ]
  //   });
  // }

  deleteCoin = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} coin?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.deleteTeachCourse(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenCreateModalMoreDetails = (data) => {

    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }
  handleOpenCoinUpdateModal = (data) => {
    this.setState({ UpdateCoinModal: true, fieldsUpdateCoin: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(coinActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleCoinHideModal = () => {
    this.setState({ coinCreateModal: false, fieldsCoin: {}, errorsCoin: {} });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleCoinUpdateHideModal = () => {
    this.setState({ UpdateCoinModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleHideCoinUpdateModal = () => {
    this.setState({ UpdateCoinModal: false });
  }
  handleOpenCreateModal1 = (data) => {
    // console.log("clicked");
    this.setState({ coinCreateModal: true, fieldsCoin: data });
  }
  inputCoinChange = (e) => {
    e.preventDefault();
    console.log('e_________________________', e.target.value);
    let { name, value } = e.target;
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = this.state.errorsCoin;
    fieldsCoin[name] = value;
    console.log(name, value);
    errorsCoin[name] = "";
    this.setState({ fieldsCoin, errorsCoin });
  }
  inputChangeUpdateCoin = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = this.state.errorsUpdateCoin;
    fieldsUpdateCoin[name] = value;
    errorsUpdateCoin[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateCoin, errorsUpdateCoin });
  }

  createCoinSubmit = () => {
    let courseData = this.state && this.state.selectCourseId && this.state.selectCourseId.map(ele => ele.id)
    let courseDataRes = this.state && this.state.fieldsCoin && this.state.fieldsCoin.course && this.state.fieldsCoin.course.map(ele => ele.id)
    let reqData = {
      "id": this.state.fieldsCoin.id,
      "class": this.state.fieldsCoin && this.state.fieldsCoin.classId ? this.state.fieldsCoin.classId : this.state.fieldsCoin.class.id,
      "course": courseData && courseData.length && courseData.length > 0 ? courseData : courseDataRes,
      "startTime": this.state.startTimeField ? this.state.startTimeField : this.state.fieldsCoin && this.state.fieldsCoin.startTime ? this.state.fieldsCoin.startTime : '',
      "endTime": this.state.endTimeFeild ? this.state.endTimeFeild : this.state.fieldsCoin && this.state.fieldsCoin.endTime ? this.state.fieldsCoin.endTime : '',
      "teachingMode": this.state.fieldsCoin && this.state.fieldsCoin.teachingMode ? this.state.fieldsCoin.teachingMode : '',
      "requiredGender": this.state.fieldsCoin && this.state.fieldsCoin.requiredGender ? this.state.fieldsCoin.requiredGender : '',
    }
    console.log('reqData________________reqData', reqData);
    let paginationData = {
      "status": "0",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    this.props.dispatch(teachcourseActions.updateDemoRequest(reqData, paginationData));
    // }

  }
  // handleTimeChange = (newTime) => {
  //   // setTime(newTime);

  // };

  // navigate = (data) => {

  //   this.props.history.push('/app/series/' + data.sportId);
  // }



  // Convert 24-hour time to 12-hour time format (AM/PM)
  startTimeFormatTimeToAMPM = (time) => {
    let { name, value } = time.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log('time________________', time.target.value);
    const [hours, minutes] = time.target.value.split(':');
    console.log('hours________________', hours, minutes);
    const formattedHours = parseInt(hours, 10) % 12 || 12;
    console.log('formattedHours______________', formattedHours);
    const ampm = parseInt(hours, 10) < 12 ? 'AM' : 'PM';
    console.log('ampm___________________', ampm);
    let resData = `${formattedHours}:${minutes} ${ampm}`;
    console.log('resData_________________', resData);
    this.setState({ startTimeField: resData, fieldsAddUser: fieldsAddUser, errorsAddUser: errorsAddUser });
  };
  endTimeFormatTimeToAMPM = (time) => {
    let { name, value } = time.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log('time________________', time.target.value);
    const [hours, minutes] = time.target.value.split(':');
    console.log('hours________________', hours, minutes);
    const formattedHours = parseInt(hours, 10) % 12 || 12;
    console.log('formattedHours______________', formattedHours);
    const ampm = parseInt(hours, 10) < 12 ? 'AM' : 'PM';
    console.log('ampm___________________', ampm);
    let resData = `${formattedHours}:${minutes} ${ampm}`;
    console.log('resData_________________', resData);
    this.setState({ endTimeFeild: resData, fieldsAddUser: fieldsAddUser, errorsAddUser: errorsAddUser });
  };

  updateCoinSubmit = () => {

    // let { users } = this.props;
    // let { filesDetails } = users;
    if (this.handleValidationUpdateCoin()) {
      let reqData = {
        "id": this.state.fieldsUpdateCoin.id,
        "name": this.state.fieldsUpdateCoin.name,
        "shortName": this.state.fieldsUpdateCoin.shortName,

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      console.log("updateCoinSubmit_updateCoinSubmit:1:2:3:", reqData);
      this.props.dispatch(teachcourseActions.updateTeachCourse(reqData, paginationData));
    }

  }
  handleValidationUpdateCoin = () => {
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = {};
    let formIsValid = true;

    //name
    if (!fieldsUpdateCoin["name"] || fieldsUpdateCoin["name"] === "") {
      formIsValid = false;
      errorsUpdateCoin["name"] = "Cannot be empty";
    }

    //ticker
    if (!fieldsUpdateCoin["shortName"] || fieldsUpdateCoin["shortName"] === "") {
      formIsValid = false;
      errorsUpdateCoin["shortName"] = "Cannot be empty";
    }
    //m_cap
    // if (!fieldsUpdateCoin["m_cap"] || fieldsUpdateCoin["m_cap"] === "") {
    //   formIsValid = false;
    //   errorsUpdateCoin["m_cap"] = "Cannot be empty m_cap";
    // }

    //volume
    // if (!fieldsUpdateCoin["volume"] || fieldsUpdateCoin["volume"] === "") {
    //   formIsValid = false;
    //   errorsUpdateCoin["volume"] = "Cannot be empty volume";
    // }

    console.log("errorsUpdateCoinerrorsUpdateCoin_errorsUpdateCoinerrorsUpdateCoin:", errorsUpdateCoin);

    this.setState({ errorsUpdateCoin: errorsUpdateCoin });
    return formIsValid;
  }
  handleValidationCoin = () => {
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = {};
    let formIsValid = true;

    //Email
    if (!fieldsCoin["classId"] || fieldsCoin["classId"] === "") {
      formIsValid = false;
      errorsCoin["classId"] = "Cannot be empty classId";
    }




    this.setState({ errorsCoin: errorsCoin });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  changeStatusDemoClass = (data, status) => {
    let paginationData = {
      "status": "0",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let tempdata = {
      "id": data.id,
      "status": status,
    }
    confirmAlert({
      title: 'Confirm to reject?',
      message: `Are you sure want to reject  ${data && data.studentId && data.studentId.firstName ? data.studentId.firstName : "-"}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.changeStatusDemoClass(tempdata, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  duplicateData = (data, status) => {
    let paginationData = {
      "status": "0",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let tempdata = {
      "id": data.id
    }
    confirmAlert({
      title: 'Confirm to make copy?',
      message: `Are you sure to want make copy`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.makeDuplicateDemoClass(tempdata, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  duplicateData1 = (data, status) => {
    if (data.course.length == 1 || data.course.length == 0) {
      this.props.dispatch(alertActions.error(`You can't duplicate data because you only have one subject.`))
    }
  }

  handleValidationUpdateDemoRequesAccept = () => {
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = {};
    let formIsValid = true;

    //inHandFees
    if (!fieldsCoin["inHandFees"] || fieldsCoin["inHandFees"] === "") {
      formIsValid = false;
      errorsCoin["inHandFees"] = "Cannot be empty in hand fees";
    }

    //numberOfClasses
    if (!fieldsCoin["numberOfClasses"] || fieldsCoin["numberOfClasses"] === "") {
      formIsValid = false;
      errorsCoin["numberOfClasses"] = "Cannot be empty number of class";
    }

    //teachingLocation
    if (!fieldsCoin["teachingLocation"] || fieldsCoin["teachingLocation"] === "") {
      formIsValid = false;
      errorsCoin["teachingLocation"] = "Cannot be empty teaching location";
    }

    console.log("errorsCoinerrorsCoin_errorsCoinerrorsCoin:", errorsCoin);

    this.setState({ errorsCoin: errorsCoin });
    return formIsValid;
  }


  changeStatusDemoClassApprove = () => {
    if (this.handleValidationUpdateDemoRequesAccept()) {
      let paginationData = {
        "status": "0",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      let tempdata = {
        "id": this.state.elementValue,
        "status": 1,
        "inHandFees": this.state.fieldsCoin.inHandFees,
        "numberOfClasses": this.state.fieldsCoin.numberOfClasses,
        "teachingLocation": this.state.fieldsCoin.teachingLocation,
        "classReference": this.state.fieldsCoin.classReference,
        "remark": this.state.fieldsCoin.remark,

      }
      this.props.dispatch(teachcourseActions.changeStatusDemoClass(tempdata, paginationData))
      this.approveClodeStatusHandel()
    }
  }
  approveOpenStatusHandel = (data) => {
    this.setState({ approveStatus: true, elementValue: data.id })
  }
  approveClodeStatusHandel = () => {
    this.setState({ approveStatus: false, fieldsCoin: {} })
  }
  moreDetailsOpenModul = (data) => {
    this.setState({ moreDetailsHandel: true, allDataValue: data })
  }
  moreDetailsCloseModul = () => {
    this.setState({ moreDetailsHandel: false, allDataValue: {} })
  }
  classReferenceSubmit = () => {
    let data = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "cityId": this.state.studentData['cityId'] && this.state.studentData['cityId'].length > 0 ? this.state.studentData['cityId'] : null,
      "requiredGender": this.state.fieldsCoin['requiredGender'],
      "status": "0",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    console.log('data_________________data', data);
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));


  }

  resetDataRes = (data) => {
    this.props.history.push('/app/pendingdemoclass')
  }

  handleChangeInputInternal = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["studentId"] = output;
    this.setState({ studentData });

  };

  handleChangeInputCityId = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["cityId"] = output;
    this.setState({ studentData });

  };

  handleChangeInputCouresId = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["Subject"] = output;
    this.setState({ studentData });

  };

  handleChangeInputGender = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["gender"] = output;
    this.setState({ studentData });

  };

  handleChangeInputTuitionMode = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["tuitionMode"] = output;
    this.setState({ studentData });

  };

  handleChangeInputPinCode = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["PinCode"] = output;
    this.setState({ studentData });

  };


  handleChangeInputSubject = (val) => {

    this.setState({ selectCourseId: val })

    const { studentData } = this.state;
    if (val.option) {
      studentData.course.push(val.option.id);
      this.setState({ studentData });
    }
    else if (val.removedValue) {
      studentData.course.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          studentData.course.splice(delIndex, 1);
        }
      })

      this.setState({
        studentData,
      });
    }

  };


  render() {

    let { teachcourse, city, tution, users } = this.props;
    // let { total, loading, items } = coin;
    let { loading, items, itemsTeachTotal } = teachcourse;
    let { getTeachCourseList } = teachcourse;
    let { getAllPinCode1 } = tution;
    let { getAllClass, boardItems, getAllCity } = city;
    let { getUserStudentList } = users;
    console.log('getAllCity__________________________getAllCity', getTeachCourseList);
    let NameRes = getUserStudentList && getUserStudentList.length ? getUserStudentList.map((ele, index) => ({ value: ele.id, label: ele.firstName + " " + ele.firstName + " " + ele.mobNo })) : null
    let cityName = getAllCity && getAllCity.length ? getAllCity.map((ele, index) => ({ value: ele.id, label: ele.name })) : null

    console.log("RENDER___teachcourseteachcourse", items);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="text-lg font-semibold leading-6 tracking-wider text-center capitalize md:text-2xl md:leading-9 text-dark-400">Demo Request ({itemsTeachTotal})</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600">
                      </div>
                    </div>
                    <button className="flex items-center justify-end py-1  font-bold text-black bg-white border border-black rounded-full  px-2" onClick={() => this.handleOpenCreateModal()}> <span className='pr-1'><FaPlusCircle /></span> Add Demo Request</button>
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">


                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#fff] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                  <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                    <label htmlFor="resourceList" style={{ color: 'black' }}>name or Number</label>

                                    <Select2
                                      width="full"
                                      isMulti
                                      name="internalId"
                                      id="internalId"
                                      onChange={this.handleChangeInputInternal}
                                      options={NameRes}
                                      className="basic-multi-select width: 'full'"
                                      classNamePrefix="Activities"
                                    />
                                  </Grid>
                                </th>
                                <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                </th>
                                <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                  <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                    <label htmlFor="resourceList" style={{ color: 'black' }}>City</label>

                                    <Select2
                                      width="full"
                                      isMulti
                                      name="internalId"
                                      id="internalId"
                                      onChange={this.handleChangeInputCityId}
                                      options={cityName}
                                      className="basic-multi-select width: 'full'"
                                      classNamePrefix="Activities"
                                    />
                                  </Grid>
                                </th>
                                <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                </th>


                                <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                  <label className='text-transparent'>City</label>
                                  <div className='flex items-center'>
                                    <select class="bg-[#FBB00C]  py-1 text-sm shadow-sm w-full font-bold tracking-wider text-white rounded-full hover:shadow-lg" onClick={this.inputCoinChange}
                                      id='requiredGender' name='requiredGender'>
                                      <option className='bg-white text-black' value="">Select Preferred Gender</option>
                                      <option className='bg-white text-black' value="Male">Male</option>
                                      <option className='bg-white text-black' value="Female">Female</option>
                                      <option className='bg-white text-black' value="No Preference">No Preference</option>
                                    </select>
                                  </div>
                                </th>

                                <th className='whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider'>
                                  <label className='text-transparent'>City</label>
                                  <div className='flex'>
                                    <span> <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.classReferenceSubmit()}> Search </button></span>
                                    <span> <button className="bg-[#25BF63] hover:bg-[#25BF63] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.resetDataRes()}> Reset </button></span>
                                  </div>
                                </th>

                              </tr>
                            </thead>
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">Name & Mobile Number </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Demo Date </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Demo Address </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> City</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Class & Subject</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Preferred Gender</th>

                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Action</th>

                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Duplicate</th>
                              </tr>
                            </thead>

                            {/* Table Row Section */}
                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap  text-sm text-gray-600 flex items-center space-x-2 pt-10">
                                        <div className='w-12 h-12'>
                                          <img
                                            class="w-12 h-12 rounded-full"
                                            src={element && element.studentId && element.studentId.imageLinkUrl ? element.studentId.imageLinkUrl : element && element.studentId && element.studentId.gender && element.studentId.gender == "Male" ? "images/boyImageLogo.png" : element && element.studentId && element.studentId.gender && element.studentId.gender == "Female" ? "images/girlImageLogo.png" : "images/male-female.png"}
                                            alt="Not found"
                                          />
                                        </div>
                                        <div>
                                          <p> {element && element.studentId && element.studentId.firstName ? element.studentId.firstName : null} {element && element.studentId && element.studentId.lastName ? element.studentId.lastName : null}</p>
                                          <p>{element && element.studentId && element.studentId.mobNo ? element.studentId.mobNo : "-"}</p>
                                          <div className='flex items-center justify-end' onClick={() => this.moreDetailsOpenModul(element)}><span className="font-bold">More</span>  <span className='text-[#FBB00C] pl-1'><FaArrowCircleRight size={16} /> </span></div>
                                        </div>
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        {element && element.date ? element.date : "NA"}
                                      </td>
                                      {/* <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600 text-center ">
                                        {element && element.fullAddress ? element.fullAddress : "NA"}/{element && element.landmark ? element.landmark : "NA"} </td> */}
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600 text-center" style={{ maxWidth: "200px" }}>
                                        <p
                                          title={element && element.fullAddress}
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          {element && element.fullAddress ? element.fullAddress.slice(" ") : null}
                                        </p>
                                        <p
                                          title={element && element.landmark}
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          {element && element.landmark ? element.landmark.slice(" ") : null}
                                        </p>
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        {element && element.cityId && element.cityId.name ? element.cityId.name : "NA"}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        {element && element.class && element.class.name ? element.class.name : "-"}/{element && element.course[0] && element.course[0].name ? element.course[0].name : "-"}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        {element && element.requiredGender ? element.requiredGender : "NA"}
                                      </td>
                                      <td className="px-6 py-3 text-center text-sm text-gray-600 space-y-2">
                                        <div>
                                          <span class="inline-block pl-1">
                                            <button class="bg-green-600 px-4 py-1.5 text-sm shadow-sm font-bold tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-green-700" onClick={() => this.handleOpenCreateModal1(element)}>Update</button>
                                          </span>
                                        </div>
                                        <div>
                                          <span class="inline-block pl-1">
                                            <button class="bg-green-600 px-4 py-1.5 text-sm shadow-sm font-bold tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-green-700" onClick={() => this.approveOpenStatusHandel(element, 1)}>Accept</button>
                                          </span>
                                        </div>
                                        <div>
                                          <span className="inline-block pl-1">
                                            <button class="bg-red-600 px-4 py-1.5 text-sm shadow-sm font-bold tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-red-700" onClick={() => this.changeStatusDemoClass(element, 3)}>Reject </button>
                                          </span>
                                        </div>
                                      </td>
                                      <td className="px-4 py-3 text-center text-sm text-gray-600 space-y-2">
                                        <button class="bg-green-500 px-1 py-1.5 w-24 text-sm shadow-sm font-bold  border text-white rounded-full hover:shadow-lg hover:bg-green-700" onClick={() => this.duplicateData(element)}>Make Copy</button>
                                      </td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            itemsTeachTotal && itemsTeachTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={itemsTeachTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            itemsTeachTotal && itemsTeachTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={itemsTeachTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>




                  {this.state.approveStatus ?
                    <div className="main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                      <div className="border border-[#FBB00C] modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-[40px] shadow-lg z-50 overflow-y-auto">
                        <div className="modal-content py-4 text-left px-6">
                          <div className="modal-close cursor-pointer bg-[#FBB00C] rounded-full w-5 h-5 flex justify-center items-center p-1">
                            <svg
                              onClick={() => this.approveClodeStatusHandel()}
                              className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                          <form autoComplete="off" className='pt-2'>
                            <div className="{otpSent?'disableArea':''}">
                              <label className='font-bold text-sm'>Teaching location</label>
                              <div className="mt-1  shadow-sm relative">
                                <input className={`px-3 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsCoin && !this.state.errorsCoin["teachingLocation"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="teachingLocation" name="teachingLocation" placeholder="Teaching Location" value={this.state.fieldsCoin.teachingLocation} type="text" onChange={this.inputCoinChange} />
                                {this.state.errorsCoin && this.state.errorsCoin["teachingLocation"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsCoin["teachingLocation"]}
                                  </div>
                                  : null}
                              </div>
                            </div>

                            <div className="{otpSent?'disableArea':''}">
                              <label className='font-bold text-sm'>Number of classes in a month.</label>
                              <div className="mt-1  shadow-sm relative">

                                <input className={`px-3 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsCoin && !this.state.errorsCoin["numberOfClasses"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="numberOfClasses" name="numberOfClasses" placeholder="Number of classes in a month" value={this.state.fieldsCoin.numberOfClasses} type="number" onChange={this.inputCoinChange} />
                                {this.state.errorsCoin && this.state.errorsCoin["numberOfClasses"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsCoin["numberOfClasses"]}
                                  </div>
                                  : null}
                              </div>
                            </div>
                            <div className="{otpSent?'disableArea':''}">
                              <label className='font-bold text-sm'>In-hand fee</label>
                              <div className="mt-1  shadow-sm relative">

                                <input className={`px-3 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsCoin && !this.state.errorsCoin["inHandFees"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="inHandFees" name="inHandFees" placeholder="In-hand fee" value={this.state.fieldsCoin.inHandFees} type="number" onChange={this.inputCoinChange} />
                                {this.state.errorsCoin && this.state.errorsCoin["inHandFees"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsCoin["inHandFees"]}
                                  </div>
                                  : null}
                              </div>
                            </div>
                            <div className="{otpSent?'disableArea':''}">
                              <label className='font-bold text-sm'>Class reference</label>
                              <div className="mt-1  shadow-sm relative">

                                <input className={`px-3 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsCoin && !this.state.errorsCoin["classReference"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="classReference" name="classReference" placeholder="Class reference" value={this.state.fieldsCoin.classReference} type="text" onChange={this.inputCoinChange} />
                                {this.state.errorsCoin && this.state.errorsCoin["classReference"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsCoin["classReference"]}
                                  </div>
                                  : null}
                              </div>
                            </div>

                            <div className="{otpSent?'disableArea':''}">
                              <label className='font-bold text-sm'>Remark</label>
                              <div className="mt-1  shadow-sm relative">

                                <input className={`px-3 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsCoin && !this.state.errorsCoin["remark"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                  id="remark" name="remark" placeholder="Remark" value={this.state.fieldsCoin.remark} type="text" onChange={this.inputCoinChange} />
                                {this.state.errorsCoin && this.state.errorsCoin["remark"] ?
                                  <div className="invalid-feedback text-yellow-600">
                                    {this.state.errorsCoin["remark"]}
                                  </div>
                                  : null}
                              </div>
                            </div>
                            <div className="w-64 sm:w-72 mx-auto py-4">
                              <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button"
                                onClick={this.changeStatusDemoClassApprove}
                              >Approve</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                  {this.state.moreDetailsHandel ?
                    <>

                      <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                        <div className="border border-yellow-500 modal-container bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/3 mx-auto rounded-[40px] shadow-lg z-50 overflow-y-auto">
                          <div className="modal-content ">
                            <div className="flex justify-between items-center modal-close cursor-pointer z-50 py-2 px-6 bg-[#FBB00C]">
                              <p className='font-bold text-md'>More</p>
                              <div className='bg-white rounded-full p-1'>
                                <svg
                                  onClick={() => this.moreDetailsCloseModul()}
                                  className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                  </path>
                                </svg>
                              </div>
                            </div>
                            <div className='py-4 px-6 h-[400px] overflow-y-auto divide-y-2'>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Student Name :</p>
                                <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.firstName ? this.state.allDataValue.studentId.firstName
                                  : "-"} {"  "}{this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.lastName ? this.state.allDataValue.studentId.lastName
                                    : "-"}</p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Student Mob No. : </p>
                                <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.mobNo ? this.state.allDataValue.studentId.mobNo
                                  : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Guardian Name :</p>
                                <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianName ? this.state.allDataValue.studentId.studentInfoId.guardianName : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Guardian Mob No. :</p>
                                <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianConNo ? this.state.allDataValue.studentId.studentInfoId.guardianConNo : "-"} </p>
                              </div>

                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Demo Date : </p>
                                <p > {this.state.allDataValue && this.state.allDataValue.date ? this.state.allDataValue.date : "-"}</p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Prefer Time :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.startTime ? this.state.allDataValue.startTime : "-"} to  {this.state.allDataValue && this.state.allDataValue.endTime ? this.state.allDataValue.endTime : "-"}  </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Teaching Mode :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.teachingMode ? this.state.allDataValue.teachingMode : "-"}  </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Board :</p>
                                <p >{this.state.allDataValue && this.state.allDataValue.board && this.state.allDataValue.board.name ? this.state.allDataValue.board.name : "-"}  </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>City :</p>
                                <p >{this.state.allDataValue && this.state.allDataValue.cityId && this.state.allDataValue.cityId.name ? this.state.allDataValue.cityId.name : "-"}  </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>PinCode :</p>
                                <p >{this.state.allDataValue && this.state.allDataValue.pinCodeId && this.state.allDataValue.pinCodeId.pinNo ? this.state.allDataValue.pinCodeId.pinNo : "-"}  </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Class :</p>
                                <p >{this.state.allDataValue && this.state.allDataValue.class && this.state.allDataValue.class.name ? this.state.allDataValue.class.name : "-"}  </p>
                              </div>
                              <div className='flex justify-between items-start w-full'>
                                <div className='w-full'>
                                  <p className='text-lg font-bold'>Subject :</p>
                                </div>
                                <div className='w-full'>
                                  <ul className='text-right'>

                                    {this.state.allDataValue && this.state.allDataValue.course && this.state.allDataValue.course.length > 0 ?
                                      this.state.allDataValue.course.map((element, index) =>
                                        <>
                                          <li className='flex flex-col'>{element && element.name ? element.name : "NA"} </li>
                                        </>
                                      ) : 'NA'}
                                  </ul>
                                </div>
                              </div>

                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Prefered gender :</p>
                                <p >{this.state.allDataValue && this.state.allDataValue.requiredGender ? this.state.allDataValue.requiredGender : "-"}  </p>
                              </div>

                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Address :</p>
                                <p >{this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianAddress ? this.state.allDataValue.studentId.studentInfoId.guardianAddress : "NA"}  </p>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </>
                    :
                    null
                  }

                </section>
              </div>
            </div>
          </main>
        </div>







        {/* <DialogExample /> */}

        <CreateCoinModal
          coinCreateModal={this.state.coinCreateModal}
          fieldsCoin={this.state.fieldsCoin}
          errorsCoin={this.state.errorsCoin}
          inputCoinChange={this.inputCoinChange}
          handleChangeInputSubject={this.handleChangeInputSubject}
          createCoinSubmit={this.createCoinSubmit}
          handleCoinHideModal={this.handleCoinHideModal}
          handleFile={this.handleFile}
          handleTimeChange={this.startTimeFormatTimeToAMPM}
          endTimeFormatTimeToAMPM={this.endTimeFormatTimeToAMPM}
          // handleChangeInputSubject={this.handleChangeInputSubject}
          getTeachCourseList={getTeachCourseList}
          getAllClass={getAllClass}
        />
        <CreateAddUserModal
          studentData={this.state.studentData}
          addUserCreateModal={this.state.addUserCreateModal}
          startTimeField={this.state.startTimeField}
          endTimeFeild={this.state.endTimeFeild}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          inputAddUserChange={this.inputAddUserChange}
          addUserSubmit={this.addUserSubmit}
          handleAddUserHideModal={this.handleAddUserHideModal}
          handleChangeInputCouresId={this.handleChangeInputCouresId}
          handleChangeInputGender={this.handleChangeInputGender}
          handleChangeInputTuitionMode={this.handleChangeInputTuitionMode}
          handleChangeInputPinCode={this.handleChangeInputPinCode}
          handleTimeChange={this.startTimeFormatTimeToAMPM}
          endTimeFormatTimeToAMPM={this.endTimeFormatTimeToAMPM}
          getTeachCourseList={getTeachCourseList}
          getAllClass={getAllClass}
          getAllCity={getAllCity}
          getAllPinCode={getAllPinCode1}
          boardItems={boardItems}
        />


        <UpdateCoinModal
          UpdateCoinModal={this.state.UpdateCoinModal}
          fieldsUpdateCoin={this.state.fieldsUpdateCoin}
          errorsUpdateCoin={this.state.errorsUpdateCoin}
          inputChangeUpdateCoin={this.inputChangeUpdateCoin}
          updateCoinSubmit={this.updateCoinSubmit}
          handleCoinUpdateHideModal={this.handleCoinUpdateHideModal}
          handleFile={this.handleFile}
        />

        <ViewCoinModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={this.state.viewRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}

        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { coin, users, teachcourse, tution, city } = state;
  return {
    coin,
    users,
    teachcourse,
    tution,
    city
  };
}
export default connect(mapStateToProps)(Coin);
