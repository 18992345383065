import { appsettingConstants } from '../_constants';

export function appsetting(state = {}, action) {

  console.log("abjsbfjsdb___________________" ,action)
  switch (action.type) {

    case appsettingConstants.UPDATES_APP_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appsettingConstants.UPDATES_APP_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        updateAppSettingSuccess: true

      };
    case appsettingConstants.UPDATES_APP_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case appsettingConstants.DISABLE_APP_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appsettingConstants.DISABLE_APP_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        addAppSettingSuccess: true
      };
    case appsettingConstants.DISABLE_APP_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case appsettingConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appsettingConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        addAppSettingSuccess: false,
        items: action.keyfeatures.getAppSettingList.list,
        total: action.keyfeatures.getAppSettingList.total
      };
    case appsettingConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case appsettingConstants.GET_ALL_APPSETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appsettingConstants.GET_ALL_APPSETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllAppSetting: action.keyfeatures.getAllAppSetting
      };
    case appsettingConstants.GET_ALL_APPSETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case appsettingConstants.ADD_APPSETTING_REQUEST:
      return {
        ...state
      };
    case appsettingConstants.ADD_APPSETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        addAppSettingSuccess: true
      };
    case appsettingConstants.ADD_APPSETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case appsettingConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        filesDetails: action.uploadImage.filesDetails,
      };

    case appsettingConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };

    case appsettingConstants.TERM_CONDITION_APPSETTING_PASSWORD_REQUEST:
      return {
        ...state
      };
    case appsettingConstants.TERM_CONDITION_APPSETTING_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        addAppSettingSuccess: action.data
      };
    case appsettingConstants.TERM_CONDITION_APPSETTING_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
      case appsettingConstants.DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_REQUEST:
        return {
          ...state
        };
      case appsettingConstants.DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          addAppSettingSuccess: action.data
        };
      case appsettingConstants.DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };
    
  
    default:
      return state
  }
}