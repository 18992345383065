import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';
import { alertActions, transactionActions } from './';

export const dashboardActions = {
    addAminOwnBalance,
    getDashboardData,
    getTermsAndConditionList,
    getAdminDetails,
    updateOwnPasswordAdmin,
    todayDemoPendingList,
    todayDemoCompletedList,
    todayDueDatesList,
    totalTuitionEarningMonthWasi,
    totalTuitionEarning,
    sendDemoRequest,
    getTutorFeeHistory,
    getStudentFeeHistory,

};

function updateOwnPasswordAdmin(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.updateOwnPasswordAdmin(data)
            .then(
                dashboards => {

                    console.log("ACTION_________updateOwnPasswordAdmin:::::", dashboards);

                    let message = dashboards && dashboards.updateOwnPasswordAdmin ? dashboards.updateOwnPasswordAdmin : null
                    dispatch(success(dashboards));
                    dispatch(alertActions.success(message));
                    dispatch(this.getDashboardData());
                },
                error => {
                    console.log('dispatch(alertActions.success(message));', error);
                    dispatch(failure(error))
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: dashboardConstants.UPDATE_OWN_PASSWORD_ADMIN_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.UPDATE_OWN_PASSWORD_ADMIN_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.UPDATE_OWN_PASSWORD_ADMIN_FAILURE, error } }
}


function getTermsAndConditionList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getTermsAndConditionList(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TERMS_AND_CONDIYION_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_TERMS_AND_CONDIYION_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_TERMS_AND_CONDIYION_FAILURE, error } }
}

function todayDemoPendingList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.todayDemoPendingList(data)
            .then(
                dashboards => {

                    console.log("ACTION_________todayDemoPendingList:::::", dashboards);

                    //let message = dashboards && dashboards.todayDemoPendingList ? dashboards.todayDemoPendingList : null
                    dispatch(success(dashboards));
                    // dispatch(alertActions.success(message));
                    // dispatch(this.getDashboardData());
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.TODAY_DEMO_PENDING_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.TODAY_DEMO_PENDING_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.TODAY_DEMO_PENDING_FAILURE, error } }
}


function todayDemoCompletedList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.todayDemoCompletedList(data)
            .then(
                dashboards => {

                    console.log("ACTION_________todayDemoPendingList:::::", dashboards);

                    //let message = dashboards && dashboards.todayDemoPendingList ? dashboards.todayDemoPendingList : null
                    dispatch(success(dashboards));
                    // dispatch(alertActions.success(message));
                    dispatch(this.getDashboardData());
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.TODAY_DEMO_COMPLETED_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.TODAY_DEMO_COMPLETED_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.TODAY_DEMO_PENDING_FAILURE, error } }
}




function todayDueDatesList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.todayDueDatesList(data)
            .then(
                dashboards => {

                    console.log("ACTION_________todayDueDatesList:::::", dashboards);

                    // let message = dashboards && dashboards.todayDemoCompletedList ? dashboards.todayDemoPendingList : null
                    dispatch(success(dashboards));
                    // dispatch(alertActions.success(message));
                    dispatch(this.getDashboardData());
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.TODAY_DUE_DATE_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.TODAY_DUE_DATE_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.TODAY_DUE_DATE_FAILURE, error } }
}


function totalTuitionEarning(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.totalTuitionEarning(data)
            .then(
                dashboards => {
                    console.log("ACTION_________totalTuitionEarning:::::", dashboards);
                    dispatch(success(dashboards));
                    dispatch(this.getDashboardData());
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.TOTAL_TUTION_EARNING_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.TOTAL_TUTION_EARNING_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.TOTAL_TUTION_EARNING_FAILURE, error } }
}

function totalTuitionEarningMonthWasi(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.totalTuitionEarningMonthWasi(data)
            .then(
                dashboards => {
                    console.log("ACTION_________totalTuitionEarningMonthWasi:::::", dashboards);
                    dispatch(success(dashboards));
                    // dispatch(this.getDashboardData());
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.TOTAL_TUTION_EARNING_MONTH_WASI_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.TOTAL_TUTION_EARNING_MONTH_WASI_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.TOTAL_TUTION_EARNING_MONTH_WASI_FAILURE, error } }
}

function sendDemoRequest(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.sendDemoRequest(data)
            .then(
                dashboards => {
                    console.log("ACTION_________sendDemoRequest:::::", dashboards);
                    dispatch(success(dashboards));
                    dispatch(this.getDashboardData());
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.SEND_DEMO_REQUEST_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.SEND_DEMO_REQUEST_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.SEND_DEMO_REQUEST_FAILURE, error } }
}

function getStudentFeeHistory(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getStudentFeeHistory(data)
            .then(
                dashboards => {
                    console.log("ACTION_________getStudentFeeHistory:::::", dashboards);
                    dispatch(success(dashboards));
                    dispatch(this.getDashboardData());
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STUDENT_FEE_HISTORY_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_STUDENT_FEE_HISTORY, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_STUDENT_FEE_HISTORY_FAILURE, error } }
}

function getTutorFeeHistory(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getTutorFeeHistory(data)
            .then(
                dashboards => {
                    console.log("ACTION_________getStudentFeeHistory:::::", dashboards);
                    dispatch(success(dashboards));
                    dispatch(this.getDashboardData());
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TUTOR_FEE_HISTORY_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_TUTOR_FEE_HISTORY_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_TUTOR_FEE_HISTORY_FAILURE, error } }
}


function getDashboardData(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getDashboardData(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DASHBOARD_DATA_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_DASHBOARD_DATA_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_DASHBOARD_DATA_FAILURE, error } }
}

function addAminOwnBalance(data) {
    let temp = {
        "txType": "ADMIN_DEPOSIT",
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        dashboardService.addAminOwnBalance(data)
            .then(
                dashboards => {
                    let message = dashboards && dashboards.addAminOwnBalance ? dashboards.addAminOwnBalance : null
                    dispatch(success(dashboards));
                    dispatch(alertActions.success(message));
                    dispatch(this.getAdminDetails());
                    dispatch(this.getDashboardData());
                    dispatch(transactionActions.getUserTxList(temp));
                    // dispatch(this.getUserTxList(temp));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.ADD_ADMIN_OWN_BALANCE_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.ADD_ADMIN_OWN_BALANCE_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.ADD_ADMIN_OWN_BALANCE_FAILURE, error } }
}

function getAdminDetails() {
    return dispatch => {
        dispatch(request());
        dashboardService.getAdminDetails()
            .then(
                dashboards => {
                    dispatch(success(dashboards));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ADMIN_DETAILS_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_ADMIN_DETAILS_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_ADMIN_DETAILS_FAILURE, error } }
}

