import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import { CONST } from "../../_config/index"
import { authHeader } from "../../_helpers"
import { alertActions, userActions } from '../../_actions';
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import moment from 'moment'
import { FaImage } from "react-icons/fa";

class PromotionalPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldskyc: {},
      errorskyc: {},
      loading: true,
      notificationList: [],
      notificationTotal: 0,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      txType: '',
      formField: {},
      errorField: {}
    }
  }
  componentDidMount() {
    this.getProfile()

  }

  getProfile = async () => {
    let resData = {
      keyWord: "",
      pageNo: 1,
      size: 10
    }
    const res = await fetch(CONST.BACKEND_URL + `/getAdminNotificationList`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
      },
      body: JSON.stringify(resData)
    });

    const dataRes = await res.json();
    console.log('dataRes_________________', dataRes.data);
    this.setState({ notificationList: dataRes.data.list, notificationTotal: dataRes.data.total, loading: false })
    // setProfile(dataRes.data);
    // setBalance(dataRes && dataRes.data && dataRes.data.wallet && dataRes.data.wallet.bTWallet);
    // setAddress(dataRes && dataRes.data && dataRes.data.user && dataRes.data.user.walletAddress);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.isKYCCreated) {
      return {
        ...nextProps,
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }


  inputChange = (event) => {
    console.log(event.target.name, event.target.value);

    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    console.log(event.target.name, event.target.value);
    this.setState({ formField, errorField });
  }

  notificationValidation = () => {
    let formField = this.state.formField
    let errorField = {}
    let verified = true

    if (!formField['msg'] || formField['msg'] === "") {
      errorField['msg'] = `Conn't be empty message.`
      verified = false
    }
    if (!formField['title'] || formField['title'] === "") {
      errorField['title'] = `Conn't be empty title.`
      verified = false
    }
    this.setState({ errorField: errorField })
    return verified
  }

  submitNotification = () => {
    if (this.notificationValidation()) {

      let { users } = this.props;
      let { filesDetails } = users;
      console.log('filesDetails___________________', filesDetails);

      let resData = {
        "userType": this.state.formField && this.state.formField.userType ? this.state.formField.userType : null,
        "msg": this.state.formField && this.state.formField.msg ? this.state.formField.msg : null,
        "image": filesDetails && filesDetails.imageUrl ? filesDetails.imageUrl : null,
        "title": this.state.formField && this.state.formField.title ? this.state.formField.title : null
      }
      confirmAlert({


        title: 'Confirm to send notification ?',
        message: `Are you sure to send notification ?`,
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {


              // let resData = {
              //   keyWord: "",
              //   pageNo: 1,
              //   size: 10
              // }
              const res = await fetch(CONST.BACKEND_URL + `/AllStudentOrTutorSendNotification`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  "Authorization": authHeader().Authorization
                },
                body: JSON.stringify(resData)
              });

              const dataResres = await res.json();

              console.log('dataResres_____________________', dataResres);
              if (dataResres && !dataResres.error) {
                this.setState({ formField: {}, errorField: {} })
                this.getProfile()
              } else {
                this.props.dispatch(alertActions.error('Somthing wrong.'))
              }


              // let header = new Headers({
              //   'Content-Type': 'application/json',
              //   "Authorization": authHeader().Authorization
              // });
              // const requestOptions = {
              //   method: "POST",
              //   headers: header,
              //   body: JSON.stringify(resData)
              // }
              // this.setState({ formField: {}, errorField: {} })
              // return fetch(CONST.BACKEND_URL + `/AllStudentOrTutorSendNotification`, requestOptions)
              // this.getProfile()
            }
          },
          {
            label: 'No'
          }
        ]
      });
    }

  }



  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  handlePageClick = async (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let resData = {
      keyWord: "",
      pageNo: data.selected + 1,
      size: 10
    }
    const res = await fetch(CONST.BACKEND_URL + `/getAdminNotificationList`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
      },
      body: JSON.stringify(resData)
    });
    this.setState({ loading: true })

    const dataRes = await res.json();
    this.setState({ notificationList: dataRes.data.list, notificationTotal: dataRes.data.total, loading: false })
  }


  render() {
    let loading = this.state.loading
    return (

      <>
        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>
        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="flex-1 relative ">
            <div className="2xl:p-10 sm:p-5 p-3">
              <div className="max-w-screen-3xl mx-auto ">
                {/* Transaction Section */}
                <section className="bg-white col-span-6 2xl:py-7 py-5 2xl:px-10 sm:px-5 px-2 flex-col rounded-md">
                  <div className="w-full flex flex-wrap  ">

                    <div className="w-full flex flex-wrap  ">
                      <div className="ml-0 mr-auto">
                        <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">Notification</h3>
                      </div>
                    </div>

                    <div className="w-full flex justify-end flex-wrap">

                    </div>
                  </div>

                  <form>

                    <div className="w-full ">
                      <div className="relative mt-1 shadow-sm">
                        <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">User Type</label>
                        <select className="px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 border border-gray-400" onChange={this.inputChange}
                          id="userType"
                          value={this.state.formField && this.state.formField.userType ? this.state.formField.userType : ''}
                          name="userType">
                          <option value="">Please Choose User Type&hellip;</option>
                          <option value="STUDENT">Student</option>
                          <option value="TUTOR">Tutor</option>
                        </select>
                      </div>
                    </div>

                    <div className="">
                      <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Title</label>
                      <div className="mt-1 rounded-md shadow-sm relative">
                        <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 ${this.state.errorField && !this.state.errorField["title"] ? "  border border-gray-400 placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                          id="title" name="title" placeholder="Title" value={this.state.formField && this.state.formField.title ? this.state.formField.title : ''} type="text" onChange={this.inputChange} />
                        {this.state.errorField && this.state.errorField["title"] ?
                          <div className="invalid-feedback text-yellow-600">
                            {this.state.errorField["title"]}
                          </div>
                          : null}
                      </div>
                    </div>

                    <div className="">
                      <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Message</label>
                      <div className="mt-1 rounded-md shadow-sm relative">
                        <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 ${this.state.errorField && !this.state.errorField["msg"] ? "  border border-gray-400 placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                          id="msg" name="msg" placeholder="Message" value={this.state.formField && this.state.formField.msg ? this.state.formField.msg : ''} type="text" onChange={this.inputChange} />
                        {this.state.errorField && this.state.errorField["msg"] ?
                          <div className="invalid-feedback text-yellow-600">
                            {this.state.errorField["msg"]}
                          </div>
                          : null}
                      </div>
                    </div>

                    <div className="">
                      <div className="mt-1 rounded-md shadow-sm relative">
                        <label class="block text-gray-700 text-sm" for="username">Image</label>
                        <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorField && !this.state.errorField["assignmentPdf"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                          id="assignmentPdf" name="assignmentPdf" placeholder="Attach Assignment"
                          // value={this.handleFile} 
                          type="file"
                          onChange={this.handleFile}
                        />
                        {this.state.errorField && this.state.errorField["assignmentPdf"] ?
                          <div className="invalid-feedback text-yellow-600">
                            {this.state.errorField["assignmentPdf"]}
                          </div>
                          : null}
                      </div>
                    </div>

                    <div className=" w-64 sm:w-72 mx-auto py-6">
                      <button className="bg-[#FBB00C] w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-[#FBB00C] hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={() => this.submitNotification()}>Submit</button>
                    </div>
                  </form>

                </section>

                <section className="bg-white col-span-6 2xl:py-7 py-5 2xl:px-10 sm:px-5 px-2 flex-col rounded-md">
                  <div className="w-full flex justify-between items-center">
                    <div className="">
                      <h3 className=" md:text-2xl text-lg leading-6 md:leading-9  font-semibold text-dark-400 tracking-widest">Notification List</h3>
                    </div>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4 shadow-md">
                    <div className="overflow-x-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Title</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Message</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">image</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">createdAte</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                this.state.notificationList && this.state.notificationList.length > 0 ?
                                  this.state.notificationList.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">

                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.name ? element.name : '-'}</td>

                                      {/* <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.msg ? element.msg : '-'}</td> */}

                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600 text-center" style={{ maxWidth: "200px" }}>
                                        <p
                                          title={element && element.msg}
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          {element && element.msg ? element.msg.slice(" ") : null}
                                        </p>
                                      </td>


                                      {/* <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        <img
                                          className='lg:w-40 mx-auto w-full'
                                          src={element && element.image ? element.image : "-"}
                                          alt=""
                                        />
                                      </td> */}
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <a target="_blank" href={element && element.image} className="text-blue-500 hover:underline font-bold">

                                          <img
                                            className='lg:w-40 mx-auto w-full'
                                            src={element && element.image ? element.image : "-"}
                                            alt=""
                                          />
                                        </a>


                                      </td>

                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD h:mm A")}</td>



                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            this.state.notificationTotal && this.state.notificationTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.notificationTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            this.state.notificationTotal && this.state.notificationTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.notificationTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>

              </div>
            </div>
          </main>
        </div>
      </>

    );
  }
}
function mapStateToProps(state) {
  const { authentication, kyc, users } = state;
  return {
    users,
    kyc,
    authentication,

  };
}
export default connect(mapStateToProps)(PromotionalPackage);
