import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, tutionActions, cityActions, alertActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import { isMobile } from "react-device-detect";
import ImageViewModal from "./components/ImageViewModal/ImageViewModal";
import ViewPendingModal from "./components/ViewPendingModal/ViewPendingModal";
import ViewAddharModal from "./components/ViewAddharModal/ViewAddharModal";
import ViewPanModal from "./components/ViewPanModal/ViewPanModal";
import ViewSelfieModal from "./components/ViewSelfieModal/ViewSelfieModal";
import { MdOutlineSearch } from "react-icons/md"
import moment from 'moment'
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';
import { CONST } from "../../_config/index"
import { authHeader } from "../../_helpers"
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from "react-icons/tb";


class paymentManagementstudent extends Component {
  constructor(props) {
    super(props);
    // this.kycSubmit = this.kycSubmit.bind(this);
    // this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldskyc: {},
      errorskyc: {},
      studentData: {},
      viewImageData: {},
      viewRowData: {},
      viewSelfieData: {},
      viewPanData: {},
      viewAddharData: {},
      fieldsHoliday: {},
      errorsHoliday: {},
      fieldstution: {},
      errorstution: {},
      dateField: '',
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      addPaymentField: false,
      viewImageCreateModal: false,
      viewMoreDetailsModal: false,
      viewSelfieDetailsModal: false,
      viewPanDetailsModal: false,
      viewAddharDetailsModal: false,
      moreDetailsHandel: false,
      moreDetailsHandelUnpaid: false
    }
  }
  componentDidMount() {
    let temp = {
      "userType": "STUDENT",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": 1000000000
    }
    let temp1 = {
      "userType": "STUDENT",
      "status": "ACTIVE",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": 1000000000
    }
    this.props.dispatch(userActions.getUserActiveStudent(temp1));
    this.props.dispatch(cityActions.getAllClass(temp));
    this.props.dispatch(userActions.getTuitionListByDate(temp));
    this.props.dispatch(userActions.getFeeHistoryListAdmin(temp));
    this.props.dispatch(tutionActions.getAllteacherCouresId(temp));
    let data = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": 1000000000
    }
    this.props.dispatch(userActions.getUserActive(data));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        fieldsHoliday: {},
        errorsHoliday: {},
        addPaymentField: false

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  demoReminderSubmit = (data) => {
    console.log('data___________________', data);
    let resData = {
      "studentId": data.studentId.id
    }
    confirmAlert({


      title: 'Confirm to send notification ?',
      message: `Are you sure to send notification ${data.studentId.firstName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let header = new Headers({
              'Content-Type': 'application/json',
              "Authorization": authHeader().Authorization
            });
            const requestOptions = {
              method: "POST",
              headers: header,
              body: JSON.stringify(resData)
            }
            return fetch(CONST.BACKEND_URL + `/studentPaymentReminder`, requestOptions)
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }
  updateUnpaidStatusSubmit = (data) => {
    let resData = {
      "id": data.id
    }
    let temp = {
      "userType": "STUDENT",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": 1000000000
    }
    confirmAlert({


      title: 'Confirm to Paid status?',
      message: `Are you sure to Paid status ${data.studentId.firstName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.dispatch(userActions.updateUnpaidStatus(resData, temp));
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }
  inputChange = (e) => {
    console.log('e________________________', e.target.value);
    e.preventDefault();
    let { name, value } = e.target;
    let fieldstution = this.state.fieldstution;
    let errorstution = this.state.errorstution;
    fieldstution[name] = value;
    errorstution[name] = "";
    console.log(name, value);
    this.setState({ fieldstution, errorstution });
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "status": "0",
      "userType": "STUDENT",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTuitionListByDate(datatemp));
  }


  moreDetailsOpenModulUnPaid = (data) => {
    this.setState({ moreDetailsHandelUnpaid: true, allDataValue: data })
  }
  moreDetailsCloseModulUnPaid = () => {
    this.setState({ moreDetailsHandelUnpaid: false, allDataValue: {} })
  }
  handleChangeInputInternalPayment = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["studentIdPaymet"] = output;
    this.setState({ studentData });

  };
  handleChangeInputTutorIdPayment = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["tutorIdPaymet"] = output;
    this.setState({ studentData });

  };
  handleChangeInputInternal = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["studentId"] = output;
    this.setState({ studentData });

  };

  handleChangeInputclassId = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["classId"] = output;
    this.setState({ studentData });

  };

  handleChangeInputteacherCouresId = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["teacherCouresId"] = output;
    this.setState({ studentData });

  };

  handleChangeInputtutorId = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["tutorId"] = output;
    this.setState({ studentData });

  };
  classReferenceSubmitTuition = () => {
    // e.preventDefault();
    console.log('this.state.fieldstution', this.state.fieldstution);

    let temp1 = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "classId": this.state.studentData['classId'] && this.state.studentData['classId'].length > 0 ? this.state.studentData['classId'] : null,
      "teacherCouresId": this.state.studentData['teacherCouresId'] && this.state.studentData['teacherCouresId'].length > 0 ? this.state.studentData['teacherCouresId'] : null,
      "tutorId": this.state.studentData['tutorId'] && this.state.studentData['tutorId'].length > 0 ? this.state.studentData['tutorId'] : null,
      "dueDate": Number(this.state.fieldstution['deuDate']),
      "userType": "STUDENT",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": 1000000000
    }
    this.props.dispatch(userActions.getTuitionListByDate(temp1));
  }
  classReferenceSubmitPayment = () => {
    // e.preventDefault();
    console.log('this.state.fieldstution', this.state.fieldstution);


    let temp1 = {
      "studentId": this.state.studentData['studentIdPaymet'] && this.state.studentData['studentIdPaymet'].length > 0 ? this.state.studentData['studentIdPaymet'] : null,
      "payType": this.state.fieldstution && this.state.fieldstution.payType ? this.state.fieldstution.payType : null,
      "date": this.state.fieldstution && this.state.fieldstution.date ? this.state.fieldstution.date : null,
      "isPaid": this.state.fieldstution && this.state.fieldstution.isPaid ? this.state.fieldstution.isPaid : null,
      "userType": "STUDENT",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": 1000000000
    }
    console.log('temp1+++++++++++++++++++++', temp1);
    this.props.dispatch(userActions.getFeeHistoryListAdmin(temp1));
  }
  resetDataRes = (data) => {
    this.props.history.push('/app/paymentManagementstudent')
  }
  markPaidOrUnPaidSubmit = () => {
    if (this.handleValidationCoin()) {
      console.log('this.state.allDataValue', this.state.allDataValue);
      let resData = {
        tuitionId: this.state.allDataValue.id,
        // studentId:
        studentId: this.state.allDataValue.studentId.id,
        tutorFee: this.state.fieldstution.tutorFee,
        feeOfMonth: this.state.fieldstution.startDate,
        isPaid: "true",
        paymentMode: this.state.fieldstution.paymentMode,
        remark: this.state.fieldstution && this.state.fieldstution.remark ? this.state.fieldstution.remark : null,
        payType: 'Advance'
      }
      console.log('resData_____________', resData);
      this.props.dispatch(userActions.markPaidOrUnPaid(resData));
      this.moreDetailsCloseModul()
      let temp = {
        "userType": "STUDENT",
        "keyWord": "",
        "pageNo": this.state.page,
        "size": 1000000000
      }
      this.props.dispatch(userActions.getTuitionListByDate(temp));
      this.props.dispatch(userActions.getFeeHistoryListAdmin(temp));
    }
  }
  markPaidOrUnPaidSubmitUnPaid = () => {
    if (this.handleValidationCoin()) {
      console.log('this.state.allDataValue', this.state.allDataValue);
      let resData = {
        tuitionId: this.state.allDataValue.id,
        // studentId:
        studentId: this.state.allDataValue.studentId.id,
        tutorFee: this.state.fieldstution.tutorFee,
        feeOfMonth: this.state.fieldstution.startDate,
        isPaid: "false",
        paymentMode: this.state.fieldstution.paymentMode,
        remark: this.state.fieldstution && this.state.fieldstution.remark ? this.state.fieldstution.remark : null,
        payType: 'Advance'
      }
      console.log('resData_____________', resData);
      this.props.dispatch(userActions.markPaidOrUnPaid(resData));
      this.moreDetailsCloseModulUnPaid()
      let temp = {
        "userType": "STUDENT",
        "keyWord": "",
        "pageNo": this.state.page,
        "size": 1000000000
      }
      this.props.dispatch(userActions.getTuitionListByDate(temp));
      this.props.dispatch(userActions.getFeeHistoryListAdmin(temp));
    }
  }
  handleValidationCoin = () => {
    let fieldstution = this.state.fieldstution;
    let errorstution = {};
    let formIsValid = true;

    //tutorFee
    if (!fieldstution["tutorFee"] || fieldstution["tutorFee"] === "") {
      formIsValid = false;
      errorstution["tutorFee"] = "Cannot be empty Fee";
      this.props.dispatch(alertActions.error('Cannot be empty fee amount'));
    }

    this.setState({ errorstution: errorstution });
    return formIsValid;
  }
  previousDateChangeSubmit = (data) => {
    let temp = {
      "currentDate": data,
      "nextDate": 0,
      "userType": "STUDENT",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": 1000000000
    }
    this.props.dispatch(userActions.getTuitionListByDate(temp));

  };
  nextDateChangeSubmit = (data) => {
    let temp = {
      "currentDate": data,
      "nextDate": 1,
      "userType": "STUDENT",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": 1000000000
    }
    this.props.dispatch(userActions.getTuitionListByDate(temp));

  };
  onChangeChangeSubmit = (e) => {
    e.preventDefault();
    let temp = {
      "calenderData": e.target.value,
      "keyWord": "",
      "userType": "STUDENT",
      "pageNo": this.state.page,
      "size": 1000000000
    }
    this.props.dispatch(userActions.getTuitionListByDate(temp));

  };
  moreDetailsOpenModul = (data, status) => {
    this.setState({ moreDetailsHandel: true, allDataValue: data })
  }
  moreDetailsCloseModul = () => {
    this.setState({ moreDetailsHandel: false, allDataValue: {} })
  }

  render() {


    let { tution, city, users } = this.props;
    let { getAllteacherCouresId } = tution;
    let { getAllClass } = city;
    let { getUserList, getUserStudentList, getTuitionDateWasiList, loading, getFeeHistoryList, currentMonthData } = users;
    let NameRes = getUserStudentList && getUserStudentList.length ? getUserStudentList.map((ele, index) => ({ value: ele.id, label: ele.firstName + " " + ele.lastName + " " + ele.mobNo })) : null
    let classRes = getAllClass && getAllClass.length ? getAllClass.map((ele, index) => ({ value: ele.id, label: ele.name })) : null
    let teacherCouresRes = getAllteacherCouresId && getAllteacherCouresId.length ? getAllteacherCouresId.map((ele, index) => ({ value: ele.id, label: ele.name })) : null
    let tutorRes = getUserList && getUserList.length ? getUserList.map((ele, index) => ({
      value: ele.id, label: ele.fullName + " " + ele.mobNo
    })) : null

    let currentDate = moment(currentMonthData);

    let currentMonth = currentDate.format('MMM');

    let studentCount = getTuitionDateWasiList && getTuitionDateWasiList.length > 0 ? getTuitionDateWasiList.filter(item => item.isStatus == false) : 0


    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>
        <div className='overflow-auto'>

          <section className='2xl:w-11/12 w-11/12 mx-auto py-10'>
            <div className="w-full flex justify-between items-center">
              <div className='py-4'>
                <h2 className='text-2xl font-bold'>Student Payment Management ({studentCount && studentCount.length ? studentCount.length : 0})</h2>
              </div>
              <div className='flex items-center space-x-1'>
                <TbLayoutSidebarLeftCollapse size={50} onClick={() => this.previousDateChangeSubmit(currentMonthData)} />
                <label className='text-xl font-bold'>{moment(new Date(parseInt(currentMonthData))).utcOffset("+05:30").format("MMMM YYYY")}</label>
                <TbLayoutSidebarLeftExpand size={50} onClick={() => this.nextDateChangeSubmit(currentMonthData)} />
              </div>
              <div className='flex items-center'>
                <input className="appearance-none lg:w-36 w-full px-6 py-1.5 rounded-full border  placeholder-gray-800 text-dark-400 focus:outline-none  focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5" id="date" name='date' placeholder="date" type="month" required onChange={this.onChangeChangeSubmit} />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-2 ">
              <thead className="bg-[#fff] rounded-t">
                <tr className=" ">
                  <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                    <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                      <label htmlFor="resourceList" style={{ color: 'black' }}>Student name or Number</label>

                      <Select2
                        width="full"
                        isMulti
                        name="internalId"
                        id="internalId"
                        onChange={this.handleChangeInputInternal}
                        options={NameRes}
                        className="basic-multi-select width: 'full'"
                        classNamePrefix="Activities"
                      />
                    </Grid>
                  </th>
                  <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                    <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                      <label htmlFor="resourceList" style={{ color: 'black' }}>Class</label>

                      <Select2
                        width="full"
                        isMulti
                        name="internalId"
                        id="internalId"
                        onChange={this.handleChangeInputclassId}
                        options={classRes}
                        className="basic-multi-select width: 'full'"
                        classNamePrefix="Activities"
                      />
                    </Grid>
                  </th>
                  <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                    <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                      <label htmlFor="resourceList" style={{ color: 'black' }}>Subject</label>

                      <Select2
                        width="full"
                        isMulti
                        name="internalId"
                        id="internalId"
                        onChange={this.handleChangeInputteacherCouresId}
                        options={teacherCouresRes}
                        className="basic-multi-select width: 'full'"
                        classNamePrefix="Activities"
                      />
                    </Grid>
                  </th>
                  <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                    <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                      <label htmlFor="resourceList" style={{ color: 'black' }}>Tutor</label>

                      <Select2
                        width="full"
                        isMulti
                        name="internalId"
                        id="internalId"
                        onChange={this.handleChangeInputtutorId}
                        options={tutorRes}
                        className="basic-multi-select width: 'full'"
                        classNamePrefix="Activities"
                      />
                    </Grid>
                  </th>
                  <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                  </th>
                  <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                    <div className="relative">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-40 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="deuDate" name='deuDate' placeholder="Due date.." type="text" onChange={this.inputChange} required />
                    </div>
                  </th>
                  <th>
                    <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.classReferenceSubmitTuition()}> Search </button>
                  </th>
                  <th>
                    <button className="bg-[#25BF63] hover:bg-[#25BF63] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.resetDataRes()}> Reset </button>
                  </th>
                </tr>
              </thead>
              <div className='w-full overflow-hidden overflow-x-auto h-[600px] overflow-y-auto mb-14'>
                <table className='bg-white divide-y-2 rounded-[14px] w-full'>
                  <thead>
                    <tr>
                      <th className="text-[14px] px-2 py-2 text-left whitespace-pre">Name and Mob.No.</th>
                      <th className="text-[14px] px-2 text-left whitespace-pre"> Class & Subject</th>
                      <th className="text-[14px] px-2 text-left whitespace-pre">Class Reference </th>
                      <th className="text-[14px] px-2 text-left whitespace-pre"> Assigned Tutor</th>
                      <th className="text-[14px] px-2 text-left whitespace-pre">Student Fee</th>
                      <th className="text-[14px] px-2 text-left whitespace-pre">Start Date</th>
                      <th className="text-[14px] px-2 text-center whitespace-pre">Due Date</th>
                      <th className="text-[14px] px-2 text-center whitespace-pre">Action</th>
                    </tr>
                  </thead>
                  {getTuitionDateWasiList && getTuitionDateWasiList && getTuitionDateWasiList.length > 0 ?
                    getTuitionDateWasiList.map((element) =>
                    (<React.Fragment key={element.id}>
                      {element.isStatus == false ? <tbody>
                        <tr>
                          <td className='text-[14px] px-2  text-left whitespace-nowrap'>
                            <p>{element && element.studentId && element.studentId.firstName ? element.studentId.firstName : '-'} {element && element.studentId && element.studentId.lastName ? element.studentId.lastName : '-'}</p>
                            <p>{element && element.studentId && element.studentId.mobNo ? element.studentId.mobNo : '-'}</p>
                          </td>
                          <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                            <p>{element && element.classId && element.classId.name ? element.classId.name : '-'} / {element && element.teacherCouresId && element.teacherCouresId[0] && element.teacherCouresId[0].name ? element.teacherCouresId[0].name : '-'}</p>
                          </td>
                          <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                            <p>{element && element.classReference ? element.classReference : '-'}</p>
                          </td>
                          <td className='text-[14px] px-2  text-left whitespace-nowrap'>
                            <p>{element && element.tutorId && element.tutorId.fullName ? element.tutorId.fullName : '-'}</p>
                            <p>{element && element.tutorId && element.tutorId.mobNo ? element.tutorId.mobNo : '-'}</p>
                          </td>
                          <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                            <p>{element && element.fee ? element.fee : '-'}/-</p>
                            <p>{element && element.feeStatus ? element.feeStatus : '-'}</p></td>
                          <td className='text-[14px] px-2 text-left whitespace-nowrap'>{element && element.startDate ? element.startDate : '-'}</td>
                          <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                            <div className='bg-[#FBB00C] text-center font-bold rounded'>
                              <p>{element && element.dueDate ? element.dueDate : '-'}</p>
                              <p>{currentMonth}</p>
                            </div>
                          </td>
                          <td className='text-[14px] px-2 text-center whitespace-nowrap space-y-1'>
                            <div>
                              <button className='bg-[#FBB00C] text-white px-4 py-1 w-[110px] rounded-full' onClick={() => this.demoReminderSubmit(element)}>Reminder</button>
                            </div>
                            <div onClick={() => this.moreDetailsOpenModul(element)}>
                              <button className='bg-[#25BF63] text-white px-4 py-1 w-[110px] rounded-full'>Mark Paid</button>
                            </div>
                            <div onClick={() => this.moreDetailsOpenModulUnPaid(element)}>
                              <button className='bg-[#ec2e2e] text-white px-4 py-1 w-[110px] rounded-full'>Mark Unpaid</button>
                            </div>
                          </td>
                        </tr>

                      </tbody> : null}
                    </React.Fragment>)
                    ) :
                    null
                  }
                </table>
              </div>

              {/* {
                isMobile ?
                  <nav className="relative z-0 flex justify-end mt-5 w-76">
                    {
                      getTuitionDateWasiTotal && getTuitionDateWasiTotal > 10 ?
                        <ReactPaginate
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={getTuitionDateWasiTotal / this.state.size}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                        />
                        : null}
                  </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                    {
                      getTuitionDateWasiTotal && getTuitionDateWasiTotal > 10 ?
                        <ReactPaginate
                          previousLabel={'Previous'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={getTuitionDateWasiTotal / this.state.size}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                        />
                        : null}
                  </nav>
              } */}

              <thead className="bg-[#fff] rounded-t">
                <tr className=" ">
                  <th scope="col-2" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                    <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                      <label htmlFor="resourceList" style={{ color: 'black' }}>Student name or Number</label>

                      <Select2
                        width="full"
                        isMulti
                        name="internalId"
                        id="internalId"
                        onChange={this.handleChangeInputInternalPayment}
                        options={NameRes}
                        className="basic-multi-select width: 'full'"
                        classNamePrefix="Activities"
                      />
                    </Grid>
                  </th>

                  <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                  </th>
                  <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                  </th>
                  <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                  </th>
                  <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                    <label className='text-transparent'>Paid</label>
                    <div className=''>
                      <select class="bg-[#FBB00C]  py-1 text-sm shadow-sm  font-bold tracking-wider text-white rounded-full hover:shadow-lg" onClick={this.inputChange}
                        id='payType' name='payType'>
                        <option className='bg-white text-black' value="">Paid In</option>
                        <option className='bg-white text-black' value="Advance">Advance</option>
                        <option className='bg-white text-black' value="Month End">Month End</option>
                      </select>
                    </div>
                  </th>
                  <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                    <label className='text-transparent'>Status</label>
                    <div className=''>
                      <select class="bg-[#FBB00C]  py-1 text-sm shadow-sm  font-bold tracking-wider text-white rounded-full hover:shadow-lg" onClick={this.inputChange}
                        id='isPaid' name='isPaid'>
                        <option className='bg-white text-black' value="">Status</option>
                        <option className='bg-white text-black' value="1">Paid</option>
                        <option className='bg-white text-black' value="2">Unpaid</option>
                      </select>
                    </div>
                  </th>
                  <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                    <label className='text-transparent'>date</label>
                    <input datepicker="" type="date" id='date' name='date' class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-40 " placeholder="Select date" onChange={this.inputChange} />
                  </th>

                  <th>
                    <label className='text-transparent'>Search</label>
                    <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.classReferenceSubmitPayment()}> Search </button>
                  </th>
                  <th>
                    <label className='text-transparent'>Reset</label>
                    <button className="bg-[#25BF63] hover:bg-[#25BF63] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.resetDataRes()}> Reset </button>
                  </th>
                </tr>
              </thead>
              <div className='w-full overflow-hidden overflow-x-auto h-[400px] overflow-y-auto '>
                <table className='bg-white divide-y-2 rounded-[14px] w-full'>
                  <thead>
                    <tr>
                      <th className="text-[14px] px-2 py-2 text-left whitespace-pre">Student Name</th>
                      <th className="text-[14px] px-2 text-left whitespace-pre"> Class & Subject</th>
                      <th className="text-[14px] px-2 text-left whitespace-pre">Tutor Name </th>
                      <th className="text-[14px] px-2 text-left whitespace-pre"> Payment Mode</th>
                      <th className="text-[14px] px-2 text-left whitespace-pre"> Paid In</th>
                      <th className="text-[14px] px-2 text-left whitespace-pre"> Student Fee</th>
                      <th className="text-[14px] px-2 text-left whitespace-pre">Date</th>
                      <th className="text-[14px] px-2 text-left whitespace-pre">Remark</th>
                      <th className="text-[14px] px-2 text-left whitespace-pre">Status</th>
                    </tr>
                  </thead>

                  {getFeeHistoryList && getFeeHistoryList && getFeeHistoryList.length > 0 ? getFeeHistoryList.map((element) =>
                    <tbody>
                      <tr>
                        <td className='text-[14px] px-2 py-2 text-left whitespace-nowrap'>
                          <p>{element && element.studentId && element.studentId.firstName ? element.studentId.firstName : null} {element && element.studentId && element.studentId.lastName ? element.studentId.lastName : null}</p>
                          <p>{element && element.studentId && element.studentId.mobNo ? element.studentId.mobNo : null}</p>
                        </td>
                        <td className='text-[14px] px-2 py-2 text-left whitespace-nowrap'>
                          <p>{element && element.tuitionId && element.tuitionId.classId && element.tuitionId.classId.name ? element.tuitionId.classId.name : null}</p>
                        </td>
                        <td className='text-[14px] px-2 py-2 text-left whitespace-nowrap'>
                          <p>{element && element.tuitionId && element.tuitionId.tutorId && element.tuitionId.tutorId.fullName ? element.tuitionId.tutorId.fullName : null}</p>
                          <p>{element && element.tuitionId && element.tuitionId.tutorId && element.tuitionId.tutorId.mobNo ? element.tuitionId.tutorId.mobNo : null}</p>
                        </td>
                        <td className='text-[14px] px-2 py-2 text-left whitespace-nowrap'>
                          <p>{element && element.paymentMode ? element.paymentMode : null}</p>
                        </td>
                        <td className='text-[14px] px-2 py-2 text-left whitespace-nowrap'>
                          <p>{element && element.payType ? element.payType : null}</p>
                        </td>
                        <td className='text-[14px] px-2 py-2 text-left whitespace-nowrap'>
                          <p>{element && element.tutorFee ? element.tutorFee : null}/-</p>
                        </td>
                        <td className='text-[14px] px-2 py-2 text-left whitespace-nowrap'>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD")}</td>
                        <td className='text-[14px] px-2 py-2 text-left whitespace-nowrap'>
                          <p>{element && element.remark ? element.remark : null}</p>
                        </td>
                        <td className='text-[14px] px-2 py-2 text-left whitespace-nowrap space-y-1'>
                          {element.isPaid ?
                            <div>
                              <button className='text-white bg-green-600 px-3 py-1 rounded-full'> Paid</button>
                            </div>
                            :
                            <div>
                              <button className='text-white bg-red-600 hover:bg-red-400 px-3 py-1 rounded-full' onClick={() => this.updateUnpaidStatusSubmit(element)}> Unpaid</button>
                            </div>
                          }
                        </td>
                      </tr>
                    </tbody>) : null}
                </table>
              </div>

              {/* {
                isMobile ?
                  <nav className="relative z-0 flex justify-end mt-5 w-76">
                    {
                      getTuitionDateWasiTotal && getTuitionDateWasiTotal > 10 ?
                        <ReactPaginate
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={getTuitionDateWasiTotal / this.state.size}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                        />
                        : null}
                  </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                    {
                      getTuitionDateWasiTotal && getTuitionDateWasiTotal > 10 ?
                        <ReactPaginate
                          previousLabel={'Previous'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={getTuitionDateWasiTotal / this.state.size}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                        />
                        : null}
                  </nav>
              } */}
            </div>
          </section>
        </div>


        {
          this.state.moreDetailsHandel ?
            <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
              <div className="border border-black shadow-lg modal-container bg-white w-11/12 md:max-w-2xl mx-auto rounded-[40px]  z-50 overflow-y-auto">
                <div className="modal-content py-4 text-left px-6">
                  <div className="modal-close cursor-pointer z-50 rounded-full bg-[#25BF63] h-5 w-5 flex justify-center items-center p-1">
                    <svg
                      onClick={() => this.moreDetailsCloseModul()}
                      className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                      <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                      </path>
                    </svg>
                  </div>
                  <div className='bg-white w-full p-4 rounded-[14px]'>
                    <div className='space-y-4'>
                      <h2 className='text-center py-6 font-bold text-xl'>Student's Payment In Entry</h2>
                      <div className='flex justify-center items-center'>

                        <input className="appearance-none lg:w-36 w-full px-6 py-1.5 rounded-full border  placeholder-gray-800 text-dark-400 focus:outline-none  focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5" id="tutorFee" name='tutorFee' placeholder="Amount" type="Number" required onChange={this.inputChange} />
                      </div>
                      <div className='flex justify-center items-center'>

                        {/* <p>Payment Mode:</p> */}
                        <select className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-44' name='paymentMode' onChange={this.inputChange}>
                          <option value="">Mode</option>
                          <option value="Cash">Cash</option>
                          <option value="Online">Online</option>
                        </select>
                      </div>
                      <div className='flex justify-center items-center'>

                        <input className="appearance-none lg:w-36 w-full px-6 py-1.5 rounded-full border  placeholder-gray-800 text-dark-400 focus:outline-none  focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5" id="remark" name='remark' placeholder="Remark" type="text" required onChange={this.inputChange} />
                      </div>
                      {/* <div className='flex justify-center items-center'>

                      <input className="appearance-none lg:w-36 w-full px-6 py-1.5 rounded-full border  placeholder-gray-800 text-dark-400 focus:outline-none  focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5" id="tutorFee" name='tutorFee' placeholder="Amount" type="Number" required onChange={this.inputChange} />
                    </div> */}
                      <div className='flex justify-center items-center'>
                        <button className='bg-[#25BF63] text-white px-8 py-1 rounded-full' type="button" onClick={() => this.markPaidOrUnPaidSubmit()}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            null
        }
        {
          this.state.moreDetailsHandelUnpaid ?
            <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
              <div className="border border-black shadow-lg modal-container bg-white w-11/12 md:max-w-2xl mx-auto rounded-[40px]  z-50 overflow-y-auto">
                <div className="modal-content py-4 text-left px-6">
                  <div className="modal-close cursor-pointer z-50 rounded-full bg-[#ca2525] h-5 w-5 flex justify-center items-center p-1">
                    <svg
                      onClick={() => this.moreDetailsCloseModulUnPaid()}
                      className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                      <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                      </path>
                    </svg>
                  </div>
                  <div className='bg-white w-full p-4 rounded-[14px]'>
                    <div className='space-y-4'>
                      <h2 className='text-center py-6 font-bold text-xl'>Student's Unpaid Payment Entry</h2>
                      <div className='flex justify-center items-center'>
                        <input className="appearance-none lg:w-36 w-full px-6 py-1.5 rounded-full border  placeholder-gray-800 text-dark-400 focus:outline-none  focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5" id="tutorFee" name='tutorFee' placeholder="Amount" type="Number" required onChange={this.inputChange} />
                      </div>
                      <div className='flex justify-center items-center'>

                        {/* <p>Payment Mode:</p> */}
                        <select className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-44' name='paymentMode' onChange={this.inputChange}>
                          <option value="">Mode</option>
                          <option value="Cash">Cash</option>
                          <option value="Online">Online</option>
                        </select>
                      </div>
                      <div className='flex justify-center items-center'>

                        <input className="appearance-none lg:w-36 w-full px-6 py-1.5 rounded-full border  placeholder-gray-800 text-dark-400 focus:outline-none  focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5" id="remark" name='remark' placeholder="Remark" type="text" required onChange={this.inputChange} />
                      </div>
                      <div className='flex justify-center items-center'>
                        <button className='bg-[#d62727] text-white px-8 py-1 rounded-full' type="button" onClick={() => this.markPaidOrUnPaidSubmitUnPaid()}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            null
        }



      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, kyc, tution, city } = state;
  return {
    users,
    kyc,
    tution,
    city,
    authentication
  };
}
export default connect(mapStateToProps)(paymentManagementstudent);
