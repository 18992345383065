import {
  miningPftShareConstants
} from '../_constants';

export function miningPftShare(state = {}, action) {

  switch (action.type) {
    // case miningPftShareConstants.DEPOSIT_USER_BALANCE_REQUEST:
    //   return {
    //     ...state
    //   };
    // case miningPftShareConstants.DEPOSIT_USER_BALANCE_SUCCESS:
    //   return {
    //     ...state,
    //     addUserSuccess: true
    //   };
    // case miningPftShareConstants.DEPOSIT_USER_BALANCE_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };
    case miningPftShareConstants.ADD_MINING_PROFIT_SHARE_REQUEST:
      return {
        ...state
      };
    case miningPftShareConstants.ADD_MINING_PROFIT_SHARE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case miningPftShareConstants.ADD_MINING_PROFIT_SHARE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case miningPftShareConstants.GET_LIST_MINING_PROFIT_SHARE_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case miningPftShareConstants.GET_LIST_MINING_PROFIT_SHARE_SUCCESS:
      return {
        ...state,
       
        loading: false,
        items: action.users.getMiningPftShareList.list,
        total: action.users.getMiningPftShareList.total
      };
    case miningPftShareConstants.GET_LIST_MINING_PROFIT_SHARE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case miningPftShareConstants.GETALL_MINING_PROFIT_SHARE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case miningPftShareConstants.GETALL_MINING_PROFIT_SHARE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getAllMiningPftShare,
        total: action.users.getAllMiningPftShare
      };
    case miningPftShareConstants.GETALL_MINING_PROFIT_SHARE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case miningPftShareConstants.UPDATE_MINING_PROFIT_SHARE_REQUEST:
      return {
        ...state
      };
    case miningPftShareConstants.UPDATE_MINING_PROFIT_SHARE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case miningPftShareConstants.UPDATE_MINING_PROFIT_SHARE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case miningPftShareConstants.DELETE_MINING_PROFIT_SHARE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case miningPftShareConstants.DELETE_MINING_PROFIT_SHARE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case miningPftShareConstants.DELETE_MINING_PROFIT_SHARE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case miningPftShareConstants.DISABLE_MINING_PROFIT_SHARE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case miningPftShareConstants.DISABLE_MINING_PROFIT_SHARE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case miningPftShareConstants.DISABLE_MINING_PROFIT_SHARE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}