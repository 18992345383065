import { userConstants, cityConstants } from '../_constants';
import { userService, cityService } from '../_services';
import { alertActions } from './';
// import { toast } from 'react-toastify';
export const cityActions = {

    login,
    logout,
    disableCity,
    getAllCity,
    createCity,
    updateCity,
    getCityList,
    getAttendanceMonthData,
    deleteCity,

    disableClass,
    getAllClass,
    createClass,
    updateClass,
    getClassList,
    deleteClass,

    disableBoard,
    getAllBoard,
    createBoard,
    updateBoard,
    getBoardList,
    deleteBoard,
    uploadImageClear
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createCity(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        cityService.createCity(data)
            .then(

                users => {
                    // toast("City Created successfully.")
                    dispatch(alertActions.success("City Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getCityList(reqData));
                    window.location.reload();


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.ADD_CITY_REQUEST } }
    function success(users) { return { type: cityConstants.ADD_CITY_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.ADD_CITY_FAILURE, error } }
}
function updateCity(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        cityService.updateCity(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("City Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getCityList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.UPDATE_CITY_REQUEST } }
    function success(users) { return { type: cityConstants.UPDATE_CITY_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.UPDATE_CITY_FAILURE, error } }
}
function getAllCity() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        cityService.getAllCity()
            .then(
                users => {
                    console.log("getAllCity $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.GETALL_CITY_REQUEST } }
    function success(users) { return { type: cityConstants.GETALL_CITY_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.GETALL_CITY_FAILURE, error } }
}
function getAttendanceMonthData(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        cityService.getAttendanceMonthData(data)
            .then(
                users => {
                    console.log("getAttendanceMonthData $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.GET_ALLENDANCE_MONTH_DATA_REQUEST } }
    function success(users) { return { type: cityConstants.GET_ALLENDANCE_MONTH_DATA_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.GET_ALLENDANCE_MONTH_DATA_FAILURE, error } }
}
function getCityList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        cityService.getCityList(data)
            .then(
                users => {
                    console.log("getCityList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.GET_LIST_CITY_REQUEST } }
    function success(users) { return { type: cityConstants.GET_LIST_CITY_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.GET_LIST_CITY_FAILURE, error } }
}

function deleteCity(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        cityService.deleteCity(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCityList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.DELETE_CITY_REQUEST } }
    function success(users) { return { type: cityConstants.DELETE_CITY_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.DELETE_CITY_FAILURE, error } }
}
function disableCity(data, paginationData) {

    return dispatch => {
        dispatch(request());
        cityService.disableCity(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCityList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.DISABLE_CITY_REQUEST } }
    function success(users) { return { type: cityConstants.DISABLE_CITY_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.DISABLE_CITY_FAILURE, error } }
}


function createClass(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        cityService.createClass(data)
            .then(

                users => {
                    // toast("City Created successfully.")
                    dispatch(alertActions.success("Class Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getClassList(reqData));
                    // window.location.reload();


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.ADD_CLASS_REQUEST } }
    function success(users) { return { type: cityConstants.ADD_CLASS_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.ADD_CLASS_FAILURE, error } }
}
function updateClass(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        cityService.updateClass(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Class Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getClassList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.UPDATE_CLASS_REQUEST } }
    function success(users) { return { type: cityConstants.UPDATE_CLASS_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.UPDATE_CLASS_FAILURE, error } }
}
function getAllClass() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        cityService.getAllClass()
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.GETALL_CLASS_REQUEST } }
    function success(users) { return { type: cityConstants.GETALL_CLASS_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.GETALL_CLASS_FAILURE, error } }
}
function getClassList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        cityService.getClassList(data)
            .then(
                users => {
                    // console.log("getClassList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.GET_LIST_CLASS_REQUEST } }
    function success(users) { return { type: cityConstants.GET_LIST_CLASS_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.GET_LIST_CLASS_FAILURE, error } }
}

function deleteClass(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        cityService.deleteClass(data)
            .then(
                users => {
                    dispatch(alertActions.success("Class Delete Successfully."));
                    dispatch(success(users));
                    dispatch(this.getClassList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.DELETE_CLASS_REQUEST } }
    function success(users) { return { type: cityConstants.DELETE_CLASS_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.DELETE_CLASS_FAILURE, error } }
}
function disableClass(data, paginationData) {

    return dispatch => {
        dispatch(request());
        cityService.disableClass(data)
            .then(
                users => {
                    dispatch(alertActions.success("Class Status Change Successfully."));
                    dispatch(success(users));
                    dispatch(this.getClassList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.DISABLE_CLASS_REQUEST } }
    function success(users) { return { type: cityConstants.DISABLE_CLASS_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.DISABLE_CLASS_FAILURE, error } }
}

function createBoard(data, props) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        cityService.createBoard(data)
            .then(

                users => {
                    // toast("City Created successfully.")
                    dispatch(alertActions.success("Board Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getBoardList(reqData));
                    // window.location.reload();


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                    // window.location.reload();
                    props.history.push('/app/board')
                }
            );
    };
    function request() { return { type: cityConstants.ADD_BOARD_REQUEST } }
    function success(users) { return { type: cityConstants.ADD_BOARD_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.ADD_BOARD_FAILURE, error } }
}
function updateBoard(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        cityService.updateBoard(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Board Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getBoardList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.UPDATE_BOARD_REQUEST } }
    function success(users) { return { type: cityConstants.UPDATE_BOARD_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.UPDATE_BOARD_FAILURE, error } }
}
function getAllBoard() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        cityService.getAllBoard()
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.GETALL_BOARD_REQUEST } }
    function success(users) { return { type: cityConstants.GETALL_BOARD_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.GETALL_BOARD_FAILURE, error } }
}
function getBoardList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        cityService.getBoardList(data)
            .then(
                users => {
                    // console.log("getBOARDList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.GET_LIST_BOARD_REQUEST } }
    function success(users) { return { type: cityConstants.GET_LIST_BOARD_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.GET_LIST_BOARD_FAILURE, error } }
}

function deleteBoard(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        cityService.deleteBoard(data)
            .then(
                users => {
                    dispatch(alertActions.success("Board Delete Successfully."));
                    dispatch(success(users));
                    dispatch(this.getBoardList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.DELETE_BOARD_REQUEST } }
    function success(users) { return { type: cityConstants.DELETE_BOARD_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.DELETE_BOARD_FAILURE, error } }
}
function disableBoard(data, paginationData) {

    return dispatch => {
        dispatch(request());
        cityService.disableBoard(data)
            .then(
                users => {
                    dispatch(alertActions.success("Board Status Change Successfully."));
                    dispatch(success(users));
                    dispatch(this.getBoardList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: cityConstants.DISABLE_BOARD_REQUEST } }
    function success(users) { return { type: cityConstants.DISABLE_BOARD_SUCCESS, users } }
    function failure(error) { return { type: cityConstants.DISABLE_BOARD_FAILURE, error } }
}
