import React from "react";
import { CgLogIn } from 'react-icons/cg';

import Modal from 'react-modal';
const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};


export default function ViewPendingModal(props) {

  let { addPaymentField, handleMoreDetailsClose, viewRowData, fieldsHoliday, errorsHoliday, inputHolidayChange, createHolidaySubmit
  } = props;
  console.log("viewRowDataModal::", viewRowData);

  return (

    <Modal
      isOpen={addPaymentField}
      style={customStyles}
      ariaHideApp={false}
    // contentLabel="Example Modal"
    >


      {viewRowData ?
        <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" >
          <div className="border border-blue-500 modal-container bg-white w-11/12 md:max-w-2xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="modal-content  text-left ">

              <div className="flex justify-between items-center pb-3 bg-blue-100 text-blue-500 text-lg px-6 py-4">
                <p className="text-2xl font-bold">User Details</p>
                <div className="modal-close cursor-pointer font-extrabold z-50 text-blue-700 rounded-full p-3 hover:bg-blue-500 hover:text-white">
                  <svg onClick={() => handleMoreDetailsClose()} className="fill-current " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                    </path>
                  </svg>
                </div>
              </div>




              <div className=" p-4">


                <div className="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Amount : </label>
                  <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsHoliday && !errorsHoliday["amount"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                    id="amount" name="amount" placeholder="Amount" value={fieldsHoliday && fieldsHoliday.amount ? fieldsHoliday.amount : ''} type="text" onChange={inputHolidayChange} />
                  {errorsHoliday && errorsHoliday["amount"] ?
                    <div className="text-xs text-red-500 invalid-feedback">
                      {errorsHoliday["amount"]}
                    </div>
                    : null}
                </div>


                <div className="flex justify-end py-0 space-x-4">
                  <button className="bg-[#6F5617]  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
                    onClick={createHolidaySubmit}
                  >Submit
                    <CgLogIn size={18} className="ml-2" />
                  </button>
                </div>

              </div>






            </div>
          </div>
        </div>
        : null
      }


    </Modal>



  );
}
