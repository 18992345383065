import React, { Component } from 'react';
import { connect } from 'react-redux';
import { transactionActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import UpdateCashFlowTransactionModal from "./components/UpdateCashFlowTransactionModal/UpdateCashFlowTransactionModal";
import UpdateSHOWFlowTransactionModal from "./components/UpdateSHOWFlowTransactionModal/UpdateSHOWFlowTransactionModal";
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
// import Table from "./components/Table/Table";

class CashFlowTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsCashFlowTransaction: {},
      errorsCashFlowTransaction: {},
      typeOfTx: {},
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      txType: 'ALL',
      cashFlowTransactionUpdateModal: false,
      showCashFlow: false,
      tableOpenModal: false,

    }
  }
  componentDidMount() {
    let temp = {
      txType: "RECEIVED_FROM_ADMIN",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size,

    }
    this.props.dispatch(transactionActions.getTxAllAdmin(temp));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.transaction.addUserSuccess) {
      return {
        ...nextProps,
        fieldsCashFlowTransaction: {},
        errorsCashFlowTransaction: {},
        cashFlowTransactionUpdateModal: false,
        showCashFlow: false,
        tableOpenModal: false,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "txType": this.state.txType === "ALL" ? null : this.state.txType,
      "pageNo": data.selected + 1,
      "size": this.state.size,
      "from": this.state.formField && this.state.formField.from ? this.state.formField.from : null,
      "to": this.state.formField && this.state.formField.to ? this.state.formField.to : null
    }
    this.props.dispatch(transactionActions.getTxAllAdmin(datatemp));
  }

  inputChange = (event) => {
    console.log(event.target.name, event.target.value);

    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    console.log(event.target.name, event.target.value);
    this.setState({ formField, errorField });
  }
  inputChangeUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsCashFlowTransaction = this.state.fieldsCashFlowTransaction;
    let errorsCashFlowTransaction = this.state.errorsCashFlowTransaction;
    fieldsCashFlowTransaction[name] = value;
    errorsCashFlowTransaction[name] = "";
    console.log('inputtttttttttttttttttttttttttttttttttttttt', name, " : ", value);
    this.setState({ fieldsCashFlowTransaction, errorsCashFlowTransaction });
  }
  cashFlowTransactionUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationCashFlowTransactionUpdate()) {
      let { id, totalCollection, remark } = this.state.fieldsCashFlowTransaction;
      console.log("asdfasdf_update_-submit  ", id, totalCollection, remark);
      this.props.dispatch(transactionActions.addCashFlow({ userTxId: id, totalCollection: totalCollection, remark: remark, }));
    }
  }
  handleValidationCashFlowTransactionUpdate = () => {
    let fieldsCashFlowTransaction = this.state.fieldsCashFlowTransaction;
    let errorsCashFlowTransaction = {};
    let formIsValid = true;

    //totalCollection
    if (!fieldsCashFlowTransaction["totalCollection"] || fieldsCashFlowTransaction["totalCollection"] === "") {
      formIsValid = false;
      errorsCashFlowTransaction["totalCollection"] = "Cannot be empty";
    }



    this.setState({ errorsCashFlowTransaction: errorsCashFlowTransaction });
    return formIsValid;
  }
  handleCashFlowTransactionHideModal = () => {
    this.setState({ cashFlowTransactionUpdateModal: false });
  }
  handleshowCashFlowHideModal = () => {
    this.setState({ showCashFlow: false });
  }

  handleTableHideModal = () => {
    this.setState({ tableOpenModal: false });
  }

  updatehandleCashFlowTransactionOpenCreateModal = (data) => {

    this.setState({ cashFlowTransactionUpdateModal: true, fieldsCashFlowTransaction: data });
  }

  updatehandleshowCashFlowOpenCreateModal = (data) => {

    this.setState({ showCashFlow: true, showCashFlowData: data });
  }




  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "txType": this.state.txType,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      "from": this.state.formField && this.state.formField.from ? this.state.formField.from : null,
      "to": this.state.formField && this.state.formField.to ? this.state.formField.to : null
    }
    this.props.dispatch(transactionActions.getTxAllAdmin(data));
  }

  handleSearchTrnxDateWise = () => {

    // const { from, to } = this.state.formField;

    let temp = {
      "keyWord": this.state.keyWord,
      "txType": this.state.txType,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      "from": this.state.formField && this.state.formField.from ? this.state.formField.from : null,
      "to": this.state.formField && this.state.formField.to ? this.state.formField.to : null
    }
    this.props.dispatch(transactionActions.getTxAllAdmin(temp));

  }

  inputTypeChange = (e) => {
    e.preventDefault();
    let {
      // name,
      value } = e.target;
    console.log('11111111111111111111', value);
    if (value === "ALL") {
      this.setState({ txType: null })
    } else {
      this.setState({ txType: value })
    }
    console.log('222222222222222222', this.state.txType);
    let data3 = {
      txType: value === "ALL" ? null : value,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      "from": this.state.formField && this.state.formField.from ? this.state.formField.from : null,
      "to": this.state.formField && this.state.formField.to ? this.state.formField.to : null
    }
    console.log("___________data3", data3)
    this.props.dispatch(transactionActions.getTxAllAdmin(data3));
    // if (value === "ALL") {
    //   this.setState({ txType: null })
    // } else {
    //   this.setState({ txType: value })
    // }
    // console.log('this.state.tx______________', this.state.txType);
  }

  // inputTypeChange = (e) => {
  //   e.preventDefault();
  //   let {
  //     // name,
  //     value } = e.target;
  //   let data3 = {
  //     txType: value === "ALL" ? null : value,
  //     "keyWord": this.state.keyWord,
  //     "pageNo": this.state.pageNo,
  //     "size": this.state.size,
  //     "from": this.state.formField && this.state.formField.from ? this.state.formField.from : null,
  //     "to": this.state.formField && this.state.formField.to ? this.state.formField.to : null
  //   }
  //   console.log("___________data3", data3)
  //   this.props.dispatch(transactionActions.getTxAllAdmin(data3));
  //   if (value === "ALL") {
  //     this.setState({ txType: null })
  //   } else {
  //     this.setState({ txType: value })
  //   }
  //   console.log('this.state.tx______________', this.state.txType);
  // }

  render() {

    let { transaction } = this.props;
    let { items, total, loading } = transaction;

    console.log("RENDER________--_:", this.state.fieldsCashFlowTransaction);

    return (

      <>
        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>
        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">

                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="text-lg font-semibold leading-6 tracking-wider text-center capitalize md:text-2xl md:leading-9 text-dark-400">Cashflow Transaction</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>
                  </div>




                  {/* <div className="flex flex-wrap justify-between w-full">
                    <div className="mt-8">
                      <div class="relative lg:w-64 w-full border border-gray-300 text-gray-800 bg-white shadow-lg appearance-none cursor-pointer">
                        <label for="frm-whatever" class="sr-only">My field</label>
                        <select class="appearance-none w-full p-2 bg-white text-sm font-normal"
                          onChange={this.inputTypeChange}
                          id="txType"
                          name="txType">
                          <option value="ALL">Please choose&hellip;</option>
                          <option value="SEND">SEND</option>
                          <option value="RECEIVED">RECEIVED</option>
                          <option value="BUY">BUY</option>
                          <option value="REF">REF</option>
                          <option value="RECEIVED_FROM_ADMIN">RECEIVED_FROM_ADMIN</option>
                          <option value="DEPOSIT">DEPOSIT</option>
                          <option value="WITHDRAW">WITHDRAW</option>
                          <option value="MINING">MINING</option>
                          <option value="TEAM_MINING">TEAM_MINING</option>
                          <option value="WELCOME">WELCOME</option>
                          <option value="DEDUCT_FROM_ADMIN">DEDUCT_FROM_ADMIN</option>
                          <option value="ADMIN_DEPOSIT">ADMIN_DEPOSIT</option>
                        </select>
                        <div class="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>


                    <div className="relative flex flex-wrap justify-between mt-3 space-x-3">
                      <div className="">
                        <label style={{ color: "#6D6997" }} className="text-xs font-bold text-gray-400">From Date</label><br />
                        <input onChange={this.inputChange} name="from" value={this.state.formField["from"] ? this.state.formField["from"] : ''}
                          type="date" className="w-40 p-2 text-gray-600 bg-gray-200 rounded-md outline-none cursor-pointer lg:py-1 focus:text-gray-700 " />
                      </div>
                      <div className="">
                        <label style={{ color: "#6D6997" }} className="text-xs font-bold text-white/50">To Date</label><br />
                        <input onChange={this.inputChange} name="to" value={this.state.formField["to"] ? this.state.formField["to"] : ''}
                          type="date" className="w-40 p-2 text-gray-600 bg-gray-200 rounded-md outline-none cursor-pointer lg:py-1 focus:text-gray-700" />
                      </div>
                      <div className="mt-6">
                        <button onClick={this.handleSearchTrnxDateWise} className="items-center px-4 py-1 text-white bg-blue-500 rounded-md">
                          Search
                        </button>
                      </div>
                    </div>
                  </div> */}


                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="rounded-t bg-lime-300">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Date</th>
                                {/* <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">UserName</th>
                                        <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Ref Code</th> */}
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">User Id</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Amount</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Transaction Type</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Collect Amount</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Remark</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Action</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Pending</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Total Collection</th>


                              </tr>
                            </thead>
                            {/* Table Row Section */}
                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                                {element && element.userId && element.userId.userName ? element.userId.userName : '-'}
                                              </td>
                                              <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                                {element && element.userId && element.userId.refCode ? element.userId.refCode : '-'}
                                              </td> */}
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                              {element && element.coinId && element.coinId.price ? element.coinId.price : 0}
                                              </td> */}
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.userId ? element.userId.userName : null}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element.amount}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element.txType}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">

                                        {element.totalCollection}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element.remark}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="inline-block pl-1">
                                          <button className="px-4 py-2 font-medium tracking-wider text-green-100 bg-green-600 border rounded-md shadow-sm hover:shadow-lg hover:bg-yellow-400" type="button" onClick={() => this.updatehandleCashFlowTransactionOpenCreateModal(element)}  >Collect</button>
                                        </span>
                                        <span className="inline-block pl-1">
                                          <button className="px-4 py-2 font-medium tracking-wider text-green-100 bg-green-600 border rounded-md shadow-sm hover:shadow-lg hover:bg-yellow-400" type="button" onClick={() => this.updatehandleshowCashFlowOpenCreateModal(element.cashCollection ? element.cashCollection : [])}  >Show Details</button>
                                        </span>


                                      </td>


                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <UpdateCashFlowTransactionModal

          cashFlowTransactionUpdateModal={this.state.cashFlowTransactionUpdateModal}
          fieldsCashFlowTransaction={this.state.fieldsCashFlowTransaction}
          errorsCashFlowTransaction={this.state.errorsCashFlowTransaction}
          inputChangeUpdate={this.inputChangeUpdate}
          cashFlowTransactionUpdateSubmit={this.cashFlowTransactionUpdateSubmit}
          handleCashFlowTransactionHideModal={this.handleCashFlowTransactionHideModal}

        />

        <UpdateSHOWFlowTransactionModal
          showCashFlow={this.state.showCashFlow}
          offset={this.state.offset}
          showCashFlowData={this.state.showCashFlowData}
          handleshowCashFlowHideModal={this.handleshowCashFlowHideModal}
        />


      </>

    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, kyc, transaction } = state;
  return {
    users,
    kyc,
    authentication,
    transaction
  };
}
export default connect(mapStateToProps)(CashFlowTransaction);
