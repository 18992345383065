import { userConstants, tutionConstants } from '../_constants';
import { userService, tutionService } from '../_services';
import { alertActions } from './';
// import { toast } from 'react-toastify';
export const tutionActions = {
    login,
    logout,
    closeClassStatusUpdate,
    disableTution,
    getAllTution,
    createTution,
    updateTution,
    getTutionList,
    getAttendanceListByTutor,
    deleteTution,
    uploadImageClear,
    createPinCode,
    updatePinCode,
    deletePinCode,
    getPinCodeList,
    getAllPinCode,
    disablePinCode,
    getAllTutorId,
    getAllStudentId,
    getAllteacherCouresId,
    getSuggestedTutorByTuition,
    getSuggestedTutorByTuition1,
    disabletutorFee,
    getAlltutorFee,
    createtutorFee,
    updatetutorFee,
    gettutorFeeList,
    getCloseClassesListAdmin,
    deletetutorFee,
    tuitionApprove,
    manualTutorChange,
    addAmountInTuition,
    updateTuitionStatusOnGoinOrReject,
    sendDemoRequest,
    getAllPinCode1,
    refuseRequest,
    getTuitionById
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function sendDemoRequest(data) {

    return dispatch => {
        dispatch(request());
        tutionService.sendDemoRequest(data)
            .then(

                users => {
                    console.log('users______________________', users);
                    dispatch(alertActions.success("Successfully create new demo request."));
                    dispatch(success(users));
                    dispatch(this.getWalletList({ id: data.userId }));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.SEND_DEMO_REQUEST_REQUEST } }
    function success(users) { return { type: tutionConstants.SEND_DEMO_REQUEST_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.SEND_DEMO_REQUEST_FAILURE, error } }
}

function createTution(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        tutionService.createTution(data)
            .then(

                users => {
                    // toast("Tution Created successfully.")
                    dispatch(alertActions.success("Tution Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getTutionList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.ADD_TUTION_REQUEST } }
    function success(users) { return { type: tutionConstants.ADD_TUTION_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.ADD_TUTION_FAILURE, error } }
}
function tuitionApprove(data) {
    console.log('data_________________________132456', data);
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        tutionService.tuitionApprove(data)
            .then(

                users => {
                    // dispatch(alertActions.success("Tution Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getTutionList(reqData));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.TUITION_APPROVE_REQUEST } }
    function success(users) { return { type: tutionConstants.TUITION_APPROVE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.TUITION_APPROVE_FAILURE, error } }
}
function updateTution(data, paginationData) {
    console.log("ACTION_____updateTution:::", data);
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        tutionService.updateTution(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Tution Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getTutionList(paginationData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.UPDATE_TUTION_REQUEST } }
    function success(users) { return { type: tutionConstants.UPDATE_TUTION_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.UPDATE_TUTION_FAILURE, error } }
}
function getAllTution() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getAllTution()
            .then(
                users => {
                    console.log("getAllTution $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GETALL_TUTION_REQUEST } }
    function success(users) { return { type: tutionConstants.GETALL_TUTION_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GETALL_TUTION_FAILURE, error } }
}
function getAttendanceListByTutor(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getAttendanceListByTutor(data)
            .then(
                users => {
                    console.log("getAttendanceListByTutor $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GET_ATTENDANCE_LIST_BY_TUTOR_REQUEST } }
    function success(users) { return { type: tutionConstants.GET_ATTENDANCE_LIST_BY_TUTOR_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GET_ATTENDANCE_LIST_BY_TUTOR_FAILURE, error } }
}
function getTuitionById(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getTuitionById(data)
            .then(
                users => {
                    console.log("getTuitionById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GET_TUTION_LIST_BY_ID_REQUEST } }
    function success(users) { return { type: tutionConstants.GET_TUTION_LIST_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GET_TUTION_LIST_BY_ID_FAILURE, error } }
}
function getTutionList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getTutionList(data)
            .then(
                users => {
                    console.log("getTutionList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GET_LIST_TUTION_REQUEST } }
    function success(users) { return { type: tutionConstants.GET_LIST_TUTION_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GET_LIST_TUTION_FAILURE, error } }
}
function getSuggestedTutorByTuition1(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getSuggestedTutorByTuition1(data)
            .then(
                users => {
                    console.log("getSuggestedTutorByTuition1 $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_1_REQUEST } }
    function success(users) { return { type: tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_1_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_1_FAILURE, error } }
}
function getSuggestedTutorByTuition(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getSuggestedTutorByTuition(data)
            .then(
                users => {
                    console.log("getSuggestedTutorByTuition $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_REQUEST } }
    function success(users) { return { type: tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_FAILURE, error } }
}
function manualTutorChange(data, paginationData) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.manualTutorChange(data)
            .then(
                users => {
                    console.log("manualTutorChange $$$$ action:", users);
                    dispatch(success(users));
                    dispatch(this.getTutionList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.MANUAL_TUTOR_CHANGE_REQUEST } }
    function success(users) { return { type: tutionConstants.MANUAL_TUTOR_CHANGE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.MANUAL_TUTOR_CHANGE_FAILURE, error } }
}
function addAmountInTuition(data, paginationData) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.addAmountInTuition(data)
            .then(
                users => {
                    console.log("addAmountInTuition $$$$ action:", users);
                    dispatch(success(users));
                    dispatch(this.getTutionList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.ADD_AMOUNT_IN_TUITION_REQUEST } }
    function success(users) { return { type: tutionConstants.ADD_AMOUNT_IN_TUITION_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.ADD_AMOUNT_IN_TUITION_FAILURE, error } }
}
function updateTuitionStatusOnGoinOrReject(data, paginationData) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.updateTuitionStatusOnGoinOrReject(data)
            .then(
                users => {
                    console.log("updateTuitionStatusOnGoinOrReject $$$$ action:", users);
                    dispatch(success(users));
                    dispatch(this.getTutionList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_REQUEST } }
    function success(users) { return { type: tutionConstants.UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_FAILURE, error } }
}

function deleteTution(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        tutionService.deleteTution(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getTutionList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.DELETE_TUTION_REQUEST } }
    function success(users) { return { type: tutionConstants.DELETE_TUTION_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.DELETE_TUTION_FAILURE, error } }
}
function disableTution(data, paginationData) {

    return dispatch => {
        dispatch(request());
        tutionService.disableTution(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getTutionList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.DISABLE_TUTION_REQUEST } }
    function success(users) { return { type: tutionConstants.DISABLE_TUTION_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.DISABLE_TUTION_FAILURE, error } }
}
function closeClassStatusUpdate(data, paginationData) {

    return dispatch => {
        dispatch(request());
        tutionService.closeClassStatusUpdate(data)
            .then(
                users => {
                    dispatch(tutionActions.getTutionList(paginationData));
                    dispatch(success(users));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.DISABLE_TUTIONCLOSE_REQUEST } }
    function success(users) { return { type: tutionConstants.DISABLE_TUTIONCLOSE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.DISABLE_TUTIONCLOSE_FAILURE, error } }
}

function createPinCode(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        tutionService.createPinCode(data)
            .then(

                users => {
                    dispatch(alertActions.success("PinCodeAdd Successfully."));
                    dispatch(success(users));
                    dispatch(this.getPinCodeList(paginationData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.ADD_PINCODE_REQUEST } }
    function success(users) { return { type: tutionConstants.ADD_PINCODE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.ADD_PINCODE_FAILURE, error } }
}
function updatePinCode(data) {
    console.log("ACTION_____updateTution:::", data);
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        tutionService.updatePinCode(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("PinCode Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getPinCodeList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.UPDATE_PINCODE_REQUEST } }
    function success(users) { return { type: tutionConstants.UPDATE_PINCODE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.UPDATE_PINCODE_FAILURE, error } }
}
function getAllPinCode() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getAllPinCode()
            .then(
                users => {
                    console.log("getAllPinCode $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GETALL_PINCODE_REQUEST } }
    function success(users) { return { type: tutionConstants.GETALL_PINCODE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GETALL_PINCODE_FAILURE, error } }
}
function getAllPinCode1(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getAllPinCode1(data)
            .then(
                users => {
                    console.log("getAllPinCode $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GETALL_PINCODE1_REQUEST } }
    function success(users) { return { type: tutionConstants.GETALL_PINCODE1_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GETALL_PINCODE1_FAILURE, error } }
}
function refuseRequest(data, reqData) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.refuseRequest(data)
            .then(
                users => {
                    console.log("getAllPinCode $$$$ action:", users);
                    dispatch(alertActions.success('Tuition refuse successfully.'));
                    dispatch(success(users));
                    dispatch(this.getTutionList(reqData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.REFUSE_REQUEST_REQUEST } }
    function success(users) { return { type: tutionConstants.REFUSE_REQUEST_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.REFUSE_REQUEST_FAILURE, error } }
}
function getPinCodeList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getPinCodeList(data)
            .then(
                users => {
                    console.log("getPinCodeList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GET_LIST_PINCODE_REQUEST } }
    function success(users) { return { type: tutionConstants.GET_LIST_PINCODE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GET_LIST_PINCODE_FAILURE, error } }
}

function deletePinCode(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        tutionService.deletePinCode(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getPinCodeList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.DELETE_PINCODE_REQUEST } }
    function success(users) { return { type: tutionConstants.DELETE_PINCODE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.DELETE_PINCODE_FAILURE, error } }
}
function disablePinCode(data, paginationData) {

    return dispatch => {
        dispatch(request());
        tutionService.disablePinCode(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getPinCodeList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.DISABLE_PINCODE_REQUEST } }
    function success(users) { return { type: tutionConstants.DISABLE_PINCODE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.DISABLE_PINCODE_FAILURE, error } }
}

function createtutorFee(data, reqData) {
    console.log('data__________data$$$$$$', data);
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        tutionService.createtutorFee(data)
            .then(

                users => {
                    // toast("Tution Created successfully.")
                    dispatch(alertActions.success("Tutor Fee Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.gettutorFeeList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.ADD_TUTORFEE_REQUEST } }
    function success(users) { return { type: tutionConstants.ADD_TUTORFEE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.ADD_TUTORFEE_FAILURE, error } }
}
function updatetutorFee(data, reqData) {
    console.log("ACTION_____updateTution:::", data);
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        tutionService.updatetutorFee(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Tutor Fee Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.gettutorFeeList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.UPDATE_TUTORFEE_REQUEST } }
    function success(users) { return { type: tutionConstants.UPDATE_TUTORFEE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.UPDATE_TUTORFEE_FAILURE, error } }
}
function getAlltutorFee() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getAlltutorFee()
            .then(
                users => {
                    console.log("getAlltutorFee $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GETALL_TUTORFEE_REQUEST } }
    function success(users) { return { type: tutionConstants.GETALL_TUTORFEE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GETALL_TUTORFEE_FAILURE, error } }
}
function gettutorFeeList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.gettutorFeeList(data)
            .then(
                users => {
                    console.log("gettutorFeeList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GET_LIST_TUTORFEE_REQUEST } }
    function success(users) { return { type: tutionConstants.GET_LIST_TUTORFEE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GET_LIST_TUTORFEE_FAILURE, error } }
}

function deletetutorFee(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        tutionService.deletetutorFee(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Tutor Fee Delete Successfully."));
                    dispatch(this.gettutorFeeList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.DELETE_TUTORFEE_REQUEST } }
    function success(users) { return { type: tutionConstants.DELETE_TUTORFEE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.DELETE_TUTORFEE_FAILURE, error } }
}
function disabletutorFee(data, paginationData) {

    return dispatch => {
        dispatch(request());
        tutionService.disabletutorFee(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Tutor Fee Update Status Successfully."));
                    dispatch(this.gettutorFeeList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.DISABLE_TUTORFEE_REQUEST } }
    function success(users) { return { type: tutionConstants.DISABLE_TUTORFEE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.DISABLE_TUTORFEE_FAILURE, error } }
}

function getAllTutorId() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getAllTutorId()
            .then(
                users => {
                    console.log("getAllTutorId $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GETALL_TUTORID_REQUEST } }
    function success(users) { return { type: tutionConstants.GETALL_TUTORID_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GETALL_TUTORID_FAILURE, error } }
}

function getAllStudentId() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getAllStudentId()
            .then(
                users => {
                    console.log("getAllStudentId $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GETALL_STUDENTID_REQUEST } }
    function success(users) { return { type: tutionConstants.GETALL_STUDENTID_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GETALL_STUDENTID_FAILURE, error } }
}

function getAllteacherCouresId() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getAllteacherCouresId()
            .then(
                users => {
                    console.log("getAllteacherCouresId $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GETALL_COURSEID_REQUEST } }
    function success(users) { return { type: tutionConstants.GETALL_COURSEID_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GETALL_COURSEID_FAILURE, error } }
}

function getCloseClassesListAdmin(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        tutionService.getCloseClassesListAdmin(data)
            .then(
                users => {
                    console.log("getCloseClassesListAdmin $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: tutionConstants.GET_LIST_TUTORCLOSE_REQUEST } }
    function success(users) { return { type: tutionConstants.GET_LIST_TUTORCLOSE_SUCCESS, users } }
    function failure(error) { return { type: tutionConstants.GET_LIST_TUTORCLOSE_FAILURE, error } }
}