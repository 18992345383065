import { loginhistoryConstants } from '../_constants';
import { loginhistoryService } from '../_services';
import { alertActions } from '.';
export const loginhistoryActions = {
    getLoginHistoryList
};
function getLoginHistoryList(data) {
    return dispatch => {
        dispatch(request());
        loginhistoryService.getLoginHistoryList(data)
            .then(
                loginhistory => dispatch(success(loginhistory)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: loginhistoryConstants.GET_LOGIN_HISTORY_REQUEST } }
    function success(loginhistory) { return { type: loginhistoryConstants.GET_LOGIN_HISTORY_SUCCESS, loginhistory } }
    function failure(error) { return { type: loginhistoryConstants.GET_LOGIN_HISTORY_FAILURE, error } }
}
