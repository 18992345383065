import { userConstants, holidayConstants } from '../_constants';
import { userService, holidayService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const holidaysActions = {

    login,
    logout,
    disableHolidays,
    getAllHolidays,
    createHolidays,
    updateHolidays,
    getHolidaysList,
    deleteHolidays,
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createHolidays(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        holidayService.createHolidays(data)
            .then(

                users => {
                    // toast("Holidays Created successfully.")
                    dispatch(alertActions.success("Holidays Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getHolidaysList(reqData));
                    window.location.reload();


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: holidayConstants.ADD_HOLIDAY_REQUEST } }
    function success(users) { return { type: holidayConstants.ADD_HOLIDAY_SUCCESS, users } }
    function failure(error) { return { type: holidayConstants.ADD_HOLIDAY_FAILURE, error } }
}
function updateHolidays(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        holidayService.updateHolidays(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Holidays Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getHolidaysList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: holidayConstants.UPDATE_HOLIDAY_REQUEST } }
    function success(users) { return { type: holidayConstants.UPDATE_HOLIDAY_SUCCESS, users } }
    function failure(error) { return { type: holidayConstants.UPDATE_HOLIDAY_FAILURE, error } }
}
function getAllHolidays() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        holidayService.getAllHolidays()
            .then(
                users => {
                    console.log("getAllHolidays $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: holidayConstants.GETALL_HOLIDAY_REQUEST } }
    function success(users) { return { type: holidayConstants.GETALL_HOLIDAY_SUCCESS, users } }
    function failure(error) { return { type: holidayConstants.GETALL_HOLIDAY_FAILURE, error } }
}
function getHolidaysList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        holidayService.getHolidaysList(data)
            .then(
                users => {
                    console.log("getHolidaysList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: holidayConstants.GET_LIST_HOLIDAY_REQUEST } }
    function success(users) { return { type: holidayConstants.GET_LIST_HOLIDAY_SUCCESS, users } }
    function failure(error) { return { type: holidayConstants.GET_LIST_HOLIDAY_FAILURE, error } }
}

function deleteHolidays(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        holidayService.deleteHolidays(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getHolidaysList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: holidayConstants.DELETE_HOLIDAY_REQUEST } }
    function success(users) { return { type: holidayConstants.DELETE_HOLIDAY_SUCCESS, users } }
    function failure(error) { return { type: holidayConstants.DELETE_HOLIDAY_FAILURE, error } }
}
function disableHolidays(data, paginationData) {

    return dispatch => {
        dispatch(request());
        holidayService.disableHolidays(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getHolidaysList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: holidayConstants.DISABLE_HOLIDAY_REQUEST } }
    function success(users) { return { type: holidayConstants.DISABLE_HOLIDAY_SUCCESS, users } }
    function failure(error) { return { type: holidayConstants.DISABLE_HOLIDAY_FAILURE, error } }
}


