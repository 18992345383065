import React, { Component } from 'react';
import { connect } from 'react-redux';
import { tutionActions, userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import CreatetutorFeeModal from './components/CreateTutionModal/CreateTutionModal';
import UpdatetutorFeeModal from './components/UpdateTutionModal/UpdateTutionModal';
import { confirmAlert } from 'react-confirm-alert';
import { HiTrash, HiPencilAlt } from "react-icons/hi";

class TutorFee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldstutorFee: {},
      errorstutorFee: {},
      fieldstutorFeeUpdate: {},
      errorstutorFeeUpdate: {},
      tutorFeeCreateModal: false,
      tutorFeeUpdateModal: false,
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      formData: {
        "name": "",
        "days": "",
        "per": "",
      },
      updateformData: {
        "id": "",
        "name": "",
        "days": "",
        "per": "",
      },
    }
  }
  componentDidMount() {
    console.log('this.props.match.params', this.props.match.params.id);
    let temp = {
      "tuitionId": this.props.match.params.tuitionId,
      // "studentId": this.props.match.params.studentId,
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    console.log('temp________________________', temp);
    this.props.dispatch(tutionActions.gettutorFeeList(temp));

  }


  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.tution.addUserSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "key": "",
        },
        tutorFeeCreateModal: false,
        tutorFeeUpdateModal: false,
        fieldstutorFee: {},
        errorstutorFee: {},
        fieldstutorFeeUpdate: {},
        errorstutorFeeUpdate: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ tutorFeeCreateModal: true });
  }
  handletutorFeeHideModal = () => {
    this.setState({ tutorFeeCreateModal: false });
    this.setState({ tutorFeeUpdateModal: false });
  }

  updatehandleOpenCreateModal = (data) => {

    this.setState({ tutorFeeUpdateModal: true, fieldstutorFeeUpdate: data });
  }

  onDeleteClick = (data) => {
    console.log(data);
    let reqData = {
      "tuitionId": this.props.match.params.id,
      "studentId": this.props.match.params.studentId,
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to Delete?',
      message: 'Are you sure to Delete ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.deletetutorFee(data, reqData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  onDisable = (data) => {
    let tempdata = {
      "id": data.id,
      // "keyWord": this.state.keyWord,
      // "pageNo": this.state.page,
      // // "roll_id": 0,
      // "size": this.state.size
    }
    // this.props.dispatch(tutionActions.disableKeyfeature(tempdata));
    let reqData = {
      "tuitionId": this.props.match.params.id,
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }

    confirmAlert({
      title: 'Confirm to Disable?',
      message: 'Are you sure to Disable ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.disabletutorFee(tempdata, reqData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldstutorFee = this.state.fieldstutorFee;
    let errorstutorFee = this.state.errorstutorFee;
    fieldstutorFee[name] = value;
    errorstutorFee[name] = "";
    console.log(name, value);
    this.setState({ fieldstutorFee, errorstutorFee });
  }

  inputChangeUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldstutorFeeUpdate = this.state.fieldstutorFeeUpdate;
    let errorstutorFeeUpdate = this.state.errorstutorFeeUpdate;
    fieldstutorFeeUpdate[name] = value;
    errorstutorFeeUpdate[name] = "";
    this.setState({ fieldstutorFeeUpdate, errorstutorFeeUpdate });
  }

  tutorFeeubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationtutorFee()) {
      let { tutorFee, details, month } = this.state.fieldstutorFee;
      // console.log("asdfasdf  ", tuitionId, days, per);

      let reqData = {
        "tuitionId": this.props.match.params.id,
        "studentId": this.props.match.params.studentId,
        "keyWord": "",
        "pageNo": this.state.page,
        "size": this.state.size
      }

      this.props.dispatch(tutionActions.createtutorFee({ tuitionId: this.props.match.params.tuitionId, studentId: this.props.match.params.studentId, tutorFee: tutorFee, details: details, month: month }, reqData, this.props));
    }
  }

  tutorFeeUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationtutorFeeUpdate()) {
      let { tuitionId, tutorFee, details, month, id } = this.state.fieldstutorFeeUpdate;
      // console.log("asdfasdf  ", tuitionId, days);
      let reqData = {
        "tuitionId": this.props.match.params.id,
        "keyWord": "",
        "pageNo": this.state.page,
        "size": this.state.size
      }
      this.props.dispatch(tutionActions.updatetutorFee({ tuitionId: tuitionId, tutorFee: tutorFee, details: details, month: month, id: id }, reqData, this.props));
    }
  }
  handleValidationtutorFeeUpdate = () => {
    let fieldstutorFeeUpdate = this.state.fieldstutorFeeUpdate;
    let errorstutorFeeUpdate = {};
    let formIsValid = true;

    //name
    if (!fieldstutorFeeUpdate["tuitionId"] || fieldstutorFeeUpdate["tuitionId"] === "") {
      formIsValid = false;
      errorstutorFeeUpdate["tuitionId"] = "Cannot be empty";
    }

    //tutorFee
    if (!fieldstutorFeeUpdate["tutorFee"]) {
      formIsValid = false;
      errorstutorFeeUpdate["tutorFee"] = "Please enter tutorFee!";
    }

    //details
    if (!fieldstutorFeeUpdate["details"]) {
      formIsValid = false;
      errorstutorFeeUpdate["details"] = "Please enter details!";
    }



    //month
    if (!fieldstutorFeeUpdate["month"]) {
      formIsValid = false;
      errorstutorFeeUpdate["month"] = "Please enter months!";
    }

    this.setState({ errorstutorFeeUpdate: errorstutorFeeUpdate });
    return formIsValid;
  }

  handleValidationtutorFee = () => {
    let fieldstutorFee = this.state.fieldstutorFee;
    let errorstutorFee = {};
    let formIsValid = true;

    // //tuitionId
    // if (!fieldstutorFee["tuitionId"] || fieldstutorFee["tuitionId"] === "") {
    //   formIsValid = false;
    //   errorstutorFee["tuitionId"] = "Cannot be empty";
    // }

    //from
    if (!fieldstutorFee["tutorFee"]) {
      formIsValid = false;
      errorstutorFee["tutorFee"] = "Please enter tutorFee!";
    }

    //details
    if (!fieldstutorFee["details"]) {
      formIsValid = false;
      errorstutorFee["details"] = "Please enter details!";
    }

    //month
    if (!fieldstutorFee["month"]) {
      formIsValid = false;
      errorstutorFee["month"] = "Please enter months!";
    }

    this.setState({ errorstutorFee: errorstutorFee });
    return formIsValid;
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value.toLowerCase(),
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.gettutorFeeList(data));
  }


  render() {

    let { tution } = this.props;
    let { itemsFee, total, items, getAllStudentId, getAllTutorId } = tution;
    console.log("items in render", JSON.stringify(tution));

    return (
      <>

        <div >
          <LoadingOverlay
            active={false}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {/* Main Content */}
        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                {/* Wallet Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">Student Fees History</h3>
                    </div>
                    {/* <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>
                    <button className="bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border rounded flex justify-end" onClick={() => this.handleOpenCreateModal()}> ADD </button> */}
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          {/* <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-sm font-semibold text-gray-700 capitalize tracking-wider">Date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-sm font-semibold text-gray-700 capitalize tracking-wider">Tuition ID</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-sm font-semibold text-gray-700 capitalize tracking-wider">student name</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-sm font-semibold text-gray-700 capitalize tracking-wider">tutor Fee</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-sm font-semibold text-gray-700 capitalize tracking-wider">Status</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-sm font-semibold text-gray-700 capitalize tracking-wider">Enable/Disable</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-sm font-semibold text-gray-700 capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                itemsFee && itemsFee.length > 0 ?
                                  itemsFee.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap text-start text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-start text-sm text-gray-600">
                                        {element && element.tuitionId && element.tuitionId.tutionNo ? element.tuitionId.tutionNo : "-"}</td>

                                      <td className="px-6 py-3 whitespace-nowrap text-start text-sm text-gray-600">
                                        {element && element.studentId && element.studentId.lastName ? element.studentId.lastName : "-"}</td>

                                      <td className="px-6 py-3 whitespace-nowrap text-start text-sm text-gray-600">
                                        {element && element.tutorFee ? element.tutorFee : "-"}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-start text-sm text-gray-600">
                                        {element.isDisable && element.isDisable === true ? "True" : "False"}</td>

                                      <td className="px-2 py-3   text-sm text-gray-600 whitespace-nowrap text-start">
                                        <label class="flex justify-center items-center cursor-pointer targetablepx-4 tooltip">
                                          <div class="relative" onClick={() => this.onDisable(element)}>
                                            <input type="checkbox" id="toggleB" class="sr-only" />
                                            {element && element.isDisable === false ? <div class="block bg-green-600 w-10 h-6 rounded-full"></div> : <div class="block bg-red-600 w-10 h-6 rounded-full"></div>}
                                            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>{element && element.isDisable === true ? "enable" : "disable"}</span>
                                          </div>
                                        </label>
                                      </td>

                                      <td className=" px-2 py-3 mt-2  flex justify-center items-center text-gray-600 whitespace-nowrap">


                                        <span className="flex justify-center pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-500 cursor-pointer" onClick={() => this.onDeleteClick({ id: element.id })}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Delete</span>
                                            <HiTrash className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>
                                      </td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table> */}

                          <table className='bg-white divide-y-2 rounded-[14px] w-full'>
                            <thead>
                              <tr>
                                <th className="text-[14px] px-2 py-2 text-left whitespace-pre">Name <br /> Mobile Number </th>
                                <th className="text-[14px] px-2 text-left whitespace-pre"> Class & Subject</th>
                                <th className="text-[14px] px-2 text-left whitespace-pre">Class Reference </th>
                                <th className="text-[14px] px-2 text-left whitespace-pre"> Assigned Tutor</th>
                                <th className="text-[14px] px-2 text-left whitespace-pre">Tuition Fee</th>
                                <th className="text-[14px] px-2 text-left whitespace-pre">Start Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                itemsFee && itemsFee.length > 0 ?
                                  itemsFee.map((element, index) =>

                                  (<React.Fragment key={element.id}>
                                    <tr>
                                      <td className='text-[14px] px-2  text-left whitespace-nowrap'>
                                        <p> {element && element.tuitionId && element.tuitionId.studentId && element.tuitionId.studentId.firstName ? element.tuitionId.studentId.firstName : "NA"}</p>
                                        <p>{element && element.tuitionId && element.tuitionId.studentId && element.tuitionId.studentId.mobNo ? element.tuitionId.studentId.mobNo : "NA"}</p>
                                      </td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                                        <p>{element && element.tuitionId && element.tuitionId.classId && element.tuitionId.classId.name ? element.tuitionId.classId.name : "NA"} / {element && element.tuitionId && element.tuitionId.teacherCouresId && element.tuitionId.teacherCouresId[0] && element.tuitionId.teacherCouresId[0].name ? element.tuitionId.teacherCouresId[0].name : "NA"}</p>
                                      </td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                                        <p>{element && element.tuitionId && element.tuitionId.classReference ? element.tuitionId.classReference : "NA"}</p>
                                      </td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                                        <p>{element && element.tuitionId && element.tuitionId.tutorId && element.tuitionId.tutorId.fullName ? element.tuitionId.tutorId.fullName : "NA"}</p>
                                        <p>{element && element.tuitionId && element.tuitionId.tutorId && element.tuitionId.tutorId.mobNo ? element.tuitionId.tutorId.mobNo : "NA"}</p>
                                      </td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                                        <p>{element && element.tuitionId && element.tuitionId.fee ? element.tuitionId.fee : "NA"}/-</p>
                                        <p>{element && element.tuitionId && element.tuitionId.feeStatus ? element.tuitionId.feeStatus : "NA"}</p></td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <nav className="relative z-0 flex justify-end mt-5">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>


                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <CreatetutorFeeModal

          tutorFeeCreateModal={this.state.tutorFeeCreateModal}
          fieldstutorFee={this.state.fieldstutorFee}
          errorstutorFee={this.state.errorstutorFee}
          inputChange={this.inputChange}
          tutorFeeubmit={this.tutorFeeubmit}
          handletutorFeeHideModal={this.handletutorFeeHideModal}
          items={items}

        />



      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, tution } = state;
  return {
    users,
    tution,
    authentication
  };
}
export default connect(mapStateToProps)(TutorFee);
