import React, { Component } from 'react';
import { connect } from 'react-redux';
import { holidaysActions, userActions, statedataActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
// import DialogExample from "./components/DialogExample/DialogExample";
// import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateHolidayModal from "./components/CreateClassModal/CreateHolidayModal";
import UpdateHolidayModal from "./components/UpdateClassModal/UpdateHolidayModal";
// import ViewMoreDetailsModal from './components/ViewClassModal/ViewHolidayModal';
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



class Holiday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fieldskyc: {},
      // errorskyc: {},
      stateId: null,
      fieldsHoliday: {
        options: [""]
      },
      errorsHoliday: {
        options: [""]
      },
      fieldsUpdateHoliday: {},
      errorsUpdateHoliday: {},
      viewRowData: {},
      cityCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateHolidayModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(holidaysActions.getHolidaysList(data));
    // this.props.dispatch(statedataActions.getAllStateData(data));
    // this.props.dispatch(holidaysActions.createSport(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.holiday.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsHoliday: {},
        errorsHoliday: {},
        cityCreateModal: false,
        UpdateHolidayModal: false,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(holidaysActions.getHolidaysList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(holidaysActions.getHolidaysList(data));
  }
  disableHoliday = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.name} city?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(holidaysActions.disableHolidays(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteHoliday = (data) => {
    let datatemp = {
      "id": data._id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.year} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(holidaysActions.deleteHolidays(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleSelectState = (data) => {

    this.setState({ stateId: data });
  }
  handleOpenCreateModalMoreDetails = (data) => {

    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }
  handleOpenHolidayUpdateModal = (data) => {
    this.setState({ UpdateHolidayModal: true, fieldsUpdateHoliday: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(holidaysActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleHolidayHideModal = () => {
    this.setState({ cityCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleHolidayUpdateHideModal = () => {
    this.setState({ UpdateHolidayModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleHideHolidayUpdateModal = () => {
    this.setState({ UpdateHolidayModal: false });
  }
  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ cityCreateModal: true });
  }
  inputHolidayChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsHoliday = this.state.fieldsHoliday;
    let errorsHoliday = this.state.errorsHoliday;
    fieldsHoliday[name] = value;
    errorsHoliday[name] = "";
    console.log(name, value);
    this.setState({ fieldsHoliday, errorsHoliday });
  }

  inputChangeUpdateHoliday = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdateHoliday = this.state.fieldsUpdateHoliday;
    let errorsUpdateHoliday = this.state.errorsUpdateHoliday;
    fieldsUpdateHoliday[name] = value;
    errorsUpdateHoliday[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateHoliday, errorsUpdateHoliday });
  }



  createHolidaySubmit = () => {
    const { fieldsHoliday } = this.state;

    if (fieldsHoliday && fieldsHoliday.options) {
      // Create the request data object


      let fieldsOptionData = fieldsHoliday && fieldsHoliday.options && fieldsHoliday.options.length > 0 ? fieldsHoliday.options.map((element) => (element.name)) : null

      // console.log('fieldsOptionData____________________', fieldsOptionData);


      // let datamap = fieldsHoliday && fieldsHoliday.options

      // console.log("datamapdatamapdatamap", datamap);
      // const convertedData = datamap.map((item) => (
      //   item
      // ));
      // console.log('convertedData____________________________', convertedData);
      // const reqData = convertedData
      const reqData = {
        // description: fieldsOptionData,
        year: this.state.fieldsHoliday.year,
        month: this.state.fieldsHoliday.month,
        date: this.state.fieldsHoliday.date,
        description: this.state.fieldsHoliday.description
      }
      console.log("reqDatareqDatareqData", reqData);
      this.props.dispatch(holidaysActions.createHolidays(reqData));

    }
  };

  addContentField = () => {
    const { fieldsHoliday } = this.state;
    fieldsHoliday.options.push('')
    this.setState({ fieldsHoliday })
    // console.log("fieldsHoliday  ", fieldsHoliday);
  }

  deleteContentField = (index) => {
    const { fieldsHoliday } = this.state;
    fieldsHoliday.options.splice(index, 1)
    this.setState({ fieldsHoliday });
  }

  setCurrentIndex = (currentConetntIndex) => {
    this.setState({ currentConetntIndex: currentConetntIndex });
    // console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e, index) => {
    e.preventDefault();
    const { value } = e.target;
    const { fieldsHoliday } = this.state;

    // Make a shallow copy of the options array
    const updatedOptions = [...fieldsHoliday.options];

    // Update the 'name' property of the object at the specified index
    updatedOptions[index] = { ...updatedOptions[index], name: value };

    this.setState({
      fieldsHoliday: {
        ...fieldsHoliday,
        options: updatedOptions,
      },
    });
  };

  // navigate = (data) => {

  //   this.props.history.push('/app/series/' + data.sportId);
  // }

  updateHolidaySubmit = () => {

    if (this.handleValidationUpdateHoliday()) {
      let reqData = {
        "id": this.state.fieldsUpdateHoliday._id,
        "year": this.state.fieldsUpdateHoliday.year,
        "month": this.state.fieldsUpdateHoliday.month,
        "date": this.state.fieldsUpdateHoliday.date,
        "description": this.state.fieldsUpdateHoliday.description

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      console.log("reqDatareqDatareqData============>", reqData);
      this.props.dispatch(holidaysActions.updateHolidays(reqData, paginationData));
    }

  }
  handleValidationUpdateHoliday = () => {
    let fieldsUpdateHoliday = this.state.fieldsUpdateHoliday;
    let errorsUpdateHoliday = {};
    let formIsValid = true;

    //year
    if (!fieldsUpdateHoliday["year"] || fieldsUpdateHoliday["year"] === "") {
      formIsValid = false;
      errorsUpdateHoliday["year"] = "Cannot be empty";
    }

    //month
    if (!fieldsUpdateHoliday["month"] || fieldsUpdateHoliday["month"] === "") {
      formIsValid = false;
      errorsUpdateHoliday["month"] = "Cannot be empty";
    }

    //description
    if (!fieldsUpdateHoliday["description"] || fieldsUpdateHoliday["description"] === "") {
      formIsValid = false;
      errorsUpdateHoliday["description"] = "Cannot be empty";
    }



    console.log("errorsUpdateHolidayerrorsUpdateHoliday_errorsUpdateHolidayerrorsUpdateHoliday:", errorsUpdateHoliday);

    this.setState({ errorsUpdateHoliday: errorsUpdateHoliday });
    return formIsValid;
  }
  handleValidationHoliday = () => {
    let fieldsHoliday = this.state.fieldsHoliday;
    let errorsHoliday = {};
    let formIsValid = true;


    if (!fieldsHoliday["name"] || fieldsHoliday["name"] === "") {
      formIsValid = false;
      errorsHoliday["name"] = "Cannot be empty name";
    }
    if (!fieldsHoliday["shortName"] || fieldsHoliday["shortName"] === "") {
      formIsValid = false;
      errorsHoliday["shortName"] = "Cannot be empty shortName";
    }
    if (!fieldsHoliday["lat"] || fieldsHoliday["lat"] === "") {
      formIsValid = false;
      errorsHoliday["lat"] = "Cannot be empty lat";
    }
    if (!fieldsHoliday["long"] || fieldsHoliday["long"] === "") {
      formIsValid = false;
      errorsHoliday["long"] = "Cannot be empty long";
    }



    this.setState({ errorsHoliday: errorsHoliday });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  render() {

    let { city, statedata, holiday } = this.props;
    let { total, loading, items } = holiday;
    let { allStatesItems } = statedata;

    console.log("itemsitemsitemsitemsitemsitems", this.state.fieldsUpdateHoliday);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400"> Holiday List</h3>
                    </div>



                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute text-gray-600 top-4 left-5"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="w-full px-3 py-3 pl-10 font-normal placeholder-gray-600 transition duration-150 ease-in-out bg-gray-200 border rounded-full appearance-none sm:w-64 border-dark-400 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-600 text-normal sm:leading-5" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>

                    <div className="flex justify-end ...">
                      <button className="flex justify-end py-1 font-bold text-white bg-[#FBB00C] border rounded px-7 "
                        style={{ margin: "2px 0px 10px 15px" }}
                        onClick={() => this.handleOpenCreateModal()}> ADD </button>
                    </div>

                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-[#000000] capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-[#000000] capitalize whitespace-nowrap">Date</th>
                                {/* <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-[#000000] capitalize whitespace-nowrap">year</th>
                                <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-[#000000] capitalize whitespace-nowrap">month</th> */}
                                <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-[#000000] capitalize whitespace-nowrap">description</th>
                                <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-[#000000] capitalize whitespace-nowrap">Actions</th>

                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.date ? element.date : "NA"}</td>
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.year ? element.year : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.month ? element.month : "NA"}</td> */}
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.description ? element.description : "NA"}</td>






                                      <td className="px-2 py-3 whitespace-nowrap text-gray-600 inline-block">


                                        <span className="inline-block pl-1">
                                          <button className="bg-[#FBB00C] text-sm px-4 py-2 shadow-sm font-medium tracking-wider border text-[#000000] rounded-md hover:shadow-lg" onClick={() => this.handleOpenHolidayUpdateModal(element)}>Update</button>
                                        </span>
                                        <span className="inline-block pl-1">
                                          <button className="bg-[#DC2626] text-sm px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-red-400" onClick={() => this.deleteHoliday(element)}>Delete</button>
                                        </span>

                                      </td>



                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        {/* <DialogExample /> */}

        <CreateHolidayModal
          cityCreateModal={this.state.cityCreateModal}
          fieldsHoliday={this.state.fieldsHoliday}
          errorsHoliday={this.state.errorsHoliday}
          inputHolidayChange={this.inputHolidayChange}
          handleSelectState={this.handleSelectState}
          createHolidaySubmit={this.createHolidaySubmit}
          handleHolidayHideModal={this.handleHolidayHideModal}
          handleFile={this.handleFile}
          allStatesItems={allStatesItems}
          addContentField={this.addContentField}
          setCurrentIndex={this.setCurrentIndex}
          deleteContentField={this.deleteContentField}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
        />

        <UpdateHolidayModal
          UpdateHolidayModal={this.state.UpdateHolidayModal}
          fieldsUpdateHoliday={this.state.fieldsUpdateHoliday}
          errorsUpdateHoliday={this.state.errorsUpdateHoliday}
          inputChangeUpdateHoliday={this.inputChangeUpdateHoliday}
          updateHolidaySubmit={this.updateHolidaySubmit}
          handleHolidayUpdateHideModal={this.handleHolidayUpdateHideModal}
          handleFile={this.handleFile}
          allStatesItems={allStatesItems}

        />


      </>

    );
  }
}
function mapStateToProps(state) {
  const { city, users, statedata, holiday } = state;
  return {
    city,
    users,
    statedata,
    holiday
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Holiday);
