
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';

export const dashboardService = {
    addAminOwnBalance,
    getDashboardData,
    getAdminDetails,
    updateOwnPasswordAdmin,
    getTermsAndConditionList,
    todayDemoPendingList,
    todayDemoCompletedList,
    todayDueDatesList,
    totalTuitionEarningMonthWasi,
    totalTuitionEarning,
    sendDemoRequest,
    getTutorFeeHistory,
    getStudentFeeHistory,
};
function logout() {
    // remove user from local storage to log user out
    window.sessionStorage.removeItem('adminuser');
    // window.location.href = "#/home"
    history.push(`#/login`);
    window.location.reload();
}


function updateOwnPasswordAdmin(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateOwnPasswordAdmin`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));
            console.log('res_____________________', res);

            let userObj = {
                updateOwnPasswordAdmin: res.message
            }

            console.log("I am in service updateOwnPasswordAdmin", userObj);
            return userObj;
        });
}


function todayDemoPendingList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/todayDemoPendingList`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                todayDemoPendingList: res.data
            }

            console.log("I am in service todayDemoPendingList", userObj);
            return userObj;
        });
}


function todayDemoCompletedList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/todayDemoCompletedList`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                todayDemoCompletedList: res.data
            }

            console.log("I am in service todayDemoCompletedList", userObj);
            return userObj;
        });
}



function todayDueDatesList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/todayDueDatesList`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                todayDueDatesList: res.data
            }

            console.log("I am in service todayDueDatesList", userObj);
            return userObj;
        });
}




function totalTuitionEarning(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/totalTuitionEarning`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                totalTuitionEarning: res.data
            }

            console.log("I am in service totalTuitionEarning", userObj);
            return userObj;
        });
}

function totalTuitionEarningMonthWasi(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/totalTuitionEarningMonthWasi`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                totalTuitionEarningMonthWasi: res.data
            }

            console.log("I am in service totalTuitionEarningMonthWasi", userObj);
            return userObj;
        });
}



function sendDemoRequest(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/sendDemoRequest`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                totalTuitionEarningMonthWasi: res.data
            }

            console.log("I am in service sendDemoRequest", userObj);
            return userObj;
        });
}



function getStudentFeeHistory(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getStudentFeeHistory`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                getStudentFeeHistory: res.data
            }

            console.log("I am in service getStudentFeeHistory", userObj);
            return userObj;
        });
}


function getTutorFeeHistory(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTutorFeeHistory`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                totalTuitionEarningMonthWasi: res.data
            }

            console.log("I am in service getTutorFeeHistory", userObj);
            return userObj;
        });
}
function getAdminDetails() {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
    }
    return fetch(CONST.BACKEND_URL + `/getAdminDetails`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                getAdminDetails: res.data
            }

            console.log("I am in service getAdminDetails", userObj);
            return userObj;
        });
}

function addAminOwnBalance(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/addAminOwnBalance`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                addAminOwnBalance: res.message
            }

            console.log("I am in service addAminOwnBalance", userObj);
            return userObj;
        });
}

function getTermsAndConditionList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTermsAndConditionList`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                getTermsAndConditionList: res.data
            }

            console.log("I am in service getTermsAndConditionList", userObj);
            return userObj;
        });
}

async function getDashboardData(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getDashboardData`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log(JSON.stringify(res));

            let userObj = {
                getDashboardData: res.data
            }

            console.log("I am in service getDashboardData", userObj);
            return userObj;
        });
}


// function getAllEmoji(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });

//     const requestOptions = {
//         method: "GET",
//         headers: header
//     }
//     return fetch(CONST.BACKEND_URL + `/getAllEmoji`, requestOptions)
//         .then(handleResponse)
//         .then(res => {
//             let userObj = {
//                 dashboarddata: res.data
//             }
//             return userObj;
//         });
// }
// function adddashboard(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });

//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/api/adddashboard`, requestOptions)
//         .then(handleResponse)
//         .then(res => {
//             let userObj = {
//                 adddashboardres: res.data
//             }
//             return userObj;
//         });
// }
// function deletedashboardService(data) {
//     //console.log("Enter into service ", data);

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });

//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/api/deletedashboard`, requestOptions)
//         .then(handleResponse)
//         .then(res => {
//             let userObj = {
//                 adddashboardres: res.data
//             }
//             return userObj;
//         });
// }
// function getAllResourceList() {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header
//     }
//     return fetch(CONST.BACKEND_URL + `/api/listresoures`, requestOptions)
//         .then(handleResponse)
//         .then(res => {
//             console.log(JSON.stringify(res));

//             let userObj = {
//                 listOfResource: res.data
//             }
//             return userObj;
//         });
// }
// function getAssignedResourceList(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/api/getassignrole`, requestOptions)
//         .then(handleResponse)
//         .then(res => {
//             console.log(JSON.stringify(res));

//             let userObj = {
//                 listOfAssignedResource: res.data
//             }
//             return userObj;
//         });
// }
// function saveAssignResourcedashboard(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });

//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/api/assignrole`, requestOptions)
//         .then(handleResponse)
//         .then(res => {
//             let userObj = {
//                 updateResource: res
//             }
//             return userObj;
//         });
// }
// function udpateStatus(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/api/updatedashboardstatus`, requestOptions)
//         .then(handleResponse)
//         .then(res => {
//             let userObj = {
//                 updatestatus: res.data
//             }
//             return userObj;
//         });
// }
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadata ", data);

        return data;
    });
}