export const cmcCmcCoinConstants = {

    GETALL_CMC_COIN_REQUEST: 'GETALL_CMC_COIN_REQUEST',
    GETALL_CMC_COIN_SUCCESS: 'GETALL_CMC_COIN_SUCCESS',
    GETALL_CMC_COIN_FAILURE: 'GETALL_CMC_COIN_FAILURE',

    GET_LIST_CMC_COIN_REQUEST: 'GET_LIST_CMC_COIN_REQUEST',
    GET_LIST_CMC_COIN_SUCCESS: 'GET_LIST_CMC_COIN_SUCCESS',
    GET_LIST_CMC_COIN_FAILURE: 'GET_LIST_CMC_COIN_FAILURE',

    // DEPOSIT_USER_BALANCE_REQUEST: 'DEPOSIT_USER_BALANCE_REQUEST',
    // DEPOSIT_USER_BALANCE_SUCCESS: 'DEPOSIT_USER_BALANCE_SUCCESS',
    // DEPOSIT_USER_BALANCE_FAILURE: 'DEPOSIT_USER_BALANCE_FAILURE',

    ADD_CMC_COIN_REQUEST: 'ADD_CMC_COIN_REQUEST',
    ADD_CMC_COIN_SUCCESS: 'ADD_CMC_COIN_SUCCESS',
    ADD_CMC_COIN_FAILURE: 'ADD_CMC_COIN_FAILURE',

    DELETE_CMC_COIN_REQUEST: 'DELETE_CMC_COIN_REQUEST',
    DELETE_CMC_COIN_SUCCESS: 'DELETE_CMC_COIN_SUCCESS',
    DELETE_CMC_COIN_FAILURE: 'DELETE_CMC_COIN_FAILURE',

    UPDATE_CMC_COIN_REQUEST: 'UPDATE_CMC_COIN_REQUEST',
    UPDATE_CMC_COIN_SUCCESS: 'UPDATE_CMC_COIN_SUCCESS',
    UPDATE_CMC_COIN_FAILURE: 'UPDATE_CMC_COIN_FAILURE',

    DISABLE_CMC_COIN_REQUEST: 'DISABLE_CMC_COIN_REQUEST',
    DISABLE_CMC_COIN_SUCCESS: 'DISABLE_CMC_COIN_SUCCESS',
    DISABLE_CMC_COIN_FAILURE: 'DISABLE_CMC_COIN_FAILURE',

};
