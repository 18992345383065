export const miningPftShareConstants = {

    GETALL_MINING_PROFIT_SHARE_REQUEST: 'GETALL_MINING_PROFIT_SHARE_REQUEST',
    GETALL_MINING_PROFIT_SHARE_SUCCESS: 'GETALL_MINING_PROFIT_SHARE_SUCCESS',
    GETALL_MINING_PROFIT_SHARE_FAILURE: 'GETALL_MINING_PROFIT_SHARE_FAILURE',

    GET_LIST_MINING_PROFIT_SHARE_REQUEST: 'GET_LIST_MINING_PROFIT_SHARE_REQUEST',
    GET_LIST_MINING_PROFIT_SHARE_SUCCESS: 'GET_LIST_MINING_PROFIT_SHARE_SUCCESS',
    GET_LIST_MINING_PROFIT_SHARE_FAILURE: 'GET_LIST_MINING_PROFIT_SHARE_FAILURE',

    // DEPOSIT_USER_BALANCE_REQUEST: 'DEPOSIT_USER_BALANCE_REQUEST',
    // DEPOSIT_USER_BALANCE_SUCCESS: 'DEPOSIT_USER_BALANCE_SUCCESS',
    // DEPOSIT_USER_BALANCE_FAILURE: 'DEPOSIT_USER_BALANCE_FAILURE',

    ADD_MINING_PROFIT_SHARE_REQUEST: 'ADD_MINING_PROFIT_SHARE_REQUEST',
    ADD_MINING_PROFIT_SHARE_SUCCESS: 'ADD_MINING_PROFIT_SHARE_SUCCESS',
    ADD_MINING_PROFIT_SHARE_FAILURE: 'ADD_MINING_PROFIT_SHARE_FAILURE',

    DELETE_MINING_PROFIT_SHARE_REQUEST: 'DELETE_MINING_PROFIT_SHARE_REQUEST',
    DELETE_MINING_PROFIT_SHARE_SUCCESS: 'DELETE_MINING_PROFIT_SHARE_SUCCESS',
    DELETE_MINING_PROFIT_SHARE_FAILURE: 'DELETE_MINING_PROFIT_SHARE_FAILURE',

    UPDATE_MINING_PROFIT_SHARE_REQUEST: 'UPDATE_MINING_PROFIT_SHARE_REQUEST',
    UPDATE_MINING_PROFIT_SHARE_SUCCESS: 'UPDATE_MINING_PROFIT_SHARE_SUCCESS',
    UPDATE_MINING_PROFIT_SHARE_FAILURE: 'UPDATE_MINING_PROFIT_SHARE_FAILURE',

    DISABLE_MINING_PROFIT_SHARE_REQUEST: 'DISABLE_MINING_PROFIT_SHARE_REQUEST',
    DISABLE_MINING_PROFIT_SHARE_SUCCESS: 'DISABLE_MINING_PROFIT_SHARE_SUCCESS',
    DISABLE_MINING_PROFIT_SHARE_FAILURE: 'DISABLE_MINING_PROFIT_SHARE_FAILURE',

};
