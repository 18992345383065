import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateAddUserModal from "./components/CreateAddUserModal/CreateAddUserModal";
import UpdatePasswordUser from "./components/CreateUpdatePasswordUserModal/CreateUpdatePasswordUser";
import AddUserBalanceModal from "./components/AddUserBalanceModal/AddUserBalanceModal";
import UpdateUserNameModal from "./components/UpdateUserNameModal/UpdateUserNameModal";
import UpdateEmailModal from "./components/UpdateEmailModal/UpdateEmailModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd, HiEye, HiOutlineServer, HiOutlineLogin, HiUserCircle, HiOutlineMail } from "react-icons/hi";
// import { RiWallet2Line, RiMailLockLine, RiLockPasswordLine } from "react-icons/ri";
import "./style.css"
// import { RiMailCloseFill, RiMailCheckFill } from "react-icons/ri";

class TutorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsUserName: {},
      errorsUserName: {},
      fieldsEmail: {},
      errorsEmail: {},
      addBalanceDetails: {},
      fieldsAddUser: {},
      errorsAddUser: {},
      fieldsUpdatePassword: {},
      errorsUpdatePassword: {},
      viewRowData: {},
      updateUserNameDetails: {},
      updateEmailDetails: {},
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      updateUserNameModal: false,
      updateEmailModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTuituinListTutorWasi(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsAddUser: {},
        errorsAddUser: {},
        addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        adBalanceModal: false,
        updateUserNameModal: false,
        updateEmailModal: false,
        fieldsUserName: {},
        errorsUserName: {},
        fieldsEmail: {},
        errorsEmail: {},

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      // "userType": "ACTIVE",
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      // "userType": "ACTIVE",
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": value.toLowerCase(),
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(data));
  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status ',
      message: `Are you sure to change status of ${data.fullName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.disableUser(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  changeLevelStatus = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "userType": "ACTIVE",
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change level status of User?',
      message: `Are you sure to change level status of ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.changeLevelStatus(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteUser = (data) => {
    // console.log("delete function inside data:::", data);
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.deleteUser(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  verifyEmail = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to verifyEmail?',
      message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.verifyEmail(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenCreateModalMoreDetails = (data) => {
    let req = {
      "id": data.id
    }
    this.props.dispatch(userActions.getProfile(req));
    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }
  handleOpenCreateModalUpdatePassword = (data) => {
    this.setState({ UpdatePasswordCreateModal: true, fieldsUpdatePassword: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }

  handleTutorDetail = (data) => {
this.history.push('/app/tutorDetails'+ data.id)
  }

  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(userActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({ addUserCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdatePasswordCreateModal: false });
  }
  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ addUserCreateModal: true });
  }
  inputAddUserChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    // console.log("inputAddUserChange ::::: ", value);
    errorsAddUser[name] = "";
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    // console.log("name", value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }
  addUserSubmit = () => {
    let paginationdata = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    if (this.handleValidationAddUser()) {
      let reqData = {
        "mobNo": this.state.fieldsAddUser.mobNo,
        "userType": "TUTOR",
        "fullName": this.state.fieldsAddUser.fullName,
        "email": this.state.fieldsAddUser.email,
        "dob": this.state.fieldsAddUser.dob,
        "experience": this.state.fieldsAddUser.experience,
        "gender": this.state.fieldsAddUser.gender,
        "address": this.state.fieldsAddUser.address,
        "city": this.state.fieldsAddUser.city,
        "state": this.state.fieldsAddUser.state,
      }
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(userActions.registerUserAdmin(reqData, paginationdata));
    }
  }

  addUserBalanceSubmit = () => {
    if (this.handleValidationAddUserBalance()) {
      let reqData = {
        "id": this.state.addBalanceDetails.id,
        "amt": parseInt(this.state.fieldsAddUser.amt),
        "remark": this.state.fieldsAddUser.remark,
      }
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);
      let tempData = {
        "userType": "ACTIVE",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(userActions.addUserBalance(reqData, tempData));
    }
  }
  updateUserPasswordSubmit = () => {
    if (this.handleValidationUpdatePassword()) {
      let reqData = {
        "id": this.state.fieldsUpdatePassword.id,
        "password": this.state.fieldsUpdatePassword.password,
      }
      let paginationData = {
        "userType": "ACTIVE",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(userActions.updateUserPasswordSubmit(reqData, paginationData));
    }

  }
  handleValidationUpdatePassword = () => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = {};
    let formIsValid = true;
    //Email
    if (!fieldsUpdatePassword["password"] || fieldsUpdatePassword["password"] === "") {
      formIsValid = false;
      errorsUpdatePassword["password"] = "Cannot be empty";
    }
    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }

  handleValidationAddUserBalance = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;
    //Amt
    if (!fieldsAddUser["amt"] || fieldsAddUser["amt"] === "") {
      formIsValid = false;
      errorsAddUser["amt"] = "Cannot be empty Amount!";
    }
    //remark
    if (!fieldsAddUser["remark"] || fieldsAddUser["remark"] === "") {
      formIsValid = false;
      errorsAddUser["remark"] = "Cannot be empty remark!";
    }
    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  handleValidationAddUser = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;
    //Email
    if (!fieldsAddUser["mobNo"] || fieldsAddUser["mobNo"] === "") {
      formIsValid = false;
      errorsAddUser["mobNo"] = "Cannot be empty";
    }
    if (!fieldsAddUser["fullName"] || fieldsAddUser["fullName"] === "") {
      formIsValid = false;
      errorsAddUser["fullName"] = "Cannot be empty";
    }
    if (!fieldsAddUser["email"] || fieldsAddUser["email"] === "") {
      formIsValid = false;
      errorsAddUser["email"] = "Cannot be empty";
    }
    if (!fieldsAddUser["dob"] || fieldsAddUser["dob"] === "") {
      formIsValid = false;
      errorsAddUser["dob"] = "Cannot be empty";
    }
    if (!fieldsAddUser["experience"] || fieldsAddUser["experience"] === "") {
      formIsValid = false;
      errorsAddUser["experience"] = "Cannot be empty";
    }
    if (!fieldsAddUser["gender"] || fieldsAddUser["gender"] === "") {
      formIsValid = false;
      errorsAddUser["gender"] = "Cannot be empty";
    }
    if (!fieldsAddUser["address"] || fieldsAddUser["address"] === "") {
      formIsValid = false;
      errorsAddUser["address"] = "Cannot be empty";
    }
    if (!fieldsAddUser["city"] || fieldsAddUser["city"] === "") {
      formIsValid = false;
      errorsAddUser["city"] = "Cannot be empty";
    }
    if (!fieldsAddUser["state"] || fieldsAddUser["state"] === "") {
      formIsValid = false;
      errorsAddUser["state"] = "Cannot be empty";
    }
    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  navigate = (data) => {
    console.log('navigate___________data.id::::', data.id);
    this.props.history.push('/app/moreDetails/' + data.id);
  }

  handleAddBalaceModal = (data) => {
    this.setState({ adBalanceModal: true, addBalanceDetails: data });
  }

  userLogin = (data) => {
    let req = {
      "userName": data.userName,
    }
    this.props.dispatch(userActions.userLogin(req));
  }

  handleAddBalaceHideModal = () => {
    this.setState({ adBalanceModal: false, fieldsAddUser: {} });
  }


  handleOpenUserNameModal = (data) => {
    this.setState({ updateUserNameModal: true, updateUserNameDetails: data });
  }

  handleUserNameHideModal = () => {
    this.setState({ updateUserNameModal: false, updateUserNameDetails: {} });
  }

  handleOpenEmailModal = (data) => {
    this.setState({ updateEmailModal: true, updateEmailDetails: data });
  }

  handleEmailHideModal = () => {
    this.setState({ updateEmailModal: false, updateEmailDetails: {} });
  }

  inputUserNameChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUserName = this.state.fieldsUserName;
    let errorsUserName = this.state.errorsUserName;
    fieldsUserName[name] = value;
    // console.log("inputAddUserChange ::::: ", value);
    errorsUserName[name] = "";
    this.setState({ fieldsUserName, errorsUserName });
  }

  inputEmailChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsEmail = this.state.fieldsEmail;
    let errorsEmail = this.state.errorsEmail;
    fieldsEmail[name] = value;
    // console.log("inputAddUserChange ::::: ", value);
    errorsEmail[name] = "";
    this.setState({ fieldsEmail, errorsEmail });
  }

  updateUserNameSubmit = () => {

    if (this.handleValidationUpdateUserName()) {
      let reqData = {
        "id": this.state.updateUserNameDetails.id,
        "userName": this.state.fieldsUserName.userName,

      }
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);

      let tempData = {
        "userType": "ACTIVE",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(userActions.updateUserName(reqData, tempData));
    }

  }

  handleValidationUpdateUserName = () => {
    let fieldsUserName = this.state.fieldsUserName;
    let errorsUserName = {};
    let formIsValid = true;

    //userName
    if (!fieldsUserName["userName"] || fieldsUserName["userName"] === "") {
      formIsValid = false;
      errorsUserName["userName"] = "Cannot be empty UserName!";
    }
    this.setState({ errorsUserName: errorsUserName });
    return formIsValid;
  }

  updateEmailSubmit = () => {

    if (this.handleValidationUpdateEmail()) {
      let reqData = {
        "id": this.state.updateEmailDetails.id,
        "email": this.state.fieldsEmail.email,
      }
      let tempData = {
        "userType": "ACTIVE",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(userActions.updateEmail(reqData, tempData));
    }

  }


  handleValidationUpdateEmail = () => {
    let fieldsEmail = this.state.fieldsEmail;
    let errorsEmail = {};
    let formIsValid = true;

    //email
    if (!fieldsEmail["email"] || fieldsEmail["email"] === "") {
      formIsValid = false;
      errorsEmail["email"] = "Cannot be empty Email!";
    }
    this.setState({ errorsEmail: errorsEmail });
    return formIsValid;
  }


  render() {

    let { users } = this.props;
    let { paymentItems, total, loading, loginToThisAccountItems,
      //  profile
    } = users;
    // console.log("{{{{{{{{{{{{:::::::loginToThisAccountItems", loginToThisAccountItems);
    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">

                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="text-lg font-semibold leading-6 tracking-widest text-center capitalize md:text-2xl md:leading-9 text-dark-400">Active Tutor</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>
                    {/* <button className="flex justify-end py-1 pt-1 ml-2 font-bold text-white bg-blue-500 border border-blue-700 rounded hover:bg-blue-700 px-7" onClick={() => this.handleOpenCreateModal()}> ADD </button> */}
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-pink-300 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">full name</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">email</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">mob No.</th>
                                {/* <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">userType</th> */}
                                {/* <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">ref_By_User_Email</th> */}
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Date</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Status</th>
                                {/* <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Actions</th> */}
                              </tr>
                            </thead>

                            {/* Table Row Section */}
                            <tbody>
                              {
                                paymentItems && paymentItems.length > 0 ?
                                  paymentItems.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element.fullName}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element.email}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element.mobNo}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <label class="flex justify-center items-center cursor-pointer targetablepx-4 tooltip">
                                          <span className="inline-block pl-1">
                                            <button className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400"
                                              onClick={() => this.disableUser(element)}
                                            >{element && element.isDisable ? "Enable" : "Disable"}</button>
                                          </span>
                                        </label>
                                      </td>

                                      
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main >
        </div >

        <DialogExample />

        <CreateAddUserModal
          addUserCreateModal={this.state.addUserCreateModal}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          inputAddUserChange={this.inputAddUserChange}
          addUserSubmit={this.addUserSubmit}
          handleAddUserHideModal={this.handleAddUserHideModal}
        />

        <AddUserBalanceModal
          adBalanceModal={this.state.adBalanceModal}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          inputAddUserChange={this.inputAddUserChange}
          addUserBalanceSubmit={this.addUserBalanceSubmit}
          handleAddBalaceHideModal={this.handleAddBalaceHideModal}
        />

        <UpdateUserNameModal
          updateUserNameModal={this.state.updateUserNameModal}
          fieldsUserName={this.state.fieldsUserName}
          errorsUserName={this.state.errorsUserName}
          updateUserNameDetails={this.state.updateUserNameDetails}
          inputUserNameChange={this.inputUserNameChange}
          updateUserNameSubmit={this.updateUserNameSubmit}
          handleUserNameHideModal={this.handleUserNameHideModal}
        />

        <UpdateEmailModal
          updateEmailModal={this.state.updateEmailModal}
          fieldsEmail={this.state.fieldsEmail}
          errorsEmail={this.state.errorsEmail}
          updateEmailDetails={this.state.updateEmailDetails}
          inputEmailChange={this.inputEmailChange}
          updateEmailSubmit={this.updateEmailSubmit}
          handleEmailHideModal={this.handleEmailHideModal}
        />

        <UpdatePasswordUser
          UpdatePasswordCreateModal={this.state.UpdatePasswordCreateModal}
          fieldsUpdatePassword={this.state.fieldsUpdatePassword}
          errorsUpdatePassword={this.state.errorsUpdatePassword}
          inputChange={this.inputChange}
          updateUserPasswordSubmit={this.updateUserPasswordSubmit}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
        />

        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={this.state.viewRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          loginToThisAccountItems={loginToThisAccountItems}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        />
      </>

    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(TutorDetail);
