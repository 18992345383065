import React, { Component } from 'react';
import { connect } from 'react-redux';
import {teachcourseActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
// import moment from 'moment'

import ViewCoinModal from "./components/ViewCoinModal/ViewCoinModal";
import CreateCoinModal from "./components/CreateCoinModal/CreateCoinModal";
import UpdateCoinModal from "./components/UpdateCoinModal/UpdateCoinModal";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


class assignTutor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fieldskyc: {},
      // errorskyc: {},
      fieldsCoin: {},
      errorsCoin: {},
      fieldsUpdateCoin: {},
      errorsUpdateCoin: {},
      viewRowData: {},
      coinCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCoinModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      assignTutor: '',
      demoTaken: null,
      isReassign: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "status": 0,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));
    // this.props.dispatch(coinActions.createSport(data));
  }
 
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "status": 0,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(datatemp));
  }
  
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "status": "1",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));
  }
  

  render() {

    let {  teachcourse } = this.props;
    let { errorsUpdateCoin, inputChangeUpdateCoin, updateCoinSubmit } = this.state;
    let { loading, items } = teachcourse;
   console.log('itemsitemsitemsitemsitems', items)

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
          <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
        <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Assign Tutor</p>
            
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">
                <div className="flex justify-between w-full">
                  <div class="mr-2 w-full">
                    <label className="text-black text-sm font-medium mt-2 mr-2">Tutor List : </label>
                    <select class="form-select border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputChangeUpdateCoin} id="assignTutor" name="assignTutor"
                    >
                      {/* <option value={fieldsUpdateCoin && fieldsUpdateCoin.tutorId && fieldsUpdateCoin.tutorId.id ? fieldsUpdateCoin.tutorId.id:""} selected >{fieldsUpdateCoin && fieldsUpdateCoin.tutorId && fieldsUpdateCoin.tutorId.id ? fieldsUpdateCoin.tutorId.fullName:"Plz Select Tutor"}</option> */}
                    
                      { 
                        items && items.length > 0 ?
                        items.map((element, index) => (
                          <>
                          { element && element.tutorAppliedList && element.tutorAppliedList.length>0 ?
                          element.tutorAppliedList.map((item, index) => (
                            
                            <option value={item && item.id ? item.id : null}>{item && item.fullName ? item.fullName : "-"} {item && item.mobNo ? item.mobNo : "-"}</option>
                            // JSON.stringify(item)
                           
                            )): null }
                          </>
                         
                          )) : null
                      }
                    </select>
                    {errorsUpdateCoin && errorsUpdateCoin["assignTutor"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateCoin["assignTutor"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateCoinSubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>
          </main >
        </div >

        {/* <DialogExample /> */}

        < CreateCoinModal
          coinCreateModal={this.state.coinCreateModal}
          fieldsCoin={this.state.fieldsCoin}
          errorsCoin={this.state.errorsCoin}
          inputCoinChange={this.inputCoinChange}
          createCoinSubmit={this.createCoinSubmit}
          handleCoinHideModal={this.handleCoinHideModal}
          handleFile={this.handleFile}
        />

        <UpdateCoinModal
          UpdateCoinModal={this.state.UpdateCoinModal}
          fieldsUpdateCoin={this.state.fieldsUpdateCoin}
          errorsUpdateCoin={this.state.errorsUpdateCoin}
          assignTutor={this.state.assignTutor}
          inputChangeUpdateCoin={this.inputChangeUpdateCoin}
          assignTutorDemoClass={this.assignTutorDemoClass}
          updateCoinSubmit={this.updateCoinSubmit}
          handleCoinUpdateHideModal={this.handleCoinUpdateHideModal}
          handleFile={this.handleFile}
        />

        <ViewCoinModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={this.state.viewRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}

        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { coin, users, teachcourse } = state;
  return {
    coin,
    users,
    teachcourse
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(assignTutor);
