import React, { Component } from 'react';
import { connect } from 'react-redux';
import { coinActions, userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import ViewCoinModal from "./components/ViewCoinModal/ViewCoinModal";
import CreateCoinModal from "./components/CreateCoinModal/CreateCoinModal";
import UpdateCoinModal from "./components/UpdateCoinModal/UpdateCoinModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiOutlineViewGridAdd, HiEye } from "react-icons/hi";
import { RiChatCheckLine, RiChatDeleteLine } from "react-icons/ri";

class Coin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fieldskyc: {},
      // errorskyc: {},
      fieldsCoin: {},
      errorsCoin: {},
      fieldsUpdateCoin: {},
      errorsUpdateCoin: {},
      viewRowData: {},
      coinCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCoinModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(coinActions.getCoinList(data));
    // this.props.dispatch(coinActions.createSport(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.coin.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsCoin: {},
        errorsCoin: {},
        fieldsUpdateCoin: {},
        errorsUpdateCoin: {},
        coinCreateModal: false,
        UpdateCoinModal: false,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(coinActions.getCoinList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(coinActions.getCoinList(data));
  }
  disableCoin = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.name} coin?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(coinActions.disableCoin(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  disableCoinCard = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable Card?',
      message: `Are you sure to disable ${data.name} Coin Card?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(coinActions.disableCoinCard(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  deleteCoin = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} coin?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(coinActions.deleteCoin(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenCreateModalMoreDetails = (data) => {

    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }
  handleOpenCoinUpdateModal = (data) => {
    this.setState({ UpdateCoinModal: true, fieldsUpdateCoin: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(coinActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleCoinHideModal = () => {
    this.setState({ coinCreateModal: false, fieldsCoin: {}, errorsCoin: {} });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleCoinUpdateHideModal = () => {
    this.setState({ UpdateCoinModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleHideCoinUpdateModal = () => {
    this.setState({ UpdateCoinModal: false });
  }
  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ coinCreateModal: true });
  }
  inputCoinChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = this.state.errorsCoin;
    fieldsCoin[name] = value;
    console.log(name, value);
    errorsCoin[name] = "";
    this.setState({ fieldsCoin, errorsCoin });
  }
  inputChangeUpdateCoin = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = this.state.errorsUpdateCoin;
    fieldsUpdateCoin[name] = value;
    errorsUpdateCoin[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateCoin, errorsUpdateCoin });
  }

  createCoinSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;

    if (this.handleValidationCoin()) {
      let reqData = {
        "name": this.state.fieldsCoin.name,
        "ticker": this.state.fieldsCoin.ticker,
        "price": this.state.fieldsCoin.price,
        // "m_cap": this.state.fieldsCoin.m_cap,
        // "volume": this.state.fieldsCoin.volume,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsCoin.image
      }

      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>createCoinSubmit>", reqData);
      this.props.dispatch(coinActions.createCoin(reqData));
    }

  }

  // navigate = (data) => {

  //   this.props.history.push('/app/series/' + data.sportId);
  // }

  updateCoinSubmit = () => {

    let { users } = this.props;
    let { filesDetails } = users;


    if (this.handleValidationUpdateCoin()) {
      let reqData = {
        "id": this.state.fieldsUpdateCoin.id,
        "name": this.state.fieldsUpdateCoin.name,
        "ticker": this.state.fieldsUpdateCoin.ticker,
        "price": this.state.fieldsUpdateCoin.price,
        // "m_cap": this.state.fieldsUpdateCoin.m_cap,
        // "volume": this.state.fieldsUpdateCoin.volume,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsUpdateCoin.image

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      console.log("updateCoinSubmit_updateCoinSubmit:1:2:3:", reqData);
      this.props.dispatch(coinActions.updateCoin(reqData, paginationData));
    }

  }
  handleValidationUpdateCoin = () => {
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = {};
    let formIsValid = true;

    //name
    if (!fieldsUpdateCoin["name"] || fieldsUpdateCoin["name"] === "") {
      formIsValid = false;
      errorsUpdateCoin["name"] = "Cannot be empty";
    }

    //ticker
    if (!fieldsUpdateCoin["ticker"] || fieldsUpdateCoin["ticker"] === "") {
      formIsValid = false;
      errorsUpdateCoin["ticker"] = "Cannot be empty";
    }

    //price
    if (!fieldsUpdateCoin["price"] || fieldsUpdateCoin["price"] === "") {
      formIsValid = false;
      errorsUpdateCoin["price"] = "Cannot be empty price";
    }

    //m_cap
    // if (!fieldsUpdateCoin["m_cap"] || fieldsUpdateCoin["m_cap"] === "") {
    //   formIsValid = false;
    //   errorsUpdateCoin["m_cap"] = "Cannot be empty m_cap";
    // }

    //volume
    // if (!fieldsUpdateCoin["volume"] || fieldsUpdateCoin["volume"] === "") {
    //   formIsValid = false;
    //   errorsUpdateCoin["volume"] = "Cannot be empty volume";
    // }

    console.log("errorsUpdateCoinerrorsUpdateCoin_errorsUpdateCoinerrorsUpdateCoin:", errorsUpdateCoin);

    this.setState({ errorsUpdateCoin: errorsUpdateCoin });
    return formIsValid;
  }
  handleValidationCoin = () => {
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = {};
    let formIsValid = true;

    //Email
    if (!fieldsCoin["name"] || fieldsCoin["name"] === "") {
      formIsValid = false;
      errorsCoin["name"] = "Cannot be empty name";
    }

    if (!fieldsCoin["ticker"] || fieldsCoin["ticker"] === "") {
      formIsValid = false;
      errorsCoin["ticker"] = "Cannot be empty ticker";
    }

    if (!fieldsCoin["price"] || fieldsCoin["price"] === "") {
      formIsValid = false;
      errorsCoin["price"] = "Cannot be empty price";
    }

    // if (!fieldsCoin["m_cap"] || fieldsCoin["m_cap"] === "") {
    //   formIsValid = false;
    //   errorsCoin["m_cap"] = "Cannot be empty m_cap";
    // }

    // if (!fieldsCoin["volume"] || fieldsCoin["volume"] === "") {
    //   formIsValid = false;
    //   errorsCoin["volume"] = "Cannot be empty volume";
    // }



    this.setState({ errorsCoin: errorsCoin });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  render() {

    let { coin } = this.props;
    let { total, loading, items } = coin;

    console.log("RENDER___itemsitemsitems:::", items);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">COIN List</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>
                    <button className="bg-blue-500 hover:bg-blue-700 px-7 py-1 pt-1 ml-2 text-white font-bold  border border-blue-700 rounded flex justify-end" onClick={() => this.handleOpenCreateModal()}> ADD </button>
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-emerald-300 rounded-t">
                              <tr className="">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">#</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">name</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">ticker</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">price</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">imageLinkUrl</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">Status</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.name ? element.name : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.ticker ? element.ticker : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.price ? element.price.toFixed(2) : 0}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <img src={element && element.imageLinkUrl ? element.imageLinkUrl : "NA"} alt="" className="h-12 w-12 rounded-full" />
                                      </td>


                                      {/* <td className="px-2 py-3 whitespace-nowrap text-gray-600 inline-block">
                                        <span className="inline-block pl-1">
                                          <button className="bg-yellow-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>More</button>
                                        </span>
                                        <span className="inline-block pl-1">
                                          <button className="bg-green-700 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-green-400" onClick={() => this.disableCoin(element)}>{element && element.isDisable ? "Disable" : "Enable"}</button>
                                        </span>
                                        <span className="inline-block pl-1">
                                          <button className="bg-green-700 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-green-400" onClick={() => this.disableCoinCard(element)}>{element && element.isCard ? "Disable Card" : "Enable Card"}</button>
                                        </span>

                                        <span className="inline-block pl-1">
                                          <button className="bg-indigo-500 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-indigo-300" onClick={() => this.handleOpenCoinUpdateModal(element)}>Update</button>
                                        </span>
                                        <span className="inline-block pl-1">
                                          <button className="bg-red-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-red-400" onClick={() => this.deleteCoin(element)}>Delete</button>
                                        </span>

                                      </td> */}

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <label class="flex justify-center items-center cursor-pointer targetablepx-4 tooltip">
                                          <div class="relative" onClick={() => this.disableCoin(element)}>
                                            <input type="checkbox" id="toggleB" class="sr-only" />
                                            <div class="block bg-gray-600 w-10 h-6 rounded-full"></div>
                                            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>Coin Enable/Disable</span>
                                          </div>
                                        </label>
                                      </td>

                                      <td className="flex px-2 py-3 text-gray-600 whitespace-nowrap">
                                        <span className="pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>View Details</span>
                                            <HiEye className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>

                                        <span className="pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.handleOpenCoinUpdateModal(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>View</span>
                                            <HiOutlineViewGridAdd className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>

                                        <span className="pl-1">
                                          <div class={`tooltip px-3 py-1 font-medium tracking-wider text-blue-100 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-500 cursor-pointer" ${element.isCard ? 'bg-green-700' : 'bg-blue-700'}`} onClick={() => this.disableCoinCard(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>{element && element.isCard ? "Disable Card" : "Enable Card"}</span>
                                            {element && element.isCard === false ? <RiChatDeleteLine className='w-5 h-5 block object-contain' /> : <RiChatCheckLine className='w-5 h-5 block object-contain' />}
                                          </div>
                                        </span>

                                        <span className="flex justify-center pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-500 cursor-pointer" onClick={() => this.deleteCoin(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Delete</span>
                                            <HiTrash className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>
                                      </td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        {/* <DialogExample /> */}

        <CreateCoinModal
          coinCreateModal={this.state.coinCreateModal}
          fieldsCoin={this.state.fieldsCoin}
          errorsCoin={this.state.errorsCoin}
          inputCoinChange={this.inputCoinChange}
          createCoinSubmit={this.createCoinSubmit}
          handleCoinHideModal={this.handleCoinHideModal}
          handleFile={this.handleFile}
        />

        <UpdateCoinModal
          UpdateCoinModal={this.state.UpdateCoinModal}
          fieldsUpdateCoin={this.state.fieldsUpdateCoin}
          errorsUpdateCoin={this.state.errorsUpdateCoin}
          inputChangeUpdateCoin={this.inputChangeUpdateCoin}
          updateCoinSubmit={this.updateCoinSubmit}
          handleCoinUpdateHideModal={this.handleCoinUpdateHideModal}
          handleFile={this.handleFile}
        />

        <ViewCoinModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={this.state.viewRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}

        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { coin, users } = state;
  return {
    coin,
    users
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Coin);
