import React, { Component } from 'react';
import { connect } from 'react-redux';
import { coinActions, userActions, teachcourseActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import ViewCoinModal from "./components/ViewCoinModal/ViewCoinModal";
import CreateCoinModal from "./components/CreateCoinModal/CreateCoinModal";
import UpdateCoinModal from "./components/UpdateCoinModal/UpdateCoinModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { HiTrash, HiOutlineViewGridAdd, HiEye } from "react-icons/hi";
// import { RiChatCheckLine, RiChatDeleteLine } from "react-icons/ri";

class Coin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fieldskyc: {},
      // errorskyc: {},
      fieldsCoin: {},
      errorsCoin: {},
      fieldsUpdateCoin: {},
      errorsUpdateCoin: {},
      viewRowData: {},
      coinCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCoinModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "status": "1",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));
    // this.props.dispatch(coinActions.createSport(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.coin.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsCoin: {},
        errorsCoin: {},
        fieldsUpdateCoin: {},
        errorsUpdateCoin: {},
        coinCreateModal: false,
        UpdateCoinModal: false,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "status": "1",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "status": "1",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));
  }
  disableCoin = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.name} coin?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.updateTeachCourseStatus(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  // disableCoinCard = (data) => {
  //   let datatemp = {
  //     "id": data.id,
  //   }
  //   let paginationdata = {
  //     "keyWord": "",
  //     "pageNo": 1,
  //     "size": this.state.size
  //   }
  //   confirmAlert({


  //     title: 'Confirm to disable Card?',
  //     message: `Are you sure to disable ${data.name} Coin Card?`,
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => this.props.dispatch(coinActions.disableCoinCard(datatemp, paginationdata))
  //       },
  //       {
  //         label: 'No'
  //       }
  //     ]
  //   });
  // }

  deleteCoin = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} coin?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.deleteTeachCourse(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenCreateModalMoreDetails = (data) => {

    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }
  handleOpenCoinUpdateModal = (data) => {
    this.setState({ UpdateCoinModal: true, fieldsUpdateCoin: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(coinActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleCoinHideModal = () => {
    this.setState({ coinCreateModal: false, fieldsCoin: {}, errorsCoin: {} });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleCoinUpdateHideModal = () => {
    this.setState({ UpdateCoinModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleHideCoinUpdateModal = () => {
    this.setState({ UpdateCoinModal: false });
  }
  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ coinCreateModal: true });
  }
  inputCoinChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = this.state.errorsCoin;
    fieldsCoin[name] = value;
    console.log(name, value);
    errorsCoin[name] = "";
    this.setState({ fieldsCoin, errorsCoin });
  }
  inputChangeUpdateCoin = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = this.state.errorsUpdateCoin;
    fieldsUpdateCoin[name] = value;
    errorsUpdateCoin[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateCoin, errorsUpdateCoin });
  }

  createCoinSubmit = () => {
    // let { users } = this.props;
    // let { filesDetails } = users;

    if (this.handleValidationCoin()) {
      let reqData = {
        "name": this.state.fieldsCoin.name,
        "shortName": this.state.fieldsCoin.shortName,
      }
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>createCoinSubmit>", reqData);
      this.props.dispatch(teachcourseActions.createTeachCourse(reqData));
    }

  }

  // navigate = (data) => {

  //   this.props.history.push('/app/series/' + data.sportId);
  // }

  updateCoinSubmit = () => {

    // let { users } = this.props;
    // let { filesDetails } = users;
    if (this.handleValidationUpdateCoin()) {
      let reqData = {
        "id": this.state.fieldsUpdateCoin.id,
        "name": this.state.fieldsUpdateCoin.name,
        "shortName": this.state.fieldsUpdateCoin.shortName,
      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      console.log("updateCoinSubmit_updateCoinSubmit:1:2:3:", reqData);
      this.props.dispatch(teachcourseActions.updateTeachCourse(reqData, paginationData));
    }

  }
  handleValidationUpdateCoin = () => {
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = {};
    let formIsValid = true;

    //name
    if (!fieldsUpdateCoin["name"] || fieldsUpdateCoin["name"] === "") {
      formIsValid = false;
      errorsUpdateCoin["name"] = "Cannot be empty";
    }

    //ticker
    if (!fieldsUpdateCoin["shortName"] || fieldsUpdateCoin["shortName"] === "") {
      formIsValid = false;
      errorsUpdateCoin["shortName"] = "Cannot be empty";
    }
    //m_cap
    // if (!fieldsUpdateCoin["m_cap"] || fieldsUpdateCoin["m_cap"] === "") {
    //   formIsValid = false;
    //   errorsUpdateCoin["m_cap"] = "Cannot be empty m_cap";
    // }

    //volume
    // if (!fieldsUpdateCoin["volume"] || fieldsUpdateCoin["volume"] === "") {
    //   formIsValid = false;
    //   errorsUpdateCoin["volume"] = "Cannot be empty volume";
    // }

    console.log("errorsUpdateCoinerrorsUpdateCoin_errorsUpdateCoinerrorsUpdateCoin:", errorsUpdateCoin);

    this.setState({ errorsUpdateCoin: errorsUpdateCoin });
    return formIsValid;
  }
  handleValidationCoin = () => {
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = {};
    let formIsValid = true;

    //Email
    if (!fieldsCoin["name"] || fieldsCoin["name"] === "") {
      formIsValid = false;
      errorsCoin["name"] = "Cannot be empty name";
    }

    if (!fieldsCoin["shortName"] || fieldsCoin["shortName"] === "") {
      formIsValid = false;
      errorsCoin["shortName"] = "Cannot be empty shortName";
    }



    this.setState({ errorsCoin: errorsCoin });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }


  changeStatusDemoClass = (data, status) => {
    let paginationData = {
      "status": "1",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let tempdata = {
      "id": data.id,
      "status": status,
    }
    confirmAlert({
      title: 'Confirm to Accept?',
      message: 'Are you sure to accept demo of phone no.:- ' + data.studentId.mobNo,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.changeStatusDemoClass(tempdata, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  render() {

    let { teachcourse } = this.props;
    let { total, loading, items } = teachcourse;
    // let { total, loading, items } = coin;
    // console.log("RENDER___teachcourseteachcourse", items);


    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="text-lg font-semibold leading-6 tracking-wider text-center capitalize md:text-2xl md:leading-9 text-dark-400">Demo Class Approved List</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600">
                      </div>
                    </div>
                    {/* <button className="flex justify-end py-1 pt-1 ml-2 font-bold text-white bg-blue-500 border border-blue-700 rounded hover:bg-blue-700 px-7" onClick={() => this.handleOpenCreateModal()}> ADD </button> */}
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="rounded-t bg-emerald-300">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">name</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Phone No.</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Class</th>
                                {/* <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Course</th> */}
                                {/* <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-center text-gray-700 capitalize whitespace-nowrap">Status</th> */}
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      {element && element.tutorAppliedList && element.tutorAppliedList.length > 0 ?
                                        null : <>
                                          <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                            {this.state.offset + index + 1}</td>

                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {element && element.studentId ? element.studentId.firstName : " "}</td>
                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {element && element.studentId && element.studentId.mobNo ? element.studentId.mobNo : "-"}</td>
                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {element && element.class && element.class.name ? element.class.name : "-"}</td>
                                          {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {element && element.course && element.course.name ? element.course.name : "-"}</td> */}




                                          {/* <td className="flex px-2 py-3 text-gray-600 whitespace-nowrap">


                                            <td className="flex-wrap content-center inline-block px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                              <>
                                                <span class="inline-block pl-1">
                                                  <button class="bg-green-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-green-100 rounded-xl hover:shadow-lg hover:bg-green-700" onClick={() => this.changeStatusDemoClass(element, 1)}>Accept</button>
                                                </span>
                                                <span className="inline-block pl-1">
                                                  <button class="bg-red-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-red-700" onClick={() => this.changeStatusDemoClass(element, 3)}>Reject </button>
                                                </span>
                                              </>
                                            </td>


                                          </td> */}
                                          <td className="flex px-2 py-3 text-gray-600 whitespace-nowrap">


                                            <td className="flex-wrap content-center inline-block px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                              <>
                                                <span class="inline-block pl-1">
                                                  <button class="bg-green-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-green-100 rounded-xl hover:shadow-lg hover:bg-green-700" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>More Details</button>
                                                </span>

                                              </>
                                            </td>


                                          </td>
                                        </>}

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>


              </div>
            </div>
          </main>
        </div>

        {/* <DialogExample /> */}

        <CreateCoinModal
          coinCreateModal={this.state.coinCreateModal}
          fieldsCoin={this.state.fieldsCoin}
          errorsCoin={this.state.errorsCoin}
          inputCoinChange={this.inputCoinChange}
          createCoinSubmit={this.createCoinSubmit}
          handleCoinHideModal={this.handleCoinHideModal}
          handleFile={this.handleFile}
        />

        <UpdateCoinModal
          UpdateCoinModal={this.state.UpdateCoinModal}
          fieldsUpdateCoin={this.state.fieldsUpdateCoin}
          errorsUpdateCoin={this.state.errorsUpdateCoin}
          inputChangeUpdateCoin={this.inputChangeUpdateCoin}
          updateCoinSubmit={this.updateCoinSubmit}
          handleCoinUpdateHideModal={this.handleCoinUpdateHideModal}
          handleFile={this.handleFile}
        />

        <ViewCoinModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={this.state.viewRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}

        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { coin, users, teachcourse } = state;
  return {
    coin,
    users,
    teachcourse
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Coin);
