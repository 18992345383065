import React from "react";

import Modal from 'react-modal';


export default function CreateAddUserModal(props) {

  let { addUserCreateModal, handleAddUserHideModal, errorsAddUser, inputAddUserChange, addUserSubmit, fieldsAddUser } = props;
  //console.log("userCreateModal  ",userCreateModal);

  return (

    <Modal
      isOpen={addUserCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="fixed inset-0 z-50 flex items-center justify-center w-full py-4 overflow-hidden overflow-y-auto main-modal animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between">
              <p className="text-2xl font-bold">Create New Tutor</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleAddUserHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-2 h-[600px]">

              <form autoComplete="off">
                <div className="grid grid-cols-1 gap-2">
                  {/* <div className="">
                    <div className="relative mt-1 shadow-sm">
                      <input className={`px-5 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["mobNo"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="mobNo" name="mobNo" placeholder="Mobile No..." value={fieldsAddUser.mobNo} type="text" onChange={inputAddUserChange} />
                      {errorsAddUser && errorsAddUser["mobNo"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {errorsAddUser["mobNo"]}
                        </div>
                        : null}
                    </div>
                  </div> */}

                </div>
                <div className="w-64 pt-6 mx-auto sm:w-64">
                  <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white capitalize transition duration-150 ease-in-out border rounded-full bg-shine-400 xl border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700" type="button" onClick={addUserSubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
