import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./style.css"

class UserActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
    }
  }
  componentDidMount() {
    let data = {
      "tuitionId": this.props.match.params.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getAttendanceListByTutor(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }


  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      // "userType": "ACTIVE",
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTuitionListByTutor(datatemp));
  }

  moreDetailsPage = (data) => {
    this.props.history.push(`/app/attendence/${data.id}`)
  }

  render() {

    let { users } = this.props;
    let { attendanceList, attendanceTotal, loading
    } = users;
    console.log('attendanceList_____________________', attendanceList);
    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">

                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="text-lg font-semibold leading-6 tracking-widest text-center capitalize md:text-2xl md:leading-9 text-dark-400">Tutor Attendance</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600">
                      </div>

                    </div>
                  </div>

                  <div className=" rounded-lg overflow-hidden pb-2 mt-4">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-teal-300 rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">#</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">Date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">Check In Time.</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">Check out time</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">duration</th>

                                {/* <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">Enable/Disable</th> */}
                                {/* <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">Actions</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {/* Odd row */}
                              {
                                attendanceList && attendanceList.length > 0 ?
                                  attendanceList.map((element, index) =>

                                  (<React.Fragment key={element.id}>
                                    {/* {!element.isCloseClass ? */}
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap text-center font-medium text-sm text-gray-600">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>

                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.checkInTime))).utcOffset("+05:30").format("HH:mm:ss")}
                                      </td>

                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.checkOutTime))).utcOffset("+05:30").format("HH:mm:ss")}
                                      </td>

                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.duration ? element.duration : "0"}</td>




                                    </tr>

                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <nav className="relative z-0 flex justify-end mt-5">
                      {
                        attendanceTotal && attendanceTotal > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={attendanceTotal / this.state.size}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>


                  </div>
                </section>
              </div>
            </div>
          </main >
        </div >

        <DialogExample />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { users, city } = state;
  return {
    users, city
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(UserActive);
