// import React from "react";
// import { Fragment } from 'react'
// import { Menu, Transition } from '@headlessui/react'



// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }
// export default function Header(props) {
//   const onClickMenu = (url) => {

//     window.sessionStorage.removeItem('adminuser');
//     props.history.push(url)

//   }

//   return (
//     <>


//       <div className="relative z-10 flex-shrink-0 flex h-16 border-b border-black border-opacity-10">

//         <div className="flex-1 px-4 flex justify-between">
//           <div>

//           </div>
//           <div className="flex justify-center items-center space-x-4">


//           </div>
//           <div className="ml-4 flex items-center md:ml-6">
//             <Menu as="div" className="relative inline-block text-left">

//               {({ open }) => (
//                 <>
//                   <div className="ml-6 relative">
//                     <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline">
//                       <img className="h-12 w-12 rounded-full" src="dist/img/profile.png" alt="" />
//                     </Menu.Button>
//                   </div>
//                   <Transition show={open} as={Fragment}
//                     enter="transition ease-out duration-100"
//                     enterFrom="transform opacity-0 scale-95"
//                     enterTo="transform opacity-100 scale-100"
//                     leave="transition ease-in duration-75"
//                     leaveFrom="transform opacity-100 scale-100"
//                     leaveTo="transform opacity-0 scale-95">
//                     <Menu.Items static className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
//                       <div className="py-1">
//                         <Menu.Item>
//                           {({ active }) =>
//                             (<span onClick={() => onClickMenu('../login')} className={classNames(active ? 'bg-shine text-gray-900' : 'text-gray-900', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-medium')}>Logout</span>)}
//                         </Menu.Item>
//                       </div>
//                     </Menu.Items>
//                   </Transition>
//                 </>
//               )}
//             </Menu>

//           </div>
//         </div>
//       </div>


//     </>
//   );
// }



import React from "react";
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header(props) {
  const userData = JSON.parse(window.sessionStorage.getItem('adminuser'));
  console.log(userData);
  const isLoggedIn = !!userData;

  const onClickMenu = (url) => {
    window.sessionStorage.removeItem('adminuser');
    props.history.push(url);
  }

  return (
    <>
      {/* Navbar */}
      <div className="relative z-10 flex-shrink-0 flex h-16 border-b border-black border-opacity-10">
        <div className="flex-1 px-4 flex justify-between">
          <div></div>
          <div className="flex justify-center items-center space-x-4"></div>
          <div className="ml-4 flex items-center md:ml-6">
            {isLoggedIn && (
              <Menu as="div" className="relative inline-block text-left">
                {({ open }) => (
                  <>
                    <div className=" flex items-center space-x-2">
                      <div cla>
                        <h2 className='capitalize transition ease-in-out duration-500 cursor-pointer block  font-bold'> {userData.userName}</h2>
                        <p className="text-[12px] text-gray-500 font-bold text-right">Administrator</p>
                      </div>
                      <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline">
                        <img className="h-12 w-12 rounded-full" src="dist/img/profile.png" alt="" />
                      </Menu.Button>
                    </div>
                    <Transition show={open} as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items static className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {/* <Menu.Item>
                            {({ active }) => (
                              <span className={classNames(active ? 'bg-shine text-gray-900' : 'text-gray-900', 'capitalize transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-medium')}>
                                {userData.userName}
                              </span>
                            )}
                          </Menu.Item> */}
                          <Menu.Item>
                            {({ active }) => (
                              <span onClick={() => onClickMenu('../login')} className={classNames(active ? 'bg-[#FBB00C] text-gray-900' : 'text-gray-900', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-medium')}>
                                Logout
                              </span>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

