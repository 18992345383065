import { transactionConstants } from '../_constants';
import { transactionService } from '../_services';
import { alertActions } from '.';
export const transactionActions = {
    getUserTxList,
    getTxWithdrawSucess,
    getTxWithdrawSuccessAll,
    getTxWithdrawPending,
    getTxWithdrawPendingAll,
    getTxMaaExchWithdrawSucess,
    sendCoinApproveDFTC,
    getTxAllAdmin,
    revertToMainBalance,
    revertToPayout,
    approveViaApi,
    addCashFlow,
    approveMultiViaApi,

};
function revertToPayout(data, tempData) {

    // console.log("tempData_revertToPayout::", tempData);

    return dispatch => {
        dispatch(request());
        transactionService.revertToPayout(data)
            .then(
                transaction => {
                    // console.log("ACTION_____revertToPayout", transaction);

                    let message = transaction && transaction.revertToPayout && transaction.revertToPayout.message ? transaction.revertToPayout.message : ''

                    dispatch(success(transaction));
                    dispatch(alertActions.success(message));
                    dispatch(this.getTxWithdrawPending(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.REVERT_TO_PAYOUT_REQUEST } }
    function success(transaction) { return { type: transactionConstants.REVERT_TO_PAYOUT_SUCCESS, transaction } }
    function failure(error) { return { type: transactionConstants.REVERT_TO_PAYOUT_FAILURE, error } }
}
function revertToMainBalance(data, tempData) {

    // console.log("tempData_revertToMainBalance::", tempData);

    return dispatch => {
        dispatch(request());
        transactionService.revertToMainBalance(data)
            .then(
                transaction => {

                    // console.log("ACTION_____revertToMainBalance", transaction);

                    let message = transaction && transaction.revertToMainBalance && transaction.revertToMainBalance.message ? transaction.revertToMainBalance.message : ''

                    dispatch(success(transaction));
                    dispatch(alertActions.success(message));
                    dispatch(this.getTxWithdrawPending(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.REVERT_TO_MAIN_BALANCE_REQUEST } }
    function success(transaction) { return { type: transactionConstants.REVERT_TO_MAIN_BALANCE_SUCCESS, transaction } }
    function failure(error) { return { type: transactionConstants.REVERT_TO_MAIN_BALANCE_FAILURE, error } }
}
function getTxAllAdmin(data) {
    return dispatch => {
        dispatch(request());
        transactionService.getTxAllAdmin(data)
            .then(
                transaction => dispatch(success(transaction)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.GET_TX_ALL_ADMIN_REQUEST } }
    function success(transaction) { return { type: transactionConstants.GET_TX_ALL_ADMIN_SUCCESS, transaction } }
    function failure(error) { return { type: transactionConstants.GET_TX_ALL_ADMIN_FAILURE, error } }
}
function getUserTxList(data) {
    return dispatch => {
        dispatch(request());
        transactionService.getUserTxList(data)
            .then(
                transaction => dispatch(success(transaction)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.GET_USER_TXLIST_REQUEST } }
    function success(transaction) { return { type: transactionConstants.GET_USER_TXLIST_SUCCESS, transaction } }
    function failure(error) { return { type: transactionConstants.GET_USER_TXLIST_FAILURE, error } }
}

function getTxMaaExchWithdrawSucess(data) {
    return dispatch => {
        dispatch(request());
        transactionService.getTxMaaExchWithdrawSucess(data)
            .then(
                transaction => dispatch(success(transaction)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.TXLIST_MAAEXCH_WIDTHRAW_SUCESS_REQUEST } }
    function success(transaction) { return { type: transactionConstants.TXLIST_MAAEXCH_WIDTHRAW_SUCESS_SUCCESS, transaction } }
    function failure(error) { return { type: transactionConstants.TXLIST_MAAEXCH_WIDTHRAW_SUCESS_FAILURE, error } }
}
function getTxWithdrawPending(data) {
    return dispatch => {
        dispatch(request());
        transactionService.getTxWithdrawPending(data)
            .then(
                transaction => dispatch(success(transaction)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.TXLIST_WIDTHRAW_PENDING_REQUEST } }
    function success(transaction) { return { type: transactionConstants.TXLIST_WIDTHRAW_PENDING_SUCCESS, transaction } }
    function failure(error) { return { type: transactionConstants.TXLIST_WIDTHRAW_PENDING_FAILURE, error } }
}
function getTxWithdrawPendingAll(data) {
    return dispatch => {
        dispatch(request());
        transactionService.getTxWithdrawPendingAll(data)
            .then(
                transaction => dispatch(success(transaction)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.TXLIST_WIDTHRAW_PENDING_ALL_REQUEST } }
    function success(transaction) { return { type: transactionConstants.TXLIST_WIDTHRAW_PENDING_ALL_SUCCESS, transaction } }
    function failure(error) { return { type: transactionConstants.TXLIST_WIDTHRAW_PENDING_ALL_FAILURE, error } }
}

function getTxWithdrawSucess(data) {
    console.log("data me kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        transactionService.getTxWithdrawSucess(data)
            .then(
                transaction => dispatch(success(transaction)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.TXLIST_WIDTHRAW_SUCESS_REQUEST } }
    function success(transaction) { return { type: transactionConstants.TXLIST_WIDTHRAW_SUCESS_SUCCESS, transaction } }
    function failure(error) { return { type: transactionConstants.TXLIST_WIDTHRAW_SUCESS_FAILURE, error } }
}

function getTxWithdrawSuccessAll() {
    console.log("data me kya aa rha h::action:::",);
    return dispatch => {
        dispatch(request());
        transactionService.getTxWithdrawSuccessAll()
            .then(
                transaction => dispatch(success(transaction)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.ALL_TXLIST_WIDTHRAW_SUCESS_REQUEST } }
    function success(transaction) { return { type: transactionConstants.ALL_TXLIST_WIDTHRAW_SUCESS_SUCCESS, transaction } }
    function failure(error) { return { type: transactionConstants.ALL_TXLIST_WIDTHRAW_SUCESS_FAILURE, error } }
}

function sendCoinApproveDFTC(data) {
    let reqData = {
        "txType": "WITHDRAW",
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size,
    }
    let mainData = {
        "userTxId": data.userTxId,
        "txId": data.txId,
    }
    return dispatch => {
        dispatch(request());
        transactionService.sendCoinApproveDFTC(mainData)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    // dispatch(alertActions.success("Password Updated successfully."));
                    dispatch(success(users));
                    dispatch(this.getTxWithdrawPending(reqData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.SENDCOIN_APPROVED_DFTC_REQUEST } }
    function success(users) { return { type: transactionConstants.SENDCOIN_APPROVED_DFTC_SUCCESS, users } }
    function failure(error) { return { type: transactionConstants.SENDCOIN_APPROVED_DFTC_FAILURE, error } }
}
function approveViaApi(data) {
    let reqData = {
        "txType": "WITHDRAW",
        "keyWord": "",
        "pageNo": data.pageNo,
        "size": data.size,
    }
    let mainData = {
        "userTxId": data.userTxId,
        "password": data.password
    }
    return dispatch => {
        dispatch(request());
        transactionService.approveViaApi(mainData)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Successfully Done."));
                    dispatch(success(users));
                    dispatch(this.getTxWithdrawPending(reqData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.APPROVED_VIA_API_REQUEST } }
    function success(users) { return { type: transactionConstants.APPROVED_VIA_API_SUCCESS, users } }
    function failure(error) { return { type: transactionConstants.APPROVED_VIA_API_FAILURE, error } }
}
function approveMultiViaApi(data) {
    let reqData = {
        "txType": "WITHDRAW",
        "keyWord": "",
        "pageNo": data.pageNo,
        "size": data.size,
    }
    let mainData = {
        "userTxId": data.userTxId,
        "password": data.password
    }
    console.log('data___________1000000000', data);
    return dispatch => {
        dispatch(request());
        transactionService.approveMultiViaApi(mainData)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Successfully Done."));
                    dispatch(success(users));
                    // dispatch(this.getTxWithdraw(reqData));
                    dispatch(this.getTxWithdrawPending(reqData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.APPROVED_VIA_API_REQUEST } }
    function success(users) { return { type: transactionConstants.APPROVED_VIA_API_SUCCESS, users } }
    function failure(error) { return { type: transactionConstants.APPROVED_VIA_API_FAILURE, error } }
}

function addCashFlow(data, paginationData) {
    let reqData = {
        "keyWord": "DEDUCT_FROM_ADMIN",
        "pageNo": 1,
        "size": 10,
    }
    return dispatch => {
        dispatch(request());
        transactionService.addCashFlow(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success(" Updated successfully."));
                    dispatch(success(users));
                    dispatch(this.getTxAllAdmin(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: transactionConstants.UPDATE_CASH_FLOW_REQUEST } }
    function success(users) { return { type: transactionConstants.UPDATE_CASH_FLOW_SUCCESS, users } }
    function failure(error) { return { type: transactionConstants.UPDATE_CASH_FLOW_FAILURE, error } }
}


