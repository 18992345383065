import React from "react";

import Modal from 'react-modal';
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';


export default function CreateAddUserModal(props) {

  let { addUserCreateModal, handleAddUserHideModal, errorsAddUser, inputAddUserChange, addUserSubmit, fieldsAddUser, getTeachCourseList, getAllClass, getAllCity, getAllPinCode, boardItems, handleChangeInputCouresId, handleTimeChange, endTimeFormatTimeToAMPM, studentData } = props;
  // console.log('startTimeField______________', startTimeField);
  let teacherCouresRes = getTeachCourseList && getTeachCourseList.length ? getTeachCourseList.map((element) => ({ value: element.id, label: element.name })) : []

  return (

    // <Modal isOpen={addUserCreateModal}>


    <>

      <div className={addUserCreateModal ? "main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" : "hidden"} style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border-2 border-black shadow-lg modal-container bg-white lg:w-[500px] w-11/12 mx-auto rounded-[40px]  z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 space-y-2">
            <div className="modal-close cursor-pointer z-50 bg-[#213360] hover:bg-white hover:text-[#213360] text-white rounded-full w-6 h-6 flex justify-center items-center transition-all duration-200 ease-in-out">
              <svg
                onClick={() => handleAddUserHideModal()}
                className="fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
            <div className='bg-[#E8E8E8] w-full p-4 lg:h-full h-[500px] overflow-y-auto mx-auto'>
              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>Name :</p>
                <div>
                  <input className={`py-1 px-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-64 ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["firstName"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="firstName" name="firstName" placeholder="Name..." value={fieldsAddUser && fieldsAddUser.firstName ? fieldsAddUser.firstName : ''} required type="text" onChange={inputAddUserChange} />
                  {errorsAddUser && errorsAddUser["firstName"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsAddUser["firstName"]}
                    </div>
                    : null}
                </div>
              </div>
              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>Mobile No :</p>
                <div>
                  <input className={`px-1 py-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-64 ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["mobNo"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="mobNo" name="mobNo" placeholder="Mobile No..." value={fieldsAddUser && fieldsAddUser.mobNo ? fieldsAddUser.mobNo : ''} required type="number" onChange={inputAddUserChange} />
                  {errorsAddUser && errorsAddUser["mobNo"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsAddUser["mobNo"]}
                    </div>
                    : null}
                </div>
              </div>
              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>Demo Date :</p>
                <div>
                  <input className={`px-1 py-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-64 ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["mobNo"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="demoDate" name="demoDate" placeholder="Mobile No..." value={fieldsAddUser && fieldsAddUser.demoDate ? fieldsAddUser.demoDate : ''} required type="date" onChange={inputAddUserChange} />
                  {errorsAddUser && errorsAddUser["demoDate"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsAddUser["demoDate"]}
                    </div>
                    : null}
                </div>
              </div>
              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>Demo Address :</p>
                <div>
                  <input className={`px-1 py-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-64 ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["address"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="address" name="address" placeholder="Address..." value={fieldsAddUser && fieldsAddUser.address ? fieldsAddUser.address : ''} required type="text" onChange={inputAddUserChange} />
                  {errorsAddUser && errorsAddUser["address"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsAddUser["address"]}
                    </div>
                    : null}
                </div>
              </div>
              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>Landmark :</p>
                <div>
                  <input className={`px-1 py-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-64 ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["landmark"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="landmark" name="landmark" placeholder="Landmark..." value={fieldsAddUser && fieldsAddUser.landmark ? fieldsAddUser.landmark : ''} required type="text" onChange={inputAddUserChange} />
                  {errorsAddUser && errorsAddUser["landmark"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsAddUser["landmark"]}
                    </div>
                    : null}
                </div>
              </div>
              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>Preferred Time</p>
                <p className="text-gray-500">(Optional) :</p>
                <div className='flex'>
                  <div>
                    <label for="">Start</label>
                    <input className={`px-1 py-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["startTime"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      value={fieldsAddUser && fieldsAddUser.startTime ? fieldsAddUser.startTime : ''}
                      id="startTime" name="startTime" placeholder="Start Time..." min="09:00" max="18:00" step="900"
                      required type="time" onChange={handleTimeChange} />
                    {errorsAddUser && errorsAddUser["startTime"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsAddUser["startTime"]}
                      </div>
                      : null}
                  </div>
                  {/* <p className="px-1 py-1">To</p> */}
                  <div>
                    <label for="">End</label>
                    <input className={`px-1 py-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["endTime"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      value={fieldsAddUser && fieldsAddUser.endTime ? fieldsAddUser.endTime : ''}
                      id="endTime" name="endTime" placeholder="End Time..." required type="time" onChange={endTimeFormatTimeToAMPM} />
                    {errorsAddUser && errorsAddUser["endTime"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsAddUser["endTime"]}
                      </div>
                      : null}
                  </div>
                </div>
              </div>
              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>Preferred Gender :</p>
                <div>
                  <select className="px-1 py-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-64 ease-linear transition-all duration-150 " onChange={inputAddUserChange}
                    id="requiredGender"
                    value={fieldsAddUser && fieldsAddUser.requiredGender ? fieldsAddUser.requiredGender : ''}
                    name="requiredGender">
                    <option value="">Please Choose Gender&hellip;</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="No Preference">No Preference</option>
                  </select>
                </div>
              </div>

              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>Teaching Mode :</p>
                <div>
                  <select class="form-select border-1 px-1 py-1 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow w-64 ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputAddUserChange} id="teachingMode"
                    value={fieldsAddUser && fieldsAddUser.teachingMode ? fieldsAddUser.teachingMode : ''} name="teachingMode">
                    <option value="">Please Choose Teaching Mode&hellip;</option>
                    <option value="Home Tuition">Home Tuition</option>
                    <option value="Online Tuition">Online Tuition</option>
                  </select>
                  {errorsAddUser && errorsAddUser["teachingMode"] ?
                    <div className="text-xs text-red-600 invalid-feedback">
                      {errorsAddUser["teachingMode"]}
                    </div>
                    : null}
                </div>
              </div>


              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>Class :</p>
                <div>
                  <select class="form-select border-1 px-1 py-1 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow w-64 ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputAddUserChange} id="class" name="class"
                    value={fieldsAddUser && fieldsAddUser["class"] ? fieldsAddUser["class"] : ''} >
                    <option selected>Please Select Class</option>
                    {
                      getAllClass && getAllClass && getAllClass.length > 0 ?
                        getAllClass.map((element, index) => (
                          <option value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                        )) : null
                    }
                  </select>
                  {errorsAddUser && errorsAddUser["class"] ?
                    <div className="text-xs text-red-600 invalid-feedback">
                      {errorsAddUser["class"]}
                    </div>
                    : null}
                </div>
              </div>

              <div className="w-full ">
                <div className="relative mt-1 shadow-sm">
                  <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                    <label htmlFor="resourceList" style={{ color: 'black' }}>Subject</label>


                    <Select2
                      width="full"
                      isMulti
                      // value={studentData && studentData['Subject'] ? studentData['Subject'] : ''}
                      onChange={handleChangeInputCouresId}
                      options={teacherCouresRes}
                      isOptionDisabled={(option) => option.disabled}
                      className="basic-multi-select width: 'full'"
                      classNamePrefix="Activities"
                    />


                  </Grid>
                </div>
              </div>



              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>Board :</p>
                <div>
                  <select class="form-select border-1 px-1 py-1 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow w-64 ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputAddUserChange} id="board" name="board"
                    value={fieldsAddUser && fieldsAddUser["board"] ? fieldsAddUser["board"] : ''} >
                    <option selected>Please Select Board</option>
                    {
                      boardItems && boardItems && boardItems.length > 0 ?
                        boardItems.map((element, index) => (
                          <option value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                        )) : null
                    }
                  </select>
                  {errorsAddUser && errorsAddUser["board"] ?
                    <div className="text-xs text-red-600 invalid-feedback">
                      {errorsAddUser["board"]}
                    </div>
                    : null}
                </div>
              </div>
              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>City :</p>
                <div>
                  <select class="form-select border-1 px-1 py-1 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow w-64 ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputAddUserChange} id="cityId" name="cityId"
                    value={fieldsAddUser && fieldsAddUser["cityId"] ? fieldsAddUser["cityId"] : ''} >
                    <option selected>Please Select City</option>
                    {
                      getAllCity && getAllCity && getAllCity.length > 0 ?
                        getAllCity.map((element, index) => (
                          <option value={element && element.id ? element.id : null} key={index}>{element && element.name ? element.name : "NA"}</option>
                        )) : null
                    }
                  </select>
                  {errorsAddUser && errorsAddUser["cityId"] ?
                    <div className="text-xs text-red-600 invalid-feedback">
                      {errorsAddUser["cityId"]}
                    </div>
                    : null}
                </div>
              </div>
              <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                <p>Pin Code :</p>
                <div>
                  <select class="form-select border-1 px-1 py-1 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow w-64 ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputAddUserChange} id="pinCodeId" name="pinCodeId"
                    value={fieldsAddUser && fieldsAddUser["pinCodeId"] ? fieldsAddUser["pinCodeId"] : ''} >
                    <option selected>Please Select Pin Code</option>
                    {
                      getAllPinCode && getAllPinCode && getAllPinCode.length > 0 ?
                        getAllPinCode.map((element, index) => (
                          <option value={element && element.id ? element.id : null}>{element && element.pinNo ? element.pinNo : "NA"}</option>
                        )) : null
                    }
                  </select>
                  {errorsAddUser && errorsAddUser["pinCodeId"] ?
                    <div className="text-xs text-red-600 invalid-feedback">
                      {errorsAddUser["pinCodeId"]}
                    </div>
                    : null}
                </div>
              </div>

              <div className='flex justify-center items-center py-2'>
                <button class="bg-[#213360]  py-1.5 text-sm shadow-sm w-40 font-bold tracking-wider border text-white rounded-full hover:shadow-lg" onClick={addUserSubmit}>Submit </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>





  );
}
