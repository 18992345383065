import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Header from "../Header";
import Dashboard from "../../pages/dashboard";
import Sidebar from "../Sidebar";

import TutorManagement from "../../pages/TutorManagement/TutorManagement";
import TutorManagementRejected from "../../pages/TutorManagement/TutorManagementRejected";
// import AssignedClasses from "../../pages/AssignedClasses/AssignedClasses";
import DemoClassPending from "../../pages/DemoClassPending/DemoClassPending";
import DemoClassReject from "../../pages/DemoClassReject/DemoClassReject";
import DemoClassApproved from "../../pages/DemoClassApproved/DemoClassApproved";
import DemoClassTutorApplied from "../../pages/DemoClassTutorApplied/DemoClassTutorApplied";
import InactiveTutor from "../../pages/InactiveTutor/InactiveTutor";
import InactiveStudent from "../../pages/InactiveStudent/InactiveStudent";
import StudentManagement from "../../pages/StudentManagement/StudentManagement";
import UserDeactive from "../../pages/userdeactive/UserDeactive";


import KYCSuccess from "../../pages/kycsuccess/KYCSuccess";
import KYCPending from "../../pages/kycpending/KYCPending";

import WithdrawSuccess from "../../pages/withdrawsuccess/WithdrawSuccess";
import WithdrawPending from "../../pages/withdrawpending/WithdrawPending";

import Maintinance from "../../pages/maintinance/Maintinance";
import Support from "../../pages/Support/Support";
import AppSetting from "../../pages/appsetting/AppSetting";
import LoginHistory from "../../pages/loginhistory/LoginHistory";
import Package from "../../pages/package/Package";
import KYCReject from "../../pages/kycreject/KYCReject";
import coin from "../../pages/coin/coin";
import Document from "../../pages/Document/Document";
import Plan from "../../pages/Plan/Plan";
import MiningPftShare from "../../pages/MiningPftShare/MiningPftShare";
import WelcomeBonus from "../../pages/WelcomeBonus/WelcomeBonus";
import CmcCoin from "../../pages/CmcCoin/CmcCoin";
import LTArchiveReward from "../../pages/LTArchiveReward/LTArchiveReward";
import FastrackBonus from "../../pages/FastrackBonus/FastrackBonus";
import MoreDetails from "../../pages/MoreDetails/MoreDetails";
import Transaction from "../../pages/Transaction/Transaction";
import CashFlowTransaction from "../../pages/CashFlowTransaction/CashFlowTransaction";
import PromotionalPackageHistory from "../../pages/PromotionalPackageHistory/PromotionalPackageHistory";
import UserPackageHistory from "../../pages/UserPackageHistory/UserPackageHistory";
import City from "../../pages/City/City";
import teachcourse from "../../pages/teachCourse/teachcourse";
import AcceptedDemoClass from "../../pages/AcceptedDemoClass/AcceptedDemoClass";
import tution from "../../pages/Tution/tution";
import pinCode from "../../pages/pinCode/pinCode";
import SubAdmin from "../../pages/SubAdmin/SubAdmin";
import Assignment from "../../pages/Assignment/Assignment";
import SubAdminDisable from "../../pages/SubAdmin/SubAdminDisable";
import classManagement from "../../pages/classManagement/classManagement";
import board from "../../pages/board/board";
import tutorFee from "../../pages/TuterFee/tutorFee";
import assignTutor from "../../pages/DemoClassTutorApplied/assignTutor";
import Tutordetail from "../../pages/TutorManagement/Tutordetail";
import attendenseTutor from "../../pages/TutorManagement/attendenseTutor";
import CompleteTution from "../../pages/CompleteTution/CompleteTution";
import leadManagement from "../../pages/leadManagement/leadManagement";
import earningManagement from "../../pages/earningManagement/earningManagement";
import paymentManagement from "../../pages/paymentManagement/paymentManagement";
import paymentManagementstudent from "../../pages/paymentManagement/paymentManagementstudent";
import termsAndCondition from "../../pages/termsAndCondition/termsAndCondition";
import TutorMoreDetials from "../../pages/TutorManagement/TutorMoreDetials";
import Attendence from "../../pages/TutorManagement/Attendence";
import studentFee from "../../pages/TuterFee/studentFee";
import studentMoreDetials from "../../pages/TutorManagement/studentMoreDetials";
import holidays from "../../pages/City/holidays";
import DemoClassTutorAppliedComplete from "../../pages/DemoClassTutorApplied/DemoClassTutorAppliedComplete";
import tutorChangeRequest from "../../pages/Tution/tutorChangeRequest";
import Attendance from "../../pages/Tution/Attendance";
import banner from "../../pages/banner/banner";
import TutorKycDetails from "../../pages/TutorManagement/TutorKycDetails";
import Notification from "../../pages/Notification/Notification";

function Layout(props) {
  return (
    <div >
      <>
        <div className="overflow-hidden">
          <div className="bg-gray-100 ">
            <div className="min-h-screen flex flex-col justify-center">
              <div className="h-screen flex overflow-hidden" >
                <Sidebar />
                <div className="flex flex-col w-0 flex-1 overflow-hidden">
                  <Header history={props.history} />
                  <Switch>
                    <Route path="/app/dashboard" component={withRouter(Dashboard)} />
                    <Route path="/app/subAdmin" component={withRouter(SubAdmin)} />
                    <Route path="/app/SubAdminDisable" component={withRouter(SubAdminDisable)} />
                    <Route path="/app/cmcCoin" component={withRouter(CmcCoin)} />
                    <Route path="/app/tuition" component={withRouter(tution)} />
                    <Route path="/app/tutorChangeRequest" component={withRouter(tutorChangeRequest)} />
                    <Route path="/app/Attendance/:id" component={withRouter(Attendance)} />
                    <Route path="/app/pincode" component={withRouter(pinCode)} />
                    <Route path="/app/welcomeBonus" component={withRouter(WelcomeBonus)} />
                    <Route path="/app/moreDetails/:id" component={withRouter(MoreDetails)} />
                    <Route path="/app/TutorKycDetails/:id" component={withRouter(TutorKycDetails)} />
                    <Route path="/app/miningPftShare" component={withRouter(MiningPftShare)} />
                    <Route path="/app/activetutor" component={withRouter(TutorManagement)} />
                    <Route path="/app/rejecedtutorInterview" component={withRouter(TutorManagementRejected)} />
                    <Route path="/app/pendingdemoclass" component={withRouter(DemoClassPending)} />
                    <Route path="/app/approveddemoclass" component={withRouter(DemoClassApproved)} />
                    <Route path="/app/accepteddemoclass" component={withRouter(AcceptedDemoClass)} />
                    <Route path="/app/tutorapplieddemoclass" component={withRouter(DemoClassTutorApplied)} />
                    <Route path="/app/DemoClassTutorAppliedComplete" component={withRouter(DemoClassTutorAppliedComplete)} />
                    <Route path="/app/rejecteddemoclass" component={withRouter(DemoClassReject)} />
                    <Route path="/app/inactivetutor" component={withRouter(InactiveTutor)} />
                    <Route path="/app/inactivestudent" component={withRouter(InactiveStudent)} />
                    <Route path="/app/activestudent" component={withRouter(StudentManagement)} />
                    <Route path="/app/userdeactive" component={withRouter(UserDeactive)} />
                    <Route path="/app/ltarchivereward" component={withRouter(LTArchiveReward)} />
                    <Route path="/app/fastrackbonus" component={withRouter(FastrackBonus)} />
                    <Route path="/app/kycsuccess" component={withRouter(KYCSuccess)} />
                    <Route path="/app/kycpending" component={withRouter(KYCPending)} />
                    <Route path="/app/kycreject" component={withRouter(KYCReject)} />
                    <Route path="/app/withdrawsuccess" component={withRouter(WithdrawSuccess)} />
                    <Route path="/app/withdrawpending" component={withRouter(WithdrawPending)} />
                    <Route path="/app/promotionalPackageHistory" component={withRouter(PromotionalPackageHistory)} />
                    <Route path="/app/userPackageHistory" component={withRouter(UserPackageHistory)} />
                    <Route path="/app/maintinance" component={withRouter(Maintinance)} />
                    <Route path="/app/support" component={withRouter(Support)} />
                    <Route path="/app/appsetting" component={withRouter(AppSetting)} />
                    <Route path="/app/packages" component={withRouter(Package)} />
                    <Route path="/app/loginhistory" component={withRouter(LoginHistory)} />
                    <Route path="/app/transaction" component={withRouter(Transaction)} />
                    <Route path="/app/holidays" component={withRouter(holidays)} />
                    <Route path="/app/cashflowtransaction" component={withRouter(CashFlowTransaction)} />
                    <Route path="/app/document" component={withRouter(Document)} />
                    <Route path="/app/coin" component={withRouter(coin)} />
                    <Route path="/app/city" component={withRouter(City)} />
                    <Route path="/app/teachcourse" component={withRouter(teachcourse)} />
                    <Route path="/app/plan" component={withRouter(Plan)} />
                    <Route path="/app/assignment" component={withRouter(Assignment)} />
                    <Route path="/app/classManagement" component={withRouter(classManagement)} />
                    <Route path="/app/board" component={withRouter(board)} />
                    <Route path="/app/tutorFee/:tuitionId?/:tutorId?" component={withRouter(tutorFee)} />
                    <Route path="/app/studentFee/:tuitionId?/:studentId?" component={withRouter(studentFee)} />
                    <Route path="/app/assignTutor/:id" component={withRouter(assignTutor)} />
                    <Route path="/app/tutorDetails/:id" component={withRouter(Tutordetail)} />
                    <Route path="/app/attendenseTutor/:id" component={withRouter(attendenseTutor)} />
                    <Route path="/app/tutorMoreDetials/:id" component={withRouter(TutorMoreDetials)} />
                    <Route path="/app/studentMoreDetials/:id" component={withRouter(studentMoreDetials)} />
                    <Route path="/app/attendence/:id" component={withRouter(Attendence)} />
                    <Route path="/app/completeTution" component={withRouter(CompleteTution)} />
                    <Route path="/app/leadManagement" component={withRouter(leadManagement)} />
                    <Route path="/app/earningManagement" component={withRouter(earningManagement)} />
                    <Route path="/app/paymentManagement" component={withRouter(paymentManagement)} />
                    <Route path="/app/paymentManagementstudent" component={withRouter(paymentManagementstudent)} />
                    <Route path="/app/terms&Condition" component={withRouter(termsAndCondition)} />
                    <Route path="/app/banner" component={withRouter(banner)} />
                    <Route path="/app/Notification" component={withRouter(Notification)} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
