export const tutionConstants = {

    GETALL_TUTION_REQUEST: 'GETALL_TUTION_REQUEST',
    GETALL_TUTION_SUCCESS: 'GETALL_TUTION_SUCCESS',
    GETALL_TUTION_FAILURE: 'GETALL_TUTION_FAILURE',

    MANUAL_TUTOR_CHANGE_REQUEST: 'MANUAL_TUTOR_CHANGE_REQUEST',
    MANUAL_TUTOR_CHANGE_SUCCESS: 'MANUAL_TUTOR_CHANGE_SUCCESS',
    MANUAL_TUTOR_CHANGE_FAILURE: 'MANUAL_TUTOR_CHANGE_FAILURE',

    ADD_AMOUNT_IN_TUITION_REQUEST: 'ADD_AMOUNT_IN_TUITION_REQUEST',
    ADD_AMOUNT_IN_TUITION_SUCCESS: 'ADD_AMOUNT_IN_TUITION_SUCCESS',
    ADD_AMOUNT_IN_TUITION_FAILURE: 'ADD_AMOUNT_IN_TUITION_FAILURE',

    UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_REQUEST: 'UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_REQUEST',
    UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_SUCCESS: 'UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_SUCCESS',
    UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_FAILURE: 'UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_FAILURE',

    GET_ATTENDANCE_LIST_BY_TUTOR_REQUEST: 'GET_ATTENDANCE_LIST_BY_TUTOR_REQUEST',
    GET_ATTENDANCE_LIST_BY_TUTOR_SUCCESS: 'GET_ATTENDANCE_LIST_BY_TUTOR_SUCCESS',
    GET_ATTENDANCE_LIST_BY_TUTOR_FAILURE: 'GET_ATTENDANCE_LIST_BY_TUTOR_FAILURE',

    GET_TUTION_LIST_BY_ID_REQUEST: 'GET_TUTION_LIST_BY_ID_REQUEST',
    GET_TUTION_LIST_BY_ID_SUCCESS: 'GET_TUTION_LIST_BY_ID_SUCCESS',
    GET_TUTION_LIST_BY_ID_FAILURE: 'GET_TUTION_LIST_BY_ID_FAILURE',

    GET_LIST_TUTION_REQUEST: 'GET_LIST_TUTION_REQUEST',
    GET_LIST_TUTION_SUCCESS: 'GET_LIST_TUTION_SUCCESS',
    GET_LIST_TUTION_FAILURE: 'GET_LIST_TUTION_FAILURE',

    REFUSE_REQUEST_REQUEST: 'REFUSE_REQUEST_REQUEST',
    REFUSE_REQUEST_SUCCESS: 'REFUSE_REQUEST_SUCCESS',
    REFUSE_REQUEST_FAILURE: 'REFUSE_REQUEST_FAILURE',

    GET_SUGGESTED_TUTOR_BY_TUITION_REQUEST: 'GET_SUGGESTED_TUTOR_BY_TUITION_REQUEST',
    GET_SUGGESTED_TUTOR_BY_TUITION_SUCCESS: 'GET_SUGGESTED_TUTOR_BY_TUITION_SUCCESS',
    GET_SUGGESTED_TUTOR_BY_TUITION_FAILURE: 'GET_SUGGESTED_TUTOR_BY_TUITION_FAILURE',

    GET_SUGGESTED_TUTOR_BY_TUITION_1_REQUEST: 'GET_SUGGESTED_TUTOR_BY_TUITION_1_REQUEST',
    GET_SUGGESTED_TUTOR_BY_TUITION_1_SUCCESS: 'GET_SUGGESTED_TUTOR_BY_TUITION_1_SUCCESS',
    GET_SUGGESTED_TUTOR_BY_TUITION_1_FAILURE: 'GET_SUGGESTED_TUTOR_BY_TUITION_1_FAILURE',

    GET_LIST_TUTORCLOSE_REQUEST: 'GET_LIST_TUTORCLOSE_REQUEST',
    GET_LIST_TUTORCLOSE_SUCCESS: 'GET_LIST_TUTORCLOSE_SUCCESS',
    GET_LIST_TUTORCLOSE_FAILURE: 'GET_LIST_TUTORCLOSE_FAILURE',

    SEND_DEMO_REQUEST_REQUEST: 'SEND_DEMO_REQUEST_REQUEST',
    SEND_DEMO_REQUEST_SUCCESS: 'SEND_DEMO_REQUEST_SUCCESS',
    SEND_DEMO_REQUEST_FAILURE: 'SEND_DEMO_REQUEST_FAILURE',

    TUITION_APPROVE_REQUEST: 'TUITION_APPROVE_REQUEST',
    TUITION_APPROVE_SUCCESS: 'TUITION_APPROVE_SUCCESS',
    TUITION_APPROVE_FAILURE: 'TUITION_APPROVE_FAILURE',

    ADD_TUTION_REQUEST: 'ADD_TUTION_REQUEST',
    ADD_TUTION_SUCCESS: 'ADD_TUTION_SUCCESS',
    ADD_TUTION_FAILURE: 'ADD_TUTION_FAILURE',

    DELETE_TUTION_REQUEST: 'DELETE_TUTION_REQUEST',
    DELETE_TUTION_SUCCESS: 'DELETE_TUTION_SUCCESS',
    DELETE_TUTION_FAILURE: 'DELETE_TUTION_FAILURE',

    UPDATE_TUTION_REQUEST: 'UPDATE_TUTION_REQUEST',
    UPDATE_TUTION_SUCCESS: 'UPDATE_TUTION_SUCCESS',
    UPDATE_TUTION_FAILURE: 'UPDATE_TUTION_FAILURE',

    DISABLE_TUTION_REQUEST: 'DISABLE_TUTION_REQUEST',
    DISABLE_TUTION_SUCCESS: 'DISABLE_TUTION_SUCCESS',
    DISABLE_TUTION_FAILURE: 'DISABLE_TUTION_FAILURE',

    DISABLE_TUTIONCLOSE_REQUEST: 'DISABLE_TUTIONCLOSE_REQUEST',
    DISABLE_TUTIONCLOSE_SUCCESS: 'DISABLE_TUTIONCLOSE_SUCCESS',
    DISABLE_TUTIONCLOSE_FAILURE: 'DISABLE_TUTIONCLOSE_FAILURE',

    GETALL_PINCODE_REQUEST: 'GETALL_PINCODE_REQUEST',
    GETALL_PINCODE_SUCCESS: 'GETALL_PINCODE_SUCCESS',
    GETALL_PINCODE_FAILURE: 'GETALL_PINCODE_FAILURE',

    GETALL_PINCODE1_REQUEST: 'GETALL_PINCODE1_REQUEST',
    GETALL_PINCODE1_SUCCESS: 'GETALL_PINCODE1_SUCCESS',
    GETALL_PINCODE1_FAILURE: 'GETALL_PINCODE1_FAILURE',

    GET_LIST_PINCODE_REQUEST: 'GET_LIST_PINCODE_REQUEST',
    GET_LIST_PINCODE_SUCCESS: 'GET_LIST_PINCODE_SUCCESS',
    GET_LIST_PINCODE_FAILURE: 'GET_LIST_PINCODE_FAILURE',

    ADD_PINCODE_REQUEST: 'ADD_PINCODE_REQUEST',
    ADD_PINCODE_SUCCESS: 'ADD_PINCODE_SUCCESS',
    ADD_PINCODE_FAILURE: 'ADD_PINCODE_FAILURE',

    DELETE_PINCODE_REQUEST: 'DELETE_PINCODE_REQUEST',
    DELETE_PINCODE_SUCCESS: 'DELETE_PINCODE_SUCCESS',
    DELETE_PINCODE_FAILURE: 'DELETE_PINCODE_FAILURE',

    UPDATE_PINCODE_REQUEST: 'UPDATE_PINCODE_REQUEST',
    UPDATE_PINCODE_SUCCESS: 'UPDATE_PINCODE_SUCCESS',
    UPDATE_PINCODE_FAILURE: 'UPDATE_PINCODE_FAILURE',

    DISABLE_PINCODE_REQUEST: 'DISABLE_PINCODE_REQUEST',
    DISABLE_PINCODE_SUCCESS: 'DISABLE_PINCODE_SUCCESS',
    DISABLE_PINCODE_FAILURE: 'DISABLE_PINCODE_FAILURE',

    GETALL_TUTORFEE_REQUEST: 'GETALL_TUTORFEE_REQUEST',
    GETALL_TUTORFEE_SUCCESS: 'GETALL_TUTORFEE_SUCCESS',
    GETALL_TUTORFEE_FAILURE: 'GETALL_TUTORFEE_FAILURE',

    GET_LIST_TUTORFEE_REQUEST: 'GET_LIST_TUTORFEE_REQUEST',
    GET_LIST_TUTORFEE_SUCCESS: 'GET_LIST_TUTORFEE_SUCCESS',
    GET_LIST_TUTORFEE_FAILURE: 'GET_LIST_TUTORFEE_FAILURE',

    ADD_TUTORFEE_REQUEST: 'ADD_TUTORFEE_REQUEST',
    ADD_TUTORFEE_SUCCESS: 'ADD_TUTORFEE_SUCCESS',
    ADD_TUTORFEE_FAILURE: 'ADD_TUTORFEE_FAILURE',

    DELETE_TUTORFEE_REQUEST: 'DELETE_TUTORFEE_REQUEST',
    DELETE_TUTORFEE_SUCCESS: 'DELETE_TUTORFEE_SUCCESS',
    DELETE_TUTORFEE_FAILURE: 'DELETE_TUTORFEE_FAILURE',

    UPDATE_TUTORFEE_REQUEST: 'UPDATE_TUTORFEE_REQUEST',
    UPDATE_TUTORFEE_SUCCESS: 'UPDATE_TUTORFEE_SUCCESS',
    UPDATE_TUTORFEE_FAILURE: 'UPDATE_TUTORFEE_FAILURE',

    DISABLE_TUTORFEE_REQUEST: 'DISABLE_TUTORFEE_REQUEST',
    DISABLE_TUTORFEE_SUCCESS: 'DISABLE_TUTORFEE_SUCCESS',
    DISABLE_TUTORFEE_FAILURE: 'DISABLE_TUTORFEE_FAILURE',


    GETALL_TUTORID_REQUEST: 'GETALL_TUTORID_REQUEST',
    GETALL_TUTORID_SUCCESS: 'GETALL_TUTORID_SUCCESS',
    GETALL_TUTORID_FAILURE: 'GETALL_TUTORID_FAILURE',


    GETALL_STUDENTID_REQUEST: 'GETALL_STUDENTID_REQUEST',
    GETALL_STUDENTID_SUCCESS: 'GETALL_STUDENTID_SUCCESS',
    GETALL_STUDENTID_FAILURE: 'GETALL_STUDENTID_FAILURE',

    GETALL_COURSEID_REQUEST: 'GETALL_COURSEID_REQUEST',
    GETALL_COURSEID_SUCCESS: 'GETALL_COURSEID_SUCCESS',
    GETALL_COURSEID_FAILURE: 'GETALL_COURSEID_FAILURE',

};
