import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  // coinActions,
  userActions
} from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';

import DepositModal from "./components/DepositModal/DepositModal";
import WithdrawModal from "./components/WithdrawModal/WithdrawModal";
import TransactionCreateModal from "./components/TransactionCreateModal/TransactionCreateModal";
import FastrackCreateModal from "./components/FastrackCreateModal/FastrackCreateModal";
import WbalanceModal from "./components/WbalanceModal/WbalanceModal";
import TeamMiningAmountModal from "./components/TeamMiningAmountModal/TeamMiningAmountModal";
import UpdateTxAmountModal from "./components/UpdateTxAmountModal/UpdateTxAmountModal";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment'
// import ReactPaginate from 'react-paginate';
// import DialogExample from "./components/DialogExample/DialogExample";
// import ViewCoinModal from "./components/ViewCoinModal/ViewCoinModal";
// import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
// import CreateCoinModal from "./components/CreateCoinModal/CreateCoinModal";
// import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



class MoreDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateTxAmtData: {},
      fieldsUpdateTxAmt: {},
      errorsUpdateTxAmt: {},
      fieldsTeamMining: {},
      errorsTeamMining: {},
      fieldsWbalance: {},
      errorsWbalance: {},
      wbalanceData: {},
      rowDataDetails: {},
      fieldsUser: {},
      errorsUser: {},
      fieldsUpdateCoin: {},
      errorsUpdateCoin: {},
      viewRowData: {},
      transactionCreateModal: false,
      fastrackCreateModal: false,
      fieldsTransaction: {},
      errorsTransaction: {},
      fieldsFastrack: {},
      errorsFastrack: {},
      txType: "",
      levelIncomeInputData: '',
      cUser: '',
      depositModal: false,
      withdrawModal: false,
      moreDetailsCreateModal: false,
      UpdateCoinModal: false,
      loginToThisAccountModal: false,
      openWbalanceModal: false,
      updateTxAmt: false,
      updateTxAmtModal: false,
      openTeamMiningModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      // updatePwdformData: {
      //   "id": "",
      //   "password": ""
      // }
    }
  }
  componentDidMount() {
    let data = {
      "id": this.props.match.params.id,
    }
    let reqdata = {
      "id": this.props.match.params.id,
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size,
    }
    let req = {
      "id": this.props.match.params.id,
      // "txType": "SEND_RECEIVED",
      // "from": "2022-08-08",
      // "to": "2022-08-08",
      // "keyWord": "",
      // "pageNo": this.state.pageNo,
      // "size": this.state.size,
    }
    let reqTicket = {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size,
    }
    console.log("datadatadatadatadatadata______________", data)
    // this.props.dispatch(userActions.getUserTeamByUserId(data));
    // this.props.dispatch(userActions.getWalletList(data));
    // this.props.dispatch(userActions.getLoginhistory(reqdata));
    // this.props.dispatch(userActions.getUserPromoPackageByAdmin(data));
    // this.props.dispatch(userActions.getUserPackages(data));
    // this.props.dispatch(userActions.getTx(req));
    // this.props.dispatch(userActions.getUserInfoByAdmin(data));
    // this.props.dispatch(userActions.getSocialMediaById(data));
    // this.props.dispatch(userActions.getUserProfile(data));
    // this.props.dispatch(userActions.getUserPackagesTeam(data));
    // this.props.dispatch(userActions.getTicketList(reqTicket));

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsUser: {},
        errorsUser: {},
        viewRowData: {},
        depositModal: false,
        UpdateCoinModal: false,
        withdrawModal: false,
        transactionCreateModal: false,
        fastrackCreateModal: false,
        fieldsTransaction: {},
        errorsTransaction: {},
        fieldsFastrack: {},
        errorsFastrack: {},
        openWbalanceModal: false,
        updateTxAmtModal: false,
        openTeamMiningModal: false,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }

  handleChangePassword = () => {
    // this.props.dispatch(userActions.changePassword(reqdata));

  }

  handlePageClick = (data) => {
    // console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    // let datatemp = {
    //   "keyWord": this.state.keyWord,
    //   "pageNo": data.selected + 1,
    //   "size": this.state.size
    // }
    // this.props.dispatch(coinActions.getCoinList(datatemp));
  }

  handleOpenDepositModal = (data) => {
    this.setState({ depositModal: true, rowDataDetails: data });
  }

  handleCloseDepositModal = () => {
    this.setState({ depositModal: false });
  }



  handleOpenWithdrawModal = (data) => {
    this.setState({ withdrawModal: true, rowDataDetails: data });
  }

  handleCloseWithdrawModal = () => {
    this.setState({ withdrawModal: false });
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    // let data = {
    //   "keyWord": value,
    //   "pageNo": 1,
    //   "size": this.state.size
    // }
    // this.props.dispatch(coinActions.getCoinList(data));
  }


  handleOpenCreateModalMoreDetails = (data) => {

    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }
  handleOpenCoinUpdateModal = (data) => {
    this.setState({ UpdateCoinModal: true, fieldsUpdateCoin: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {

    this.setState({ loginToThisAccountModal: true });
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    // console.log(name, value);
    errorsUser[name] = "";
    this.setState({ fieldsUser, errorsUser });
  }


  depositBalanceSubmit = () => {


    if (this.handleValidationCoin()) {
      let reqData = {
        "userId": this.props.match.params.id,
        "coinId": this.state.rowDataDetails.id,
        "amt": parseInt(this.state.fieldsUser.amt)
      }


      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>depositBalanceSubmit>", reqData);
      this.props.dispatch(userActions.depositUserBalance(reqData));
    }

  }

  withdrawBalanceSubmit = () => {


    if (this.handleValidationCoin()) {
      let reqData = {
        "userId": this.props.match.params.id,
        "coinId": this.state.rowDataDetails.id,
        "amt": parseInt(this.state.fieldsUser.amt)
      }


      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>depositBalanceSubmit>", reqData);
      this.props.dispatch(userActions.withdrawUserBalance(reqData));
    }

  }

  // navigate = (data) => {

  //   this.props.history.push('/app/series/' + data.sportId);
  // }




  // handleValidationCoin = () => {
  //   let fieldsUser = this.state.fieldsUser;
  //   let errorsUser = {};
  //   let formIsValid = true;

  //   //amt
  //   if (!fieldsUser["amt"] || fieldsUser["amt"] === "") {
  //     formIsValid = false;
  //     errorsUser["amt"] = "Cannot be empty amt";
  //   }
  //   // console.log("errorsUsererrorsUsererrorsUsererrors::::", errorsUser);

  //   this.setState({ errorsUser: errorsUser });
  //   return formIsValid;
  // }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      // this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      // console.log("No File To Upload!")
    }

  }

  changeLevelStatus = (data) => {
    let datatemp = {
      "id": data.id,
    }
    // let data = {
    //   "id": this.props.match.params.id,
    // }
    // let paginationdata = {
    //   "userType": "ACTIVE",
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": this.state.size
    // }
    confirmAlert({


      title: 'Confirm to change level status of User?',
      message: `Are you sure to change level status of ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.changeLevelStatus(datatemp))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  inputTransactionChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsTransaction = this.state.fieldsTransaction;
    let errorsTransaction = this.state.errorsTransaction;
    fieldsTransaction[name] = value;
    console.log(name, value);
    errorsTransaction[name] = "";
    this.setState({ fieldsTransaction, errorsTransaction });
  }
  inputFastrackChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsFastrack = this.state.fieldsFastrack;
    let errorsFastrack = this.state.errorsFastrack;
    fieldsFastrack[name] = value;
    console.log('fieldsFastrack__', name, value);
    console.log(name, value);
    errorsFastrack[name] = "";
    this.setState({ fieldsFastrack, errorsFastrack });
  }
  inputTypeChangePackage = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    if (value === "null") {
      let fieldsTransaction = this.state.fieldsTransaction;
      let errorsTransaction = this.state.errorsTransaction;
      fieldsTransaction[name] = null;
      console.log(name, null);
      errorsTransaction[name] = "";
      this.setState({ fieldsTransaction, errorsTransaction });
      let data1 = {
        "id": null,
      }
      let data2 = {
        "userId": null,
        "teamList": null,
      }
      console.log('data_______33333333333', data1);
      this.props.dispatch(userActions.getUserPackages(data1));
      this.props.dispatch(userActions.getUserLevel(data2));
    }
    else {
      let fieldsTransaction = this.state.fieldsTransaction;
      let errorsTransaction = this.state.errorsTransaction;
      fieldsTransaction[name] = value;
      console.log(name, value);
      errorsTransaction[name] = "";
      this.setState({ fieldsTransaction, errorsTransaction });
      let data1 = {
        "id": value,
      }
      // let data2 = {
      //   "userId": "6313434e6e1eed5d9e1e1613",
      //   "teamList": "631348e16e1eed5d9e1e22d8"
      // }
      let data2 = {
        "userId": this.props.match.params.id,
        "teamList": value,
      }
      console.log('data_______33333333333', data1);
      this.props.dispatch(userActions.getUserPackages(data1));
      this.props.dispatch(userActions.getUserLevel(data2));

    }


  }

  // inputTypeChangePackage = (e) => {
  //   e.preventDefault();
  //   let {
  //     // name,
  //     value } = e.target;
  //   console.log('11111111111111111111', value);
  //   this.setState({ cUser: value })

  //   console.log('222222222222222222', this.state.cUser);
  //   let data3 = {
  //     "id": this.state.cUser,
  //   }
  //   this.props.dispatch(userActions.getUserPackages(data3));
  // }
  handleValidationTransaction = () => {
    let fieldsTransaction = this.state.fieldsTransaction;
    let errorsTransaction = {};
    let formIsValid = true;

    //Email
    if (!fieldsTransaction["amount"] || fieldsTransaction["amount"] === "") {
      formIsValid = false;
      errorsTransaction["amount"] = "Cannot be empty amount";
    }

    if (!fieldsTransaction["txType"] || fieldsTransaction["txType"] === "") {
      formIsValid = false;
      errorsTransaction["txType"] = "Please select any transaction type";
    }

    if (!fieldsTransaction["givenByUser"] || fieldsTransaction["givenByUser"] === "") {
      formIsValid = false;
      errorsTransaction["givenByUser"] = "Please select the user";
    }
    if (!fieldsTransaction["packgeId"] || fieldsTransaction["packgeId"] === "") {
      formIsValid = false;
      errorsTransaction["packgeId"] = "Please select the package";
    }

    this.setState({ errorsTransaction: errorsTransaction });
    return formIsValid;
  }
  handleValidationFastrack = () => {
    let fieldsFastrack = this.state.fieldsFastrack;
    let errorsFastrack = {};
    let formIsValid = true;

    if (!fieldsFastrack["amount"] || fieldsFastrack["amount"] === "") {
      formIsValid = false;
      errorsFastrack["amount"] = "Cannot be empty amount";
    }

    if (!fieldsFastrack["txType"] || fieldsFastrack["txType"] === "") {
      formIsValid = false;
      errorsFastrack["txType"] = "Please select any transaction type";
    }

    if (!fieldsFastrack["givenByUser"] || fieldsFastrack["givenByUser"] === "") {
      formIsValid = false;
      errorsFastrack["givenByUser"] = "Please select the user";
    }
    this.setState({ errorsFastrack: errorsFastrack });
    return formIsValid;
  }


  createTransactionSubmit = () => {
    let { users } = this.props;
    let { levelItem } = users;
    let req = {
      // "id": "63103b99301be013ef3c5583",
      "id": this.props.match.params.id,
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size,
    }
    // if (this.handleValidationTransaction()) {
    let reqData = {
      "userId": this.props.match.params.id,
      // "userId": "63103b99301be013ef3c5583",
      "amount": this.state.fieldsTransaction.amount,
      "txType": this.state.fieldsTransaction.txType,
      "givenByUser": this.state.fieldsTransaction.givenByUser,
      "packgeId": this.state.fieldsTransaction.packgeId,
      "level": levelItem.level,
    }

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>createCoinSubmit>", reqData);
    this.props.dispatch(userActions.createTransaction(reqData, req));
    // }

  }

  createFastrackSubmit = () => {
    // let { users } = this.props;
    // let { levelItem } = users;
    // let req = {
    //   // "id": "63103b99301be013ef3c5583",
    //   "id": this.props.match.params.id,
    //   "keyWord": "",
    //   "pageNo": this.state.pageNo,
    //   "size": this.state.size,
    // }
    // if (this.handleValidationFastrack()) {
    let req = {
      "id": this.props.match.params.id,
    }

    let reqData = {
      "userId": this.props.match.params.id,
      // "userId": "63103b99301be013ef3c5583",
      "amount": this.state.fieldsFastrack.amount,
      "txType": this.state.fieldsFastrack.txType,
      // "givenByUser": this.state.fieldsFastrack.givenByUser,
      // "packgeId": this.state.fieldsFastrack.packgeId,
      // "level": levelItem.level,
    }

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>createCoinSubmit>", reqData);
    this.props.dispatch(userActions.payFastrackOrLifeTimeBonus(reqData, req));
    // }

  }

  // inputTypeChange = (e) => {
  //   e.preventDefault();
  //   let {
  //     value } = e.target;
  //   console.log('11111111111111111111', value);
  //   this.setState({ txType: value })
  //   console.log('222222222222222222', this.state.txType);

  // }

  // removeAddress = (data) => {
  //   console.log('datadatadatadatadatadatadatadatadatadatadata', data);
  //   let reqData = {
  //     id: data.userId,
  //     address: data.address

  //   }
  //   console.log('reqDatareqData_______________-reqData', reqData);
  //   this.props.dispatch(userActions.removeDftcWalletAddress(reqData));
  // }

  removeAddress = (data) => {
    let reqData = {
      id: data.userId,
      address: data.address

    }


    confirmAlert({
      title: 'Confirm to remove DFTC address?',
      message: 'Are you sure to remove address ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.removeDftcWalletAddress(reqData))
        },
        {
          label: 'No'
        }
      ]
    });


  }


  createWalletSubmit = (data) => {
    let reqData = {
      "coinId": data.id,
      "id": this.props.match.params.id
    }
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>depositBalanceSubmit", reqData);
    this.props.dispatch(userActions.createWallet(reqData));
  }

  handleTransactionHideModal = () => {
    this.setState({ transactionCreateModal: false, fieldsTransaction: {}, errorsTransaction: {} });
    // this.setState({ appsettingUpdateModal: false });
  }

  handleFastrackHideModal = () => {
    this.setState({ fastrackCreateModal: false, fieldsFastrack: {}, errorsFastrack: {} });
    // this.setState({ appsettingUpdateModal: false });
  }

  handleOpenCreateTransactionModal = () => {
    // console.log("clicked");
    this.setState({ transactionCreateModal: true });
  }

  handleOpenCreateFastrackModal = () => {
    // console.log("clicked");
    this.setState({ fastrackCreateModal: true });
  }

  updateWbalanceModal = (data) => {
    this.setState({ openWbalanceModal: true, wbalanceData: data });
  }

  handleCloseWbalanceModal = () => {
    this.setState({ openWbalanceModal: false, fieldsWbalance: {}, errorsWbalance: {} });
  }

  handleOpenTeamMiningModal = (data) => {
    this.setState({ openTeamMiningModal: true, teamMiningData: data });
  }

  handleCloseTeamMiningModal = () => {
    this.setState({ openTeamMiningModal: false, fieldsTeamMining: {}, errorsTeamMining: {} });
  }


  updateTxAmtSubmitModal = (data) => {
    this.setState({ updateTxAmtModal: true, updateTxAmtData: data });
  }

  handleCloseUpdateTxAmtModal = () => {
    this.setState({ updateTxAmtModal: false, fieldsUpdateTxAmt: {}, errorsUpdateTxAmt: {} });
  }

  approvePromoPack = (data) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>11111111", data);

    let reqData = {
      id: data.id,
      userId: data.userId,
    }
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>222222222", reqData);
    // this.props.dispatch(userActions.approvePromoPack(reqData));
  }

  approvePromoPack = (data) => {

    let paginationData = {
      "id": this.props.match.params.id,
    }

    let reqData = {
      id: data.id,
      userId: data.userId,
    }
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>222222222", reqData);
    confirmAlert({


      title: 'Confirm to Approve Promo Pack?',
      // message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          // onClick: () => this.props.dispatch(userActions.approvePromoPack(reqData, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  deleteUserPackage = (data) => {
    let req = {
      "id": data.id,
    }
    // this.props.dispatch(userActions.deleteUserPackage(req));
  }


  deleteUserPackage = (data) => {

    // let paginationData = {
    //   "id": this.props.match.params.id,
    // }

    let req = {
      "id": data.id,
    }
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>222222222", req);
    confirmAlert({


      title: 'Confirm to Delete Promo Pack?',
      // message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          // onClick: () => this.props.dispatch(userActions.deleteUserPackage(req))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  wbalanceSubmit = () => {


    if (this.handleValidationWbalance()) {
      let reqData = {
        "id": this.props.match.params.id,
        "amt": Number(this.state.fieldsWbalance.amt).toFixed(2)
      }


      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>updatePayoutAmount##Submit>", reqData);
      // this.props.dispatch(userActions.updatePayoutAmount(reqData));
    }

  }

  handleValidationWbalance = () => {
    let fieldsWbalance = this.state.fieldsWbalance;
    let errorsWbalance = {};
    let formIsValid = true;

    //amt
    if (!fieldsWbalance["amt"] || fieldsWbalance["amt"] === "") {
      formIsValid = false;
      errorsWbalance["amt"] = "Cannot be empty amt";
    }
    // console.log("errorsUsererrorsUsererrorsUsererrors::::", errorsWbalance);

    this.setState({ errorsWbalance: errorsWbalance });
    return formIsValid;
  }

  inputChangeWbalance = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsWbalance = this.state.fieldsWbalance;
    let errorsWbalance = this.state.errorsWbalance;
    fieldsWbalance[name] = value;
    // console.log(name, value);
    errorsWbalance[name] = "";
    this.setState({ fieldsWbalance, errorsWbalance });
  }

  inputChangeUpdateTxAmt = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdateTxAmt = this.state.fieldsUpdateTxAmt;
    let errorsUpdateTxAmt = this.state.errorsUpdateTxAmt;
    fieldsUpdateTxAmt[name] = value;
    // console.log(name, value);
    errorsUpdateTxAmt[name] = "";
    this.setState({ fieldsUpdateTxAmt, errorsUpdateTxAmt });
  }



  updateTxAmtSubmit = () => {
    let tempData = {
      "id": this.props.match.params.id,
      // "txType": "SEND_RECEIVED",
      // "from": "2022-08-08",
      // "to": "2022-08-08",
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size,
    }

    if (this.handleValidationUpdateTxAmt()) {
      let reqData = {
        "id": this.state.updateTxAmtData && this.state.updateTxAmtData.id ? this.state.updateTxAmtData.id : null,
        // "coinId": this.state.rowDataDetails.id,
        // "amt": parseInt(this.state.fieldsUpdateTxAmt.amt),
        "amt": Number(this.state.fieldsUpdateTxAmt && this.state.fieldsUpdateTxAmt.amt ? this.state.fieldsUpdateTxAmt.amt : null).toFixed(2)
      }


      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>updateTxAmount$$$$$Submit>", reqData);
      // this.props.dispatch(userActions.updateTxAmount(reqData, tempData));
    }

  }

  handleValidationUpdateTxAmt = () => {
    let fieldsUpdateTxAmt = this.state.fieldsUpdateTxAmt;
    let errorsUpdateTxAmt = {};
    let formIsValid = true;

    //amt
    if (!fieldsUpdateTxAmt["amt"] || fieldsUpdateTxAmt["amt"] === "") {
      formIsValid = false;
      errorsUpdateTxAmt["amt"] = "Cannot be empty amt";
    }
    // console.log("errorsUsererrorsUsererrorsUsererrors::::", errorsUpdateTxAmt);

    this.setState({ errorsUpdateTxAmt: errorsUpdateTxAmt });
    return formIsValid;
  }

  inputChangeTeamMining = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsTeamMining = this.state.fieldsTeamMining;
    let errorsTeamMining = this.state.errorsTeamMining;
    fieldsTeamMining[name] = value;
    // console.log(name, value);
    errorsTeamMining[name] = "";
    this.setState({ fieldsTeamMining, errorsTeamMining });
  }


  teamMiningSubmit = () => {
    if (this.handleValidationTeamMining()) {
      let reqData = {
        "id": this.state.match.params.id,
        "amt": Number(this.state.fieldsTeamMining.amt).toFixed(2)
      }
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>updatePayoutAmount##Submit>", reqData);
      // this.props.dispatch(userActions.updateTeamMiningAmount(reqData));
    }
  }



  handleValidationTeamMining = () => {
    let fieldsTeamMining = this.state.fieldsTeamMining;
    let errorsTeamMining = {};
    let formIsValid = true;

    //amt
    if (!fieldsTeamMining["amt"] || fieldsTeamMining["amt"] === "") {
      formIsValid = false;
      errorsTeamMining["amt"] = "Cannot be empty amt";
    }
    // console.log("errorsUsererrorsUsererrorsUsererrors::::", errorsTeamMining);
    this.setState({ errorsTeamMining: errorsTeamMining });
    return formIsValid;
  }



  inputChangeLevelIncome = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let { levelIncomeInputData } = this.state;

    levelIncomeInputData = value;
    this.setState({ levelIncomeInputData });
  }

  inputChangeLevelIncomeSet = (data) => {

    let { levelIncomeInputData } = this.state;

    levelIncomeInputData = data;
    this.setState({ levelIncomeInputData });
  }

  submitUpdateDataLevelIncome = (data) => {

    console.log('call here api', this.state.levelIncomeInputData);
    let reqData = {
      "userId": this.props.match.params.id,
      // "userId": "63103b99301be013ef3c5583",
      "totalPortfolio": this.state.levelIncomeInputData,
    }

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>createCoinSubmit>", reqData);
    // this.props.dispatch(userActions.manageUserPortfolio(reqData));

  }


  // createTransactionSubmit = () => {
  //   let { users } = this.props;
  //   let { levelItem } = users;
  //   let req = {
  //     // "id": "63103b99301be013ef3c5583",
  //     "id": this.props.match.params.id,
  //     "keyWord": "",
  //     "pageNo": this.state.pageNo,
  //     "size": this.state.size,
  //   }
  //   // if (this.handleValidationTransaction()) {
  //   let reqData = {
  //     "userId": this.props.match.params.id,
  //     // "userId": "63103b99301be013ef3c5583",
  //     "amount": this.state.fieldsTransaction.amount,
  //     "txType": this.state.fieldsTransaction.txType,
  //     "givenByUser": this.state.fieldsTransaction.givenByUser,
  //     "packgeId": this.state.fieldsTransaction.packgeId,
  //     "level": levelItem.level,
  //   }

  //   console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>createCoinSubmit>", reqData);
  //   this.props.dispatch(userActions.createTransaction(reqData, req));
  //   // }

  // }
  render() {

    let { users } = this.props;
    let { loading, walletItems, historyItem, userPromoPackageItem, userPackageItem, txItem, userInfoItem, levelItem, socialInfoItem, userProfileItem, userPackageTeamItem, ticketItem, userTeamItem } = users;
    // let { total, loading, walletItems } = users;

    // console.log("RENDER___this.state.updateTxAmtData:::", userTeamItem);
    // console.log("RENDER___this.state.updateTxAmtData:::", this.state.updateTxAmtData);
    // console.log("RENDER___this.state.wbalanceData:::", this.state.wbalanceData);
    // console.log("RENDER___walletItems:::", walletItems);
    // console.log("RENDER___historyItem:::", historyItem);
    // console.log("RENDER___userPromoPackageItem:::", userPromoPackageItem);
    console.log("RENDER__________________________--userProfileItem:::", userProfileItem);
    // console.log("RENDER___txItem:::", txItem);
    // console.log("RENDER___userInfoItem:::", userInfoItem);
    // console.log("RENDER___socialInfoItem:::", socialInfoItem);
    // console.log("RENDER___userProfileItem:::", userProfileItem);
    // console.log("RENDER___userPackageTeamItem:::", userPackageTeamItem);
    // console.log("RENDER___ticketItem:::", ticketItem);
    // console.log("RENDER____this,state::", this.state.rowDataDetails);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}



                {

                  userProfileItem && userProfileItem.levelIncomeData > 0 ?

                    <div className="{otpSent?'disableArea':''}">
                      <div className="mt-1  shadow-sm relative">
                        <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                          id="amount" name="amount" placeholder="Enter Portfolio" value={this.state.levelIncomeInputData ? this.state.levelIncomeInputData : userProfileItem.levelIncomeData} type="text" onChange={this.inputChangeLevelIncome} />

                        {!this.state.levelIncomeInputData ? < button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded' onClick={() => this.inputChangeLevelIncomeSet(userProfileItem.levelIncomeData)}>Edit</button> : < button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded' onClick={() => this.submitUpdateDataLevelIncome(userProfileItem.levelIncomeData)}>Submit Updated Data</button>}
                      </div>
                    </div> : <div className="{otpSent?'disableArea':''}">
                      <div className="mt-1  shadow-sm relative">
                        <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
                          id="amount" name="amount" placeholder="Enter Portfolio" value={this.state.levelIncomeInputData} type="text" onChange={this.inputChangeLevelIncome} />
                      </div>
                      < button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded' onClick={() => this.submitUpdateDataLevelIncome(userProfileItem.levelIncomeData)}>Submit Updated Data</button>
                    </div>
                }
                <div className="p-3 2xl:p-10 sm:p-5">
                  <br />
                  {userProfileItem && userProfileItem.user && userProfileItem.user.isAllComLvl == true ?
                    <>
                      <h1 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">Level 21 Completed
                      </h1>
                      <br />
                      <div className="justify-center text-center text-dark-400"><button className="bg-red-500 hover:bg-red-700 px-7 py-1 pt-1 ml-2 text-white font-bold text-center border border-red-700 rounded " onClick={() => this.changeLevelStatus(userProfileItem.user)}> Disable </button></div>
                    </>
                    :
                    <>
                      <h1 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">Level 21 Not Completed
                      </h1>
                      <div className="justify-center text-center text-dark-400"><button className="bg-blue-500 hover:bg-blue-700 px-7 py-1 pt-1 ml-2 text-white font-bold text-center border border-blue-700 rounded " onClick={() => this.changeLevelStatus(userProfileItem.user)}> Enable </button></div>
                    </>
                  }



                  <br />
                </div>
                <div className="p-3 2xl:p-10 sm:p-5">
                  <br />
                  <h1 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">Fastrack Bonus</h1>
                  <br />
                  <div className="justify-center text-center text-dark-400"><button className="bg-blue-500 hover:bg-blue-700 px-7 py-1 pt-1 ml-2 text-white font-bold text-center border border-blue-700 rounded " onClick={() => this.handleOpenCreateFastrackModal()}> Pay </button></div>

                  <br />
                </div>
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">user Info</h3>
                    </div>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">userName</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">email</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">refCode</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">refByCode</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">refCount</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">refReward</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">teamCount</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Date</th>



                              </tr>
                            </thead>

                            <tbody>
                              {/* {JSON.stringify(walletItems)} */}

                              {


                                userProfileItem ?
                                  <>



                                    <tr className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem && userProfileItem.user && userProfileItem.user.userName ? userProfileItem.user.userName : "NA"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.user && userProfileItem.user.email ? userProfileItem.user.email : "NA"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.user && userProfileItem.user.refCode ? userProfileItem.user.refCode : 0}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.user && userProfileItem.user.refByCode ? userProfileItem.user.refByCode : 0}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.user && userProfileItem.user.refCount ? userProfileItem.user.refCount : 0}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.user && userProfileItem.user.refReward ? userProfileItem.user.refReward : 0}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.user && userProfileItem.user.teamCount ? userProfileItem.user.teamCount : 0}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {moment(new Date(parseInt(userProfileItem && userProfileItem.user ? userProfileItem.user.createdAt : "-"))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                        {/* {/* {userProfileItem && userProfileItem.user && userProfileItem.user.createdAt ? userProfileItem.user.createdAt : "NA"} */}
                                      </td>

                                    </tr>
                                  </>

                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                  </div>
                </section>


                {/* <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">Level Income Info</h3>
                    </div>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">userName</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">email</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Level Income</th>

                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Date</th>
                              </tr>
                            </thead>

                            <tbody>

                              {


                                userProfileItem && userProfileItem.data ?
                                  <>



                                    <tr className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.data && userProfileItem.data.userName ? userProfileItem.data.userName : "NA"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.data && userProfileItem.data.email ? userProfileItem.data.email : "NA"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.data && userProfileItem.data.refCode ? userProfileItem.data.refCode : 0}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.data && userProfileItem.data.refByCode ? userProfileItem.data.refByCode : 0}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.data && userProfileItem.data.refCount ? userProfileItem.data.refCount : 0}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.data && userProfileItem.data.refReward ? userProfileItem.data.refReward : 0}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {userProfileItem && userProfileItem.data && userProfileItem.data.teamCount ? userProfileItem.data.teamCount : 0}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        
                                        {userProfileItem && userProfileItem.data && userProfileItem.data.createdAt ? userProfileItem.data.createdAt : "NA"}</td>

                                    </tr>

                                  </>

                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                  </div>
                </section> */}


                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">Wallet Details</h3>
                    </div>





                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">ticker</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">balance</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">price</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">imageLinkUrl</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Address</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Payout</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Team Mining Profit</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Actions</th>

                              </tr>
                            </thead>

                            <tbody>
                              {/* {JSON.stringify(walletItems)} */}

                              {


                                walletItems && walletItems.length > 0 ?
                                  walletItems.map((element, index) => (


                                    <React.Fragment key={index}>
                                      {index < 5 ?
                                        <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                          <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                            {this.state.offset + index + 1}</td>
                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {element && element.coinId && element.coinId.ticker ? element.coinId.ticker : "NA"}</td>
                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {element && element.balance ? element.balance : 0}</td>
                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {element && element.coinId && element.coinId.price ? element.coinId.price : 0}</td>
                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {element && element.coinId && element.coinId.image ? element.coinId.image : null}</td>
                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {element && element.address ? element.address : "-"}</td>
                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {element && element.wBalance ? element.wBalance : 0}</td>
                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {element && element.teamMiningP ? element.teamMiningP : 0}</td>

                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {
                                              element && element.coinId && element.coinId.ticker === "DFTC" ?
                                                <>
                                                  <span className="inline-block pl-1">
                                                    <button className="bg-green-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" type="button"
                                                      onClick={() => this.removeAddress(element)}
                                                    >Remove address</button>
                                                  </span>

                                                </> : null
                                            }
                                          </td>
                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {
                                              element && element.coinId && element.coinId.ticker === "DOLLER" ?
                                                <>
                                                  <span className="inline-block pl-1">
                                                    <button className="bg-green-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" type="button" onClick={() => this.updateWbalanceModal(element)}  >Update Payout</button>
                                                  </span>
                                                  <span className="inline-block pl-1">
                                                    <button className="bg-green-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" type="button" onClick={() => this.handleOpenTeamMiningModal(element)}  >Update Team Mining</button>
                                                  </span>
                                                </> : null
                                            }
                                          </td>


                                          {/* <td className="px-2 py-3 whitespace-nowrap text-gray-600 inline-block">


                                        {element.wallet === null ?
                                          <>
                                            <span className="inline-block pl-1">
                                              <button className="bg-yellow-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" type="button" onClick={() => this.createWalletSubmit(element)}>createWallet</button>
                                            </span>
                                          </> :
                                          <span className="inline-block pl-1">
                                            <button disabled className="bg-green-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" type="button" >Wallet Created</button>
                                          </span>
                                        }

                                        <span className="inline-block pl-1">
                                          <button onClick={() => this.handleOpenDepositModal(element)} className="bg-green-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" type="button" >Deposit Balance</button>
                                        </span>

                                        <span className="inline-block pl-1">
                                          <button onClick={() => this.handleOpenWithdrawModal(element)} className="bg-green-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" type="button" >Withdraw Balance</button>
                                        </span>


                                      </td> */}



                                        </tr>
                                        : null}
                                    </React.Fragment>))

                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)

                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                  </div>
                </section>
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">Login History Details</h3>
                    </div>





                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">user id</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">login time</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">ip Address</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">description</th>



                              </tr>
                            </thead>

                            <tbody>
                              {/* {JSON.stringify(walletItems)} */}

                              {


                                historyItem && historyItem.length > 0 ?
                                  historyItem.map((element, index) => (<React.Fragment key={index}>
                                    {index < 5 ?
                                      <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                        <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                          {this.state.offset + index + 1}</td>

                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.userId ? element.userId : "NA"}
                                        </td>
                                        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                          {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.ipAddress ? element.ipAddress : "NA"}</td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.desc ? element.desc : 0}</td>




                                      </tr>
                                      : null}
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                  </div>
                </section>
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400" >User Promo Package Details</h3>
                    </div>





                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Date</th>
                                {/* <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Achieve</th> */}
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Amount</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {/* {JSON.stringify(walletItems)} */}

                              {


                                userPromoPackageItem && userPromoPackageItem.length > 0 ?
                                  userPromoPackageItem.map((element, index) => (<React.Fragment key={index}>
                                    {index < 5 ?
                                      <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                        <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                          {this.state.offset + index + 1}</td>
                                        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                          {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                        </td>

                                        {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.isAchieve ? element.isAchieve : "-"}
                                        </td> */}
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.amount ? element.amount : 0}</td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          <span className="inline-block pl-1">
                                            {element.status === 0 ? <button className="bg-red-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-red-400" onClick={() => this.approvePromoPack(element)}>Approve Promo Pack</button> : null}

                                            {element.status === 1 ? "Approved" : null}
                                            {element.status === 2 ? "Expired" : null}
                                          </span>
                                        </td>
                                      </tr>
                                      : null}
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                  </div>
                </section>
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">User Package Details</h3>
                    </div>





                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">date</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">package Id</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">price</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Amount</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">total Profit</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Action</th>


                              </tr>
                            </thead>

                            <tbody>
                              {/* {JSON.stringify(walletItems)} */}

                              {


                                userPackageItem && userPackageItem.length > 0 ?
                                  userPackageItem.map((element, index) => (<React.Fragment key={index}>

                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD ")}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.packageId ? element.packageId : "NA"}
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.price ? element.price : 0}</td>
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.isSettled ? element.isSettled : "NA"}</td> */}

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.amount ? element.amount.toFixed(2) : 0}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.totalProfit ? element.totalProfit.toFixed(2) : 0}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <button onClick={() => this.deleteUserPackage(element)} className="items-center bg-red-800 p-2 px-4	rounded-md text-white">Delete</button></td>

                                    </tr>

                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                  </div>
                </section>
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">Transaction Details</h3>
                    </div>
                    <button className="bg-blue-500 hover:bg-blue-700 px-7 py-1 pt-1 ml-2 text-white font-bold  border border-blue-700 rounded flex justify-end" onClick={() => this.handleOpenCreateTransactionModal()}> ADD </button>
                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">date</th>
                                {/* <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">ticker</th> */}
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Price</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Transaction Type</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Amount</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Action</th>



                              </tr>
                            </thead>

                            <tbody>
                              {/* {JSON.stringify(walletItems)} */}

                              {


                                txItem && txItem.length > 0 ?
                                  txItem.map((element, index) => (<React.Fragment key={index}>
                                    {index < 5 ?
                                      <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                        <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                          {this.state.offset + index + 1}</td>
                                        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                          {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                        </td>
                                        {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.coinId && element.coinId.ticker ? element.coinId.ticker : "NA"}
                                        </td> */}
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.coinId && element.coinId.price ? element.coinId.price : 0}</td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.txType ? element.txType : "N/A"}</td>

                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.amount ? element.amount : 0}</td>

                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">

                                          <span className="inline-block pl-1">
                                            <button className="bg-green-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" type="button" onClick={() => this.updateTxAmtSubmitModal(element)}  >Update Tx Amt</button>
                                          </span>

                                        </td>

                                      </tr>
                                      : null}
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                  </div>
                </section>

                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">User Social Info </h3>
                    </div>





                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">date</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">facebook id</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">instagram id</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">twitter id</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">linkedin id</th>



                              </tr>
                            </thead>

                            <tbody>
                              {/* {JSON.stringify(walletItems)} */}

                              {


                                socialInfoItem && socialInfoItem.length > 0 ?
                                  socialInfoItem.map((element, index) => (<React.Fragment key={index}>
                                    {index < 5 ?
                                      <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                        <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                          {this.state.offset + index + 1}</td>
                                        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                          {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.facebook ? element.facebook : "NA"}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.instagram ? element.instagram : "NA"}</td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.twitter ? element.twitter : "N/A"}</td>

                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.linkedin ? element.linkedin : "N/A"}</td>
                                      </tr>
                                      : null}
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                  </div>
                </section>
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">User Package Team</h3>
                    </div>





                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Name</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Date</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">From</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">To</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Reward</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Price</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Total Profit</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Amount</th>



                              </tr>
                            </thead>

                            <tbody>
                              {/* {JSON.stringify(walletItems)} */}

                              {


                                userPackageTeamItem && userPackageTeamItem.length > 0 ?
                                  userPackageTeamItem.map((element, index) => (<React.Fragment key={index}>
                                    {index < 5 ?
                                      <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                        <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                          {this.state.offset + index + 1}</td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.packageId && element.packageId.name ? element.packageId.name : "NA"}
                                        </td>
                                        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                          {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.packageId && element.packageId.from ? element.packageId.from : "NA"}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.packageId && element.packageId.to ? element.packageId.to : "NA"}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.packageId && element.packageId.reward ? element.packageId.reward : "NA"}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.packageId && element.packageId.price ? element.packageId.price : "NA"}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.price ? element.price : 0}</td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.totalProfit ? element.totalProfit : "N/A"}</td>

                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.amount ? element.amount : 0}</td>
                                      </tr>
                                      : null}
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                  </div>
                </section>
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">Ticket Details </h3>
                    </div>





                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Title</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Date</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Mob no.</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">comment</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Message</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">reply</th>
                              </tr>
                            </thead>

                            <tbody>
                              {/* {JSON.stringify(walletItems)} */}

                              {


                                ticketItem && ticketItem.length > 0 ?
                                  ticketItem.map((element, index) => (<React.Fragment key={index}>
                                    {index < 5 ?
                                      <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                        <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                          {this.state.offset + index + 1}</td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.title ? element.title : "NA"}
                                        </td>
                                        <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                          {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.mobNo ? element.mobNo : "NA"}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.comment ? element.comment : "NA"}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.msg ? element.msg : "NA"}
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                          {element && element.reply ? element.reply : "NA"}
                                        </td>

                                      </tr>
                                      : null}
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                  </div>
                </section>
              </div>
            </div>
          </main>
        </div >




        {/* <DialogExample /> */}

        < DepositModal
          depositModal={this.state.depositModal}
          fieldsUser={this.state.fieldsUser}
          errorsUser={this.state.errorsUser}
          inputChange={this.inputChange}
          depositBalanceSubmit={this.depositBalanceSubmit}
          handleCloseDepositModal={this.handleCloseDepositModal}
        // handleFile={this.handleFile}
        />

















        <TeamMiningAmountModal
          openTeamMiningModal={this.state.openTeamMiningModal}
          fieldsTeamMining={this.state.fieldsTeamMining}
          errorsTeamMining={this.state.errorsTeamMining}
          inputChangeTeamMining={this.inputChangeTeamMining}
          teamMiningSubmit={this.teamMiningSubmit}
          handleCloseTeamMiningModal={this.handleCloseTeamMiningModal}
        />



        <WbalanceModal
          openWbalanceModal={this.state.openWbalanceModal}
          fieldsWbalance={this.state.fieldsWbalance}
          errorsWbalance={this.state.errorsWbalance}
          inputChangeWbalance={this.inputChangeWbalance}
          wbalanceSubmit={this.wbalanceSubmit}
          handleCloseWbalanceModal={this.handleCloseWbalanceModal}
        />

        <UpdateTxAmountModal
          updateTxAmtModal={this.state.updateTxAmtModal}
          fieldsUpdateTxAmt={this.state.fieldsUpdateTxAmt}
          errorsUpdateTxAmt={this.state.errorsUpdateTxAmt}
          inputChangeUpdateTxAmt={this.inputChangeUpdateTxAmt}
          updateTxAmtSubmit={this.updateTxAmtSubmit}
          handleCloseUpdateTxAmtModal={this.handleCloseUpdateTxAmtModal}
        />
        <TransactionCreateModal
          transactionCreateModal={this.state.transactionCreateModal}
          userTeamItem={userTeamItem}
          userPackageItem={userPackageItem}
          fieldsTransaction={this.state.fieldsTransaction}
          errorsTransaction={this.state.errorsTransaction}
          inputTransactionChange={this.inputTransactionChange}
          inputTypeChangePackage={this.inputTypeChangePackage}
          createTransactionSubmit={this.createTransactionSubmit}
          handleTransactionHideModal={this.handleTransactionHideModal}
        />
        <FastrackCreateModal
          fastrackCreateModal={this.state.fastrackCreateModal}
          // userTeamItem={userTeamItem}
          // userPackageItem={userPackageItem}
          fieldsFastrack={this.state.fieldsFastrack}
          errorsFastrack={this.state.errorsFastrack}
          inputFastrackChange={this.inputFastrackChange}
          // inputTypeChangePackage={this.inputTypeChangePackage}
          createFastrackSubmit={this.createFastrackSubmit}
          handleFastrackHideModal={this.handleFastrackHideModal}
        />
        <WithdrawModal
          withdrawModal={this.state.withdrawModal}
          fieldsUser={this.state.fieldsUser}
          errorsUser={this.state.errorsUser}
          inputChange={this.inputChange}
          withdrawBalanceSubmit={this.withdrawBalanceSubmit}
          handleCloseWithdrawModal={this.handleCloseWithdrawModal}
        // handleFile={this.handleFile}
        />

        {/* <UpdateCoinModal
          UpdateCoinModal={this.state.UpdateCoinModal}
          fieldsUpdateCoin={this.state.fieldsUpdateCoin}
          errorsUpdateCoin={this.state.errorsUpdateCoin}
          inputChangeUpdateCoin={this.inputChangeUpdateCoin}
          updateCoinSubmit={this.updateCoinSubmit}
          handleCoinUpdateHideModal={this.handleCoinUpdateHideModal}
          handleFile={this.handleFile}
        /> */}

        {/* <ViewCoinModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={this.state.viewRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}

        /> */}

      </>

    );
  }
}
function mapStateToProps(state) {
  const { coin, users } = state;
  return {
    coin,
    users
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(MoreDetails);
