import {
  transactionConstants
} from '../_constants';

export function transaction(state = {}, action) {

  switch (action.type) {

    case transactionConstants.REVERT_TO_PAYOUT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case transactionConstants.REVERT_TO_PAYOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        // items: action.transaction.revertToMainBalance.list,
        // total: action.transaction.revertToMainBalance.total
      };
    case transactionConstants.REVERT_TO_PAYOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case transactionConstants.UPDATE_CASH_FLOW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case transactionConstants.UPDATE_CASH_FLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: true,
        // items: action.transaction.revertToMainBalance.list,
        // total: action.transaction.revertToMainBalance.total
      };
    case transactionConstants.UPDATE_CASH_FLOW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case transactionConstants.REVERT_TO_MAIN_BALANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case transactionConstants.REVERT_TO_MAIN_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        // items: action.transaction.revertToMainBalance.list,
        // total: action.transaction.revertToMainBalance.total
      };
    case transactionConstants.REVERT_TO_MAIN_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case transactionConstants.GET_TX_ALL_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case transactionConstants.GET_TX_ALL_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        items: action.transaction.getTxAllAdmin.list,
        total: action.transaction.getTxAllAdmin.total
      };
    case transactionConstants.GET_TX_ALL_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case transactionConstants.GET_USER_TXLIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case transactionConstants.GET_USER_TXLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.transaction.getUserTxList.list,
        total: action.transaction.getUserTxList.total
      };
    case transactionConstants.GET_USER_TXLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case transactionConstants.TXLIST_WIDTHRAW_SUCESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case transactionConstants.TXLIST_WIDTHRAW_SUCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawitems: action.transaction.getUserTxListWithdraw.list,
        withdrawtotal: action.transaction.getUserTxListWithdraw.total
      };
    case transactionConstants.TXLIST_WIDTHRAW_SUCESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case transactionConstants.ALL_TXLIST_WIDTHRAW_SUCESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case transactionConstants.ALL_TXLIST_WIDTHRAW_SUCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawAllItems: action.transaction.getTxWithdrawSuccessAll,
      };
    case transactionConstants.ALL_TXLIST_WIDTHRAW_SUCESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case transactionConstants.TXLIST_WIDTHRAW_PENDING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case transactionConstants.TXLIST_WIDTHRAW_PENDING_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawitems: action.transaction.getTxWithdrawPending.list,
        withdrawtotal: action.transaction.getTxWithdrawPending.total
      };
    case transactionConstants.TXLIST_WIDTHRAW_PENDING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case transactionConstants.TXLIST_WIDTHRAW_PENDING_ALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case transactionConstants.TXLIST_WIDTHRAW_PENDING_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawitemsAll: action.transaction.getTxWithdrawPending
      };
    case transactionConstants.TXLIST_WIDTHRAW_PENDING_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case transactionConstants.TXLIST_MAAEXCH_WIDTHRAW_SUCESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case transactionConstants.TXLIST_MAAEXCH_WIDTHRAW_SUCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        maaExchwithdrawitems: action.transaction.getTxMaaExchWithdrawSucess.list,
        maaExchwithdrawtotal: action.transaction.getTxMaaExchWithdrawSucess.total
      };
    case transactionConstants.TXLIST_MAAEXCH_WIDTHRAW_SUCESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case transactionConstants.SENDCOIN_APPROVED_DFTC_REQUEST:
      return {
        ...state
      };
    case transactionConstants.SENDCOIN_APPROVED_DFTC_SUCCESS:
      return {
        ...state,
        loading: false,
        addKycSuccess: true
      };
    case transactionConstants.SENDCOIN_APPROVED_DFTC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case transactionConstants.APPROVED_VIA_API_REQUEST:
      return {
        ...state
      };
    case transactionConstants.APPROVED_VIA_API_SUCCESS:
      return {
        ...state,
        loading: false,
        addKycSuccess: true
      };
    case transactionConstants.APPROVED_VIA_API_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}