export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',


    GET_CLASS_ID_BY_TUITION_STUDENT_REQUEST: 'GET_CLASS_ID_BY_TUITION_STUDENT_REQUEST',
    GET_CLASS_ID_BY_TUITION_STUDENT_SUCCESS: 'GET_CLASS_ID_BY_TUITION_STUDENT_SUCCESS',
    GET_CLASS_ID_BY_TUITION_STUDENT_FAILURE: 'GET_CLASS_ID_BY_TUITION_STUDENT_FAILURE',

    GET_TUITION_LIST_BY_TUTOR_REQUEST: 'GET_TUITION_LIST_BY_TUTOR_REQUEST',
    GET_TUITION_LIST_BY_TUTOR_SUCCESS: 'GET_TUITION_LIST_BY_TUTOR_SUCCESS',
    GET_TUITION_LIST_BY_TUTOR_FAILURE: 'GET_TUITION_LIST_BY_TUTOR_FAILURE',

    UPDATE_UNPAID_STATUS_REQUEST: 'UPDATE_UNPAID_STATUS_REQUEST',
    UPDATE_UNPAID_STATUS_SUCCESS: 'UPDATE_UNPAID_STATUS_SUCCESS',
    UPDATE_UNPAID_STATUS_FAILURE: 'UPDATE_UNPAID_STATUS_FAILURE',

    GETALL_USER_ACTIVE_STUDENT_REQUEST: 'GETALL_USER_ACTIVE_STUDENT_REQUEST',
    GETALL_USER_ACTIVE_STUDENT_SUCCESS: 'GETALL_USER_ACTIVE_STUDENT_SUCCESS',
    GETALL_USER_ACTIVE_STUDENT_FAILURE: 'GETALL_USER_ACTIVE_STUDENT_FAILURE',

    GETALL_USER_ACTIVE_REJECTED_INTERVIEW_REQUEST: 'GETALL_USER_ACTIVE_REJECTED_INTERVIEW_REQUEST',
    GETALL_USER_ACTIVE_REJECTED_INTERVIEW_SUCCESS: 'GETALL_USER_ACTIVE_REJECTED_INTERVIEW_SUCCESS',
    GETALL_USER_ACTIVE_REJECTED_INTERVIEW_FAILURE: 'GETALL_USER_ACTIVE_REJECTED_INTERVIEW_FAILURE',

    UPDATE_STUDENT_DETAILS_REQUEST: 'UPDATE_STUDENT_DETAILS_REQUEST',
    UPDATE_STUDENT_DETAILS_SUCCESS: 'UPDATE_STUDENT_DETAILS_SUCCESS',
    UPDATE_STUDENT_DETAILS_FAILURE: 'UPDATE_STUDENT_DETAILS_FAILURE',

    GET_FEE_HISTORY_LIST_ADMIN_REQUEST: 'GET_FEE_HISTORY_LIST_ADMIN_REQUEST',
    GET_FEE_HISTORY_LIST_ADMIN_SUCCESS: 'GET_FEE_HISTORY_LIST_ADMIN_SUCCESS',
    GET_FEE_HISTORY_LIST_ADMIN_FAILURE: 'GET_FEE_HISTORY_LIST_ADMIN_FAILURE',

    MARK_PAID_OR_UNPAID_REQUEST: 'MARK_PAID_OR_UNPAID_REQUEST',
    MARK_PAID_OR_UNPAID_SUCCESS: 'MARK_PAID_OR_UNPAID_SUCCESS',
    MARK_PAID_OR_UNPAID_FAILURE: 'MARK_PAID_OR_UNPAID_FAILURE',

    GET_TUITION_LIST_BY_DATE_REQUEST: 'GET_TUITION_LIST_BY_DATE_REQUEST',
    GET_TUITION_LIST_BY_DATE_SUCCESS: 'GET_TUITION_LIST_BY_DATE_SUCCESS',
    GET_TUITION_LIST_BY_DATE_FAILURE: 'GET_TUITION_LIST_BY_DATE_FAILURE',

    GET_USER_KYC_DETAILS_BY_ID_REQUEST: 'GET_USER_KYC_DETAILS_BY_ID_REQUEST',
    GET_USER_KYC_DETAILS_BY_ID_SUCCESS: 'GET_USER_KYC_DETAILS_BY_ID_SUCCESS',
    GET_USER_KYC_DETAILS_BY_ID_FAILURE: 'GET_USER_KYC_DETAILS_BY_ID_FAILURE',

    GET_ATTENDANCE_LIST_REQUEST: 'GET_ATTENDANCE_LIST_REQUEST',
    GET_ATTENDANCE_LIST_SUCCESS: 'GET_ATTENDANCE_LIST_SUCCESS',
    GET_ATTENDANCE_LIST_FAILURE: 'GET_ATTENDANCE_LIST_FAILURE',

    CREATE_SUBADMIN_REQUEST: 'CREATE_SUBADMIN_REQUEST',
    CREATE_SUBADMIN_SUCCESS: 'CREATE_SUBADMIN_SUCCESS',
    CREATE_SUBADMIN_FAILURE: 'CREATE_SUBADMIN_FAILURE',

    GET_ADMIN_LIST_REQUEST: 'GET_ADMIN_LIST_REQUEST',
    GET_ADMIN_LIST_SUCCESS: 'GET_ADMIN_LIST_SUCCESS',
    GET_ADMIN_LIST_FAILURE: 'GET_ADMIN_LIST_FAILURE',

    GET_USER_PAYMENT_DETAILS_REQUEST: 'GET_USER_PAYMENT_DETAILS_REQUEST',
    GET_USER_PAYMENT_DETAILS_SUCCESS: 'GET_USER_PAYMENT_DETAILS_SUCCESS',
    GET_USER_PAYMENT_DETAILS_FAILURE: 'GET_USER_PAYMENT_DETAILS_FAILURE',

    GET_LEAD_LIST_REQUEST: 'GET_LEAD_LIST_REQUEST',
    GET_LEAD_LIST_SUCCESS: 'GET_LEAD_LIST_SUCCESS',
    GET_LEAD_LIST_FAILURE: 'GET_LEAD_LIST_FAILURE',

    UPDATE_SUB_ADMIN_PASSWORD_REQUEST: 'UPDATE_SUB_ADMIN_PASSWORD_REQUEST',
    UPDATE_SUB_ADMIN_PASSWORD_SUCCESS: 'UPDATE_SUB_ADMIN_PASSWORD_SUCCESS',
    UPDATE_SUB_ADMIN_PASSWORD_FAILURE: 'UPDATE_SUB_ADMIN_PASSWORD_FAILURE',

    ADD_AMOUNT_REFERRAL_USER_REQUEST: 'ADD_AMOUNT_REFERRAL_USER_REQUEST',
    ADD_AMOUNT_REFERRAL_USER_SUCCESS: 'ADD_AMOUNT_REFERRAL_USER_SUCCESS',
    ADD_AMOUNT_REFERRAL_USER_FAILURE: 'ADD_AMOUNT_REFERRAL_USER_FAILURE',

    GET_ADMIN_PROFILE_REQUEST: 'GET_ADMIN_PROFILE_REQUEST',
    GET_ADMIN_PROFILE_SUCCESS: 'GET_ADMIN_PROFILE_SUCCESS',
    GET_ADMIN_PROFILE_FAILURE: 'GET_ADMIN_PROFILE_FAILURE',

    UPDATE_TEAM_MINING_AMOUNT_REQUEST: 'UPDATE_TEAM_MINING_AMOUNT_REQUEST',
    UPDATE_TEAM_MINING_AMOUNT_SUCCESS: 'UPDATE_TEAM_MINING_AMOUNT_SUCCESS',
    UPDATE_TEAM_MINING_AMOUNT_FAILURE: 'UPDATE_TEAM_MINING_AMOUNT_FAILURE',

    GET_USER_PACKAGE_LIST_REQUEST: 'GET_USER_PACKAGE_LIST_REQUEST',
    GET_USER_PACKAGE_LIST_SUCCESS: 'GET_USER_PACKAGE_LIST_SUCCESS',
    GET_USER_PACKAGE_LIST_FAILURE: 'GET_USER_PACKAGE_LIST_FAILURE',

    GET_ASSIGNMENT_REQUEST: 'GET_ASSIGNMENT_REQUEST',
    GET_ASSIGNMENT_SUCCESS: 'GET_ASSIGNMENT_SUCCESS',
    GET_ASSIGNMENT_FAILURE: 'GET_ASSIGNMENT_FAILURE',

    GET_USER_PROMO_PACKAGE_LIST_REQUEST: 'GET_USER_PROMO_PACKAGE_LIST_REQUEST',
    GET_USER_PROMO_PACKAGE_LIST_SUCCESS: 'GET_USER_PROMO_PACKAGE_LIST_SUCCESS',
    GET_USER_PROMO_PACKAGE_LIST_FAILURE: 'GET_USER_PROMO_PACKAGE_LIST_FAILURE',

    UPDATE_TX_AMOUNT_REQUEST: 'UPDATE_TX_AMOUNT_REQUEST',
    UPDATE_TX_AMOUNT_SUCCESS: 'UPDATE_TX_AMOUNT_SUCCESS',
    UPDATE_TX_AMOUNT_FAILURE: 'UPDATE_TX_AMOUNT_FAILURE',

    UPDATE_PAYOUT_AMOUNT_REQUEST: 'UPDATE_PAYOUT_AMOUNT_REQUEST',
    UPDATE_PAYOUT_AMOUNT_SUCCESS: 'UPDATE_PAYOUT_AMOUNT_SUCCESS',
    UPDATE_PAYOUT_AMOUNT_FAILURE: 'UPDATE_PAYOUT_AMOUNT_FAILURE',

    UPDATE_EMAIL_REQUEST: 'USERS_UPDATE_EMAIL_REQUEST',
    UPDATE_EMAIL_SUCCESS: 'USERS_UPDATE_EMAIL_SUCCESS',
    UPDATE_EMAIL_FAILURE: 'USERS_UPDATE_EMAIL_FAILURE',

    UPDATE_USERNAME_REQUEST: 'USERS_UPDATE_USERNAME_REQUEST',
    UPDATE_USERNAME_SUCCESS: 'USERS_UPDATE_USERNAME_SUCCESS',
    UPDATE_USERNAME_FAILURE: 'USERS_UPDATE_USERNAME_FAILURE',

    CUSTOM_LOGIN_REQUEST: 'USERS_CUSTOM_LOGIN_REQUEST',
    CUSTOM_LOGIN_SUCCESS: 'USERS_CUSTOM_LOGIN_SUCCESS',
    CUSTOM_LOGIN_FAILURE: 'USERS_CUSTOM_LOGIN_FAILURE',

    APPROVE_PROMO_PACK_REQUEST: 'APPROVE_PROMO_PACK_REQUEST',
    APPROVE_PROMO_PACK_SUCCESS: 'APPROVE_PROMO_PACK_SUCCESS',
    APPROVE_PROMO_PACK_FAILURE: 'APPROVE_PROMO_PACK_FAILURE',

    ADD_USER_BALANCE_REQUEST: 'ADD_USER_BALANCE_REQUEST',
    ADD_USER_BALANCE_SUCCESS: 'ADD_USER_BALANCE_SUCCESS',
    ADD_USER_BALANCE_FAILURE: 'ADD_USER_BALANCE_FAILURE',

    CREATE_WALLET_REQUEST: 'CREATE_WALLET_REQUEST',
    CREATE_WALLET_SUCCESS: 'CREATE_WALLET_SUCCESS',
    CREATE_WALLET_FAILURE: 'CREATE_WALLET_FAILURE',

    REMOVE_DFTC_ADDRESS_REQUEST: 'REMOVE_DFTC_ADDRESS_REQUEST',
    REMOVE_DFTC_ADDRESS_SUCCESS: 'REMOVE_DFTC_ADDRESS_SUCCESS',
    REMOVE_DFTC_ADDRESS_FAILURE: 'REMOVE_DFTC_ADDRESS_FAILURE',

    CREATE_TRANSACTION_REQUEST: 'CREATE_TRANSACTION_REQUEST',
    CREATE_TRANSACTION_SUCCESS: 'CREATE_TRANSACTION_SUCCESS',
    CREATE_TRANSACTION_FAILURE: 'CREATE_TRANSACTION_FAILURE',

    CREATE_ASSIGN_CLASSES_REQUEST: 'CREATE_ASSIGN_CLASSES_REQUEST',
    CREATE_ASSIGN_CLASSES_SUCCESS: 'CREATE_ASSIGN_CLASSES_SUCCESS',
    CREATE_ASSIGN_CLASSES_FAILURE: 'CREATE_ASSIGN_CLASSES_FAILURE',

    GET_ATTENDENCE_TUTION_LIST_REQUEST: 'GET_ATTENDENCE_TUTION_LIST_REQUEST',
    GET_ATTENDENCE_TUTION_LIST_SUCCESS: 'GET_ATTENDENCE_TUTION_LIST_SUCCESS',
    GET_ATTENDENCE_TUTION_LIST_FAILURE: 'GET_ATTENDENCE_TUTION_LIST_FAILURE',

    UPDATE_ASSIGN_CLASSES_REQUEST: 'UPDATE_ASSIGN_CLASSES_REQUEST',
    UPDATE_ASSIGN_CLASSES_SUCCESS: 'UPDATE_ASSIGN_CLASSES_SUCCESS',
    UPDATE_ASSIGN_CLASSES_FAILURE: 'UPDATE_ASSIGN_CLASSES_FAILURE',

    DELETE_ASSIGN_CLASSES_REQUEST: 'DELETE_ASSIGN_CLASSES_REQUEST',
    DELETE_ASSIGN_CLASSES_SUCCESS: 'DELETE_ASSIGN_CLASSES_SUCCESS',
    DELETE_ASSIGN_CLASSES_FAILURE: 'DELETE_ASSIGN_CLASSES_FAILURE',

    PAY_FASTRACK_OR_LIFE_REQUEST: 'PAY_FASTRACK_OR_LIFE_REQUEST',
    PAY_FASTRACK_OR_LIFE_SUCCESS: 'PAY_FASTRACK_OR_LIFE_SUCCESS',
    PAY_FASTRACK_OR_LIFE_FAILURE: 'PAY_FASTRACK_OR_LIFE_FAILURE',

    DELETE_USER_PACKAGE_REQUEST: 'DELETE_USER_PACKAGE_REQUEST',
    DELETE_USER_PACKAGE_SUCCESS: 'DELETE_USER_PACKAGE_SUCCESS',
    DELETE_USER_PACKAGE_FAILURE: 'DELETE_USER_PACKAGE_FAILURE',

    UPDATE_STATUS_PAYMENT_REQUEST: 'UPDATE_STATUS_PAYMENT_REQUEST',
    UPDATE_STATUS_PAYMENT_SUCCESS: 'UPDATE_STATUS_PAYMENT_SUCCESS',
    UPDATE_STATUS_PAYMENT_FAILURE: 'UPDATE_STATUS_PAYMENT_FAILURE',

    GET_WALLET_LIST_REQUEST: 'GET_WALLET_LIST_REQUEST',
    GET_WALLET_LIST_SUCCESS: 'GET_WALLET_LIST_SUCCESS',
    GET_WALLET_LIST_FAILURE: 'GET_WALLET_LIST_FAILURE',

    GET_LOGIN_HISTORY_USER_REQUEST: 'GET_LOGIN_HISTORY_USER_REQUEST',
    GET_LOGIN_HISTORY_USER_SUCCESS: 'GET_LOGIN_HISTORY_USER_SUCCESS',
    GET_LOGIN_HISTORY_USER_FAILURE: 'GET_LOGIN_HISTORY_USER_FAILURE',

    GET_PROMO_PACKAGE_REQUEST: 'GET_PROMO_PACKAGE_REQUEST',
    GET_PROMO_PACKAGE_SUCCESS: 'GET_PROMO_PACKAGE_SUCCESS',
    GET_PROMO_PACKAGE_FAILURE: 'GET_PROMO_PACKAGE_FAILURE',

    GET_USER_PACKAGE_REQUEST: 'GET_USER_PACKAGE_REQUEST',
    GET_USER_PACKAGE_SUCCESS: 'GET_USER_PACKAGE_SUCCESS',
    GET_USER_PACKAGE_FAILURE: 'GET_USER_PACKAGE_FAILURE',

    GET_USER_LEVEL_REQUEST: 'GET_USER_LEVEL_REQUEST',
    GET_USER_LEVEL_SUCCESS: 'GET_USER_LEVEL_SUCCESS',
    GET_USER_LEVEL_FAILURE: 'GET_USER_LEVEL_FAILURE',

    GET_TX_REQUEST: 'GET_TX_REQUEST',
    GET_TX_SUCCESS: 'GET_TX_SUCCESS',
    GET_TX_FAILURE: 'GET_TX_FAILURE',

    GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',

    GET_SOCIAL_INFO_REQUEST: 'GET_SOCIAL_INFO_REQUEST',
    GET_SOCIAL_INFO_SUCCESS: 'GET_SOCIAL_INFO_SUCCESS',
    GET_SOCIAL_INFO_FAILURE: 'GET_SOCIAL_INFO_FAILURE',

    GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',

    GET_USER_PACKAGE_TEAM_REQUEST: 'GET_USER_PACKAGE_TEAM_REQUEST',
    GET_USER_PACKAGE_TEAM_SUCCESS: 'GET_USER_PACKAGE_TEAM_SUCCESS',
    GET_USER_PACKAGE_TEAM_FAILURE: 'GET_USER_PACKAGE_TEAM_FAILURE',

    GET_USER_TEAM_BY_USER_REQUEST: 'GET_USER_TEAM_BY_USER_REQUEST',
    GET_USER_TEAM_BY_USER_SUCCESS: 'GET_USER_TEAM_BY_USER_SUCCESS',
    GET_USER_TEAM_BY_USER_FAILURE: 'GET_USER_TEAM_BY_USER_FAILURE',

    GET_TICKET_REQUEST: 'GET_TICKET_REQUEST',
    GET_TICKET_SUCCESS: 'GET_TICKET_SUCCESS',
    GET_TICKET_FAILURE: 'GET_TICKET_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    LOGIN_TO_THIS_ACCOUNT_REQUEST: 'USERS_LOGIN_TO_THIS_ACCOUNT_REQUEST',
    LOGIN_TO_THIS_ACCOUNT_SUCCESS: 'USERS_LOGIN_TO_THIS_ACCOUNT_SUCCESS',
    LOGIN_TO_THIS_ACCOUNT_FAILURE: 'USERS_LOGIN_TO_THIS_ACCOUNT_FAILURE',

    GET_PROFILE_REQUEST: 'USERS_GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'USERS_GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'USERS_GET_PROFILE_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GET_PAYMENT_LIST_REQUEST: 'GET_PAYMENT_LIST_REQUEST',
    GET_PAYMENT_LIST_SUCCESS: 'GET_PAYMENT_LIST_SUCCESS',
    GET_PAYMENT_LIST_FAILURE: 'GET_PAYMENT_LIST_FAILURE',

    GETALL_USER_ACTIVE_REQUEST: 'GETALL_USER_ACTIVE_REQUEST',
    GETALL_USER_ACTIVE_SUCCESS: 'GETALL_USER_ACTIVE_SUCCESS',
    GETALL_USER_ACTIVE_FAILURE: 'GETALL_USER_ACTIVE_FAILURE',

    GET_ASSIGNED_CLASSES_REQUEST: 'GET_ASSIGNED_CLASSES_REQUEST',
    GET_ASSIGNED_CLASSES_SUCCESS: 'GET_ASSIGNED_CLASSES_SUCCESS',
    GET_ASSIGNED_CLASSES_FAILURE: 'GET_ASSIGNED_CLASSES_FAILURE',

    GETALL_USER_DEACTIVE_REQUEST: 'GETALL_USER_DEACTIVE_REQUEST',
    GETALL_USER_DEACTIVE_SUCCESS: 'GETALL_USER_DEACTIVE_SUCCESS',
    GETALL_USER_DEACTIVE_FAILURE: 'GETALL_USER_DEACTIVE_FAILURE',

    DISABLE_ADMIN_REQUEST: 'DISABLE_ADMIN_REQUEST',
    DISABLE_ADMIN_SUCCESS: 'DISABLE_ADMIN_SUCCESS',
    DISABLE_ADMIN_FAILURE: 'DISABLE_ADMIN_FAILURE',

    DISABLE_USER_REQUEST: 'DISABLE_USER_REQUEST',
    DISABLE_USER_SUCCESS: 'DISABLE_USER_SUCCESS',
    DISABLE_USER_FAILURE: 'DISABLE_USER_FAILURE',

    DISABLE_USER_REF_REQUEST: 'DISABLE_USER_REF_REQUEST',
    DISABLE_USER_REF_SUCCESS: 'DISABLE_USER_REF_SUCCESS',
    DISABLE_USER_REF_FAILURE: 'DISABLE_USER_REF_FAILURE',

    REJISTER_USER_REF_REQUEST: 'REJISTER_USER_REF_REQUEST',
    REJISTER_USER_REF_SUCCESS: 'REJISTER_USER_REF_SUCCESS',
    REJISTER_USER_REF_FAILURE: 'REJISTER_USER_REF_FAILURE',

    USER_INTERVIEW_REF_REQUEST: 'USER_INTERVIEW_REF_REQUEST',
    USER_INTERVIEW_REF_SUCCESS: 'USER_INTERVIEW_REF_SUCCESS',
    USER_INTERVIEW_REF_FAILURE: 'USER_INTERVIEW_REF_FAILURE',

    DISABLE_ASSIGNED_CLASSES_REQUEST: 'DISABLE_ASSIGNED_CLASSES_REQUEST',
    DISABLE_ASSIGNED_CLASSES_SUCCESS: 'DISABLE_ASSIGNED_CLASSES_SUCCESS',
    DISABLE_ASSIGNED_CLASSES_FAILURE: 'DISABLE_ASSIGNED_CLASSES_FAILURE',

    DISABLE_ASSIGNMENT_REQUEST: 'DISABLE_ASSIGNMENT_REQUEST',
    DISABLE_ASSIGNMENT_SUCCESS: 'DISABLE_ASSIGNMENT_SUCCESS',
    DISABLE_ASSIGNMENT_FAILURE: 'DISABLE_ASSIGNMENT_FAILURE',

    LEVEL_STATUS_USER_REF_REQUEST: 'LEVEL_STATUS_USER_REQUEST',
    LEVEL_STATUS_USER_SUCCESS: 'LEVEL_STATUS_USER_SUCCESS',
    LEVEL_STATUS_USER_FAILURE: 'LEVEL_STATUS_USER_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    DELETE_ASSIGNMENT_REQUEST: 'DELETE_ASSIGNMENT_REQUEST',
    DELETE_ASSIGNMENT_SUCCESS: 'DELETE_ASSIGNMENT_SUCCESS',
    DELETE_ASSIGNMENT_FAILURE: 'DELETE_ASSIGNMENT_FAILURE',

    USER_VERIFY_EMAIL_REQUEST: 'USER_VERIFY_EMAIL_REQUEST',
    USER_VERIFY_EMAIL_SUCCESS: 'USER_VERIFY_EMAIL_SUCCESS',
    USER_VERIFY_EMAIL_FAILURE: 'USER_VERIFY_EMAIL_FAILURE',

    UPDATE_USER_PASSWORD_REQUEST: 'UPDATE_USER_PASSWORD_REQUEST',
    UPDATE_USER_PASSWORD_SUCCESS: 'UPDATE_USER_PASSWORD_SUCCESS',
    UPDATE_USER_PASSWORD_FAILURE: 'UPDATE_USER_PASSWORD_FAILURE',

    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',

    FILE_UPLOAD_CLEAR_SUCCESS: 'FILE_UPLOAD_CLEAR_SUCCESS',


    DEPOSIT_USER_BALANCE_REQUEST: 'DEPOSIT_USER_BALANCE_REQUEST',
    DEPOSIT_USER_BALANCE_SUCCESS: 'DEPOSIT_USER_BALANCE_SUCCESS',
    DEPOSIT_USER_BALANCE_FAILURE: 'DEPOSIT_USER_BALANCE_FAILURE',

    WITHDRAW_USER_BALANCE_REQUEST: 'WITHDRAW_USER_BALANCE_REQUEST',
    WITHDRAW_USER_BALANCE_SUCCESS: 'WITHDRAW_USER_BALANCE_SUCCESS',
    WITHDRAW_USER_BALANCE_FAILURE: 'WITHDRAW_USER_BALANCE_FAILURE',


};
