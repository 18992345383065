import {
  teachcourseConstants
} from '../_constants';

export function teachcourse(state = {}, action) {

  switch (action.type) {
    // case teachcourseConstants.DEPOSIT_USER_BALANCE_REQUEST:
    //   return {
    //     ...state
    //   };
    // case teachcourseConstants.DEPOSIT_USER_BALANCE_SUCCESS:
    //   return {
    //     ...state,
    //     addUserSuccess: true
    //   };
    // case teachcourseConstants.DEPOSIT_USER_BALANCE_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };
    case teachcourseConstants.ADD_COURSE_REQUEST:
      return {
        ...state
      };
    case teachcourseConstants.ADD_COURSE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case teachcourseConstants.ADD_COURSE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case teachcourseConstants.APPROVED_DEMO_CLASS_REQUEST:
      return {
        ...state
      };
    case teachcourseConstants.APPROVED_DEMO_CLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case teachcourseConstants.APPROVED_DEMO_CLASS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case teachcourseConstants.MAKE_DUPLICATE_DEMO_CLASS_REQUEST:
      return {
        ...state
      };
    case teachcourseConstants.MAKE_DUPLICATE_DEMO_CLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case teachcourseConstants.MAKE_DUPLICATE_DEMO_CLASS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case teachcourseConstants.UPDATE_DEMO_REQUEST_REQUEST:
      return {
        ...state
      };
    case teachcourseConstants.UPDATE_DEMO_REQUEST_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case teachcourseConstants.UPDATE_DEMO_REQUEST_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case teachcourseConstants.CHANGE_STATUS_DEMO_CLASS_REQUEST:
      return {
        ...state
      };
    case teachcourseConstants.CHANGE_STATUS_DEMO_CLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case teachcourseConstants.CHANGE_STATUS_DEMO_CLASS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case teachcourseConstants.GET_PENDING_DEMO_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case teachcourseConstants.GET_PENDING_DEMO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isStateMangerRes: true,
        items: action.users.getFilteredDemoClassList.list,
        itemsTeachCourse: action.users.getFilteredDemoClassList.list,
        itemsTeachCourseRes: action.users.getFilteredDemoClassList.list,
        total: action.users.getFilteredDemoClassList.total,
        itemsTeachTotal: action.users.getFilteredDemoClassList.total
      };
    case teachcourseConstants.GET_PENDING_DEMO_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case teachcourseConstants.GET_LIST_COURSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teachcourseConstants.GET_LIST_COURSE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getTeachCourseList: action.users.getTeachCourseList.list,
        total: action.users.getTeachCourseList.total
      };
    case teachcourseConstants.GET_LIST_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case teachcourseConstants.UPDATE_COURSE_REQUEST:
      return {
        ...state
      };
    case teachcourseConstants.UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case teachcourseConstants.UPDATE_COURSE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case teachcourseConstants.UPDATE_TUTOR_DEMO_STATUS_ADMIN_REQUEST:
      return {
        ...state
      };
    case teachcourseConstants.UPDATE_TUTOR_DEMO_STATUS_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case teachcourseConstants.UPDATE_TUTOR_DEMO_STATUS_ADMIN_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case teachcourseConstants.GET_SUGGESTED_TUTOR_REQUEST:
      return {
        ...state
      };
    case teachcourseConstants.GET_SUGGESTED_TUTOR_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        suggestesTutorData: action.users.getSuggestedTutor
      };
    case teachcourseConstants.GET_SUGGESTED_TUTOR_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case teachcourseConstants.ASSIGN_TUTOR_REQUEST:
      return {
        ...state
      };
    case teachcourseConstants.ASSIGN_TUTOR_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case teachcourseConstants.ASSIGN_TUTOR_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case teachcourseConstants.DELETE_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teachcourseConstants.DELETE_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case teachcourseConstants.DELETE_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case teachcourseConstants.DISABLE_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teachcourseConstants.DISABLE_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case teachcourseConstants.DISABLE_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}