import React, { Component } from 'react';
import { connect } from 'react-redux';
import { tutionActions, userActions, cityActions, teachcourseActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import CreateTutionModal from './components/CreateTutionModal/CreateTutionModal';
import UpdateTutionModal from './components/UpdateTutionModal/UpdateTutionModal';
import UpdateTutionModalAddAmount from './components/UpdateTutionModal/UpdateTutionModalAddAmount';
import { confirmAlert } from 'react-confirm-alert';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaExclamationCircle } from "react-icons/fa";
// import { index } from '../../_reducers/index.reducer';

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldstution: {},
      errorstution: {},
      fieldstutionUpdate: {},
      errorstutionUpdate: {},
      allDataValue: {},
      fieldsUpdateCoin: {},
      errorsUpdateCoin: {},
      tutionCreateModal: false,
      tutionUpdateModal: false,
      tutionUpdateModalAddAmount: false,
      moreDetailsHandel: false,
      ManualTutorModal: false,
      tutorIdSaveFeildValue: null,
      companyTotalShare: 0,
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      formData: {
        "name": "",
        "days": "",
        "per": "",
      },
      updateformData: {
        "id": "",
        "name": "",
        "days": "",
        "per": "",
      },
      isOpen: false
    }
  }
  componentDidMount() {
    let temp = {
      "tuitionId": this.props.match.params.id
    }
    let temp1 = {
      "id": this.props.match.params.id
    }
    this.props.dispatch(cityActions.getAttendanceMonthData(temp));
    this.props.dispatch(tutionActions.getTuitionById(temp1));

  }


  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.tution.addUserSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "key": "",
        },
        tutionCreateModal: false,
        tutionUpdateModal: false,
        ManualTutorModal: false,
        fieldstution: {},
        errorstution: {},
        fieldstutionUpdate: {},
        errorstutionUpdate: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  handelIsOpenFnction = (data) => {

    let temp1 = {
      month: data.monthTimeStamp,
      tuitionId: this.props.match.params.id,
      keyWord: "",
      pageNo: 1,
      size: 10000000,
    }
    this.props.dispatch(tutionActions.getAttendanceListByTutor(temp1));

    this.setState({ isOpen: !this.state.isOpen })
  }


  handleOpenCreateModal = () => {
    this.setState({ tutionCreateModal: true });
  }
  handletutionHideModal = () => {
    this.setState({ tutionCreateModal: false });
    this.setState({ tutionUpdateModal: false });
  }

  updatehandleOpenCreateModal = (data) => {

    this.setState({ tutionUpdateModal: true, fieldstutionUpdate: data });
  }

  onDeleteClick = (data) => {
    console.log(data);
    confirmAlert({
      title: 'Confirm to Delete?',
      message: 'Are you sure to Delete ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.deleteTution(data))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  onDisable = (data) => {
    let tempdata = {
      "id": data.id,
    }

    confirmAlert({
      title: 'Confirm to Disable?',
      message: 'Are you sure to Disable ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.disableTution(tempdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    confirmAlert({


      title: 'Confirm that the class is complete ',
      message: `Are you sure this class is complete?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.closeClassStatusUpdate(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldstution = this.state.fieldstution;
    let errorstution = this.state.errorstution;
    fieldstution[name] = value;
    errorstution[name] = "";
    console.log(name, value);
    this.setState({ fieldstution, errorstution });
  }

  inputChangeUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldstutionUpdate = this.state.fieldstutionUpdate;
    let errorstutionUpdate = this.state.errorstutionUpdate;
    fieldstutionUpdate[name] = value;
    errorstutionUpdate[name] = "";
    this.setState({ fieldstutionUpdate, errorstutionUpdate });
  }

  tutionubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationtution()) {
      let resTest = (this.state.fieldstution && this.state.fieldstution.fee ? this.state.fieldstution.fee : 0) - (this.state.fieldstution && this.state.fieldstution.refFee ? this.state.fieldstution.refFee : 0) - (this.state.fieldstution && this.state.fieldstution.tutorFee ? this.state.fieldstution.tutorFee : 0)

      let { classId, tutorId, studentId, teacherCouresId, monthInDay, startDate, fee, classReference, tutorFee, refFee, paymentMode, dueDate, board, tuitionMord, remark } = this.state.fieldstution;
      this.props.dispatch(tutionActions.createTution({ remark: remark, tuitionMord: tuitionMord, board: board, dueDate: Number(dueDate), classId: classId, tutorId: tutorId, studentId: studentId, teacherCouresId: [teacherCouresId], monthInDay: monthInDay, fee: fee, startDate: startDate, classReference: classReference, tutorFee: tutorFee, refFee: refFee, companyShare: resTest, paymentMode: paymentMode }, this.props));
    }
  }

  tutionUpdateSubmit = (e) => {
    e.preventDefault();
    {
      let resTest = (this.state.fieldstutionUpdate && this.state.fieldstutionUpdate.fee ? this.state.fieldstutionUpdate.fee : 0) - (this.state.fieldstutionUpdate && this.state.fieldstutionUpdate.refFee ? this.state.fieldstutionUpdate.refFee : 0) - (this.state.fieldstutionUpdate && this.state.fieldstutionUpdate.tutorFee ? this.state.fieldstutionUpdate.tutorFee : 0)
      let { tutorId, studentId, teacherCouresId, monthInDay, fee, classReference, id, classId, tutorFee, refFee, startDate, paymentMode } = this.state.fieldstutionUpdate;
      this.props.dispatch(tutionActions.updateTution({ tutorId: tutorId, studentId: studentId, classId: classId, teacherCouresId: teacherCouresId, monthInDay: monthInDay, fee: fee, startDate: startDate, classReference: classReference, id: id, companyShareUpdate: resTest, tutorFee: tutorFee, refFee: refFee, paymentMode: paymentMode }, this.props));
    }
  }

  handleValidationtution = () => {
    let fieldstution = this.state.fieldstution;
    let errorstution = {};
    let formIsValid = true;

    //tutorId
    if (!fieldstution["tutorId"] || fieldstution["tutorId"] === "") {
      formIsValid = false;
      errorstution["tutorId"] = "Cannot be empty";
    }

    //from
    if (!fieldstution["studentId"]) {
      formIsValid = false;
      errorstution["studentId"] = "Please enter studentId!";
    }

    //teacherCouresId
    if (!fieldstution["teacherCouresId"]) {
      formIsValid = false;
      errorstution["teacherCouresId"] = "Please enter teacherCouresId!";
    }

    //monthInDay
    if (!fieldstution["monthInDay"]) {
      formIsValid = false;
      errorstution["monthInDay"] = "Please enter monthInDay!";
    }

    //fee
    if (!fieldstution["fee"]) {
      formIsValid = false;
      errorstution["fee"] = "Please enter fee!";
    }


    this.setState({ errorstution: errorstution });
    return formIsValid;
  }
  handelClickPushMode = (data) => {
    this.props.history.push('/app/tutorFee' + "/" + data.id)
  }
  openModelHandel = (data) => {
    this.setState({ moreDetailsHandel: true, allDataValue: data })
  }
  closeModelHandel = () => {
    this.setState({ moreDetailsHandel: false, allDataValue: {} })
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getTutionList(data));
  }
  inputChangeUpdateCoin = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    if (!value) {
      console.log('!!!!!!!!!!!!!');
    }
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = this.state.errorsUpdateCoin;
    fieldsUpdateCoin[name] = value;
    errorsUpdateCoin[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateCoin, errorsUpdateCoin });
  }
  manualAssginTutor = () => {
    let reqData = {
      "id": this.state.tutorIdSaveFeildValue,
      "tutorId": this.state.fieldsUpdateCoin.assignTutor,

    }
    let paginationData = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.manualTutorChange(reqData, paginationData));


  }
  addAmountHandelRes = () => {

    let reqData = {
      "id": this.state.tutorIdSaveFeildValue,
      "paidAmount": this.state.fieldsUpdateCoin.amount,

    }
    let paginationData = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.addAmountInTuition(reqData, paginationData));

  }
  handleOnTutorModal = (data) => {
    this.setState({ ManualTutorModal: true, tutorIdSaveFeildValue: data.id });
  }
  handleHideTutorModal = () => {
    this.setState({ ManualTutorModal: false });
  }
  handleFile = (event) => {

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }
  addAmountHandel = (data) => {
    this.setState({ tutionUpdateModalAddAmount: true, valueAddAmount: data.id })
  }
  render() {

    let { tution, city, users } = this.props;
    let { getAllteacherCouresId, getAllStudentId, getAllTutorId, attendanceListItems, getTuitionById, totalClassDemo, totalSudent, totalTutor, loading } = tution;
    let { getAllClass, boardItems, attendanceItems } = city;
    let { getUserList } = users;
    console.log('attendanceListItems___________________', attendanceListItems);
    return (
      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5 divide-y-2">
              <div className="mx-auto max-w-screen-3xl ">
                <div className="w-full flex flex-wrap py-2 ">
                  <div className="ml-0 mr-auto">
                    <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">View Attendance</h3>
                  </div>
                </div>
                <section className="flex-col col-span-6 px-2 bg-white rounded-md 2xl:px-10  sm:px-5">
                  <div className=" rounded-lg overflow-hidden ">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#fff] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                  <p>Student Name</p>
                                  <p>Mobile Number</p>
                                </th>
                                <th scope="col" className=" px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider whitespace-nowrap">Class & Subject</th>
                                <th scope="col" className=" px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider whitespace-nowrap"> Class Reference</th>
                                <th scope="col" className=" px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider whitespace-nowrap">Assigned Tutor</th>
                                <th scope="col" className=" px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider whitespace-nowrap">Start Date</th>
                                <th scope="col" className=" px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider whitespace-nowrap">Due Date</th>
                                <th scope="col" className=" px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider whitespace-nowrap">Number of Classes in a month</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td className="px-6 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600">
                                  <p>{getTuitionById && getTuitionById.studentId && getTuitionById.studentId.firstName ? getTuitionById.studentId.firstName : null}</p>
                                  <p>{getTuitionById && getTuitionById.studentId && getTuitionById.studentId.mobNo ? getTuitionById.studentId.mobNo : null}</p>
                                </td>
                                <td className="px-6 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600">
                                  <p>{getTuitionById && getTuitionById.classId && getTuitionById.classId.name ? getTuitionById.classId.name : null}</p>
                                </td>
                                <td className="px-6 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600">
                                  <p>{getTuitionById && getTuitionById.classReference ? getTuitionById.classReference : null}</p>
                                </td>
                                <td className="px-6 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600">
                                  <p>{getTuitionById && getTuitionById.tutorId && getTuitionById.tutorId.fullName ? getTuitionById.tutorId.fullName : null}</p>
                                  <p>{getTuitionById && getTuitionById.tutorId && getTuitionById.tutorId.mobNo ? getTuitionById.tutorId.mobNo : null}</p>
                                </td>
                                <td className="px-6 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600">
                                  <p>{getTuitionById && getTuitionById.startDate ? getTuitionById.startDate : null}</p>
                                </td>
                                <td className="px-6 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600">
                                  <p>{getTuitionById && getTuitionById.dueDate ? getTuitionById.dueDate : '0'}</p>
                                </td>
                                <td className="px-6 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600">
                                  <p>{getTuitionById && getTuitionById.monthInDay ? getTuitionById.monthInDay : '0'}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <nav className="relative z-0 flex justify-end mt-5">
                    </nav>


                  </div>
                </section>
              </div>
              <div className='flex xl:flex-row flex-col xl:space-x-4 space-x-0 xl:space-y-0 space-y-2 py-6 bg-white px-10'>




                {
                  attendanceItems && attendanceItems.length > 0 ? attendanceItems.map((element) => (<React.Fragment key={element.id}>

                    <div className='space-y-4 w-full xl:w-[12%]'>

                      <div className='flex justify-between items-center space-x-6 rounded-full border px-1 py-1 bg-[#E8E8E8]'>
                        <p className='font-bold'>{element && element.monthName ? element.monthName : null}</p>
                        <p><MdOutlineRemoveRedEye size={24} onClick={() => this.handelIsOpenFnction(element)} /></p>
                      </div>
                    </div>

                  </React.Fragment>))
                    : null
                }




                {this.state.isOpen && (

                  <>

                    <div className='bg-[#E8E8E8] w-full xl:w-[44%] overflow-hidden overflow-x-auto'>
                      <table>
                        <thead className="rounded-t">
                          <tr className=" ">
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              date
                            </th>
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              Status
                            </th>
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              Remark
                            </th>
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              Any Dispute
                            </th>
                          </tr>
                        </thead>



                        {/* Table Row Section */}
                        {attendanceListItems && attendanceListItems.list && attendanceListItems.list.length > 0 ? attendanceListItems.list.slice(0, 15).map((element, index) =>
                          <>
                            <tbody key={index}>
                              <tr>
                                <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                  {moment(new Date(parseInt(element && element.date))).utcOffset("+05:30").format("DD MMMM YYYY")}
                                </td>
                                {element.isMarkAbsent ?
                                  <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                    <p><span className='text-red-600'>Off By Tutor </span> </p>
                                  </td>
                                  :
                                  element.isOffByStudent ?
                                    <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                      <p><span className='text-red-600'>Off By Student </span> </p>
                                    </td>
                                    :

                                    <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                      <p><span className='text-green-400'>class done </span> {moment(new Date(parseInt(element && element.checkInTime ? element.checkInTime : null))).utcOffset("+05:30").format("h:mm A")} To {moment(new Date(parseInt(element && element.checkOutTime ? element.checkOutTime : null))).utcOffset("+05:30").format("h:mm A")}</p>
                                    </td>}
                                <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                  <p>{element && element.offByStudent ? element.offByStudent : null}</p>
                                </td>
                                {element && element.isAttandance ?
                                  <td className="px-4 py-3 whitespace-nowrap text-center font-medium text-sm capitalize">
                                    <p
                                      title={element && element.reason}
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap"
                                      }}

                                    >
                                      <FaExclamationCircle size={20} className='text-[#BE1E2D]' />
                                    </p>
                                  </td>
                                  :
                                  null}
                              </tr>
                            </tbody>
                          </>
                        )
                          : null
                        }
                      </table>
                      <div className='flex justify-between items-center p-3'>
                        <p className=''>Total <span className='text-green-400'>Total Class Done</span> : {totalClassDemo ? totalClassDemo : '0'}</p>
                        <p className=''>Total <span className='text-[#BE1E2D]'>Off By Student</span> : {totalSudent ? totalSudent : '0'}</p>
                        <p className=''>Total <span className='text-[#BE1E2D]'>Off By Tutor</span> : {totalTutor ? totalTutor : '0'}</p>
                      </div>
                    </div>
                    <div className='bg-[#E8E8E8] w-full xl:w-[44%] overflow-hidden overflow-x-auto'>
                      <table>
                        <thead className="rounded-t">
                          <tr className=" ">
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              date
                            </th>
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              Status
                            </th>
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              Remark
                            </th>
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              Any Dispute
                            </th>
                          </tr>
                        </thead>

                        {/* Table Row Section */}
                        {attendanceListItems && attendanceListItems.list && attendanceListItems.list.length > 0 ? attendanceListItems.list.slice(15, 31).map((element, index) =>
                          <>
                            <tbody key={index}>
                              <tr>
                                <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                  {moment(new Date(parseInt(element && element.date))).utcOffset("+05:30").format("DD MMMM YYYY")}
                                </td>
                                {element.isMarkAbsent ?
                                  <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                    <p><span className='text-red-600'>Off By Tutor </span> </p>
                                  </td>
                                  :
                                  element.isOffByStudent ?
                                    <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                      <p><span className='text-red-600'>Off By Student </span> </p>
                                    </td>
                                    :

                                    <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                      <p><span className='text-green-400'>class done </span> {moment(new Date(parseInt(element && element.checkInTime))).utcOffset("+05:30").format("h:mm A")} {moment(new Date(parseInt(element && element.checkOutTime))).utcOffset("+05:30").format("h:mm A")}</p>
                                    </td>}
                                <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                  <p>{element && element.remark ? element.remark : null}</p>
                                </td>
                                {element && element.isOffByStudent ?
                                  <td className="px-4 py-3 whitespace-nowrap text-center font-medium text-sm capitalize relative">
                                    <FaExclamationCircle size={20} className='text-[#BE1E2D]' />
                                    {/* <span className='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded absolute hidden'>Disable</span> */}
                                  </td>
                                  :
                                  null
                                }
                              </tr>
                            </tbody>
                          </>
                        )
                          : null
                        }
                      </table>

                    </div>
                    <div className='bg-[#E8E8E8] w-full xl:w-[44%] overflow-hidden overflow-x-auto'>
                      <table>
                        <thead className="rounded-t">
                          <tr className=" ">
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              date
                            </th>
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              Status
                            </th>
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              Remark
                            </th>
                            <th scope="col" className="whitespace-nowrap px-4 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              Any Dispute
                            </th>
                          </tr>
                        </thead>

                        {/* Table Row Section */}
                        {attendanceListItems && attendanceListItems.resDataHolidays && attendanceListItems.resDataHolidays.length > 0 ? attendanceListItems.resDataHolidays.map((element, index) =>
                          <>
                            <tbody key={index}>
                              <tr>
                                <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">{
                                  element && element.date ? element.date : null
                                }
                                  {/* {moment(new Date(parseInt(element && element.date))).utcOffset("+05:30").format("DD MMMM YYYY")} */}
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                  <p><span className='text-red-600'>{element && element.description ? element.description : null} </span> </p>
                                </td>

                                <td className="px-4 py-3 whitespace-nowrap text-start font-medium text-sm text-gray-600 capitalize">
                                  <p>{element && element.remark ? element.remark : null}</p>
                                </td>
                                {element && element.isOffByStudent ?
                                  <td className="px-4 py-3 whitespace-nowrap text-center font-medium text-sm capitalize relative">
                                    <FaExclamationCircle size={20} className='text-[#BE1E2D]' />
                                    {/* <span className='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded absolute hidden'>Disable</span> */}
                                  </td>
                                  :
                                  null
                                }
                              </tr>
                            </tbody>
                          </>
                        )
                          : null
                        }
                      </table>

                    </div>

                    {/* </React.Fragment>))
                      : <div>Not found</div>
                    } */}
                  </>
                )

                }

              </div>
            </div>


          </main >
        </div >

        <CreateTutionModal

          tutionCreateModal={this.state.tutionCreateModal}
          fieldstution={this.state.fieldstution}
          errorstution={this.state.errorstution}
          inputChange={this.inputChange}
          tutionubmit={this.tutionubmit}
          handletutionHideModal={this.handletutionHideModal}
          getAllStudentId={getAllStudentId}
          getAllTutorId={getAllTutorId}
          getAllteacherCouresId={getAllteacherCouresId}
          getAllClass={getAllClass}
          // companyShare={companyShare}
          boardItems={boardItems}

        />
        <UpdateTutionModal

          tutionUpdateModal={this.state.tutionUpdateModal}
          ManualTutorModal={this.state.ManualTutorModal}
          fieldsUpdateCoin={this.state.fieldsUpdateCoin}
          errorsUpdateCoin={this.state.errorsUpdateCoin}
          assignTutor={this.state.assignTutor}
          inputChangeUpdateCoin={this.inputChangeUpdateCoin}
          assignTutorDemoClass={this.assignTutorDemoClass}
          updateCoinSubmit={this.manualAssginTutor}
          handleHideTutorModal={this.handleHideTutorModal}
          handleFile={this.handleFile}
          getUserList={getUserList}
        />
        <UpdateTutionModalAddAmount

          tutionUpdateModalAddAmount={this.state.tutionUpdateModalAddAmount}
          ManualTutorModal={this.state.ManualTutorModal}
          fieldsUpdateCoin={this.state.fieldsUpdateCoin}
          errorsUpdateCoin={this.state.errorsUpdateCoin}
          assignTutor={this.state.assignTutor}
          inputChangeUpdateCoin={this.inputChangeUpdateCoin}
          assignTutorDemoClass={this.assignTutorDemoClass}
          updateCoinSubmit={this.addAmountHandelRes}
          handleHideTutorModal={this.handleHideTutorModal}
          handleFile={this.handleFile}
          getUserList={getUserList}
        />


      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, tution, city } = state;
  return {
    users,
    tution, city,
    authentication
  };
}
export default connect(mapStateToProps)(Attendance);
