export const keyfeatureConstants = {
    LOGIN_REQUEST: 'KEYFEATURES_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'KEYFEATURES_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'KEYFEATURES_LOGIN_FAILURE',

    LOGOUT: 'KEYFEATURES_LOGOUT',

    GETALL_REQUEST: 'KEYFEATURES_GETALL_REQUEST',
    GETALL_SUCCESS: 'KEYFEATURES_GETALL_SUCCESS',
    GETALL_FAILURE: 'KEYFEATURES_GETALL_FAILURE',

    GET_ALL_KEYFEATURE_REQUEST: 'KEYFEATURES_GET_ALL_KEYFEATURE_REQUEST',
    GET_ALL_KEYFEATURE_SUCCESS: 'KEYFEATURES_GET_ALL_KEYFEATURE_SUCCESS',
    GET_ALL_KEYFEATURE_FAILURE: 'KEYFEATURES_GET_ALL_KEYFEATURE_FAILURE',
    
    GETALL_KEYFEATURE_NOTIFY_REQUEST: 'GETALL_KEYFEATURE_NOTIFY_REQUEST',
    GETALL_KEYFEATURE_NOTIFY_SUCCESS: 'GETALL_KEYFEATURE_NOTIFY_SUCCESS',
    GETALL_KEYFEATURE_NOTIFY_FAILURE: 'GETALL_KEYFEATURE_NOTIFY_FAILURE',

    UPDATE_KEYFEATURE_NOTIFY_REQUEST: 'UPDATE_KEYFEATURE_NOTIFY_REQUEST',
    UPDATE_KEYFEATURE_NOTIFY_SUCCESS: 'UPDATE_KEYFEATURE_NOTIFY_SUCCESS',
    UPDATE_KEYFEATURE_NOTIFY_FAILURE: 'UPDATE_KEYFEATURE_NOTIFY_FAILURE',

    UPDATE_KEYFEATURE_PASSWORD_REQUEST: 'UPDATE_KEYFEATURE_PASSWORD_REQUEST',
    UPDATE_KEYFEATURE_PASSWORD_SUCCESS: 'UPDATE_KEYFEATURE_PASSWORD_SUCCESS',
    UPDATE_KEYFEATURE_PASSWORD_FAILURE: 'UPDATE_KEYFEATURE_PASSWORD_FAILURE',


    ADD_RESTAURANT_KEYFEATURE_REQUEST: 'ADD_RESTAURANT_KEYFEATURE_REQUEST',
    ADD_RESTAURANT_KEYFEATURE_SUCCESS: 'ADD_RESTAURANT_KEYFEATURE_SUCCESS',
    ADD_RESTAURANT_KEYFEATURE_FAILURE: 'ADD_RESTAURANT_KEYFEATURE_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',
    
    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

};
