export const coinConstants = {

    GETALL_COIN_REQUEST: 'GETALL_COIN_REQUEST',
    GETALL_COIN_SUCCESS: 'GETALL_COIN_SUCCESS',
    GETALL_COIN_FAILURE: 'GETALL_COIN_FAILURE',

    GET_LIST_COIN_REQUEST: 'GET_LIST_COIN_REQUEST',
    GET_LIST_COIN_SUCCESS: 'GET_LIST_COIN_SUCCESS',
    GET_LIST_COIN_FAILURE: 'GET_LIST_COIN_FAILURE',


    // DEPOSIT_USER_BALANCE_REQUEST: 'DEPOSIT_USER_BALANCE_REQUEST',
    // DEPOSIT_USER_BALANCE_SUCCESS: 'DEPOSIT_USER_BALANCE_SUCCESS',
    // DEPOSIT_USER_BALANCE_FAILURE: 'DEPOSIT_USER_BALANCE_FAILURE',

    ADD_COIN_REQUEST: 'ADD_COIN_REQUEST',
    ADD_COIN_SUCCESS: 'ADD_COIN_SUCCESS',
    ADD_COIN_FAILURE: 'ADD_COIN_FAILURE',

    DELETE_COIN_REQUEST: 'DELETE_COIN_REQUEST',
    DELETE_COIN_SUCCESS: 'DELETE_COIN_SUCCESS',
    DELETE_COIN_FAILURE: 'DELETE_COIN_FAILURE',

    UPDATE_COIN_REQUEST: 'UPDATE_COIN_REQUEST',
    UPDATE_COIN_SUCCESS: 'UPDATE_COIN_SUCCESS',
    UPDATE_COIN_FAILURE: 'UPDATE_COIN_FAILURE',

    DISABLE_COIN_REQUEST: 'DISABLE_COIN_REQUEST',
    DISABLE_COIN_SUCCESS: 'DISABLE_COIN_SUCCESS',
    DISABLE_COIN_FAILURE: 'DISABLE_COIN_FAILURE',

};
