import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dashboardActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import { isMobile } from "react-device-detect";
import ImageViewModal from "./components/ImageViewModal/ImageViewModal";
import ViewPendingModal from "./components/ViewPendingModal/ViewPendingModal";
import ViewAddharModal from "./components/ViewAddharModal/ViewAddharModal";
import ViewPanModal from "./components/ViewPanModal/ViewPanModal";
import ViewSelfieModal from "./components/ViewSelfieModal/ViewSelfieModal";
import moment from 'moment'
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';


class LeadManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentData: {},
    }
  }
  componentDidMount() {

    let newDataMonth = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    this.props.dispatch(dashboardActions.totalTuitionEarning(newDataMonth));
    this.props.dispatch(dashboardActions.totalTuitionEarningMonthWasi(newDataMonth));
    this.props.dispatch(cityActions.getAllCity());
  }
  handleChangeInputCityId = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["cityId"] = output;
    this.setState({ studentData });

  };

  classReferenceSubmit = () => {

    let newDataMonth = {
      "cityId": this.state.studentData['cityId'] && this.state.studentData['cityId'].length > 0 ? this.state.studentData['cityId'] : null,
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    console.log('newDataMonth______________________', newDataMonth);
    this.props.dispatch(dashboardActions.totalTuitionEarning(newDataMonth));
    this.props.dispatch(dashboardActions.totalTuitionEarningMonthWasi(newDataMonth));


  }

  resetDataRes = (data) => {
    this.props.history.push('/app/earningManagement')
  }


  render() {

    let { dashboard, city } = this.props;
    const { totalTuitionEarningMonthWasi, loading, totalTuitionEarning } = dashboard
    let { getAllCity } = city;
    console.log('totalTuitionEarningMonthWasi_________________', totalTuitionEarningMonthWasi);
    let cityName = getAllCity && getAllCity.length ? getAllCity.map((ele, index) => ({ value: ele.id, label: ele.name })) : null

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="flex-1 relative ">
            <div className="2xl:p-10 sm:p-5 p-3">
              <div className="max-w-screen-3xl mx-auto ">
                {/* Transaction Section */}
                <section className="bg-white col-span-6 2xl:py-7 py-5 2xl:px-10 sm:px-5 px-2 flex-col rounded-md">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold text-dark-400 tracking-widest">Total Earning Management</h3>
                    </div>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4 shadow-md">
                    <div className="overflow-x-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-x-auto">
                          <thead className="bg-[#fff] rounded-t">
                            <tr className=" ">

                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>City</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputCityId}
                                    options={cityName}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              </th>




                              <th className='whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider'>
                                <label className='text-transparent'>City</label>
                                <div className='flex'>
                                  <span> <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.classReferenceSubmit()}> Search </button></span>
                                  <span> <button className="bg-[#25BF63] hover:bg-[#25BF63] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.resetDataRes()}> Reset </button></span>
                                </div>
                              </th>

                            </tr>
                          </thead>
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Month</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Tuition Fee</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Refrence Fee </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Tutor's Payment</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Referral Payment</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Company Share</th>
                              </tr>
                            </thead>

                            {/* Table Row Section */}

                            <tbody>

                              <tr className=" bg-white border-b border-black border-opacity-10 ">
                                <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">{moment(new Date(parseInt(totalTuitionEarningMonthWasi && totalTuitionEarningMonthWasi.month ? totalTuitionEarningMonthWasi.month : null))).utcOffset("+05:30").format("MMMM YYYY")}</td>
                                <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">₹ {totalTuitionEarningMonthWasi && totalTuitionEarningMonthWasi.studentFee ? totalTuitionEarningMonthWasi.studentFee : 0}</td>
                                <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">₹ {totalTuitionEarningMonthWasi && totalTuitionEarningMonthWasi.refFee ? totalTuitionEarningMonthWasi.refFee : 0}</td>
                                <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">₹ {totalTuitionEarningMonthWasi && totalTuitionEarningMonthWasi.tutorFee ? totalTuitionEarningMonthWasi.tutorFee : 0}</td>
                                <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">₹ {totalTuitionEarningMonthWasi && totalTuitionEarningMonthWasi.referralFee ? totalTuitionEarningMonthWasi.referralFee : 0}</td>
                                <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">₹ {totalTuitionEarningMonthWasi && totalTuitionEarningMonthWasi.companyShare ? totalTuitionEarningMonthWasi.companyShare : 0}</td>
                              </tr>

                              {
                                totalTuitionEarning && totalTuitionEarning && totalTuitionEarning.length > 0 ?
                                  totalTuitionEarning.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">{moment(new Date(parseInt(element && element.month ? element.month : null))).utcOffset("+05:30").format("MMMM YYYY")}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">₹ {element && element.studentFee ? element.studentFee : 0}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">₹ {element && element.refFee ? element.refFee : 0}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">₹ {element && element.tutorFee ? element.tutorFee : 0}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">₹ {element && element.referralFee ? element.referralFee : 0}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">₹ {element && element.companyShare ? element.companyShare : 0}</td>
                                    </tr>
                                  </React.Fragment>))
                                  : null
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <ImageViewModal
          viewImageCreateModal={this.state.viewImageCreateModal}
          //classes={classes}
          viewImageData={this.state.viewImageData}
          viewhandleClose={this.viewhandleClose}

        />

        <ViewPendingModal
          addPaymentField={this.state.addPaymentField}
          fieldsHoliday={this.state.fieldsHoliday}
          errorsHoliday={this.state.errorsHoliday}
          viewRowData={this.state.viewRowData}
          inputHolidayChange={this.inputHolidayChange}
          createHolidaySubmit={this.createHolidaySubmit}
          handleMoreDetailsClose={this.handlePayAmountClose}

        />

        <ViewAddharModal
          viewAddharDetailsModal={this.state.viewAddharDetailsModal}
          viewAddharData={this.state.viewAddharData}
          handleAddharClose={this.handleAddharClose}

        />

        <ViewPanModal
          viewPanDetailsModal={this.state.viewPanDetailsModal}
          viewPanData={this.state.viewPanData}
          handlePanClose={this.handlePanClose}

        />

        <ViewSelfieModal
          viewSelfieDetailsModal={this.state.viewSelfieDetailsModal}
          viewSelfieData={this.state.viewSelfieData}
          handleSelfieClose={this.handleSelfieClose}

        />

      </>
    );
  }
}
function mapStateToProps(state) {
  const { dashboard, city } = state;
  return {
    dashboard,
    city
  };
}
export default connect(mapStateToProps)(LeadManagement);
