import React, { useState } from "react";

import Modal from 'react-modal';
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';


export default function CreateAddDealerModal(props) {



  let { coinCreateModal, handleCoinHideModal, errorsCoin, inputCoinChange, createCoinSubmit, fieldsCoin, handleFile, studentAndTutorList, handleChangeInputInternal, getAllClass, getClassIdByTuition } = props;


  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAll, setSelecteAll] = useState(false);
  const [selectAlll, setSelectAll] = useState(false);





  let studentList = []
  let tutorList = []
  studentAndTutorList.forEach(element => {
    if (element.userType == "STUDENT") {
      studentList.push(element)
    } else if (element.userType == "TUTOR") {
      tutorList.push(element)
    }
  });
  let studentName = studentList && studentList.length ? studentList.map((ele, index) => ({ value: ele && ele.id, label: ele && ele.firstName + " " + ele && ele.firstName + " " + ele && ele.mobNo })) : null
  let studentNameRes = getClassIdByTuition && getClassIdByTuition.length ? getClassIdByTuition.map((ele, index) => ({ value: ele && ele.studentId && ele.studentId.id, label: ele && ele.studentId && ele.studentId.firstName + ", " + ele && ele.studentId && ele.studentId.firstName + ", " + ele && ele.studentId && ele.studentId.mobNo + ", " + ele && ele.classId && ele.classId.name + ", " + ele && ele.teacherCouresId && ele.teacherCouresId[0] && ele.teacherCouresId[0].name })) : []




  const handleChangeInputInternal1 = (selected) => {
    setSelectedOptions(selected);
    handleChangeInputInternal(selected)
    setSelectAll(selected.length === studentNameRes.length);
  };

  const handleDeSelectAll = () => {
    const allOptions = []
    setSelectedOptions(allOptions);
    setSelecteAll(false)
  };
  const handleSelectAll = () => {
    const allOptions = studentNameRes ? studentNameRes : studentName;
    setSelectedOptions(allOptions);
    handleChangeInputInternal(allOptions)
    setSelecteAll(true)
  };






  return (

    <Modal
      isOpen={coinCreateModal}
    >
      <div className="main-modal fixed left-0 top-0 w-full h-full inset-0 z-90 overflow-hidden flex justify-center items-center animated fadeIn faster  overflow-y-auto" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6 w-full ">
            <div className="flex justify-between items-center">
              <p className="text-2xl font-bold">Create Assignment</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleCoinHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="">
              <form autoComplete="on">


                <div className="w-full ">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Class</label>
                    <select className="px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 border border-gray-400" onChange={inputCoinChange}
                      id="classId"
                      value={fieldsCoin && fieldsCoin.classId ? fieldsCoin.classId : ''}
                      name="classId">
                      <option value=""> Please Choose class&hellip;</option>
                      {
                        getAllClass && getAllClass && getAllClass.length > 0 ?
                          getAllClass.map((element, index) => (
                            <option value={element && element.id} key={index}>{element && element.name}</option>
                          )) : null
                      }
                    </select>
                  </div>
                </div>


                <div className="flex justify-between items-center w-full">
                  <div class="mr-2 w-[80%]">
                    <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                      <label htmlFor="resourceList" style={{ color: 'black' }}>Name or Number</label>

                      <Select2
                        width="full"
                        isMulti
                        name="internalId"
                        id="internalId"
                        onChange={handleChangeInputInternal1}
                        options={studentNameRes ? studentNameRes : studentName}
                        className="basic-multi-select width: 'full'"
                        classNamePrefix="Activities"
                        value={selectedOptions} // Pass the selected options to the Select2 component
                      />
                    </Grid>
                  </div>

                  {selectAll ?
                    <div className="w-[20%]">
                      <button type="button" className="bg-shine-400 w-auto   flex justify-center py-2 capitalize px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" onClick={handleDeSelectAll}>Deselect</button>
                    </div>
                    :
                    <div className="w-[20%]">
                      <label className="text-transparent">Name or Number</label>
                      <button type="button" className="bg-shine-400 w-auto   flex justify-center py-2 capitalize px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" onClick={handleSelectAll}>Select All</button>
                    </div>
                  }


                </div>
                {errorsCoin && errorsCoin["studentId"] ?
                  <div className="invalid-feedback text-yellow-600">
                    {errorsCoin["studentId"]}
                  </div>
                  : null}



                <div className="w-full mr-2">
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <label class="block text-gray-700 text-sm" for="username">Assignment Name</label>
                    <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsCoin && !errorsCoin["name"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="name" name="name" placeholder="Assignment Name" value={fieldsCoin.name} type="text"
                      onChange={inputCoinChange}
                    />
                    {errorsCoin && errorsCoin["name"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsCoin["name"]}
                      </div>
                      : null}
                  </div>
                </div>



                <div className="w-full mr-2">
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <label class="block text-gray-700 text-sm" for="username">Attach Assignment</label>
                    <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsCoin && !errorsCoin["assignmentPdf"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="assignmentPdf" name="assignmentPdf" placeholder="Attach Assignment" value={handleFile} type="file"
                      onChange={handleFile}
                    />
                    {errorsCoin && errorsCoin["assignmentPdf"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsCoin["assignmentPdf"]}
                      </div>
                      : null}
                  </div>
                </div>



                <div className="mt-6 w-60 mx-auto">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-2 capitalize px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={createCoinSubmit}>Submit</button>
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal>



  );
}
