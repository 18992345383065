import {
  userConstants, documentConstants
} from '../_constants';

export function document(state = {}, action) {

  switch (action.type) {
    case documentConstants.ADD_DOCUMENT_REQUEST:
      return {
        ...state
      };
    case documentConstants.ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case documentConstants.ADD_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case documentConstants.GET_LIST_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case documentConstants.GET_LIST_DOCUMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getDocumentList.list,
        total: action.users.getDocumentList.total
      };
    case documentConstants.GET_LIST_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case documentConstants.GETALL_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case documentConstants.GETALL_DOCUMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getAllDocument,
        total: action.users.getAllDocument
      };
    case documentConstants.GETALL_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_DOCUMENT_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_DOCUMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}