export const appsettingConstants = {
    GETALL_REQUEST: 'APPSETTINGS_GETALL_REQUEST',
    GETALL_SUCCESS: 'APPSETTINGS_GETALL_SUCCESS',
    GETALL_FAILURE: 'APPSETTINGS_GETALL_FAILURE',

   
    ADD_APPSETTING_REQUEST: 'ADD_APPSETTING_REQUEST',
    ADD_APPSETTING_SUCCESS: 'ADD_APPSETTING_SUCCESS',
    ADD_APPSETTING_FAILURE: 'ADD_APPSETTING_FAILURE',


    UPDATES_APP_SETTING_REQUEST: 'UPDATES_APP_SETTING_REQUEST',
    UPDATES_APP_SETTING_SUCCESS: 'UPDATES_APP_SETTING_SUCCESS',
    UPDATES_APP_SETTING_FAILURE: 'UPDATES_APP_SETTING_FAILURE',


    DELETE__APP_SETTING_FAILURE: 'DELETE__APP_SETTING_FAILURE',
    DELETE__APP_SETTING_SUCCESS: 'DELETE__APP_SETTING_SUCCESS',
    DELETE__APP_SETTING_REQUEST: 'DELETE__APP_SETTING_REQUEST',


    DISABLE_APP_SETTING_REQUEST: 'DISABLE_APP_SETTING_REQUEST',
    DISABLE_APP_SETTING_SUCCESS: 'DISABLE_APP_SETTING_SUCCESS',
    DISABLE_APP_SETTING_FAILURE: 'DISABLE_APP_SETTING_FAILURE',


    LOGIN_REQUEST: 'APPSETTINGS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'APPSETTINGS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'APPSETTINGS_LOGIN_FAILURE',

    LOGOUT: 'APPSETTINGS_LOGOUT',


    GET_ALL_APPSETTING_REQUEST: 'APPSETTINGS_GET_ALL_APPSETTING_REQUEST',
    GET_ALL_APPSETTING_SUCCESS: 'APPSETTINGS_GET_ALL_APPSETTING_SUCCESS',
    GET_ALL_APPSETTING_FAILURE: 'APPSETTINGS_GET_ALL_APPSETTING_FAILURE',    


    TERM_CONDITION_APPSETTING_PASSWORD_REQUEST : "TERM_CONDITION_APPSETTING_PASSWORD_REQUEST",
    TERM_CONDITION_APPSETTING_PASSWORD_SUCCESS : "TERM_CONDITION_APPSETTING_PASSWORD_SUCCESS",
    TERM_CONDITION_APPSETTING_PASSWORD_FAILURE : "TERM_CONDITION_APPSETTING_PASSWORD_FAILURE",

    DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_REQUEST :"DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_REQUEST",
    DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_SUCCESS : "DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_SUCCESS",
    DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_FAILURE : "DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_FAILURE",
};
