import { bannerConstants } from "../_constants/banner.constants";

  
  export function banner(state = {}, action) {
  
    switch (action.type) {
      case bannerConstants.GET_ALL_BANNER_REQUEST:
        return {
          ...state,
          loading: true
        };
      case bannerConstants.GET_ALL_BANNER_SUCCESS:
        return {
          ...state,
          loading: false,
          getBannerList : action.users
        };
      case bannerConstants.GET_ALL_BANNER_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };
       case bannerConstants.ADD_BANNER_REQUEST:
            return {
              ...state,
              loading: true
            };
        case bannerConstants.ADD_BANNER_SUCCESS:
        return {
            ...state,
            loading: false,
            createBanner : action.users
        };
        case bannerConstants.ADD_BANNER_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.error
        };
        case bannerConstants.UPDATE_BANNER_REQUEST:
            return {
              ...state,
              loading: true
            };
        case bannerConstants.UPDATE_BANNER_SUCCESS:
        return {
            ...state,
            loading: false,
            updateBanner : action.users
        };
        case bannerConstants.UPDATE_BANNER_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.error
        };
        case bannerConstants.DELETE_BANNER_REQUEST:
            return {
              ...state,
              loading: true
            };
        case bannerConstants.DELETE_BANNER_SUCCESS:
        return {
            ...state,
            loading: false,
            DELETEBanner : action.users
        };
        case bannerConstants.DELETE_BANNER_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.error
        };
        case bannerConstants.UPDATE_BANNER_STATUS_REQUEST:
            return {
              ...state,
              loading: true
            };
        case bannerConstants.UPDATE_BANNER_STATUS_SUCCESS:
        return {
            ...state,
            loading: false,
            updateBannerStatus : action.users
        };
        case bannerConstants.UPDATE_BANNER_STATUS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.error
        };
      default:
        return state
    }
  }