import {
  userConstants, statedataConstants
} from '../_constants';

export function statedata(state = {}, action) {

  switch (action.type) {
    case statedataConstants.ADD_STATE_DATA_REQUEST:
      return {
        ...state
      };
    case statedataConstants.ADD_STATE_DATA_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case statedataConstants.ADD_STATE_DATA_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case statedataConstants.GET_LIST_STATE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case statedataConstants.GET_LIST_STATE_DATA_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
        items: action.users.getStateDataList.list,
        total: action.users.getStateDataList.total
      };
    case statedataConstants.GET_LIST_STATE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case statedataConstants.GETALL_STATE_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case statedataConstants.GETALL_STATE_DATA_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allStatesItems: action.users.getAllStateData,
        allStatesTotal: action.users.getAllStateData
      };
    case statedataConstants.GETALL_STATE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_STATE_DATA_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_STATE_DATA_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_STATE_DATA_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_STATE_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_STATE_DATA_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_STATE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_STATE_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_STATE_DATA_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_STATE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}