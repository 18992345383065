export const ltArchiveRewardConstants = {

    GETALL_L_T_ARCHIVE_REWARD_REQUEST: 'GETALL_L_T_ARCHIVE_REWARD_REQUEST',
    GETALL_L_T_ARCHIVE_REWARD_SUCCESS: 'GETALL_L_T_ARCHIVE_REWARD_SUCCESS',
    GETALL_L_T_ARCHIVE_REWARD_FAILURE: 'GETALL_L_T_ARCHIVE_REWARD_FAILURE',

    GET_LIST_L_T_ARCHIVE_REWARD_REQUEST: 'GET_LIST_L_T_ARCHIVE_REWARD_REQUEST',
    GET_LIST_L_T_ARCHIVE_REWARD_SUCCESS: 'GET_LIST_L_T_ARCHIVE_REWARD_SUCCESS',
    GET_LIST_L_T_ARCHIVE_REWARD_FAILURE: 'GET_LIST_L_T_ARCHIVE_REWARD_FAILURE',

    // DEPOSIT_USER_BALANCE_REQUEST: 'DEPOSIT_USER_BALANCE_REQUEST',
    // DEPOSIT_USER_BALANCE_SUCCESS: 'DEPOSIT_USER_BALANCE_SUCCESS',
    // DEPOSIT_USER_BALANCE_FAILURE: 'DEPOSIT_USER_BALANCE_FAILURE',

    ADD_L_T_ARCHIVE_REWARD_REQUEST: 'ADD_L_T_ARCHIVE_REWARD_REQUEST',
    ADD_L_T_ARCHIVE_REWARD_SUCCESS: 'ADD_L_T_ARCHIVE_REWARD_SUCCESS',
    ADD_L_T_ARCHIVE_REWARD_FAILURE: 'ADD_L_T_ARCHIVE_REWARD_FAILURE',

    DELETE_L_T_ARCHIVE_REWARD_REQUEST: 'DELETE_L_T_ARCHIVE_REWARD_REQUEST',
    DELETE_L_T_ARCHIVE_REWARD_SUCCESS: 'DELETE_L_T_ARCHIVE_REWARD_SUCCESS',
    DELETE_L_T_ARCHIVE_REWARD_FAILURE: 'DELETE_L_T_ARCHIVE_REWARD_FAILURE',

    UPDATE_L_T_ARCHIVE_REWARD_REQUEST: 'UPDATE_L_T_ARCHIVE_REWARD_REQUEST',
    UPDATE_L_T_ARCHIVE_REWARD_SUCCESS: 'UPDATE_L_T_ARCHIVE_REWARD_SUCCESS',
    UPDATE_L_T_ARCHIVE_REWARD_FAILURE: 'UPDATE_L_T_ARCHIVE_REWARD_FAILURE',

    DISABLE_L_T_ARCHIVE_REWARD_REQUEST: 'DISABLE_L_T_ARCHIVE_REWARD_REQUEST',
    DISABLE_L_T_ARCHIVE_REWARD_SUCCESS: 'DISABLE_L_T_ARCHIVE_REWARD_SUCCESS',
    DISABLE_L_T_ARCHIVE_REWARD_FAILURE: 'DISABLE_L_T_ARCHIVE_REWARD_FAILURE',

};
