import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, teachcourseActions, tutionActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { FaArrowCircleRight } from "react-icons/fa";
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';

class Coin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsCoin: {},
      errorsCoin: {},
      fieldsUpdateCoin: {},
      fieldsUpdateRes: {},
      studentData: {},
      fieldstution: {},
      errorstution: {},
      errorsUpdateCoin: {},
      viewRowData: {},
      validateData: {},
      coinCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCoinModal: false,
      loginToThisAccountModal: false,
      addTuitoinModal: false,
      filedIsTuitionOn: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      },
      moreDetailsHandel: false,
    }
  }
  componentDidMount() {
    let temp1 = {
      // "status": "ASSIGN",
      "isTuitionOn": "yes",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getTutionList(temp1));



    let data1 = {
      "userType": "STUDENT",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActiveStudent(data1));


    // this.props.dispatch(cityActions.getBoardList());
    let data = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActive(data));
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getAllteacherCouresId(temp));
    this.props.dispatch(tutionActions.getAllStudentId(temp));
    this.props.dispatch(tutionActions.getAllTutorId(temp));
    this.props.dispatch(cityActions.getAllClass(temp));



  }
  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('nextProps.coin.addUserSuccess', nextProps.coin.addUserSuccess);
    console.log('nextProps.tution', nextProps.tution.isStateMangerRes);
    if (nextProps.tution.isStateMangerRes) {
      return {
        ...nextProps,
        fieldsUpdateRes: nextProps.tution
      }
    } else if (nextProps.coin.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsCoin: {},
        errorsCoin: {},
        fieldsUpdateCoin: {},
        errorsUpdateCoin: {},
        coinCreateModal: false,
        UpdateCoinModal: false,

      }
    }
    else if (nextProps.tution.addUserSuccess) {
      return {
        ...nextProps,
        filedIsTuitionOn: false,
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected + 1 });
    // let datatemp = {
    //   "status": "2",
    //   "keyWord": this.state.keyWord,
    //   "pageNo": data.selected + 1,
    //   "size": this.state.size
    // }
    // this.props.dispatch(teachcourseActions.getFilteredDemoClassList(datatemp));

    let temp1 = {
      // "status": "ASSIGN",
      "isTuitionOn": "yes",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getTutionList(temp1));



  }


  rejectedHandelTuition = (data) => {

    console.log('data________________data', data);

    let datatemp = {
      "id": data.id,
      "status": data.status,
    }
    let paginationdata = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "classId": this.state.studentData['classId'] && this.state.studentData['classId'].length > 0 ? this.state.studentData['classId'] : null,
      "teacherCouresId": this.state.studentData['teacherCouresId'] && this.state.studentData['teacherCouresId'].length > 0 ? this.state.studentData['teacherCouresId'] : null,
      "tutorId": this.state.studentData['tutorId'] && this.state.studentData['tutorId'].length > 0 ? this.state.studentData['tutorId'] : null,
      "dueDate": this.state.fieldstution && this.state.fieldstution['deuDate'] ? this.state.fieldstution['deuDate'] : null,
      "classReference": this.state.fieldstution && this.state.fieldstution['classReference'] ? this.state.fieldstution['classReference'] : null,
      "isTuitionOn": "yes",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to rejected tuition?',
      message: `Are you sure to rejected tuition?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.updateTuitionStatusOnGoinOrReject(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  amountSubmitHandel = (data) => {
    console.log('data____________________data', data);

    let datatemp = {
      // "id": data.id,
      "id": this.state.validateData.id,
      "status": data,
      "amount": this.state.fieldstution.amount
    }
    let paginationdata = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "classId": this.state.studentData['classId'] && this.state.studentData['classId'].length > 0 ? this.state.studentData['classId'] : null,
      "teacherCouresId": this.state.studentData['teacherCouresId'] && this.state.studentData['teacherCouresId'].length > 0 ? this.state.studentData['teacherCouresId'] : null,
      "tutorId": this.state.studentData['tutorId'] && this.state.studentData['tutorId'].length > 0 ? this.state.studentData['tutorId'] : null,
      "dueDate": this.state.fieldstution && this.state.fieldstution['deuDate'] ? this.state.fieldstution['deuDate'] : null,
      "classReference": this.state.fieldstution && this.state.fieldstution['classReference'] ? this.state.fieldstution['classReference'] : null,
      "isTuitionOn": "yes",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.updateTuitionStatusOnGoinOrReject(datatemp, paginationdata))
  }






  moreDetailsOpenModul = (data, status) => {
    this.setState({ moreDetailsHandel: true, allDataValue: data })
  }
  moreDetailsCloseModul = (data, status) => {
    this.setState({ moreDetailsHandel: false, allDataValue: {} })
  }
  inputChange = (e) => {
    console.log('e.target_______________________', e.target.value);
    e.preventDefault();
    let { name, value } = e.target;
    let fieldstution = this.state.fieldstution;
    let errorstution = this.state.errorstution;
    fieldstution[name] = value;
    errorstution[name] = "";
    console.log(name, value);
    this.setState({ fieldstution, errorstution });
  }
  handleOpenIsTuitionOn = (data) => {
    this.setState({ filedIsTuitionOn: true, validateData: data })
  }
  handleCloseIsTuitionOn = () => {
    this.setState({ filedIsTuitionOn: false, validateData: {} })
  }
  handleChangeInputInternal = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["studentId"] = output;
    this.setState({ studentData });

  };
  handleChangeInputclassId = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["classId"] = output;
    this.setState({ studentData });

  };
  handleChangeInputteacherCouresId = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["teacherCouresId"] = output;
    this.setState({ studentData });

  };
  handleChangeInputtutorId = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["tutorId"] = output;
    this.setState({ studentData });

  };
  classReferenceSubmit = () => {
    // e.preventDefault();
    console.log('this.state.fieldstution', this.state.fieldstution);

    let temp1 = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "classId": this.state.studentData['classId'] && this.state.studentData['classId'].length > 0 ? this.state.studentData['classId'] : null,
      "teacherCouresId": this.state.studentData['teacherCouresId'] && this.state.studentData['teacherCouresId'].length > 0 ? this.state.studentData['teacherCouresId'] : null,
      "tutorId": this.state.studentData['tutorId'] && this.state.studentData['tutorId'].length > 0 ? this.state.studentData['tutorId'] : null,
      "dueDate": this.state.fieldstution && this.state.fieldstution['deuDate'] ? this.state.fieldstution['deuDate'] : null,
      "classReference": this.state.fieldstution && this.state.fieldstution['classReference'] ? this.state.fieldstution['classReference'] : null,
      "isTuitionOn": "yes",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getTutionList(temp1));

  }
  resetDataRes = (data) => {
    this.props.history.push('/app/accepteddemoclass')
  }


  render() {

    let { tution, city, users } = this.props;
    let { totalPending, loading, items, getAllteacherCouresId } = tution;
    let { getAllClass } = city;
    let { getUserList, getUserStudentList } = users;


    let NameRes = getUserStudentList && getUserStudentList.length ? getUserStudentList.map((ele, index) => ({ value: ele.id, label: ele.firstName + " " + ele.lastName + " " + ele.mobNo })) : null
    let classRes = getAllClass && getAllClass.length ? getAllClass.map((ele, index) => ({ value: ele.id, label: ele.name })) : null
    let teacherCouresRes = getAllteacherCouresId && getAllteacherCouresId.length ? getAllteacherCouresId.map((ele, index) => ({ value: ele.id, label: ele.name })) : null
    let tutorRes = getUserList && getUserList.length ? getUserList.map((ele, index) => ({ value: ele.id, label: ele.fullName + " " + ele.mobNo })) : null

    console.log("RENDER___teachcourseteachcourse", totalPending);
    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="text-lg font-semibold leading-6 tracking-wider text-center capitalize md:text-2xl md:leading-9 text-dark-400">Pending For Registration ({items && items.length ? items.length : 0})</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600">
                      </div>
                    </div>
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">


                          <thead className="bg-[#fff] rounded-t">
                            <tr className=" ">
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'grey' }}>Student name or Number</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputInternal}
                                    options={NameRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'grey' }}>Class</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputclassId}
                                    options={classRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'grey' }}>Subject</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputteacherCouresId}
                                    options={teacherCouresRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'grey' }}>Tutor</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputtutorId}
                                    options={tutorRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              </th>
                              <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              </th>
                              <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <label className='invisible' htmlFor="resourceList" style={{ color: 'grey' }}>due date</label>
                                <div className="relative">
                                  <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                  </div>
                                  <input className="appearance-none sm:w-40 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="deuDate" name='deuDate' placeholder="Due date.." type="text" onChange={this.inputChange} required />
                                </div>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <label className='invisible' htmlFor="resourceList" style={{ color: 'grey' }}>due date</label>
                                <div className="relative">
                                  <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                  </div>
                                  <input className="appearance-none sm:w-40 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="classReference" name='classReference' placeholder="Class reference.." type="text" onChange={this.inputChange} required />
                                </div>
                              </th>
                              <th>
                                <label className='invisible' htmlFor="resourceList" style={{ color: 'grey' }}>due date</label>
                                <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.classReferenceSubmit()}> Search </button>
                              </th>
                              <th>
                                <label className='invisible' htmlFor="resourceList" style={{ color: 'grey' }}>due date</label>
                                <button className="bg-[#25BF63] hover:bg-[#25BF63] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.resetDataRes()}> Reset </button>
                              </th>
                            </tr>
                          </thead>

                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">Name & Mobile Number </th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Class & Subject</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Class Reference</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Assigned Tutor</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Tuition Fee</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Start Date</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>

                            {/* Table Row Section */}
                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap  text-sm text-gray-600 flex items-center space-x-2">
                                        <div className='w-12 h-12'>
                                          <img
                                            class="w-12 h-12 rounded-full"
                                            src={element && element.studentId && element.studentId.imageLinkUrl ? element.studentId.imageLinkUrl : element && element.studentId && element.studentId.gender && element.studentId.gender == "Male" ? "images/boyImageLogo.png" : element && element.studentId && element.studentId.gender && element.studentId.gender == "Female" ? "images/girlImageLogo.png" : "images/male-female.png"}
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <p>{element && element.studentId && element.studentId.firstName ? element.studentId.firstName : null} {element && element.studentId && element.studentId.lastName ? element.studentId.lastName : null}</p>
                                          <p> {element && element.studentId && element.studentId.mobNo ? element.studentId.mobNo : "-"}</p>
                                          <div className='flex items-center justify-end' onClick={() => this.moreDetailsOpenModul(element)}><span className="font-bold">More</span>  <span className='text-[#FBB00C] pl-1'><FaArrowCircleRight size={16} /> </span></div>
                                        </div>

                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600 space-y-2">
                                        <p className='text-center flex justify-center items-center'>{element && element.classId && element.classId.name ? element.classId.name : "-"} / {element && element.teacherCouresId && element.teacherCouresId[0] && element.teacherCouresId[0].name ? element.teacherCouresId[0].name : "-"}</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600 space-y-2">
                                        <p className='text-center flex justify-center items-center'>{element.classReference}</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600 space-y-2">
                                        <p className='text-center flex justify-center items-center'>{element && element.tutorId && element.tutorId.fullName ? element.tutorId.fullName : "-"} </p>
                                        <p className='text-center flex justify-center items-center'>{element && element.tutorId && element.tutorId.mobNo ? element.tutorId.mobNo : "-"}</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600 space-y-2">
                                        <p className='text-center flex justify-center items-center'>{element.fee}/- {element.tutorFeeStatus}</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600 space-y-2">
                                        <p className='text-center flex justify-center items-center'>{element.startDate}</p>
                                      </td>

                                      <td className="px-6 py-3  text-center text-sm text-gray-600 space-y-1">
                                        <div onClick={() => this.handleOpenIsTuitionOn(element)}>
                                          <button type="button" className='bg-[#25BF63] hover:bg-green-300 px-2 py-1 w-36 text-white font-bold rounded-full capitalize'>Approve</button>
                                        </div>
                                        <div onClick={() => this.rejectedHandelTuition({ ...element, status: 'REJECTED' })}>
                                          <button type="button" className='bg-red-500 hover:bg-red-300 px-2 py-1 w-36 text-white font-bold rounded-full capitalize'>Reject</button>
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }


                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {this.state.moreDetailsHandel ?
                      <>
                        <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                          <div className="border border-yellow-500 modal-container bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/3 mx-auto rounded-[40px] shadow-lg z-50 overflow-y-auto">
                            <div className="modal-content ">
                              <div className="flex justify-between items-center modal-close cursor-pointer z-50 py-2 px-6 bg-[#FBB00C]">
                                <p className='font-bold text-md'>More</p>
                                <div className='bg-white rounded-full p-1'>
                                  <svg
                                    onClick={() => this.moreDetailsCloseModul()}
                                    className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                    </path>
                                  </svg>
                                </div>
                              </div>

                              <div className='py-4 px-6 h-[400px] overflow-y-auto divide-y-2'>
                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Student name :</p>
                                  <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.firstName ? this.state.allDataValue.studentId.firstName
                                    : "NA"} {"  "}{this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.lastName ? this.state.allDataValue.studentId.lastName
                                      : "NA"}</p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Student mob No. : </p>
                                  <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.mobNo ? this.state.allDataValue.studentId.mobNo
                                    : "NA"} </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Guardian Name :</p>
                                  <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianName ? this.state.allDataValue.studentId.studentInfoId.guardianName : "-"} </p>
                                </div>
                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Guardian Mob No. :</p>
                                  <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianConNo ? this.state.allDataValue.studentId.studentInfoId.guardianConNo : "-"} </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Tutor name :</p>
                                  <p > {this.state.allDataValue && this.state.allDataValue.tutorId && this.state.allDataValue.tutorId.fullName ? this.state.allDataValue.tutorId.fullName : "NA"}   </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Tutor mob No. : </p>
                                  <p > {this.state.allDataValue && this.state.allDataValue.tutorId && this.state.allDataValue.tutorId.mobNo ? this.state.allDataValue.tutorId.mobNo
                                    : "NA"} </p>
                                </div>

                                <div className='flex justify-between items-start w-full'>
                                  <div className='w-full'>
                                    <p className='text-lg font-bold'>Subject:</p>
                                  </div>
                                  <div className='w-full'>
                                    <ul className='text-right'>
                                      {this.state.allDataValue && this.state.allDataValue.teacherCouresId && this.state.allDataValue.teacherCouresId.length > 0 ?
                                        this.state.allDataValue.teacherCouresId.map((element) =>
                                          <>
                                            <li className='flex flex-col'>{element && element.name ? element.name : "NA"} </li>
                                          </>
                                        ) : 'NA'}
                                    </ul>
                                  </div>
                                </div>



                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Class:</p>
                                  <p >{this.state.allDataValue && this.state.allDataValue.classId && this.state.allDataValue.classId.name ? this.state.allDataValue.classId.name : "NA"}  </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Class in month:</p>
                                  <p >{this.state.allDataValue && this.state.allDataValue.monthInDay ? this.state.allDataValue.monthInDay : "NA"}  </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Total fee:</p>
                                  <p >{this.state.allDataValue && this.state.allDataValue.fee ? this.state.allDataValue.fee : "0"}  </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Tutor fee:</p>
                                  <p >{this.state.allDataValue && this.state.allDataValue.tutorFee ? this.state.allDataValue.tutorFee : "0"}  </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Reference fee:</p>
                                  <p >{this.state.allDataValue && this.state.allDataValue.refFee ? this.state.allDataValue.refFee : "0"}  </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Company share:</p>
                                  <p >{this.state.allDataValue && this.state.allDataValue.companyShare ? this.state.allDataValue.companyShare : "0"}  </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Start date:</p>
                                  <p >{this.state.allDataValue && this.state.allDataValue.startDate ? this.state.allDataValue.startDate : "0"}  </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Payment mode:</p>
                                  <p >{this.state.allDataValue && this.state.allDataValue.paymentMode ? this.state.allDataValue.paymentMode : "NA"}  </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Create tution date:</p>
                                  <p >{moment(new Date(parseInt(this.state.allDataValue && this.state.allDataValue.createdAt ? this.state.allDataValue.createdAt : "NA"))).utcOffset("+05:30").format("DD-MMM-YY")}
                                  </p>
                                </div>

                                <div className='flex justify-between items-center w-full'>
                                  <p className='text-lg font-bold'>Class status:</p>
                                  <p >{this.state.allDataValue && this.state.allDataValue.isCloseClass ? "Complete class" : "On going class"}
                                  </p>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                      </>
                      :
                      null
                    }
                    {this.state.filedIsTuitionOn ?
                      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                        <div className="border border-[#25BF63] shadow-lg modal-container bg-white w-96 mx-auto rounded-[40px]  z-50 overflow-y-auto">
                          <div className="modal-content py-4 text-left px-6">
                            <div className="modal-close cursor-pointer z-50 bg-[#25BF63] w-6 h-6 rounded-full flex justify-center items-center">
                              <svg
                                onClick={() => this.handleCloseIsTuitionOn()}
                                className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                </path>
                              </svg>
                            </div>
                            <div className="my-5">
                              <form class="max-w-sm mx-auto">
                                <div class="mb-5">
                                  <label for="email" class="block mb-2 text-sm font-bold text-gray-900">Amount</label>
                                  <input type="number" id="amount" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg px-2 py-3 block w-full" placeholder="Amount" name='amount' required onChange={this.inputChange} />
                                </div>
                                <button type="button" class="text-white bg-[#25BF63] font-bold rounded-full text-sm w-full  px-5 py-2.5 text-center" onClick={() => this.amountSubmitHandel('APPROVED')}>Submit</button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      null
                    }
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            totalPending && totalPending > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={totalPending / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> :
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            totalPending && totalPending > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={totalPending / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>



      </>

    );
  }
}
function mapStateToProps(state) {
  const { coin, users, tution, city } = state;
  return {
    coin,
    users,
    tution,
    city
  };
}
export default connect(mapStateToProps)(Coin);
