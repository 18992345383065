import React from "react";

import Modal from 'react-modal';
const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};

export default function ViewPendingModal(props) {

  let { viewSelfieDetailsModal, handleSelfieClose, viewSelfieData,
  } = props;
  console.log("viewSelfieDataModal::", viewSelfieData);

  return (

    <Modal
      isOpen={viewSelfieDetailsModal}
      style={customStyles}
      ariaHideApp={false}
    >


      {viewSelfieData ?
        <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" >
          <div className="border border-teal-500 modal-container bg-white w-11/12 md:max-w-4xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">

              <div className="flex justify-between items-center">
                <p className="text-2xl font-bold">View Photo</p>
                <div className="modal-close cursor-pointer z-50">
                  <svg onClick={() => handleSelfieClose()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                    </path>
                  </svg>
                </div>
              </div>

              <div className="my-3">
                <form autoComplete="off">
                  <>


                    <div className="grid grid-rows-1 grid-flow-col">
                      <div className="justify-center flex text-white p-4 bg-shine rounded-md items-center mt-2 w-full ">
                        <div className="space-x-4 mt-4">
                          <div className="space-y-2" >
                            <label className="">IMAGE FRONT</label>
                            <img className="w-96 h-64" src={viewSelfieData && viewSelfieData.imageUserLink ? viewSelfieData.imageUserLink : "-"} alt=" " />
                          </div>
                        </div>
                      </div>
                    </div>


                  </>
                </form>

              </div>
            </div>
          </div>
        </div>
        : null
      }


    </Modal>



  );
}
