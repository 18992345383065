import {
  ticketConstants
} from '../_constants';

export function ticket(state = {}, action) {

  switch (action.type) {


    case ticketConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ticketConstants.GETALL_SUCCESS:
      return {
        ...state,
        addTicketSuccess: false,
        items: action.tickets.getTicketList.list,
        total: action.tickets.getTicketList.total
      };
    case ticketConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case ticketConstants.GET_ALL_TICKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ticketConstants.GET_ALL_TICKET_SUCCESS:
      return {
        ...state,
        getAllTicket: action.tickets.getAllTicket
      };
    case ticketConstants.GET_ALL_TICKET_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case ticketConstants.GETALL_TICKET_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ticketConstants.GETALL_TICKET_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.tickets.listOfNotification.list,
        listOfNotificationtotal: action.tickets.listOfNotification.total
      };
    case ticketConstants.GETALL_TICKET_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case ticketConstants.UPDATE_TICKET_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ticketConstants.UPDATE_TICKET_NOTIFY_SUCCESS:
      return {
        ...state,
      };
    case ticketConstants.UPDATE_TICKET_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case ticketConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ticketConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.tickets.restaurantDetails
      };
    case ticketConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case ticketConstants.UPDATE_TICKET_PASSWORD_REQUEST:
      return {
        ...state
      };
    case ticketConstants.UPDATE_TICKET_PASSWORD_SUCCESS:
      return {
        ...state,
        addTicketSuccess: true
      };
    case ticketConstants.UPDATE_TICKET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case ticketConstants.ADD_RESTAURANT_TICKET_REQUEST:
      return {
        ...state
      };
    case ticketConstants.ADD_RESTAURANT_TICKET_SUCCESS:
      return {
        ...state,
        addTicketSuccess: true
      };
    case ticketConstants.ADD_RESTAURANT_TICKET_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case ticketConstants.ADD_MENU_REQUEST:
      return {
        ...state
      };
    case ticketConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true
      };
    case ticketConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case ticketConstants.ADD_ITEM_REQUEST:
      return {
        ...state
      };
    case ticketConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true
      };
    case ticketConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case ticketConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case ticketConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true
      };
    case ticketConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case ticketConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state
      };
    case ticketConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case ticketConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case ticketConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state, filesDetails: action.uploadImage.filesDetails,
      };

    case ticketConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };


    // case ticketConstants.STATS_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case ticketConstants.STATS_SUCCESS:
    //   return {
    //     ...state,
    //     statics: action.tickets.statics
    //   };
    // case ticketConstants.STATS_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    default:
      return state
  }
}