import React from "react";

import Modal from 'react-modal';


export default function FastrackCreateModal(props) {

  let { fastrackCreateModal, handleFastrackHideModal, errorsFastrack, inputFastrackChange, createFastrackSubmit, fieldsFastrack,  inputTypeChangePackage,  } = props;

  return (

    <Modal
      isOpen={fastrackCreateModal}
      >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
           
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Create Fastrack</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleFastrackHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            
            <div className="my-5">

              <form autoComplete="off">

                
                <div className="mt-8">
                  <div class="relative lg:w-64 w-full border border-gray-300 text-gray-800 bg-white shadow-lg appearance-none cursor-pointer">
                    <label for="frm-whatever" class="sr-only">Bonus Type</label>
                    <select class="appearance-none w-full p-2 bg-white text-sm font-normal"
                      onChange={inputFastrackChange}
                      id="txType"
                      name="txType">
                      <option value="null">Please choose bonus type</option>
                      <option value="FASTRACK">Fastrack Bonus</option>
                      <option value="LIFETIME">LifeTime Bonus</option>

                    </select>
                    <div class="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsFastrack && !errorsFastrack["amount"] ? "border  placeholder-gray-500" : "border border-opacity-100  "}`}
                      id="amount" name="amount" placeholder="Amount" value={fieldsFastrack.amount} type="text" onChange={inputFastrackChange} />
                    {errorsFastrack && errorsFastrack["amount"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsFastrack["amount"]}
                      </div>
                      : null}
                    
                  </div>
                </div>

                
                



                <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={createFastrackSubmit}>Submit</button>
                </div>
              </form>

            </div>
            
          </div>
        </div>
      </div >


    </Modal >



  );
}
