import React, { Component } from 'react';
import { connect } from 'react-redux';
import { kycActions, userActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import { isMobile } from "react-device-detect";
import ImageViewModal from "./components/ImageViewModal/ImageViewModal";
import ViewPendingModal from "./components/ViewPendingModal/ViewPendingModal";
import ViewAddharModal from "./components/ViewAddharModal/ViewAddharModal";
import ViewPendingModalBack from './components/ViewAddharModal/ViewAddharModalBack';
import ViewSelfieModal from "./components/ViewSelfieModal/ViewSelfieModal";
import moment from 'moment'
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaArrowCircleRight } from "react-icons/fa";
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';

class KYCPending extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fieldskyc: {},
			errorskyc: {},
			studentData: {},
			viewImageData: {},
			viewRowData: {},
			viewSelfieData: {},
			viewPanData: {},
			viewAddharData: {},
			offset: 0,
			size: 10,
			page: 1,
			pageNo: 1,
			keyWord: '',
			viewImageCreateModal: false,
			viewMoreDetailsModal: false,
			viewSelfieDetailsModal: false,
			viewPanDetailsModal: false,
			viewAddharDetailsModal: false,
			viewAddharDetailsModalBack: false
		}
	}
	componentDidMount() {
		let temp = {
			"status": "0",
			"keyWord": "",
			"pageNo": this.state.page,
			"size": this.state.size
		}
		this.props.dispatch(kycActions.getKycList(temp));
		this.props.dispatch(cityActions.getAllCity());
	}


	onAcceptKyc = (data) => {
		let paginationData = {
			"status": "0",
			"keyWord": this.state.keyWord,
			"pageNo": this.state.page,
			"size": this.state.size
		}
		let tempdata = {
			"id": data.id,
			// "comment": this.state.keyWord,
			"status": data.statusRes,
		}
		confirmAlert({
			title: data.statusRes && data.statusRes == 1 ? 'Confirm KYC acceptance ?' : data.statusRes && data.statusRes == 2 ? 'Confirm to Reject Kyc?' : null,
			message: data.statusRes && data.statusRes == 1 ? 'Are you sure to accept KYC of  ' + data.userId.fullName : data.statusRes && data.statusRes == 2 ? 'Are you sure to reject KYC of  ' + data.userId.fullName : null,
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.props.dispatch(kycActions.updateStatusKyctwo(tempdata, paginationData))
				},
				{
					label: 'No'
				}
			]
		});
	}
	onAcceptIntrview = (data) => {
		let paginationData = {
			"status": "0",
			"keyWord": this.state.keyWord,
			"pageNo": this.state.page,
			"size": this.state.size
		}
		let tempdata = {
			"id": data.userId.id,
			"interviewStatus": data.statusRes,
		}
		confirmAlert({
			title: 'Are you Sure selecting this Candidate ?',
			message: 'Confirm to Proceed for KYC ' + data.userId.fullName,
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.props.dispatch(userActions.updateInterviewStatus(tempdata, paginationData))
				},
				{
					label: 'No'
				}
			]
		});
	}
	handleSelfieModal = (data) => {
		this.setState({ viewSelfieDetailsModal: true, viewSelfieData: data });
	}
	handleSelfieClose = () => {
		this.setState({ viewSelfieDetailsModal: false, viewSelfieData: {} });
	}
	handleAddharModal = (data) => {
		this.setState({ viewAddharDetailsModal: true, viewAddharData: data });
	}

	handleAddharClose = () => {
		this.setState({ viewAddharDetailsModal: false, viewAddharData: {} });
	}
	handleAddharModalBack = (data) => {
		this.setState({ viewAddharDetailsModalBack: true, viewAddharData: data });
	}
	handleAddharCloseBack = () => {
		this.setState({ viewAddharDetailsModalBack: false, viewAddharData: {} });
	}

	handlePageClick = (data) => {
		console.log("data  ", data);
		let offset = Math.ceil(data.selected * this.state.size);
		this.setState({ offset: offset, page: data.selected + 1 });
		let datatemp = {
			"status": "0",
			"keyWord": this.state.keyWord,
			"pageNo": data.selected + 1,
			"size": this.state.size
		}
		this.props.dispatch(kycActions.getKycList(datatemp));
	}

	onAcceptIntrview1 = (data) => {
		let paginationData = {
			"status": "0",
			"keyWord": this.state.keyWord,
			"pageNo": this.state.page,
			"size": this.state.size
		}
		let tempdata = {
			"id": data.userId.id,
			"interviewStatus": data.statusRes,
		}
		confirmAlert({
			title: 'Are you Sure reject this Candidate ?',
			message: '' + data.userId.fullName,
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.props.dispatch(userActions.updateInterviewStatus(tempdata, paginationData))
				},
				{
					label: 'No'
				}
			]
		});
	}

	approveOpenStatusHandel1 = (data) => {
		this.setState({ approveStatus1: true, elementValue: data })
	}
	approveClodeStatusHandel1 = () => {
		this.setState({ approveStatus1: false, fieldsCoin: {} })
	}

	classReferenceSubmit = () => {
		let temp = {
			"userId": this.state.studentData['userId'] && this.state.studentData['userId'].length > 0 ? this.state.studentData['userId'] : null,
			"cityId": this.state.studentData['cityId'] && this.state.studentData['cityId'].length > 0 ? this.state.studentData['cityId'] : null,
			"status": "0",
			"keyWord": "",
			"pageNo": this.state.page,
			"size": this.state.size
		}
		console.log('temp__________________temp', temp);
		this.props.dispatch(kycActions.getKycList(temp));
	}
	resetDataRes = (data) => {
		this.props.history.push('/app/kycpending')
	}

	handleChangeInputInternal = (event) => {


		console.log("event.value  ", event);


		let output = event && event.length > 0 ? event.map(item => item.value) : [];
		console.log(output);
		console.log('yeezhgs', event);
		const { studentData } = this.state;
		studentData["userId"] = output;
		this.setState({ studentData });

	};
	handleChangeInputCityId = (event) => {


		console.log("event.value  ", event);


		let output = event && event.length > 0 ? event.map(item => item.value) : [];
		console.log(output);
		console.log('yeezhgs', event);
		const { studentData } = this.state;
		studentData["cityId"] = output;
		this.setState({ studentData });

	};

	render() {

		let { kyc, city } = this.props;
		let { items, total, loading, tutorEducationById } = kyc;
		let { getAllCity } = city;

		let NameRes = items && items.length && items.length > 0 ? items.map((ele, index) => ({ value: ele && ele.userId && ele.userId.id, label: ele && ele.userId && ele.userId.fullName + " " + ele && ele.userId && ele.userId.mobNo })) : null

		let cityName = getAllCity && getAllCity.length && getAllCity.length > 0 ? getAllCity.map((ele, index) => ({ value: ele && ele.id, label: ele && ele.name })) : null
		console.log('page____________________', this.state.page);
		return (
			<>
				<div>
					<LoadingOverlay
						active={loading}
						className="temp001"
						spinner
						text='Please wait...' />
				</div>

				<div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
					<main className="flex-1 relative ">
						<div className="2xl:p-10 sm:p-5 p-3">
							<div className="max-w-screen-3xl mx-auto ">
								{/* Transaction Section */}
								<section className="bg-white col-span-6 2xl:py-7 py-5 2xl:px-10 sm:px-5 px-2 flex-col rounded-md">
									<div className="w-full flex flex-wrap  ">
										<div className="ml-0 mr-auto">
											<h3 className="pb-4 md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold text-dark-400 tracking-widest">KYC Pending ({total ? total : 0})</h3>
										</div>
										<div className="relative mt-5 lg:mt-0">
											<div className="absolute top-4 left-5 text-gray-600">
											</div>
										</div>
									</div>
									<div className=" rounded-lg overflow-hidden pb-2 mt-4 shadow-md">
										<div className="overflow-x-auto max-w-full ">
											<div className="inline-block min-w-full  ">
												<div className="overflow-x-auto">
													<table className="min-w-full divide-y divide-gray-800 border-0">

														<thead className="bg-[#fff] rounded-t">
															<tr className=" ">
																<th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
																	<Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
																		<label htmlFor="resourceList" style={{ color: 'black' }}>Tutor name or Number</label>

																		<Select2
																			width="full"
																			isMulti
																			name="internalId"
																			id="internalId"
																			onChange={this.handleChangeInputInternal}
																			options={NameRes}
																			className="basic-multi-select width: 'full'"
																			classNamePrefix="Activities"
																		/>
																	</Grid>
																</th>
																<th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
																	<Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
																		<label htmlFor="resourceList" style={{ color: 'black' }}>City</label>

																		<Select2
																			width="full"
																			isMulti
																			name="internalId"
																			id="internalId"
																			onChange={this.handleChangeInputCityId}
																			options={cityName}
																			className="basic-multi-select width: 'full'"
																			classNamePrefix="Activities"
																		/>
																	</Grid>
																</th>

																<th>
																	<label className='invisible' htmlFor="resourceList" style={{ color: 'black' }}>City</label>
																	<button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.classReferenceSubmit()}> Search </button>
																</th>
																<th>
																	<label className='invisible' htmlFor="resourceList" style={{ color: 'black' }}>City</label>
																	<button className="bg-[#25BF63] hover:bg-[#25BF63] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.resetDataRes()}> Reset </button>
																</th>
															</tr>
														</thead>

														<thead className="bg-[#FBB00C] rounded-t">
															<tr className=" ">
																<th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">Pending KYC Request </th>
																<th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Address</th>
																<th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">City</th>
																<th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Front</th>
																<th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Back</th>
																<th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Selfie</th>
																<th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Interview</th>
																<th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">KYC</th>
															</tr>
														</thead>

														{/* Table Row Section */}
														<tbody>
															{
																items && items.length > 0 ?
																	items.map((element, index) => (<React.Fragment key={element.id}>
																		{element.userId.interviewStatus != 2 ? <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">

																			<td className="px-6 py-3 whitespace-nowrap  text-sm text-gray-600 flex items-center space-x-2">
																				<div className='w-12 h-12 '>
																					<img
																						class="w-12 h-12 rounded-full"
																						src={element && element.imageUserLink}
																						alt=""
																					/>
																				</div>
																				<div>
																					<p>{element && element.userId && element.userId.fullName ? element.userId.fullName : '-'}</p>
																					<p>{element && element.userId && element.userId.mobNo ? element.userId.mobNo : '-'}</p>
																					<p>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD h:mm A")}</p>
																					<div className='flex items-center justify-end' onClick={() => this.approveOpenStatusHandel1(element, 1)}><span className="font-bold">More</span>  <span className='text-[#FBB00C] pl-1'><FaArrowCircleRight size={16} /> </span></div>

																				</div>

																			</td>
																			<td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
																				<p>{element && element.userId && element.userId.address ? element.userId.address : '-'}</p>
																			</td>
																			<td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
																				<p>{element && element.userId && element.userId.city && element.userId.city.name ? element.userId.city.name : '-'}</p>
																			</td>
																			<td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
																				<img className="w-20 h-10 mx-auto" src="images/id-card.png" alt=" " />
																				<p className='text-center flex justify-center items-center' onClick={() => this.handleAddharModal(element)}><MdOutlineRemoveRedEye /></p>
																			</td>
																			<td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
																				<img className="w-20 h-10 mx-auto" src="images/id-card.png" alt=" " />
																				<p className='text-center flex justify-center items-center' onClick={() => this.handleAddharModalBack(element)}><MdOutlineRemoveRedEye /></p>
																			</td>
																			<td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
																				<img className="w-20 h-10 mx-auto" src="images/id-card.png" alt=" " />
																				<p className='text-center flex justify-center items-center' onClick={() => this.handleSelfieModal(element)}><MdOutlineRemoveRedEye /></p>
																			</td>
																			{
																				element.userId.interviewStatus == 1 ?
																					< td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 pace-y-1">
																						< div >
																							<button type="button" className='bg-[#25BF63] px-2 py-1 w-20 text-white font-bold rounded-full' >Accepted</button>
																						</div>
																					</td>
																					:
																					element.userId.interviewStatus == 2 ?
																						< td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 pace-y-1">
																							< div >
																								<button type="button" className='bg-[#FC0000] px-2 py-1 w-20 text-white font-bold rounded-full' >Rejected</button>
																							</div>
																						</td>
																						:
																						<td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-1">
																							<div>
																								<button type="button" className='bg-[#25BF63] px-2 py-1 w-20 text-white font-bold rounded-full' onClick={() => this.onAcceptIntrview({ ...element, statusRes: 1 })}>Accept</button>
																							</div>
																							<div>
																								<button type="button" className='bg-[#FC0000] px-2 py-1 w-20 text-white font-bold rounded-full' onClick={() => this.onAcceptIntrview1({ ...element, statusRes: 2 })}>Reject</button>
																							</div>
																						</td>
																			}

																			<td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-1">
																				{element.userId.interviewStatus == 1 &&
																					<>
																						<div>
																							<button type="button" className='bg-[#25BF63] px-2 py-1 w-20 text-white font-bold rounded-full' onClick={() => this.onAcceptKyc({ ...element, statusRes: 1 })}>Accept</button>
																						</div>
																						<div>
																							<button type="button" className='bg-[#FC0000] px-2 py-1 w-20 text-white font-bold rounded-full' onClick={() => this.onAcceptKyc({ ...element, statusRes: 2 })}>Reject</button>
																						</div>
																					</>
																				}
																			</td>




																		</tr>
																			: null}
																	</React.Fragment>))
																	: (<tr className="bg-white bg-opacity-5 " >
																		<td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
																	</tr>)
															}
														</tbody>
													</table>
												</div>
											</div>
										</div>


										{this.state.approveStatus1 ?
											<div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
												<div className="border border-yellow-500 modal-container bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/3 mx-auto rounded-[40px] shadow-lg z-50 overflow-y-auto">
													<div className="modal-content ">
														<div className="flex justify-between items-center modal-close cursor-pointer z-50 py-2 px-6 bg-[#FBB00C]">
															<p className='font-bold text-md'>More</p>
															<div className='bg-white rounded-full p-1'>
																<svg
																	onClick={() => this.approveClodeStatusHandel1()}
																	className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
																	<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
																	</path>
																</svg>
															</div>
														</div>
														<div className='py-4 px-6 h-[300px] overflow-y-auto divide-y-2'>
															<div className='flex justify-between items-center w-full'>
																<p className='text-lg font-bold'>Email : </p>
																<p>{this.state.elementValue && this.state.elementValue.userId && this.state.elementValue.userId.email ? this.state.elementValue.userId.email : 'NA'}</p>
															</div>
															<div className='flex justify-between items-center w-full'>
																<p className='text-lg font-bold'>DOB : </p>
																<p>{this.state.elementValue && this.state.elementValue.userId && this.state.elementValue.userId.dob ? this.state.elementValue.userId.dob : 'NA'}</p>
															</div>
															<div className='flex justify-between items-center w-full'>
																<p className='text-lg font-bold'>Gender : </p>
																<p>{this.state.elementValue && this.state.elementValue.userId && this.state.elementValue.userId.gender ? this.state.elementValue.userId.gender : 'NA'}</p>
															</div>
															<div className='flex justify-between items-center w-full'>
																<p className='text-lg font-bold'>School : </p>
																<p>{this.state.elementValue && this.state.elementValue.userId && this.state.elementValue.userId.tutorInfoId && this.state.elementValue.userId.tutorInfoId.schoolName ? this.state.elementValue.userId.tutorInfoId.schoolName : 'NA'}</p>
															</div>
															<div className='flex justify-between items-center w-full'>
																<p className='text-lg font-bold'>Graduation : </p>
																<p>{this.state.elementValue && this.state.elementValue.userId && this.state.elementValue.userId.tutorInfoId && this.state.elementValue.userId.tutorInfoId.ugCourse ? this.state.elementValue.userId.tutorInfoId.ugCourse : 'NA'}</p>
															</div>
															<div className='flex justify-between items-center w-full'>
																<p className='text-lg font-bold'>Post Graduation : </p>
																<p>{this.state.elementValue && this.state.elementValue.userId && this.state.elementValue.userId.tutorInfoId && this.state.elementValue.userId.tutorInfoId.pgCourse ? this.state.elementValue.userId.tutorInfoId.pgCourse : 'NA'}</p>
															</div>
															<div className='flex justify-between items-start w-full'>
																<div className="w-full">
																	<p className='text-lg font-bold'>Teaching Mode :</p>
																</div>
																<div className="w-full">
																	<ul className="text-right">
																		{
																			this.state.elementValue &&
																				this.state.elementValue.userId &&
																				this.state.elementValue.userId.tutorInfoId &&
																				this.state.elementValue.userId.tutorInfoId.teachingMode &&
																				this.state.elementValue.userId.tutorInfoId.teachingMode.length > 0 ? (
																				this.state.elementValue.userId.tutorInfoId.teachingMode.map((ele) => (
																					<li key={ele}>{ele ? ele : '-'}</li>
																				))
																			) : (
																				null
																			)}
																	</ul>
																</div>
															</div>
															<div className='flex justify-between items-start w-full'>
																<div className="w-full">
																	<p className='text-lg font-bold'>Class Teaches :</p>
																</div>
																<div className="w-full">
																	<ul className="text-right">
																		{
																			this.state.elementValue &&
																				this.state.elementValue.userId &&
																				this.state.elementValue.userId.tutorInfoId &&
																				this.state.elementValue.userId.tutorInfoId.teachingClasses &&
																				this.state.elementValue.userId.tutorInfoId.teachingClasses.length > 0 ? (
																				this.state.elementValue.userId.tutorInfoId.teachingClasses.map((ele) => (
																					<li key={ele.id}>{ele && ele.name ? ele.name : '-'}</li>
																				))
																			) : (
																				null
																			)}
																	</ul>
																</div>
															</div>
															<div className='flex justify-between items-start w-full'>
																<div className="w-full">
																	<p className='text-lg font-bold'>Subject Teaches :</p>
																</div>
																<div className="w-full">
																	<ul className="text-right">
																		{
																			this.state.elementValue &&
																				this.state.elementValue.userId &&
																				this.state.elementValue.userId.tutorInfoId &&
																				this.state.elementValue.userId.tutorInfoId.teachingCourse &&
																				this.state.elementValue.userId.tutorInfoId.teachingCourse.length > 0 ? (
																				this.state.elementValue.userId.tutorInfoId.teachingCourse.map((ele) => (
																					<li key={ele.id}>{ele && ele.name ? ele.name : '-'}</li>
																				))
																			) : (
																				null
																			)}
																	</ul>
																</div>
															</div>
															<div className='flex justify-between items-start w-full'>
																<div className="w-full">
																	<p className='text-lg font-bold'>Teaching Location PinCode :</p>
																</div>
																<div className="w-full">
																	<ul className="text-right">
																		{
																			this.state.elementValue &&
																				this.state.elementValue.userId &&
																				this.state.elementValue.userId.tutorInfoId &&
																				this.state.elementValue.userId.tutorInfoId.teachPincode &&
																				this.state.elementValue.userId.tutorInfoId.teachPincode.length > 0 ? (
																				this.state.elementValue.userId.tutorInfoId.teachPincode.map((ele) => (
																					<li key={ele.id}>{ele && ele.pinNo ? ele.pinNo : '-'}</li>
																				))
																			) : (
																				null
																			)}
																	</ul>
																</div>
															</div>
														</div>

													</div>
												</div>
											</div>
											:
											null
										}


										{/* Table Pagination Section */}
										{
											isMobile ?
												<nav className="relative z-0 flex justify-end mt-5 w-76">
													{
														total && total > 10 ?
															<ReactPaginate
																previousLabel={'Prev'}
																nextLabel={'Next'}
																breakLabel={'...'}
																breakClassName={'break-me'}
																pageCount={total / this.state.size}
																marginPagesDisplayed={1}
																pageRangeDisplayed={1}
																onPageChange={this.handlePageClick}
																containerClassName={'pagination'}
																pageClassName={'page-cls'}
																activeClassName={'active'}
															/>
															: null}
												</nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
													{
														total && total > 10 ?
															<ReactPaginate
																previousLabel={'Previous'}
																nextLabel={'Next'}
																breakLabel={'...'}
																breakClassName={'break-me'}
																pageCount={total / this.state.size}
																marginPagesDisplayed={3}
																pageRangeDisplayed={3}
																onPageChange={this.handlePageClick}
																containerClassName={'pagination'}
																pageClassName={'page-cls'}
																activeClassName={'active'}
															/>
															: null}
												</nav>
										}
									</div>
								</section>
							</div>
						</div >
					</main >
				</div >

				<DialogExample />



				<ViewAddharModal
					viewAddharDetailsModal={this.state.viewAddharDetailsModal}
					viewAddharData={this.state.viewAddharData}
					handleAddharClose={this.handleAddharClose}
				/>
				<ViewPendingModalBack
					viewAddharDetailsModalBack={this.state.viewAddharDetailsModalBack}
					viewAddharData={this.state.viewAddharData}
					handleAddharClose={this.handleAddharCloseBack}
				/>


				<ViewSelfieModal
					viewSelfieDetailsModal={this.state.viewSelfieDetailsModal}
					viewSelfieData={this.state.viewSelfieData}
					handleSelfieClose={this.handleSelfieClose}

				/>

			</>
		);
	}
}
function mapStateToProps(state) {
	const { users, authentication, kyc, city } = state;
	return {
		users,
		kyc,
		city,
		authentication
	};
}
export default connect(mapStateToProps)(KYCPending);