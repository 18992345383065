export const supportConstants = {


    GET_TICKET_LIST_REQUEST: 'GET_TICKET_LIST_REQUEST',
    GET_TICKET_LIST_SUCCESS: 'GET_TICKET_LIST_SUCCESS',
    GET_TICKET_LIST_FAILURE: 'GET_TICKET_LIST_FAILURE',

    STATUS_TICKET_REQUEST: 'STATUS_TICKET_REQUEST',
    STATUS_TICKET_SUCCESS: 'STATUS_TICKET_SUCCESS',
    STATUS_TICKET_FAILURE: 'STATUS_TICKET_FAILURE',

    UPDATE_TICKET_STATUS_REQUEST: 'UPDATE_TICKET_STATUS_REQUEST',
    UPDATE_TICKET_STATUS_SUCCESS: 'UPDATE_TICKET_STATUS_SUCCESS',
    UPDATE_TICKET_STATUS_FAILURE: 'UPDATE_TICKET_STATUS_FAILURE',

    MESSAGE_LIST_TICKET_REQUEST: 'MESSAGE_LIST_TICKET_REQUEST',
    MESSAGE_LIST_TICKET_SUCCESS: 'MESSAGE_LIST_TICKET_SUCCESS',
    MESSAGE_LIST_TICKET_FAILURE: 'MESSAGE_LIST_TICKET_FAILURE',
};
