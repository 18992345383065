
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';

export const bannerServices = {
   getBannerList,
   createBanner,
   updateBanner,
   updateBannerStatus,
   deleteBanner
};
function logout() {
    window.sessionStorage.removeItem('adminuser');
    history.push(`#/login`);
    window.location.reload();


}


async function getBannerList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getBannerList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getBannerList: data.data
            }
            console.log("i am in service getAllCoin", userObj);

            return userObj;
        });
}


async function deleteBanner(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteBanner`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteBanner: data.data
            }
            console.log("i am in service''...>> deleteBanner ::", userObj);

            return userObj;
        });
}



// function disableCoin(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateBannerStatus`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 updateBannerStatus: data.data
//             }
//             console.log("i am in service''...>> updateCoinStatus ::", userObj);

//             return userObj;
//         });
// }

async function updateBannerStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBannerStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateBannerStatus: data.data
            }
            console.log("i am in service''...>> updateBannerStatus ::", userObj);

            return userObj;
        });
}

async function updateBanner(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBanner`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateBanner: data.data
            }
            console.log("I am in service updateBanner", userObj);

            return userObj;
        });
}

async function createBanner(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createBanner`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createBanner: data.data
            }
            return userObj;
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}