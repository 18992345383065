import React from "react";
import { CgLogIn } from 'react-icons/cg';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';

export default function CreatePackageModal(props) {

  let { handlePackageHideModal, inputChange,
    PinCodeCreateModal,
    fieldsPinCode,
    errorsPinCode,
    PinCodeubmit, getAllCity, setCurrentIndex, addContentField, deleteContentField, inputAddUserChangeContent,
    fieldsclassManagement, errorsclassManagement
  } = props;
  return (

    <div className={PinCodeCreateModal ? "fixed w-full top-0 left-0 h-full inset-0 z-50 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto mt-2">
        <div className="flex items-center justify-between p-6 py-2 bg-[#FBB00C] border-b">
          <p className="text-[16px] font-bold text-white capitalize">Add Pincode</p>
          <div className="rounded-full cursor-pointer modal-close bg-white p-1">
            <svg onClick={() => handlePackageHideModal()} className="fill-current text-black " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>

        <div className="p-4">
          <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">
            <div className="w-full space-y-2">
              <label className="block text-sm font-bold text-gray-500 md:text-left" for="username">City:</label>
              <select class="form-select border-1 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputChange} id="cityId" name="cityId"
                value={fieldsPinCode && fieldsPinCode["cityId"] ? fieldsPinCode["cityId"] : ''} >
                <option selected>Please Select City</option>
                {
                  getAllCity && getAllCity && getAllCity.length > 0 ?
                    getAllCity.map((element, index) => (
                      <option value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                    )) : null
                }
              </select>
              {errorsPinCode && errorsPinCode["cityId"] ?
                <div className="text-xs text-red-600 invalid-feedback">
                  {errorsPinCode["cityId"]}
                </div>
                : null}
            </div>

            <div className="{otpSent?'disableArea':''}">


              <div className="grid grid-cols-2 gap-4">
                {
                  fieldsclassManagement && fieldsclassManagement.options && fieldsclassManagement.options.length > 0 ?
                    fieldsclassManagement.options.map((item, index) => (
                      <>
                        <div className="relative mt-1 shadow-sm">
                          <div className="flex justify-between">
                            <label class="block text-xs font-medium text-gray-500 md:text-left" for="username">pinCode {index + 1} :</label>
                            <div>
                              {index === 0 ?
                                <span className='flex justify-end' onClick={() => addContentField(index)}><MdAddCircleOutline size={22} className="text-gray-600 cursor-pointer hover:text-blue-500" /></span> :
                                <>
                                  <div className="flex justify-end space-x-3">
                                    <span className='flex justify-end' onClick={() => deleteContentField(index)}><MdRemoveCircleOutline size={22} className="text-red-500 cursor-pointer hover:text-red-700" /></span>
                                  </div>
                                </>
                              }
                            </div>
                          </div>
                          <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 ${errorsclassManagement && !errorsclassManagement["pinNo"] ? "border  placeholder-gray-500" : "border  border-red-500"}`}
                            id="pinNo" name="pinNo" placeholder="PinCode"
                            onClick={() => setCurrentIndex(index)}
                            type="number" value={item && item.pinNo ? item.pinNo : ''}
                            onChange={(e) => inputAddUserChangeContent(e, index)} />
                        </div>
                      </>
                    )) : null
                }
              </div>



            </div>





            <div className="flex justify-end py-0 space-x-4">
              <button className="bg-[#FBB00C]  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center font-bold " type="button"
                onClick={PinCodeubmit}
              >Submit
                <CgLogIn size={18} className="ml-2" />
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>




  );
}
