import React from "react";

import Modal from 'react-modal';
const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};

export default function ViewPendingModal(props) {

  let { viewPanDetailsModal, handlePanClose, viewPanData,
  } = props;
  console.log("viewPanDataModal::", viewPanData);

  return (

    <Modal
      isOpen={viewPanDetailsModal}
      style={customStyles}
      ariaHideApp={false}
    // contentLabel="Example Modal"
    >


      {viewPanData ?
        <div className="main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" >
          <div className="border border-teal-500 modal-container bg-white w-11/12 md:max-w-4xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">

              <div className="flex justify-between items-center">
                <p className="text-2xl font-bold">View Pan Details</p>
                <div className="modal-close cursor-pointer z-50">
                  <svg onClick={() => handlePanClose()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                    </path>
                  </svg>
                </div>
              </div>

              <div className="my-2">
                <form autoComplete="off">
                  <>
                    {
                      viewPanData && viewPanData.length > 0 ?
                        viewPanData.map((element, index) => (<React.Fragment >
                          <>
                            {
                              element.docTypeId && element.docTypeId.slug && element.docTypeId.slug === 1 ?
                                <div className="grid grid-rows-1 grid-flow-col">
                                  <div className="justify-center lg:justify-start text-white p-4 bg-shine rounded-md items-start mt-2 w-full ">
                                    <div className=" text-center lg:text-left ">
                                      <h4 className="2xl:text-xl lg:text-lg font-semibold tracking-widest">DOCUMENT NAME : {element && element.docTypeId && element.docTypeId.name ? element.docTypeId.name : "-"}</h4>
                                      <h4 className="2xl:text-xl lg:text-lg font-semibold tracking-widest">DOCUMENT NO. :{element && element.docNumber ? element.docNumber : "-"} </h4>
                                    </div>
                                    <div className="space-x-4 mt-4">
                                      <div className="space-y-2" >
                                        <label className="">IMAGE FRONT</label>
                                        <img className="w-96 h-64" src={element && element.imageLink ? element.imageLink : "-"} alt=" " />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                null
                            }

                          </>
                        </React.Fragment>))
                        : null
                    }

                  </>

                </form>

              </div>
            </div>
          </div>
        </div>
        : null
      }


    </Modal>



  );
}
