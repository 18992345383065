import { feesettingConstants } from '../_constants';

export function feesetting(state = {}, action) {

  switch (action.type) {

    case feesettingConstants.UPDATES_FEE_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case feesettingConstants.UPDATES_FEE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        addFeeSettingSuccess: true

      };
    case feesettingConstants.UPDATES_FEE_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case feesettingConstants.DISABLE_FEE_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case feesettingConstants.DISABLE_FEE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        addFeeSettingSuccess: true
      };
    case feesettingConstants.DISABLE_FEE_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case feesettingConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case feesettingConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        addFeeSettingSuccess: false,
        updateFeeSettingSuccess: false,
        items: action.keyfeatures.getFeeSettingList.list,
        total: action.keyfeatures.getFeeSettingList.total
      };
    case feesettingConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case feesettingConstants.GET_ALL_APPSETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case feesettingConstants.GET_ALL_APPSETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllFeeSetting: action.keyfeatures.getAllFeeSetting
      };
    case feesettingConstants.GET_ALL_APPSETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case feesettingConstants.ADD_FEE_SETTING_REQUEST:
      return {
        ...state
      };
    case feesettingConstants.ADD_FEE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        addFeeSettingSuccess: true
      };
    case feesettingConstants.ADD_FEE_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case feesettingConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        filesDetails: action.uploadImage.filesDetails,
      };

    case feesettingConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };


    default:
      return state
  }
}