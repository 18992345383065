import {
  holidayConstants,
} from '../_constants';

export function holiday(state = {}, action) {

  switch (action.type) {
    case holidayConstants.ADD_HOLIDAY_REQUEST:
      return {
        ...state
      };
    case holidayConstants.ADD_HOLIDAY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case holidayConstants.ADD_HOLIDAY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case holidayConstants.GET_LIST_HOLIDAY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case holidayConstants.GET_LIST_HOLIDAY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getHolidaysList.list,
        total: action.users.getHolidaysList.total
      };
    case holidayConstants.GET_LIST_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case holidayConstants.GETALL_HOLIDAY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case holidayConstants.GETALL_HOLIDAY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllHolidays: action.users.getAllHolidays,
        total: action.users.getAllHolidays
      };
    case holidayConstants.GETALL_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case holidayConstants.UPDATE_HOLIDAY_REQUEST:
      return {
        ...state
      };
    case holidayConstants.UPDATE_HOLIDAY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case holidayConstants.UPDATE_HOLIDAY_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case holidayConstants.DELETE_HOLIDAY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case holidayConstants.DELETE_HOLIDAY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case holidayConstants.DELETE_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case holidayConstants.DISABLE_HOLIDAY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case holidayConstants.DISABLE_HOLIDAY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case holidayConstants.DISABLE_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    default:
      return state
  }
}