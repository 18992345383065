import {
  cmcCmcCoinConstants
} from '../_constants';

export function cmcCmcCoin(state = {}, action) {

  switch (action.type) {
    // case cmcCmcCoinConstants.DEPOSIT_USER_BALANCE_REQUEST:
    //   return {
    //     ...state
    //   };
    // case cmcCmcCoinConstants.DEPOSIT_USER_BALANCE_SUCCESS:
    //   return {
    //     ...state,
    //     addUserSuccess: true
    //   };
    // case cmcCmcCoinConstants.DEPOSIT_USER_BALANCE_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };
    case cmcCmcCoinConstants.ADD_CMC_COIN_REQUEST:
      return {
        ...state
      };
    case cmcCmcCoinConstants.ADD_CMC_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case cmcCmcCoinConstants.ADD_CMC_COIN_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case cmcCmcCoinConstants.GET_LIST_CMC_COIN_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case cmcCmcCoinConstants.GET_LIST_CMC_COIN_SUCCESS:
      return {
        ...state,
       
        loading: false,
        items: action.users.getCmcCoinList.list,
        total: action.users.getCmcCoinList.total
      };
    case cmcCmcCoinConstants.GET_LIST_CMC_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cmcCmcCoinConstants.GETALL_CMC_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cmcCmcCoinConstants.GETALL_CMC_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getAllCmcCoin,
        total: action.users.getAllCmcCoin
      };
    case cmcCmcCoinConstants.GETALL_CMC_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case cmcCmcCoinConstants.UPDATE_CMC_COIN_REQUEST:
      return {
        ...state
      };
    case cmcCmcCoinConstants.UPDATE_CMC_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case cmcCmcCoinConstants.UPDATE_CMC_COIN_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case cmcCmcCoinConstants.DELETE_CMC_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cmcCmcCoinConstants.DELETE_CMC_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case cmcCmcCoinConstants.DELETE_CMC_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cmcCmcCoinConstants.DISABLE_CMC_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cmcCmcCoinConstants.DISABLE_CMC_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case cmcCmcCoinConstants.DISABLE_CMC_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}