import React from "react";

import Modal from 'react-modal';
import {
  // Dialog,
  // AppBar,
  // Toolbar,
  // IconButton,
  // Container,
  // CssBaseline,
  // Grid,
  Typography,
  Box
} from "@material-ui/core";
const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};

export default function ViewMoreDetailsModal(props) {

  let { moreDetailsCreateModal, handleMoreDetailsHideModal, viewRowData,
    //  errorsappsetting, inputChange, appSettingSubmit
  } = props;
  //console.log("userCreateModal  ",userCreateModal);

  return (

    <Modal
      isOpen={moreDetailsCreateModal}
      style={customStyles}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" /* style={{ background: 'rgba(0,0,0,.7)' }} */>
        <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">More Details</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleMoreDetailsHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div >
              <Typography style={{ fontSize: "17px" }} component="h1" variant="h5">
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}><b>userName  : </b>{viewRowData.userName}</Box>
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}><b>EMAIL  : </b>{viewRowData.email}</Box>
                <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}><b>REF_CODE  : </b>{viewRowData.refCode}</Box>
                {/* <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}><b>KYC  : </b>{viewRowData.kyc}</Box> */}
                {/* <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}><b>REF_BY_CODE  :</b>{viewRowData.refByCode?viewRowData.refByCode:"  -"}</Box> */}
                {/* <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}><b>JOB_DETAILS :</b> {viewRowData.job_details}</Box>
            <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}><b>JOB_ID :</b> {viewRowData.job_id}</Box> <br />

            <Box className="pl-3 fw-normal"><b>CITY :</b> {viewRowData.location_id && viewRowData.location_id.city ? viewRowData.location_id.city : '-'}</Box>
            <Box className="pl-3 fw-normal"><b>LINE1 :</b> {viewRowData.location_id && viewRowData.location_id.line1 ? viewRowData.location_id.line1 : '-'}</Box>
            <Box className="pl-3 fw-normal"><b>LINE2 :</b> {viewRowData.location_id && viewRowData.location_id.line2 ? viewRowData.location_id.line2 : '-'}</Box>
            <Box className="pl-3 fw-normal"><b>LINE3 :</b> {viewRowData.location_id && viewRowData.location_id.line3 ? viewRowData.location_id.line3 : '-'}</Box> <br />

            <Box className="pl-3 fw-normal"><b>LANDLORD_FIRST_NAME :</b> {viewRowData.created_by && viewRowData.created_by.first_name ? viewRowData.created_by.first_name : '-'}</Box>
            <Box className="pl-3 fw-normal"><b>LANDLORD_LAST_NAME :</b> {viewRowData.created_by && viewRowData.created_by.last_name ? viewRowData.created_by.last_name : '-'}</Box>
            <Box className="pl-3 fw-normal"><b>EMAIL :</b> {viewRowData.created_by && viewRowData.created_by.email ? viewRowData.created_by.email : '-'}</Box>
            <Box className="pl-3 fw-normal"><b>ADDRESS :</b> {viewRowData.created_by && viewRowData.created_by.address ? viewRowData.created_by.address : '-'}</Box><br />

            <Box className="pl-3 fw-normal"><b>WORKER_FIRST_NAME :</b> {viewRowData.assign_to && viewRowData.assign_to.first_name ? viewRowData.assign_to.first_name : '-'}</Box>
            <Box className="pl-3 fw-normal"><b>WORKER_LAST_NAME :</b> {viewRowData.assign_to && viewRowData.assign_to.last_name ? viewRowData.assign_to.last_name : '-'}</Box>
            <Box className="pl-3 fw-normal"><b>EMAIL :</b> {viewRowData.assign_to && viewRowData.assign_to.email ? viewRowData.assign_to.email : '-'}</Box>
            <Box className="pl-3 fw-normal"><b>CONTACT :</b> {viewRowData.assign_to && viewRowData.assign_to.contact ? viewRowData.assign_to.contact : '-'}</Box><br /><br /> */}
              </Typography>
            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
