import { userConstants } from '../_constants';
import { bannerServices } from '../_services';
import { alertActions } from '.';
import { bannerConstants } from '../_constants/banner.constants';
// import { toast } from 'react-toastify';
export const bannerActions = {
    logout,
    getBannerList,
    createBanner,
    deleteBanner,
    updateBanner,
    updateBannerStatus
};
// function login(data, props) {
//     return dispatch => {
//         dispatch(request({ data }));
//         userService.login(data)
//             .then(
//                 user => {
//                     dispatch(success(user));
//                     props.history.push({ pathname: 'app/dashboard' });
//                 },
//                 error => {
//                     console.log("errorerror ", error);
//                     dispatch(failure(error));
//                     dispatch(alertActions.error(error));
//                 }
//             );
//     };

//     function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
//     function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
//     function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
// }
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

// function uploadImageClear() {
//     return dispatch => {
//         dispatch(success());
//     };
//     function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
// }



function createBanner(data) {
    const reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        bannerServices.createBanner(data)
            .then(

                users => {
                    dispatch(alertActions.success("Banner Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());
                    dispatch(this.getBannerList(reqData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: bannerConstants.ADD_BANNER_REQUEST } }
    function success(users) { return { type: bannerConstants.ADD_BANNER_SUCCESS, users } }
    function failure(error) { return { type: bannerConstants.ADD_BANNER_FAILURE, error } }
}
function updateBanner(data, pagination) {

    return dispatch => {
        dispatch(request());
        bannerServices.updateBanner(data)
            .then(

                users => {
                    dispatch(this.getBannerList(pagination))
                    dispatch(alertActions.success("Banner Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: bannerConstants.UPDATE_BANNER_REQUEST } }
    function success(users) { return { type: bannerConstants.UPDATE_BANNER_SUCCESS, users } }
    function failure(error) { return { type: bannerConstants.UPDATE_BANNER_FAILURE, error } }
}
function getBannerList(data) {
    return dispatch => {
        dispatch(request());
        bannerServices.getBannerList(data)
            .then(
                users => {
                    console.log("getBannerList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: bannerConstants.GET_ALL_BANNER_REQUEST } }
    function success(users) { return { type: bannerConstants.GET_ALL_BANNER_SUCCESS, users } }
    function failure(error) { return { type: bannerConstants.GET_ALL_BANNER_FAILURE, error } }
}
function deleteBanner(data, reqData) {
    return dispatch => {
        dispatch(request());
        bannerServices.deleteBanner(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Delete Successfully"))
                    dispatch(this.getBannerList(reqData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: bannerConstants.DELETE_BANNER_REQUEST } }
    function success(users) { return { type: bannerConstants.DELETE_BANNER_SUCCESS, users } }
    function failure(error) { return { type: bannerConstants.DELETE_BANNER_FAILURE, error } }
}
function updateBannerStatus(data, paginationData) {
    return dispatch => {
        dispatch(request());
        bannerServices.updateBannerStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getBannerList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: bannerConstants.UPDATE_BANNER_STATUS_REQUEST } }
    function success(users) { return { type: bannerConstants.UPDATE_BANNER_STATUS_SUCCESS, users } }
    function failure(error) { return { type: bannerConstants.UPDATE_BANNER_STATUS_FAILURE, error } }
}
