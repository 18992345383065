import React, { Component } from 'react';
import { connect } from 'react-redux';
import { kycActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";

class WithdrawManagement extends Component {
  constructor(props) {
    super(props);
    // this.kycSubmit = this.kycSubmit.bind(this);
    // this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldskyc: {},
      errorskyc: {},
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
    }
  }
  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(kycActions.getKycList(temp));
    // this.props.dispatch(userActions.getKYC());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.isKYCCreated) {
      return {
        ...nextProps,
        fieldskyc: {},
        errorskyc: {},

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }


  handleFile = (event) => {

  }

  handleFileSelfie = (event) => {

  }

  render() {

    let { kyc } = this.props;
    let { items, total } = kyc;

    return (
      <>

        <div >
          <LoadingOverlay
            active={false}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {/* Main Content */}
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" >
          <div className="2xl:p-10 p-5">
            <div className="max-w-screen-3xl mx-auto">
              {/* Wallet Section */}
              <section className="grid grid-cols-12 gap-5 ">
                <div className="bg-white xl:col-span-12  lg:col-span-12 col-span-12 2xl:py-7 py-5 2xl:px-10 px-5 flex-col rounded-md">
                  <div className="w-full ">
                    <h3 className="pb-4 md:text-2xl text-lg leading-9 font-semibold capitalize text-black text-opacity-70 tracking-widest">
                    Maintinance Transactions history</h3>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full border-0">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">#</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Transaction Type</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Amount</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Coin</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Description</th>

                              </tr>
                            </thead>
                            <tbody>
                              {/* Odd row */}
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">
                                        {this.state.offset + index + 1}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.txType}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.amount}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.coin}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.status && element.status === true ? "Success" : "Pending"}</td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <nav className="relative z-0 flex justify-end mt-5">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>


                  </div>

                </div>
              </section>
            </div>
          </div>
        </main>

        <DialogExample />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, kyc } = state;
  return {
    users,
    kyc,
    authentication
  };
}
export default connect(mapStateToProps)(WithdrawManagement);
