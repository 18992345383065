import React from "react";
import { CgLogIn } from 'react-icons/cg';

export default function CreateTutionModal(props) {

  let { handletutionHideModal, inputChange,
    tutionCreateModal,
    fieldstution,
    errorstution,
    tutionubmit, getAllStudentId, getAllTutorId, getAllteacherCouresId, companyShare, getAllClass, boardItems
  } = props;



  return (

    <div className={tutionCreateModal ? "fixed w-full top-0 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto mt-2">
        {/*modal header*/}
        <div className="flex items-center justify-between p-6 py-2 bg-[#6F5617] border-b">
          <p className="text-[16px] font-medium text-white capitalize">Add Tuition</p>
          <div className="rounded-full cursor-pointer modal-close ">
            <svg onClick={() => handletutionHideModal()} className="fill-current text-white/60 hover:text-white/100 " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>

        <div className="p-4">
          <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">

            <div className="grid grid-cols-2 gap-4">
              <div className="w-full space-y-2">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Tutor:</label>
                <select class="form-select border-1 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputChange} id="tutorId" name="tutorId"
                  value={fieldstution && fieldstution["tutorId"] ? fieldstution["tutorId"] : null} >
                  <option selected>Please Select Tutor</option>
                  {
                    getAllTutorId && getAllTutorId && getAllTutorId.length > 0 ?
                      getAllTutorId.map((element, index) => (
                        <option value={element && element.id ? element.id : null}>{element && element.fullName ? element.fullName : "NA"}</option>
                      )) : null
                  }
                </select>
                {errorstution && errorstution["tutorId"] ?
                  <div className="text-xs text-red-600 invalid-feedback">
                    {errorstution["tutorId"]}
                  </div>
                  : null}
              </div>

              <div className="w-full space-y-2">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Student:</label>
                <select class="form-select border-1 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputChange} id="studentId" name="studentId"
                  value={fieldstution && fieldstution["studentId"] ? fieldstution["studentId"] : null} >
                  <option selected>Please Select Student</option>
                  {
                    getAllStudentId && getAllStudentId && getAllStudentId.length > 0 ?
                      getAllStudentId.map((element, index) => (
                        <option value={element && element.id ? element.id : null}>{element && element.firstName ? element.firstName : "NA"}</option>
                      )) : null
                  }
                </select>
                {errorstution && errorstution["studentId"] ?
                  <div className="text-xs text-red-600 invalid-feedback">
                    {errorstution["studentId"]}
                  </div>
                  : null}
              </div>

              <div className="w-full space-y-2">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Course:</label>
                <select class="form-select border-1 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputChange} id="teacherCouresId" name="teacherCouresId"
                  value={fieldstution && fieldstution["teacherCouresId"] ? fieldstution["teacherCouresId"] : null} >
                  <option selected>Please Select Course</option>
                  {
                    getAllteacherCouresId && getAllteacherCouresId && getAllteacherCouresId.length > 0 ?
                      getAllteacherCouresId.map((element, index) => (
                        <option value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                      )) : null
                  }
                </select>
                {errorstution && errorstution["teacherCouresId"] ?
                  <div className="text-xs text-red-600 invalid-feedback">
                    {errorstution["teacherCouresId"]}
                  </div>
                  : null}
              </div>
              <div className="w-full space-y-2">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Class:</label>
                <select class="form-select border-1 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputChange} id="classId" name="classId"
                  value={fieldstution && fieldstution["classId"] ? fieldstution["classId"] : null} >
                  <option selected>Please Select Class</option>
                  {
                    getAllClass && getAllClass && getAllClass.length > 0 ?
                      getAllClass.map((element, index) => (
                        <option value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                      )) : null
                  }
                </select>
                {errorstution && errorstution["classId"] ?
                  <div className="text-xs text-red-600 invalid-feedback">
                    {errorstution["classId"]}
                  </div>
                  : null}
              </div>

              <div className="w-full space-y-2">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Board:</label>
                <select class="form-select border-1 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputChange} id="board" name="board"
                  value={fieldstution && fieldstution["board"] ? fieldstution["board"] : null} >
                  <option selected>Please Select Class</option>
                  {
                    boardItems && boardItems && boardItems.length > 0 ?
                      boardItems.map((element, index) => (
                        <option value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                      )) : null
                  }
                </select>
                {errorstution && errorstution["board"] ?
                  <div className="text-xs text-red-600 invalid-feedback">
                    {errorstution["board"]}
                  </div>
                  : null}
              </div>

              <div className="w-full p-2">
                <div className="relative">
                  <label for="frm-whatever" className='text-sm leading-7 text-gray-600'>Tuition mord</label>
                  <select className="w-full bg-gray-100  rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 p-2.5 focus:ring-indigo-200 text-base outline-none text-gray-700  leading-8 transition-colors duration-200 ease-in-out" onChange={inputChange}
                    id="tuitionMord"
                    value={fieldstution && fieldstution.tuitionMord ? fieldstution.tuitionMord : ''}
                    name="tuitionMord">
                    <option value="">Please choose&hellip;</option>
                    <option value="Home Tuition">Home Tuition</option>
                    <option value="Online Tuition">Online Tuition</option>
                  </select>
                </div>
              </div>



              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Payment mode : </label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorstution && !errorstution["paymentMode"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                  id="paymentMode" name="paymentMode" placeholder="Payment mode" value={fieldstution && fieldstution.paymentMode ? fieldstution.paymentMode : ''} type="text" onChange={inputChange} />
                {errorstution && errorstution["paymentMode"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorstution["paymentMode"]}
                  </div>
                  : null}
              </div>


              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">No. of classes in a month : </label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorstution && !errorstution["monthInDay"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                  id="monthInDay" name="monthInDay" placeholder="Days" value={fieldstution && fieldstution.monthInDay ? fieldstution.monthInDay : ''} type="text" onChange={inputChange} />
                {errorstution && errorstution["monthInDay"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorstution["monthInDay"]}
                  </div>
                  : null}
              </div>

              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">start Date" : </label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorstution && !errorstution["startDate"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                  id="startDate" name="startDate" placeholder="class Time" value={fieldstution && fieldstution.startDate ? fieldstution.startDate : ''} type="date" onChange={inputChange} />
                {errorstution && errorstution["startDate"] ?
                  <div className="text-xs text-red-500 invalid-monthdback">
                    {errorstution["startDate"]}
                  </div>
                  : null}
              </div>

              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Due Date" : </label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorstution && !errorstution["dueDate"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                  id="dueDate" name="dueDate" placeholder="class Time" value={fieldstution && fieldstution.dueDate ? fieldstution.dueDate : ''} type="text" onChange={inputChange} />
                {errorstution && errorstution["dueDate"] ?
                  <div className="text-xs text-red-500 invalid-monthdback">
                    {errorstution["dueDate"]}
                  </div>
                  : null}
              </div>

              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Class reference : </label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorstution && !errorstution["classReference"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                  id="classReference" name="classReference" placeholder="Class reference" value={fieldstution && fieldstution.classReference ? fieldstution.classReference : ''} type="text" onChange={inputChange} />
                {errorstution && errorstution["classReference"] ?
                  <div className="text-xs text-red-500 invalid-monthdback">
                    {errorstution["classReference"]}
                  </div>
                  : null}
              </div>

              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Total Fee : </label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorstution && !errorstution["fee"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                  id="fee" name="fee" placeholder="Total Fee" value={fieldstution && fieldstution.fee ? fieldstution.fee : ''} type="number" onChange={inputChange} />
                {errorstution && errorstution["fee"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorstution["fee"]}
                  </div>
                  : null}
              </div>

              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Reference fee: </label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorstution && !errorstution["monthInDay"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                  id="refFee" name="refFee" placeholder="Reference fee" value={fieldstution && fieldstution.refFee ? fieldstution.refFee : ''} type="number" onChange={inputChange} />
                {errorstution && errorstution["refFee"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorstution["refFee"]}
                  </div>
                  : null}
              </div>

              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Tutor Fee : </label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorstution && !errorstution["tutorFee"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                  id="tutorFee" name="tutorFee" placeholder="Tutor Fee" value={fieldstution && fieldstution.tutorFee ? fieldstution.tutorFee : ''} type="number" onChange={inputChange} />
                {errorstution && errorstution["tutorFee"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorstution["tutorFee"]}
                  </div>
                  : null}
              </div>

              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Company Share : </label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorstution && !errorstution["companyShare"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                  id="companyShare" name="companyShare" placeholder="Company Share" value={companyShare} type="number" onChange={inputChange} />
                {errorstution && errorstution["companyShare"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorstution["companyShare"]}
                  </div>
                  : null}
              </div>

              <div className="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Remark : </label>
                <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorstution && !errorstution["remark"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                  id="remark" name="remark" placeholder="Remark" value={fieldstution && fieldstution.remark ? fieldstution.remark : ''} type="text" onChange={inputChange} />
                {errorstution && errorstution["remark"] ?
                  <div className="text-xs text-red-500 invalid-feedback">
                    {errorstution["remark"]}
                  </div>
                  : null}
              </div>
            </div>






            <div className="flex justify-end py-0 space-x-4">
              <button className="bg-[#6F5617]  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
                onClick={tutionubmit}
              >Submit
                <CgLogIn size={18} className="ml-2" />
              </button>
            </div>

          </form>
        </div>
        {/*Footer*/}
      </div>
    </div>




  );
}
