import { userConstants, coinConstants, teachcourseConstants } from '../_constants';
import { userService, teachcourseService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const teachcourseActions = {

    login,
    logout,
    disableCoin,
    getAllCoin,
    createCoin,
    changeStatusDemoClass,
    acceptRejectOrReassignTutorForDemoClass,
    updateCoin,
    getCoinList,
    deleteCoin,
    uploadImageClear,
    disableCoinCard,
    createTeachCourse,
    getTeachCourseList,
    getFilteredDemoClassList,
    updateTeachCourseStatus,
    updateTeachCourse,
    assignDemoClassToTutor,
    manualTutorAssign,
    getSuggestedTutor,
    updateTutorDemoStatusAdmin,
    deleteTeachCourse,
    makeDuplicateDemoClass,
    updateDemoRequest
    // depositUserBalance
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

// function depositUserBalance(data) {

//     return dispatch => {
//         dispatch(request());
//         teachcourseService.depositUserBalance(data)
//             .then(

//                 users => {
//                     dispatch(alertActions.success("Deposit Balance Successfully."));
//                     dispatch(success(users));
//                     dispatch(this.getWalletList({ id: data.userId }));


//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: coinConstants.DEPOSIT_USER_BALANCE_REQUEST } }
//     function success(users) { return { type: coinConstants.DEPOSIT_USER_BALANCE_SUCCESS, users } }
//     function failure(error) { return { type: coinConstants.DEPOSIT_USER_BALANCE_FAILURE, error } }
// }

function createTeachCourse(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        teachcourseService.createTeachCourse(data)
            .then(

                users => {
                    // toast("Coin Created successfully.")
                    dispatch(alertActions.success("Course Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getTeachCourseList(reqData));
                    // dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.ADD_COURSE_REQUEST } }
    function success(users) { return { type: teachcourseConstants.ADD_COURSE_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.ADD_COURSE_FAILURE, error } }
}
function createCoin(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        teachcourseService.createCoin(data)
            .then(

                users => {
                    // toast("Coin Created successfully.")
                    dispatch(alertActions.success("Coin Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getCoinList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.ADD_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.ADD_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.ADD_COIN_FAILURE, error } }
}
function updateDemoRequest(data, reqData) {
    return dispatch => {
        dispatch(request());
        teachcourseService.updateDemoRequest(data)
            .then(
                users => {
                    // toast("Coin Created successfully.")
                    dispatch(alertActions.success("Request Successfully Update."));
                    dispatch(success(users));
                    dispatch(this.getFilteredDemoClassList(reqData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.UPDATE_DEMO_REQUEST_REQUEST } }
    function success(users) { return { type: teachcourseConstants.UPDATE_DEMO_REQUEST_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.UPDATE_DEMO_REQUEST_FAILURE, error } }
}
function makeDuplicateDemoClass(data, reqData) {
    return dispatch => {
        dispatch(request());
        teachcourseService.makeDuplicateDemoClass(data)
            .then(
                users => {
                    console.log('tempdata___________________tempdata11111111111111111', users);
                    // toast("Coin Created successfully.")
                    dispatch(alertActions.success("Request Successfully Update."));
                    dispatch(success(users));
                    dispatch(this.getFilteredDemoClassList(reqData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.MAKE_DUPLICATE_DEMO_CLASS_REQUEST } }
    function success(users) { return { type: teachcourseConstants.MAKE_DUPLICATE_DEMO_CLASS_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.MAKE_DUPLICATE_DEMO_CLASS_FAILURE, error } }
}
function changeStatusDemoClass(data, reqData) {
    return dispatch => {
        dispatch(request());
        teachcourseService.changeStatusDemoClass(data)
            .then(
                users => {
                    console.log('tempdata___________________tempdata11111111111111111', users);
                    // toast("Coin Created successfully.")
                    dispatch(alertActions.success("Request Successfully Update."));
                    dispatch(success(users));
                    dispatch(this.getFilteredDemoClassList(reqData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.APPROVED_DEMO_CLASS_REQUEST } }
    function success(users) { return { type: teachcourseConstants.APPROVED_DEMO_CLASS_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.APPROVED_DEMO_CLASS_FAILURE, error } }
}
function acceptRejectOrReassignTutorForDemoClass(data, reqData, status) {
    // let statusRes = status == 'ACCEPT' ? 'Approved' : 'Reject'
    return dispatch => {
        dispatch(request());
        teachcourseService.acceptRejectOrReassignTutorForDemoClass(data)
            .then(

                users => {
                    // toast("Coin Created successfully.")
                    // dispatch(alertActions.success(` Class Sent for Registration process.`));
                    dispatch(success(users));
                    dispatch(this.getFilteredDemoClassList(reqData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.CHANGE_STATUS_DEMO_CLASS_REQUEST } }
    function success(users) { return { type: teachcourseConstants.CHANGE_STATUS_DEMO_CLASS_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.CHANGE_STATUS_DEMO_CLASS_FAILURE, error } }
}
function updateTeachCourse(data, reqData) {
    return dispatch => {
        dispatch(request());
        teachcourseService.updateTeachCourse(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Course Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getTeachCourseList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.UPDATE_COURSE_REQUEST } }
    function success(users) { return { type: teachcourseConstants.UPDATE_COURSE_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.UPDATE_COURSE_FAILURE, error } }
}
function manualTutorAssign(data, reqData) {
    return dispatch => {
        dispatch(request());
        teachcourseService.manualTutorAssign(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success(" Class Sent for Registration process."));
                    dispatch(success(users));
                    dispatch(this.getFilteredDemoClassList(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.MANUAL_TUTOR_ASSIGN_REQUEST } }
    function success(users) { return { type: teachcourseConstants.MANUAL_TUTOR_ASSIGN_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.MANUAL_TUTOR_ASSIGN_FAILURE, error } }
}
function getSuggestedTutor(data, reqData) {
    return dispatch => {
        dispatch(request());
        teachcourseService.getSuggestedTutor(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    // dispatch(alertActions.success(" Class Sent for Registration process."));
                    dispatch(success(users));
                    // dispatch(this.getFilteredDemoClassList(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.GET_SUGGESTED_TUTOR_REQUEST } }
    function success(users) { return { type: teachcourseConstants.GET_SUGGESTED_TUTOR_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.GET_SUGGESTED_TUTOR_FAILURE, error } }
}
function updateTutorDemoStatusAdmin(data, reqData) {
    return dispatch => {
        dispatch(request());
        teachcourseService.updateTutorDemoStatusAdmin(data)
            .then(

                users => {
                    dispatch(success(users));
                    dispatch(this.getFilteredDemoClassList(reqData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.UPDATE_TUTOR_DEMO_STATUS_ADMIN_REQUEST } }
    function success(users) { return { type: teachcourseConstants.UPDATE_TUTOR_DEMO_STATUS_ADMIN_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.UPDATE_TUTOR_DEMO_STATUS_ADMIN_FAILURE, error } }
}
function assignDemoClassToTutor(data, reqData) {
    return dispatch => {
        dispatch(request());
        teachcourseService.assignDemoClassToTutor(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Successfully Assigned."));
                    dispatch(success(users));
                    dispatch(this.getFilteredDemoClassList(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.ASSIGN_TUTOR_REQUEST } }
    function success(users) { return { type: teachcourseConstants.ASSIGN_TUTOR_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.ASSIGN_TUTOR_FAILURE, error } }
}
function updateCoin(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        teachcourseService.updateCoin(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Coin Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getCoinList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.UPDATE_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.UPDATE_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.UPDATE_COIN_FAILURE, error } }
}
function getAllCoin() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        teachcourseService.getAllCoin()
            .then(
                users => {
                    console.log("getAllCoin $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.GETALL_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.GETALL_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.GETALL_COIN_FAILURE, error } }
}
function getTeachCourseList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        teachcourseService.getTeachCourseList(data)
            .then(
                users => {
                    console.log("getTeachCourseList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.GET_LIST_COURSE_REQUEST } }
    function success(users) { return { type: teachcourseConstants.GET_LIST_COURSE_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.GET_LIST_COURSE_FAILURE, error } }
}
function getFilteredDemoClassList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        teachcourseService.getFilteredDemoClassList(data)
            .then(
                users => {
                    console.log("getFilteredDemoClassList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teachcourseConstants.GET_PENDING_DEMO_LIST_REQUEST } }
    function success(users) { return { type: teachcourseConstants.GET_PENDING_DEMO_LIST_SUCCESS, users } }
    function failure(error) { return { type: teachcourseConstants.GET_PENDING_DEMO_LIST_FAILURE, error } }
}
function getCoinList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        teachcourseService.getCoinList(data)
            .then(
                users => {
                    console.log("getCoinList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.GET_LIST_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.GET_LIST_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.GET_LIST_COIN_FAILURE, error } }
}

function deleteTeachCourse(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        teachcourseService.deleteTeachCourse(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getTeachCourseList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.DELETE_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.DELETE_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.DELETE_COIN_FAILURE, error } }
}
function deleteCoin(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        teachcourseService.deleteCoin(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCoinList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.DELETE_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.DELETE_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.DELETE_COIN_FAILURE, error } }
}
function updateTeachCourseStatus(data, paginationData) {

    return dispatch => {
        dispatch(request());
        teachcourseService.updateTeachCourseStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getTeachCourseList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.DISABLE_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.DISABLE_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.DISABLE_COIN_FAILURE, error } }
}
function disableCoin(data, paginationData) {

    return dispatch => {
        dispatch(request());
        teachcourseService.disableCoin(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCoinList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.DISABLE_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.DISABLE_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.DISABLE_COIN_FAILURE, error } }
}
function disableCoinCard(data, paginationData) {

    return dispatch => {
        dispatch(request());
        teachcourseService.disableCoinCard(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCoinList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: coinConstants.DISABLE_COIN_REQUEST } }
    function success(users) { return { type: coinConstants.DISABLE_COIN_SUCCESS, users } }
    function failure(error) { return { type: coinConstants.DISABLE_COIN_FAILURE, error } }
}
