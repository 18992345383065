export const ticketConstants = {
    LOGIN_REQUEST: 'TICKETS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'TICKETS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'TICKETS_LOGIN_FAILURE',

    LOGOUT: 'TICKETS_LOGOUT',

    GETALL_REQUEST: 'TICKETS_GETALL_REQUEST',
    GETALL_SUCCESS: 'TICKETS_GETALL_SUCCESS',
    GETALL_FAILURE: 'TICKETS_GETALL_FAILURE',

    GET_ALL_TICKET_REQUEST: 'TICKETS_GET_ALL_TICKET_REQUEST',
    GET_ALL_TICKET_SUCCESS: 'TICKETS_GET_ALL_TICKET_SUCCESS',
    GET_ALL_TICKET_FAILURE: 'TICKETS_GET_ALL_TICKET_FAILURE',
    
    GETALL_TICKET_NOTIFY_REQUEST: 'GETALL_TICKET_NOTIFY_REQUEST',
    GETALL_TICKET_NOTIFY_SUCCESS: 'GETALL_TICKET_NOTIFY_SUCCESS',
    GETALL_TICKET_NOTIFY_FAILURE: 'GETALL_TICKET_NOTIFY_FAILURE',

    UPDATE_TICKET_NOTIFY_REQUEST: 'UPDATE_TICKET_NOTIFY_REQUEST',
    UPDATE_TICKET_NOTIFY_SUCCESS: 'UPDATE_TICKET_NOTIFY_SUCCESS',
    UPDATE_TICKET_NOTIFY_FAILURE: 'UPDATE_TICKET_NOTIFY_FAILURE',

    UPDATE_TICKET_PASSWORD_REQUEST: 'UPDATE_TICKET_PASSWORD_REQUEST',
    UPDATE_TICKET_PASSWORD_SUCCESS: 'UPDATE_TICKET_PASSWORD_SUCCESS',
    UPDATE_TICKET_PASSWORD_FAILURE: 'UPDATE_TICKET_PASSWORD_FAILURE',

    UPDATE_TICKET_STATUS_REQUEST: 'UPDATE_TICKET_STATUS_REQUEST',
    UPDATE_TICKET_STATUS_SUCCESS: 'UPDATE_TICKET_STATUS_SUCCESS',
    UPDATE_TICKET_STATUS_FAILURE: 'UPDATE_TICKET_STATUS_FAILURE',


    ADD_RESTAURANT_TICKET_REQUEST: 'ADD_RESTAURANT_TICKET_REQUEST',
    ADD_RESTAURANT_TICKET_SUCCESS: 'ADD_RESTAURANT_TICKET_SUCCESS',
    ADD_RESTAURANT_TICKET_FAILURE: 'ADD_RESTAURANT_TICKET_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',
    
    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

};
