import React from "react";

import Modal from 'react-modal';
const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};

export default function ViewPendingModal(props) {

  let { viewAddharDetailsModal, handleAddharClose, viewAddharData,
  } = props;
  console.log("viewAddharDataModal::", viewAddharData);

  return (

    <Modal
      isOpen={viewAddharDetailsModal}
      style={customStyles}
      ariaHideApp={false}
    // contentLabel="Example Modal"
    >


      {viewAddharData ?
        <div className="main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster overflow-y-auto" >
          <div className="border border-teal-500 modal-container bg-white w-11/12 xl:max-w-4xl md:max-w-2xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">

              <div className="flex justify-between items-center pb-3">
                <p className="text-2xl font-bold">Covet. Id proof</p>
                <div className="modal-close cursor-pointer z-50">
                  <svg onClick={() => handleAddharClose()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                    </path>
                  </svg>
                </div>
              </div>

              <div className="my-5">
                <form autoComplete="off">
                  <>

                    <div className="grid grid-rows-1 grid-flow-col">
                      <div className="justify-center lg:justify-start text-white p-4 bg-shine rounded-md items-start mt-2 w-full ">

                        <div className="flex justify-center items-center">
                          <div className="space-y-2" >
                            <label className="">IMAGE FRONT</label>
                            <img className="w-96 h-64" src={viewAddharData && viewAddharData.imagefrontLink ? viewAddharData.imagefrontLink : "-"} alt=" " />
                          </div>
                          {/* <div className="space-y-2" >
                            <label>IMAGE BACK</label>
                            <img className="w-96 h-64" src={viewAddharData && viewAddharData.imageBackLink ? viewAddharData.imageBackLink : "-"} alt=" " />
                          </div> */}
                        </div>
                      </div>
                    </div>

                  </>
                </form>

              </div>
            </div>
          </div>
        </div>
        : null
      }


    </Modal>



  );
}
