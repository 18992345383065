import {
  ltArchiveRewardConstants
} from '../_constants';

export function ltArchiveReward(state = {}, action) {

  switch (action.type) {
    // case ltArchiveRewardConstants.DEPOSIT_USER_BALANCE_REQUEST:
    //   return {
    //     ...state
    //   };
    // case ltArchiveRewardConstants.DEPOSIT_USER_BALANCE_SUCCESS:
    //   return {
    //     ...state,
    //     addUserSuccess: true
    //   };
    // case ltArchiveRewardConstants.DEPOSIT_USER_BALANCE_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };
    case ltArchiveRewardConstants.ADD_L_T_ARCHIVE_REWARD_REQUEST:
      return {
        ...state
      };
    case ltArchiveRewardConstants.ADD_L_T_ARCHIVE_REWARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case ltArchiveRewardConstants.ADD_L_T_ARCHIVE_REWARD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case ltArchiveRewardConstants.GET_LIST_L_T_ARCHIVE_REWARD_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case ltArchiveRewardConstants.GET_LIST_L_T_ARCHIVE_REWARD_SUCCESS:
      return {
        ...state,
       
        loading: false,
        items: action.users.getLTArchiveRewardList.list,
        total: action.users.getLTArchiveRewardList.total
      };
    case ltArchiveRewardConstants.GET_LIST_L_T_ARCHIVE_REWARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ltArchiveRewardConstants.GETALL_L_T_ARCHIVE_REWARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ltArchiveRewardConstants.GETALL_L_T_ARCHIVE_REWARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getAllLTArchiveReward,
        total: action.users.getAllLTArchiveReward
      };
    case ltArchiveRewardConstants.GETALL_L_T_ARCHIVE_REWARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case ltArchiveRewardConstants.UPDATE_L_T_ARCHIVE_REWARD_REQUEST:
      return {
        ...state
      };
    case ltArchiveRewardConstants.UPDATE_L_T_ARCHIVE_REWARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case ltArchiveRewardConstants.UPDATE_L_T_ARCHIVE_REWARD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case ltArchiveRewardConstants.DELETE_L_T_ARCHIVE_REWARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ltArchiveRewardConstants.DELETE_L_T_ARCHIVE_REWARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case ltArchiveRewardConstants.DELETE_L_T_ARCHIVE_REWARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ltArchiveRewardConstants.DISABLE_L_T_ARCHIVE_REWARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ltArchiveRewardConstants.DISABLE_L_T_ARCHIVE_REWARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case ltArchiveRewardConstants.DISABLE_L_T_ARCHIVE_REWARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}