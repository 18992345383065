import { dashboardConstants } from '../_constants';

export function dashboard(state = {}, action) {
  console.log("action.typeaction.typeaction.type  ", action);

  switch (action.type) {

    case dashboardConstants.GET_ADMIN_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        adminDetails: action.dashboards.getAdminDetails,
      };
    case dashboardConstants.GET_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_TERMS_AND_CONDIYION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TERMS_AND_CONDIYION_SUCCESS:
      return {
        ...state,
        loading: false,
        termsAndCondition: action.dashboards.getTermsAndConditionList,
      };
    case dashboardConstants.GET_TERMS_AND_CONDIYION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.ADD_ADMIN_OWN_BALANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.ADD_ADMIN_OWN_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        adminBalanceSuccess: true,
        // aminBalance: action.dashboards.addAminOwnBalance,
      };
    case dashboardConstants.ADD_ADMIN_OWN_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.UPDATE_OWN_PASSWORD_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.UPDATE_OWN_PASSWORD_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        adminBalanceSuccess: true,
        // aminBalance: action.dashboards.addAminOwnBalance,
      };
    case dashboardConstants.UPDATE_OWN_PASSWORD_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        adminBalanceSuccess: false
      };
    case dashboardConstants.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboarditems: action.dashboards.getDashboardData,
      };
    case dashboardConstants.GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.TODAY_DEMO_PENDING_REQUEST:
      return {
        ...state,
        loading: true,
        adminBalanceSuccess: false
      };
    case dashboardConstants.TODAY_DEMO_PENDING_SUCCESS:
      return {
        ...state,
        loading: false,
        todayDemoPendingList: action.dashboards.todayDemoPendingList.list,
      };
    case dashboardConstants.TODAY_DEMO_PENDING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dashboardConstants.TODAY_DUE_DATE_REQUEST:
      return {
        ...state,
        loading: true,
        adminBalanceSuccess: false
      };
    case dashboardConstants.TODAY_DUE_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        todayDueDatesList: action.dashboards,
      };
    case dashboardConstants.TODAY_DUE_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dashboardConstants.SEND_DEMO_REQUEST_REQUEST:
      return {
        ...state,
        loading: true,
        adminBalanceSuccess: false
      };
    case dashboardConstants.SEND_DEMO_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        sendDemoRequest: action.dashboards,
      };
    case dashboardConstants.SEND_DEMO_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_STUDENT_FEE_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        adminBalanceSuccess: false
      };
    case dashboardConstants.GET_STUDENT_FEE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        getStudentFeeHistory: action.dashboards,
      };
    case dashboardConstants.GET_STUDENT_FEE_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_TUTOR_FEE_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        adminBalanceSuccess: false
      };
    case dashboardConstants.GET_TUTOR_FEE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        totalTuitionEarningMonthWasi: action.dashboards,
      };
    case dashboardConstants.GET_TUTOR_FEE_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.TOTAL_TUTION_EARNING_REQUEST:
      return {
        ...state,
        loading: true,
        adminBalanceSuccess: false
      };
    case dashboardConstants.TOTAL_TUTION_EARNING_SUCCESS:
      return {
        ...state,
        loading: false,
        totalTuitionEarning: action.dashboards.totalTuitionEarning,
      };
    case dashboardConstants.TOTAL_TUTION_EARNING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.TOTAL_TUTION_EARNING_MONTH_WASI_REQUEST:
      return {
        ...state,
        loading: true,
        adminBalanceSuccess: false
      };
    case dashboardConstants.TOTAL_TUTION_EARNING_MONTH_WASI_SUCCESS:
      return {
        ...state,
        loading: false,
        totalTuitionEarningMonthWasi: action.dashboards.totalTuitionEarningMonthWasi,
      };
    case dashboardConstants.TOTAL_TUTION_EARNING_MONTH_WASI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case dashboardConstants.TODAY_DEMO_COMPLETED_REQUEST:
      return {
        ...state,
        loading: true,
        adminBalanceSuccess: false
      };
    case dashboardConstants.TODAY_DEMO_COMPLETED_SUCCESS:
      return {
        ...state,
        loading: false,
        todayDemoCompletedList: action.dashboards,
      };
    case dashboardConstants.TODAY_DEMO_COMPLETED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}