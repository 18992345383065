import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import { isMobile } from "react-device-detect";
import CreateAddUserModal from "./components/CreateAddUserModal/CreateAddUserModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./style.css"

class UserActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      addUserCreateModal: false
    }
  }
  componentDidMount() {
    let data = {
      "id": this.props.match.params.id
    }
    this.props.dispatch(userActions.getUserPaymentDetails(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      // "userType": "ACTIVE",
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": value.toLowerCase(),
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTuitionListByTutor(data));
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      // "userType": "ACTIVE",
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTuitionListByTutor(datatemp));
  }

  moreDetailsPage = (data) => {
    this.props.history.push(`/app/attendence/${data.id}`)
  }

  addFeeDetailsPage = (data) => {
    this.props.history.push(`/app/studentFee/${data.id}/${this.props.match.params.id}`)
    // this.setState({ addUserCreateModal: true })
  }

  handleAddUserHideModal = (data) => {

    // this.setState({ addUserCreateModal: false })
  }

  render() {

    let { users } = this.props;
    let { tuitionList, tuitionTotal, loading, attendanceList, userPaymentList, totalAmount
    } = users;
    console.log('tuitionList_____________________', tuitionList);
    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">

                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="text-lg font-semibold leading-6 tracking-widest text-center capitalize md:text-2xl md:leading-9 text-dark-400">Student payment history</h3>
                    </div>
                    <div className='border p-1 flex items-center space-x-2 text-xl font-bold'>
                      <h1 className=''>Total Amount:</h1>
                      <p> {totalAmount ? totalAmount : '0'}</p>
                    </div>
                    {/* <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div> */}
                  </div>

                  <div className=" rounded-lg overflow-hidden pb-2 mt-4">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          {/* <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-teal-300 rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">#</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">Tuition Id No.</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">class</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">student Name</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">Tutor Name</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">No of classes in month</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">fee received</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-gray-700 capitalize tracking-wider">Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                userPaymentList && userPaymentList.length > 0 ?
                                  userPaymentList.map((element, index) =>

                                  (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap text-center font-medium text-sm text-gray-600">
                                        {this.state.offset + index + 1}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.tuitionId && element.tuitionId.tutionNo ? element.tuitionId.tutionNo : "0"}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.tuitionId && element.tuitionId.classId && element.tuitionId.classId.name ? element.tuitionId.classId.name : "NA"}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.tuitionId && element.tuitionId.teacherCouresId && element.tuitionId.teacherCouresId[0] && element.tuitionId.teacherCouresId[0].name ? element.tuitionId.teacherCouresId[0].name : "NA"}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.tuitionId && element.tuitionId.tutorId && element.tuitionId.tutorId.fullName ? element.tuitionId.tutorId.fullName : "NA"}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.tuitionId && element.tuitionId.monthInDay ? element.tuitionId.monthInDay : "NA"}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.tutorFee ? element.tutorFee : "NA"}</td>

                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>
                                    </tr>

                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table> */}

                          <table className='bg-white divide-y-2 rounded-[14px] w-full'>
                            <thead>
                              <tr>
                                <th className="text-[14px] px-2 py-2 text-left whitespace-pre">Name <br /> Mobile Number </th>
                                <th className="text-[14px] px-2 text-left whitespace-pre"> Class & Subject</th>
                                <th className="text-[14px] px-2 text-left whitespace-pre">Class Reference </th>
                                <th className="text-[14px] px-2 text-left whitespace-pre"> Assigned Tutor</th>
                                <th className="text-[14px] px-2 text-left whitespace-pre">Amount Paid</th>
                                <th className="text-[14px] px-2 text-left whitespace-pre">Payment Paid date</th>
                                <th className="text-[14px] px-2 text-left whitespace-pre">Payment Mode</th>
                                <th className="text-[14px] px-2 text-left whitespace-pre">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                userPaymentList && userPaymentList.length > 0 ?
                                  userPaymentList.map((element, index) =>

                                  (<React.Fragment key={element.id}>
                                    <tr>
                                      <td className='text-[14px] px-2  text-left whitespace-nowrap'>
                                        <p> {element && element.tuitionId && element.tuitionId.studentId && element.tuitionId.studentId.firstName ? element.tuitionId.studentId.firstName : null}</p>
                                        <p>{element && element.tuitionId && element.tuitionId.studentId && element.tuitionId.studentId.mobNo ? element.tuitionId.studentId.mobNo : null}</p>
                                      </td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                                        <p>{element && element.tuitionId && element.tuitionId.classId && element.tuitionId.classId.name ? element.tuitionId.classId.name : null} / {element && element.tuitionId && element.tuitionId.teacherCouresId && element.tuitionId.teacherCouresId[0] && element.tuitionId.teacherCouresId[0].name ? element.tuitionId.teacherCouresId[0].name : null}</p>
                                      </td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                                        <p>{element && element.tuitionId && element.tuitionId.classReference ? element.tuitionId.classReference : "0"}</p>
                                      </td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                                        <p>{element && element.tuitionId && element.tuitionId.tutorId && element.tuitionId.tutorId.fullName ? element.tuitionId.tutorId.fullName : "NA"}</p>
                                        <p>{element && element.tuitionId && element.tuitionId.tutorId && element.tuitionId.tutorId.mobNo ? element.tuitionId.tutorId.mobNo : "NA"}</p>
                                      </td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                                        <p>{element && element.tutorFee ? element.tutorFee : "0"}/-</p>
                                      </td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                                        <p>{element && element.paymentMode ? element.paymentMode : null}</p>
                                      </td>
                                      <td className='text-[14px] px-2 text-left whitespace-nowrap'>
                                        <p>{element && element.remark ? element.remark : null}</p>
                                      </td>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <nav className="relative z-0 flex justify-end mt-5">
                      {
                        tuitionTotal && tuitionTotal > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={tuitionTotal / this.state.size}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>


                  </div>
                </section>
              </div>
            </div>
          </main >
        </div >

        <DialogExample />

        <CreateAddUserModal
          addUserCreateModal={this.state.addUserCreateModal}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          inputAddUserChange={this.inputAddUserChange}
          addUserSubmit={this.addUserSubmit}
          handleAddUserHideModal={this.handleAddUserHideModal}
        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { users, city } = state;
  return {
    users, city
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(UserActive);
