import React from "react";

import Modal from 'react-modal';
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';


export default function UpdatePasswordUser(props) {

  let { UpdatePasswordCreateModal, handleAppSeetingHideModal, errorsUpdatePassword, inputChange, updateUserPasswordSubmit, fieldsUpdatePassword, getAllCity, getAllPinCode1, getAllClass, handleChangeInputclassId, handleChangeInputPinCodeId, handleChangeInputteachingCourse, getAllteacherCouresId, handleChangeInputTeachingPincode } = props;
  //console.log("userCreateModal  ",userCreateModal);
  console.log('getAllPinCode1_______________getAllPinCode1', getAllCity);

  // let classRes = getAllClass && getAllClass.length ? getAllClass.map((ele, index) => ({ value: ele.id, label: ele.name })) : null
  // let teacherCouresRes = getAllteacherCouresId && getAllteacherCouresId.length ? getAllteacherCouresId.map((ele, index) => ({ value: ele.id, label: ele.name })) : null




  let teacherCouresRes = []
  if (getAllteacherCouresId && getAllteacherCouresId.length > 0) {
    getAllteacherCouresId.forEach((emp) => {

      let result = fieldsUpdatePassword && fieldsUpdatePassword.tutorInfoId && fieldsUpdatePassword.tutorInfoId.teachingCourse ? fieldsUpdatePassword.tutorInfoId.teachingCourse.filter(o => (o.id == emp && emp.id)) : [];

      console.log('resultresultresultresult', result)

      if (result && result.length > 0) {
        // console.log("ppppppppppppppppppppppppppppppppppppppppp",emp)
        teacherCouresRes.push(
          {
            value: emp.id,
            label: emp.name,
            id: emp.id,
            disabled: true
          }
        )
      } else {
        // console.log("ppppppppppppppppppppppppppppppppppppppppp",emp)
        teacherCouresRes.push(
          {
            value: emp.id,
            label: emp.name,
            id: emp.id,
            disabled: false
          }
        )
      }
    })
  }
  let classRes = []
  if (getAllClass && getAllClass.length > 0) {
    getAllClass.forEach((emp) => {

      let result = fieldsUpdatePassword && fieldsUpdatePassword.tutorInfoId && fieldsUpdatePassword.tutorInfoId.teachingClasses ? fieldsUpdatePassword.tutorInfoId.teachingClasses.filter(o => (o.id == emp && emp.id)) : [];

      console.log('resultresultresultresult', result)

      if (result && result.length > 0) {
        // console.log("ppppppppppppppppppppppppppppppppppppppppp",emp)
        classRes.push(
          {
            value: emp.id,
            label: emp.name,
            id: emp.id,
            disabled: true
          }
        )
      } else {
        // console.log("ppppppppppppppppppppppppppppppppppppppppp",emp)
        classRes.push(
          {
            value: emp.id,
            label: emp.name,
            id: emp.id,
            disabled: false
          }
        )
      }
    })
  }
  let teachPincodeRes = []
  if (getAllPinCode1 && getAllPinCode1.length > 0) {
    getAllPinCode1.forEach((emp) => {

      let result = fieldsUpdatePassword && fieldsUpdatePassword.tutorInfoId && fieldsUpdatePassword.tutorInfoId.teachPincode ?
        fieldsUpdatePassword.tutorInfoId.teachPincode.filter(o => (o.id == emp && emp.id)) : [];

      console.log('resultresultresultresult', result)

      if (result && result.length > 0) {
        // console.log("ppppppppppppppppppppppppppppppppppppppppp",emp)
        teachPincodeRes.push(
          {
            value: emp.id,
            label: emp.pinNo,
            id: emp.id,
            disabled: true
          }
        )
      } else {
        // console.log("ppppppppppppppppppppppppppppppppppppppppp",emp)
        teachPincodeRes.push(
          {
            value: emp.id,
            label: emp.pinNo,
            id: emp.id,
            disabled: false
          }
        )
      }
    })
  }



  return (

    <Modal
      isOpen={UpdatePasswordCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-[#FBB00C] modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content  text-left">
            {/*Title*/}
            <div className="flex justify-between items-center  bg-[#FBB00C] px-4 py-4">
              <p className="text-2xl font-bold">Update Tutor</p>
              <div className="modal-close cursor-pointer z-50 bg-white rounded-full p-2">
                <svg onClick={() => handleAppSeetingHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5 lg:h-[500px] h-[400px] overflow-y-auto px-4">
              <form autoComplete="off" className="space-y-1">
                <div className="">
                  <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Mobile No</label>
                  <div className="mt-1  shadow-sm relative">
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["mobNo"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="mobNo" name="mobNo" placeholder="Mobile No" value={fieldsUpdatePassword.mobNo} type="number" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["mobNo"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["mobNo"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="">
                  <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Full Name</label>
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["fullName"] ? " border border-gray-400 placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="fullName" name="fullName" placeholder="Full Name" value={fieldsUpdatePassword.fullName} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["fullName"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["fullName"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="w-full ">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Gender</label>
                    <select className="px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 border border-gray-400" onChange={inputChange}
                      id="gender"
                      value={fieldsUpdatePassword && fieldsUpdatePassword.gender ? fieldsUpdatePassword.gender : ''}
                      name="gender">
                      <option value="">Please Choose Gender&hellip;</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>


                <div className="">
                  <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Email</label>
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["email"] ? "  border border-gray-400 placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="email" name="email" placeholder="Email" value={fieldsUpdatePassword.email} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["email"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["email"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="">
                  <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Date of birth</label>
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["dob"] ? "  border border-gray-400 placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="dob" name="dob" placeholder="Date of birth" value={fieldsUpdatePassword.dob} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["dob"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["dob"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="">
                  <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Experience</label>
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["experience"] ? "  border border-gray-400 placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="experience" name="experience" placeholder="Experience" value={fieldsUpdatePassword.experience} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["experience"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["experience"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="">
                  <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Address</label>
                  <div className="mt-1 rounded-md shadow-sm relative">
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 ${errorsUpdatePassword && !errorsUpdatePassword["address"] ? "  border border-gray-400 placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                      id="address" name="address" placeholder="Address" value={fieldsUpdatePassword.address} type="text" onChange={inputChange} />
                    {errorsUpdatePassword && errorsUpdatePassword["address"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdatePassword["address"]}
                      </div>
                      : null}
                  </div>
                </div>




                <div className="w-full ">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">City</label>
                    <select className="px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 border border-gray-400" onChange={inputChange}
                      id="city"
                      // value={fieldsUpdatePassword && fieldsUpdatePassword.city && fieldsUpdatePassword.city.name ? fieldsUpdatePassword.city.name : ''}
                      name="city">
                      <option value="">{fieldsUpdatePassword && fieldsUpdatePassword.city && fieldsUpdatePassword.city.name ? fieldsUpdatePassword.city.name : 'Please Choose City'}&hellip;</option>
                      {
                        getAllCity && getAllCity && getAllCity.length > 0 ?
                          getAllCity.map((element, index) => (
                            <option value={element && element.id} key={index}>{element && element.name}</option>
                          )) : null
                      }
                    </select>
                  </div>
                </div>

                <div className="w-full ">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">PinCode</label>
                    <select className="px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 border border-gray-400" onChange={inputChange}
                      id="pinCodeId"
                      // value={fieldsUpdatePassword && fieldsUpdatePassword.pinCode && fieldsUpdatePassword.pinCode[0] && fieldsUpdatePassword.pinCode[0].pinNo ? fieldsUpdatePassword.pinCode[0].pinNo : ''}
                      name="pinCodeId">
                      <option value="">{fieldsUpdatePassword && fieldsUpdatePassword.pinCode && fieldsUpdatePassword.pinCode[0] && fieldsUpdatePassword.pinCode[0].pinNo ? fieldsUpdatePassword.pinCode[0].pinNo : 'Please Choose pincode'}&hellip;</option>
                      {
                        getAllPinCode1 && getAllPinCode1 && getAllPinCode1.length > 0 ?
                          getAllPinCode1.map((element, index) => (
                            <option value={element && element.id} key={index}>{element && element.pinNo}</option>
                          )) : null
                      }
                    </select>
                  </div>
                </div>



                <div className="w-full ">
                  <div className="relative mt-1 shadow-sm">
                    <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                      <label htmlFor="resourceList" style={{ color: 'black' }}>Class</label>

                      <Select2
                        width="full"
                        isMulti
                        onChange={handleChangeInputclassId}
                        defaultValue={fieldsUpdatePassword && fieldsUpdatePassword.tutorInfoId && fieldsUpdatePassword.tutorInfoId.teachingClasses ?
                          fieldsUpdatePassword.tutorInfoId.teachingClasses.map((element) => ({
                            value: element && element.id ? element.id : null,
                            label: element && element.name ? element.name : null,
                            id: element && element.id ? element.id : null
                          })) : []}
                        options={classRes}
                        isOptionDisabled={(option) => option.disabled}
                        className="basic-multi-select width: 'full'"
                        classNamePrefix="Activities"
                      />

                    </Grid>
                  </div>
                </div>

                <div className="w-full ">
                  <div className="relative mt-1 shadow-sm">
                    <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                      <label htmlFor="resourceList" style={{ color: 'black' }}>Subject</label>


                      <Select2
                        width="full"
                        isMulti
                        onChange={handleChangeInputteachingCourse}
                        defaultValue={fieldsUpdatePassword && fieldsUpdatePassword.tutorInfoId && fieldsUpdatePassword.tutorInfoId.teachingCourse ?
                          fieldsUpdatePassword.tutorInfoId.teachingCourse.map((element) => ({
                            value: element && element.id ? element.id : null,
                            label: element && element.name ? element.name : null,
                            id: element && element.id ? element.id : null
                          })) : []}
                        options={teacherCouresRes}
                        isOptionDisabled={(option) => option.disabled}
                        className="basic-multi-select width: 'full'"
                        classNamePrefix="Activities"
                      />


                    </Grid>
                  </div>
                </div>

                <div className="w-full ">
                  <div className="relative mt-1 shadow-sm">
                    <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                      <label htmlFor="resourceList" style={{ color: 'black' }}>Teaching Location PinCode</label>


                      <Select2
                        width="full"
                        isMulti
                        onChange={handleChangeInputTeachingPincode}
                        defaultValue={fieldsUpdatePassword && fieldsUpdatePassword.tutorInfoId && fieldsUpdatePassword.tutorInfoId.teachPincode ?
                          fieldsUpdatePassword.tutorInfoId.teachPincode.map((element) => ({
                            value: element && element.id ? element.id : null,
                            label: element && element.pinNo ? element.pinNo : null,
                            id: element && element.id ? element.id : null
                          })) : []}
                        options={teachPincodeRes}
                        isOptionDisabled={(option) => option.disabled}
                        className="basic-multi-select width: 'full'"
                        classNamePrefix="Activities"
                      />


                    </Grid>
                  </div>
                </div>
                <div className=" w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-[#FBB00C] w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-[#FBB00C] hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateUserPasswordSubmit}>Submit</button>
                </div>
              </form>



            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
