import {
  userConstants, coinIndexConstants
} from '../_constants';

export function coinIndex(state = {}, action) {

  switch (action.type) {
    case coinIndexConstants.ADD_COIN_INDEX_REQUEST:
      return {
        ...state
      };
    case coinIndexConstants.ADD_COIN_INDEX_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case coinIndexConstants.ADD_COIN_INDEX_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case coinIndexConstants.GET_LIST_COIN_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case coinIndexConstants.GET_LIST_COIN_INDEX_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // coinItems: action.users.getCoinIndexList.list.coin,
        coinItems: action.users.getCoinIndexList.list,
        total: action.users.getCoinIndexList.total
      };
    case coinIndexConstants.GET_LIST_COIN_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case coinIndexConstants.GETALL_COIN_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case coinIndexConstants.GETALL_COIN_INDEX_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        coinIndexItems: action.users.getAllCoinIndex,
        coinIndexTotal: action.users.getAllCoinIndex
      };
    case coinIndexConstants.GETALL_COIN_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_COIN_INDEX_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_COIN_INDEX_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_COIN_INDEX_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DELETE_COIN_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_COIN_INDEX_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_COIN_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_COIN_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_COIN_INDEX_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_COIN_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}