import React from "react";

import Modal from 'react-modal';
import Select from "react-dropdown-select";


export default function CreateUpdatePasswordDealer(props) {

  let { UpdateCoinModal, handleCoinUpdateHideModal, errorsUpdateCoin, assignTutorDemoClass, inputChangeUpdateCoin, updateCoinSubmit, fieldsUpdateCoin, assignTutor, handleFile } = props;
  console.log("fieldsUpdateCoin___________-fieldsUpdateCoin", fieldsUpdateCoin);

  return (

    <Modal
      isOpen={UpdateCoinModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 shadow-lg modal-container bg-white rounded z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Assign Tutor</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleCoinUpdateHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">
                <div className="flex justify-between w-full">
                  <div class="mr-2 w-full">
                    <label className="text-black text-sm font-medium mt-2 mr-2">Tutor List : </label>
                    <select class="form-select border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputChangeUpdateCoin} id="assignTutor" name="assignTutor"
                    >
                      <option value={fieldsUpdateCoin && fieldsUpdateCoin.tutorId && fieldsUpdateCoin.tutorId.id ? fieldsUpdateCoin.tutorId.id : ""} selected >{fieldsUpdateCoin && fieldsUpdateCoin.tutorId && fieldsUpdateCoin.tutorId.id ? fieldsUpdateCoin.tutorId.fullName : "Plz Select Tutor"}</option>

                      {
                        fieldsUpdateCoin && fieldsUpdateCoin.tutorAppliedList && fieldsUpdateCoin.tutorAppliedList.length > 0 ?
                          fieldsUpdateCoin.tutorAppliedList.map((element, index) => (
                            <option value={element && element.id ? element.id : null}>Name:{element && element.fullName ? element.fullName : "-"} Mob No.:{element && element.mobNo ? element.mobNo : "-"} Gender:{element && element.gender ? element.gender : "-"}  Experience:{element && element.experience ? element.experience : "-"} Address:{element && element.address ? element.address : "-"}</option>
                          )) : null
                      }
                    </select>
                    {errorsUpdateCoin && errorsUpdateCoin["assignTutor"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateCoin["assignTutor"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateCoinSubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
