import { supportConstants } from '../_constants';
import { supportService } from '../_services';
import { alertActions } from '.';
export const supportActions = {
    getTicketList,
    replyMsgAdminTicket,
    MsgListTicketById,
    resolvedTicket,
};
function replyMsgAdminTicket(data) {

    // console.log('2222222222222222222222222222222');
    // let temp = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }

    return dispatch => {
        dispatch(request());
        supportService.replyMsgAdminTicket(data)
            .then(
                support => {
                    console.log('lllllllllllllllll');
                    dispatch(success(support));
                    dispatch(this.MsgListTicketById({ ticketId: data.ticketId }));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: supportConstants.UPDATE_TICKET_STATUS_REQUEST } }
    function success(support) { return { type: supportConstants.UPDATE_TICKET_STATUS_SUCCESS, support } }
    function failure(error) { return { type: supportConstants.UPDATE_TICKET_STATUS_FAILURE, error } }
}


function MsgListTicketById(data) {
    return dispatch => {
        dispatch(request());
        supportService.MsgListTicketById(data)
            .then(
                support => {
                    console.log('support', support);
                    dispatch(success(support));
                    // dispatch(this.getTicketList(temp));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: supportConstants.MESSAGE_LIST_TICKET_REQUEST } }
    function success(support) { return { type: supportConstants.MESSAGE_LIST_TICKET_SUCCESS, support } }
    function failure(error) { return { type: supportConstants.MESSAGE_LIST_TICKET_FAILURE, error } }
}
function resolvedTicket(data, temp) {
    // let temp = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        supportService.resolvedTicket(data)
            .then(
                support => {
                    console.log('support', support);
                    dispatch(success(support));
                    dispatch(this.getTicketList(temp));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: supportConstants.STATUS_TICKET_REQUEST } }
    function success(support) { return { type: supportConstants.STATUS_TICKET_SUCCESS, support } }
    function failure(error) { return { type: supportConstants.STATUS_TICKET_FAILURE, error } }
}

function getTicketList(data) {
    return dispatch => {
        dispatch(request());
        supportService.getTicketList(data)
            .then(
                support => dispatch(success(support)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: supportConstants.GET_TICKET_LIST_REQUEST } }
    function success(support) { return { type: supportConstants.GET_TICKET_LIST_SUCCESS, support } }
    function failure(error) { return { type: supportConstants.GET_TICKET_LIST_FAILURE, error } }
}
