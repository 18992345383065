import React from "react";
import { CgLogIn } from 'react-icons/cg';
import Modal from 'react-modal';


export default function UpdateTutionModalAddAmount(props) {

  let { tutionUpdateModalAddAmount, getUserList, handleHideTutorModal, errorsUpdateCoin, assignTutorDemoClass, inputChangeUpdateCoin, updateCoinSubmit, fieldsUpdateCoin, assignTutor, handleFile } = props;
  // console.log("inputChangeUpdateinputChangeUpdateinputChangeUpdate", inputChangeUpdate);

  return (



    <Modal
      isOpen={tutionUpdateModalAddAmount}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 shadow-lg modal-container bg-white rounded z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Manual Tutor</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleHideTutorModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">
                <div className="flex justify-between w-full">
                  <div className="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Amount : </label>
                    <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdateCoin && !errorsUpdateCoin["amount"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                      id="amount" name="amount" placeholder="Amount" value={fieldsUpdateCoin && fieldsUpdateCoin.amount ? fieldsUpdateCoin.amount : ''} type="number" onChange={inputChangeUpdateCoin} />
                    {errorsUpdateCoin && errorsUpdateCoin["amount"] ?
                      <div className="text-xs text-red-500 invalid-feedback">
                        {errorsUpdateCoin["amount"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateCoinSubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>


  );
}
