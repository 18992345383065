import React from "react";
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import Modal from 'react-modal';


export default function CreateAddDealerModal(props) {

  let { coinCreateModal, handleCoinHideModal, errorsCoin, inputCoinChange, createCoinSubmit, fieldsCoin, setCurrentIndex, addContentField, deleteContentField, inputAddUserChangeContent } = props;
  //console.log("userCreateModal  ",userCreateModal);

  return (

    <Modal
      isOpen={coinCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border  modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-[40px] shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Create Subject</p>
              <div className="modal-close cursor-pointer z-50 bg-[#FBB00C] rounded-full p-1 h-5 w-5 flex justify-center items-center">
                <svg onClick={() => handleCoinHideModal()} className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">


                <div className="grid grid-cols-2 gap-4">
                  {
                    fieldsCoin && fieldsCoin.options && fieldsCoin.options.length > 0 ?
                      fieldsCoin.options.map((item, index) => (
                        <>
                          <div className="relative mt-1 shadow-sm">
                            <div className="flex justify-between">
                              <label class="block text-xs font-medium text-gray-500 md:text-left" for="username">Course Name {index + 1} :</label>
                              <div>
                                {index === 0 ?
                                  <span className='flex justify-end' onClick={() => addContentField(index)}><MdAddCircleOutline size={22} className="text-gray-600 cursor-pointer hover:text-blue-500" /></span> :
                                  <>
                                    <div className="flex justify-end space-x-3">
                                      <span className='flex justify-end' onClick={() => deleteContentField(index)}><MdRemoveCircleOutline size={22} className="text-red-500 cursor-pointer hover:text-red-700" /></span>
                                    </div>
                                  </>
                                }
                              </div>
                            </div>
                            <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 ${errorsCoin && !errorsCoin["name"] ? "border  placeholder-gray-500" : "border  border-red-500"}`}
                              id="name" name="name" placeholder="Course Name"
                              onClick={() => setCurrentIndex(index)}
                              type="text" value={item.name}
                              onChange={(e) => inputAddUserChangeContent(e, index)} />
                          </div>
                        </>
                      )) : null
                  }
                </div>



                <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={createCoinSubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
