import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cityActions, userActions, statedataActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
// import DialogExample from "./components/DialogExample/DialogExample";
// import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateboardModal from "./components/CreateClassModal/CreateClassModal";
import UpdateboardModal from "./components/UpdateClassModal/UpdateClassModal";
import ViewMoreDetailsModal from './components/ViewClassModal/ViewClassModal';
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiPencilAlt } from "react-icons/hi";



class board extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fieldskyc: {},
      // errorskyc: {},
      stateId: null,
      fieldsboard: {
        options: [""]
      },
      errorsboard: {
        options: [""]
      },
      fieldsUpdateboard: {},
      errorsUpdateboard: {},
      viewRowData: {},
      boardCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateboardModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(cityActions.getBoardList(data));
    // this.props.dispatch(statedataActions.getAllStateData(data));
    // this.props.dispatch(cityActions.createSport(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.city.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsboard: {
          options: [""]
        },
        errorsboard: {
          options: [""]
        },
        boardCreateModal: false,
        UpdateboardModal: false,
        viewRowData: {},

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(cityActions.getBoardList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(cityActions.getBoardList(data));
  }
  disableboard = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status Board?',
      message: `Are you sure to change ${data.name} board?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(cityActions.disableBoard(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteboard = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} board?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(cityActions.deleteBoard(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleSelectState = (data) => {

    this.setState({ stateId: data });
  }
  handleOpenCreateModalMoreDetails = (data) => {

    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }
  handleOpenboardUpdateModal = (data) => {
    this.setState({ UpdateboardModal: true, fieldsUpdateboard: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(cityActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleboardHideModal = () => {
    this.setState({ boardCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleboardUpdateHideModal = () => {
    this.setState({ UpdateboardModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleHideboardUpdateModal = () => {
    this.setState({ UpdateboardModal: false });
  }
  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ boardCreateModal: true });
  }
  inputboardChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsboard = this.state.fieldsboard;
    let errorsboard = this.state.errorsboard;
    fieldsboard[name] = value;
    errorsboard[name] = "";
    console.log(name, value);
    this.setState({ fieldsboard, errorsboard });
  }

  inputChangeUpdateboard = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdateboard = this.state.fieldsUpdateboard;
    let errorsUpdateboard = this.state.errorsUpdateboard;
    fieldsUpdateboard[name] = value;
    errorsUpdateboard[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateboard, errorsUpdateboard });
  }



  createboardSubmit = () => {
    const { fieldsboard } = this.state;

    if (fieldsboard && fieldsboard.options) {
      // Create the request data object

      let datamap = fieldsboard && fieldsboard.options

      console.log("datamapdatamapdatamap", datamap);
      const convertedData = datamap.map((item) => ({
        name: item.name,
      })).filter((element) => (element.name));


      const reqData = convertedData
      console.log("reqDatareqDatareqData", reqData);
      this.props.dispatch(cityActions.createBoard(reqData, this.props));

      // let fieldsboard = this.state.fieldsboard.options[""]
      // let errorsboard = this.state.errorsboard.options[""]
      // this.setState({ fieldsboard: fieldsboard, errorsboard: errorsboard })
    }
  };

  addContentField = () => {
    const { fieldsboard } = this.state;
    fieldsboard.options.push('')
    this.setState({ fieldsboard })
    // console.log("fieldsboard  ", fieldsboard);
  }

  deleteContentField = (index) => {
    const { fieldsboard } = this.state;
    fieldsboard.options.splice(index, 1)
    this.setState({ fieldsboard });
  }

  setCurrentIndex = (currentConetntIndex) => {
    this.setState({ currentConetntIndex: currentConetntIndex });
    // console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e, index) => {
    e.preventDefault();
    const { value } = e.target;
    const { fieldsboard } = this.state;

    // Make a shallow copy of the options array
    const updatedOptions = [...fieldsboard.options];

    // Update the 'name' property of the object at the specified index
    updatedOptions[index] = { ...updatedOptions[index], name: value };

    this.setState({
      fieldsboard: {
        ...fieldsboard,
        options: updatedOptions,
      },
    });
  };

  updateboardSubmit = () => {

    if (this.handleValidationUpdateboard()) {
      let reqData = {
        "id": this.state.fieldsUpdateboard.id,
        "name": this.state.fieldsUpdateboard.name,
        // "shorName": this.state.fieldsUpdateboard.shorName,
      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(cityActions.updateBoard(reqData, paginationData));
    }

  }
  handleValidationUpdateboard = () => {
    let fieldsUpdateboard = this.state.fieldsUpdateboard;
    let errorsUpdateboard = {};
    let formIsValid = true;

    //name
    if (!fieldsUpdateboard["name"] || fieldsUpdateboard["name"] === "") {
      formIsValid = false;
      errorsUpdateboard["name"] = "Cannot be empty";
    }

    // console.log("errorsUpdateboarderrorsUpdateboard_errorsUpdateboarderrorsUpdateboard:", errorsUpdateboard);

    this.setState({ errorsUpdateboard: errorsUpdateboard });
    return formIsValid;
  }
  handleValidationboard = () => {
    let fieldsboard = this.state.fieldsboard;
    let errorsboard = {};
    let formIsValid = true;


    if (!fieldsboard["name"] || fieldsboard["name"] === "") {
      formIsValid = false;
      errorsboard["name"] = "Cannot be empty name";
    }
    // if (!fieldsboard["shorName"] || fieldsboard["shorName"] === "") {
    //   formIsValid = false;
    //   errorsboard["shorName"] = "Cannot be empty shorName";
    // }


    this.setState({ errorsboard: errorsboard });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  render() {

    let { city, statedata } = this.props;
    let { total, loading, boardItems } = city;


    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                {/* Wallet Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">Board List ({total})</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>
                    <button className="bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.handleOpenCreateModal()}> ADD </button>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-[#000] capitalize tracking-wider">#</th>

                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-[#000] capitalize tracking-wider"> Class name</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-[#000] capitalize tracking-wider">Date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-[#000] capitalize tracking-wider">Enable/Disable</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-[#000] capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Odd row */}
                              {
                                boardItems && boardItems.length > 0 ?
                                  boardItems.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white  border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap text-center font-medium text-sm text-gray-600">
                                        {this.state && this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.name ? element.name : "-"}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {moment(new Date(parseInt(element && element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <label class="flex justify-center items-center cursor-pointer targetablepx-4 tooltip">
                                          <div class="relative" onClick={() => this.disableboard(element)}>
                                            <input type="checkbox" id="toggleB" class="sr-only" />
                                            {element && element.isDisable && element.isDisable === false ? <div class="block bg-green-600 w-10 h-6 rounded-full"></div> : <div class="block bg-red-600 w-10 h-6 rounded-full"></div>}
                                            {/* <div class="block bg-gray-600 w-10 h-6 rounded-full"></div> */}
                                            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>{element && element.isDisable && element.isDisable === true ? "enable" : "disable"}</span>
                                          </div>
                                        </label>
                                      </td>

                                      <td className="flex justify-center items-center px-2 py-3 mt-2 text-gray-600 whitespace-nowrap">
                                        <span className="pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.handleOpenboardUpdateModal(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Edit</span>
                                            <HiPencilAlt className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>

                                        <span className="flex justify-center pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-500 cursor-pointer" onClick={() => this.deleteboard({ id: element && element.id, name: element && element.name })}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Delete</span>
                                            <HiTrash className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>
                                      </td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <nav className="relative z-0 flex justify-end mt-5">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>


                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        {/* <DialogExample /> */}

        <CreateboardModal
          boardCreateModal={this.state.boardCreateModal}
          fieldsboard={this.state.fieldsboard}
          errorsboard={this.state.errorsboard}
          inputboardChange={this.inputboardChange}
          handleSelectState={this.handleSelectState}
          createboardSubmit={this.createboardSubmit}
          handleboardHideModal={this.handleboardHideModal}
          handleFile={this.handleFile}
          addContentField={this.addContentField}
          setCurrentIndex={this.setCurrentIndex}
          deleteContentField={this.deleteContentField}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
        />

        <UpdateboardModal
          UpdateboardModal={this.state.UpdateboardModal}
          fieldsUpdateboard={this.state.fieldsUpdateboard}
          errorsUpdateboard={this.state.errorsUpdateboard}
          inputChangeUpdateboard={this.inputChangeUpdateboard}
          updateboardSubmit={this.updateboardSubmit}
          handleboardUpdateHideModal={this.handleboardUpdateHideModal}
          handleFile={this.handleFile}
        // allStatesItems={allStatesItems}

        />

        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={this.state.viewRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}

        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { city, users, statedata } = state;
  return {
    city,
    users,
    statedata
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(board);
