import React from "react";
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import Modal from 'react-modal';
import { CgLogIn } from 'react-icons/cg';


export default function CreateAddDealerModal(props) {

  let { cityCreateModal, handleHolidayHideModal, errorsHoliday, inputHolidayChange, createHolidaySubmit, fieldsHoliday, allStatesItems,
    setCurrentIndex, addContentField, deleteContentField, inputAddUserChangeContent

  } = props;
  // console.log("allStatesItems__MODAL::::", allStatesItems);

  return (

    <Modal
      isOpen={cityCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Add Holiday</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleHolidayHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">



                <div className="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Date : </label>
                  <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsHoliday && !errorsHoliday["date"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                    id="date" name="date" placeholder="Date" value={fieldsHoliday && fieldsHoliday.date ? fieldsHoliday.date : ''} type="date" onChange={inputHolidayChange} />
                  {errorsHoliday && errorsHoliday["date"] ?
                    <div className="text-xs text-red-500 invalid-feedback">
                      {errorsHoliday["date"]}
                    </div>
                    : null}
                </div>




                {/* <div className="w-full p-2">
                  <div className="relative">
                    <label for="frm-whatever" className='text-sm leading-7 text-gray-600'>Year</label>
                    <select className="w-full bg-gray-100  rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 p-2.5 focus:ring-indigo-200 text-base outline-none text-gray-700  leading-8 transition-colors duration-200 ease-in-out" onChange={inputHolidayChange}
                      id="year"
                      value={fieldsHoliday && fieldsHoliday.year ? fieldsHoliday.year : ''}
                      name="year">
                      <option value="">Please choose&hellip;</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                      <option value="2031">2031</option>
                      <option value="2032">2032</option>
                      <option value="2033">2033</option>
                      <option value="2034">2034</option>
                      <option value="2035">2035</option>
                      <option value="2036">2036</option>
                      <option value="2037">2037</option>
                      <option value="2038">2038</option>
                      <option value="2039">2039</option>
                      <option value="2040">2040</option>
                      <option value="2041">2041</option>
                      <option value="2042">2042</option>
                      <option value="2043">2043</option>
                      <option value="2044">2044</option>
                      <option value="2045">2045</option>
                      <option value="2046">2046</option>
                      <option value="2047">2047</option>
                      <option value="2048">2048</option>
                      <option value="2049">2049</option>
                      <option value="2050">2050</option>
                    </select>
                  </div>
                </div> */}

                {/* <div className="w-full p-2">
                  <div className="relative">
                    <label for="frm-whatever" className='text-sm leading-7 text-gray-600'>Month</label>
                    <select className="w-full bg-gray-100  rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 p-2.5 focus:ring-indigo-200 text-base outline-none text-gray-700  leading-8 transition-colors duration-200 ease-in-out" onChange={inputHolidayChange}
                      id="month"
                      value={fieldsHoliday && fieldsHoliday.month ? fieldsHoliday.month : ''}
                      name="month">
                      <option value="">Please choose&hellip;</option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </select>
                  </div>
                </div> */}

                <div className="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Description : </label>
                  <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsHoliday && !errorsHoliday["description"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
                    id="description" name="description" placeholder="Description" value={fieldsHoliday && fieldsHoliday.description ? fieldsHoliday.description : ''} type="text" onChange={inputHolidayChange} />
                  {errorsHoliday && errorsHoliday["description"] ?
                    <div className="text-xs text-red-500 invalid-feedback">
                      {errorsHoliday["description"]}
                    </div>
                    : null}
                </div>


                <div className="flex justify-end py-0 space-x-4">
                  <button className="bg-[#6F5617]  transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
                    onClick={createHolidaySubmit}
                  >Submit
                    <CgLogIn size={18} className="ml-2" />
                  </button>
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
