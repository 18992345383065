
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';

export const transactionService = {
    getTxWithdrawPendingAll,
    getUserTxList,
    getTxWithdrawSucess,
    getTxMaaExchWithdrawSucess,
    sendCoinApproveDFTC,
    getTxWithdrawPending,
    getTxAllAdmin,
    revertToMainBalance,
    revertToPayout,
    approveViaApi,
    getTxWithdrawSuccessAll,
    addCashFlow,
    approveMultiViaApi,

};
function logout() {
    window.sessionStorage.removeItem('transaction');
    history.push(`#/login`);
    window.location.reload();
}

function getTxMaaExchWithdrawSucess(data) {
    console.log("data me kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserTxListWithdraw`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let transactionObj = {
                getTxMaaExchWithdrawSucess: data.data
            }
            console.log(" i am in service lllll getTxMaaExchWithdrawSucess", transactionObj);

            return transactionObj;
        });
}
function getTxWithdrawSucess(data) {
    console.log("data me kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTxWithdrawSucess`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let transactionObj = {
                getUserTxListWithdraw: data.data
            }
            console.log(" i am in service lllll getTxWithdrawSucess", transactionObj);

            return transactionObj;
        });
}
function getTxWithdrawSuccessAll() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
    }
    return fetch(CONST.BACKEND_URL + `/getTxWithdrawSuccessAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let transactionObj = {
                getTxWithdrawSuccessAll: data.data
            }
            console.log(" i am in service lllll getTxWithdrawSuccessAll", transactionObj);

            return transactionObj;
        });
}
function getTxWithdrawPending(data) {
    console.log("data me kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTxWithdraw`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let transactionObj = {
                getTxWithdrawPending: data.data
            }
            console.log(" i am in service lllll getTxWithdrawPending", transactionObj);

            return transactionObj;
        });
}
function getTxWithdrawPendingAll(data) {
    console.log("data me kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTxWithdrawPendingAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let transactionObj = {
                getTxWithdrawPending: data.data
            }
            console.log(" i am in service lllll getTxWithdrawPending", transactionObj);

            return transactionObj;
        });
}

function revertToPayout(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/revertToPayout`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let transactionObj = {
                revertToPayout: data
            }
            console.log(" i am in service lllll revertToPayout", transactionObj);

            return transactionObj;
        });
}

function revertToMainBalance(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/revertToMainBalance`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let transactionObj = {
                revertToMainBalance: data
            }
            console.log(" i am in service lllll revertToMainBalance", transactionObj);

            return transactionObj;
        });
}

function getTxAllAdmin(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTxAllAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let transactionObj = {
                getTxAllAdmin: data.data
            }
            console.log(" i am in service lllll getTxAllAdmin", transactionObj);

            return transactionObj;
        });
}

function getUserTxList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getKycList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let transactionObj = {
                getUserTxList: data.data
            }
            console.log(" i am in service lllll getUserTxList", transactionObj);

            return transactionObj;
        });
}
function handleResponse(response) {
    //    console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function sendCoinApproveDFTC(data) {

    console.log("SERVICE________sendCoinApproveDFTC:::i", data);

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/sendCoinApproveDFTC`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                sendCoinApproveDFTC: data.data
            }
            console.log("I am in service sendCoinApproveDFTC", userObj);

            return userObj;
        });
}
function approveViaApi(data) {

    console.log("SERVICE________approveViaApi:::i", data);

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/approveViaApi`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                approveViaApi: data.data
            }
            console.log("I am in service approveViaApi", userObj);

            return userObj;
        });
}
function approveMultiViaApi(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/approveMultiViaApi`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                approveMultiViaApi: data.data
            }
            console.log("I am in service approveMultiViaApi", userObj);

            return userObj;
        });
}
function addCashFlow(data) {

    console.log("SERVICE________approveViaApi:::i", data);

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/addCashFlow`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                addCashFlow: data.data
            }
            console.log("I am in service addCashFlow", userObj);

            return userObj;
        });
}