import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions, cityActions, alertActions, dashboardActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import { isMobile } from "react-device-detect";
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert';
import { HiTrash } from "react-icons/hi";
import { RiLockPasswordFill } from "react-icons/ri";
import { CONST } from "../../_config/index"
import { authHeader } from "../../_helpers"


class Subadmin extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    // this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldsAddUser: {},
      errorsAddUser: {},
      fieldslogin: {},
      errorslogin: {},
      validateData: {},
      fieldstution: {},
      errorstution: {},
      offset: 0,
      size: 10,
      pageNo: 1,
      addState: true,
      filedIsTuitionOn: false,
      filedOwnPasswordUpdate: false,
    }
  }

  inputChange(e) {
    e.preventDefault();
    console.log("22222222222222222", e.target.value);
    let { name, value } = e.target;
    console.log('name, value', name, value);
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    console.log('fieldslogin[name]', fieldslogin);
    this.setState({ fieldslogin, errorslogin });
  }

  inputChangeRes = (e) => {
    console.log('e.target_______________________', e.target.value);
    e.preventDefault();
    let { name, value } = e.target;
    let fieldstution = this.state.fieldstution;
    let errorstution = this.state.errorstution;
    fieldstution[name] = value;
    errorstution[name] = "";
    console.log(name, value);
    this.setState({ fieldstution, errorstution });
  }


  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      // let { username, password } = this.state.fieldslogin;
      let { username, password } = this.state.fieldsAddUser;
      console.log("asdfasdf  ", username, password);
      let dataRes = {
        keyWord: "",
        pageNo: 1,
        size: 10
      }
      this.props.dispatch(userActions.createSubAdmin({ username: username, password: password, city: [this.state.fieldsAddUser.cityId] }, dataRes, this.props));
    }
  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "isActive": false,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status ?',
      message: `Are you sure to change status of ${data.username}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.updateAdminStatus(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldsAddUser;
    console.log('fieldslogin____________________', fieldslogin);
    let errorslogin = {};
    let formIsValid = true;

    //username
    if (!fieldslogin["username"] || fieldslogin["username"] === "") {
      formIsValid = false;
      errorslogin["username"] = "Cannot be empty";
    }

    //password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Please enter password";
    }
    //cityIdu
    if (!fieldslogin["cityId"] || fieldslogin["cityId"] <= 0) {
      formIsValid = false;
      errorslogin["cityId"] = "Please enter city";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      size: 10
    }
    this.props.dispatch(userActions.getAdminList(data));
    this.props.dispatch(cityActions.getAllCity());


  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        addState: true

      }
    }
    if (nextProps.dashboard.adminBalanceSuccess) {
      return {
        ...nextProps,
        filedOwnPasswordUpdate: false,
        fieldsAddUser: {},
        errorsAddUser: {},

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }
  handleOpenCreateModal = (url) => {
    this.setState({ addState: !this.state.addState })
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getAdminList(datatemp));
  }

  inputAddUserChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    // console.log("inputAddUserChange ::::: ", value);
    errorsAddUser[name] = "";
    this.setState({ fieldsAddUser, errorsAddUser });
  }

  handleOpenOwnPasswordUpdate = (data) => {
    this.setState({ filedOwnPasswordUpdate: true })
  }
  handleCloseOwnPasswordUpdate = () => {
    this.setState({ filedOwnPasswordUpdate: false })
  }

  handleOpenIsTuitionOn = (data) => {
    this.setState({ filedIsTuitionOn: true, validateData: data })
  }
  handleCloseIsTuitionOn = () => {
    this.setState({ filedIsTuitionOn: false, validateData: {} })
  }

  passwordValidation = () => {
    let fieldsAddUser = this.state.fieldsAddUser
    let errorsAddUser = {}
    let validtion = true

    if (!fieldsAddUser['password'] || fieldsAddUser['password'] === "") {
      errorsAddUser['password'] = `can't be empty password`
      validtion = false
    }

    if (!fieldsAddUser['confirmPassword'] || fieldsAddUser['confirmPassword'] === "") {
      errorsAddUser['confirmPassword'] = `can't be empty confirm password`
      validtion = false
    }
    this.setState({ errorsAddUser: errorsAddUser })
    return validtion
  }

  amountSubmitHandel = () => {

    if (this.passwordValidation()) {

      if (this.state.fieldsAddUser.password != this.state.fieldsAddUser.confirmPassword) {
        this.props.dispatch(alertActions.error('Password and confirm password are not matching'))
      } else {

        let resData = {
          id: this.state.validateData.id,
          newpassword: this.state.fieldsAddUser.password
        }
        let header = new Headers({
          'Content-Type': 'application/json',
          "Authorization": authHeader().Authorization
        });
        const requestOptions = {
          method: "POST",
          headers: header,
          body: JSON.stringify(resData)
        }
        fetch(CONST.BACKEND_URL + `/changeSubAdminPassword`, requestOptions)
        this.setState({ filedIsTuitionOn: false, validateData: {} })
      }
    }
  }

  passwordValidationAdmin = () => {
    let fieldsAddUser = this.state.fieldsAddUser
    let errorsAddUser = {}
    let validtion = true

    if (!fieldsAddUser['password'] || fieldsAddUser['password'] === "") {
      errorsAddUser['password'] = `can't be empty password`
      validtion = false
    }

    if (!fieldsAddUser['newpassword'] || fieldsAddUser['newpassword'] === "") {
      errorsAddUser['newpassword'] = `can't be empty new password`
      validtion = false
    }

    if (!fieldsAddUser['confirmPassword'] || fieldsAddUser['confirmPassword'] === "") {
      errorsAddUser['confirmPassword'] = `can't be empty confirm password`
      validtion = false
    }
    this.setState({ errorsAddUser: errorsAddUser })
    return validtion
  }

  amountSubmitHandelAdmin = () => {

    if (this.passwordValidationAdmin()) {

      if (this.state.fieldsAddUser.newpassword != this.state.fieldsAddUser.confirmPassword) {
        this.props.dispatch(alertActions.error('Password and confirm password are not matching'))
      } else {
        let resData = {
          currentpassword: this.state.fieldsAddUser.password,
          newpassword: this.state.fieldsAddUser.newpassword,
          confirmnewpassword: this.state.fieldsAddUser.confirmPassword
        }
        console.log('resData__________________________', resData);
        this.props.dispatch(dashboardActions.updateOwnPasswordAdmin(resData))

      }
    }
  }



  render() {
    let { users, city } = this.props;
    let { subAdminList, subAdminTotal, loading } = users;
    let { getAllCity } = city;

    console.log('this.state.fieldslogin', this.state.fieldslogin);



    return (
      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="overflow-hidden overflow-y-auto">
          <div className="bg-gray-100 relative">
            {!this.state.addState ?
              <div className="min-h-screen flex flex-col justify-center py-12 px-3 sm:px-6 lg:px-8 relative z-20">
                <div className=" mx-auto w-full  max-w-lg">
                  <div className="bg-white border border-gray-800  shadow rounded-xl px-6 ">
                    <div className="z-50 cursor-pointer modal-close flex justify-end py-2 bg-white">
                      <svg onClick={() => this.handleOpenCreateModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                      </svg>
                    </div>
                    <form autoComplete="off" className="py-10">
                      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <h2 className="mb-12 text-center text-3xl leading-9 font-bold capitalize text-dark-400 tracking-widest">Sub Admin Create</h2>
                      </div>
                      <div className="w-full space-y-2">
                        <label className="block text-sm mt-1 font-bold text-black md:text-left" for="username">User Name</label>
                        <div className="mt-1  shadow-sm relative">
                          <input className={`px-10 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!this.state.errorslogin["username"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                            id="username" name="username" placeholder="User name" type="text" onChange={this.inputAddUserChange} />
                          {this.state.errorslogin["username"] ?
                            <div className="invalid-feedback text-yellow-600">
                              {this.state.errorslogin["username"]}
                            </div>
                            : null}
                          <span className="absolute top-4 left-2 text-gray-500"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                          </svg></span>
                        </div>
                      </div>
                      <div className="w-full space-y-2">
                        <label className="block text-sm mt-1 font-bold text-black md:text-left" for="username">Password</label>
                        <div className="mt-1 rounded-md shadow-sm relative">
                          <input className={`border-1 px-10 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white text-black rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${!this.state.errorslogin["password"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                            id="password" name="password" placeholder="password" type="password" onChange={this.inputAddUserChange} />
                          {this.state.errorslogin["password"] ?
                            <div className="invalid-feedback text-yellow-600">
                              {this.state.errorslogin["password"]}
                            </div>
                            : null}
                          <span className="absolute top-4 left-2 text-gray-500 "><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                          </svg></span>
                        </div>
                      </div>
                      <div className="w-full space-y-2">
                        <label className="block text-sm mt-1 font-bold text-black md:text-left" for="username">Select City:</label>
                        <select class="form-select border-1 px-3 py-5 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border border-black" aria-label="Default select example" onChange={this.inputAddUserChange} id="cityId" name="cityId"
                          value={this.state.fieldsAddUser && this.state.fieldsAddUser["cityId"] ? this.state.fieldsAddUser["cityId"] : null} >
                          <option selected>Please Select City</option>
                          {
                            getAllCity && getAllCity && getAllCity.length > 0 ?
                              getAllCity.map((element, index) => (
                                <option value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                              )) : null
                          }
                        </select>
                        {this.state.errorslogin["cityId"] ?
                          <div className="invalid-feedback text-yellow-600">
                            {this.state.errorslogin["cityId"]}
                          </div>
                          : null}
                      </div>
                      <div className="w-64 sm:w-72 mx-auto py-6">
                        <button className="bg-blue-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-blue-400 hover:bg-blue hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-blue-700 transition duration-150 ease-in-out" type="submit" onClick={this.loginSubmit}>Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              :
              null}


            {this.state.addState ?
              <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">

                <div className="flex flex-wrap w-full ">
                  <div className="ml-0 mr-auto">
                    <h3 className="text-lg font-semibold leading-6 tracking-widest text-center capitalize md:text-2xl md:leading-9 text-dark-400">Active Sub Admin</h3>
                  </div>
                  <div className="ml-0 mr-auto">
                    <button className="flex justify-end py-1 pt-1 ml-2 font-bold text-white bg-[#FBB00C] border rounded px-7" onClick={() => this.handleOpenOwnPasswordUpdate()}> Admin Own Password Update </button>
                  </div>
                  <div className="relative mt-5 lg:mt-0">
                    <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    </div>
                    <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                  </div>
                  <button className="flex justify-end py-1 pt-1 ml-2 font-bold text-white bg-[#FBB00C] border rounded px-7" onClick={() => this.handleOpenCreateModal()}> ADD </button>
                </div>

                <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                  <div className="max-w-full overflow-x-auto ">
                    <div className="inline-block min-w-full ">
                      <div className="overflow-x-auto">
                        <table className="min-w-full border-0 divide-y divide-gray-800">
                          <thead className="bg-[#FBB00C] rounded-t">
                            <tr className="">
                              <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">#</th>
                              <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">user name</th>
                              <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">admin Type</th>
                              <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">City</th>
                              <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">Date</th>
                              <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">Status</th>
                              <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">Actions</th>
                              {/* <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">ACTIONS</th> */}
                              {/* <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">Actions</th> */}
                            </tr>
                          </thead>

                          {/* Table Row Section */}
                          <tbody>
                            {
                              subAdminList && subAdminList.length > 0 ?
                                subAdminList.map((element, index) => (<React.Fragment key={element.id}>
                                  <>{!element.isActive ?
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.username}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.adminType}</td>

                                      {element && element.city && element.city.length > 0 ?
                                        element.city.map((inerEle, index) =>
                                          <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                            {inerEle && inerEle.name}</td>
                                        ) :
                                        null}


                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <label class=" cursor-pointer targetablepx-4 tooltip">
                                          <span className="inline-block pl-1">

                                            {element && element.isActive == false ?
                                              <button className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400"
                                                onClick={() => this.disableUser(element)}
                                              >
                                                Enable
                                              </button>
                                              :
                                              <button className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-red-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-400"
                                                onClick={() => this.disableUser(element)}
                                              >
                                                Disable
                                              </button>
                                            }
                                          </span>
                                        </label>
                                      </td>

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <label class=" cursor-pointer targetablepx-4 tooltip">
                                          <span className="inline-block pl-1">

                                            <button className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400"
                                              onClick={() => this.handleOpenIsTuitionOn(element)}
                                            >
                                              Change Password
                                            </button>
                                          </span>
                                        </label>
                                      </td>
                                    </tr>
                                    : null}</>
                                </React.Fragment>))
                                : (<tr className="bg-white bg-opacity-5 " >
                                  <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                </tr>)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>


                    {this.state.filedIsTuitionOn ?
                      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                        <div className="border border-[#25BF63] shadow-lg modal-container bg-white w-96 mx-auto rounded-[40px]  z-50 overflow-y-auto">
                          <div className="modal-content py-4 text-left px-6">
                            <div className="modal-close cursor-pointer z-50 bg-[#25BF63] w-6 h-6 rounded-full flex justify-center items-center">
                              <svg
                                onClick={() => this.handleCloseIsTuitionOn()}
                                className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                </path>
                              </svg>
                            </div>
                            <div className="my-5">
                              <form class="max-w-sm mx-auto">
                                <div class="mb-5">
                                  <label for="email" class="block mb-2 text-sm font-bold text-gray-900">Password</label>
                                  <input type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg px-2 py-3 block w-full" placeholder="Password" name='password' required onChange={this.inputAddUserChange} />
                                  {this.state.errorsAddUser && this.state.errorsAddUser["Password"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsAddUser["Password"]}
                                    </div>
                                    : null}
                                </div>
                                <div class="mb-5">
                                  <label for="email" class="block mb-2 text-sm font-bold text-gray-900">confirm Password</label>
                                  <input type="password" id="confirmPassword" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg px-2 py-3 block w-full" placeholder="confirm Password" name='confirmPassword' required onChange={this.inputAddUserChange} />
                                  {this.state.errorsAddUser && this.state.errorsAddUser["confirmPassword"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsAddUser["confirmPassword"]}
                                    </div>
                                    : null}
                                </div>
                                <button type="button" class="text-white bg-[#25BF63] font-bold rounded-full text-sm w-full  px-5 py-2.5 text-center" onClick={() => this.amountSubmitHandel()}>Change Password</button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      null
                    }

                    {this.state.filedOwnPasswordUpdate ?
                      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                        <div className="border border-[#25BF63] shadow-lg modal-container bg-white w-96 mx-auto rounded-[40px]  z-50 overflow-y-auto">
                          <div className="modal-content py-4 text-left px-6">
                            <div className="modal-close cursor-pointer z-50 bg-[#25BF63] w-6 h-6 rounded-full flex justify-center items-center">
                              <svg
                                onClick={() => this.handleCloseOwnPasswordUpdate()}
                                className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                </path>
                              </svg>
                            </div>
                            <div className="my-5">
                              <form class="max-w-sm mx-auto">
                                <div class="mb-5">
                                  <label for="email" class="block mb-2 text-sm font-bold text-gray-900">Current Password</label>
                                  <input type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg px-2 py-3 block w-full" placeholder="Current Password" name='password' required onChange={this.inputAddUserChange} />
                                  {this.state.errorsAddUser && this.state.errorsAddUser["Password"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsAddUser["Password"]}
                                    </div>
                                    : null}
                                </div>
                                <div class="mb-5">
                                  <label for="email" class="block mb-2 text-sm font-bold text-gray-900">New Password</label>
                                  <input type="password" id="newpassword" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg px-2 py-3 block w-full" placeholder="newPassword" name='newpassword' required onChange={this.inputAddUserChange} />
                                  {this.state.errorsAddUser && this.state.errorsAddUser["newPassword"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsAddUser["newPassword"]}
                                    </div>
                                    : null}
                                </div>
                                <div class="mb-5">
                                  <label for="email" class="block mb-2 text-sm font-bold text-gray-900">confirm Password</label>
                                  <input type="password" id="confirmPassword" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg px-2 py-3 block w-full" placeholder="confirm Password" name='confirmPassword' required onChange={this.inputAddUserChange} />
                                  {this.state.errorsAddUser && this.state.errorsAddUser["confirmPassword"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsAddUser["confirmPassword"]}
                                    </div>
                                    : null}
                                </div>
                                <button type="button" class="text-white bg-[#25BF63] font-bold rounded-full text-sm w-full  px-5 py-2.5 text-center" onClick={() => this.amountSubmitHandelAdmin()}>Update password</button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      null
                    }


                  </div>
                  {/* Table Pagination Section */}
                  {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          subAdminTotal && subAdminTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={subAdminTotal / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          subAdminTotal && subAdminTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={subAdminTotal / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  }
                </div>
              </section>
              : null
            }
          </div>
        </div>
        {/* Whole Structure End*/}


      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users, city, dashboard } = state;
  return {
    loggingIn,
    dashboard,
    otpSent,
    user,
    users,
    city
  };
}
export default connect(mapStateToProps)(Subadmin);
