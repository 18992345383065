export const bannerConstants = {

    GET_ALL_BANNER_REQUEST : "GET_ALL_BANNER_REQUEST",
    GET_ALL_BANNER_SUCCESS : "GET_ALL_BANNER_SUCCESS",
    GET_ALL_BANNER_FAILURE : "GET_ALL_BANNER_FAILURE",

    ADD_BANNER_REQUEST : "ADD_BANNER_REQUEST",
    ADD_BANNER_SUCCESS : "ADD_BANNER_SUCCESS",
    ADD_BANNER_FAILURE : "ADD_BANNER_FAILURE",

    UPDATE_BANNER_REQUEST : "UPDATE_BANNER_REQUEST",
    UPDATE_BANNER_SUCCESS : "UPDATE_BANNER_SUCCESS",
    UPDATE_BANNER_FAILURE : "UPDATE_BANNER_FAILURE",

    DELETE_BANNER_REQUEST :"DELETE_BANNER_REQUEST",
    DELETE_BANNER_SUCCESS :"DELETE_BANNER_SUCCESS",
    DELETE_BANNER_FAILURE :"DELETE_BANNER_FAILURE",

    UPDATE_BANNER_STATUS_REQUEST : "UPDATE_BANNER_STATUS_REQUEST",
    UPDATE_BANNER_STATUS_SUCCESS : "UPDATE_BANNER_STATUS_SUCCESS",
    UPDATE_BANNER_STATUS_FAILURE : "UPDATE_BANNER_STATUS_FAILURE",

};
