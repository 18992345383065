import React from "react";

import Modal from 'react-modal';
const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};

export default function ViewPendingModal(props) {

  let { viewMoreDetailsModal, handleMoreDetailsClose, viewRowData, educationDetails
  } = props;
  console.log("viewRowDataModal::", viewRowData);

  return (

    <Modal
      isOpen={viewMoreDetailsModal}
      style={customStyles}
      ariaHideApp={false}
    // contentLabel="Example Modal"
    >


      {viewRowData ?
        <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" >
          <div className="border border-blue-500 modal-container bg-white w-11/12 md:max-w-2xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="modal-content  text-left ">

              <div className="flex justify-between items-center pb-3 bg-blue-100 text-blue-500 text-lg px-6 py-4">
                <p className="text-2xl font-bold">User Details</p>
                <div className="modal-close cursor-pointer font-extrabold z-50 text-blue-700 rounded-full p-3 hover:bg-blue-500 hover:text-white">
                  <svg onClick={() => handleMoreDetailsClose()} className="fill-current " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                    </path>
                  </svg>
                </div>
              </div>




              <div className=" p-4">

                <div className="border rounded-lg p-4 bg-gray-100 text-gray-700 divide-y divide-gray-300 space-y-2">
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Name :</span>
                    <span > {viewRowData && viewRowData.userId && viewRowData.userId.fullName ? viewRowData.userId.fullName : "NA"}</span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Gender : </span>
                    <span > {viewRowData && viewRowData.userId && viewRowData.userId.gender ? viewRowData.userId.gender : "NA"} </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Email :</span>
                    <span > {viewRowData && viewRowData.userId && viewRowData.userId.email ? viewRowData.userId.email : "NA"}  </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Date of Birth :</span>
                    <span > {viewRowData && viewRowData.userId && viewRowData.userId.dob ? viewRowData.userId.dob : "NA"}  </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Address :</span>
                    <span >{viewRowData && viewRowData.userId && viewRowData.userId.address ? viewRowData.userId.address : "NA"}  </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>City :</span>
                    <span >{viewRowData && viewRowData.userId && viewRowData.userId.city && viewRowData.userId.city.name ? viewRowData.userId.city.name
                      : "NA"} </span>
                  </p>
                  <p className="py-1 flex justify-between  space-x-4">
                    <div>
                      <span>PinCode
                        :</span>
                    </div>
                    <div>

                      {viewRowData && viewRowData.userId && viewRowData.userId.pinCode && viewRowData.userId.pinCode.length > 0 ?
                        viewRowData.userId.pinCode.map((element, index) =>
                          <>
                            <span className='flex flex-col'>{element && element.pinNo ? element.pinNo : "-"} </span>
                          </>
                        ) : 'NA'}
                    </div>
                  </p>

                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>School name :</span>
                    <span >{educationDetails && educationDetails.schoolName ? educationDetails.schoolName : "NA"} </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>School passing year :</span>
                    <span >{educationDetails && educationDetails.schoolPassYear ? educationDetails.schoolPassYear : "NA"} </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>college name :</span>
                    <span >{educationDetails && educationDetails.ugClgName ? educationDetails.ugClgName : "NA"} </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>college Course :</span>
                    <span >{educationDetails && educationDetails.ugCourse ? educationDetails.ugCourse : "NA"} </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>college passing year :</span>
                    <span >{educationDetails && educationDetails.ugPassYear ? educationDetails.ugPassYear : "NA"} </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Teaching mode :</span>
                    <span >{educationDetails && educationDetails.teachingMode && educationDetails.teachingMode[0] ? educationDetails.teachingMode[0] : "NA"}{educationDetails && educationDetails.teachingMode && educationDetails.teachingMode[1] ? "," + educationDetails.teachingMode[1] : "NA"} </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Teaching course

                      :</span>
                    {educationDetails && educationDetails.teachingCourse && educationDetails.teachingCourse.length > 0 ?
                      educationDetails.teachingCourse.map((element, index) =>
                        <>
                          <span >{element && element.name ? element.name : "NA"} </span>
                        </>
                      ) : 'NA'}
                  </p>
                  {/* <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Teaching mode :</span>
                    <span >{educationDetails && educationDetails.teachingMode && educationDetails.teachingMode[0] ? educationDetails.teachingMode[0] : "NA"}{educationDetails && educationDetails.teachingMode && educationDetails.teachingMode[1] ? "," + educationDetails.teachingMode[1] : "NA"} </span>
                  </p> */}

                </div>

              </div>




              {/* {
                 JSON.stringify(viewRowData && viewRowData.kycDocList ? viewRowData.kycDocList : null)
               } */}


              {/* <>

                 {
                   viewRowData && viewRowData.kycDocList && viewRowData.kycDocList.length > 0 ?
                     viewRowData.kycDocList.map((element, index) => (<React.Fragment >

                       <div className="grid grid-rows-1 grid-flow-col w-3/5">




                         {
                           element.imageBackLink ?

                             <div className="justify-center lg:justify-start text-white md:py-2 md:px-5 py-2 px-3 bg-shine flex flex-wrap rounded-md items-center mt-2 ">

                               <div className="ml-2 text-center lg:text-left ">
                                 <h4 className="2xl:text-xl lg:text-lg font-semibold tracking-widest">Doc Name : {element && element.docTypeId && element.docTypeId.name ? element.docTypeId.name : "-"}</h4>
                                 <p className="text-base font-light">docNumber :{element && element.docNumber ? element.docNumber : "-"} </p>
                               </div>
                               <div className="ml-5 " >
                                 <label className="ml-5">IMAGE FRONT</label>
                                 <img className="w-16 h-16 " src={element && element.imageLink ? element.imageLink : "-"} alt=" " />
                               </div>
                               <div className="ml-5 " >
                                 <label>IMAGE BACK</label>
                                 <img className="w-16 h-16 " src={element && element.imageBackLink ? element.imageBackLink : "-"} alt=" " />
                               </div>
                             </div>

                             :

                             <div className="justify-center lg:justify-start text-white md:py-2 md:px-5 py-2 px-3 bg-shine flex flex-wrap rounded-md items-center mt-2 ">

                               <div className="ml-2 text-center lg:text-left ">
                                 <h4 className="2xl:text-xl lg:text-lg font-semibold tracking-widest">Doc Name:{element && element.docTypeId && element.docTypeId.name ? element.docTypeId.name : "-"}</h4>
                                 <p className="text-base font-light">docNumber :{element && element.docNumber ? element.docNumber : "-"} </p>
                              
                               </div>
                               <div className="ml-5 " >
                                 <label className="ml-5">IMAGE FRONT</label>
                                 <img className="w-16 h-16 " src={element && element.imageLink ? element.imageLink : "-"} alt=" " />
                               </div>

                             </div>


                         }




                       </div>


                     </React.Fragment>))
                     : null
                 }
               </> */}




            </div>
          </div>
        </div>
        : null
      }


    </Modal>



  );
}
