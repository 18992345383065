export const holidayConstants = {

    GETALL_HOLIDAY_REQUEST: 'GETALL_HOLIDAY_REQUEST',
    GETALL_HOLIDAY_SUCCESS: 'GETALL_HOLIDAY_SUCCESS',
    GETALL_HOLIDAY_FAILURE: 'GETALL_HOLIDAY_FAILURE',

    GET_LIST_HOLIDAY_REQUEST: 'GET_LIST_HOLIDAY_REQUEST',
    GET_LIST_HOLIDAY_SUCCESS: 'GET_LIST_HOLIDAY_SUCCESS',
    GET_LIST_HOLIDAY_FAILURE: 'GET_LIST_HOLIDAY_FAILURE',

    ADD_HOLIDAY_REQUEST: 'ADD_HOLIDAY_REQUEST',
    ADD_HOLIDAY_SUCCESS: 'ADD_HOLIDAY_SUCCESS',
    ADD_HOLIDAY_FAILURE: 'ADD_HOLIDAY_FAILURE',

    DELETE_HOLIDAY_REQUEST: 'DELETE_HOLIDAY_REQUEST',
    DELETE_HOLIDAY_SUCCESS: 'DELETE_HOLIDAY_SUCCESS',
    DELETE_HOLIDAY_FAILURE: 'DELETE_HOLIDAY_FAILURE',

    UPDATE_HOLIDAY_REQUEST: 'UPDATE_HOLIDAY_REQUEST',
    UPDATE_HOLIDAY_SUCCESS: 'UPDATE_HOLIDAY_SUCCESS',
    UPDATE_HOLIDAY_FAILURE: 'UPDATE_HOLIDAY_FAILURE',

    DISABLE_HOLIDAY_REQUEST: 'DISABLE_HOLIDAY_REQUEST',
    DISABLE_HOLIDAY_SUCCESS: 'DISABLE_HOLIDAY_SUCCESS',
    DISABLE_HOLIDAY_FAILURE: 'DISABLE_HOLIDAY_FAILURE',

};
