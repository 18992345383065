import React, { Component } from 'react';
import { connect } from 'react-redux';
import { packageActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import CreatePackageModal from "./components/CreatePackageModal/CreatePackageModal";
import UpdatePackageModal from "./components/UpdatePackageModal/UpdatePackageModal";
import { confirmAlert } from 'react-confirm-alert';
import { HiTrash, HiPencilAlt } from "react-icons/hi";

class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldspackages: {},
      errorspackages: {},
      fieldspackagesUpdate: {},
      errorspackagesUpdate: {},
      packagesCreateModal: false,
      packagesUpdateModal: false,
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      formData: {
        "name": "",
        "days": "",
        "per": "",
      },
      updateformData: {
        "id": "",
        "name": "",
        "days": "",
        "per": "",
      },
    }
  }
  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(packageActions.getPackageList(temp));
    this.props.dispatch(packageActions.getUserPackage(temp));
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.packages.addUserSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "key": "",
        },
        packagesCreateModal: false,
        packagesUpdateModal: false,
        fieldspackages: {},
        errorspackages: {},
        fieldspackagesUpdate: {},
        errorspackagesUpdate: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(packageActions.getUserPackage(datatemp));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let temp = {
      "keyWord": value.toLowerCase(),
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(packageActions.getPackageList(temp));
  }

  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ packagesCreateModal: true });
  }
  handlePackageHideModal = () => {
    this.setState({ packagesCreateModal: false });
    this.setState({ packagesUpdateModal: false });
  }

  updatehandleOpenCreateModal = (data) => {

    this.setState({ packagesUpdateModal: true, fieldspackagesUpdate: data });
  }

  onDeleteClick = (data) => {
    console.log(data);
    confirmAlert({
      title: 'Confirm to Delete?',
      message: 'Are you sure to Delete ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(packageActions.deletePackage(data))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  onDisable = (data) => {
    let tempdata = {
      "id": data.id,
      // "keyWord": this.state.keyWord,
      // "pageNo": this.state.page,
      // // "roll_id": 0,
      // "size": this.state.size
    }
    // this.props.dispatch(packageActions.disableKeyfeature(tempdata));

    confirmAlert({
      title: 'Confirm to Disable?',
      message: `Are you sure to Disable ${data.name}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(packageActions.disablePackage(tempdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldspackages = this.state.fieldspackages;
    let errorspackages = this.state.errorspackages;
    fieldspackages[name] = value;
    errorspackages[name] = "";
    console.log(name, value);
    this.setState({ fieldspackages, errorspackages });
  }

  inputChangeUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldspackagesUpdate = this.state.fieldspackagesUpdate;
    let errorspackagesUpdate = this.state.errorspackagesUpdate;
    fieldspackagesUpdate[name] = value;
    errorspackagesUpdate[name] = "";
    this.setState({ fieldspackagesUpdate, errorspackagesUpdate });
  }

  packageSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationPackage()) {
      let { name, amount, session } = this.state.fieldspackages;
      // console.log("asdfasdf  ", name, days, per);
      this.props.dispatch(packageActions.createPackage({ name: name, amount: amount, session: session }, this.props));
    }
  }

  packageUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationPackageUpdate()) {
      let { name, amount, session, id } = this.state.fieldspackagesUpdate;
      // console.log("asdfasdf  ", name, days);
      this.props.dispatch(packageActions.updatePackage({ name: name, amount: amount, session: session, id: id }, this.props));
    }
  }
  handleValidationPackageUpdate = () => {
    let fieldspackagesUpdate = this.state.fieldspackagesUpdate;
    let errorspackagesUpdate = {};
    let formIsValid = true;

    //name
    if (!fieldspackagesUpdate["name"] || fieldspackagesUpdate["name"] === "") {
      formIsValid = false;
      errorspackagesUpdate["name"] = "Cannot be empty";
    }

    //amount
    if (!fieldspackagesUpdate["amount"]) {
      formIsValid = false;
      errorspackagesUpdate["amount"] = "Please enter amount!";
    }



    //session
    if (!fieldspackagesUpdate["session"]) {
      formIsValid = false;
      errorspackagesUpdate["session"] = "Please enter session!";
    }

    this.setState({ errorspackagesUpdate: errorspackagesUpdate });
    return formIsValid;
  }

  handleValidationPackage = () => {
    let fieldspackages = this.state.fieldspackages;
    let errorspackages = {};
    let formIsValid = true;

    //Name
    if (!fieldspackages["name"] || fieldspackages["name"] === "") {
      formIsValid = false;
      errorspackages["name"] = "Cannot be empty";
    }

    //amount
    if (!fieldspackages["amount"]) {
      formIsValid = false;
      errorspackages["amount"] = "Please enter amount!";
    }



    //session
    if (!fieldspackages["session"]) {
      formIsValid = false;
      errorspackages["session"] = "Please enter session!";
    }

    this.setState({ errorspackages: errorspackages });
    return formIsValid;
  }
  render() {

    let { packages } = this.props;
    let { items, total, UserPackageItems, UserPackageTotal } = packages;
    // console.log("items in render", JSON.stringify(packages));
    console.log('UserPackageItems__________________', UserPackageItems);

    return (
      <>

        <div >
          <LoadingOverlay
            active={false}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {/* Main Content */}
        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                {/* Wallet Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-[#F3F4F6] rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">packages List</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>
                    <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.handleOpenCreateModal()}> ADD </button>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full space-y-4 ">
                        <div className="overflow-hidden bg-white ">
                          <table className="min-w-full rounded-md">
                            <thead className="bg-white ">
                              <tr className=" ">
                                {/* <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">#</th> */}
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Package Name</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">amount</th>

                                {/* <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">session</th> */}
                                {/* <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Reward %</th> */}
                                {/* <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Status</th> */}
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Action</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">created On</th>
                                {/* <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Actions</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {/* Odd row */}
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white ">
                                      {/* <td className="px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">
                                        {this.state.offset + index + 1}</td> */}

                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element && element.name ? element.name : "-"}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element && element.amount ? element.amount : "-"}</td>
                                      {element.isDisable ? <td className="px-6 py-3 whitespace-nowrap  text-sm text-gray-600 space-y-1">
                                        <div onClick={() => this.onDisable(element)}>
                                          <button type="button" className='bg-[#dd2b2b] px-2 py-1 w-20 text-[#fff] font-bold rounded-full'>Enable</button>
                                        </div>
                                      </td>
                                        :
                                        <td className="px-6 py-3 whitespace-nowrap  text-sm text-gray-600 space-y-1">
                                          <div onClick={() => this.onDisable(element)}>
                                            <button type="button" className='bg-[#25BF63] px-2 py-1 w-20 text-[#fff] font-bold rounded-full'>Disable</button>
                                          </div>
                                        </td>}

                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>

                                      {/* <td className="flex px-2 py-3 mt-2 text-gray-600 whitespace-nowrap">
                                        <span className="pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.updatehandleOpenCreateModal(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Edit</span>
                                            <HiPencilAlt className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>

                                        <span className="flex justify-center pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-500 cursor-pointer" onClick={() => this.onDeleteClick({ id: element.id })}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Delete</span>
                                            <HiTrash className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>
                                      </td> */}
                                      <div div className='px-6 py-2 '>
                                        <p className='inline-flex' onClick={() => this.updatehandleOpenCreateModal(element)}><span className='pr-2'><HiPencilAlt className='w-5 h-5 block object-contain' /></span> Edit</p>
                                      </div>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>

                          </table>
                        </div>

                        <div>
                          <h2 className='md:text-2xl text-lg leading-6 md:leading-9 font-semibold capitalize text-dark-400 tracking-wider'>Registration History</h2>
                        </div>
                        <div className="overflow-hidden ">
                          <table className="min-w-full divide-y-4 divide-[#F3F4F6]">
                            <thead className=" ">
                              <tr className="bg-white rounded-full">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider rounded-l-full">Tutor Name</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider ">Contact Number</th>

                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider ">Amount Received</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider ">Registration date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider ">Renewal Date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider rounded-r-full">Status</th>
                              </tr>
                            </thead>
                            <tbody className="min-w-full divide-y-8 divide-[#F3F4F6]">
                              {/* Odd row */}
                              {
                                UserPackageItems && UserPackageItems.length > 0 ?
                                  UserPackageItems.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white ">
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600 rounded-l-full">
                                        {element && element.tutorId && element.tutorId.fullName ? element.tutorId.fullName : 'NA'}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element && element.tutorId && element.tutorId.mobNo ? element.tutorId.mobNo : 'NA'}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element && element.amount ? element.amount : '0'}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {moment(new Date(parseInt(element && element.createdAt ? element.createdAt : null))).utcOffset("+05:30").format("YYYY-MM-DD")}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {moment(new Date(parseInt(element && element.renewalDate ? element.renewalDate : null))).utcOffset("+05:30").format("YYYY-MM-DD")}
                                      </td>
                                      {element.isDisable ?
                                        <td className="px-6 py-3 whitespace-nowrap text-sm text-red-600 rounded-r-full">
                                          In-active</td>
                                        :
                                        <td className="px-6 py-3 whitespace-nowrap text-sm text-green-600 rounded-r-full">
                                          Active</td>

                                      }
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <nav className="relative z-0 flex justify-end mt-5">
                      {
                        UserPackageTotal && UserPackageTotal > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={UserPackageTotal / this.state.size}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>


                  </div>
                </section>
              </div>
            </div >
          </main >
        </div >

        <CreatePackageModal

          packagesCreateModal={this.state.packagesCreateModal}
          fieldspackages={this.state.fieldspackages}
          errorspackages={this.state.errorspackages}
          inputChange={this.inputChange}
          packageSubmit={this.packageSubmit}
          handlePackageHideModal={this.handlePackageHideModal}

        />
        <UpdatePackageModal

          packagesUpdateModal={this.state.packagesUpdateModal}
          fieldspackagesUpdate={this.state.fieldspackagesUpdate}
          errorspackagesUpdate={this.state.errorsappsettinUpdate}
          inputChangeUpdate={this.inputChangeUpdate}
          packageUpdateSubmit={this.packageUpdateSubmit}
          handlePackageHideModal={this.handlePackageHideModal}

        />


      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, packages } = state;
  return {
    users,
    packages,
    authentication
  };
}
export default connect(mapStateToProps)(Packages);
