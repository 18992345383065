import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./style.css"
import { MdOutlineRemoveRedEye, MdLocationPin } from "react-icons/md";
import { FaArrowCircleRight } from "react-icons/fa";

class UserActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      showMe: true
    }
    this.handleLanguageToggle = this.handleLanguageToggle.bind(this);
  }
  componentDidMount() {
    let data = {
      "id": this.props.match.params.id
    }
    this.props.dispatch(userActions.getUserKycDetailsById(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsAddUser: {},
        errorsAddUser: {},
        addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        adBalanceModal: false,
        updateUserNameModal: false,
        updateEmailModal: false,
        fieldsUserName: {},
        errorsUserName: {},
        fieldsEmail: {},
        errorsEmail: {},

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }

  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status ',
      message: `Are you sure to disable ${data.fullName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.disableUser(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      // "userType": "ACTIVE",
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": value.toLowerCase(),
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(data));
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      // "userType": "ACTIVE",
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(datatemp));
  }

  moreDetailsPage = (data) => {
    this.props.history.push(`/app/tutorMoreDetials/${data.id}`)
  }

  KycResponseHandel = (data) => {
    this.props.history.push(`/app/tutorMoreDetials/${data.id}`)
  }



  handleLanguageToggle() {
    this.setState(prevState => ({
      showMe: !prevState.showMe // Toggle showMe state
    }));
  }



  approveOpenStatusHandel1 = (data, status) => {
    this.setState({ approveStatus1: true, elementValue: data })
  }
  approveClodeStatusHandel1 = (data, status) => {
    this.setState({ approveStatus1: false, fieldsCoin: {} })
  }

  render() {

    let { users, city } = this.props;
    let { getUserList, total, loading, loginToThisAccountItems, userKycDetails
    } = users;
    let { getAllClass } = city

    console.log("rendor method]]]]]]]]12121212121212121212121", userKycDetails);


    console.log('elementValue___________________', this.state.elementValue);
    const { showMe } = this.state;
    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="w-11/12 mx-auto lg:p-10 p-4 overflow-hidden overflow-y-auto">
          {/* <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">

                  <div className="grid grid-rows-1 grid-flow-col">
                    <div className=" text-white p-4 bg-shine rounded-md items-start mt-2 w-full ">

                      <div className="flex justify-center space-x-4 mt-4">
                        <div className="space-y-2 bg-white p-6 " >
                          <label className="text-black">Selfie / Selfie Photo</label>
                          <img className="w-[200px]" src={userKycDetails && userKycDetails.imageUserLink ? userKycDetails.imageUserLink : "-"} alt=" " />
                        </div>


                        <div className="space-y-2" >
                          <label className="">Image Front / Govt. Id Proof </label>
                          <img className="w-[200px]" src={userKycDetails && userKycDetails.imagefrontLink ? userKycDetails.imagefrontLink : "-"} alt=" " />
                        </div>

                        <div className="space-y-2" >
                          <label className="">Image Back / Govt. Id Proof</label>
                          <img className="w-[200px]" src={userKycDetails && userKycDetails.imageBackLink ? userKycDetails.imageBackLink : "-"} alt=" " />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main > */}
          <div className='grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6'>
            <div className="space-y-2 bg-[#FBB00C] lg:p-6 p-4 flex flex-col justify-center items-center" >
              <label className="text-black text-xl font-bold">Selfie / Selfie Photo</label>
              <img className="w-[200px]" src={userKycDetails && userKycDetails.imageUserLink ? userKycDetails.imageUserLink : "-"} alt=" " />
            </div>


            <div className="space-y-2 bg-[#FBB00C] lg:p-6 p-4 flex flex-col justify-center items-center" >
              <label className="text-black text-xl font-bold">Image Front / Govt. Id Proof </label>
              <img className="w-[200px]" src={userKycDetails && userKycDetails.imagefrontLink ? userKycDetails.imagefrontLink : "-"} alt=" " />
            </div>

            <div className="space-y-2 bg-[#FBB00C] lg:p-6 p-4 flex flex-col justify-center items-center" >
              <label className="text-black text-xl font-bold">Image Back / Govt. Id Proof</label>
              <img className="w-[200px]" src={userKycDetails && userKycDetails.imageBackLink ? userKycDetails.imageBackLink : "-"} alt=" " />
            </div>
          </div>
        </div>


        <DialogExample />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { users, city } = state;
  return {
    users, city
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(UserActive);
