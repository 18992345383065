export const documentConstants = {

    GETALL_DOCUMENT_REQUEST: 'GETALL_DOCUMENT_REQUEST',
    GETALL_DOCUMENT_SUCCESS: 'GETALL_DOCUMENT_SUCCESS',
    GETALL_DOCUMENT_FAILURE: 'GETALL_DOCUMENT_FAILURE',

    GET_LIST_DOCUMENT_REQUEST: 'GET_LIST_DOCUMENT_REQUEST',
    GET_LIST_DOCUMENT_SUCCESS: 'GET_LIST_DOCUMENT_SUCCESS',
    GET_LIST_DOCUMENT_FAILURE: 'GET_LIST_DOCUMENT_FAILURE',

    ADD_DOCUMENT_REQUEST: 'ADD_DOCUMENT_REQUEST',
    ADD_DOCUMENT_SUCCESS: 'ADD_DOCUMENT_SUCCESS',
    ADD_DOCUMENT_FAILURE: 'ADD_DOCUMENT_FAILURE',

    DELETE_DOCUMENT_REQUEST: 'DELETE_DOCUMENT_REQUEST',
    DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
    DELETE_DOCUMENT_FAILURE: 'DELETE_DOCUMENT_FAILURE',

    UPDATE_DOCUMENT_REQUEST: 'UPDATE_DOCUMENT_REQUEST',
    UPDATE_DOCUMENT_SUCCESS: 'UPDATE_DOCUMENT_SUCCESS',
    UPDATE_DOCUMENT_FAILURE: 'UPDATE_DOCUMENT_FAILURE',

    DISABLE_DOCUMENT_REQUEST: 'DISABLE_DOCUMENT_REQUEST',
    DISABLE_DOCUMENT_SUCCESS: 'DISABLE_DOCUMENT_SUCCESS',
    DISABLE_DOCUMENT_FAILURE: 'DISABLE_DOCUMENT_FAILURE',

};
