import { userConstants, ltArchiveRewardConstants } from '../_constants';
import { userService, ltArchiveRewardService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const ltArchiveRewardActions = {

    login,
    logout,
    disableLTArchiveReward,
    getAllLTArchiveReward,
    createLTArchiveReward,
    updateLTArchiveReward,
    getLTArchiveRewardList,
    deleteLTArchiveReward,
    uploadImageClear,
    // depositUserBalance
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

// function depositUserBalance(data) {

//     return dispatch => {
//         dispatch(request());
//         ltArchiveRewardService.depositUserBalance(data)
//             .then(

//                 users => {
//                     dispatch(alertActions.success("Deposit Balance Successfully."));
//                     dispatch(success(users));
//                     dispatch(this.getWalletList({ id: data.userId }));


//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: ltArchiveRewardConstants.DEPOSIT_USER_BALANCE_REQUEST } }
//     function success(users) { return { type: ltArchiveRewardConstants.DEPOSIT_USER_BALANCE_SUCCESS, users } }
//     function failure(error) { return { type: ltArchiveRewardConstants.DEPOSIT_USER_BALANCE_FAILURE, error } }
// }

function createLTArchiveReward(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        ltArchiveRewardService.createLTArchiveReward(data)
            .then(

                users => {
                    // toast("LTArchiveReward Created successfully.")
                    dispatch(alertActions.success("LTArchiveReward Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getLTArchiveRewardList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: ltArchiveRewardConstants.ADD_L_T_ARCHIVE_REWARD_REQUEST } }
    function success(users) { return { type: ltArchiveRewardConstants.ADD_L_T_ARCHIVE_REWARD_SUCCESS, users } }
    function failure(error) { return { type: ltArchiveRewardConstants.ADD_L_T_ARCHIVE_REWARD_FAILURE, error } }
}
function updateLTArchiveReward(data) {
    console.log("ACTION_____updateLTArchiveReward:::", data);
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        ltArchiveRewardService.updateLTArchiveReward(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("LTArchiveReward Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getLTArchiveRewardList(reqData));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: ltArchiveRewardConstants.UPDATE_L_T_ARCHIVE_REWARD_REQUEST } }
    function success(users) { return { type: ltArchiveRewardConstants.UPDATE_L_T_ARCHIVE_REWARD_SUCCESS, users } }
    function failure(error) { return { type: ltArchiveRewardConstants.UPDATE_L_T_ARCHIVE_REWARD_FAILURE, error } }
}
function getAllLTArchiveReward() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        ltArchiveRewardService.getAllLTArchiveReward()
            .then(
                users => {
                    console.log("getAllLTArchiveReward $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: ltArchiveRewardConstants.GETALL_L_T_ARCHIVE_REWARD_REQUEST } }
    function success(users) { return { type: ltArchiveRewardConstants.GETALL_L_T_ARCHIVE_REWARD_SUCCESS, users } }
    function failure(error) { return { type: ltArchiveRewardConstants.GETALL_L_T_ARCHIVE_REWARD_FAILURE, error } }
}
function getLTArchiveRewardList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        ltArchiveRewardService.getLTArchiveRewardList(data)
            .then(
                users => {
                    console.log("getLTArchiveRewardList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: ltArchiveRewardConstants.GET_LIST_L_T_ARCHIVE_REWARD_REQUEST } }
    function success(users) { return { type: ltArchiveRewardConstants.GET_LIST_L_T_ARCHIVE_REWARD_SUCCESS, users } }
    function failure(error) { return { type: ltArchiveRewardConstants.GET_LIST_L_T_ARCHIVE_REWARD_FAILURE, error } }
}

function deleteLTArchiveReward(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        ltArchiveRewardService.deleteLTArchiveReward(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getLTArchiveRewardList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: ltArchiveRewardConstants.DELETE_L_T_ARCHIVE_REWARD_REQUEST } }
    function success(users) { return { type: ltArchiveRewardConstants.DELETE_L_T_ARCHIVE_REWARD_SUCCESS, users } }
    function failure(error) { return { type: ltArchiveRewardConstants.DELETE_L_T_ARCHIVE_REWARD_FAILURE, error } }
}
function disableLTArchiveReward(data, paginationData) {

    return dispatch => {
        dispatch(request());
        ltArchiveRewardService.disableLTArchiveReward(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getLTArchiveRewardList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: ltArchiveRewardConstants.DISABLE_L_T_ARCHIVE_REWARD_REQUEST } }
    function success(users) { return { type: ltArchiveRewardConstants.DISABLE_L_T_ARCHIVE_REWARD_SUCCESS, users } }
    function failure(error) { return { type: ltArchiveRewardConstants.DISABLE_L_T_ARCHIVE_REWARD_FAILURE, error } }
}
