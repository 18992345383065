import React from "react";

import Modal from 'react-modal';


export default function CreateUpdatePasswordDealer(props) {

  let { UpdateCoinModal, handleCoinUpdateHideModal, errorsUpdateCoin, inputChangeUpdateCoin, updateCoinSubmit, fieldsUpdateCoin, studentAndTutorList, handleFile} = props;
  console.log("UpdateCoinModalUpdateCoinModalUpdateCoinModal", UpdateCoinModal);
  let studentList = []
  let tutorList = []
  studentAndTutorList.forEach(element => {
    if (element.userType == "STUDENT") {
      studentList.push(element)
    } else if (element.userType == "TUTOR") {
      tutorList.push(element)
    }
  });
  return (

    <Modal
      isOpen={UpdateCoinModal} >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500  modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Update Assignment</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleCoinUpdateHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
             <div className="my-5">

              <form autoComplete="on">
                <div className="flex justify-between w-full">
                  <div class="mr-2 w-full">
                    <label className="text-black text-sm font-medium mt-2 mr-2">Student List:</label>
                    <select class="form-select border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputChangeUpdateCoin} id="studentId" name="studentId"
                      // value={fieldsUpdateCoin && fieldsUpdateCoin.studentId && fieldsUpdateCoin.studentId.id ? fieldsUpdateCoin.studentId.id : null}
                    >
                      <option value={fieldsUpdateCoin && fieldsUpdateCoin.studentId && fieldsUpdateCoin.studentId.id ? fieldsUpdateCoin.studentId.id : ""} selected >{fieldsUpdateCoin && fieldsUpdateCoin.studentId && fieldsUpdateCoin.studentId.id ? fieldsUpdateCoin.studentId.firstName + " " + fieldsUpdateCoin.studentId.lastName : "Plz Select Student"}</option>
                      {
                        studentList && studentList && studentList.length > 0 ?
                          studentList.map((element, index) => (
                            <option key={index} value={element && element.id ? element.id : null}>{element && element.firstName ? element.firstName + " " + element.lastName : "NA"}</option>
                          )) : null
                      }
                    </select>
                    {errorsUpdateCoin && errorsUpdateCoin["studentId"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateCoin["studentId"]}
                      </div>
                      : null}
                  </div>
                  <div class="mr-2 w-full">
                    <label className="text-black text-sm font-medium mt-2 mr-2">Tutor List:</label>
                    <select class="form-select border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" aria-label="Default select example" onChange={inputChangeUpdateCoin} id="tutorId" name="tutorId"
                    // value={studentAndTutorList && studentAndTutorList["tutorId"] ? studentAndTutorList["tutorId"] : null} 
                    >
                      <option value={fieldsUpdateCoin && fieldsUpdateCoin.tutorId && fieldsUpdateCoin.tutorId.id ? fieldsUpdateCoin.tutorId.id : ""} selected >{fieldsUpdateCoin && fieldsUpdateCoin.tutorId && fieldsUpdateCoin.tutorId.id ? fieldsUpdateCoin.tutorId.fullName : "Plz Select Tutor"}</option>
                      {
                        tutorList && tutorList && tutorList.length > 0 ?
                          tutorList.map((element, index) => (
                            <option key={index} value={element && element.id ? element.id : null}>{element && element.fullName ? element.fullName : "NA"}</option>
                          )) : null
                      }
                    </select>
                    {errorsUpdateCoin && errorsUpdateCoin["tutorId"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateCoin["tutorId"]}
                      </div>
                      : null}
                  </div>
                </div>

                  <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Assignment name</label>
                      <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateCoin && !errorsUpdateCoin["name"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="name" name="name" placeholder="name" value={fieldsUpdateCoin.name} type="text"
                        onChange={inputChangeUpdateCoin}
                      />
                      {errorsUpdateCoin && errorsUpdateCoin["name"] ?
                        <div className="invalid-feedback text-yellow-600">
                          {errorsUpdateCoin["name"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="w-full mr-2">
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <label class="block text-gray-700 text-sm" for="username">Assignment PDF</label>
                      <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateCoin && !errorsUpdateCoin["assignmentPdf"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="assignmentPdf" name="assignmentPdf" placeholder="Assignment Pdf" value={handleFile} type="file"
                        onChange={handleFile}
                      />
                      {errorsUpdateCoin && errorsUpdateCoin["assignmentPdf"] ?
                        <div className="invalid-feedback text-yellow-600">
                          {errorsUpdateCoin["assignmentPdf"]}
                        </div>
                        : null}
                    </div>
                  </div>

                <div className="mt-6 w-60 mx-auto">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-2 capitalize px-4 border text-lg font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateCoinSubmit}>Submit</button>
                </div>

              </form>
            </div> 
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
