import React, { Component } from 'react';
import { connect } from 'react-redux';
import { kycActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { isMobile } from "react-device-detect";
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import ImageViewModal from "./components/ImageViewModal/ImageViewModal";
import ViewRejectModal from "./components/ViewRejectModal/ViewRejectModal";
import ViewAddharModal from "./components/ViewAddharModal/ViewAddharModal";
import ViewPanModal from "./components/ViewPanModal/ViewPanModal";
import ViewSelfieModal from "./components/ViewSelfieModal/ViewSelfieModal";
import moment from 'moment'
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaArrowCircleRight } from "react-icons/fa";
import ViewPendingModalBack from '../kycpending/components/ViewAddharModal/ViewAddharModalBack';

class KYCReject extends Component {
  constructor(props) {
    super(props);
    // this.kycSubmit = this.kycSubmit.bind(this);
    // this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldskyc: {},
      errorskyc: {},
      viewImageData: {},
      viewRowData: {},
      viewSelfieData: {},
      viewPanData: {},
      viewAddharData: {},
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      viewImageCreateModal: false,
      viewMoreDetailsModal: false,
      viewSelfieDetailsModal: false,
      viewPanDetailsModal: false,
      viewAddharDetailsModal: false,
    }
  }
  componentDidMount() {
    let temp = {
      "status": "2",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(kycActions.getKycList(temp));
    // this.props.dispatch(userActions.getKYC());
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.users.isKYCCreated) {
  //     return {
  //       ...nextProps,
  //       fieldskyc: {},
  //       errorskyc: {},

  //     }
  //   } else {
  //     return {
  //       ...nextProps,

  //     }
  //   }
  // }

  imageOpenCreateModal = (data) => {
    console.log("imageOpenCreateModal data m kya aa rha h::::", data);
    this.setState({ viewImageCreateModal: true, viewImageData: data });
  }

  viewhandleClose = (data) => {
    this.setState({ viewImageCreateModal: false, viewImageData: {} });
  }

  onAcceptKyc = (data, status) => {
    console.log("onAcceptKyc kkkkkkkkkkdatadata:::::::::", data);
    console.log("onAcceptKyc statusstatus:::::::::", status);
    // console.log("onAcceptKyc ididididid:::::::::", data.id);
    let tempdata = {
      "id": data.id,
      "comment": this.state.keyWord,
      "status": status,
    }
    confirmAlert({
      title: 'Confirm to AcceptKyc?',
      message: 'Are you sure to AcceptKyc ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(kycActions.updateStatusKyc(tempdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  onRejectKyc = (data, status) => {
    console.log("onRejectKyc kkkkkkkkkk:::::::::", data);
    console.log("onRejectKyc statusstatus:::::::::", status);
    // event.preventDefault();
    // let { value } = event.target;
    // this.setState({ keyWord: value })
    // let tempdata = {
    // "id": data.id,
    // "keyWord": this.state.keyWord,
    // "pageNo": this.state.page,
    // "size": this.state.size,
    // "comment": this.state.comment,
    // "status": status,

    // }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to delete this file?</p>
            <input
              id="standard-search"
              label="Search field"
              type="text"
              name="keyWord"
              onChange={this.handleChangeCommentInput}
              // className={classes.textField}
              margin="normal"
            />
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                this.props.dispatch(kycActions.updateStatusKyc({
                  "id": data.id,
                  "keyWord": this.state.keyWord,
                  "pageNo": this.state.page,
                  "size": this.state.size,
                  "comment": this.state.comment,
                  "status": status,
                }))
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      }
    });
  }


  handleChangeCommentInput = (event) => {
    console.log("handleChangeCommentInput ::::::event", event.target.value);
    let { value } = event.target;
    this.setState({ comment: value })
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "status": "2",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(kycActions.getKycList(datatemp));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "status": "2",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(kycActions.getKycList(data));
  }

  handleMoreDetailsModal = (data) => {
    this.props.dispatch(kycActions.getTutorEducationById({ id: data.userId.id }));
    this.setState({ viewMoreDetailsModal: true, viewRowData: data });
  }

  handleMoreDetailsClose = () => {
    this.setState({ viewMoreDetailsModal: false, viewRowData: {} });
  }



  handleSelfieModal = (data) => {
    this.setState({ viewSelfieDetailsModal: true, viewSelfieData: data });
  }

  handleSelfieClose = () => {
    this.setState({ viewSelfieDetailsModal: false, viewSelfieData: {} });
  }

  handlePanModal = (data) => {
    this.setState({ viewPanDetailsModal: true, viewPanData: data.kycDocList });
  }

  handlePanClose = () => {
    this.setState({ viewPanDetailsModal: false, viewPanData: {} });
  }

  handleAddharModal = (data) => {
    // console.log("hsdjkfhsd82345r23:", data.kycDocList);
    this.setState({ viewAddharDetailsModal: true, viewAddharData: data });
  }

  handleAddharClose = () => {
    this.setState({ viewAddharDetailsModal: false, viewAddharData: {} });
  }


  handleAddharModalBack = (data) => {
    // console.log("hsdjkfhsd82345r23:", data.kycDocList);
    this.setState({ viewAddharDetailsModalBack: true, viewAddharData: data });
  }


  handleAddharCloseBack = () => {
    this.setState({ viewAddharDetailsModalBack: false, viewAddharData: {} });
  }


  render() {

    let { kyc } = this.props;
    let { items, total, loading, tutorEducationById } = kyc;

    return (


      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>


        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="flex-1 relative ">
            <div className="2xl:p-10 sm:p-5 p-3">
              <div className="max-w-screen-3xl mx-auto ">
                {/* Transaction Section */}
                <section className="bg-white col-span-6 2xl:py-7 py-5 2xl:px-10 sm:px-5 px-2 flex-col rounded-md">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold text-dark-400 tracking-widest">KYC Reject</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-4 left-5 text-gray-600">
                      </div>
                    </div>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4 shadow-md">
                    <div className="overflow-x-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-x-auto">
                          {/* <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">#</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Name </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Phone No.</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Comment</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">createdAte</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Addhar Front</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Addhar Back</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Selfie</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">
                                        {this.state.offset + index + 1}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element && element.userId && element.userId.fullName ? element.userId.fullName : '-'}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element && element.userId && element.userId.mobNo ? element.userId.mobNo : '-'}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element && element.comment ? element.comment : '-'}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        <img className="w-10 h-10 " src="/images/image-upload.webp" alt=" " onClick={() => this.handleAddharModal(element)} />
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        <img className="w-10 h-10 " src="/images/image-upload.webp" alt=" " onClick={() => this.handleAddharModalBack(element)} />
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        <img className="w-10 h-10 " src="/images/image-upload.webp" alt=" " onClick={() => this.handleSelfieModal(element)} />
                                      </td>
                                      <td className=" flex-wrap content-center px-2 py-3 whitespace-nowrap text-sm text-gray-600 inline-block">
                                        <span class="inline-block pl-1">
                                          <button class="bg-green-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-green-100 rounded-xl hover:shadow-lg hover:bg-green-700" onClick={() => this.handleMoreDetailsModal(element)}>More Details</button>
                                        </span>
                                      </td>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table> */}

                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">Reject KYC Request </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Comment </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Front</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Back</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Selfie</th>
                                {/* <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Actions</th> */}
                              </tr>
                            </thead>

                            {/* Table Row Section */}
                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap  text-sm text-gray-600 flex items-center space-x-2">
                                        <div>
                                          <img
                                            class="w-12 h-12 rounded-full"
                                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <p>{element && element.userId && element.userId.fullName ? element.userId.fullName : '-'}</p>
                                          <p>{element && element.userId && element.userId.mobNo ? element.userId.mobNo : '-'}</p>
                                          <p>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</p>
                                          <div className='flex items-center justify-end' onClick={() => this.handleMoreDetailsModal(element)}><span className="font-bold">More</span>  <span className='text-[#FBB00C] pl-1'><FaArrowCircleRight size={16} /> </span></div>
                                        </div>

                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600 text-center">
                                        {element && element.comment ? element.comment : '-'}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        <img className="w-20 h-10 mx-auto" src="/images/id-card.png" alt=" " />
                                        <p className='text-center flex justify-center items-center' onClick={() => this.handleAddharModal(element)}><MdOutlineRemoveRedEye /></p>
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        <img className="w-20 h-10 mx-auto" src="/images/id-card.png" alt=" " />
                                        <p className='text-center flex justify-center items-center' onClick={() => this.handleAddharModalBack(element)}><MdOutlineRemoveRedEye /></p>
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        <img className="w-20 h-10 mx-auto" src="/images/id-card.png" alt=" " />
                                        <p className='text-center flex justify-center items-center' onClick={() => this.handleSelfieModal(element)}><MdOutlineRemoveRedEye /></p>
                                      </td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <DialogExample />

        <ImageViewModal
          viewImageCreateModal={this.state.viewImageCreateModal}
          //classes={classes}
          viewImageData={this.state.viewImageData}
          viewhandleClose={this.viewhandleClose}
        />

        <ViewRejectModal
          viewMoreDetailsModal={this.state.viewMoreDetailsModal}
          viewRowData={this.state.viewRowData}
          educationDetails={tutorEducationById}
          handleMoreDetailsClose={this.handleMoreDetailsClose}

        />

        <ViewAddharModal
          viewAddharDetailsModal={this.state.viewAddharDetailsModal}
          viewAddharData={this.state.viewAddharData}
          handleAddharClose={this.handleAddharClose}

        />
        <ViewPendingModalBack
          viewAddharDetailsModalBack={this.state.viewAddharDetailsModalBack}
          viewAddharData={this.state.viewAddharData}
          handleAddharClose={this.handleAddharCloseBack}
        />

        <ViewPanModal
          viewPanDetailsModal={this.state.viewPanDetailsModal}
          viewPanData={this.state.viewPanData}
          handlePanClose={this.handlePanClose}

        />

        <ViewSelfieModal
          viewSelfieDetailsModal={this.state.viewSelfieDetailsModal}
          viewSelfieData={this.state.viewSelfieData}
          handleSelfieClose={this.handleSelfieClose}

        />


      </>

    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, kyc } = state;
  return {
    users,
    kyc,
    authentication
  };
}
export default connect(mapStateToProps)(KYCReject);
