export const fastrackBonusConstants = {

    GETALL_FASTRACK_BONUS_REQUEST: 'GETALL_FASTRACK_BONUS_REQUEST',
    GETALL_FASTRACK_BONUS_SUCCESS: 'GETALL_FASTRACK_BONUS_SUCCESS',
    GETALL_FASTRACK_BONUS_FAILURE: 'GETALL_FASTRACK_BONUS_FAILURE',

    GET_LIST_FASTRACK_BONUS_REQUEST: 'GET_LIST_FASTRACK_BONUS_REQUEST',
    GET_LIST_FASTRACK_BONUS_SUCCESS: 'GET_LIST_FASTRACK_BONUS_SUCCESS',
    GET_LIST_FASTRACK_BONUS_FAILURE: 'GET_LIST_FASTRACK_BONUS_FAILURE',

    // DEPOSIT_USER_BALANCE_REQUEST: 'DEPOSIT_USER_BALANCE_REQUEST',
    // DEPOSIT_USER_BALANCE_SUCCESS: 'DEPOSIT_USER_BALANCE_SUCCESS',
    // DEPOSIT_USER_BALANCE_FAILURE: 'DEPOSIT_USER_BALANCE_FAILURE',

    ADD_FASTRACK_BONUS_REQUEST: 'ADD_FASTRACK_BONUS_REQUEST',
    ADD_FASTRACK_BONUS_SUCCESS: 'ADD_FASTRACK_BONUS_SUCCESS',
    ADD_FASTRACK_BONUS_FAILURE: 'ADD_FASTRACK_BONUS_FAILURE',

    DELETE_FASTRACK_BONUS_REQUEST: 'DELETE_FASTRACK_BONUS_REQUEST',
    DELETE_FASTRACK_BONUS_SUCCESS: 'DELETE_FASTRACK_BONUS_SUCCESS',
    DELETE_FASTRACK_BONUS_FAILURE: 'DELETE_FASTRACK_BONUS_FAILURE',

    UPDATE_FASTRACK_BONUS_REQUEST: 'UPDATE_FASTRACK_BONUS_REQUEST',
    UPDATE_FASTRACK_BONUS_SUCCESS: 'UPDATE_FASTRACK_BONUS_SUCCESS',
    UPDATE_FASTRACK_BONUS_FAILURE: 'UPDATE_FASTRACK_BONUS_FAILURE',

    DISABLE_FASTRACK_BONUS_REQUEST: 'DISABLE_FASTRACK_BONUS_REQUEST',
    DISABLE_FASTRACK_BONUS_SUCCESS: 'DISABLE_FASTRACK_BONUS_SUCCESS',
    DISABLE_FASTRACK_BONUS_FAILURE: 'DISABLE_FASTRACK_BONUS_FAILURE',

};
