
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const appsettingService = {
    logout,
    addAppSetting,
    updateStatusAppSetting,
    uploadImage,
    getAppSettingList,
    disableAppSetting,
    updateAppSetting,
    deleteAppSetting,
    getAllAppSetting,
    createUpdateTermsAndCondition,
    delelteTermsAndCondition

};
function logout() {
    // window.sessionStorage.removeItem('appsetting');
    // history.push(`#/login`);
    // window.location.reload();
}

function delelteTermsAndCondition(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/delelteTermsAndCondition`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                delelteTermsAndCondition: data.data
            }
            // console.log("I am in service updateStatusAppSetting", userObj);
            return userObj;
        });
}

function createUpdateTermsAndCondition(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createUpdateTermsAndCondition`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createUpdateTermsAndCondition: data.data
            }
            // console.log("I am in service updateStatusAppSetting", userObj);
            return userObj;
        });
}
function updateStatusAppSetting(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateStatusAppSetting`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateStatusAppSetting: data.data
            }
            // console.log("I am in service updateStatusAppSetting", userObj);
            return userObj;
        });
}

function getAppSettingList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAppSettingList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let appsettingObj = {
                getAppSettingList: data.data
            }
            // console.log(" i am in service getAppSettingList", appsettingObj);
            return appsettingObj;
        });
}
function getAllAppSetting() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getAllUser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let appsettingObj = {
                getAllAppSetting: data.data
            }
            console.log();

            return appsettingObj;
        });
}
function addAppSetting(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    console.log("passed data in service", data);
    return fetch(CONST.BACKEND_URL + `/createAppSetting`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let appsettingObj = {
                addAppSetting: data.data
            }
            console.log();

            return appsettingObj;
        });
}
function updateAppSetting(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateAppSetting`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let appsettingObj = {
                addAppSetting: data.data
            }
            return appsettingObj;
        });
}
function disableAppSetting(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateAppSettingStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let appsettingObj = {
                addAppSetting: data.data
            }
            console.log();

            return appsettingObj;
        });
}
function deleteAppSetting(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteAppSetting `, requestOptions)
        .then(handleResponse)
        .then(data => {

            let appsettingObj = {
                addAppSetting: data.data
            }
            console.log();

            return appsettingObj;
        });
}
function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let appsettingObj = {
                filesDetails: res.data
            }
            return appsettingObj;
        });
}
function handleResponse(response) {
    //    console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}