import React, { Component } from 'react';
import { connect } from 'react-redux';
import { tutionActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import CreatePackageModal from "./components/CreatePackageModal/CreatePackageModal";
import UpdatePackageModal from "./components/UpdatePackageModal/UpdatePackageModal";
import { confirmAlert } from 'react-confirm-alert';
import { HiTrash, HiPencilAlt } from "react-icons/hi";
import { isMobile } from "react-device-detect";


class Pincode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsPinCode: {},
      errorsPinCode: {},
      fieldsPinCodeUpdate: {},
      errorsPinCodeUpdate: {},
      fieldsclassManagement: {
        options: [""]
      },
      errorsclassManagement: {
        options: [""]
      },
      PinCodeCreateModal: false,
      PinCodeUpdateModal: false,
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      formData: {
        "name": "",
        "days": "",
        "per": "",
      },
      updateformData: {
        "id": "",
        "name": "",
        "days": "",
        "per": "",
      },
    }
  }
  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getPinCodeList(temp));
    // this.props.dispatch(tutionActions.getAllPinCode(temp));

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(cityActions.getAllCity(data));
  }



  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('nextProps.tution.addUserSuccess______________________', nextProps.tution.addUserSuccess);

    if (nextProps.tution.addUserSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "key": "",
        },
        PinCodeCreateModal: false,
        PinCodeUpdateModal: false,
        fieldsclassManagement: {
          options: [""]
        },
        errorsclassManagement: {
          options: [""]
        },
        fieldsPinCode: {},
        errorsPinCode: {},
        fieldsPinCodeUpdate: {},
        errorsPinCodeUpdate: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }


  handlePageClick1 = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getPinCodeList(datatemp));


  }

  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ PinCodeCreateModal: true });
  }
  handlePackageHideModal = () => {
    this.setState({ PinCodeCreateModal: false });
    this.setState({ PinCodeUpdateModal: false });
  }

  updatehandleOpenCreateModal = (data) => {

    this.setState({ PinCodeUpdateModal: true, fieldsPinCodeUpdate: data });
  }

  onDeleteClick = (data) => {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      // // "roll_id": 0,
      // "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to Delete?',
      message: `Are you sure to Delete this ${data.pinNo}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.deletePinCode(tempdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  onDisable = (data) => {
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      // // "roll_id": 0,
      // "size": this.state.size
    }
    // this.props.dispatch(tutionActions.disableKeyfeature(tempdata));

    confirmAlert({
      title: 'Confirm to change status?',
      message: `Are you sure to change status ${data.pinNo}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.disablePinCode(tempdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsPinCode = this.state.fieldsPinCode;
    let errorsPinCode = this.state.errorsPinCode;
    fieldsPinCode[name] = value;
    errorsPinCode[name] = "";
    console.log(name, value);
    this.setState({ fieldsPinCode, errorsPinCode });
  }

  inputChangeUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsPinCodeUpdate = this.state.fieldsPinCodeUpdate;
    let errorsPinCodeUpdate = this.state.errorsPinCodeUpdate;
    fieldsPinCodeUpdate[name] = value;
    errorsPinCodeUpdate[name] = "";
    this.setState({ fieldsPinCodeUpdate, errorsPinCodeUpdate });
  }

  PinCodeubmit = (e) => {
    e.preventDefault();



    const { fieldsclassManagement } = this.state;

    if (fieldsclassManagement && fieldsclassManagement.options) {
      // Create the request data object

      let datamap = fieldsclassManagement && fieldsclassManagement.options

      const convertedData = datamap.map((item) =>
        item.pinNo
      );
      console.log('convertedData_______________', convertedData);

      if (this.handleValidationPackage()) {
        let reqData = {
          cityId: this.state.fieldsPinCode.cityId,
          pinNo: convertedData
        }
        this.props.dispatch(tutionActions.createPinCode(reqData, this.props));
      }
    }
  }

  packageUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationPackageUpdate()) {
      let { cityId, pinNo, id } = this.state.fieldsPinCodeUpdate;
      // console.log("asdfasdf  ", cityId, days);
      this.props.dispatch(tutionActions.updatePinCode({ cityId: cityId, pinNo: pinNo, id: id }, this.props));
    }
  }
  handleValidationPackageUpdate = () => {
    let fieldsPinCodeUpdate = this.state.fieldsPinCodeUpdate;
    let errorsPinCodeUpdate = {};
    let formIsValid = true;

    //cityId
    if (!fieldsPinCodeUpdate["cityId"] || fieldsPinCodeUpdate["cityId"] === "") {
      formIsValid = false;
      errorsPinCodeUpdate["cityId"] = "Cannot be empty";
    }

    //pinNo
    if (!fieldsPinCodeUpdate["pinNo"]) {
      formIsValid = false;
      errorsPinCodeUpdate["pinNo"] = "Please enter pinNo!";
    }

    this.setState({ errorsPinCodeUpdate: errorsPinCodeUpdate });
    return formIsValid;
  }

  handleValidationPackage = () => {
    let fieldsPinCode = this.state.fieldsPinCode;
    let errorsPinCode = {};
    let formIsValid = true;

    //cityId
    if (!fieldsPinCode["cityId"] || fieldsPinCode["cityId"] === "") {
      formIsValid = false;
      errorsPinCode["cityId"] = "Cannot be empty";
    }

    //pinNo
    // if (!fieldsPinCode["pinNo"]) {
    //   formIsValid = false;
    //   errorsPinCode["pinNo"] = "Please enter pinNo!";
    // }



    this.setState({ errorsPinCode: errorsPinCode });
    return formIsValid;
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getPinCodeList(data));
  }

  addContentField = () => {
    const { fieldsclassManagement } = this.state;
    fieldsclassManagement.options.push('')
    this.setState({ fieldsclassManagement })
    // console.log("fieldsclassManagement  ", fieldsclassManagement);
  }

  deleteContentField = (index) => {
    const { fieldsclassManagement } = this.state;
    fieldsclassManagement.options.splice(index, 1)
    this.setState({ fieldsclassManagement });
  }

  setCurrentIndex = (currentConetntIndex) => {
    this.setState({ currentConetntIndex: currentConetntIndex });
    // console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e, index) => {
    e.preventDefault();
    const { value } = e.target;
    const { fieldsclassManagement } = this.state;

    // Make a shallow copy of the options array
    const updatedOptions = [...fieldsclassManagement.options];

    // Update the 'name' property of the object at the specified index
    updatedOptions[index] = { ...updatedOptions[index], pinNo: value };

    this.setState({
      fieldsclassManagement: {
        ...fieldsclassManagement,
        options: updatedOptions,
      },
    });
  };



  render() {

    let { tution, city } = this.props;
    let { getAllCity } = city;
    let { pinCodeItems, pinCodeTotal } = tution;
    console.log("getAllCitygetAllCitygetAllCity", pinCodeTotal);
    console.log("pinCodeItems in render", JSON.stringify(pinCodeItems));

    return (
      <>

        <div >
          <LoadingOverlay
            active={false}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {/* Main Content */}
        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                {/* Wallet Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">PinCode List ({pinCodeTotal})</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>
                    <button className="bg-[#FBB00C] hover:bg-[#3a2d0c] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.handleOpenCreateModal()}> ADD </button>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">#</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">Date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">City name</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">Pin No</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">Enable/Disable</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Odd row */}
                              {
                                pinCodeItems && pinCodeItems.length > 0 ?
                                  pinCodeItems.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white  border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap text-center font-medium text-sm text-gray-600">
                                        {this.state.offset + index + 1}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.cityId && element.cityId.name ? element.cityId.name : "-"}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.pinNo ? element.pinNo : "-"}</td>

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <label class="flex justify-center items-center cursor-pointer targetablepx-4 tooltip">
                                          <div class="relative" onClick={() => this.onDisable(element)}>
                                            <input type="checkbox" id="toggleB" class="sr-only" />
                                            {element && element.isDisable === false ? <div class="block bg-green-600 w-10 h-6 rounded-full"></div> : <div class="block bg-red-600 w-10 h-6 rounded-full"></div>}
                                            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>{element && element.isDisable === true ? "enable" : "disable"}</span>
                                          </div>
                                        </label>
                                      </td>

                                      <td className="flex justify-center items-center px-2 py-3 mt-2 text-gray-600 whitespace-nowrap">
                                        <span className="pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.updatehandleOpenCreateModal(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Edit</span>
                                            <HiPencilAlt className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>

                                        <span className="flex justify-center pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-500 cursor-pointer" onClick={() => this.onDeleteClick(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Delete</span>
                                            <HiTrash className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>
                                      </td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <nav className="relative z-0 flex justify-end mt-5">
                      {
                        isMobile ?
                          <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              pinCodeTotal && pinCodeTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={pinCodeTotal / this.state.size}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={this.handlePageClick1}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              pinCodeTotal && pinCodeTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={pinCodeTotal / this.state.size}
                                  marginPagesDisplayed={3}
                                  pageRangeDisplayed={3}
                                  onPageChange={this.handlePageClick1}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav>
                      }
                    </nav>


                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <CreatePackageModal

          PinCodeCreateModal={this.state.PinCodeCreateModal}
          fieldsclassManagement={this.state.fieldsclassManagement}
          errorsclassManagement={this.state.errorsclassManagement}
          fieldsPinCode={this.state.fieldsPinCode}
          errorsPinCode={this.state.errorsPinCode}
          addContentField={this.addContentField}
          deleteContentField={this.deleteContentField}
          setCurrentIndex={this.setCurrentIndex}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
          inputChange={this.inputChange}
          PinCodeubmit={this.PinCodeubmit}
          handlePackageHideModal={this.handlePackageHideModal}
          getAllCity={getAllCity}

        />
        <UpdatePackageModal

          PinCodeUpdateModal={this.state.PinCodeUpdateModal}
          fieldsPinCodeUpdate={this.state.fieldsPinCodeUpdate}
          errorsPinCodeUpdate={this.state.errorsappsettinUpdate}
          inputChangeUpdate={this.inputChangeUpdate}
          packageUpdateSubmit={this.packageUpdateSubmit}
          handlePackageHideModal={this.handlePackageHideModal}
          getAllCity={getAllCity}
        />


      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, tution, city } = state;
  return {
    users,
    tution,
    authentication,
    city
  };
}
export default connect(mapStateToProps)(Pincode);
