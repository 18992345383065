import {
  userConstants
} from '../_constants';

export function users(state = {}, action) {
  console.log('action________________________', action);
  switch (action.type) {
    case userConstants.UPDATE_TEAM_MINING_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_TEAM_MINING_AMOUNT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,

      };
    case userConstants.UPDATE_TEAM_MINING_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ADMIN_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        adminProfileData: action.user.adminDetails

      };
    case userConstants.GET_ADMIN_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.ADD_AMOUNT_REFERRAL_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_AMOUNT_REFERRAL_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        // adminProfileData: action.user.adminDetails

      };
    case userConstants.ADD_AMOUNT_REFERRAL_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ADMIN_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        subAdminList: action.user.getSubAdminList.list,
        subAdminTotal: action.user.getSubAdminList.total
      };
    case userConstants.GET_ADMIN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_PAYMENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userPaymentList: action.user.getUserPaymentDetails.list,
        totalAmount: action.user.getUserPaymentDetails.totalfee
      };
    case userConstants.GET_USER_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_TUITION_LIST_BY_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_TUITION_LIST_BY_DATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getTuitionDateWasiList: action.user.getTuitionListByDate.list,
        getTuitionDateWasiTotal: action.user.getTuitionListByDate.total,
        currentMonthData: action.user.getTuitionListByDate.month
      };
    case userConstants.GET_TUITION_LIST_BY_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_FEE_HISTORY_LIST_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_FEE_HISTORY_LIST_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getFeeHistoryList: action.user.getFeeHistoryListAdmin.list,
        getFeeHistoryTotal: action.user.getFeeHistoryListAdmin.total
      };
    case userConstants.GET_FEE_HISTORY_LIST_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_LEAD_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_LEAD_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getUserRefList: action.user.getUserRefList.list,
        getUserRefTotal: action.user.getUserRefList.total,
        getUserRefDate: action.user.getUserRefList.month
      };
    case userConstants.GET_LEAD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.UPDATE_SUB_ADMIN_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_SUB_ADMIN_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        adminList: action.user.getAdminList.list,
        adminTotal: action.user.getAdminList.total
      };
    case userConstants.UPDATE_SUB_ADMIN_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_TX_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_TX_AMOUNT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,

      };
    case userConstants.UPDATE_TX_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_PAYOUT_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_PAYOUT_AMOUNT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,

      };
    case userConstants.UPDATE_PAYOUT_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_EMAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,

      };
    case userConstants.UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_USERNAME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_USERNAME_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,

      };
    case userConstants.UPDATE_USERNAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.APPROVE_PROMO_PACK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.APPROVE_PROMO_PACK_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,

      };
    case userConstants.APPROVE_PROMO_PACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.ADD_USER_BALANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_USER_BALANCE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,

      };
    case userConstants.ADD_USER_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.CREATE_SUBADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_SUBADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,

      };
    case userConstants.CREATE_SUBADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.UPDATE_STATUS_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_STATUS_PAYMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,

      };
    case userConstants.UPDATE_STATUS_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_PAYMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        tutionItems: action.users.getTuituinListTutorWasi.list,
        total: action.users.getTuituinListTutorWasi.total
      };
    case userConstants.GET_PAYMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_ATTENDENCE_TUTION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ATTENDENCE_TUTION_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAttendanceListTuitionWasi: action.users.getAttendanceListTuitionWasi.list,
        total: action.users.getAttendanceListTuitionWasi.total
      };
    case userConstants.GET_ATTENDENCE_TUTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ASSIGNMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        addUserSuccessRes: false,
        loading: false,
        assignmentItems: action.users.getAssignmentAdminList.list,
        assignmentTotal: action.users.getAssignmentAdminList.total
      };
    case userConstants.GET_ASSIGNMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_PROMO_PACKAGE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_PROMO_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        promoPackageItems: action.users.getUserPromoPackageList.list,
        promoPackageTotal: action.users.getUserPromoPackageList.total
      };
    case userConstants.GET_USER_PROMO_PACKAGE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_PACKAGE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        packageListItems: action.users.getUserPackageList.list,
        packageListTotal: action.users.getUserPackageList.total
      };
    case userConstants.GET_USER_PACKAGE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.WITHDRAW_USER_BALANCE_REQUEST:
      return {
        ...state
      };
    case userConstants.WITHDRAW_USER_BALANCE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.WITHDRAW_USER_BALANCE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.DEPOSIT_USER_BALANCE_REQUEST:
      return {
        ...state
      };
    case userConstants.DEPOSIT_USER_BALANCE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.DEPOSIT_USER_BALANCE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.CREATE_WALLET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_WALLET_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        // walletItems: action.users.getWalletList,
        // total: action.users.getUserActive.total
      };
    case userConstants.CREATE_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.UPDATE_STUDENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        // walletItems: action.users.getWalletList,
        // total: action.users.getUserActive.total
      };
    case userConstants.UPDATE_STUDENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.REMOVE_DFTC_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.REMOVE_DFTC_ADDRESS_SUCCESS:
      return {
        ...state,
        addUserSuccessRes: false,
        loading: false,
      };
    case userConstants.REMOVE_DFTC_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.CREATE_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        addUserSuccessRes: true,
        loading: false,
      };
    case userConstants.CREATE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.CREATE_ASSIGN_CLASSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_ASSIGN_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case userConstants.CREATE_ASSIGN_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.MARK_PAID_OR_UNPAID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.MARK_PAID_OR_UNPAID_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case userConstants.MARK_PAID_OR_UNPAID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.UPDATE_ASSIGN_CLASSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_ASSIGN_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.UPDATE_ASSIGN_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.PAY_FASTRACK_OR_LIFE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.PAY_FASTRACK_OR_LIFE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        // walletItems: action.users.getWalletList,
        // total: action.users.getUserActive.total
      };
    case userConstants.PAY_FASTRACK_OR_LIFE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DELETE_USER_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_USER_PACKAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // walletItems: action.users.getWalletList,
        // total: action.users.getUserActive.total
      };
    case userConstants.DELETE_USER_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_WALLET_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_WALLET_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        walletItems: action.users.getWalletList,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_WALLET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_LOGIN_HISTORY_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_LOGIN_HISTORY_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        historyItem: action.users.getLoginhistory,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_LOGIN_HISTORY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_PROMO_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_PROMO_PACKAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userPromoPackageItem: action.users.getUserPromoPackageByAdmin,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_PROMO_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_PACKAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userPackageItem: action.users.getUserPackages,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_USER_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_LEVEL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_LEVEL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        levelItem: action.users.getUserLevel,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_USER_LEVEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_TX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_TX_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        txItem: action.users.getTx.list,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_TX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userInfoItem: action.users.getUserInfoByAdmin,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_SOCIAL_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_SOCIAL_INFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        socialInfoItem: action.users.getSocialMediaById,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_SOCIAL_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userProfileItem: action.users.getUserProfile,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_PACKAGE_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_PACKAGE_TEAM_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userPackageTeamItem: action.users.getUserPackagesTeam,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_USER_PACKAGE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_TEAM_BY_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_TEAM_BY_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userTeamItem: action.users.getUserTeamByUserId,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_USER_TEAM_BY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_TICKET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_TICKET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        ticketItem: action.users.getTicketList.list,
        // total: action.users.getUserActive.total
      };
    case userConstants.GET_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        passwordItem: action.users.changePassword,
        // total: action.users.getUserActive.total
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GETALL_USER_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_ACTIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getUserList: action.users.getUserActive.list,
        total: action.users.getUserActive.total
      };
    case userConstants.GETALL_USER_ACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GETALL_USER_ACTIVE_REJECTED_INTERVIEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_ACTIVE_REJECTED_INTERVIEW_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        rejectedInterviewList: action.users.getUserListRejectedInterview.list,
        rejectedInterviewTotal: action.users.getUserListRejectedInterview.total
      };
    case userConstants.GETALL_USER_ACTIVE_REJECTED_INTERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_KYC_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_KYC_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userKycDetails: action.users.getUserKycDetailsById
      };
    case userConstants.GET_USER_KYC_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GETALL_USER_ACTIVE_STUDENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_ACTIVE_STUDENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getUserStudentList: action.users.getUserActive.list,
        total: action.users.getUserActive.total
      };
    case userConstants.GETALL_USER_ACTIVE_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_TUITION_LIST_BY_TUTOR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_TUITION_LIST_BY_TUTOR_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        tuitionList: action.users.getTuitionListByTutor.list,
        tuitionTotal: action.users.getTuitionListByTutor.total
      };
    case userConstants.GET_TUITION_LIST_BY_TUTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ATTENDANCE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ATTENDANCE_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        attendanceList: action.users.getAttendanceListByTutor.list,
        attendanceTotal: action.users.getAttendanceListByTutor.total
      };
    case userConstants.GET_ATTENDANCE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ASSIGNED_CLASSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ASSIGNED_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        assignedClassesItems: action.users.getAssignedClassesList.list,
        assignedClassesTotal: action.users.getAssignedClassesList.total
      };
    case userConstants.GET_ASSIGNED_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_CLASS_ID_BY_TUITION_STUDENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_CLASS_ID_BY_TUITION_STUDENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getClassIdByTuition: action.users.getClassIdByTuitionStudent
      };
    case userConstants.GET_CLASS_ID_BY_TUITION_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GETALL_USER_DEACTIVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_DEACTIVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getUserDeactive.list,
        total: action.users.getUserDeactive.total
      };
    case userConstants.GETALL_USER_DEACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_USER_REF_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_USER_REF_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_USER_REF_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.REJISTER_USER_REF_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.REJISTER_USER_REF_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: true,
      };
    case userConstants.REJISTER_USER_REF_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.USER_INTERVIEW_REF_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_INTERVIEW_REF_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: true,
      };
    case userConstants.USER_INTERVIEW_REF_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.UPDATE_UNPAID_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_UNPAID_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: true,
      };
    case userConstants.UPDATE_UNPAID_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_ASSIGNED_CLASSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_ASSIGNED_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_ASSIGNED_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DISABLE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DISABLE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DISABLE_ASSIGNMENT_FAILURE:
    case userConstants.DISABLE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.LEVEL_STATUS_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LEVEL_STATUS_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // items: action.users.disableUser.list,
        // total: action.users.disableUser.total
      };
    case userConstants.LEVEL_STATUS_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // items: action.users.deleteUser.list,
        // total: action.users.deleteUser.total
      };
    case userConstants.DELETE_ASSIGNMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DELETE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DELETE_ASSIGN_CLASSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_ASSIGN_CLASSES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // items: action.users.deleteUser.list,
        // total: action.users.deleteUser.total
      };
    case userConstants.DELETE_ASSIGN_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.USER_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // items: action.users.verifyEmail.list,
        // total: action.users.verifyEmail.total
      };
    case userConstants.USER_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.UPDATE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.ADD_USER_REQUEST:
      return {
        ...state
      };
    case userConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.ADD_USER_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.LOGIN_TO_THIS_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_TO_THIS_ACCOUNT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        loginToThisAccountItems: action.users.loginToThisAccount
        // loginToThisAccountTotal: action.users.loginToThisAccount.total
      };
    case userConstants.LOGIN_TO_THIS_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.users.getProfile
      };
    case userConstants.GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        filesDetails: action.uploadImage.filesDetails,
      };

    case userConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };

    case userConstants.FILE_UPLOAD_CLEAR_SUCCESS:
      return {
        ...state,
        imageUploadClearSuccess: true,
        filesDetails: null
      };

    default:
      return state
  }
}