import React, { useState } from "react";
import Modal from 'react-modal';
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";
const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};

export default function ViewPendingModal(props) {

  let { viewSelfieDetailsModal, handleSelfieClose, viewSelfieData,
  } = props;
  console.log("viewSelfieDataModal::", viewSelfieData);

  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };



  return (

    // <Modal
    //   isOpen={viewSelfieDetailsModal}
    //   style={customStyles}
    //   ariaHideApp={false}
    // >
    <>

      {viewSelfieData ?
        <div className={viewSelfieDetailsModal ? "fixed w-full top-0 left-0 h-full inset-0 z-50 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
          <div className="border border-teal-500 modal-container bg-white w-11/12 md:max-w-xl mx-auto rounded shadow-lg z-50 overflow-y-auto">



            {/* <div className="modal-content py-4 text-left px-6">

              <div className="flex justify-between items-center">
                <p className="text-2xl font-bold">View Selfie</p>
                <div className="modal-close cursor-pointer z-50">
                  <svg onClick={() => handleSelfieClose()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                    </path>
                  </svg>
                </div>
              </div>

              <div className="my-3">
                <form autoComplete="off">
                  <>

                    <div className="grid grid-rows-1 grid-flow-col">
                      <div className="justify-center lg:justify-start text-white p-4 bg-[#FBB00C] rounded-md items-start mt-2 w-full ">

                        <div className="flex justify-center space-x-4 mt-4">
                          <div className="space-y-2" >
                            <label className="">SELFIE IMAGE</label>
                            <img className="w-[200px]" src={viewSelfieData && viewSelfieData.imageUserLink ? viewSelfieData.imageUserLink : "-"} alt=" " />
                          </div>
                        </div>
                      </div>
                    </div>

                  </>
                </form>


              </div>
            </div>
 */}

            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-between items-center pb-3">
                <p className="text-2xl font-bold">View Selfie</p>
                <div className="modal-close cursor-pointer z-50">
                  <svg onClick={() => handleSelfieClose()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                    </path>
                  </svg>
                </div>
              </div>

              <label className="text-black font-bold">SELFIE IMAGE</label>
              <div className=" p-4 bg-[#FBB00C] rounded-md mt-2 w-full ">
                <div style={{ position: 'relative', width: '400px', height: '400px', overflow: 'hidden', margin: 'auto' }}>
                  <div
                    style={{
                      transform: `scale(${zoomLevel})`,
                      transformOrigin: '0 0 0 0',
                      transition: 'transform 0.25s ease',
                    }}
                  >
                    <img
                      style={{ width: '100%', height: 'auto' }}
                      src={viewSelfieData && viewSelfieData.imageUserLink ? viewSelfieData.imageUserLink : "-"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="space-x-2 pt-1">
                <button className="bg-black text-white rounded-full p-2" onClick={handleZoomIn}><FaSearchPlus /></button>
                <button className="bg-black text-white rounded-full p-2" onClick={handleZoomOut}><FaSearchMinus /></button>
              </div>

            </div>
          </div>
        </div>
        : null
      }
    </>


    // </Modal>



  );
}
