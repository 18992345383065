
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';

export const holidayService = {
    login,
    logout,
    getAllHolidays,
    createHolidays,
    updateHolidays,
    getHolidaysList,
    deleteHolidays,
    disableHolidays,

};
function logout() {
    window.sessionStorage.removeItem('adminuser');
    // window.location.href = "#/login";
    history.push(`#/login`);
    window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                window.sessionStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getHolidaysList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getHolidaysList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getHolidaysList: data.data
            }
            console.log("i am in service getHolidaysList", userObj);

            return userObj;
        });
}
function getAllHolidays() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllHolidays`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllHolidays: data.data
            }
            console.log("i am in service getAllHolidays", userObj);

            return userObj;
        });
}

function deleteHolidays(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteHolidays`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteHolidays: data.data
            }
            console.log("i am in service''...>> deleteHolidays ::", userObj);

            return userObj;
        });
}
function disableHolidays(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateHolidaysStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateHolidaysStatus: data.data
            }
            console.log("i am in service''...>> updateHolidaysStatus ::", userObj);

            return userObj;
        });
}

function updateHolidays(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateHolidays`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateHolidays: data.data
            }
            console.log("I am in service updateHolidays", userObj);

            return userObj;
        });
}
function createHolidays(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createHolidays`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createHolidays: data.data
            }
            console.log("I am in service createHolidays", userObj);

            return userObj;
        });
}




function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}