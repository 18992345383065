
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';

export const cityService = {
    login,
    logout,
    // verifyEmail,
    // loginToThisAccount,
    getAllCity,
    createCity,
    updateCity,
    getCityList,
    getAttendanceMonthData,
    deleteCity,
    disableCity,

    disableClass,
    getAllClass,
    createClass,
    updateClass,
    getClassList,
    deleteClass,

    disableBoard,
    getAllBoard,
    createBoard,
    updateBoard,
    getBoardList,
    deleteBoard,
};
function logout() {
    window.sessionStorage.removeItem('adminuser');
    // window.location.href = "#/login";
    history.push(`#/login`);
    window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                window.sessionStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getAttendanceMonthData(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAttendanceMonthData`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAttendanceMonthData: data.data
            }
            console.log("i am in service getAttendanceMonthData", userObj);

            return userObj;
        });
}

function getCityList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCityList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getCityList: data.data
            }
            console.log("i am in service getCityList", userObj);

            return userObj;
        });
}
function getAllCity() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllCity`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllCity: data.data
            }
            console.log("i am in service getAllCity", userObj);

            return userObj;
        });
}

function deleteCity(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteCity`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteCity: data.data
            }
            console.log("i am in service''...>> deleteCity ::", userObj);

            return userObj;
        });
}
function disableCity(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCityStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateCityStatus: data.data
            }
            console.log("i am in service''...>> updateCityStatus ::", userObj);

            return userObj;
        });
}

function updateCity(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCity`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateCity: data.data
            }
            console.log("I am in service updateCity", userObj);

            return userObj;
        });
}
function createCity(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createCity`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createCity: data.data
            }
            console.log("I am in service createCity", userObj);

            return userObj;
        });
}

function getClassList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getClassList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getClassList: data.data
            }
            return userObj;
        });
}
function getAllClass() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllClass`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllClass: data.data
            }
            return userObj;
        });
}
function createClass(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createClass`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createClass: data.data
            }
            console.log();

            return userObj;
        });
}
function deleteClass(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteClass`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteClass: data.data
            }
            console.log();

            return userObj;
        });
}



function updateClass(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateClass`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateClass: data.data
            }
            return userObj;
        });
}


function disableClass(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateClassStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                disableClass: data.data
            }
            return userObj;
        });
}

function getBoardList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getBoardList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getBoardList: data.data
            }
            return userObj;
        });
}
function getAllBoard() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllBoard`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllBoard: data.data
            }
            return userObj;
        });
}
function createBoard(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createBoard`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createBoard: data.data
            }
            console.log();

            return userObj;
        });
}
function deleteBoard(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteBoard`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteBoard: data.data
            }
            console.log();

            return userObj;
        });
}



function updateBoard(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBoard`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateBoard: data.data
            }
            return userObj;
        });
}


function disableBoard(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBoardStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                disableBoard: data.data
            }
            return userObj;
        });
}




function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}