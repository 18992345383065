import React from "react";

import Modal from 'react-modal';
const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};

export default function ViewMoreDetailsModal(props) {

  let { moreDetailsCreateModal, handleMoreDetailsHideModal, viewRowData,
    //  errorsappsetting, inputChange, appSettingSubmit
  } = props;
  console.log("viewRowData  ", viewRowData);

  return (

    <Modal
      isOpen={moreDetailsCreateModal}
      style={customStyles}
      ariaHideApp={false}
    // contentLabel="Example Modal"
    >


      {viewRowData ?
        <div className="main-modal fixed w-full h-full inset-0 z-40  xl:z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
          <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-lg mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="modal-close cursor-pointer z-50 py-2">
                <svg
                  onClick={() => handleMoreDetailsHideModal()}
                  className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>


              <form autoComplete="off">


                {/* {JSON.stringify(viewRowData)} */}



                <div className="border rounded-lg p-4 bg-gray-100 text-gray-700 divide-y divide-gray-300 space-y-2">
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Tutor name :</span>
                    <span > {viewRowData && viewRowData.tutorId && viewRowData.tutorId.fullName ? viewRowData.tutorId.fullName
                      : "tutor not assigned"}</span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Student name :</span>
                    <span > {viewRowData && viewRowData.studentId && viewRowData.studentId.firstName ? viewRowData.studentId.firstName
                      : "-"} {"  "}{viewRowData && viewRowData.studentId && viewRowData.studentId.lastName ? viewRowData.studentId.lastName
                        : "-"}</span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Student Mob No. : </span>
                    <span > {viewRowData && viewRowData.studentId && viewRowData.studentId.mobNo ? viewRowData.studentId.mobNo
                      : "-"} </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Class name :</span>
                    <span > {viewRowData && viewRowData.class && viewRowData.class.name ? viewRowData.class.name
                      : "-"} </span>
                  </p>
                  {/* <p className="py-1 flex justify-between items-center space-x-4">
                        <span>Course Name :</span>
                        <span > {viewRowData && viewRowData.course && viewRowData.course.length > 0 ? viewRowData.course.map((element) => (
                          <span className='px-1'>
                            {element.name}
                          </span>

                        ))
                          : "-"} </span>
                      </p> */}

                  <p className="py-1 flex justify-between  space-x-4">
                    <div>
                      <span>Course
                        :</span>
                    </div>
                    <div>

                      {viewRowData && viewRowData.course && viewRowData.course.length > 0 ?
                        viewRowData.course.map((element) =>
                          <>
                            <span className='flex flex-col'>{element && element.name ? element.name : "NA"} </span>
                          </>
                        ) : 'NA'}
                    </div>
                  </p>

                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Number of class :</span>
                    <span > {viewRowData && viewRowData.numberOfClasses ? viewRowData.numberOfClasses : "0"}  </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Teaching mode :</span>
                    <span > {viewRowData && viewRowData.teachingMode ? viewRowData.teachingMode : "-"}  </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Date :</span>
                    <span > {viewRowData && viewRowData.date ? viewRowData.date : "-"}  </span>
                  </p>

                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Prefered demo time :</span>
                    <span > {viewRowData && viewRowData.startTime ? viewRowData.startTime : "-"} to  {viewRowData && viewRowData.endTime ? viewRowData.endTime : "-"} </span>
                  </p>

                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>City Name :</span>
                    <span > {viewRowData && viewRowData.cityId && viewRowData.cityId.name ? viewRowData.cityId.name
                      : "-"} </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Pin code :</span>
                    <span > {viewRowData && viewRowData.pinCodeId && viewRowData.pinCodeId.pinNo ? viewRowData.pinCodeId.pinNo
                      : "-"} </span>
                  </p>
                  <p className="py-1 flex justify-between items-center space-x-4">
                    <span>Board Name :</span>
                    <span > {viewRowData && viewRowData.board && viewRowData.board.name ? viewRowData.board.name
                      : "-"} </span>
                  </p>


                </div>



              </form>


            </div>
          </div>
        </div>
        : null
      }


    </Modal>



  );
}
