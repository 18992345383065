import {
  swapConstants
} from '../_constants';

export function swap(state = {}, action) {

  switch (action.type) {

    case swapConstants.GET_USER_TXLIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case swapConstants.GET_USER_TXLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.swap.getUserTxList.list,
        total: action.swap.getUserTxList.total
      };
    case swapConstants.GET_USER_TXLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case swapConstants.TXLIST_SWAP_SUCCESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case swapConstants.TXLIST_SWAP_SUCCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.swap.getUserTxList.list,
        total: action.swap.getUserTxList.total
      };
    case swapConstants.TXLIST_SWAP_SUCCESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case swapConstants.TXLIST_SWAP_PENDING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case swapConstants.TXLIST_SWAP_PENDING_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.swap.getUserTxList.list,
        total: action.swap.getUserTxList.total
      };
    case swapConstants.TXLIST_SWAP_PENDING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}