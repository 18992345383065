export const kycConstants = {
    LOGIN_REQUEST: 'KYCS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'KYCS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'KYCS_LOGIN_FAILURE',

    LOGOUT: 'KYCS_LOGOUT',

    GETALL_REQUEST: 'KYCS_GETALL_REQUEST',
    GETALL_SUCCESS: 'KYCS_GETALL_SUCCESS',
    GETALL_FAILURE: 'KYCS_GETALL_FAILURE',

    GET_ALL_KYC_REQUEST: 'KYCS_GET_ALL_KYC_REQUEST',
    GET_ALL_KYC_SUCCESS: 'KYCS_GET_ALL_KYC_SUCCESS',
    GET_ALL_KYC_FAILURE: 'KYCS_GET_ALL_KYC_FAILURE',

    GET_TUTTOR_EDUCATION_BY_ID_REQUEST: 'GET_TUTTOR_EDUCATION_BY_ID_REQUEST',
    GET_TUTTOR_EDUCATION_BY_ID_SUCCESS: 'GET_TUTTOR_EDUCATION_BY_ID_SUCCESS',
    GET_TUTTOR_EDUCATION_BY_ID_FAILURE: 'GET_TUTTOR_EDUCATION_BY_ID_FAILURE',

    GETALL_KYC_NOTIFY_REQUEST: 'GETALL_KYC_NOTIFY_REQUEST',
    GETALL_KYC_NOTIFY_SUCCESS: 'GETALL_KYC_NOTIFY_SUCCESS',
    GETALL_KYC_NOTIFY_FAILURE: 'GETALL_KYC_NOTIFY_FAILURE',

    UPDATE_KYC_NOTIFY_REQUEST: 'UPDATE_KYC_NOTIFY_REQUEST',
    UPDATE_KYC_NOTIFY_SUCCESS: 'UPDATE_KYC_NOTIFY_SUCCESS',
    UPDATE_KYC_NOTIFY_FAILURE: 'UPDATE_KYC_NOTIFY_FAILURE',

    UPDATE_KYC_PASSWORD_REQUEST: 'UPDATE_KYC_PASSWORD_REQUEST',
    UPDATE_KYC_PASSWORD_SUCCESS: 'UPDATE_KYC_PASSWORD_SUCCESS',
    UPDATE_KYC_PASSWORD_FAILURE: 'UPDATE_KYC_PASSWORD_FAILURE',


    ADD_RESTAURANT_KYC_REQUEST: 'ADD_RESTAURANT_KYC_REQUEST',
    ADD_RESTAURANT_KYC_SUCCESS: 'ADD_RESTAURANT_KYC_SUCCESS',
    ADD_RESTAURANT_KYC_FAILURE: 'ADD_RESTAURANT_KYC_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',

    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

};
