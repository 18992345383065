
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';

export const supportService = {
    getTicketList,
    replyMsgAdminTicket,
    MsgListTicketById,
    resolvedTicket,
};
function logout() {
    window.sessionStorage.removeItem('support');
    history.push(`#/login`);
    window.location.reload();
}

function replyMsgAdminTicket(data) {
    console.log("updateTicketStatus____service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/replyMsgAdminTicket`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let supportObj = {
                updateTicketStatus: data.data
            }
            console.log(" i am in service  updateTicketStatus", supportObj);

            return supportObj;
        });
}

function resolvedTicket(data) {
    console.log("updateTicketStatus____service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/resolvedTicket`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let supportObj = {
                resolvedTicket: data.data
            }
            console.log(" i am in service  resolvedTicket", supportObj);

            return supportObj;
        });
}

function MsgListTicketById(data) {
    console.log("MsgListTicketByIdMsgListTicketByIdMsgListTicketById:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/MsgListTicketById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let supportObj = {
                MsgListTicketById: data.data
            }
            console.log("MsgListTicketByIdMsgListTicketByIdMsgListTicketByIdMsgListTicketById ", supportObj);

            return supportObj;
        });
}

function getTicketList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTicketList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let supportObj = {
                getTicketList: data.data
            }
            console.log(" i am in service  getTicketList", supportObj);

            return supportObj;
        });
}
function handleResponse(response) {
    //    console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}