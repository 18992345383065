import React, { Component } from 'react';
import { connect } from 'react-redux';
import { tutionActions, bannerActions, userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import CreatePackageModal from "./components/CreatePackageModal/CreatePackageModal";
import UpdatePackageModal from "./components/UpdatePackageModal/UpdatePackageModal";
import { confirmAlert } from 'react-confirm-alert';
import { HiTrash, HiPencilAlt } from "react-icons/hi";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: {},
      fieldsBanner: {},
      errorsBanner: {},
      fieldsBannerUpdate: {},
      errorsBannerUpdate: {},
      PinCodeCreateModal: false,
      PinCodeUpdateModal: false,
      offset: 0,
      size: 10,
      page: 1,
      imageName: '',
      imageLinkUrlRes: null,
      keyWord: '',
      formData: {
        "name": "",
        "days": "",
        "per": "",
      },
      updateformData: {
        "id": "",
        "name": "",
        "days": "",
        "per": "",
      },
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    this.props.dispatch(bannerActions.getBannerList(data));
  }



  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('nextProps.users.addUserSuccess', nextProps.users.addUserSuccess);
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        imageName: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageLinkUrlRes: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageUrl ? nextProps.users.filesDetails.imageUrl : null,
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ PinCodeCreateModal: true });
  }
  handlePackageHideModal = () => {
    this.setState({ PinCodeCreateModal: false, imageName: '', imageLinkUrlRes: null });
    this.setState({ PinCodeUpdateModal: false, imageName: '', imageLinkUrlRes: null });
  }

  updatehandleOpenCreateModal = (data) => {

    this.setState({ PinCodeUpdateModal: true, fieldsBannerUpdate: data });
  }

  onDeleteClick = (data) => {
    console.log(data);
    confirmAlert({
      title: 'Confirm to Delete?',
      message: 'Are you sure to Delete ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.deletePinCode(data))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  onDisable = (data) => {
    let resData = {
      id: data.id
    }
    confirmAlert({
      title: 'Confirm to change status?',
      message: `Are you sure to change banner status `,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(bannerActions.updateBannerStatus(resData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  inputChange = (e) => {
    e.preventDefault();
    const { users } = this.props
    let { name, value } = e.target;
    let fieldsBanner = this.state.fieldsBanner;
    let errorsBanner = this.state.errorsBanner;
    fieldsBanner[name] = value;
    errorsBanner[name] = "";
    console.log(name, value);
    fieldsBanner["image"] = users && users.filesDetails && users.filesDetails.imageName
    this.setState({ fieldsBanner, errorsBanner });
  }

  inputChangeUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsPinCodeUpdate = this.state.fieldsPinCodeUpdate;
    let errorsPinCodeUpdate = this.state.errorsPinCodeUpdate;
    fieldsPinCodeUpdate[name] = value;
    errorsPinCodeUpdate[name] = "";
    this.setState({ fieldsPinCodeUpdate, errorsPinCodeUpdate });
  }

  PinCodeubmit = () => {
    if (this.handleValidationPackage()) {
      // let { name, image } = this.state.fieldsBanner;
      let resData = {
        name: this.state.fieldsBanner.name,
        image: this.state.imageName
      }
      this.props.dispatch(bannerActions.createBanner(resData));
      this.setState({ PinCodeCreateModal: false });
      this.setState({ fieldsBanner: {} })
      let data = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      }
      this.props.dispatch(bannerActions.getBannerList(data));
    }
  }

  packageUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationPackageUpdate()) {
      // let { name, image, id } = this.state.fieldsBannerUpdate;
      let resData = {
        id: this.state.fieldsBannerUpdate.id,
        name: this.state.fieldsBannerUpdate.name,
        image: this.state.imageName
      }
      this.props.dispatch(bannerActions.updateBanner(resData, { keyWord: this.state.keyWord, size: this.state.size, pageNo: this.state.page }));
      this.setState({ PinCodeUpdateModal: false });
      this.setState({ fieldsBannerUpdate: {} })
      this.setState({ imageLinkUrl: null })

    }
  }
  handleValidationPackageUpdate = () => {
    let fieldsBannerUpdate = this.state.fieldsBannerUpdate;
    let errorsBannerUpdate = {};
    let formIsValid = true;

    if (!fieldsBannerUpdate["name"] || fieldsBannerUpdate["name"] === "") {
      formIsValid = false;
      errorsBannerUpdate["name"] = "Banner Name be empty";
    }

    //pinNo
    if (!fieldsBannerUpdate["image"]) {
      formIsValid = false;
      errorsBannerUpdate["image"] = "Please Upload Image!";
    }

    this.setState({ errorsBannerUpdate: errorsBannerUpdate });
    return formIsValid;
  }

  handleValidationPackage = () => {
    let fieldsBanner = this.state.fieldsBanner;
    let errorsBanner = {};
    let formIsValid = true;

    if (!fieldsBanner["name"] || fieldsBanner["name"] === "") {
      formIsValid = false;
      errorsBanner["name"] = "Banner Name cannot be empty";
    }

    // if (!fieldsBanner["image"]) {
    //   formIsValid = false;
    //   errorsBanner["image"] = "Please Upload image!";
    // }
    this.setState({ errorsBanner: errorsBanner });
    return formIsValid;
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getPinCodeList(data));
  }

  handelDeleteButton = (data) => {
    const paginationdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to Delete Banner?',
      message: `Are you sure to delete banner?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(bannerActions.deleteBanner(data, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });

  }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  render() {

    let { banner, users } = this.props;
    let total = banner && banner.getBannerList && banner.getBannerList.getBannerList && banner.getBannerList.getBannerList.total;
    let { filesDetails } = users
    console.log('fieldsBanner["image"]', filesDetails);
    return (
      <>
        <div >
          <LoadingOverlay
            active={false}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>
        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">Add / Remove Banner</h3>

                    </div>
                    {/* <button className="bg-[#FBB00C] hover:bg-[#3a2d0c] px-7 py-1 pt-1 ml-2 text-black font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.handleOpenCreateModal()}> STUDENT </button>
                    <button className="bg-[#FBB00C] hover:bg-[#3a2d0c] px-7 py-1 pt-1 ml-2 text-black font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.handleOpenCreateModal()}> TUTOR </button> */}
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>
                    <button className="bg-[#FBB00C] hover:bg-[#3a2d0c] px-7 py-1 pt-1 ml-2 text-black font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.handleOpenCreateModal()}> ADD </button>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">#</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">Date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">User Type</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">Banner image</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">Enable/Disable</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-sm font-semibold text-black capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {banner &&
                                banner.getBannerList && banner.getBannerList.getBannerList.list.length > 0 ?
                                banner.getBannerList.getBannerList.list.map((element, index) => (<React.Fragment key={element.id}>
                                  <tr key={element.id} className="bg-white  border-b border-black border-opacity-10 ">
                                    <td className="px-6 py-3 whitespace-nowrap text-center font-medium text-sm text-gray-600">
                                      {this.state.offset + index + 1}</td>
                                    <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                      {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                    </td>
                                    <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                      {element && element.name ? element.name : "-"}</td>
                                    <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 flex justify-center">
                                      <img src={element && element.imageLinkUrl ? element.imageLinkUrl : "-"} alt={element && element.imageLinkUrl ? element.imageLinkUrl : "-"} className='w-20 rounded-full' />
                                    </td>
                                    <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                      <label class="flex justify-center items-center cursor-pointer targetablepx-4 tooltip">
                                        <div class="relative" onClick={() => this.onDisable(element)}>
                                          <input type="checkbox" id="toggleB" class="sr-only" />
                                          {element && element.isDisable ?

                                            <div>
                                              <div class="block bg-red-600 w-10 h-6 rounded-full"></div>
                                              <div class="dot absolute right-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                            </div>
                                            :
                                            <div>
                                              <div class="block bg-green-600 w-10 h-6 rounded-full"></div>
                                              <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                            </div>
                                          }
                                          <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>{element && element.isDisable === true ? "enable" : "disable"}</span>
                                        </div>
                                      </label>
                                    </td>

                                    <td className="flex justify-center items-center px-2 py-3 mt-2 text-gray-600 whitespace-nowrap">
                                      <span className="pl-1">
                                        <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.updatehandleOpenCreateModal(element)}>
                                          <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Edit</span>
                                          <HiPencilAlt className='w-5 h-5 block object-contain' />
                                        </div>
                                      </span>

                                      <span className="flex justify-center pl-1">
                                        <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-500 cursor-pointer" onClick={() => this.handelDeleteButton({ id: element.id })}>
                                          <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Delete</span>
                                          <HiTrash className='w-5 h-5 block object-contain' />
                                        </div>
                                      </span>
                                    </td>

                                  </tr>
                                </React.Fragment>))
                                : (<tr className="bg-white bg-opacity-5 " >
                                  <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <nav className="relative z-0 flex justify-end mt-5">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>


                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <CreatePackageModal
          selectedFile={this.state.selectedFile}
          PinCodeCreateModal={this.state.PinCodeCreateModal}
          fieldsBanner={this.state.fieldsBanner}
          errorsBanner={this.state.errorsBanner}
          inputChange={this.inputChange}
          PinCodeubmit={this.PinCodeubmit}
          handleFile={this.handleFile}
          handlePackageHideModal={this.handlePackageHideModal}

        />
        <UpdatePackageModal
          PinCodeUpdateModal={this.state.PinCodeUpdateModal}
          imageLinkUrlRes={this.state.imageLinkUrlRes}
          fieldsBannerUpdate={this.state.fieldsBannerUpdate}
          errorsBannerUpdate={this.state.errorsBannerUpdate}
          inputChangeUpdate={this.inputChangeUpdate}
          packageUpdateSubmit={this.packageUpdateSubmit}
          handlePackageHideModal={this.handlePackageHideModal}
          handleFile={this.handleFile}
        // filesDetails={filesDetails}
        />


      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, tution, city, banner } = state;
  return {
    users,
    tution,
    authentication,
    city,
    banner
  };
}
export default connect(mapStateToProps)(Banner);
