import React from "react";

import Modal from 'react-modal';


export default function CreateAddUserModal(props) {

  let { addUserCreateModal, handleAddUserHideModal, errorsAddUser, inputAddUserChange, addUserSubmit, fieldsAddUser, getTeachCourseList, getAllClass, getAllCity, getAllPinCode, boardItems } = props;

  console.log('fieldsAddUser_____________fieldsAddUser', fieldsAddUser);
  //console.log("userCreateModal  ",userCreateModal);

  return (

    <div className={addUserCreateModal ? "fixed w-full top-0 left-0 h-full inset-0 z-50 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}>
      <div className="w-11/12 mx-auto overflow-y-auto bg-white border border-[#FBB00C] rounded shadow-lg modal-container md:max-w-2xl">
        <div className="modal-content">
          {/*Title*/}
          <div className="flex items-center justify-between pb-3 bg-[#FBB00C] px-6 py-2">
            <p className="text-2xl font-bold">Update Student Details</p>
            <div className="z-50 cursor-pointer modal-close bg-white rounded-full p-1">
              <svg onClick={() => handleAddUserHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          <div className="h-[500px] lg:h-full overflow-y-auto px-6 py-2">

            <form autoComplete="off">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div className="">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900 dark:text-black" for="file_input">Mobile No</label>
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["mobNo"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="mobNo" name="mobNo" placeholder="Mobile No..." value={fieldsAddUser.mobNo} type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["mobNo"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsAddUser["mobNo"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900 dark:text-black" for="file_input">Email</label>
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["email"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="email" name="email" placeholder="Email..." value={fieldsAddUser.email} type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["email"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsAddUser["email"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900 dark:text-black" for="file_input">First name</label>
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["firstName"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="firstName" name="firstName" placeholder="First name..." value={fieldsAddUser.firstName} type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["firstName"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsAddUser["firstName"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900 dark:text-black" for="file_input">Last name</label>
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["lastName"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="lastName" name="lastName" placeholder="Last name..." value={fieldsAddUser.lastName} type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["lastName"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsAddUser["lastName"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900 dark:text-black" for="file_input">Stream</label>
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["stream"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="stream" name="stream" placeholder="Stream..." value={fieldsAddUser.stream} type="text" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["stream"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsAddUser["stream"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900 dark:text-black" for="file_input">Whatsapp No</label>
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["whatsappNo"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="whatsappNo" name="whatsappNo" placeholder="Whatsapp No..." value={fieldsAddUser.whatsappNo} type="number" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["whatsappNo"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsAddUser["whatsappNo"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900 dark:text-black" for="file_input">Dob</label>
                    <input className={`px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsAddUser && !errorsAddUser["dob"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="dob" name="dob" placeholder="Dob..." value={fieldsAddUser.dob} type="dob" onChange={inputAddUserChange} />
                    {errorsAddUser && errorsAddUser["dob"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsAddUser["dob"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="w-full ">
                  <div className="relative mt-1 shadow-sm">
                    <label class="block mb-2 text-sm font-bold text-gray-900 dark:text-black" for="file_input">Gender</label>
                    <select className="px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border border-gray-400" onChange={inputAddUserChange}
                      id="gender"
                      value={fieldsAddUser && fieldsAddUser.gender ? fieldsAddUser.gender : ''}
                      name="gender">
                      <option value="">Please Choose Gender&hellip;</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="w-full space-y-2">
                  <label class="block  text-sm font-bold text-gray-900 dark:text-black" for="username">City:</label>
                  <select class="form-select border-1 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border border-gray-400" aria-label="Default select example" onChange={inputAddUserChange} id="cityId" name="cityId"
                    value={fieldsAddUser && fieldsAddUser["cityId"] ? fieldsAddUser["cityId"] : null} >
                    <option selected>{fieldsAddUser && fieldsAddUser.city && fieldsAddUser.city.name ? fieldsAddUser.city.name : "Please Select City"}</option>
                    {
                      getAllCity && getAllCity && getAllCity.length > 0 ?
                        getAllCity.map((element, index) => (
                          <option value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                        )) : null
                    }
                  </select>
                  {errorsAddUser && errorsAddUser["cityId"] ?
                    <div className="text-xs text-red-600 invalid-feedback">
                      {errorsAddUser["cityId"]}
                    </div>
                    : null}
                </div>
              </div>

              <div className="w-64 mx-auto py-4">
                <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white capitalize transition duration-150 ease-in-out border rounded-full bg-[#FBB00C]" type="button" onClick={addUserSubmit}>Submit</button>
              </div>

            </form>

          </div>
        </div>
      </div>
    </div>






  );
}
