import {
  fastrackBonusConstants
} from '../_constants';

export function fastrackBonus(state = {}, action) {

  switch (action.type) {
    // case fastrackBonusConstants.DEPOSIT_USER_BALANCE_REQUEST:
    //   return {
    //     ...state
    //   };
    // case fastrackBonusConstants.DEPOSIT_USER_BALANCE_SUCCESS:
    //   return {
    //     ...state,
    //     addUserSuccess: true
    //   };
    // case fastrackBonusConstants.DEPOSIT_USER_BALANCE_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };
    case fastrackBonusConstants.ADD_FASTRACK_BONUS_REQUEST:
      return {
        ...state
      };
    case fastrackBonusConstants.ADD_FASTRACK_BONUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case fastrackBonusConstants.ADD_FASTRACK_BONUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case fastrackBonusConstants.GET_LIST_FASTRACK_BONUS_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case fastrackBonusConstants.GET_LIST_FASTRACK_BONUS_SUCCESS:
      return {
        ...state,
       
        loading: false,
        items: action.users.getFastrackBonusList.list,
        total: action.users.getFastrackBonusList.total
      };
    case fastrackBonusConstants.GET_LIST_FASTRACK_BONUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case fastrackBonusConstants.GETALL_FASTRACK_BONUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case fastrackBonusConstants.GETALL_FASTRACK_BONUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getAllFastrackBonus,
        total: action.users.getAllFastrackBonus
      };
    case fastrackBonusConstants.GETALL_FASTRACK_BONUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case fastrackBonusConstants.UPDATE_FASTRACK_BONUS_REQUEST:
      return {
        ...state
      };
    case fastrackBonusConstants.UPDATE_FASTRACK_BONUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case fastrackBonusConstants.UPDATE_FASTRACK_BONUS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case fastrackBonusConstants.DELETE_FASTRACK_BONUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case fastrackBonusConstants.DELETE_FASTRACK_BONUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case fastrackBonusConstants.DELETE_FASTRACK_BONUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case fastrackBonusConstants.DISABLE_FASTRACK_BONUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case fastrackBonusConstants.DISABLE_FASTRACK_BONUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case fastrackBonusConstants.DISABLE_FASTRACK_BONUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}