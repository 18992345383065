import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appsettingActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import CreateAppSettingModal from "./components/CreateAppSettingModal/CreateAppSettingModal";
import UpdateAppSettingModal from "./components/UpdateAppSettingModal/UpdateAppSettingModal";
import { confirmAlert } from 'react-confirm-alert';
import { isMobile } from "react-device-detect";
import { HiTrash, HiPencilAlt } from "react-icons/hi";

class Supprt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsappsetting: {},
      errorsappsetting: {},
      fieldsappsettingUpdate: {},
      errorsappsettingUpdate: {},
      appsettingCreateModal: false,
      appsettingUpdateModal: false,
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      formData: {
        "name": "",
        "key": "",
      },
      updateformData: {
        "id": "",
        "name": "",
        "key": "",
      },
    }
  }
  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(appsettingActions.getAppSettingList(temp));
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.appsetting.addAppSettingSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "key": "",
        },
        appsettingCreateModal: false,
        appsettingUpdateModal: false,
        fieldsappsetting: {},
        errorsappsetting: {},
        fieldsappsettingUpdate: {},
        errorsappsettingUpdate: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  handleOpenCreateModal = () => {
    this.setState({ appsettingCreateModal: true });
  }
  handleAppSeetingHideModal = () => {
    this.setState({ appsettingCreateModal: false });
    this.setState({ appsettingUpdateModal: false });
  }

  updatehandleOpenCreateModal = (data) => {
    this.setState({ appsettingUpdateModal: true, fieldsappsettingUpdate: data });
  }

  onDeleteClick = (data) => {
    console.log(data);
    confirmAlert({
      title: 'Confirm to Delete?',
      message: 'Are you sure to Delete ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(appsettingActions.deleteAppSetting(data))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  onDisable = (data) => {
    console.log(data);
    console.log(data.id);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }

    confirmAlert({
      title: 'Confirm to Disable?',
      message: 'Are you sure to Disable ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(appsettingActions.disableAppSetting(tempdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsappsetting = this.state.fieldsappsetting;
    let errorsappsetting = this.state.errorsappsetting;
    fieldsappsetting[name] = value;
    errorsappsetting[name] = "";
    this.setState({ fieldsappsetting, errorsappsetting });
  }

  inputChangeUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsappsettingUpdate = this.state.fieldsappsettingUpdate;
    let errorsappsettingUpdate = this.state.errorsappsettingUpdate;
    fieldsappsettingUpdate[name] = value;
    errorsappsettingUpdate[name] = "";
    this.setState({ fieldsappsettingUpdate, errorsappsettingUpdate });
  }

  appSettingSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationAppSetting()) {
      let { name, key, value } = this.state.fieldsappsetting;
      this.props.dispatch(appsettingActions.addAppSetting({ name: name, key: key, value: value }, this.props));
    }
  }

  appSettingUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationAppSettingUpdate()) {
      let { name, key, value, id } = this.state.fieldsappsettingUpdate;
      this.props.dispatch(appsettingActions.updateAppSetting({ name: name, key: key, value: value, id }, this.props));
    }
  }

  handleValidationAppSettingUpdate = () => {
    let fieldsappsettingUpdate = this.state.fieldsappsettingUpdate;
    let errorsappsettingUpdate = {};
    let formIsValid = true;

    //Email
    if (!fieldsappsettingUpdate["name"] || fieldsappsettingUpdate["name"] === "") {
      formIsValid = false;
      errorsappsettingUpdate["name"] = "Cannot be empty";
    }

    //Password
    if (!fieldsappsettingUpdate["key"]) {
      formIsValid = false;
      errorsappsettingUpdate["key"] = "Please enter key!";
    }

    //value
    if (!fieldsappsettingUpdate["value"]) {
      formIsValid = false;
      errorsappsettingUpdate["value"] = "Please enter value!";
    }
    this.setState({ errorsappsettingUpdate: errorsappsettingUpdate });
    return formIsValid;
  }

  handleValidationAppSetting = () => {
    let fieldsappsetting = this.state.fieldsappsetting;
    let errorsappsetting = {};
    let formIsValid = true;

    //Email
    if (!fieldsappsetting["name"] || fieldsappsetting["name"] === "") {
      formIsValid = false;
      errorsappsetting["name"] = "Cannot be empty";
    }

    //Password
    if (!fieldsappsetting["key"]) {
      formIsValid = false;
      errorsappsetting["key"] = "Please enter key!";
    }
    this.setState({ errorsappsetting: errorsappsetting });
    return formIsValid;
  }
  render() {

    let { appsetting } = this.props;
    let { items, total, loading } = appsetting;
    console.log("items in render", JSON.stringify(appsetting));

    return (
      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {/* Main Content */}
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" >
          <div className="2xl:p-10 p-5">
            <div className="max-w-screen-3xl mx-auto">
              {/* Wallet Section */}
              <section className="grid grid-cols-12 gap-5 ">
                <div className="bg-white xl:col-span-12  lg:col-span-12 col-span-12 2xl:py-7 py-5 2xl:px-10 px-5 flex-col rounded-md">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">App Setting List</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>
                    <button className="bg-blue-500 hover:bg-blue-700 px-7 py-1 pt-1 ml-2 text-white font-bold  border border-blue-700 rounded flex justify-end" onClick={() => this.handleOpenCreateModal()}> ADD </button>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-sky-300 rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">#</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Name</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Key</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">value</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Status</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Enable/Disable</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Odd row */}
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">
                                        {this.state.offset + index + 1}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.name}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.key}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.value}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {element.status && element.status === true ? "True" : "False"}</td>

                                      {/* <td className="px-2 py-3 whitespace-nowrap text-sm text-gray-600 inline-block">
                                        <td className="px-2 py-3 whitespace-nowrap text-sm text-gray-600 inline-block">
                                          <span class="inline-block pl-1">
                                            <button class="bg-green-600 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-green-100 rounded-xl hover:shadow-lg hover:bg-green-700" onClick={() => this.updatehandleOpenCreateModal(element)}>Update</button>
                                          </span>
                                          <span className="inline-block pl-1" >
                                            <button className={`${element && element.status === true ? "bg-yellow-500 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-blue-700" : "bg-blue-500 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-blue-100 rounded-xl hover:shadow-lg hover:bg-blue-700"}`}
                                              onClick={() => this.onDisable(element)}>{element && element.status === true ? "enable" : "disable"}</button>
                                          </span>
                                          <span className="inline-block pl-1">
                                            <button class="bg-red-500 px-4 py-2 text-sm shadow-sm font-medium tracking-wider border text-red-100 rounded-xl hover:shadow-lg hover:bg-red-700" onClick={() => this.onDeleteClick(element)}>Delete</button>
                                          </span>
                                        </td>
                                      </td> */}


                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <label class="flex justify-center items-center cursor-pointer targetablepx-4 tooltip">
                                          <div class="relative" onClick={() => this.onDisable(element)}>
                                            <input type="checkbox" id="toggleB" class="sr-only" />
                                            <div class="block bg-gray-600 w-10 h-6 rounded-full"></div>
                                            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>{element && element.status === true ? "enable" : "disable"}</span>
                                          </div>
                                        </label>
                                      </td>

                                      <td className="flex px-2 py-3 mt-2 text-gray-600 whitespace-nowrap">
                                        <span className="pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.updatehandleOpenCreateModal(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Edit</span>
                                            <HiPencilAlt className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>

                                        <span className="flex justify-center pl-1">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-500 cursor-pointer" onClick={() => this.onDeleteClick(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Delete</span>
                                            <HiTrash className='w-5 h-5 block object-contain' />
                                          </div>
                                        </span>
                                      </td>




                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }

                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>

        <CreateAppSettingModal
          appsettingCreateModal={this.state.appsettingCreateModal}
          fieldsappsetting={this.state.fieldsappsetting}
          errorsappsetting={this.state.errorsappsetting}
          inputChange={this.inputChange}
          appSettingSubmit={this.appSettingSubmit}
          handleAppSeetingHideModal={this.handleAppSeetingHideModal}
        />
        <UpdateAppSettingModal
          appsettingUpdateModal={this.state.appsettingUpdateModal}
          fieldsappsettingUpdate={this.state.fieldsappsettingUpdate}
          errorsappsettingUpdate={this.state.errorsappsettinUpdate}
          inputChangeUpdate={this.inputChangeUpdate}
          appSettingUpdateSubmit={this.appSettingUpdateSubmit}
          handleAppSeetingHideModal={this.handleAppSeetingHideModal}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, appsetting } = state;
  return {
    users,
    appsetting,
    authentication
  };
}
export default connect(mapStateToProps)(Supprt);
