import React from "react";

import Modal from 'react-modal';


export default function CreateUpdatePasswordDealer(props) {

  let { UpdateboardModal, handleboardUpdateHideModal, errorsUpdateboard, inputChangeUpdateboard, updateboardSubmit, fieldsUpdateboard,
    //  handleFile,
      allStatesItems } = props;
  console.log("MODAL____fieldsUpdateboard", fieldsUpdateboard);

  return (

    <Modal
      isOpen={UpdateboardModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Update board</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleboardUpdateHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">

                <div class="flex justify-center">
                  {/* <div class="mb-3 ">
                    <label className="text-white text-sm font-medium ">Coin :</label>
                    <select class="form-select appearance-none block w-[400px] px-3 py-1.5 text-base font-normal  text-gray-700  bg-white bg-clip-padding bg-no-repeat  border border-solid border-gray-300 rounded transition ease-in-out  m-0  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example"
                      onChange={inputChangeUpdateboard}
                      id="stateId"
                      name="stateId"
                    // value={fieldsUpdateboard && fieldsUpdateboard.stateId && fieldsUpdateboard.stateId["name"] ? fieldsUpdateboard.stateId["name"] : null}

                    // value={fieldsUpdateboard && fieldsUpdateboard.stateId && fieldsUpdateboard.stateId.name ? fieldsUpdateboard.stateId.name : null}
                    >
                      <option selected>{fieldsUpdateboard && fieldsUpdateboard.stateId && fieldsUpdateboard.stateId.name ? fieldsUpdateboard.stateId.name : "NA"}</option>
                      {
                        allStatesItems && allStatesItems && allStatesItems.length > 0 ?
                          allStatesItems.map((element, index) => (

                            <option value={element.id}>{element && element.name ? element.name : "NA"}</option>

                          ))
                          : null
                      }
                    </select>
                    {errorsUpdateboard && errorsUpdateboard["stateId"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateboard["stateId"]}
                      </div>
                      : null}
                  </div> */}
                </div>


                <div className="{otpSent?'disableArea':''}name">
                  <div className="mt-1  shadow-sm relative">
                    <label className="py-1">Board Name</label>
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateboard && !errorsUpdateboard["name"] ? "border border-gray-400 placeholder-gray-500" : "border border-opaboard-100 border-red-500 "}`}
                      id="name" name="name" value={fieldsUpdateboard.name} placeholder="name" type="text" onChange={inputChangeUpdateboard} />
                    {errorsUpdateboard && errorsUpdateboard["name"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsUpdateboard["name"]}
                      </div>
                      : null}
                  </div>
                </div>
         
           

                <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={updateboardSubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
