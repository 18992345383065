import React, { Component } from 'react';
import { connect } from 'react-redux';
import { coinActions, userActions, teachcourseActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { MdOutlineSearch } from "react-icons/md";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';
import { CONST } from "../../_config/index"
import { authHeader } from "../../_helpers"


class Coin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsCoin: {},
      errorsCoin: {},
      studentData: {},
      fieldsUpdateCoin: {},
      errorsUpdateCoin: {},
      viewRowData: {},
      allDataValue: {},
      allDataValue1: {},
      userResData: {},
      tutorDataRes: {},
      suggestedTutorDataRes: {},
      coinCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCoinModal: false,
      addTuitoinModal: false,
      loginToThisAccountModal: false,
      moreDetailsHandel: false,
      checkApplication: false,
      assignTutorMenually: false,
      assignSuggestedTutor: false,
      isOpenSuggested: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      page: 1,
      keyWord: '',
      assignTutor: '',
      demoTaken: null,
      isReassign: '',
      isOpen: false,

    }
  }
  componentDidMount() {

    let data = {
      "isTakenDemo": 1,
      "status": 1,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let data1 = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));
    this.props.dispatch(cityActions.getAllCity(data1));
    let data3 = {
      "userType": "STUDENT",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActiveStudent(data3));

  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.teachcourse.addUserSuccess) {
      return {
        ...nextProps,
        fieldsCoin: {},
        errorsCoin: {},
        fieldsUpdateCoin: {},
        errorsUpdateCoin: {},
        coinCreateModal: false,
        UpdateCoinModal: false,
        checkApplication: false,
        isOpen: false,
        assignTutorMenually: false,
        assignSuggestedTutor: false,
        isOpenMannually: false
      }
    } else {
      return {
        ...nextProps,
      }
    }
  }

  handlePageClick2 = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected + 1 });
    let datatemp = {
      "isTakenDemo": 1,
      "tutorStatus": this.state.fieldsCoin && this.state.fieldsCoin.tutorStatus ? this.state.fieldsCoin.tutorStatus : null,
      "tutorAssignStatus": this.state.fieldsCoin && this.state.fieldsCoin.tutorAssignStatus ? this.state.fieldsCoin.tutorAssignStatus : null,
      "status": 1,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });

    let data2 = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": value,
      "pageNo": this.state.pageNo,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActive(data2));


  }

  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(coinActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }

  inputCoinChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = this.state.errorsCoin;
    fieldsCoin[name] = value;
    console.log(name, value);
    errorsCoin[name] = "";
    this.setState({ fieldsCoin, errorsCoin });
  }
  inputChangeUpdateCoin = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // console.log(name, value);
    if (!value) {
      console.log('!!!!!!!!!!!!!');
    }
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = this.state.errorsUpdateCoin;
    fieldsUpdateCoin[name] = value;
    errorsUpdateCoin[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateCoin, errorsUpdateCoin });
  }

  createCoinSubmit = () => {


    if (this.handleValidationCoin()) {
      let reqData = {
        "name": this.state.fieldsCoin.name,
        "shortName": this.state.fieldsCoin.shortName,
      }
      this.props.dispatch(teachcourseActions.createTeachCourse(reqData));
    }

  }


  updateCoinSubmit = (data) => {
    let reqData = {
      "demoClassId": this.state.allDataValue.id,
      "tutorId": data.id

    }
    let paginationData = {
      "status": "1",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to assign?',
      message: `
      Would you like to hire ${data.fullName} as a tutor??`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.assignDemoClassToTutor(reqData, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  suggestedTutorSubmit = (data) => {
    let reqData = {
      "demoClassId": this.state.allDataValue.id,
      "tutorId": data.user.userDetails._id

    }
    let paginationData = {
      "status": "1",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to assign?',
      message: `
      Would you like to hire ${data.user.userDetails.fullName} as a tutor??`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.assignDemoClassToTutor(reqData, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  updateCoinSubmitManual = (data) => {
    console.log('data_______________________', this.state.allDataValue);
    let reqData = {
      "demoClassId": this.state.allDataValue.id,
      "tutorId": data.id,
      "status": "REASSIGN"
    }
    let paginationData = {
      "isTakenDemo": 1,
      "status": 1,
      "keyWord": '',
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    console.log('paginationData_____________________10', paginationData);

    confirmAlert({

      title: 'Confirm to assign?',
      message: `
      Would you like to hire ${data.fullName} as a tutor??`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.acceptRejectOrReassignTutorForDemoClass(reqData, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  searchAssignList = () => {
    if (this.handleValidationTutorAssignStatus()) {

      let data = {
        "isTakenDemo": 1,
        "status": 1,
        "tutorAssignStatus": this.state.fieldsCoin && this.state.fieldsCoin.tutorAssignStatus ? this.state.fieldsCoin.tutorAssignStatus : null,
        "tutorStatus": this.state.fieldsCoin && this.state.fieldsCoin.tutorStatus ? this.state.fieldsCoin.tutorStatus : null,
        "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
        "cityId": this.state.studentData['cityId'] && this.state.studentData['cityId'].length > 0 ? this.state.studentData['cityId'] : null,
        "requiredGender": this.state.fieldsCoin && this.state.fieldsCoin['requiredGender'] ? this.state.fieldsCoin['requiredGender'] : null,
        "keyWord": this.state.keyWord,
        // "pageNo": this.state.pageNo,
        // "size": this.state.size
      }
      this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));

    }

  }
  handleValidationTutorAssignStatus = () => {
    let fieldsCoin = this.state.fieldsCoin;
    let errorsUpdateCoin = {};
    let formIsValid = true;

    //tutorAssignStatus
    if (!fieldsCoin["tutorAssignStatus"] || fieldsCoin["tutorAssignStatus"] === "") {
      formIsValid = false;
      errorsUpdateCoin["tutorAssignStatus"] = "Cannot be empty";
    }

    console.log("errorsUpdateCoinerrorsUpdateCoin_errorsUpdateCoinerrorsUpdateCoin:", errorsUpdateCoin);

    this.setState({ errorsUpdateCoin: errorsUpdateCoin });
    return formIsValid;
  }
  searchTutorResList = () => {
    if (this.handleValidationTutorStatus()) {

      let data = {
        "isTakenDemo": 1,
        "status": 1,
        "tutorAssignStatus": this.state.fieldsCoin && this.state.fieldsCoin.tutorAssignStatus ? this.state.fieldsCoin.tutorAssignStatus : null,
        "tutorStatus": this.state.fieldsCoin && this.state.fieldsCoin.tutorStatus ? this.state.fieldsCoin.tutorStatus : null,
        "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
        "cityId": this.state.studentData['cityId'] && this.state.studentData['cityId'].length > 0 ? this.state.studentData['cityId'] : null,
        "requiredGender": this.state.fieldsCoin && this.state.fieldsCoin['requiredGender'] ? this.state.fieldsCoin['requiredGender'] : null,
        "keyWord": this.state.keyWord,
        // "pageNo": this.state.page,
        // "size": this.state.size
      }
      this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));

    }

  }
  handleValidationTutorStatus = () => {
    let fieldsCoin = this.state.fieldsCoin;
    let errorsUpdateCoin = {};
    let formIsValid = true;

    //tutorStatus
    if (!fieldsCoin["tutorStatus"] || fieldsCoin["tutorStatus"] === "") {
      formIsValid = false;
      errorsUpdateCoin["tutorStatus"] = "Cannot be empty";
    }

    console.log("errorsUpdateCoinerrorsUpdateCoin_errorsUpdateCoinerrorsUpdateCoin:", errorsUpdateCoin);

    this.setState({ errorsUpdateCoin: errorsUpdateCoin });
    return formIsValid;
  }
  handleValidationUpdateCoin = () => {
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = {};
    let formIsValid = true;

    //assignTutor
    if (!fieldsUpdateCoin["assignTutor"] || fieldsUpdateCoin["assignTutor"] === "") {
      formIsValid = false;
      errorsUpdateCoin["assignTutor"] = "Cannot be empty";
    }

    console.log("errorsUpdateCoinerrorsUpdateCoin_errorsUpdateCoinerrorsUpdateCoin:", errorsUpdateCoin);

    this.setState({ errorsUpdateCoin: errorsUpdateCoin });
    return formIsValid;
  }
  handleValidationCoin = () => {
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = {};
    let formIsValid = true;

    //Email
    if (!fieldsCoin["name"] || fieldsCoin["name"] === "") {
      formIsValid = false;
      errorsCoin["name"] = "Cannot be empty name";
    }

    if (!fieldsCoin["shortName"] || fieldsCoin["shortName"] === "") {
      formIsValid = false;
      errorsCoin["shortName"] = "Cannot be empty shortName";
    }



    this.setState({ errorsCoin: errorsCoin });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  changeStatusDemoClass = (data, status) => {
    let paginationData = {
      "status": "1",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let tempdata = {
      "id": data.id,
      "status": status,
    }
    confirmAlert({
      title: 'Confirm to Accept?',
      message: 'Are you sure to accept demo of phone no.:- ' + data.studentId.mobNo,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.changeStatusDemoClass(tempdata, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  moreDetailsOpenModul = (data, status) => {
    this.setState({ moreDetailsHandel: true, allDataValue: data })
  }
  moreDetailsCloseModul = (data, status) => {
    this.setState({ moreDetailsHandel: false, allDataValue: {} })
  }
  checkApplicationOpen = (data, status) => {
    this.setState({ checkApplication: true, allDataValue: data })
  }
  checkApplicationClose = (data, status) => {
    this.setState({ checkApplication: false, allDataValue: {}, isOpen: false })
  }
  assignTutorMenuallyOpen = (data) => {
    this.setState({ assignTutorMenually: true, allDataValue: data })
    let data2 = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "cityId": data && data.cityId && data.cityId.id ? [data.cityId.id] : null,
      "pageNo": this.state.pageNo,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActive(data2));

  }
  assignTutorMenuallyClose = () => {
    this.setState({ assignTutorMenually: false, allDataValue: {} })
  }
  assignSuggestedTutorOpen = (data, status) => {
    let resData = {
      id: data.id
    }
    this.props.dispatch(teachcourseActions.getSuggestedTutor(resData));
    this.setState({ assignSuggestedTutor: true, allDataValue: data })
  }
  assignSuggestedTutorClose = () => {
    this.setState({ assignSuggestedTutor: false, allDataValue: {} })
  }


  handelIsOpenFnction = (data) => {
    this.setState({ isOpen: !this.state.isOpen, userResData: data })
  }
  handelIsOpenFnctionMannually = (data) => {
    this.setState({ isOpenMannually: !this.state.isOpenMannually, tutorDataRes: data })
  }
  handelIsOpenFnctionSuggested = (data) => {
    this.setState({ isOpenSuggested: !this.state.isOpenSuggested, suggestedTutorDataRes: data })
  }
  classReferenceSubmit = () => {
    let data = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "cityId": this.state.studentData['cityId'] && this.state.studentData['cityId'].length > 0 ? this.state.studentData['cityId'] : null,
      "requiredGender": this.state.fieldsCoin && this.state.fieldsCoin['requiredGender'] ? this.state.fieldsCoin['requiredGender'] : null,
      "status": 1,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    console.log('data_________________data', data);
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));


  }

  resetDataRes = (data) => {
    this.props.history.push('/app/tutorapplieddemoclass')
  }

  handleChangeInputInternal = (event) => {

    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["studentId"] = output;
    this.setState({ studentData });

  };

  handleChangeInputCityId = (event) => {

    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["cityId"] = output;
    this.setState({ studentData });

  };
  demoReminderSubmit = (data) => {
    let resData = {
      "tutorId": data.tutorId.id
    }
    confirmAlert({


      title: 'Confirm to send notification ?',
      message: `Are you sure to send notification ${data.tutorId.fullName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let header = new Headers({
              'Content-Type': 'application/json',
              "Authorization": authHeader().Authorization
            });
            const requestOptions = {
              method: "POST",
              headers: header,
              body: JSON.stringify(resData)
            }
            return fetch(CONST.BACKEND_URL + `/tutorDemoDoneReminder`, requestOptions)
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  render() {

    let { users, teachcourse, city } = this.props;
    let { getUserList, getUserStudentList } = users;
    let { total, loading, itemsTeachCourse, suggestesTutorData } = teachcourse;
    let { getAllCity } = city;
    console.log('this.state.allDataValue', this.state.assignTutorMenually)
    let NameRes = getUserStudentList && getUserStudentList.length ? getUserStudentList.map((ele, index) => ({
      value: ele.id, label: ele.firstName + " " + ele.lastName + " " + ele.mobNo
    })) : null
    let cityName = getAllCity && getAllCity.length ? getAllCity.map((ele, index) => ({ value: ele.id, label: ele.name })) : null


    // let companyShareId = (this.state.fieldstution && this.state.fieldstution.fee ? this.state.fieldstution.fee : 0) - (this.state.fieldstution && this.state.fieldstution.refFee ? this.state.fieldstution.refFee : 0) - (this.state.fieldstution && this.state.fieldstution.tutorFee ? this.state.fieldstution.tutorFee : this.state.fieldstution.inHandFees ? this.state.fieldstution.inHandFees : 0)


    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">Assign Tutor ({total})</h3>
                    </div>
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <thead className="bg-[#fff] rounded-t">
                            <tr className=" ">
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>Student name or Number </label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputInternal}
                                    options={NameRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>City</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputCityId}
                                    options={cityName}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>


                              <th scope="col" className="whitespace-nowrap py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <div className='flex flex-col items-center'>
                                  <label className='invisible' htmlFor="resourceList" style={{ color: 'black' }}>City</label>
                                  <select class="bg-[#FBB00C]  py-2 px-4 text-sm shadow-sm w-40 font-bold tracking-wider text-white rounded-full hover:shadow-lg" onClick={this.inputCoinChange}
                                    id='requiredGender' name='requiredGender'>
                                    <option className='bg-white text-black' value="">Select Preferred Gender</option>
                                    <option className='bg-white text-black' value="Male">Male</option>
                                    <option className='bg-white text-black' value="Female">Female</option>
                                    <option className='bg-white text-black' value="No Preference">No Preference</option>
                                  </select>
                                </div>
                              </th>


                              <th>
                                <label className='invisible' htmlFor="resourceList" style={{ color: 'black' }}>City</label>
                                <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.classReferenceSubmit()}> Search </button>
                              </th>
                              <th>
                                <label className='invisible' htmlFor="resourceList" style={{ color: 'black' }}>City</label>
                                <button className="bg-[#25BF63] hover:bg-[#25BF63] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.resetDataRes()}> Reset </button>
                              </th>
                            </tr>
                          </thead>

                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FFF] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                  <div className='flex items-center'>
                                    <select class="bg-[#FBB00C]  py-1 text-sm shadow-sm w-32 font-bold tracking-wider text-white rounded-full hover:shadow-lg" onClick={this.inputCoinChange}
                                      id='tutorAssignStatus' name='tutorAssignStatus'>

                                      <option className='bg-white text-black' value="">Select</option>
                                      <option className='bg-white text-black' value="ASSIGN">Assigned</option>
                                      <option className='bg-white text-black' value="NOASSIGN">Not Assigned</option>
                                    </select>
                                    <button className="bg-[#25BF63] hover:bg-[#25BF63] px-2 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.searchAssignList()}> Search </button>
                                  </div>
                                </th>
                                <th scope="col" className="whitespace-nowrap py-2 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"></th>
                                <th scope="col" className="whitespace-nowrap py-2 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"></th>
                                <th scope="col" className="whitespace-nowrap py-2 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"></th>
                                <th scope="col" className="whitespace-nowrap py-2 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"></th>
                                <th scope="col" className="whitespace-nowrap py-2 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">
                                  <div className='flex items-center'>
                                    <select class="bg-[#FBB00C]  py-1 text-sm shadow-sm w-32 font-bold tracking-wider text-white rounded-full hover:shadow-lg" id='tutorStatus' name='tutorStatus' onClick={this.inputCoinChange}>
                                      <option className='bg-white text-black' value="">Select</option>
                                      <option className='bg-white text-black' value="PENDING">Pending</option>
                                      <option className='bg-white text-black' value="ACCEPT">Accepted</option>
                                      <option className='bg-white text-black' value="REJECT">Rejected</option>
                                    </select>
                                    <button className="bg-[#25BF63] hover:bg-[#25BF63] px-2 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.searchTutorResList()}> Search </button>
                                  </div>
                                </th>
                                <th scope="col" className="whitespace-nowrap py-2 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"></th>
                              </tr>
                            </thead>
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">Name & Mobile Number </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Demo Address</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Class & Subject</th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Preferred Gender</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Class Reference </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Assigned Tutor</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Status By Tutor</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Action</th>
                              </tr>
                            </thead>
                            {/* Table Row Section */}
                            <tbody>
                              {
                                itemsTeachCourse && itemsTeachCourse.length > 0 ?
                                  itemsTeachCourse.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap  text-sm text-gray-600 flex items-center space-x-2 pt-12">
                                        <div className='w-12 h-12'>
                                          <img
                                            class="w-12 h-12 rounded-full"
                                            src={element && element.studentId && element.studentId.imageLinkUrl ? element.studentId.imageLinkUrl :
                                              element && element.requiredGender && element.requiredGender == "Male" ? "images/boyImageLogo.png" : element && element.requiredGender && element.requiredGender == "Female" ? "images/girlImageLogo.png" : "images/male-female.png"}
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <p> {element && element.studentId && element.studentId.firstName ? element.studentId.firstName : null} {element && element.studentId && element.studentId.lastName ? element.studentId.lastName : null}</p>
                                          <p>{element && element.studentId && element.studentId.mobNo ? element.studentId.mobNo : "-"}</p>
                                          <div className='flex items-center justify-end space-x-1' onClick={() => this.moreDetailsOpenModul(element)}><span className='font-bold'>More</span>  <span className='bg-[#FBB00C] text-white rounded-full p-1'><AiOutlineDoubleRight size={10} /> </span></div>
                                        </div>
                                      </td>
                                      {/* <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600 text-center ">

                                        <p> {element && element.fullAddress ? element.fullAddress.slice(" ") : null}</p>
                                        <p>{element && element.landmark ? element.landmark.split(" ") : null}</p>
                                       
                                      </td> */}

                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600 text-center" style={{ maxWidth: "200px" }}>
                                        <p
                                          title={element && element.fullAddress}
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          {element && element.fullAddress ? element.fullAddress.slice(" ") : null}
                                        </p>
                                        <p
                                          title={element && element.landmark}
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          {element && element.landmark ? element.landmark.slice(" ") : null}
                                        </p>
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        <p>{element && element.class && element.class.name ? element.class.name : "-"}</p>
                                        <p>{element && element.course[0] && element.course[0].name ? element.course[0].name : "-"}</p>
                                      </td>
                                      <td className="px-2 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        {element && element.requiredGender ? element.requiredGender : "NA"}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600 text-center ">
                                        {element && element.classReference ? element.classReference : "NA"}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        <p> {element && element.tutorId && element.tutorId.fullName ? element.tutorId.fullName : "-"} </p>
                                        <p> {element && element.tutorId && element.tutorId.mobNo ? element.tutorId.mobNo : "-"} </p>
                                      </td>
                                      {
                                        element.tutorStatus && element.tutorStatus === 1 ?
                                          < td className="px-6 py-3 text-bold text-center text-green-600 whitespace-nowrap">
                                            Accepted
                                          </td>
                                          :
                                          element.tutorStatus && element.tutorStatus === 2 ?
                                            < td className="px-6 py-3 text-bold text-center text-red-600 whitespace-nowrap">
                                              Rejected
                                            </td>
                                            :
                                            < td className="px-6 py-3 text-bold text-center text-blue-600 whitespace-nowrap">
                                              Pending
                                            </td>
                                      }

                                      <td className="px-6 py-3 text-center  text-sm text-gray-600 space-y-2">
                                        {/* {element.tutorId == null ? */}
                                        <div>
                                          {element.tutorStatus && element.tutorStatus === 1 ? <span class="inline-block pl-1">
                                            <button class="bg-blue-600  py-1.5 text-sm shadow-sm w-56 font-bold tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-blue-700" onClick={() => this.demoReminderSubmit(element)}>Demo Done Reminder</button>
                                          </span>
                                            :
                                            null}
                                        </div>
                                        <div>
                                          {element.tutorAppliedList.length > 0 ? <span class="inline-block pl-1">
                                            <button class="bg-green-600  py-1.5 text-sm shadow-sm w-56 font-bold tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-green-700" onClick={() => this.checkApplicationOpen(element)}>Check Applications</button>
                                          </span>
                                            :
                                            <span class="inline-block pl-1">
                                              <button class="bg-black  py-1.5 text-sm shadow-sm w-56 font-bold tracking-wider border text-white rounded-full hover:shadow-lg " >Check Applications</button>
                                            </span>}
                                        </div>
                                        <div>
                                          <span className="inline-block pl-1">
                                            <button class="bg-orange-500  py-1.5 text-sm shadow-sm w-56 font-bold tracking-wider border text-white rounded-full hover:shadow-lg" onClick={() => this.assignSuggestedTutorOpen(element)}>Suggested Tutors </button>
                                          </span>
                                        </div>
                                        <div>
                                          <span className="inline-block pl-1">
                                            <button class="bg-[#FBB00C]  py-1.5 text-sm shadow-sm w-56 font-bold tracking-wider border text-white rounded-full hover:shadow-lg" onClick={() => this.assignTutorMenuallyOpen(element)}>Assign Tutor Manually </button>
                                          </span>
                                        </div>
                                        <div>
                                          <span className="inline-block pl-1">
                                            <button class="bg-red-600 hover:bg-red-400  py-1.5 text-sm shadow-sm w-56 font-bold tracking-wider border text-white rounded-full hover:shadow-lg" onClick={() => this.changeStatusDemoClass(element, 3)}>Reject</button>
                                          </span>
                                        </div>
                                      </td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick2}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick2}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>


                  {this.state.moreDetailsHandel ?
                    <>
                      <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                        <div className="border border-yellow-500 modal-container bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/3 mx-auto rounded-[40px] shadow-lg z-50 overflow-y-auto">
                          <div className="modal-content ">
                            <div className="flex justify-between items-center modal-close cursor-pointer z-50 py-2 px-6 bg-[#FBB00C]">
                              <p className='font-bold text-md'>More</p>
                              <div className='bg-white rounded-full p-1'>
                                <svg
                                  onClick={() => this.moreDetailsCloseModul()}
                                  className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                  </path>
                                </svg>
                              </div>
                            </div>
                            <div className='py-4 px-6 h-[400px] overflow-y-auto divide-y-2'>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Tutor Name :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.tutorId && this.state.allDataValue.tutorId.fullName ? this.state.allDataValue.tutorId.fullName
                                  : "-"}</p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Student Name :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.firstName ? this.state.allDataValue.studentId.firstName
                                  : "-"} {"  "}{this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.lastName ? this.state.allDataValue.studentId.lastName
                                    : "-"}</p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Guardian Name :</p>
                                <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianName ? this.state.allDataValue.studentId.studentInfoId.guardianName : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Guardian Mob No. :</p>
                                <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianConNo ? this.state.allDataValue.studentId.studentInfoId.guardianConNo : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Address :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.fullAddress ? this.state.allDataValue.fullAddress
                                  : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Gender :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.gender ? this.state.allDataValue.studentId.gender
                                  : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>  <p className='text-lg font-bold'>School :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.schoolOrCollegeName ? this.state.allDataValue.studentId.studentInfoId.schoolOrCollegeName : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Student Mob No. : </p>
                                <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.mobNo ? this.state.allDataValue.studentId.mobNo
                                  : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Class Name :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.class && this.state.allDataValue.class.name ? this.state.allDataValue.class.name
                                  : "-"} </p>
                              </div>
                              <div className='flex justify-between items-start w-full'>
                                <div>
                                  <p className='text-lg font-bold'>Subject:</p>
                                </div>
                                <div>
                                  <ul className='text-right'>
                                    {this.state.allDataValue && this.state.allDataValue.course && this.state.allDataValue.course.length > 0 ?
                                      this.state.allDataValue.course.map((element, index) =>
                                        <>
                                          <li className='flex flex-col'>{element && element.name ? element.name : "NA"} </li>
                                        </>
                                      ) : 'NA'}
                                  </ul>
                                </div>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Number of class :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.numberOfClasses ? this.state.allDataValue.numberOfClasses : "0"}  </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Tuition Mode :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.teachingMode ? this.state.allDataValue.teachingMode : "-"}  </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Date :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.date ? this.state.allDataValue.date : "-"}  </p>
                              </div>

                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Class Time :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.startTime ? this.state.allDataValue.startTime : "-"} to  {this.state.allDataValue && this.state.allDataValue.endTime ? this.state.allDataValue.endTime : "-"} </p>
                              </div>

                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>City Name :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.cityId && this.state.allDataValue.cityId.name ? this.state.allDataValue.cityId.name
                                  : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Pin code :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.pinCodeId && this.state.allDataValue.pinCodeId.pinNo ? this.state.allDataValue.pinCodeId.pinNo
                                  : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Board Name :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.board && this.state.allDataValue.board.name ? this.state.allDataValue.board.name
                                  : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>In Hand Fees :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.inHandFees ? this.state.allDataValue.inHandFees
                                  : "-"} </p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Remark :</p>
                                <p > {this.state.allDataValue && this.state.allDataValue.remark ? this.state.allDataValue.remark
                                  : "-"} </p>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </>
                    :
                    null
                  }
                  {this.state.checkApplication ?
                    <>
                      <div className="main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                        <div className="border-2 border-black shadow-lg modal-container bg-white w-96 mx-auto rounded-[40px]  z-50 overflow-y-auto p-4">
                          <div className="modal-close cursor-pointer z-50">
                            <svg
                              onClick={() => this.checkApplicationClose()}
                              className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                          <div className="modal-content text-left h-[550px] overflow-y-auto">
                            {
                              this.state.allDataValue && this.state.allDataValue.tutorAppliedList && this.state.allDataValue.tutorAppliedList.length > 0 ?
                                this.state.allDataValue.tutorAppliedList.map((ele, index) => (
                                  <>
                                    <div className='flex justify-between items-center py-4 border-b-2'>
                                      <div className='flex items-center space-x-2'>
                                        <div>
                                          <img
                                            class="w-12 h-12 rounded-full"
                                            src={ele && ele.imageLinkUrl ? ele.imageLinkUrl : null}
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <p>{ele && ele.fullName ? ele.fullName : null}</p>
                                          <p className='text-[#213360] text-xs'>{ele && ele.mobNo ? ele.mobNo : null}</p>
                                        </div>
                                      </div>
                                      <div className='flex items-center space-x-4'>
                                        <div className='flex items-center space-x-1' onClick={() => this.handelIsOpenFnction({ ...ele, index: index })}><span className='font-bold'>More</span>  <span className='bg-green-600 text-white rounded-full p-1'><AiOutlineDoubleRight size={10} /> </span></div>
                                        <button class="bg-green-600  py-1 text-sm shadow-sm px-2 font-bold tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-green-700" onClick={() => this.updateCoinSubmit(ele)}>Assign</button>
                                      </div>
                                    </div>
                                    {this.state.isOpen && this.state.userResData.index === index && (
                                      <>
                                        <div className='bg-[#E8E8E8] w-full p-4'>
                                          <div className='flex justify-between items-center text-[#213360]'>
                                            <p>Gender :</p>
                                            <p>{this.state.userResData && this.state.userResData.gender ? this.state.userResData.gender : 'NA'}</p>
                                          </div>
                                          <div className='flex justify-between items-start text-[#213360]'>
                                            <p className='w-full'>Address :</p>
                                            <p className='w-full text-end'>{this.state.userResData && this.state.userResData.address ? this.state.userResData.address : 'NA'}</p>
                                          </div>
                                          <div className='flex justify-between items-start text-[#213360]'>
                                            <p className='w-full'>School :</p>
                                            <p className='w-full text-end'>{this.state.userResData && this.state.userResData.tutorInfoId && this.state.userResData.tutorInfoId.schoolName ? this.state.userResData.tutorInfoId.schoolName : 'NA'}</p>
                                          </div>
                                          <div className='flex justify-between items-start text-[#213360]'>
                                            <p className='w-full '>Graduation :</p>
                                            <p className='w-full text-end'>{this.state.userResData && this.state.userResData.tutorInfoId && this.state.userResData.tutorInfoId.ugCourse ? this.state.userResData.tutorInfoId.ugCourse : 'NA'}</p>
                                          </div>
                                          <div className='flex justify-between items-start text-[#213360]'>
                                            <p className='w-full'>Post Graduation :</p>
                                            <p className='w-full text-end'>{this.state.userResData && this.state.userResData.tutorInfoId && this.state.userResData.tutorInfoId.pgClgName ? this.state.userResData.tutorInfoId.pgClgName : 'NA'}</p>
                                          </div>
                                          <div className='flex justify-between items-center text-[#213360]'>
                                            <p>Experience :</p>
                                            <p>{this.state.userResData && this.state.userResData.experience ? this.state.userResData.experience : 'NA'}</p>
                                          </div>

                                        </div>
                                      </>)
                                    }
                                  </>
                                )) :
                                <div>DATA NOT FOUND</div>}

                          </div>
                        </div>
                      </div>

                    </>
                    :
                    null
                  }
                  {this.state.assignTutorMenually ?
                    <div className="main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                      <div className="border-2 border-black shadow-lg modal-container bg-white w-96 mx-auto rounded-[40px]  z-50 overflow-y-auto">
                        <div className='py-4'>
                          <div className="modal-close cursor-pointer z-50 pl-6">
                            <svg
                              onClick={() => this.assignTutorMenuallyClose()}
                              className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                          <div className='flex justify-center items-center'>
                            <div className="relative mt-5 lg:mt-0">
                              <div className="absolute top-2.5 left-4 text-sky-500"><MdOutlineSearch /></div>
                              <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-[#fff] placeholder-gray-800  transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search Here" type="amount" required onChange={this.handleSearch} />
                            </div>
                          </div>
                        </div>
                        <div className="modal-content py-4 text-left px-6  h-[550px] overflow-y-auto">
                          {
                            getUserList && getUserList.length > 0 ?
                              getUserList.map((ele, index) => (
                                <>
                                  <div className='flex justify-between items-center py-4 border-b-2'>
                                    <div className='flex items-center space-x-2'>
                                      <div>
                                        <img
                                          class="w-12 h-12 rounded-full"
                                          src={ele && ele.imageLinkUrl ? ele.imageLinkUrl : null}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p>{ele && ele.fullName ? ele.fullName : null}</p>
                                        <p className='text-[#213360] text-xs'>{ele && ele.mobNo ? ele.mobNo : null}</p>
                                      </div>
                                    </div>
                                    <div className='flex items-center space-x-4'>
                                      <div className='flex items-center space-x-1' onClick={() => this.handelIsOpenFnctionMannually({ ...ele, index: index })}><span className='font-bold'>More</span>  <span className='bg-green-600 rounded-full text-white p-1'><AiOutlineDoubleRight size={10} /> </span></div>
                                      <button class="bg-green-600  py-1 text-sm shadow-sm px-2 font-bold tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-green-700" onClick={() => this.updateCoinSubmitManual(ele)}>Assign</button>
                                    </div>
                                  </div>
                                  {this.state.isOpenMannually && this.state.tutorDataRes.index === index && (
                                    <>
                                      <div className='bg-[#E8E8E8] w-full p-4'>
                                        <div className='flex justify-between items-center text-[#213360]'>
                                          <p>Gender :</p>
                                          <p>{this.state.tutorDataRes && this.state.tutorDataRes.gender ? this.state.tutorDataRes.gender : 'NA'}</p>
                                        </div>
                                        <div className='flex justify-between items-start text-[#213360]'>
                                          <p className='w-full'>Address :</p>
                                          <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.address ? this.state.tutorDataRes.address : 'NA'}</p>
                                        </div>
                                        <div className='flex justify-between items-start text-[#213360]'>
                                          <p className='w-full'>School :</p>
                                          <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.tutorInfoId && this.state.tutorDataRes.tutorInfoId.schoolName ? this.state.tutorDataRes.tutorInfoId.schoolName : 'NA'}</p>
                                        </div>
                                        <div className='flex justify-between items-center text-[#213360]'>
                                          <p>Graduation :</p>
                                          <p>{this.state.tutorDataRes && this.state.tutorDataRes.tutorInfoId && this.state.tutorDataRes.tutorInfoId.ugCourse ? this.state.tutorDataRes.tutorInfoId.ugCourse : 'NA'}</p>
                                        </div>
                                        <div className='flex justify-between items-start text-[#213360]'>
                                          <p className='w-full'>Post Graduation :</p>
                                          <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.tutorInfoId && this.state.tutorDataRes.tutorInfoId.pgClgName ? this.state.tutorDataRes.tutorInfoId.pgClgName : 'NA'}</p>
                                        </div>
                                        <div className='flex justify-between items-center text-[#213360]'>
                                          <p>Experience:</p>
                                          <p>{this.state.tutorDataRes && this.state.tutorDataRes.experience ? this.state.tutorDataRes.experience : 'NA'}</p>
                                        </div>

                                      </div>
                                    </>)
                                  }
                                </>
                              ))
                              : null}
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                  {this.state.assignSuggestedTutor ?
                    <div className="main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                      <div className="border-2 border-black shadow-lg modal-container bg-white w-96 mx-auto rounded-[40px]  z-50 overflow-y-auto">
                        <div className='py-4'>
                          <div className="modal-close cursor-pointer z-50 pl-6">
                            <svg
                              onClick={() => this.assignSuggestedTutorClose()}
                              className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                        </div>
                        <div className="modal-content py-4 text-left px-6  h-[550px] overflow-y-auto">
                          {
                            suggestesTutorData && suggestesTutorData.length > 0 ?
                              suggestesTutorData.map((ele, index) => (
                                <>
                                  <div className='flex justify-between items-center py-4 border-b-2'>
                                    <div className='flex items-center space-x-2'>
                                      <div>
                                        <img
                                          class="w-12 h-12 rounded-full"
                                          src={ele && ele.imageUrl ? ele.imageUrl : null}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <p>{ele && ele.user && ele.user.userDetails && ele.user.userDetails.fullName ? ele.user.userDetails.fullName : null}</p>
                                        <p className='text-[#213360] text-xs'>{ele && ele.user && ele.user.userDetails && ele.user.userDetails.mobNo ? ele.user.userDetails.mobNo : null}</p>
                                      </div>
                                    </div>
                                    <div className='flex items-center space-x-4'>
                                      <div className='flex items-center space-x-1' onClick={() => this.handelIsOpenFnctionSuggested({ ...ele, index: index })}><span className='font-bold'>More</span>  <span className='bg-green-600 rounded-full text-white p-1'><AiOutlineDoubleRight size={10} /> </span></div>
                                      <button class="bg-green-600  py-1 text-sm shadow-sm px-2 font-bold tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-green-700" onClick={() => this.suggestedTutorSubmit(ele)}>Assign</button>
                                    </div>
                                  </div>
                                  {this.state.isOpenSuggested && this.state.suggestedTutorDataRes.index === index && (
                                    <>
                                      <div className='bg-[#E8E8E8] w-full p-4'>
                                        <div className='flex justify-between items-start text-[#213360]'>
                                          <p className='w-full'>Gender :</p>
                                          <p className='w-full text-end'>{this.state.suggestedTutorDataRes && this.state.suggestedTutorDataRes.user && this.state.suggestedTutorDataRes.user.userDetails && this.state.suggestedTutorDataRes.user.userDetails.gender ? this.state.suggestedTutorDataRes.user.userDetails.gender : "NA"}</p>
                                        </div>
                                        <div className='flex justify-between items-start text-[#213360]'>
                                          <p className='w-full'>Address :</p>
                                          <p className='w-full text-end'>{this.state.suggestedTutorDataRes && this.state.suggestedTutorDataRes.user && this.state.suggestedTutorDataRes.user.userDetails && this.state.suggestedTutorDataRes.user.userDetails.address ? this.state.suggestedTutorDataRes.user.userDetails.address : "NA"}</p>
                                        </div>
                                        <div className='flex justify-between items-start text-[#213360]'>
                                          <p className='w-full'>School :</p>
                                          <p className='w-full text-end'>{this.state.suggestedTutorDataRes && this.state.suggestedTutorDataRes.tutorInfo && this.state.suggestedTutorDataRes.tutorInfo.schoolName ? this.state.suggestedTutorDataRes.tutorInfo.schoolName : "NA"}</p>
                                        </div>
                                        <div className='flex justify-between items-start text-[#213360]'>
                                          <p className='w-full'>Graduation :</p>
                                          <p className='w-full text-end'>{this.state.suggestedTutorDataRes && this.state.suggestedTutorDataRes.tutorInfo && this.state.suggestedTutorDataRes.tutorInfo.ugCourse ? this.state.suggestedTutorDataRes.tutorInfo.ugCourse : "NA"}</p>
                                        </div>
                                        <div className='flex justify-between items-start text-[#213360]'>
                                          <p className='w-full'>Post Graduation :</p>
                                          <p className='w-full text-end'>{this.state.suggestedTutorDataRes && this.state.suggestedTutorDataRes.tutorInfo && this.state.suggestedTutorDataRes.tutorInfo.pgClgName ? this.state.suggestedTutorDataRes.tutorInfo.pgClgName : "NA"}</p>
                                        </div>
                                        <div className='flex justify-between items-start text-[#213360]'>
                                          <p className='w-full'>Experience :</p>
                                          <p className='w-full text-end'>{this.state.suggestedTutorDataRes && this.state.suggestedTutorDataRes.user && this.state.suggestedTutorDataRes.user.userDetails && this.state.suggestedTutorDataRes.user.userDetails.experience ? this.state.suggestedTutorDataRes.user.userDetails.experience : "NA"}</p>
                                        </div>

                                      </div>
                                    </>)
                                  }
                                </>
                              ))
                              : <div>Data Not Found</div>}
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                </section>
              </div>
            </div >
          </main >
        </div >




      </>

    );
  }
}
function mapStateToProps(state) {
  const { coin, users, teachcourse, city } = state;
  return {
    coin,
    users,
    teachcourse,
    city
  };
}
export default connect(mapStateToProps)(Coin);
