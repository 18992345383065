import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cityActions, userActions, statedataActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
// import DialogExample from "./components/DialogExample/DialogExample";
// import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateCityModal from "./components/CreateClassModal/CreateClassModal";
import UpdateCityModal from "./components/UpdateClassModal/UpdateClassModal";
import ViewMoreDetailsModal from './components/ViewClassModal/ViewClassModal';
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



class City extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fieldskyc: {},
      // errorskyc: {},
      stateId: null,
      fieldsCity: {
        options: [""]
      },
      errorsCity: {
        options: [""]
      },
      fieldsUpdateCity: {},
      errorsUpdateCity: {},
      viewRowData: {},
      cityCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCityModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(cityActions.getCityList(data));
    // this.props.dispatch(statedataActions.getAllStateData(data));
    // this.props.dispatch(cityActions.createSport(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.city.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsCity: {},
        errorsCity: {},
        cityCreateModal: false,
        UpdateCityModal: false,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(cityActions.getCityList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(cityActions.getCityList(data));
  }
  disableCity = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.name} city?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(cityActions.disableCity(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  onDisable = (data) => {
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      // // "roll_id": 0,
      // "size": this.state.size
    }
    // this.props.dispatch(tutionActions.disableKeyfeature(tempdata));

    confirmAlert({
      title: 'Confirm to change status?',
      message: `Are you sure to change status ${data.name}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(cityActions.disableCity(tempdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  deleteCity = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} city?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(cityActions.deleteCity(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleSelectState = (data) => {

    this.setState({ stateId: data });
  }
  handleOpenCreateModalMoreDetails = (data) => {

    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }
  handleOpenCityUpdateModal = (data) => {
    this.setState({ UpdateCityModal: true, fieldsUpdateCity: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(cityActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleCityHideModal = () => {
    this.setState({ cityCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleCityUpdateHideModal = () => {
    this.setState({ UpdateCityModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleHideCityUpdateModal = () => {
    this.setState({ UpdateCityModal: false });
  }
  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ cityCreateModal: true });
  }
  inputCityChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsCity = this.state.fieldsCity;
    let errorsCity = this.state.errorsCity;
    fieldsCity[name] = value;
    errorsCity[name] = "";
    console.log(name, value);
    this.setState({ fieldsCity, errorsCity });
  }

  inputChangeUpdateCity = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdateCity = this.state.fieldsUpdateCity;
    let errorsUpdateCity = this.state.errorsUpdateCity;
    fieldsUpdateCity[name] = value;
    errorsUpdateCity[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateCity, errorsUpdateCity });
  }



  createCitySubmit = () => {
    const { fieldsCity } = this.state;

    if (fieldsCity && fieldsCity.options) {
      // Create the request data object

      let datamap = fieldsCity && fieldsCity.options

      console.log("datamapdatamapdatamap", datamap);
      const convertedData = datamap.map((item) => ({
        name: item.name,

      }));


      const reqData = convertedData
      console.log("reqDatareqDatareqData", reqData);
      this.props.dispatch(cityActions.createCity(reqData));

    }
  };

  addContentField = () => {
    const { fieldsCity } = this.state;
    fieldsCity.options.push('')
    this.setState({ fieldsCity })
    // console.log("fieldsCity  ", fieldsCity);
  }

  deleteContentField = (index) => {
    const { fieldsCity } = this.state;
    fieldsCity.options.splice(index, 1)
    this.setState({ fieldsCity });
  }

  setCurrentIndex = (currentConetntIndex) => {
    this.setState({ currentConetntIndex: currentConetntIndex });
    // console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e, index) => {
    e.preventDefault();
    const { value } = e.target;
    const { fieldsCity } = this.state;

    // Make a shallow copy of the options array
    const updatedOptions = [...fieldsCity.options];

    // Update the 'name' property of the object at the specified index
    updatedOptions[index] = { ...updatedOptions[index], name: value };

    this.setState({
      fieldsCity: {
        ...fieldsCity,
        options: updatedOptions,
      },
    });
  };

  // navigate = (data) => {

  //   this.props.history.push('/app/series/' + data.sportId);
  // }

  updateCitySubmit = () => {

    if (this.handleValidationUpdateCity()) {
      let reqData = {
        "id": this.state.fieldsUpdateCity.id,
        "name": this.state.fieldsUpdateCity.name,

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      console.log("reqDatareqDatareqData============>", reqData);
      this.props.dispatch(cityActions.updateCity(reqData, paginationData));
    }

  }
  handleValidationUpdateCity = () => {
    let fieldsUpdateCity = this.state.fieldsUpdateCity;
    let errorsUpdateCity = {};
    let formIsValid = true;

    //name
    if (!fieldsUpdateCity["name"] || fieldsUpdateCity["name"] === "") {
      formIsValid = false;
      errorsUpdateCity["name"] = "Cannot be empty";
    }



    console.log("errorsUpdateCityerrorsUpdateCity_errorsUpdateCityerrorsUpdateCity:", errorsUpdateCity);

    this.setState({ errorsUpdateCity: errorsUpdateCity });
    return formIsValid;
  }
  handleValidationCity = () => {
    let fieldsCity = this.state.fieldsCity;
    let errorsCity = {};
    let formIsValid = true;


    if (!fieldsCity["name"] || fieldsCity["name"] === "") {
      formIsValid = false;
      errorsCity["name"] = "Cannot be empty name";
    }
    if (!fieldsCity["shortName"] || fieldsCity["shortName"] === "") {
      formIsValid = false;
      errorsCity["shortName"] = "Cannot be empty shortName";
    }
    if (!fieldsCity["lat"] || fieldsCity["lat"] === "") {
      formIsValid = false;
      errorsCity["lat"] = "Cannot be empty lat";
    }
    if (!fieldsCity["long"] || fieldsCity["long"] === "") {
      formIsValid = false;
      errorsCity["long"] = "Cannot be empty long";
    }



    this.setState({ errorsCity: errorsCity });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  render() {

    let { city, statedata } = this.props;
    let { cityTotal, loading, cityItems } = city;
    let { allStatesItems } = statedata;

    console.log("cityItemscityItemscityItemscityItemscityItemscityItems", cityItems);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400"> City List ({cityTotal})</h3>
                    </div>



                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute text-gray-600 top-4 left-5"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="w-full px-3 py-3 pl-10 font-normal placeholder-gray-600 transition duration-150 ease-in-out bg-gray-200 border rounded-full appearance-none sm:w-64 border-dark-400 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-600 text-normal sm:leading-5" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>

                    <div className="flex justify-end ...">
                      <button className="flex justify-end py-1 font-bold text-white bg-[#FBB00C] border rounded px-7 "
                        style={{ margin: "2px 0px 10px 15px" }}
                        onClick={() => this.handleOpenCreateModal()}> ADD </button>
                    </div>

                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">name</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">date</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">Status</th>

                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-black capitalize whitespace-nowrap">Actions</th>

                              </tr>
                            </thead>

                            <tbody>
                              {
                                cityItems && cityItems.length > 0 ?
                                  cityItems.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.name ? element.name : "NA"}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td>
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <span className="inline-block pl-1">
                                          <button className="bg-green-700 text-sm px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-green-400" onClick={() => this.disableCity(element)}>{element && element.isDisable ? "Disable" : "Enable"}</button>
                                        </span>
                                      </td> */}

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <label class=" cursor-pointer targetablepx-4 tooltip">
                                          <div class="relative" onClick={() => this.onDisable(element)}>
                                            <input type="checkbox" id="toggleB" class="sr-only" />
                                            {element && element.isDisable === false ? <div class="block bg-green-600 w-10 h-6 rounded-full"></div> : <div class="block bg-red-600 w-10 h-6 rounded-full"></div>}
                                            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>{element && element.isDisable === true ? "enable" : "disable"}</span>
                                          </div>
                                        </label>
                                      </td>



                                      <td className="px-2 py-3 whitespace-nowrap text-gray-600 inline-block">
                                        {/* <span className="inline-block pl-1">
                                          <button className="bg-yellow-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>More</button>
                                        </span> */}


                                        <span className="inline-block pl-1">
                                          <button className="bg-[#FBB00C] text-sm px-4 py-2 shadow-sm font-medium tracking-wider border text-black rounded-md hover:shadow-lg hover:bg-indigo-300" onClick={() => this.handleOpenCityUpdateModal(element)}>Update</button>
                                        </span>
                                        <span className="inline-block pl-1">
                                          <button className="bg-red-600 text-sm px-4 py-2 shadow-sm font-medium tracking-wider border text-white rounded-md hover:shadow-lg hover:bg-red-400" onClick={() => this.deleteCity(element)}>Delete</button>
                                        </span>

                                      </td>



                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            cityTotal && cityTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={cityTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            cityTotal && cityTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={cityTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        {/* <DialogExample /> */}

        <CreateCityModal
          cityCreateModal={this.state.cityCreateModal}
          fieldsCity={this.state.fieldsCity}
          errorsCity={this.state.errorsCity}
          inputCityChange={this.inputCityChange}
          handleSelectState={this.handleSelectState}
          createCitySubmit={this.createCitySubmit}
          handleCityHideModal={this.handleCityHideModal}
          handleFile={this.handleFile}
          allStatesItems={allStatesItems}
          addContentField={this.addContentField}
          setCurrentIndex={this.setCurrentIndex}
          deleteContentField={this.deleteContentField}
          inputAddUserChangeContent={this.inputAddUserChangeContent}
        />

        <UpdateCityModal
          UpdateCityModal={this.state.UpdateCityModal}
          fieldsUpdateCity={this.state.fieldsUpdateCity}
          errorsUpdateCity={this.state.errorsUpdateCity}
          inputChangeUpdateCity={this.inputChangeUpdateCity}
          updateCitySubmit={this.updateCitySubmit}
          handleCityUpdateHideModal={this.handleCityUpdateHideModal}
          handleFile={this.handleFile}
        // allStatesItems={allStatesItems}

        />

        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={this.state.viewRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}

        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { city, users, statedata } = state;
  return {
    city,
    users,
    statedata
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(City);
