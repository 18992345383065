export const dashboardConstants = {

    UPDATE_OWN_PASSWORD_ADMIN_REQUEST: 'UPDATE_OWN_PASSWORD_ADMIN_REQUEST',
    UPDATE_OWN_PASSWORD_ADMIN_SUCCESS: 'UPDATE_OWN_PASSWORD_ADMIN_SUCCESS',
    UPDATE_OWN_PASSWORD_ADMIN_FAILURE: 'UPDATE_OWN_PASSWORD_ADMIN_FAILURE',

    GET_ADMIN_DETAILS_REQUEST: 'GET_ADMIN_DETAILS_REQUEST',
    GET_ADMIN_DETAILS_SUCCESS: 'GET_ADMIN_DETAILS_SUCCESS',
    GET_ADMIN_DETAILS_FAILURE: 'GET_ADMIN_DETAILS_FAILURE',

    ADD_ADMIN_OWN_BALANCE_REQUEST: 'ADD_ADMIN_OWN_BALANCE_REQUEST',
    ADD_ADMIN_OWN_BALANCE_SUCCESS: 'ADD_ADMIN_OWN_BALANCE_SUCCESS',
    ADD_ADMIN_OWN_BALANCE_FAILURE: 'ADD_ADMIN_OWN_BALANCE_FAILURE',

    GET_DASHBOARD_DATA_REQUEST: 'GET_DASHBOARD_DATA_REQUEST',
    GET_DASHBOARD_DATA_SUCCESS: 'GET_DASHBOARD_DATA_SUCCESS',
    GET_DASHBOARD_DATA_FAILURE: 'GET_DASHBOARD_DATA_FAILURE',

    TOTAL_TUTION_EARNING_REQUEST: 'TOTAL_TUTION_EARNING_REQUEST',
    TOTAL_TUTION_EARNING_SUCCESS: 'TOTAL_TUTION_EARNING_SUCCESS',
    TOTAL_TUTION_EARNING_FAILURE: 'TOTAL_TUTION_EARNING_FAILURE',

    GET_TERMS_AND_CONDIYION_REQUEST: 'GET_TERMS_AND_CONDIYION_REQUEST',
    GET_TERMS_AND_CONDIYION_SUCCESS: 'GET_TERMS_AND_CONDIYION_SUCCESS',
    GET_TERMS_AND_CONDIYION_FAILURE: 'GET_TERMS_AND_CONDIYION_FAILURE',

    TODAY_DEMO_PENDING_REQUEST :"TODAY_DEMO_PENDING_REQUEST",
    TODAY_DEMO_PENDING_SUCCESS: 'TODAY_DEMO_PENDING_SUCCESS',
    TODAY_DEMO_PENDING_FAILURE: 'TODAY_DEMO_PENDING_FAILURE',

    TODAY_DEMO_COMPLETED_REQUEST :"TODAY_DEMO_COMPLETED_REQUEST",
    TODAY_DEMO_COMPLETED_SUCCESS: 'TODAY_DEMO_COMPLETED_SUCCESS',
    TODAY_DEMO_COMPLETED_FAILURE: 'TODAY_DEMO_COMPLETED_FAILURE',

    TODAY_DUE_DATE_REQUEST :"TODAY_DUE_DATE_REQUEST",
    TODAY_DUE_DATE_SUCCESS: 'TODAY_DUE_DATE_SUCCESS',
    TODAY_DUE_DATE_FAILURE: 'TODAY_DUE_DATE_FAILURE',

    TOTAL_TUTION_EARNING_MONTH_WASI_REQUEST :" TOTAL_TUTION_EARNING_MONTH_WASI_REQUEST",
    TOTAL_TUTION_EARNING_MONTH_WASI_SUCCESS: ' TOTAL_TUTION_EARNING_MONTH_WASI_SUCCESS',
    TOTAL_TUTION_EARNING_MONTH_WASI_FAILURE: ' TOTAL_TUTION_EARNING_MONTH_WASI_FAILURE',

    SEND_DEMO_REQUEST_REQUEST :"SEND_DEMO_REQUEST_REQUEST",
    SEND_DEMO_REQUEST_SUCCESS: 'SEND_DEMO_REQUEST_SUCCESS',
    SEND_DEMO_REQUEST_FAILURE: 'SEND_DEMO_REQUEST_FAILURE',

    GET_STUDENT_FEE_HISTORY_REQUEST :"GET_STUDENT_FEE_HISTORY_REQUEST",
    GET_STUDENT_FEE_HISTORY_SUCCESS: 'GET_STUDENT_FEE_HISTORY_SUCCESS',
    GET_STUDENT_FEE_HISTORY_FAILURE: 'GET_STUDENT_FEE_HISTORY_FAILURE',

    GET_TUTOR_FEE_HISTORY_REQUEST :"GET_TUTOR_FEE_HISTORY_REQUEST",
    GET_TUTOR_FEE_HISTORY_SUCCESS: 'GET_TUTOR_FEE_HISTORY_SUCCESS',
    GET_TUTOR_FEE_HISTORY_FAILURE: 'GET_TUTOR_FEE_HISTORY_FAILURE',




};
