import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
// import { branch } from './branch.reducer';
import { dashboard } from './dashboard.reducer';
import { keyfeatures } from './keyfeature.reducer';
import { kyc } from './kyc.reducer';
import { ticket } from './ticket.reducer';
import { transaction } from './transaction.reducer';
import { support } from './support.reducer';
import { loginhistory } from './loginhistory.reducer';
import { appsetting } from './appsetting.reducer';
import { packages } from './package.reducer';
import { miningPftShare } from './miningPftShare.reducer';
import { swap } from './swap.reducer';
import { feesetting } from './feesetting.reducer';
import { coinIndex } from './coinIndex.reducer';
import { index } from './index.reducer';
import { coin } from './coin.reducer';
import { document } from './document.reducer';
import { statedata } from './statedata.reducer';
import { city } from './city.reducer';
import { welcomeBonus } from './welcomeBonus.reducer';
import { cmcCmcCoin } from './CmcCoin.reducer';
import { ltArchiveReward } from './ltArchiveReward.reducer';
import { fastrackBonus } from './fastrackBonus.reducer';
import { teachcourse } from './teachcourse.reducer';
import { tution } from './tution.reducer';
import { holiday } from './holiday.reducer';
import { banner } from './banner.reducer';



const rootReducer = combineReducers({
  // branch,
  packages,
  appsetting,
  authentication,
  users,
  alert,
  dashboard,
  keyfeatures,
  ticket,
  transaction,
  kyc,
  support,
  loginhistory,
  swap,
  feesetting,
  coinIndex,
  index,
  coin,
  document,
  statedata,
  city,
  miningPftShare,
  welcomeBonus,
  cmcCmcCoin,
  ltArchiveReward,
  fastrackBonus,
  teachcourse,
  tution,
  holiday,
  banner
});

export default rootReducer;
