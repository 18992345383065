export const statedataConstants = {

    GETALL_STATE_DATA_REQUEST: 'GETALL_STATE_DATA_REQUEST',
    GETALL_STATE_DATA_SUCCESS: 'GETALL_STATE_DATA_SUCCESS',
    GETALL_STATE_DATA_FAILURE: 'GETALL_STATE_DATA_FAILURE',

    GET_LIST_STATE_DATA_REQUEST: 'GET_LIST_STATE_DATA_REQUEST',
    GET_LIST_STATE_DATA_SUCCESS: 'GET_LIST_STATE_DATA_SUCCESS',
    GET_LIST_STATE_DATA_FAILURE: 'GET_LIST_STATE_DATA_FAILURE',

    ADD_STATE_DATA_REQUEST: 'ADD_STATE_DATA_REQUEST',
    ADD_STATE_DATA_SUCCESS: 'ADD_STATE_DATA_SUCCESS',
    ADD_STATE_DATA_FAILURE: 'ADD_STATE_DATA_FAILURE',

    DELETE_STATE_DATA_REQUEST: 'DELETE_STATE_DATA_REQUEST',
    DELETE_STATE_DATA_SUCCESS: 'DELETE_STATE_DATA_SUCCESS',
    DELETE_STATE_DATA_FAILURE: 'DELETE_STATE_DATA_FAILURE',

    UPDATE_STATE_DATA_REQUEST: 'UPDATE_STATE_DATA_REQUEST',
    UPDATE_STATE_DATA_SUCCESS: 'UPDATE_STATE_DATA_SUCCESS',
    UPDATE_STATE_DATA_FAILURE: 'UPDATE_STATE_DATA_FAILURE',

    DISABLE_STATE_DATA_REQUEST: 'DISABLE_STATE_DATA_REQUEST',
    DISABLE_STATE_DATA_SUCCESS: 'DISABLE_STATE_DATA_SUCCESS',
    DISABLE_STATE_DATA_FAILURE: 'DISABLE_STATE_DATA_FAILURE',

};
