
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';

export const tutionService = {
    login,
    logout,
    getAllTution,
    createTution,
    tuitionApprove,
    updateTution,
    getTutionList,
    deleteTution,
    disableTution,
    createPinCode,
    updatePinCode,
    getPinCodeList,
    getSuggestedTutorByTuition,
    getSuggestedTutorByTuition1,
    deletePinCode,
    disablePinCode,
    getAllPinCode,
    closeClassStatusUpdate,
    createtutorFee,
    updatetutorFee,
    gettutorFeeList,
    getAttendanceListByTutor,
    deletetutorFee,
    disabletutorFee,
    getCloseClassesListAdmin,
    getAlltutorFee,
    getAllTutorId,
    getAllStudentId,
    getAllteacherCouresId,
    manualTutorChange,
    addAmountInTuition,
    updateTuitionStatusOnGoinOrReject,
    sendDemoRequest,
    getAllPinCode1,
    refuseRequest,
    getTuitionById

};
function logout() {
    window.sessionStorage.removeItem('adminuser');
    // window.location.href = "#/login";
    history.push(`#/login`);
    window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                window.sessionStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getTuitionById(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTuitionById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTuitionById: data.data
            }
            console.log("i am in service getTuitionById", userObj);

            return userObj;
        });
}

function getTutionList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTuitionList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTutionList: data.data
            }
            console.log("i am in service getTutionList", userObj);

            return userObj;
        });
}
function getAllTution() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllTution`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllTution: data.data
            }
            console.log("i am in service getAllTution", userObj);

            return userObj;
        });
}

function getPinCodeList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPinCodeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getPinCodeList: data.data
            }
            console.log("i am in service getPinCodeList", userObj);

            return userObj;
        });
}
function getSuggestedTutorByTuition1(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getSuggestedTutorId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getSuggestedTutorByTuition1: data.data
            }
            console.log("i am in service getSuggestedTutorByTuition1", userObj);

            return userObj;
        });
}
function getSuggestedTutorByTuition(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getSuggestedTutorByTuition`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getSuggestedTutorByTuition: data.data
            }
            console.log("i am in service getSuggestedTutorByTuition", userObj);

            return userObj;
        });
}
function getAllPinCode1(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllPinCode`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllPinCode: data.data
            }
            console.log("i am in service getAllPinCode", userObj);

            return userObj;
        });
}
function refuseRequest(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/refuseRequest`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                refuseRequest: data.data
            }
            console.log("i am in service refuseRequest", userObj);

            return userObj;
        });
}
function getAllPinCode() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllPinCode`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllPinCode: data.data
            }
            console.log("i am in service getAllPinCode", userObj);

            return userObj;
        });
}

function sendDemoRequest(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/sendDemoRequest`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                sendDemoRequest: data.data
            }

            return userObj;
        });
}

function deleteTution(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteTuition`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteTution: data.data
            }
            console.log("i am in service''...>> deleteTution ::", userObj);

            return userObj;
        });
}
function closeClassStatusUpdate(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/closeClassStatusUpdate`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                closeClassStatusUpdate: data.data
            }
            console.log("i am in service''...>> closeClassStatusUpdate ::", userObj);

            return userObj;
        });
}
function disableTution(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTuitionStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateTutionStatus: data.data
            }
            console.log("i am in service''...>> updateTutionStatus ::", userObj);

            return userObj;
        });
}

function updateTution(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTuition`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateTution: data.data
            }
            console.log("I am in service updateTution", userObj);

            return userObj;
        });
}

function createTution(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createTuition`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createTution: data.data
            }
            console.log("I am in service createTution", userObj);

            return userObj;
        });
}

function tuitionApprove(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/tuitionApprove`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                tuitionApprove: data.data
            }
            console.log("I am in service tuitionApprove", userObj);

            return userObj;
        });
}

function deletePinCode(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deletePinCode`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deletePinCode: data.data
            }
            console.log("i am in service''...>> deletePinCode ::", userObj);

            return userObj;
        });
}
function disablePinCode(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePinCodeStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updatePinCodeStatus: data.data
            }
            console.log("i am in service''...>> updatePinCodeStatus ::", userObj);

            return userObj;
        });
}

function updatePinCode(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePinCode`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updatePinCode: data.data
            }
            console.log("I am in service updatePinCode", userObj);

            return userObj;
        });
}

function createPinCode(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createPinCode`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createPinCode: data.data
            }
            console.log("I am in service createPinCode", userObj);

            return userObj;
        });
}

function deletetutorFee(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteTutorFeeHistory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deletetutorFee: data.data
            }
            console.log("i am in service''...>> deletetutorFee ::", userObj);

            return userObj;
        });
}
function disabletutorFee(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTutorFeeHistoryStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updatetutorFeeStatus: data.data
            }
            console.log("i am in service''...>> updatetutorFeeStatus ::", userObj);

            return userObj;
        });
}

function updatetutorFee(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTutorFeeHistory`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updatetutorFee: data.data
            }
            console.log("I am in service updatetutorFee", userObj);

            return userObj;
        });
}

function createtutorFee(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createTutorFeeHistory`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createtutorFee: data.data
            }
            console.log("I am in service createtutorFee", userObj);

            return userObj;
        });
}


function getAttendanceListByTutor(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAttendanceListByTutor`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAttendanceListByTutor: data.data
            }
            console.log("i am in service getAttendanceListByTutor", userObj);

            return userObj;
        });
}


function gettutorFeeList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTutorFeeHistoryList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                gettutorFeeList: data.data
            }
            console.log("i am in service gettutorFeeList", userObj);

            return userObj;
        });
}


function getCloseClassesListAdmin(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCloseClassesListAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getCloseClassesListAdmin: data.data
            }
            console.log("i am in service getCloseClassesListAdmin", userObj);

            return userObj;
        });
}
function getAlltutorFee() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllTutorFeeHistory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAlltutorFee: data.data
            }
            console.log("i am in service getAlltutorFee", userObj);

            return userObj;
        });
}

function getAllTutorId() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllTutorId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllTutorId: data.data
            }
            console.log("i am in service getAllTutorId", userObj);

            return userObj;
        });
} function getAllStudentId() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllStudentId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllStudentId: data.data
            }
            console.log("i am in service getAllStudentId", userObj);

            return userObj;
        });
}
function getAllteacherCouresId() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllteacherCouresId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllteacherCouresId: data.data
            }
            console.log("i am in service getAllteacherCouresId", userObj);

            return userObj;
        });
}
function manualTutorChange(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/manualTutorChange`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                manualTutorChange: data.data
            }
            console.log("i am in service manualTutorChange", userObj);

            return userObj;
        });
}
function updateTuitionStatusOnGoinOrReject(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTuitionStatusOnGoinOrReject`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateTuitionStatusOnGoinOrReject: data.data
            }
            console.log("i am in service updateTuitionStatusOnGoinOrReject", userObj);

            return userObj;
        });
}
function addAmountInTuition(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/addAmountInTuition`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                addAmountInTuition: data.data
            }
            console.log("i am in service addAmountInTuition", userObj);

            return userObj;
        });
}

function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}