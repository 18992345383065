import React from "react";

import Modal from 'react-modal';
import moment from 'moment'


export default function CreateCashFlowTransactionModal(props) {

  let { showCashFlow, handleshowCashFlowHideModal, showCashFlowData, offset } = props;

  return (

    <Modal
      isOpen={showCashFlow}
      // overlayClassName="Overlay"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 shadow-lg  bg-white w-96 mx-auto rounded shadow-lg z-50 overflow-y-auto" style={{width:"50%"}}>
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Cash Flow</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleshowCashFlowHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            {/* <div className="my-5">

              <form autoComplete="off">
                

             {JSON.stringify(showCashFlowData)}

               

                
              </form>

            </div> */}
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-800 border-0">
                <thead className="bg-lime-300 rounded-t">
                  <tr className="">
                    <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">#</th>
                    <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Date</th>
                    <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Amount</th>
                    <th scope="col" className="whitespace-nowrap px-6 py-3 text-left text-sm font-semibold text-gray-700 capitalize tracking-wider">Remark</th>



                  </tr>
                </thead>
                {/* Table Row Section */}
                <tbody>
                  {
                    showCashFlowData && showCashFlowData.length > 0 ?
                      showCashFlowData.map((element, index) => (<React.Fragment key={element.id}>
                        <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                          <td className="px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">
                            {offset + index + 1}</td>
                          <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                            {moment(new Date(parseInt(element.date))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                            {element.amount}</td>
                          <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600">
                            {element.remark}</td>


                        </tr>
                      </React.Fragment>))
                      : (<tr className="bg-white bg-opacity-5 " >
                        <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                      </tr>)
                  }
                </tbody>
              </table>
            </div>

            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
