import { appsettingConstants } from '../_constants';
import { appsettingService } from '../_services';
import { alertActions, dashboardActions } from '.';
// import { history } from '../_helpers';
export const appsettingActions = {
    logout,
    updateStatusAppSetting,
    addAppSetting,
    uploadImage,
    disableAppSetting,
    deleteAppSetting,
    updateAppSetting,
    getAllAppSetting,
    getAppSettingList,
    createUpdateTermsAndCondition,
    delelteTermsAndCondition
};

function logout() {
    appsettingService.logout();
    return { type: appsettingConstants.LOGOUT };
}
function getAppSettingList(data) {
    console.log('data', data);
    return dispatch => {
        dispatch(request());
        appsettingService.getAppSettingList(data)
            .then(
                keyfeatures => dispatch(success(keyfeatures)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: appsettingConstants.GETALL_REQUEST } }
    function success(keyfeatures) { return { type: appsettingConstants.GETALL_SUCCESS, keyfeatures: keyfeatures } }
    function failure(error) { return { type: appsettingConstants.GETALL_FAILURE, error } }
}
function updateStatusAppSetting(data) {
    let reqData = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        // "roll_id": 0,
        "size": data.size,
    }
    let mainData = {
        comment: data.keyWord,
        status: data.status,
        id: data.id,

    }
    return dispatch => {
        dispatch(request());
        appsettingService.updateStatusAppSetting(mainData)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    // dispatch(alertActions.success("Password Updated successfully."));
                    dispatch(success(users));
                    dispatch(appsettingActions.getAppSettingList(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: appsettingConstants.UPDATE_APPSETTING_PASSWORD_REQUEST } }
    function success(users) { return { type: appsettingConstants.UPDATE_APPSETTING_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: appsettingConstants.UPDATE_APPSETTING_PASSWORD_FAILURE, error } }
}
function createUpdateTermsAndCondition(data) {

    return dispatch => {
        dispatch(request());
        appsettingService.createUpdateTermsAndCondition(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    // dispatch(alertActions.success("Password Updated successfully."));
                    dispatch(success(users));
                    dispatch(dashboardActions.getTermsAndConditionList())
                    // dispatch(appsettingActions.createUpdateTermsAndCondition(data));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: appsettingConstants.TERM_CONDITION_APPSETTING_PASSWORD_REQUEST } }
    function success(users) { return { type: appsettingConstants.TERM_CONDITION_APPSETTING_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: appsettingConstants.TERM_CONDITION_APPSETTING_PASSWORD_FAILURE, error } }
}
function delelteTermsAndCondition(data) {

    let mainData = {
        comment: data.keyWord,
        status: data.status,
        id: data.id,

    }
    return dispatch => {
        dispatch(request());
        appsettingService.delelteTermsAndCondition(data)
            .then(
                users => {
                    // toast("Password Updated successfully.")
                    // dispatch(alertActions.success("Password Updated successfully."));
                    dispatch(success(users));
                    dispatch(dashboardActions.getTermsAndConditionList())
                    // dispatch(appsettingActions.delelteTermsAndCondition(data));
                    dispatch(alertActions.success("Delete Successfully"))

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: appsettingConstants.DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_REQUEST } }
    function success(users) { return { type: appsettingConstants.DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: appsettingConstants.DELETE_TERMANDCONDITION_APPSETTING_PASSWORD_FAILURE, error } }
}

function getAllAppSetting(data) {
    return dispatch => {
        dispatch(request());
        appsettingService.getAllAppSetting(data)
            .then(
                keyfeatures => {
                    //console.log("keyfeatures  ",keyfeatures);
                    let returnResppnse =
                        keyfeatures.getAllAppSetting ? keyfeatures.getAllAppSetting.map(appsetting => ({ value: appsetting.id, label: appsetting.firstName + " " + appsetting.lastName })) : [];
                    let newRespnse = { ...keyfeatures, getAllAppSetting: returnResppnse }
                    dispatch(success(newRespnse))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: appsettingConstants.GET_ALL_APPSETTING_REQUEST } }
    function success(keyfeatures) { return { type: appsettingConstants.GET_ALL_APPSETTING_SUCCESS, keyfeatures } }
    function failure(error) { return { type: appsettingConstants.GET_ALL_APPSETTING_FAILURE, error } }
}
function addAppSetting(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        appsettingService.addAppSetting(data)
            .then(
                keyfeatures => {
                    dispatch(success(keyfeatures));
                    dispatch(this.getAppSettingList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: appsettingConstants.ADD_APPSETTING_REQUEST } }
    function success(keyfeatures) { return { type: appsettingConstants.ADD_APPSETTING_SUCCESS, keyfeatures } }
    function failure(error) { return { type: appsettingConstants.ADD_APPSETTING_FAILURE, error } }
}
function updateAppSetting(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        appsettingService.updateAppSetting(data)
            .then(
                keyfeatures => {
                    dispatch(success(keyfeatures));
                    dispatch(this.getAppSettingList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: appsettingConstants.ADD_APPSETTING_REQUEST } }
    function success(keyfeatures) { return { type: appsettingConstants.ADD_APPSETTING_SUCCESS, keyfeatures } }
    function failure(error) { return { type: appsettingConstants.ADD_APPSETTING_FAILURE, error } }
}

function disableAppSetting(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id: data.id
    }
    return dispatch => {
        dispatch(request());
        appsettingService.disableAppSetting(maintempdata)
            .then(
                keyfeatures => {
                    dispatch(success(keyfeatures));
                    dispatch(this.getAppSettingList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: appsettingConstants.DISABLE_APP_SETTING_REQUEST } }
    function success(keyfeatures) { return { type: appsettingConstants.DISABLE_APP_SETTING_SUCCESS, keyfeatures } }
    function failure(error) { return { type: appsettingConstants.DISABLE_APP_SETTING_FAILURE, error } }
}
function deleteAppSetting(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id: data.id
    }
    return dispatch => {
        dispatch(request());
        appsettingService.deleteAppSetting(maintempdata)
            .then(
                keyfeatures => {
                    dispatch(success(keyfeatures));
                    dispatch(this.getAppSettingList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: appsettingConstants.DELETE__APP_SETTING_REQUEST } }
    function success(keyfeatures) { return { type: appsettingConstants.DELETE__APP_SETTING_SUCCESS, keyfeatures } }
    function failure(error) { return { type: appsettingConstants.DELETE__APP_SETTING_FAILURE, error } }
}
function uploadImage(data) {
    return dispatch => {
        appsettingService.uploadImage(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: appsettingConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: appsettingConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}
