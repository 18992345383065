import {
  coinConstants
} from '../_constants';

export function coin(state = {}, action) {

  switch (action.type) {
    // case coinConstants.DEPOSIT_USER_BALANCE_REQUEST:
    //   return {
    //     ...state
    //   };
    // case coinConstants.DEPOSIT_USER_BALANCE_SUCCESS:
    //   return {
    //     ...state,
    //     addUserSuccess: true
    //   };
    // case coinConstants.DEPOSIT_USER_BALANCE_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };
    case coinConstants.ADD_COIN_REQUEST:
      return {
        ...state
      };
    case coinConstants.ADD_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case coinConstants.ADD_COIN_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case coinConstants.GET_LIST_COIN_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case coinConstants.GET_LIST_COIN_SUCCESS:
      return {
        ...state,

        loading: false,
        items: action.users.getCoinList.list,
        total: action.users.getCoinList.total
      };
    case coinConstants.GET_LIST_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case coinConstants.GETALL_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case coinConstants.GETALL_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getAllCoin,
        total: action.users.getAllCoin
      };
    case coinConstants.GETALL_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case coinConstants.UPDATE_COIN_REQUEST:
      return {
        ...state
      };
    case coinConstants.UPDATE_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case coinConstants.UPDATE_COIN_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case coinConstants.ASSIGN_TUTOR_REQUEST:
      return {
        ...state
      };
    case coinConstants.ASSIGN_TUTOR_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case coinConstants.ASSIGN_TUTOR_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case coinConstants.DELETE_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case coinConstants.DELETE_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case coinConstants.DELETE_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case coinConstants.DISABLE_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case coinConstants.DISABLE_COIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case coinConstants.DISABLE_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}