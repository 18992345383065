
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';

export const loginhistoryService = {
    getLoginHistoryList
};
function logout() {
    window.sessionStorage.removeItem('loginhistory');
    history.push(`#/login`);
    window.location.reload();
}

function getLoginHistoryList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getLoginHistoryList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let loginhistoryObj = {
                getLoginHistoryList: data.data
            }
            console.log(" i am in service lllll getLoginHistoryList", loginhistoryObj);

            return loginhistoryObj;
        });
}
function handleResponse(response) {
    //    console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}