import {
  keyfeatureConstants
} from '../_constants';

export function keyfeatures(state = {}, action) {

  switch (action.type) {


    case keyfeatureConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case keyfeatureConstants.GETALL_SUCCESS:
      return {
        ...state,
        addKeyfeatureSuccess: false,
        items: action.keyfeatures.getKeyfeatureList.list,
        total: action.keyfeatures.getKeyfeatureList.total
      };
    case keyfeatureConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case keyfeatureConstants.GET_ALL_KEYFEATURE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case keyfeatureConstants.GET_ALL_KEYFEATURE_SUCCESS:
      return {
        ...state,
        getAllKeyfeature: action.keyfeatures.getAllKeyfeature
      };
    case keyfeatureConstants.GET_ALL_KEYFEATURE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case keyfeatureConstants.GETALL_KEYFEATURE_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case keyfeatureConstants.GETALL_KEYFEATURE_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.keyfeatures.listOfNotification.list,
        listOfNotificationtotal: action.keyfeatures.listOfNotification.total
      };
    case keyfeatureConstants.GETALL_KEYFEATURE_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case keyfeatureConstants.UPDATE_KEYFEATURE_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case keyfeatureConstants.UPDATE_KEYFEATURE_NOTIFY_SUCCESS:
      return {
        ...state,
      };
    case keyfeatureConstants.UPDATE_KEYFEATURE_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case keyfeatureConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case keyfeatureConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.keyfeatures.restaurantDetails
      };
    case keyfeatureConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case keyfeatureConstants.UPDATE_KEYFEATURE_PASSWORD_REQUEST:
      return {
        ...state
      };
    case keyfeatureConstants.UPDATE_KEYFEATURE_PASSWORD_SUCCESS:
      return {
        ...state,
        addKeyfeatureSuccess: true
      };
    case keyfeatureConstants.UPDATE_KEYFEATURE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case keyfeatureConstants.ADD_RESTAURANT_KEYFEATURE_REQUEST:
      return {
        ...state
      };
    case keyfeatureConstants.ADD_RESTAURANT_KEYFEATURE_SUCCESS:
      return {
        ...state,
        addKeyfeatureSuccess: true
      };
    case keyfeatureConstants.ADD_RESTAURANT_KEYFEATURE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case keyfeatureConstants.ADD_MENU_REQUEST:
      return {
        ...state
      };
    case keyfeatureConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true
      };
    case keyfeatureConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case keyfeatureConstants.ADD_ITEM_REQUEST:
      return {
        ...state
      };
    case keyfeatureConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true
      };
    case keyfeatureConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case keyfeatureConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case keyfeatureConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true
      };
    case keyfeatureConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case keyfeatureConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state
      };
    case keyfeatureConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case keyfeatureConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case keyfeatureConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state, filesDetails: action.uploadImage.filesDetails,
      };

    case keyfeatureConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };


    // case keyfeatureConstants.STATS_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case keyfeatureConstants.STATS_SUCCESS:
    //   return {
    //     ...state,
    //     statics: action.keyfeatures.statics
    //   };
    // case keyfeatureConstants.STATS_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    default:
      return state
  }
}