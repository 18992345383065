export const coinIndexConstants = {

    GETALL_COIN_INDEX_REQUEST: 'GETALL_COIN_INDEX_REQUEST',
    GETALL_COIN_INDEX_SUCCESS: 'GETALL_COIN_INDEX_SUCCESS',
    GETALL_COIN_INDEX_FAILURE: 'GETALL_COIN_INDEX_FAILURE',

    GET_LIST_COIN_INDEX_REQUEST: 'GET_LIST_COIN_INDEX_REQUEST',
    GET_LIST_COIN_INDEX_SUCCESS: 'GET_LIST_COIN_INDEX_SUCCESS',
    GET_LIST_COIN_INDEX_FAILURE: 'GET_LIST_COIN_INDEX_FAILURE',

    ADD_COIN_INDEX_REQUEST: 'ADD_COIN_INDEX_REQUEST',
    ADD_COIN_INDEX_SUCCESS: 'ADD_COIN_INDEX_SUCCESS',
    ADD_COIN_INDEX_FAILURE: 'ADD_COIN_INDEX_FAILURE',

    DELETE_COIN_INDEX_REQUEST: 'DELETE_COIN_INDEX_REQUEST',
    DELETE_COIN_INDEX_SUCCESS: 'DELETE_COIN_INDEX_SUCCESS',
    DELETE_COIN_INDEX_FAILURE: 'DELETE_COIN_INDEX_FAILURE',

    UPDATE_COIN_INDEX_REQUEST: 'UPDATE_COIN_INDEX_REQUEST',
    UPDATE_COIN_INDEX_SUCCESS: 'UPDATE_COIN_INDEX_SUCCESS',
    UPDATE_COIN_INDEX_FAILURE: 'UPDATE_COIN_INDEX_FAILURE',

    DISABLE_COIN_INDEX_REQUEST: 'DISABLE_COIN_INDEX_REQUEST',
    DISABLE_COIN_INDEX_SUCCESS: 'DISABLE_COIN_INDEX_SUCCESS',
    DISABLE_COIN_INDEX_FAILURE: 'DISABLE_COIN_INDEX_FAILURE',

};
