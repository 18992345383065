import {
  cityConstants,
} from '../_constants';

export function city(state = {}, action) {
  console.log('action_______________________', action);

  switch (action.type) {
    case cityConstants.ADD_CITY_REQUEST:
      return {
        ...state
      };
    case cityConstants.ADD_CITY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case cityConstants.ADD_CITY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case cityConstants.GET_ALLENDANCE_MONTH_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.GET_ALLENDANCE_MONTH_DATA_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        attendanceItems: action.users.getAttendanceMonthData,
        // total: action.users.getAttendanceMonthData
      };
    case cityConstants.GET_ALLENDANCE_MONTH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case cityConstants.GET_LIST_CITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.GET_LIST_CITY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        cityItems: action.users.getCityList.list,
        cityTotal: action.users.getCityList.total
      };
    case cityConstants.GET_LIST_CITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cityConstants.GETALL_CITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.GETALL_CITY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllCity: action.users.getAllCity,
        total: action.users.getAllCity
      };
    case cityConstants.GETALL_CITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case cityConstants.UPDATE_CITY_REQUEST:
      return {
        ...state
      };
    case cityConstants.UPDATE_CITY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case cityConstants.UPDATE_CITY_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case cityConstants.DELETE_CITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.DELETE_CITY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case cityConstants.DELETE_CITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cityConstants.DISABLE_CITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.DISABLE_CITY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case cityConstants.DISABLE_CITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case cityConstants.ADD_CLASS_REQUEST:
      return {
        ...state
      };
    case cityConstants.ADD_CLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case cityConstants.ADD_CLASS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case cityConstants.GET_LIST_CLASS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.GET_LIST_CLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        classitems: action.users.getClassList.list,
        total: action.users.getClassList.total
      };
    case cityConstants.GET_LIST_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cityConstants.GETALL_CLASS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.GETALL_CLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllClass: action.users.getAllClass,
        // classTotal: action.users.getAllClass
      };
    case cityConstants.GETALL_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case cityConstants.UPDATE_CLASS_REQUEST:
      return {
        ...state
      };
    case cityConstants.UPDATE_CLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case cityConstants.UPDATE_CLASS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case cityConstants.DELETE_CLASS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.DELETE_CLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case cityConstants.DELETE_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cityConstants.DISABLE_CLASS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.DISABLE_CLASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case cityConstants.DISABLE_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case cityConstants.ADD_BOARD_REQUEST:
      return {
        ...state,
        addUserSuccess: true
      };
    case cityConstants.ADD_BOARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case cityConstants.ADD_BOARD_FAILURE:
      return {
        ...state,
        // addUserSuccess: true,
        error: action.error
      };

    case cityConstants.GET_LIST_BOARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.GET_LIST_BOARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        boardItems: action.users.getBoardList.list,
        total: action.users.getBoardList.total
      };
    case cityConstants.GET_LIST_BOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cityConstants.GETALL_BOARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.GETALL_BOARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllCity: action.users.getAllBoard,
        total: action.users.getAllBoard
      };
    case cityConstants.GETALL_BOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case cityConstants.UPDATE_BOARD_REQUEST:
      return {
        ...state
      };
    case cityConstants.UPDATE_BOARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case cityConstants.UPDATE_BOARD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case cityConstants.DELETE_BOARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.DELETE_BOARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case cityConstants.DELETE_BOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cityConstants.DISABLE_BOARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.DISABLE_BOARD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case cityConstants.DISABLE_BOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}