import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions, kycActions, teachcourseActions } from './';
import { CONST } from '../_config';

import { toast } from 'react-toastify';
export const userActions = {
    getProfile,
    login,
    userLogin,
    logout,
    updateUserPasswordSubmit,
    getUserKycDetailsById,
    updateAssignedClassesStatus,
    updateAssignmentAdminStatus,
    registerUserAdmin,
    markPaidOrUnPaid,
    createAssignedClasses,
    updateAssignedClasses,
    getUserActive,
    getUserListRejectedInterview,
    getTuitionListByTutor,
    getAttendanceListByTutor,
    getAssignedClassesList,
    getAssignmentAdminList,
    getUserDeactive,
    disableUser,
    changeStatusUserRef,
    updateInterviewStatus,
    registerUserByAdmin,
    updateAdminStatus,
    deleteUser,
    deleteAssignedClasses,
    deleteAssignedClasses,
    verifyEmail,
    loginToThisAccount,
    uploadImage,
    getWalletList,
    addStudent,
    updateStudentDetails,
    depositUserBalance,
    withdrawUserBalance,
    getTuituinListTutorWasi,
    updateStatusPayment,
    addUserBalance,
    getLoginhistory,
    getUserPromoPackageByAdmin,
    getUserPackages,
    getTx,
    getUserInfoByAdmin,
    getSocialMediaById,
    getUserProfile,
    getUserPackagesTeam,
    getTicketList,
    changePassword,
    approvePromoPack,
    updateUserName,
    updateEmail,
    deleteAssignmentAdmin,
    updatePayoutAmount,
    updateAssignmentAdmin,
    getUserPromoPackageList,
    getUserPackageList,
    updateTeamMiningAmount,
    getUserTeamByUserId,
    getUserLevel,
    createAssignmentAdmin,
    removeDftcWalletAddress,
    manageUserPortfolio,
    changeLevelStatus,
    payFastrackOrLifeTimeBonus,
    createSubAdmin,
    getAdminProfile,
    getAdminList,
    getUserRefList,
    updateSubAdminPassword,
    addAmountReferralUser,
    getAttendanceListTuitionWasi,
    getUserActiveStudent,
    getUserPaymentDetails,
    getTuitionListByDate,
    getFeeHistoryListAdmin,
    updateUnpaidStatus,
    updateInterviewStatusUser,
    getClassIdByTuitionStudent
};
function login(data, props) {
    console.log("loginlogin_______props::", props);
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    // props.history.push({ pathname: 'app/dashboard' });
                    props.history.push('/app/dashboard');
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function createSubAdmin(data, dataRes, props) {
    console.log("createSubAdmincreateSubAdmin_______props::", props);
    return dispatch => {
        dispatch(request({ data }));
        userService.createSubAdmin(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(this.getAdminList(dataRes));

                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.CREATE_SUBADMIN_REQUEST, user } }
    function success(user) { return { type: userConstants.CREATE_SUBADMIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CREATE_SUBADMIN_FAILURE, error } }
}
function getAdminProfile() {
    // console.log("getAdminProfilegetAdminProfile_______props::", props);
    return dispatch => {
        dispatch(request());
        userService.getAdminProfile()
            .then(
                user => {
                    console.log('user_______________________user', user);
                    dispatch(success(user));
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.GET_ADMIN_PROFILE_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_ADMIN_PROFILE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_ADMIN_PROFILE_FAILURE, error } }
}
function addAmountReferralUser(data, paginationData) {
    // console.log("getAdminProfilegetAdminProfile_______props::", props);
    return dispatch => {
        dispatch(request());
        userService.addAmountReferralUser(data)
            .then(
                user => {
                    console.log('user_______________________user', user);
                    dispatch(success('Amount successfully paid.'));
                    dispatch(this.getUserRefList(paginationData));
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.ADD_AMOUNT_REFERRAL_USER_REQUEST } }
    function success(user) { return { type: userConstants.ADD_AMOUNT_REFERRAL_USER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.ADD_AMOUNT_REFERRAL_USER_FAILURE, error } }
}
function updateSubAdminPassword(data) {
    // console.log("getAdminProfilegetAdminProfile_______props::", props);
    return dispatch => {
        dispatch(request());
        userService.updateSubAdminPassword(data)
            .then(
                user => {
                    console.log('user_______________________user', user);
                    dispatch(success(user));
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.UPDATE_SUB_ADMIN_PASSWORD_REQUEST } }
    function success(user) { return { type: userConstants.UPDATE_SUB_ADMIN_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_SUB_ADMIN_PASSWORD_FAILURE, error } }
}
function getAdminList(data) {
    // console.log("getAdminProfilegetAdminProfile_______props::", props);
    return dispatch => {
        dispatch(request());
        userService.getAdminList(data)
            .then(
                user => {
                    console.log('user_______________________user', user);
                    dispatch(success(user));
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.GET_ADMIN_LIST_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_ADMIN_LIST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_ADMIN_LIST_FAILURE, error } }
}
function getUserPaymentDetails(data) {
    // console.log("getAdminProfilegetAdminProfile_______props::", props);
    return dispatch => {
        dispatch(request());
        userService.getUserPaymentDetails(data)
            .then(
                user => {
                    console.log('user_______________________user', user);
                    dispatch(success(user));
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.GET_USER_PAYMENT_DETAILS_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_USER_PAYMENT_DETAILS_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_USER_PAYMENT_DETAILS_FAILURE, error } }
}
function getUserRefList(data) {
    // console.log("getAdminProfilegetAdminProfile_______props::", props);
    return dispatch => {
        dispatch(request());
        userService.getUserRefList(data)
            .then(
                user => {
                    console.log('user_______________________user', user);
                    dispatch(success(user));
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.GET_LEAD_LIST_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_LEAD_LIST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_LEAD_LIST_FAILURE, error } }
}
function getTuitionListByDate(data) {
    // console.log("getAdminProfilegetAdminProfile_______props::", props);
    return dispatch => {
        dispatch(request());
        userService.getTuitionListByDate(data)
            .then(
                user => {
                    console.log('user_______________________user', user);
                    dispatch(success(user));
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.GET_TUITION_LIST_BY_DATE_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_TUITION_LIST_BY_DATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_TUITION_LIST_BY_DATE_FAILURE, error } }
}
function getFeeHistoryListAdmin(data) {
    // console.log("getAdminProfilegetAdminProfile_______props::", props);
    return dispatch => {
        dispatch(request());
        userService.getFeeHistoryListAdmin(data)
            .then(
                user => {
                    console.log('user_______________________user', user);
                    dispatch(success(user));
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.GET_FEE_HISTORY_LIST_ADMIN_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_FEE_HISTORY_LIST_ADMIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_FEE_HISTORY_LIST_ADMIN_FAILURE, error } }
}
function userLogin(data, props) {
    console.log("loginlogin_______props::", props);
    return dispatch => {
        dispatch(request({ data }));
        userService.userLogin(data)
            .then(
                user => {
                    dispatch(success(user));
                    window.open(CONST.WEV_LIVE_URL + "/#/customlogin/" + JSON.stringify(user.userinfo), '_blank', 'noopener,noreferrer')
                    // props.history.push({ pathname: 'app/dashboard' });
                    // props.history.push('customlogin');
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.CUSTOM_LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.CUSTOM_LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CUSTOM_LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}
function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                uploadImage => {
                    toast("File Upload Successfully.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}
function verifyEmail(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.verifyEmail(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserActive(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_VERIFY_EMAIL_REQUEST } }
    function success(users) { return { type: userConstants.USER_VERIFY_EMAIL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_VERIFY_EMAIL_FAILURE, error } }
}
function withdrawUserBalance(data) {

    return dispatch => {
        dispatch(request());
        userService.withdrawUserBalance(data)
            .then(

                users => {
                    dispatch(alertActions.success("Deposit Balance Successfully."));
                    dispatch(success(users));
                    dispatch(this.getWalletList({ id: data.userId }));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.WITHDRAW_USER_BALANCE_REQUEST } }
    function success(users) { return { type: userConstants.WITHDRAW_USER_BALANCE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.WITHDRAW_USER_BALANCE_FAILURE, error } }
}

function depositUserBalance(data) {

    return dispatch => {
        dispatch(request());
        userService.depositUserBalance(data)
            .then(

                users => {
                    dispatch(alertActions.success("Deposit Balance Successfully."));
                    dispatch(success(users));
                    dispatch(this.getWalletList({ id: data.userId }));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DEPOSIT_USER_BALANCE_REQUEST } }
    function success(users) { return { type: userConstants.DEPOSIT_USER_BALANCE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DEPOSIT_USER_BALANCE_FAILURE, error } }
}

function deleteUser(data, paginationFilter) {

    return dispatch => {
        dispatch(request());
        userService.deleteUser(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserActive(paginationFilter));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DELETE_USER_REQUEST } }
    function success(users) { return { type: userConstants.DELETE_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DELETE_USER_FAILURE, error } }
}

function deleteAssignedClasses(data, paginationFilter) {

    return dispatch => {
        dispatch(request());
        userService.deleteAssignedClasses(data)
            .then(
                users => {
                    dispatch(success(users));
                    toast("Delete successfully.")
                    dispatch(this.getAssignedClassesList(paginationFilter));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DELETE_ASSIGN_CLASSES_REQUEST } }
    function success(users) { return { type: userConstants.DELETE_ASSIGN_CLASSES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DELETE_ASSIGN_CLASSES_FAILURE, error } }
}
function updateAdminStatus(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.updateAdminStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAdminList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DISABLE_ADMIN_REQUEST } }
    function success(users) { return { type: userConstants.DISABLE_ADMIN_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DISABLE_ADMIN_FAILURE, error } }
}
function disableUser(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.disableUser(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserActive(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DISABLE_USER_REQUEST } }
    function success(users) { return { type: userConstants.DISABLE_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DISABLE_USER_FAILURE, error } }
}
function changeStatusUserRef(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.changeStatusUserRef(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserRefList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DISABLE_USER_REF_REQUEST } }
    function success(users) { return { type: userConstants.DISABLE_USER_REF_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DISABLE_USER_REF_FAILURE, error } }
}
function registerUserByAdmin(data, paginationData, response) {

    return dispatch => {
        dispatch(request());
        userService.registerUserByAdmin(data)
            .then(
                users => {
                    dispatch(alertActions.success("Register User Successfully."));
                    dispatch(success(users));
                    dispatch(this.changeStatusUserRef(response));
                    dispatch(this.getUserRefList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.REJISTER_USER_REF_REQUEST } }
    function success(users) { return { type: userConstants.REJISTER_USER_REF_SUCCESS, users } }
    function failure(error) { return { type: userConstants.REJISTER_USER_REF_FAILURE, error } }
}
function updateInterviewStatus(data, paginationData) {
    console.log('paginationData_______________', paginationData);

    return dispatch => {
        dispatch(request());
        userService.updateInterviewStatus(data)
            .then(
                users => {
                    dispatch(alertActions.success("Update Status Successfully."));
                    dispatch(success(users));
                    dispatch(kycActions.getKycList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_INTERVIEW_REF_REQUEST } }
    function success(users) { return { type: userConstants.USER_INTERVIEW_REF_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_INTERVIEW_REF_FAILURE, error } }
}
function updateUnpaidStatus(data, paginationData) {
    console.log('paginationData_______________', paginationData);

    return dispatch => {
        dispatch(request());
        userService.updateUnpaidStatus(data)
            .then(
                users => {
                    dispatch(alertActions.success("Update Status Successfully."));
                    dispatch(success(users));
                    dispatch(userActions.getFeeHistoryListAdmin(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_UNPAID_STATUS_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_UNPAID_STATUS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_UNPAID_STATUS_FAILURE, error } }
}
function updateInterviewStatusUser(data, paginationData) {
    console.log('paginationData_______________', paginationData);

    return dispatch => {
        dispatch(request());
        userService.updateInterviewStatus(data)
            .then(
                users => {
                    dispatch(alertActions.success("Update Status Successfully."));
                    dispatch(success(users));
                    dispatch(userActions.getUserListRejectedInterview(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_INTERVIEW_REF_REQUEST } }
    function success(users) { return { type: userConstants.USER_INTERVIEW_REF_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_INTERVIEW_REF_FAILURE, error } }
}
function updateAssignedClassesStatus(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.updateAssignedClassesStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAssignedClassesList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DISABLE_ASSIGNED_CLASSES_REQUEST } }
    function success(users) { return { type: userConstants.DISABLE_ASSIGNED_CLASSES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DISABLE_ASSIGNED_CLASSES_FAILURE, error } }
}
function updateAssignmentAdminStatus(data, paginationData) {

    return dispatch => {
        dispatch(request());
        userService.updateAssignmentAdminStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Update Status Successfully."));
                    dispatch(this.getAssignmentAdminList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DISABLE_ASSIGNMENT_REQUEST } }
    function success(users) { return { type: userConstants.DISABLE_ASSIGNMENT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DISABLE_ASSIGNMENT_FAILURE, error } }
}
function changeLevelStatus(data) {

    return dispatch => {
        dispatch(request());
        userService.changeLevelStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    // dispatch(this.getUserActive(paginationData));
                    dispatch(this.getUserProfile(data));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.LEVEL_STATUS_USER_REQUEST } }
    function success(users) { return { type: userConstants.LEVEL_STATUS_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.LEVEL_STATUS_USER_FAILURE, error } }
}
function getTuituinListTutorWasi(data) {
    return dispatch => {
        dispatch(request());
        userService.getTuituinListTutorWasi(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_PAYMENT_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_PAYMENT_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_PAYMENT_LIST_FAILURE, error } }
}

function getAttendanceListTuitionWasi(data) {
    return dispatch => {
        dispatch(request());
        userService.getAttendanceListTuitionWasi(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ATTENDENCE_TUTION_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_ATTENDENCE_TUTION_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ATTENDENCE_TUTION_LIST_FAILURE, error } }
}
function updateStatusPayment(data) {
    let temp = {
        "paymentType": "PENDING",
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        userService.updateStatusPayment(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getTuituinListTutorWasi(temp));
                }
                ,
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_STATUS_PAYMENT_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_STATUS_PAYMENT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_STATUS_PAYMENT_FAILURE, error } }
}
function getUserListRejectedInterview(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserListRejectedInterview(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_USER_ACTIVE_REJECTED_INTERVIEW_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_USER_ACTIVE_REJECTED_INTERVIEW_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_USER_ACTIVE_REJECTED_INTERVIEW_FAILURE, error } }
}
function getUserActive(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserActive(data)
            .then(
                users => {
                    console.log('users___________users____________', users);
                    dispatch(success(users))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_USER_ACTIVE_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_USER_ACTIVE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_USER_ACTIVE_FAILURE, error } }
}
function getUserActiveStudent(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserActive(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_USER_ACTIVE_STUDENT_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_USER_ACTIVE_STUDENT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_USER_ACTIVE_STUDENT_FAILURE, error } }
}
function getTuitionListByTutor(data) {
    return dispatch => {
        dispatch(request());
        userService.getTuitionListByTutor(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_TUITION_LIST_BY_TUTOR_REQUEST } }
    function success(users) { return { type: userConstants.GET_TUITION_LIST_BY_TUTOR_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_TUITION_LIST_BY_TUTOR_FAILURE, error } }
}
function getAttendanceListByTutor(data) {
    return dispatch => {
        dispatch(request());
        userService.getAttendanceListByTutor(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ATTENDANCE_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_ATTENDANCE_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ATTENDANCE_LIST_FAILURE, error } }
}
function getClassIdByTuitionStudent(data) {
    return dispatch => {
        dispatch(request());
        userService.getClassIdByTuitionStudent(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_CLASS_ID_BY_TUITION_STUDENT_REQUEST } }
    function success(users) { return { type: userConstants.GET_CLASS_ID_BY_TUITION_STUDENT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_CLASS_ID_BY_TUITION_STUDENT_FAILURE, error } }
}
function getAssignedClassesList(data) {
    return dispatch => {
        dispatch(request());
        userService.getAssignedClassesList(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ASSIGNED_CLASSES_REQUEST } }
    function success(users) { return { type: userConstants.GET_ASSIGNED_CLASSES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ASSIGNED_CLASSES_FAILURE, error } }
}
function getAssignmentAdminList(data) {
    return dispatch => {
        dispatch(request());
        userService.getAssignmentAdminList(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ASSIGNMENT_REQUEST } }
    function success(users) { return { type: userConstants.GET_ASSIGNMENT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ASSIGNMENT_FAILURE, error } }
}
function getUserDeactive(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserDeactive(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_USER_DEACTIVE_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_USER_DEACTIVE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_USER_DEACTIVE_FAILURE, error } }
}

function registerUserAdmin(data, reqData) {
    return dispatch => {
        dispatch(request());
        userService.registerUserAdmin(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Tutor Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getUserActive(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_USER_FAILURE, error } }
}
function updateUserPasswordSubmit(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        userService.updateUserPasswordSubmit(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Password Updated successfully."));
                    dispatch(success(users));
                    dispatch(this.getUserActive(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_PASSWORD_FAILURE, error } }
}
function getUserKycDetailsById(data, paginationData) {
    return dispatch => {
        dispatch(request());
        userService.getUserKycDetailsById(data)
            .then(

                users => {
                    dispatch(success(users));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_KYC_DETAILS_BY_ID_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_KYC_DETAILS_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_KYC_DETAILS_BY_ID_FAILURE, error } }
}

function loginToThisAccount(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.loginToThisAccount(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$ loginToThisAccount $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.LOGIN_TO_THIS_ACCOUNT_REQUEST } }
    function success(users) { return { type: userConstants.LOGIN_TO_THIS_ACCOUNT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.LOGIN_TO_THIS_ACCOUNT_FAILURE, error } }
}

function getProfile(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getProfile(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getProfile $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_PROFILE_REQUEST } }
    function success(users) { return { type: userConstants.GET_PROFILE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_PROFILE_FAILURE, error } }
}

function addStudent(data, dataRes) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.addStudent(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Student data add successfully."));
                    dispatch(this.getUserActive(dataRes));
                    dispatch(teachcourseActions.getFilteredDemoClassList(dataRes))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_WALLET_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_WALLET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_WALLET_FAILURE, error } }
}

function updateStudentDetails(data, dataRes) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.updateStudentDetails(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Student data add successfully."));
                    dispatch(this.getUserActive(dataRes));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_STUDENT_DETAILS_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_STUDENT_DETAILS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_STUDENT_DETAILS_FAILURE, error } }
}

function removeDftcWalletAddress(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.removeDftcWalletAddress(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ removeDftcWalletAddress $$$$ action:", users);
                    dispatch(success(users));
                    toast("Remove address successfully.")
                    dispatch(this.getWalletList({ id: data.id }));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.REMOVE_DFTC_ADDRESS_REQUEST } }
    function success(users) { return { type: userConstants.REMOVE_DFTC_ADDRESS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.REMOVE_DFTC_ADDRESS_FAILURE, error } }
}
function createAssignmentAdmin(data, req) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.createAssignmentAdmin(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ createAssignmentAdmin $$$$ action:", users);
                    dispatch(success(users));
                    toast("Create Assignment successfully successfully.")
                    dispatch(this.getAssignmentAdminList());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_TRANSACTION_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_TRANSACTION_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_TRANSACTION_FAILURE, error } }
}
function markPaidOrUnPaid(data, req) {
    return dispatch => {
        dispatch(request());
        userService.markPaidOrUnPaid(data)
            .then(
                users => {
                    dispatch(success(users));
                    // toast("Assign successfully.")
                    dispatch(this.getAssignedClassesList(req));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.MARK_PAID_OR_UNPAID_REQUEST } }
    function success(users) { return { type: userConstants.MARK_PAID_OR_UNPAID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.MARK_PAID_OR_UNPAID_FAILURE, error } }
}
function createAssignedClasses(data, req) {
    return dispatch => {
        dispatch(request());
        userService.createAssignedClasses(data)
            .then(
                users => {
                    dispatch(success(users));
                    toast("Assign successfully.")
                    dispatch(this.getAssignedClassesList(req));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_ASSIGN_CLASSES_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_ASSIGN_CLASSES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_ASSIGN_CLASSES_FAILURE, error } }
}
function updateAssignedClasses(data, req) {
    return dispatch => {
        dispatch(request());
        userService.updateAssignedClasses(data)
            .then(
                users => {
                    dispatch(success(users));
                    toast("Update successfully.")
                    dispatch(this.getAssignedClassesList(req));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_ASSIGN_CLASSES_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_ASSIGN_CLASSES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_ASSIGN_CLASSES_FAILURE, error } }
}
function payFastrackOrLifeTimeBonus(data, req) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.payFastrackOrLifeTimeBonus(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ payFastrackOrLifeTimeBonus $$$$ action:", users);
                    dispatch(success(users));
                    toast("Pay successfully.")
                    dispatch(this.getUserProfile(req));
                    // dispatch(this.getTx(req));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.PAY_FASTRACK_OR_LIFE_REQUEST } }
    function success(users) { return { type: userConstants.PAY_FASTRACK_OR_LIFE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.PAY_FASTRACK_OR_LIFE_FAILURE, error } }
}
// function createAssignmentAdmin(data, req) {
//     // console.log("data m kya aa rha h::action:::", data);
//     return dispatch => {
//         dispatch(request());
//         userService.createAssignmentAdmin(data)
//             .then(
//                 users => {
//                     // console.log("$$$$$$$$$$$ createAssignmentAdmin $$$$ action:", users);
//                     dispatch(success(users));
//                     toast("Pay successfully successfully.")
//                     dispatch(this.getTx(req));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: userConstants.CREATE_TRANSACTION_REQUEST } }
//     function success(users) { return { type: userConstants.CREATE_TRANSACTION_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.CREATE_TRANSACTION_FAILURE, error } }
// }
function manageUserPortfolio(data, req) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.manageUserPortfolio(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ manageUserPortfolio $$$$ action:", users);
                    dispatch(success(users));
                    toast("Update level income successfully.")
                    window.location.reload();



                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_TRANSACTION_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_TRANSACTION_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_TRANSACTION_FAILURE, error } }
}

function deleteAssignmentAdmin(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.deleteAssignmentAdmin(data)
            .then(
                users => {
                    dispatch(success(users));
                    toast("Delete Assignment successfully.")
                    dispatch(this.getAssignmentAdminList(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DELETE_ASSIGNMENT_REQUEST } }
    function success(users) { return { type: userConstants.DELETE_ASSIGNMENT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DELETE_ASSIGNMENT_FAILURE, error } }
}

function updatePayoutAmount(data) {
    return dispatch => {
        dispatch(request());
        userService.updatePayoutAmount(data)
            .then(
                users => {
                    let message = users && users.updatePayoutAmount && users.updatePayoutAmount.message ? users.updatePayoutAmount.message : null
                    dispatch(success(users));
                    dispatch(alertActions.success(message));
                    dispatch(this.getWalletList({ id: data.id }));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_PAYOUT_AMOUNT_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_PAYOUT_AMOUNT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_PAYOUT_AMOUNT_FAILURE, error } }
}

function updateTeamMiningAmount(data) {
    return dispatch => {
        dispatch(request());
        userService.updateTeamMiningAmount(data)
            .then(
                users => {
                    let message = users && users.updateTeamMiningAmount && users.updateTeamMiningAmount.message ? users.updateTeamMiningAmount.message : null
                    dispatch(success(users));
                    dispatch(alertActions.success(message));
                    dispatch(this.getWalletList({ id: data.id }));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_TEAM_MINING_AMOUNT_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_TEAM_MINING_AMOUNT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_TEAM_MINING_AMOUNT_FAILURE, error } }
}

function updateAssignmentAdmin(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.updateAssignmentAdmin(data)
            .then(
                users => {
                    // let message = users && users.updateAssignmentAdmin && users.updateAssignmentAdmin.message ? users.updateAssignmentAdmin.message : null
                    dispatch(success(users));
                    dispatch(alertActions.success("Update Assignment Successfully"));
                    dispatch(this.getAssignmentAdminList(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_TX_AMOUNT_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_TX_AMOUNT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_TX_AMOUNT_FAILURE, error } }
}

function approvePromoPack(data, paginationData) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.approvePromoPack(data)
            .then(
                users => {
                    let message = users && users.approvePromoPack && users.approvePromoPack.message ? users.approvePromoPack.message : null
                    // console.log("$$$$$$$$$$$ approvePromoPack $$$$ action:", users);
                    dispatch(success(users));
                    console.log("approvePromoPack action ::", users);
                    dispatch(alertActions.success(message));
                    dispatch(this.getUserPromoPackageByAdmin(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.APPROVE_PROMO_PACK_REQUEST } }
    function success(users) { return { type: userConstants.APPROVE_PROMO_PACK_SUCCESS, users } }
    function failure(error) { return { type: userConstants.APPROVE_PROMO_PACK_FAILURE, error } }
}

function addUserBalance(data, tempData) {

    return dispatch => {
        dispatch(request());
        userService.addUserBalance(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$ addUserBalance $$$$ action:", users);
                    let message = users && users.addUserBalance && users.addUserBalance.message ? users.addUserBalance.message : ''
                    dispatch(alertActions.success(message));
                    dispatch(success(users));
                    dispatch(this.getUserActive(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_USER_BALANCE_REQUEST } }
    function success(users) { return { type: userConstants.ADD_USER_BALANCE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_USER_BALANCE_FAILURE, error } }
}

function updateUserName(data, tempData) {

    return dispatch => {
        dispatch(request());
        userService.updateUserName(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$ updateUserName $$$$ action:", users);
                    let message = users && users.updateUserName && users.updateUserName.message ? users.updateUserName.message : ''
                    dispatch(alertActions.success(message));
                    dispatch(success(users));
                    dispatch(this.getUserActive(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USERNAME_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USERNAME_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USERNAME_FAILURE, error } }
}

function updateEmail(data, tempData) {

    return dispatch => {
        dispatch(request());
        userService.updateEmail(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$ updateEmail $$$$ action:", users);
                    let message = users && users.updateEmail && users.updateEmail.message ? users.updateEmail.message : ''
                    dispatch(alertActions.success('User update successfully.'));
                    dispatch(success(users));
                    dispatch(this.getUserActive(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_EMAIL_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_EMAIL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_EMAIL_FAILURE, error } }
}

function getWalletList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getWalletList(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getWalletList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_WALLET_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_WALLET_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_WALLET_LIST_FAILURE, error } }
}

function getLoginhistory(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getLoginhistory(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$ getWalletList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_LOGIN_HISTORY_USER_REQUEST } }
    function success(users) { return { type: userConstants.GET_LOGIN_HISTORY_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_LOGIN_HISTORY_USER_FAILURE, error } }
}

function getUserPromoPackageList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserPromoPackageList(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$ getWalletList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_PROMO_PACKAGE_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_PROMO_PACKAGE_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_PROMO_PACKAGE_LIST_FAILURE, error } }
}

function getUserPackageList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserPackageList(data)
            .then(
                users => {
                    console.log("$$$$$$$$$$$ getWalletList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_PACKAGE_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_PACKAGE_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_PACKAGE_LIST_FAILURE, error } }
}

function getUserPromoPackageByAdmin(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserPromoPackageByAdmin(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getUserPromoPackageByAdmin $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_PROMO_PACKAGE_REQUEST } }
    function success(users) { return { type: userConstants.GET_PROMO_PACKAGE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_PROMO_PACKAGE_FAILURE, error } }
}
function getUserPackages(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserPackages(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getUserPackages $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_PACKAGE_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_PACKAGE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_PACKAGE_FAILURE, error } }
}
function getUserLevel(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserLevel(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getUserLevel $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_LEVEL_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_LEVEL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_LEVEL_FAILURE, error } }
}
function getTx(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getTx(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getTx $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_TX_REQUEST } }
    function success(users) { return { type: userConstants.GET_TX_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_TX_FAILURE, error } }
}
function getUserInfoByAdmin(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserInfoByAdmin(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getUserInfoByAdmin $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_INFO_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_INFO_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_INFO_FAILURE, error } }
}
function getSocialMediaById(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getSocialMediaById(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getSocialMediaById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_SOCIAL_INFO_REQUEST } }
    function success(users) { return { type: userConstants.GET_SOCIAL_INFO_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_SOCIAL_INFO_FAILURE, error } }
}
function getUserProfile(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserProfile(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getUserProfile $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_PROFILE_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_PROFILE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_PROFILE_FAILURE, error } }
}
function getUserPackagesTeam(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserPackagesTeam(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getUserPackagesTeam $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_PACKAGE_TEAM_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_PACKAGE_TEAM_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_PACKAGE_TEAM_FAILURE, error } }
}
function getUserTeamByUserId(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getUserTeamByUserId(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getUserTeamByUserId $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_TEAM_BY_USER_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_TEAM_BY_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_TEAM_BY_USER_FAILURE, error } }
}
function getTicketList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.getTicketList(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ getTicketList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_TICKET_REQUEST } }
    function success(users) { return { type: userConstants.GET_TICKET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_TICKET_FAILURE, error } }
}
function changePassword(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        userService.changePassword(data)
            .then(
                users => {
                    // console.log("$$$$$$$$$$$ changePassword $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CHANGE_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}
