import React, { Component } from 'react';
import { connect } from 'react-redux';
import { documentActions, userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
// import DialogExample from "./components/DialogExample/DialogExample";
import ViewDocumentModal from "./components/ViewDocumentModal/ViewDocumentModal";
// import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateDocumentModal from "./components/CreateDocumentModal/CreateDocumentModal";
import UpdateDocumentModal from "./components/UpdateDocumentModal/UpdateDocumentModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fieldskyc: {},
      // errorskyc: {},
      fieldsDocument: {},
      errorsDocument: {},
      fieldsUpdateDocument: {},
      errorsUpdateDocument: {},
      viewRowData: {},
      documentCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateDocumentModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(documentActions.getDocumentList(data));
    // this.props.dispatch(documentActions.createSport(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.document.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsDocument: {},
        errorsDocument: {},
        documentCreateModal: false,
        UpdateDocumentModal: false,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(documentActions.getDocumentList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(documentActions.getDocumentList(data));
  }
  disableDocument = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.name} document?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(documentActions.disableDocument(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteDocument = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} document?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(documentActions.deleteDocument(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenCreateModalMoreDetails = (data) => {

    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }
  handleOpenDocumentUpdateModal = (data) => {
    this.setState({ UpdateDocumentModal: true, fieldsUpdateDocument: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(documentActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleDocumentHideModal = () => {
    this.setState({ documentCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleDocumentUpdateHideModal = () => {
    this.setState({ UpdateDocumentModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleHideDocumentUpdateModal = () => {
    this.setState({ UpdateDocumentModal: false });
  }
  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ documentCreateModal: true });
  }
  inputDocumentChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsDocument = this.state.fieldsDocument;
    let errorsDocument = this.state.errorsDocument;
    fieldsDocument[name] = value;
    console.log(name, value);
    errorsDocument[name] = "";
    this.setState({ fieldsDocument, errorsDocument });
  }
  inputChangeUpdateDocument = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdateDocument = this.state.fieldsUpdateDocument;
    let errorsUpdateDocument = this.state.errorsUpdateDocument;
    fieldsUpdateDocument[name] = value;
    errorsUpdateDocument[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateDocument, errorsUpdateDocument });
  }

  createDocumentSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;

    if (this.handleValidationDocument()) {
      let reqData = {
        "name": this.state.fieldsDocument.name,
        "slug": this.state.fieldsDocument.slug,
        "imageCount": this.state.fieldsDocument.imageCount,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsDocument.image,
        "imageBack": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsDocument.image
      }

      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>createDocumentSubmit>", reqData);
      this.props.dispatch(documentActions.createDocument(reqData));
    }

  }

  // navigate = (data) => {

  //   this.props.history.push('/app/series/' + data.sportId);
  // }

  updateDocumentSubmit = () => {

    let { users } = this.props;
    let { filesDetails } = users;


    if (this.handleValidationUpdateDocument()) {
      let reqData = {
        "id": this.state.fieldsUpdateDocument.id,
        "name": this.state.fieldsUpdateDocument.name,
        "slug": this.state.fieldsUpdateDocument.slug,
        "imageCount": this.state.fieldsUpdateDocument.imageCount,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsUpdateDocument.image

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(documentActions.updateDocument(reqData, paginationData));
    }

  }
  handleValidationUpdateDocument = () => {
    let fieldsUpdateDocument = this.state.fieldsUpdateDocument;
    let errorsUpdateDocument = {};
    let formIsValid = true;

    //name
    if (!fieldsUpdateDocument["name"] || fieldsUpdateDocument["name"] === "") {
      formIsValid = false;
      errorsUpdateDocument["name"] = "Cannot be empty";
    }

    //slug
    if (!fieldsUpdateDocument["slug"] || fieldsUpdateDocument["slug"] === "") {
      formIsValid = false;
      errorsUpdateDocument["slug"] = "Cannot be empty slug";
    }

    //imageCount
    if (!fieldsUpdateDocument["imageCount"] || fieldsUpdateDocument["imageCount"] === "") {
      formIsValid = false;
      errorsUpdateDocument["imageCount"] = "Cannot be empty imageCount";
    }

    console.log("errorsUpdateDocumenterrorsUpdateDocument_:", errorsUpdateDocument);

    this.setState({ errorsUpdateDocument: errorsUpdateDocument });
    return formIsValid;
  }
  handleValidationDocument = () => {
    let fieldsDocument = this.state.fieldsDocument;
    let errorsDocument = {};
    let formIsValid = true;

    //name
    if (!fieldsDocument["name"] || fieldsDocument["name"] === "") {
      formIsValid = false;
      errorsDocument["name"] = "Cannot be empty name";
    }
    //slug
    if (!fieldsDocument["slug"] || fieldsDocument["slug"] === "") {
      formIsValid = false;
      errorsDocument["slug"] = "Cannot be empty slug";
    }
    //imageCount
    if (!fieldsDocument["imageCount"] || fieldsDocument["imageCount"] === "") {
      formIsValid = false;
      errorsDocument["imageCount"] = "Cannot be empty imageCount";
    }
    this.setState({ errorsDocument: errorsDocument });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  render() {

    let { document } = this.props;
    let { total, loading, items } = document;

    console.log("RENDER___itemsitemsitems:::", items);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="pb-4 text-lg font-semibold leading-6 tracking-widest text-center md:text-2xl md:leading-9 text-dark-400">Document</h3>
                    </div>



                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute text-gray-600 top-4 left-5"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="w-full px-3 py-3 pl-10 font-normal placeholder-gray-600 transition duration-150 ease-in-out bg-gray-200 border rounded-full appearance-none sm:w-64 border-dark-400 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-600 text-normal sm:leading-5" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>

                    <div className="flex justify-end ...">
                      <button className="flex justify-end py-1 font-bold text-white bg-blue-500 border border-blue-700 rounded hover:bg-blue-700 px-7 "
                        style={{ margin: "2px 0px 10px 15px" }}
                        onClick={() => this.handleOpenCreateModal()}> ADD </button>
                    </div>

                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">name</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">slug</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">imageCount</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">imageLinkUrl</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">imageLinkUrlBack</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 capitalize whitespace-nowrap">Actions</th>

                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.name ? element.name : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.slug ? element.slug : "NA"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.imageCount ? element.imageCount : 0}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <img src={element && element.imageLinkUrl ? element.imageLinkUrl : "NA"} alt=" " />

                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <img src={element && element.imageBackLinkUrl ? element.imageBackLinkUrl : "NA"} alt=" " />

                                      </td>


                                      <td className="px-2 py-3 whitespace-nowrap text-gray-600 inline-block">
                                        <span className="inline-block pl-1">
                                          <button className="bg-yellow-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>More</button>
                                        </span>
                                        <span className="inline-block pl-1">
                                          <button className="bg-green-700 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-green-400" onClick={() => this.disableDocument(element)}>{element && element.isDisable ? "Disable" : "Enable"}</button>
                                        </span>

                                        <span className="inline-block pl-1">
                                          <button className="bg-indigo-500 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-indigo-300" onClick={() => this.handleOpenDocumentUpdateModal(element)}>Update</button>
                                        </span>
                                        <span className="inline-block pl-1">
                                          <button className="bg-red-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-red-400" onClick={() => this.deleteDocument(element)}>Delete</button>
                                        </span>

                                      </td>



                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        {/* <DialogExample /> */}

        <CreateDocumentModal
          documentCreateModal={this.state.documentCreateModal}
          fieldsDocument={this.state.fieldsDocument}
          errorsDocument={this.state.errorsDocument}
          inputDocumentChange={this.inputDocumentChange}
          createDocumentSubmit={this.createDocumentSubmit}
          handleDocumentHideModal={this.handleDocumentHideModal}
          handleFile={this.handleFile}
        />

        <UpdateDocumentModal
          UpdateDocumentModal={this.state.UpdateDocumentModal}
          fieldsUpdateDocument={this.state.fieldsUpdateDocument}
          errorsUpdateDocument={this.state.errorsUpdateDocument}
          inputChangeUpdateDocument={this.inputChangeUpdateDocument}
          updateDocumentSubmit={this.updateDocumentSubmit}
          handleDocumentUpdateHideModal={this.handleDocumentUpdateHideModal}
          handleFile={this.handleFile}
        />

        <ViewDocumentModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={this.state.viewRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { document, users } = state;
  return {
    document,
    users
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Document);
