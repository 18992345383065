import {
  tutionConstants
} from '../_constants';

export function tution(state = {}, action) {

  switch (action.type) {
    case tutionConstants.SEND_DEMO_REQUEST_REQUEST:
      return {
        loading: true,
        ...state
      };
    case tutionConstants.SEND_DEMO_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: true,
        sendDemoRequest: action.users
      };
    case tutionConstants.SEND_DEMO_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case tutionConstants.ADD_TUTION_REQUEST:
      return {
        ...state
      };
    case tutionConstants.ADD_TUTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tutionConstants.ADD_TUTION_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case tutionConstants.TUITION_APPROVE_REQUEST:
      return {
        ...state
      };
    case tutionConstants.TUITION_APPROVE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tutionConstants.TUITION_APPROVE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case tutionConstants.GET_LIST_TUTION_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case tutionConstants.GET_LIST_TUTION_SUCCESS:
      return {
        ...state,

        loading: false,
        tuitionItemsDashboard: action.users.getTutionList.list,
        items: action.users.getTutionList.list,
        itemsClose: action.users.getTutionList.list,
        tutionTotal: action.users.getTutionList.total,
        totalClose: action.users.getTutionList.total,
        total: action.users.getTutionList.total,
        totalPending: action.users.getTutionList.total
      };
    case tutionConstants.GET_LIST_TUTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case tutionConstants.GET_TUTION_LIST_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case tutionConstants.GET_TUTION_LIST_BY_ID_SUCCESS:
      return {
        ...state,

        loading: false,
        getTuitionById: action.users.getTuitionById
      };
    case tutionConstants.GET_TUTION_LIST_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case tutionConstants.GET_ATTENDANCE_LIST_BY_TUTOR_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case tutionConstants.GET_ATTENDANCE_LIST_BY_TUTOR_SUCCESS:
      return {
        ...state,

        loading: false,
        attendanceListItems: action.users.getAttendanceListByTutor,
        totalClassDemo: action.users.getAttendanceListByTutor.totalClassDemo,
        totalSudent: action.users.getAttendanceListByTutor.totalSudent,
        totalTutor: action.users.getAttendanceListByTutor.totalTutor,
        // total: action.users.getTutionList.total
      };
    case tutionConstants.GET_ATTENDANCE_LIST_BY_TUTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_SUCCESS:
      return {
        ...state,

        loading: false,
        suggestedTutorByTuition: action.users.getSuggestedTutorByTuition,
        suggestedTutorByTuitionTotal: action.users.getSuggestedTutorByTuition
      };
    case tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_1_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_1_SUCCESS:
      return {
        ...state,

        loading: false,
        suggestedTutorByTuition1: action.users.getSuggestedTutorByTuition1,
        suggestedTutorByTuitionTotal1: action.users.getSuggestedTutorByTuition1
      };
    case tutionConstants.GET_SUGGESTED_TUTOR_BY_TUITION_1_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case tutionConstants.GET_LIST_TUTORCLOSE_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case tutionConstants.GET_LIST_TUTORCLOSE_SUCCESS:
      return {
        ...state,

        loading: false,
        campleteItems: action.users.getCloseClassesListAdmin.list,
        campleteTotal: action.users.getCloseClassesListAdmin.total
      };
    case tutionConstants.GET_LIST_TUTORCLOSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case tutionConstants.GETALL_TUTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.GETALL_TUTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllTution: action.users.getAllTution,
        total: action.users.getAllTution
      };
    case tutionConstants.GETALL_TUTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case tutionConstants.UPDATE_TUTION_REQUEST:
      return {
        ...state
      };
    case tutionConstants.UPDATE_TUTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tutionConstants.UPDATE_TUTION_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case tutionConstants.MANUAL_TUTOR_CHANGE_REQUEST:
      return {
        ...state
      };
    case tutionConstants.MANUAL_TUTOR_CHANGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tutionConstants.MANUAL_TUTOR_CHANGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case tutionConstants.ADD_AMOUNT_IN_TUITION_REQUEST:
      return {
        ...state
      };
    case tutionConstants.ADD_AMOUNT_IN_TUITION_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tutionConstants.ADD_AMOUNT_IN_TUITION_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case tutionConstants.UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_REQUEST:
      return {
        ...state
      };
    case tutionConstants.UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tutionConstants.UPDATE_TUITION_STATUS_ON_GOIN_ORREJECT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case tutionConstants.DELETE_TUTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.DELETE_TUTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case tutionConstants.DELETE_TUTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case tutionConstants.DISABLE_TUTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.DISABLE_TUTION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case tutionConstants.DISABLE_TUTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case tutionConstants.DISABLE_TUTIONCLOSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.DISABLE_TUTIONCLOSE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case tutionConstants.DISABLE_TUTIONCLOSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case tutionConstants.ADD_PINCODE_REQUEST:
      return {
        ...state
      };
    case tutionConstants.ADD_PINCODE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tutionConstants.ADD_PINCODE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case tutionConstants.GET_LIST_PINCODE_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case tutionConstants.GET_LIST_PINCODE_SUCCESS:
      return {
        ...state,

        loading: false,
        pinCodeItems: action.users.getPinCodeList.list,
        pinCodeTotal: action.users.getPinCodeList.total
      };
    case tutionConstants.GET_LIST_PINCODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case tutionConstants.GETALL_PINCODE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.GETALL_PINCODE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllPinCode: action.users.getAllPinCode,
        total: action.users.getAllPinCode
      };
    case tutionConstants.GETALL_PINCODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case tutionConstants.GETALL_PINCODE1_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.GETALL_PINCODE1_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllPinCode1: action.users.getAllPinCode,
        total: action.users.getAllPinCode
      };
    case tutionConstants.GETALL_PINCODE1_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case tutionConstants.UPDATE_PINCODE_REQUEST:
      return {
        ...state
      };
    case tutionConstants.UPDATE_PINCODE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tutionConstants.UPDATE_PINCODE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case tutionConstants.DELETE_PINCODE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.DELETE_PINCODE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case tutionConstants.DELETE_PINCODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case tutionConstants.DISABLE_PINCODE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.DISABLE_PINCODE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case tutionConstants.DISABLE_PINCODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case tutionConstants.ADD_TUTORFEE_REQUEST:
      return {
        ...state
      };
    case tutionConstants.ADD_TUTORFEE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tutionConstants.ADD_TUTORFEE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case tutionConstants.REFUSE_REQUEST_REQUEST:
      return {
        ...state
      };
    case tutionConstants.REFUSE_REQUEST_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tutionConstants.REFUSE_REQUEST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case tutionConstants.GET_LIST_TUTORFEE_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
      };
    case tutionConstants.GET_LIST_TUTORFEE_SUCCESS:
      return {
        ...state,

        loading: false,
        itemsFee: action.users.gettutorFeeList.list,
        total: action.users.gettutorFeeList.total
      };
    case tutionConstants.GET_LIST_TUTORFEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case tutionConstants.GETALL_TUTORFEE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.GETALL_TUTORFEE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getAllTUTORFEE,
        total: action.users.getAllTUTORFEE
      };
    case tutionConstants.GETALL_TUTORFEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case tutionConstants.UPDATE_TUTORFEE_REQUEST:
      return {
        ...state
      };
    case tutionConstants.UPDATE_TUTORFEE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case tutionConstants.UPDATE_TUTORFEE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case tutionConstants.DELETE_TUTORFEE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.DELETE_TUTORFEE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case tutionConstants.DELETE_TUTORFEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case tutionConstants.DISABLE_TUTORFEE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.DISABLE_TUTORFEE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case tutionConstants.DISABLE_TUTORFEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case tutionConstants.GETALL_TUTORID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.GETALL_TUTORID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllTutorId: action.users.getAllTutorId,
        total: action.users.getAllTutorId
      };
    case tutionConstants.GETALL_TUTORID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case tutionConstants.GETALL_STUDENTID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.GETALL_STUDENTID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllStudentId: action.users.getAllStudentId,
        total: action.users.getAllStudentId
      };
    case tutionConstants.GETALL_STUDENTID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case tutionConstants.GETALL_COURSEID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case tutionConstants.GETALL_COURSEID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllteacherCouresId: action.users.getAllteacherCouresId,
        total: action.users.getAllteacherCouresId
      };
    case tutionConstants.GETALL_COURSEID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    default:
      return state
  }
}