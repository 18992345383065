import React, { Component } from 'react';
import { dashboardActions, appsettingActions } from '../../_actions';
import { connect } from 'react-redux';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      fieldsAdminUser: {},
      errorsAdminUser: {},
      fieldsAdminPass: {},
      errorsAdminPass: {},
      errorsAddUser: {
        content: [""],
      },
      fieldsAddUser: {
        msg: ``,
        msg2: "",
        userType: ""
      },
      adBalanceModal: false,
      adminPassModal: false,
      zoomLevel: 1,
      opneModel: false,
      userType: "TUTOR"
    }
  }


  componentDidMount() {
    // getTermsAndConditionList
    this.props.dispatch(dashboardActions.getTermsAndConditionList());

    window.scrollTo(0, 0)
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    console.log("nextPropsnextPropsnextProps", nextProps)
    if (nextProps.dashboard.termsAndCondition) {
      return {
        ...nextProps,
        fieldsAdminUser: {},
        errorsAdminUser: {},
        adBalanceModal: false,
        adminPassModal: false,
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  handelDelete = (id) => {
    const data = {
      id: id
    }
    confirmAlert({
      message: `Are you sure to delete Condition ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(appsettingActions.delelteTermsAndCondition(data))
        },
        {
          label: 'No'
        }
      ]
    });


  }

  handelSubmit = () => {

    const data = {
      msg: this.state.fieldsAddUser.msg2,
      userType: this.state.userType
    }
    this.props.dispatch(appsettingActions.createUpdateTermsAndCondition(data))
    this.setState({ opneModel: false })
  }
  handleContentChange = (content, delta, source, editor) => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser["msg"] = editor.getHTML();
    errorsAddUser["msg"] = "";
    fieldsAddUser["msg2"] = editor.getText();
    // console.log(editor.getHTML()); // rich text
    // console.log(editor.getText()); // plain text
    // console.log(editor.getLength()); // number of characters
    this.setState({ fieldsAddUser, errorsAddUser });

  };

  handelOnChangeSlect = (e) => {
    // console.log("e.target.valuee.target.valuee.target.value", e.target.value)
    this.setState({ userType: e.target.value })
  }

  handelOpenModel = () => {
    this.setState({ fieldsAddUser: {} })
    this.setState({ opneModel: true })
  };
  handelCloseModel = () => {
    this.setState({ opneModel: false })
  };


  handelOnUpdate = (ele) => {
    this.setState({ fieldsAddUser: { ...ele } })
    this.setState({ userType: ele.userType })
    this.setState({ opneModel: true })
  }



  render() {
    const { dashboard } = this.props;
    const { termsAndCondition } = dashboard
    console.log("ttttttttttttttttttttttttt", this.state.fieldsAdminUser)
    return (
      <>
        <div className="flex flex-col flex-1  overflow-y-auto">
          <main className="relative flex-1 ">
            <h1 className=' p-4 text-2xl font-bold text-center'>Terms & Conditions</h1>
            <div className='flex justify-end my-3 mx-5'>
              {!this.state.opneModel && <button className="bg-blue-500 hover:bg-blue-700 px-7 py-1 pt-1 ml-2 text-white font-bold  border border-blue-700 rounded flex justify-end" onClick={this.handelOpenModel}> ADD </button>}
            </div>
            {!this.state.opneModel ?
              <>
                {termsAndCondition && termsAndCondition.length > 0 ?
                  termsAndCondition && termsAndCondition.map((ele, index) => {
                    return < div className='flex justify-center items-center my-4' key={index}>
                      <div className='p-10 bg-white w-full'>
                        <div>
                          <b>Type : </b> {ele.userType}
                        </div>
                        <p className='text-black text-md'>
                          {ele.msg}
                        </p>
                        <div className='flex justify-end items-center space-x-4'>
                          <button title='Edit' className='rounded-full h-10 w-10 flex justify-center items-center p-2 text-black hover:bg-blue-500 hover:text-white' onClick={() => this.handelOnUpdate(ele)}>
                            <FaRegEdit size={24} />
                          </button>
                          <button title='Delete' className='rounded-full h-10 w-10 flex justify-center items-center p-2 text-black hover:bg-blue-500 hover:text-white'>
                            <RiDeleteBin6Fill size={24} onClick={() => this.handelDelete(termsAndCondition[0].id)} />
                          </button>
                        </div>
                      </div>
                    </div>
                  })
                  :
                  null
                }
              </>
              : null
            }

            {this.state.opneModel ?
              <div className='lg:p-20 p-10'>

                <div>
                  <button title='Close' className='rounded-full h-10 w-10 flex justify-center items-center p-2 text-black hover:bg-blue-500 hover:text-white' onClick={() => this.handelCloseModel()}>
                    <IoClose />
                  </button>
                </div>

                <div className="w-full mt-2">

                  <div className="w-full mr-2">
                    <label className="mt-2 mr-2 text-sm font-medium text-black">Message :</label>

                    <div className='flex justify-end my-3 mx-5'>
                      <select className='bg-gray-400 px-5 py-1 ' onChange={this.handelOnChangeSlect}>
                        <option value={"TUTOR"}>TUTOR</option>
                        <option value={"STUDENT"}>STUDENT</option>
                        <option value={"REFERRAL"}>REFERRAL</option>
                        <option value={"REF_DESCRIPTION"}>REF_DESCRIPTION</option>
                      </select>
                    </div>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <ReactQuill
                        theme="snow"
                        rows={5}
                        value={this.state.fieldsAddUser.msg}
                        onChange={this.handleContentChange}
                        modules={this.modules}
                        formats={this.formats}
                        ref={this.quillRef}
                      />

                    </div>
                  </div>
                  {this.state.errorsAddUser && this.state.errorsAddUser["msg"] ?
                    <div className="text-xs text-red-600 invalid-feedback">
                      {this.state.errorsAddUser["msg"]}
                    </div>
                    : null}
                </div>

                <div className='mt-2 mr-2'>
                  <label className="mt-2 mr-2 text-sm font-medium text-black">HTML as text: :</label>
                  <span className=''>
                    <textarea className='border border-gray-400' name='msg'
                      onChange={this.rteChangeText}
                      value={this.state.fieldsAddUser.msg}
                      rows={5}
                      // readOnly
                      style={{ width: '100%' }} />
                  </span>
                </div>

                <div className='flex justify-center items-center pt-4'>
                  <button className='px-4 py-1 rounded bg-blue-500 text-white hover:bg-yellow-400 hover:text-black' onClick={() => this.handelSubmit()}>
                    Submit
                  </button>
                </div>

              </div>
              :
              null}
          </main>
        </div>

      </>
    );
  }
}

function mapStateToProps(state) {
  const { dashboard, transaction, users } = state;
  console.log("transactiontransactiontransaction???????????", transaction);
  return {
    dashboard,
    transaction,
    users
  };
}
export default connect(mapStateToProps)(Dashboard);
