import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import { isMobile } from "react-device-detect";
import ImageViewModal from "./components/ImageViewModal/ImageViewModal";
import ViewPendingModal from "./components/ViewPendingModal/ViewPendingModal";
import ViewAddharModal from "./components/ViewAddharModal/ViewAddharModal";
import ViewPanModal from "./components/ViewPanModal/ViewPanModal";
import ViewSelfieModal from "./components/ViewSelfieModal/ViewSelfieModal";
import moment from 'moment'
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from "react-icons/tb";




class LeadManagement extends Component {
  constructor(props) {
    super(props);
    // this.kycSubmit = this.kycSubmit.bind(this);
    // this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldskyc: {},
      errorskyc: {},
      viewImageData: {},
      viewRowData: {},
      viewSelfieData: {},
      viewPanData: {},
      viewAddharData: {},
      fieldsHoliday: {},
      errorsHoliday: {},
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      addPaymentField: false,
      viewImageCreateModal: false,
      viewMoreDetailsModal: false,
      viewSelfieDetailsModal: false,
      viewPanDetailsModal: false,
      viewAddharDetailsModal: false,
    }
  }
  componentDidMount() {
    let temp = {
      "currentDate": 0,
      "nextDate": 0,
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserRefList(temp));
    // this.props.dispatch(userActions.getKYC());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        fieldsHoliday: {},
        errorsHoliday: {},
        addPaymentField: false

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }

  handleSelfieModal = (data) => {
    this.setState({ viewSelfieDetailsModal: true, viewSelfieData: data });
  }

  handleSelfieClose = () => {
    this.setState({ viewSelfieDetailsModal: false, viewSelfieData: {} });
  }

  handlePanModal = (data) => {
    this.setState({ viewPanDetailsModal: true, viewPanData: data.kycDocList });
  }

  handlePanClose = () => {
    this.setState({ viewPanDetailsModal: false, viewPanData: {} });
  }

  handleAddharModal = (data) => {
    // console.log("hsdjkfhsd82345r23:", data.kycDocList);
    this.setState({ viewAddharDetailsModal: true, viewAddharData: data });
  }

  handleAddharClose = () => {
    this.setState({ viewAddharDetailsModal: false, viewAddharData: {} });
  }

  handleMoreDetailsModal = (data) => {
    this.setState({ viewMoreDetailsModal: true, viewRowData: data.userId });
  }

  handleMoreDetailsClose = () => {
    this.setState({ viewMoreDetailsModal: false, viewRowData: {} });
  }

  imageOpenCreateModal = (data) => {
    console.log("imageOpenCreateModal data::::", data);
    this.setState({ viewImageCreateModal: true, viewImageData: data });
  }

  viewhandleClose = (data) => {
    this.setState({ viewImageCreateModal: false, viewImageData: {} });
  }

  handlePayAmount = (data) => {
    console.log("imageOpenCreateModal data::::", data);
    this.setState({ addPaymentField: true, viewImageData: data });
  }

  handlePayAmountClose = (data) => {
    this.setState({ addPaymentField: false, viewImageData: {} });
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "status": "0",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserRefList(datatemp));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserRefList(data));
  }


  onAcceptUser = (data) => {
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let tempdata = {
      "id": data.id,
      "status": 2,
    }
    confirmAlert({
      title: 'Confirm to Reject the User ?',
      message: 'Are you sure to Reject ' + data.name,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.changeStatusUserRef(tempdata, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  onRegisterUser = (data, status) => {
    console.log('data111111111111', data)
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let tempdata = {
      // "id": data.id,
      "email": data.email,
      "mobNo": data.mobNo,
      "name": data.name,
      "address": data.address,
      "refByCode": data.userId.refByCode,
    }
    let response = {
      "id": data.id,
      "status": 1,
    }
    confirmAlert({
      title: 'Confirm to Register?',
      message: 'Are you sure to Register of  ' + data.name,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.registerUserByAdmin(tempdata, paginationData, response))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  handleChangeCommentInput = (event) => {
    let { value } = event.target;
    this.setState({ comment: value })
  }

  inputHolidayChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsHoliday = this.state.fieldsHoliday;
    let errorsHoliday = this.state.errorsHoliday;
    fieldsHoliday[name] = value;
    errorsHoliday[name] = "";
    console.log(name, value);
    this.setState({ fieldsHoliday, errorsHoliday });
  }
  createHolidaySubmit = () => {
    const reqData = {

      id: this.state.viewImageData.id,
      amount: this.state.fieldsHoliday.amount
    }
    let temp = {
      "status": "0",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    console.log("reqDatareqDatareqData", reqData);
    this.props.dispatch(userActions.addAmountReferralUser(reqData, temp));

  };

  previousDateChangeSubmit = (data) => {
    let temp = {
      "currentDate": data,
      "nextDate": 0,
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserRefList(temp));

  };
  nextDateChangeSubmit = (data) => {
    let temp = {
      "currentDate": data,
      "nextDate": 1,
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserRefList(temp));

  };
  onChangeChangeSubmit = (e) => {
    e.preventDefault();
    let temp = {
      "calenderData": e.target.value,
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserRefList(temp));

  };

  render() {

    let { users } = this.props;
    let { getUserRefList, getUserRefTotal, loading, getUserRefDate } = users;

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="flex-1 relative ">
            <div className="2xl:p-10 sm:p-5 p-3">
              <div className="max-w-screen-3xl mx-auto ">
                {/* Transaction Section */}
                <section className="bg-white col-span-6 2xl:py-7 py-5 2xl:px-10 sm:px-5 px-2 flex-col rounded-md">
                  <div className="w-full flex justify-between items-center">
                    <div className="">
                      <h3 className=" md:text-2xl text-lg leading-6 md:leading-9  font-semibold text-dark-400 tracking-widest">Referral Management</h3>
                    </div>
                    <div className='flex items-center space-x-1'>
                      <TbLayoutSidebarLeftCollapse size={50} onClick={() => this.previousDateChangeSubmit(getUserRefDate)} />
                      <label className='text-xl font-bold'>{moment(new Date(parseInt(getUserRefDate))).utcOffset("+05:30").format("MMMM YYYY")}</label>
                      <TbLayoutSidebarLeftExpand size={50} onClick={() => this.nextDateChangeSubmit(getUserRefDate)} />
                    </div>
                    <div className='flex items-center'>
                      <input className="appearance-none lg:w-36 w-full px-6 py-1.5 rounded-full border  placeholder-gray-800 text-dark-400 focus:outline-none  focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5" id="date" name='date' placeholder="date" type="month" required onChange={this.onChangeChangeSubmit} />
                    </div>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4 shadow-md">
                    <div className="overflow-x-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Referred by</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Referred To </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Class</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Phone No.</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">address</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Status</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">amount</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Date</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-black capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>

                            {/* Table Row Section */}
                            <tbody>
                              {
                                getUserRefList && getUserRefList.length > 0 ?
                                  getUserRefList.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.userId && element.userId.firstName ? element.userId.firstName + " " + element.userId.lastName : element && element.userId && element.userId.fullName ? element.userId.fullName : null}</td>

                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.name ? element.name : '-'}</td>

                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.classId && element.classId.name ? element.classId.name : '-'}</td>

                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.mobNo ? element.mobNo : '-'}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.address ? element.address : '-'}</td>
                                      {element && element.status == 1 ?
                                        < td className="px-6 py-3 whitespace-nowrap text-center text-sm text-yellow-400">
                                          Pending
                                        </td>
                                        :
                                        element && element.status == 2 ?
                                          <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-red-400">
                                            Rejected
                                          </td>
                                          :
                                          element && element.status == 3 ?
                                            <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-green-400">
                                              Approved
                                            </td>
                                            :
                                            <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-yellow-400">
                                              Pending
                                            </td>
                                      }
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {element && element.amount ? element.amount : '0'}</td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format(" MMMM D YYYY")}</td>


                                      <div className='flex flex-col justify-center items-center '>
                                        <td className="  justify-center items-center flex  py-3 whitespace-nowrap text-sm text-gray-600 ">

                                          {element && element.status == 0 ?
                                            <span className="inline-block pl-1">
                                              <button className="bg-[#25BF63] px-2 py-1 w-20 text-white font-bold rounded-full"
                                                onClick={() => this.onRegisterUser(element)}
                                              >Accept</button>
                                            </span>
                                            :
                                            null}

                                        </td>
                                        <td className="  justify-center items-center flex  py-3 whitespace-nowrap text-sm text-gray-600 ">

                                          {element && !element.status ?
                                            <>
                                              <span class="inline-block pl-1">
                                                <button class="bg-[#FC0000] px-2 py-1 w-20 text-white font-bold rounded-full" onClick={() => this.onAcceptUser(element)}>Reject </button>
                                              </span>
                                            </> :
                                            element && element.status === 1 ?
                                              <td className="  justify-center items-center flex  py-3 whitespace-nowrap text-sm text-gray-600 ">

                                                <span class="inline-block pl-1" >
                                                  <button class="bg-[#25BF63] px-2 py-1 w-20 text-white font-bold rounded-full" onClick={() => this.handlePayAmount(element)}>Due</button>
                                                </span>
                                              </td>
                                              :
                                              element && element.status === 3 ?
                                                <td className="  justify-center items-center flex  py-3 whitespace-nowrap text-sm text-gray-600 ">
                                                  <span class="inline-block pl-1" >
                                                    <button class="bg-[#25BF63] px-2 py-1 w-20 text-white font-bold rounded-full" disabled>Paid</button>
                                                  </span>
                                                </td>
                                                :
                                                <span class="inline-block pl-1">
                                                  <button class="bg-[#FC0000] px-2 py-1 w-20 text-white font-bold rounded-full" disabled>Rejected </button>
                                                </span>
                                          }
                                        </td>

                                      </div>


                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            getUserRefTotal && getUserRefTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={getUserRefTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            getUserRefTotal && getUserRefTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={getUserRefTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div >
          </main >
        </div >

        <DialogExample />

        <ImageViewModal
          viewImageCreateModal={this.state.viewImageCreateModal}
          //classes={classes}
          viewImageData={this.state.viewImageData}
          viewhandleClose={this.viewhandleClose}

        />

        <ViewPendingModal
          addPaymentField={this.state.addPaymentField}
          fieldsHoliday={this.state.fieldsHoliday}
          errorsHoliday={this.state.errorsHoliday}
          viewRowData={this.state.viewRowData}
          inputHolidayChange={this.inputHolidayChange}
          createHolidaySubmit={this.createHolidaySubmit}
          handleMoreDetailsClose={this.handlePayAmountClose}

        />

        <ViewAddharModal
          viewAddharDetailsModal={this.state.viewAddharDetailsModal}
          viewAddharData={this.state.viewAddharData}
          handleAddharClose={this.handleAddharClose}

        />

        <ViewPanModal
          viewPanDetailsModal={this.state.viewPanDetailsModal}
          viewPanData={this.state.viewPanData}
          handlePanClose={this.handlePanClose}

        />

        <ViewSelfieModal
          viewSelfieDetailsModal={this.state.viewSelfieDetailsModal}
          viewSelfieData={this.state.viewSelfieData}
          handleSelfieClose={this.handleSelfieClose}

        />

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, kyc } = state;
  return {
    users,
    kyc,
    authentication
  };
}
export default connect(mapStateToProps)(LeadManagement);
