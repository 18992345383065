import React, { Component } from 'react';
import { connect } from 'react-redux';
import { tutionActions, userActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';
import moment from 'moment'

import { FaArrowCircleRight } from "react-icons/fa";
class CompleteTution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldstution: {},
      errorstution: {},
      fieldstutionUpdate: {},
      errorstutionUpdate: {},
      allDataValue: {},
      moreDetailsHandel: false,
      moreDetailsHandel1: false,
      tutionCreateModal: false,
      tutionUpdateModal: false,
      companyTotalShare: 0,
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      formData: {
        "name": "",
        "days": "",
        "per": "",
      },
      updateformData: {
        "id": "",
        "name": "",
        "days": "",
        "per": "",
      },
      fieldModelData: {
        "class": "",
        "course": "",
        "startTime": "",
        "endTime": "",
        "teachingLocation": "",
        "classReference": "",
        "cityId": "",
        "pinCodeId": "",
        "date": "",
        "board": "",
        "fullAddress": "",
        "landmark": "",
        "remark": "",
        "studentId": "",
        "teachingMode": "",
        "requiredGender": ""
      }
    }
  }
  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getCloseClassesListAdmin(temp));

    let data1 = {
      "userType": "STUDENT",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActiveStudent(data1));


    this.props.dispatch(cityActions.getBoardList());
    let data = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActive(data));
    this.props.dispatch(tutionActions.getAllteacherCouresId(temp));
    this.props.dispatch(tutionActions.getAllStudentId(temp));
    this.props.dispatch(tutionActions.getAllTutorId(temp));
    this.props.dispatch(cityActions.getAllClass(temp));




  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.tution.addUserSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "key": "",
        },
        tutionCreateModal: false,
        tutionUpdateModal: false,
        moreDetailsHandel1: false,
        fieldstution: {},
        errorstution: {},
        fieldstutionUpdate: {},
        errorstutionUpdate: {},
        fieldModelData: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }



  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldstution = this.state.fieldstution;
    let errorstution = this.state.errorstution;
    fieldstution[name] = value;
    errorstution[name] = "";
    console.log(name, value);
    this.setState({ fieldstution, errorstution });
  }

  inputChangeUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldstutionUpdate = this.state.fieldstutionUpdate;
    let errorstutionUpdate = this.state.errorstutionUpdate;
    fieldstutionUpdate[name] = value;
    errorstutionUpdate[name] = "";
    this.setState({ fieldstutionUpdate, errorstutionUpdate });
    console.log('valuevaluevaluevaluevalue', value)
  }
  openModelHandel = (data) => {
    this.setState({ moreDetailsHandel: true, allDataValue: data })
  }
  closeModelHandel = () => {
    this.setState({ moreDetailsHandel: false, allDataValue: {} })
  }
  openModelHandel1 = (data) => {
    this.setState({ moreDetailsHandel1: true, allDataValue: data, fieldModelData: data })
  }
  closeModelHandel1 = () => {
    this.setState({ moreDetailsHandel1: false, allDataValue: {} })
  }
  addFeeDetailsPageTutor = (data) => {
    this.props.history.push(`/app/tutorFee/${data.id}/${data.tutorId.id}`)
  }
  addFeeDetailsPageStudent = (data) => {
    this.props.history.push(`/app/studentFee/${data.id}/${data.studentId.id}`)
  }

  handelOnChangeModel = (e) => {
    const { name, value } = e.target;
    console.log("ddddddddddddddddddddddd", name, value);

    this.setState({
      fieldModelData: {
        ...this.state.fieldModelData,
        [name]: value
      }
    });
  };


  handelOnSubmitModel = () => {
    // console.log("///////////////////////", this.state.fieldModelData)
    this.props.dispatch(tutionActions.sendDemoRequest({
      "class": this.state.fieldModelData["classId"].id,
      "course": this.state.fieldModelData["teacherCouresId"][0].id,
      "startTime": this.state.fieldModelData.startTime,
      "endTime": this.state.fieldModelData.endTime,
      "teachingLocation": this.state.fieldModelData.endTime,
      "classReference": this.state.fieldModelData.endTime,
      "cityId": this.state.fieldModelData["studentId"].city,
      "pinCodeId": this.state.fieldModelData["studentId"].pinCode[0],
      "date": this.state.fieldModelData.date,
      "board": this.state.fieldModelData["board"],
      "fullAddress": this.state.fieldModelData.fullAddress,
      "landmark": this.state.fieldModelData["landmark"],
      "remark": this.state.fieldModelData.remark,
      "studentId": this.state.fieldModelData["studentId"].id,
      "teachingMode": this.state.fieldModelData.teachingMode,
      "requiredGender": this.state.fieldModelData.requiredGender
    }))
  }

  handleOpenhandleOpenAddAttendance = (data) => {
    this.props.history.push('/app/Attendance/' + data.id)
  };
  handleChangeInputInternal = (event) => {

    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    const { studentData } = this.state;
    studentData["studentId"] = output;
    this.setState({ studentData });

  };

  handleChangeInputclassId = (event) => {

    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    const { studentData } = this.state;
    studentData["classId"] = output;
    this.setState({ studentData });

  };

  handleChangeInputteacherCouresId = (event) => {

    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    const { studentData } = this.state;
    studentData["teacherCouresId"] = output;
    this.setState({ studentData });

  };

  handleChangeInputtutorId = (event) => {

    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    const { studentData } = this.state;
    studentData["tutorId"] = output;
    this.setState({ studentData });

  };

  classReferenceSubmit = () => {
    // e.preventDefault();
    console.log('this.state.fieldstution', this.state.fieldstution);

    let temp1 = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "classId": this.state.studentData['classId'] && this.state.studentData['classId'].length > 0 ? this.state.studentData['classId'] : null,
      "teacherCouresId": this.state.studentData['teacherCouresId'] && this.state.studentData['teacherCouresId'].length > 0 ? this.state.studentData['teacherCouresId'] : null,
      "tutorId": this.state.studentData['tutorId'] && this.state.studentData['tutorId'].length > 0 ? this.state.studentData['tutorId'] : null,
      "dueDate": this.state.fieldstution && this.state.fieldstution['deuDate'] ? Number(this.state.fieldstution['deuDate']) : null,
      "classReference": this.state.fieldstution && this.state.fieldstution['classReference'] ? this.state.fieldstution['classReference'] : null,
      "status": "ONGOIN",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getTutionList(temp1));
  }
  resetDataRes = (data) => {
    this.props.history.push('/app/completeTution')
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected + 1 });

    let temp1 = {
      "studentId": this.state.studentData && this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "classId": this.state.studentData && this.state.studentData['classId'] && this.state.studentData['classId'].length > 0 ? this.state.studentData['classId'] : null,
      "teacherCouresId": this.state.studentData && this.state.studentData['teacherCouresId'] && this.state.studentData['teacherCouresId'].length > 0 ? this.state.studentData['teacherCouresId'] : null,
      "tutorId": this.state.studentData && this.state.studentData['tutorId'] && this.state.studentData['tutorId'].length > 0 ? this.state.studentData['tutorId'] : null,
      "dueDate": this.state.studentData && this.state.fieldstution && this.state.fieldstution['deuDate'] ? Number(this.state.fieldstution['deuDate']) : null,
      "classReference": this.state.fieldstution && this.state.fieldstution['classReference'] ? this.state.fieldstution['classReference'] : null,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getCloseClassesListAdmin(temp1));
  }

  render() {

    let { tution, city, users } = this.props;
    let { campleteItems, campleteTotal, getAllteacherCouresId, loading } = tution;
    let { getAllClass } = city;
    let { getUserList, getUserStudentList } = users;
    let NameRes = getUserStudentList && getUserStudentList.length ? getUserStudentList.map((ele, index) => ({ value: ele.id, label: ele.firstName + " " + ele.lastName + " " + ele.mobNo })) : null
    let classRes = getAllClass && getAllClass.length ? getAllClass.map((ele, index) => ({ value: ele.id, label: ele.name })) : null
    let teacherCouresRes = getAllteacherCouresId && getAllteacherCouresId.length ? getAllteacherCouresId.map((ele, index) => ({ value: ele.id, label: ele.name })) : null
    let tutorRes = getUserList && getUserList.length ? getUserList.map((ele, index) => ({ value: ele.id, label: ele.fullName + " " + ele.mobNo })) : null
    console.log('campleteItems_________________', campleteItems);
    return (
      <>
        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {/* Main Content */}
        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">Completed Tuitions ({campleteTotal})</h3>
                    </div>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <thead className="bg-[#fff] rounded-t">
                            <tr className=" ">
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>Student name or Number</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputInternal}
                                    options={NameRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>Class</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputclassId}
                                    options={classRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>Subject</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputteacherCouresId}
                                    options={teacherCouresRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>Tutor</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputtutorId}
                                    options={tutorRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              </th>
                              <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              </th>
                              <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <label className='text-transparent' for="">djcfb</label>
                                <div className="relative">
                                  <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                  </div>
                                  <input className="appearance-none sm:w-40 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="deuDate" name='deuDate' placeholder="Due date.." type="text" onChange={this.inputChange} required />
                                </div>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <label className='text-transparent' for="">djcfb</label>
                                <div className="relative">
                                  <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                  </div>
                                  <input className="appearance-none sm:w-40 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="classReference" name='classReference' placeholder="Class reference.." type="text" onChange={this.inputChange} required />
                                </div>
                              </th>
                              <th>
                                <label className='text-transparent' for="">djcfb</label>
                                <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.classReferenceSubmit()}> Search </button>
                              </th>
                              <th>
                                <label className='text-transparent' for="">djcfb</label>
                                <button className="bg-[#25BF63] hover:bg-[#25BF63] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.resetDataRes()}> Reset </button>
                              </th>
                            </tr>
                          </thead>
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">Name & Mobile Number </th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Class & Subject</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Class Reference</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Assigned Tutor</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Tuition Fee</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Start Date</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">End Date</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Tutor's Fee</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Reference Fee</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Status</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                campleteItems && campleteItems.length > 0 ?
                                  campleteItems.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap  text-sm text-gray-600 flex items-center space-x-2 pt-10">
                                        <div className='w-12 h-12'>
                                          <img
                                            class="w-12 h-12 rounded-full"
                                            src={element && element.studentId && element.studentId.imageLinkUrl ? element.studentId.imageLinkUrl : element && element.studentId && element.studentId.gender && element.studentId.gender == "Male" ? "images/boyImageLogo.png" : element && element.studentId && element.studentId.gender && element.studentId.gender == "Female" ? "images/girlImageLogo.png" : "images/maleFemaleIcon.png"}
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <p>{element && element.studentId && element.studentId.firstName ? element.studentId.firstName : "-"} {element && element.studentId && element.studentId.lastName ? element.studentId.lastName : "-"}</p>
                                          <p> {element && element.studentId && element.studentId.mobNo ? element.studentId.mobNo : "-"}</p>
                                          <div className='flex items-center justify-end' onClick={() => this.openModelHandel(element)}><span>More</span>  <span className='text-[#FBB00C] pl-1'><FaArrowCircleRight size={16} /> </span></div>
                                        </div>

                                      </td>
                                      <td className="px-3 py-3  text-center text-sm text-gray-600 ">
                                        <p className=''>{element && element.classId && element.classId.name ? element.classId.name : "-"}</p>
                                        <p className=''>{element && element.teacherCouresId && element.teacherCouresId[0] && element.teacherCouresId[0].name ? element.teacherCouresId[0].name : "-"}</p>
                                      </td>
                                      <td className="px-3 py-3  text-center text-sm text-gray-600 ">
                                        <p className=''>{element.classReference}</p>
                                      </td>
                                      <td className="px-3 py-3  text-center text-sm text-gray-600 ">
                                        <p className=''>{element && element.tutorId && element.tutorId.fullName ? element.tutorId.fullName : "-"} </p>
                                        <p className=''>{element && element.tutorId && element.tutorId.mobNo ? element.tutorId.mobNo : "-"}</p>
                                      </td>
                                      <td className="px-3 py-3  text-center text-sm text-gray-600 ">
                                        <p className=''>{element.fee}/- Monthly</p>
                                      </td>
                                      <td className="px-3 py-3  text-center text-sm text-gray-600 ">
                                        <p className=''>{element.startDate}</p>
                                      </td>
                                      <td className="px-3 py-3  text-center text-sm text-gray-600 ">
                                        {element && element.endDate && element.endDate > 0 ?
                                          <p className=''>{moment(new Date(parseInt(element && element.endDate))).utcOffset("+05:30").format("MMM D YYYY")}</p> : '-'}
                                      </td>
                                      <td className="px-3 py-3  text-center text-sm text-gray-600 ">
                                        <p className=''>{element.tutorFee}/- Monthly</p>
                                      </td>
                                      <td className="px-3 py-3  text-center text-sm text-gray-600 ">
                                        <p className=''>{element.refFee}/- Monthly</p>
                                      </td>
                                      <td className="px-3 py-3  text-center text-sm text-red-600 ">
                                        <p className=''>Closed</p>
                                      </td>
                                      <td className="px-3 py-3  text-center text-sm text-gray-600 space-y-1">
                                        <div>
                                          <button type="button" className='bg-[#213360] px-2 py-1 w-40 text-white font-bold rounded-full capitalize' onClick={() => this.handleOpenhandleOpenAddAttendance(element)}>View Attendence</button>
                                        </div>
                                        <div onClick={() => this.disableUser(element)}>
                                          <button type="button" className='bg-[#FBB00C] px-2 py-1 w-40 text-black font-bold rounded-full capitalize' onClick={() => this.openModelHandel1(element)}>Send Demo Request</button>
                                        </div>
                                        <div onClick={() => this.disableUser(element)}>
                                          <button type="button" className='bg-[#000] px-2 py-1 w-40 text-white font-bold rounded-full capitalize' onClick={() => this.addFeeDetailsPageStudent(element)}>student fee history</button>
                                        </div>
                                        <div onClick={() => this.disableUser(element)}>
                                          <button type="button" className='bg-[#38B6FF] px-2 py-1 w-40 text-black font-bold rounded-full capitalize' onClick={() => this.addFeeDetailsPageTutor(element)}>Tutor's fee history</button>
                                        </div>

                                      </td>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <nav className="relative z-0 flex justify-end mt-5">
                      {
                        campleteTotal && campleteTotal > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={campleteTotal / this.state.size}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>


                  </div>
                </section>
              </div>
            </div>
            {this.state.moreDetailsHandel ?
              <>

                <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                  <div className="border border-yellow-500 modal-container bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/3 mx-auto rounded-[40px] shadow-lg z-50 overflow-y-auto">
                    <div className="modal-content ">
                      <div className="flex justify-between items-center modal-close cursor-pointer z-50 py-2 px-6 bg-[#FBB00C]">
                        <p className='font-bold text-md'>More</p>
                        <div className='bg-white rounded-full p-1'>
                          <svg
                            onClick={() => this.closeModelHandel()}
                            className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                            </path>
                          </svg>
                        </div>
                      </div>

                      <div className='py-4 px-6 h-[400px] overflow-y-auto divide-y-2'>
                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Student name :</p>
                          <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.firstName ? this.state.allDataValue.studentId.firstName
                            : "NA"} {"  "}{this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.lastName ? this.state.allDataValue.studentId.lastName
                              : "NA"}</p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Student mob No. : </p>
                          <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.mobNo ? this.state.allDataValue.studentId.mobNo
                            : "NA"} </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Address : </p>
                          <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianAddress ? this.state.allDataValue.studentId.studentInfoId.guardianAddress
                            : "NA"} </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Guardian Name :</p>
                          <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianName ? this.state.allDataValue.studentId.studentInfoId.guardianName : "-"} </p>
                        </div>
                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Guardian Mob No. :</p>
                          <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianConNo ? this.state.allDataValue.studentId.studentInfoId.guardianConNo : "-"} </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Tutor name :</p>
                          <p > {this.state.allDataValue && this.state.allDataValue.tutorId && this.state.allDataValue.tutorId.fullName ? this.state.allDataValue.tutorId.fullName : "NA"}   </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Tutor mob No. : </p>
                          <p > {this.state.allDataValue && this.state.allDataValue.tutorId && this.state.allDataValue.tutorId.mobNo ? this.state.allDataValue.tutorId.mobNo
                            : "NA"} </p>
                        </div>

                        <div className='flex justify-between items-start w-full'>
                          <div className='w-full'>
                            <p className='text-lg font-bold'>Subject:</p>
                          </div>
                          <div className='w-full'>
                            <ul className='text-right'>
                              {this.state.allDataValue && this.state.allDataValue.teacherCouresId && this.state.allDataValue.teacherCouresId.length > 0 ?
                                this.state.allDataValue.teacherCouresId.map((element) =>
                                  <>
                                    <li className='flex flex-col'>{element && element.name ? element.name : "NA"} </li>
                                  </>
                                ) : 'NA'}
                            </ul>
                          </div>
                        </div>



                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Class:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.classId && this.state.allDataValue.classId.name ? this.state.allDataValue.classId.name : "NA"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Class in month:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.monthInDay ? this.state.allDataValue.monthInDay : "NA"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Total fee:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.fee ? this.state.allDataValue.fee : "0"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Tutor fee:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.tutorFee ? this.state.allDataValue.tutorFee : "0"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Reference fee:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.refFee ? this.state.allDataValue.refFee : "0"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Company share:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.companyShare ? this.state.allDataValue.companyShare : "0"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Start date:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.startDate ? this.state.allDataValue.startDate : "0"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Payment mode:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.paymentMode ? this.state.allDataValue.paymentMode : "NA"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Create tution date:</p>
                          <p >{moment(new Date(parseInt(this.state.allDataValue && this.state.allDataValue.createdAt ? this.state.allDataValue.createdAt : "NA"))).utcOffset("+05:30").format("DD-MMM-YY")}
                          </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Class status:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.isCloseClass ? "Complete class" : "On going class"}
                          </p>
                        </div>

                      </div>


                    </div>
                  </div>
                </div>
              </>
              :
              null
            }
            {this.state.moreDetailsHandel1 ?
              <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                <div className="border border-[#FBB00C]  modal-container bg-white lg:w-[500px] w-11/12 mx-auto rounded-[40px]  z-50 overflow-y-auto">
                  <div className="modal-content py-4 text-left px-6 space-y-2">
                    <div className="modal-close cursor-pointer z-50 bg-[#213360] hover:bg-white hover:text-[#213360] text-white rounded-full w-6 h-6 flex justify-center items-center transition-all duration-200 ease-in-out">
                      <svg
                        onClick={() => this.closeModelHandel1()}
                        className="fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                      </svg>
                    </div>
                    <div className='bg-[#E8E8E8] w-full p-4 lg:h-full h-[500px] overflow-y-auto mx-auto'>
                      <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                        <p>Preferred Time :</p>
                        <div className='flex items-center'>
                          <div className='flex flex-col'>
                            <label for="">Start</label>
                            <input className="py-1 px-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-28 ease-linear transition-all duration-150"
                              id="" name="startTime" type="time" value={this.state.fieldModelData.startTime} onChange={this.handelOnChangeModel} />
                          </div>
                          <div className='flex flex-col'>
                            <label for="">End</label>
                            <input className="py-1 px-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-28 ease-linear transition-all duration-150"
                              id="" name="endTime" type="time" value={this.state.fieldModelData.endTime} onChange={this.handelOnChangeModel} />
                          </div>
                        </div>
                      </div>
                      <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                        <p>Date :</p>
                        <input className="py-1 px-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-56 ease-linear transition-all duration-150"
                          id="firstName" name="date" type="date" onChange={this.handelOnChangeModel} />
                      </div>
                      <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                        <p>Full Address :</p>
                        <input className="py-1 px-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-56 ease-linear transition-all duration-150"
                          id="firstName" name="firstName" placeholder="Address .." type="text" />
                      </div>
                      <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                        <p>Landmark :</p>
                        <input className="py-1 px-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-56 ease-linear transition-all duration-150"
                          id="firstName" name="landmark" placeholder="Landmark" type="text" />
                      </div>
                      <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                        <p>Remark :</p>
                        <input className="py-1 px-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-56 ease-linear transition-all duration-150"
                          id="firstName" name="firstName" placeholder="Remark" type="text" />
                      </div>
                      <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                        <p>Student Name:</p>
                        <input className="py-1 px-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-56 ease-linear transition-all duration-150"
                          id="firstName" name="firstName" placeholder="Name..." type="text" value={this.state.fieldModelData["studentId"].firstName} />
                      </div>
                      <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                        <p>Teaching Mode :</p>
                        <select class="form-select border-1 px-1 py-1 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow w-56 ease-linear transition-all duration-150" name='teachingMode' value={this.state.fieldModelData.teachingMode} onChange={this.handelOnChangeModel}>
                          <option>Please Select Teaching Mode</option>
                          <option value="Home Tuition">Home Tuition</option>
                          <option value="Online Tuition">Online Tuition</option>
                        </select>
                      </div>
                      <div className='md:flex justify-between items-center text-[#213360] space-y-1'>
                        <p>Required Gender :</p>
                        <select class="form-select border-1 px-1 py-1 placeholder-gray-400 text-gray-600 bg-white rounded text-sm shadow w-56 ease-linear transition-all duration-150" name='requiredGender' value={this.state.fieldModelData.requiredGender} onChange={this.handelOnChangeModel}>
                          <option>Please Select Required Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="No Preferrence">No Preferrence</option>
                        </select>
                      </div>
                      <div className='flex justify-center items-center py-2'>
                        <button class="bg-[#213360]  py-1.5 text-sm shadow-sm w-40 font-bold tracking-wider border text-white rounded-full hover:shadow-lg" onClick={this.handelOnSubmitModel} >Submit </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              :
              null
            }





          </main>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, tution, city } = state;
  return {
    users,
    tution, city,
    authentication
  };
}
export default connect(mapStateToProps)(CompleteTution);
