import React, { Component } from 'react';
import {
  dashboardActions, transactionActions, userActions, teachcourseActions, tutionActions, supportActions
} from '../../_actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import AddAdminBalanceModal from "./components/AddAdminBalanceModal/AddAdminBalanceModal";
import UpdateAminPassModal from "./components/UpdateAminPassModal/UpdateAminPassModal";
import { HiUserGroup, HiOutlineTrendingUp, HiOutlineNewspaper } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";
// import ZoomableImage from './ZoomableImage'
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { MdOutlineRemoveRedEye } from "react-icons/md";


class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      fieldsAdminUser: {},
      errorsAdminUser: {},
      fieldsAdminPass: {},
      errorsAdminPass: {},
      adBalanceModal: false,
      adminPassModal: false,
      zoomLevel: 1,
    }
  }

// Fetching initial data
fetchInitialData = () => {
  const { pageNo, size, keyWord } = this.state;
  
  const temp1 = { "status": "0", "keyWord": "", "pageNo": pageNo, "size": 5 };
  const temp = { "status": "0", "keyWord": "", "pageNo": pageNo, "size": size };
  const newData = { "keyWord": "", "pageNo": 1, "size": 4 };
  const newDataMonth = { "keyWord": "", "pageNo": 1, "size": 10 };
  const resData = { "isTakenDemo": 2, "status": 1, "keyWord": keyWord, "pageNo": pageNo, "size": size };
  const tempTest = { "isTuitionOn": "yes", "keyWord": "", "pageNo": pageNo, "size": size };
  const tempTime = { "date": "A", "keyWord": "", "pageNo": pageNo, "size": size };
  const tempRes = { "userType": "STUDENT", "keyWord": "", "pageNo": pageNo, "size": size };

  this.props.dispatch(userActions.getUserRefList(temp1));
  this.props.dispatch(teachcourseActions.getFilteredDemoClassList(resData));
  this.props.dispatch(dashboardActions.todayDemoPendingList(newData));
  this.props.dispatch(transactionActions.getUserTxList(temp));
  this.props.dispatch(dashboardActions.totalTuitionEarningMonthWasi(newDataMonth));
  this.props.dispatch(supportActions.getTicketList(tempTime));
  this.props.dispatch(userActions.getTuitionListByDate(tempRes));
  this.props.dispatch(tutionActions.getTutionList(tempTest));
}

componentDidMount() {
  this.fetchInitialData();
}

// static getDerivedStateFromProps(nextProps, prevState) {
//   // Check for admin balance success and update state accordingly
//   if (nextProps.dashboard.adminBalanceSuccess) {
//     return {
//       ...nextProps,
//       fieldsAdminUser: {},
//       errorsAdminUser: {},
//       adBalanceModal: false,
//       adminPassModal: false,
//     };
//   } else {
//     return null; // No state update necessary
//   }
// }






  handleOpenAddTuitionModal = () => {
    this.props.history.push('/app/kycpending')
  };
  handleOpenAddTuitionModalAcceptDemo = () => {
    this.props.history.push('/app/accepteddemoclass')
  };
  handleOpenAddTuitionModalPaymentManagementSudent = () => {
    this.props.history.push('/app/paymentManagementstudent')
  };
  handleOpenAddTuitionModalPendingDemo = () => {
    this.props.history.push('/app/pendingdemoclass')
  };
  handleOpenAddTuitionModalCompletedDemo = () => {
    this.props.history.push('/app/DemoClassTutorAppliedComplete')
  };
  handleOpenAddTuitionModalLeadDemo = () => {
    this.props.history.push('/app/leadManagement')
  };
  handleOpenAddSuppoet = () => {
    this.props.history.push('/app/support')
  };
  handleOpenAddTuitionModalLeadDemo = () => {
    this.props.history.push('/app/leadManagement')
  };
  handleOpenAddTuitionModalearningManagement = () => {
    this.props.history.push('/app/earningManagement')
  };


  render() {

    let { dashboard, transaction, users, teachcourse, tution, support } = this.props;
    let { items } = transaction;
    let { getUserRefList, getTuitionDateWasiList } = users;
    let { itemsTeachCourseRes } = teachcourse;
    let { tuitionItemsDashboard } = tution;
    let { dashboardSupportData } = support;
    const { todayDemoPendingList, totalTuitionEarningMonthWasi } = dashboard
    // console.log('tuitionItemsDashboard___________________________', dashboardSupportData);

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', { day: 'numeric' });
    const currentMonthFormatted = moment().format('MMMM YYYY');


    // console.log("i am in render::", adminDetails);

    return (
      <>
        <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
          <div className="relative">
            <div className="relative z-20 flex min-h-screen">
              <div className="flex w-full overflow-hidden ">

                <div className="flex flex-col flex-1 overflow-hidden">
                  <main className="relative flex-1 ">
                    <div className="p-3 2xl:p-10 sm:p-5">
                      <div className="mx-auto max-w-screen-3xl">

                        <sectiion className="flex justify-between my-4 ">

                          <div>
                            <p className='text-2xl font-bold'>Dashboard</p>
                          </div>

                        </sectiion>
                        <section className="grid grid-cols-1 gap-5 lg:grid-cols-6">
                          <div className="grid gap-4 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 2xl:col-span-7 md:col-span-12">

                            <div className='bg-white rounded-[16px]  drop-shadow-xl'>
                              <div className='flex justify-between items-center rounded-t-[16px] bg-[#D59A20] p-4 '>
                                <p className='font-semibold text-white  '>Pending For Registration</p>
                                <span className='cursor-pointer' onClick={() => this.handleOpenAddTuitionModalAcceptDemo()}><IoIosArrowForward className='text-xl text-white' /></span>
                              </div>
                              <div className='p-4 space-y-3' >
                                {
                                  tuitionItemsDashboard && tuitionItemsDashboard.length > 0 ?
                                    tuitionItemsDashboard.slice(0, 4).map((element, index) =>
                                      <div className='flex justify-between items-center pb-1 border-b border-b-[#00000030] drop-shadow-none'>
                                        <div>
                                          <p className='text-base '>{element && element.studentId && element.studentId.firstName ? element.studentId.firstName : null} | Tutor: {element && element.tutorId && element.tutorId.fullName ? element.tutorId.fullName : null}</p>
                                          <p className='text-[#BF0E6C] text-[12px]'>Class: {element && element.classId && element.classId.name ? element.classId.name : null} | ₹ {element && element.tutorFee ? element.tutorFee : 0}/- | Start Date: {element && element.startDate ? element.startDate : null} </p>
                                        </div>
                                        <span><MdOutlineRemoveRedEye size={24} className='cursor-pointer' onClick={() => this.handleOpenAddTuitionModalAcceptDemo()} /></span>
                                      </div>
                                    ) :
                                    null
                                }
                              </div>


                            </div>
                            <div className='bg-white rounded-[16px]  drop-shadow-xl'>
                              <div className='flex justify-between items-center rounded-t-[16px] bg-[#D59A20] p-4'>
                                <p className='font-semibold text-white '>Today's Due Dates</p>
                                <span><IoIosArrowForward className='text-xl text-white cursor-pointer' onClick={() => this.handleOpenAddTuitionModalPaymentManagementSudent()} /></span>
                              </div>
                              <div className='p-4 space-y-3'>
                                {getTuitionDateWasiList && getTuitionDateWasiList && getTuitionDateWasiList.slice(0, 4).map((ele) => {
                                  return <div>
                                    {ele.isStatus == false && ele.dueDate == formattedDate ?
                                      <div className='flex justify-between items-center'>
                                        <p className='text-base '>{(ele && ele.studentId && ele.studentId.firstName ? ele.studentId.firstName : "") + " " + (ele && ele.studentId && ele.studentId.lastName ? ele.studentId.lastName : "")} | Tutor: {ele && ele.tutorId && ele.tutorId.fullName}| Tuition fee: {ele && ele.fee && ele.fee}
                                          <span className='text-green-500'> | Due Date: {ele.dueDate}</span>
                                        </p>
                                        {/* <span className='text-green-500'>Due Date: {ele.dueDate}</span> */}
                                      </div>
                                      :
                                      null}
                                  </div>
                                })}
                              </div>
                            </div>
                            <div className='bg-white rounded-[16px]   drop-shadow-xl'>
                              <div className='flex justify-between items-center  rounded-t-[16px] bg-[#D59A20] p-4'>
                                <p className='font-semibold text-white '>Pending KYC Request</p>
                                <span className='cursor-pointer' onClick={() => this.handleOpenAddTuitionModal()}><IoIosArrowForward className='text-xl text-white' /></span>
                              </div>
                              <div className='p-4 space-y-3'>
                                {items && items.length > 0 ? items.slice(0, 4).map((element, index) => {
                                  return <div >
                                    {element && element.userId && element.userId.interviewStatus != 2 ?
                                      <div className='flex justify-between items-center border-b border-b-[#00000030] pb-2'>
                                        <div className='flex items-center space-x-3 '>
                                          <img src={element.imageUserLink} alt="" className='w-12 h-12 rounded-full' />
                                          <div>
                                            <p className='text-base '>{element && element.userId && element.userId.fullName ? element.userId.fullName : '-'}</p>
                                            <p className='text-[#294d69] text-sm'>{element && element.userId && element.userId.mobNo ? element.userId.mobNo : '-'}</p>
                                          </div>
                                        </div>
                                        <span><MdOutlineRemoveRedEye className='cursor-pointer' size={24} onClick={() => this.handleOpenAddTuitionModal()} /></span>
                                      </div>
                                      : null}
                                  </div>
                                }) : null}
                              </div>


                            </div>
                            <div className='bg-white rounded-[16px]  drop-shadow-xl'>
                              <div className='flex justify-between items-center rounded-t-[16px] p-4 bg-[#D59A20] '>
                                <p className='font-semibold text-white '>Today's Demo Pending Request</p>
                                <span className='cursor-pointer' onClick={() => this.handleOpenAddTuitionModalPendingDemo()}><IoIosArrowForward className='text-xl text-white' /></span>
                              </div>
                              <div className='p-4 space-y-3 '>
                                {todayDemoPendingList && todayDemoPendingList && todayDemoPendingList.length > 0 ? todayDemoPendingList.slice(0, 4).map((ele) => {
                                  return <div className='flex justify-between items-center border-b border-b-[#00000030] pb-2 '>
                                    <div className=''>
                                      <p>{(ele && ele.studentId && ele.studentId.firstName ? ele.studentId.firstName : "") + " " + (ele && ele.studentId && ele.studentId.lastName ? ele.studentId.lastName : "")} </p>
                                      <p className='text-[#294d69] text-sm'>{ele && ele.studentId && ele.studentId.mobNo} | {ele && ele.class && ele.class.name} | {ele && ele.landmark} {ele && ele.cityId && ele.cityId.name} {ele && ele.pinCodeId && ele.pinCodeId.pinNo} </p>
                                    </div>
                                    <span><MdOutlineRemoveRedEye size={24} className='cursor-pointer' onClick={() => this.handleOpenAddTuitionModalPendingDemo()} /></span>
                                  </div>
                                }) :
                                  null}
                              </div>

                            </div>
                            <div className='bg-white rounded-[16px] drop-shadow-xl '>
                              <div className='flex justify-between items-center rounded-t-[16px] p-4 bg-[#D59A20]  '>
                                <p className='font-semibold text-white '>Completed Demo's</p>
                                <span className='cursor-pointer' onClick={() => this.handleOpenAddTuitionModalCompletedDemo()}><IoIosArrowForward className='text-xl text-white' /></span>
                              </div>
                              <div className='p-4 space-y-4'>
                                {itemsTeachCourseRes && itemsTeachCourseRes && itemsTeachCourseRes.slice(0, 4).map((ele) => {
                                  return <div className='flex justify-between items-center border-b border-b-[#00000030] pb-2'>
                                    <div>
                                      <p>{(ele && ele.studentId && ele.studentId.firstName ? ele.studentId.firstName : "") + " " + (ele && ele.studentId && ele.studentId.lastName ? ele.studentId.lastName : "")} | Tutor: {ele && ele.tutorId && ele.tutorId.fullName}</p>
                                      <p className='text-[#294d69] text-sm'>{ele && ele.studentId && ele.studentId.mobNo} | {ele && ele.class && ele.class.name} | {ele && ele.landmark} {ele && ele.cityId && ele.cityId.name}  {ele && ele.pinCodeId && ele.pinCodeId.pinNo}</p>
                                    </div>
                                    <span><MdOutlineRemoveRedEye size={24} className='cursor-pointer' onClick={() => this.handleOpenAddTuitionModalCompletedDemo()} /></span>
                                  </div>
                                })}
                              </div>

                            </div>
                            <div className='bg-white rounded-[16px]  lg:row-span-2 drop-shadow-xl'>
                              <div className='flex justify-between items-center  rounded-t-[16px] p-4 bg-[#D59A20]'>
                                <p className='font-semibold text-white '>Total Earning {currentMonthFormatted}</p>
                                <span className='cursor-pointer' onClick={() => this.handleOpenAddTuitionModalearningManagement()}><IoIosArrowForward className='text-white text-xl' /></span>
                              </div>
                              <div className='flex flex-col justify-center space-y-4 pt-6 p-4'>
                                <div className='flex justify-between items-center border-b border-b-[#00000030] pb-2'>
                                  <p>Tuition Fee</p>
                                  <span>₹{totalTuitionEarningMonthWasi && totalTuitionEarningMonthWasi.studentFee ? totalTuitionEarningMonthWasi.studentFee : 0} </span>
                                </div>
                                <div className='flex justify-between items-center  border-b border-b-[#00000030] pb-2'>
                                  <p>Refrence Fee</p>
                                  <span>₹ {totalTuitionEarningMonthWasi && totalTuitionEarningMonthWasi.refFee ? totalTuitionEarningMonthWasi.refFee : 0}</span>
                                </div>
                                <div className='flex justify-between items-center border-b border-b-[#00000030] pb-2'>
                                  <p>Tutor's Payment</p>
                                  <span>₹ {totalTuitionEarningMonthWasi && totalTuitionEarningMonthWasi.tutorFee ? totalTuitionEarningMonthWasi.tutorFee : 0}</span>
                                </div>
                                <div className='flex justify-between items-center '>
                                  <p>Referral Payment</p>
                                  <span>₹ {totalTuitionEarningMonthWasi && totalTuitionEarningMonthWasi.referralFee ? totalTuitionEarningMonthWasi.referralFee : 0}</span>
                                </div>
                                <div className='flex flex-col justify-center items-center bg-[#830552] rounded-lg p-1 text-white w-full  mx-auto '>
                                  <p className='text-lg font-semibold'>Company Share</p>
                                  <p className='text-md font-bold'>₹ {totalTuitionEarningMonthWasi && totalTuitionEarningMonthWasi.companyShare ? totalTuitionEarningMonthWasi.companyShare : 0}</p>
                                </div>
                              </div>

                            </div>
                            <div className='bg-white rounded-[16px] drop-shadow-xl '>
                              <div className='flex justify-between items-center  rounded-t-[16px] p-4 bg-[#D59A20] '>
                                <p className='font-semibold text-white '>New Referral</p>
                                <span className='cursor-pointer' onClick={() => this.handleOpenAddTuitionModalLeadDemo()}><IoIosArrowForward className='text-xl text-white' /></span>
                              </div>
                              <div className='p-4 space-y-4'>
                                {getUserRefList && getUserRefList.length > 0 ?
                                  getUserRefList.slice(0, 4).map((element, index) =>
                                    <div className='flex justify-between items-center border-b border-b-[#00000030] pb-2  '>
                                      <p>{element && element.name ? element.name : null} | {element && element.mobNo ? element.mobNo : null} | Referred By : {element && element.userId && element.userId.lastName ? element.userId.lastName : element && element.userId && element.userId.fullName ? element.userId.fullName : null}</p>
                                      <span><MdOutlineRemoveRedEye size={24} className='cursor-pointer' onClick={() => this.handleOpenAddTuitionModalLeadDemo()} /></span>
                                    </div>
                                  )
                                  :
                                  null
                                }
                              </div>

                            </div>
                            <div className='bg-white rounded-[16px] drop-shadow-xl '>
                              <div className='flex justify-between items-center  rounded-t-[16px] p-4 bg-[#D59A20] '>
                                <p className='font-semibold text-white '>New Support Ticket</p>
                                <span className='cursor-pointer' onClick={() => this.handleOpenAddSuppoet()}><IoIosArrowForward className='text-xl text-white' /></span>
                              </div>
                              <div className='p-4 space-y-4'>
                                {dashboardSupportData && dashboardSupportData.length > 0 ?
                                  dashboardSupportData.slice(0, 4).map((element, index) =>
                                    <div className='flex justify-between items-center border-b border-b-[#00000030] pb-2  '>
                                      <p>{element && element.userId && element.userId.fullName ? element.userId.fullName : element && element.userId && element.userId.firstName ? element.userId.firstName + "" + element.userId.lastName : null} | {element && element.userId && element.userId.mobNo ? element.userId.mobNo : null} | Title : {element && element.title ? element.title : null}| Ticket No : {element && element.ticketNo ? element.ticketNo : null}</p>
                                      <span><MdOutlineRemoveRedEye size={24} className='cursor-pointer' onClick={() => this.handleOpenAddSuppoet()} /></span>
                                    </div>
                                  )
                                  :
                                  null
                                }
                              </div>

                            </div>
                          </div>
                        </section>

                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

function mapStateToProps(state) {
  const { dashboard, transaction, users, teachcourse, tution, support } = state;
  console.log("transactiontransactiontransaction???????????", transaction);
  return {
    dashboard,
    transaction,
    users,
    teachcourse,
    tution,
    support
  };
}
export default connect(mapStateToProps)(Dashboard);
