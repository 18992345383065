export const swapConstants = {


    // GET_USER_TXLIST_REQUEST: 'TRANSACTION_GET_USER_TXLIST_REQUEST',
    // GET_USER_TXLIST_SUCCESS: 'TRANSACTION_GET_USER_TXLIST_SUCCESS',
    // GET_USER_TXLIST_FAILURE: 'TRANSACTION_GET_USER_TXLIST_FAILURE',



    TXLIST_SWAP_SUCCESS_REQUEST: 'TXLIST_SWAP_SUCCESS_REQUEST',
    TXLIST_SWAP_SUCCESS_SUCCESS: 'TXLIST_SWAP_SUCCESS_SUCCESS',
    TXLIST_SWAP_SUCCESS_FAILURE: 'TXLIST_SWAP_SUCCESS_FAILURE',

    TXLIST_SWAP_PENDING_REQUEST: 'TXLIST_SWAP_PENDING_REQUEST',
    TXLIST_SWAP_PENDING_SUCCESS: 'TXLIST_SWAP_PENDING_SUCCESS',
    TXLIST_SWAP_PENDING_FAILURE: 'TXLIST_SWAP_PENDING_FAILURE',

};
