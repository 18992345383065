import React from "react";

import Modal from 'react-modal';
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';



export default function CreateAddDealerModal(props) {

  let { coinCreateModal, handleCoinHideModal, errorsCoin, inputCoinChange, createCoinSubmit, fieldsCoin, handleFile, NameRes, handleChangeInputSubject, getTeachCourseList, getAllClass, startTimeFormatTimeToAMPM, endTimeFormatTimeToAMPM } = props;

  let startTimeData = fieldsCoin && fieldsCoin.startTime ? fieldsCoin.startTime : null

  let start24Hour = new Date("2000-01-01 " + startTimeData).toLocaleTimeString("en-US", { hour12: false });

  let endTimeData = fieldsCoin && fieldsCoin.endTime ? fieldsCoin.endTime : null

  let end24Hour = new Date("2000-01-01 " + endTimeData).toLocaleTimeString("en-US", { hour12: false });

  console.log('getTeachCourseList______________', fieldsCoin.course);
  let subjectData = []
  console.log('subjectData_____________________', subjectData);

  if (getTeachCourseList && getTeachCourseList.length > 0) {
    getTeachCourseList.forEach((emp) => {
      console.log('emp_________________', emp);

      let result = fieldsCoin && fieldsCoin.course ? fieldsCoin.course.filter(o => (o.id == emp && emp.id)) : [];

      console.log('resultresultresultresult', result)

      if (result && result.length > 0) {
        // console.log("ppppppppppppppppppppppppppppppppppppppppp",emp)
        subjectData.push(
          {
            value: emp.id,
            label: emp.name,
            id: emp.id,
            disabled: true
          }
        )
      } else {
        // console.log("ppppppppppppppppppppppppppppppppppppppppp",emp)
        subjectData.push(
          {
            value: emp.id,
            label: emp.name,
            id: emp.id,
            disabled: false
          }
        )
      }
    })
  }


  return (




    <div className={coinCreateModal ? "main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" : "hidden"} style={{ background: 'rgba(0,0,0,.7)' }}>
      <div className="border border-teal-500  modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-4 text-left px-6">
          {/*Title*/}
          <div className="flex justify-between items-center pb-3">
            <p className="text-2xl font-bold">Update Class And Subject</p>
            <div className="modal-close cursor-pointer z-50">
              <svg onClick={() => handleCoinHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          {/*Body*/}


          <div className="w-full ">

            <p>Preferred Time :</p>

            <div className='flex w-full space-x-2'>
              <div className="w-full">
                <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Start</label>
                <input className="px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 border border-gray-400" onChange={startTimeFormatTimeToAMPM}
                  id="startTime"
                  value={start24Hour}

                  type="time"
                  name="startTime" />

              </div>
              {/* <p className="px-1 py-1">To</p> */}
              <div className="w-full">
                <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">End</label>
                <input className="px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 border border-gray-400" onChange={endTimeFormatTimeToAMPM}
                  id="endTime"
                  value={end24Hour}
                  type="time"
                  name="endTime" />

              </div>
            </div>

          </div>


          <div >
            <div className="relative mt-1 shadow-sm">
              <div>
                {/* <p>Preferred Gender :</p> */}
                <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Preferred Gender :</label>
                <select className="px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 border border-gray-400" onChange={inputCoinChange}
                  id="requiredGender"
                  name="requiredGender">
                  <option value={fieldsCoin && fieldsCoin.requiredGender ? fieldsCoin.requiredGender : 'Please Choose Gender'}>{fieldsCoin && fieldsCoin.requiredGender ? fieldsCoin.requiredGender : 'Please Choose Gender'} &hellip;</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="No Preference">No Preference</option>
                </select>
              </div>
            </div>
          </div>

          <div >

            <div>
              <div className="relative mt-1 shadow-sm">
                <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Teaching Mode :</label>
                <select className="px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 border border-gray-400" onChange={inputCoinChange}
                  id="teachingMode"
                  name="teachingMode">
                  <option value={fieldsCoin && fieldsCoin.teachingMode ? fieldsCoin.teachingMode : 'Please Choose Gender'}>{fieldsCoin && fieldsCoin.teachingMode ? fieldsCoin.teachingMode : 'Please Choose Gender'} &hellip;</option>
                  <option value="Home Tuition">Home Tuition</option>
                  <option value="Online Tuition">Online Tuition</option>
                </select>
              </div>
            </div>
          </div>

          <div className="w-full ">
            <div className="relative mt-1 shadow-sm">
              <label class="block mb-2 text-sm font-bold text-gray-900" for="file_input">Class</label>
              <select className="px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow w-full ease-linear transition-all duration-150 border border-gray-400" onChange={inputCoinChange}
                id="classId"
                name="classId">
                <option value=""> {fieldsCoin && fieldsCoin.class && fieldsCoin.class.name ? fieldsCoin.class.name : 'Please Choose class'}&hellip;</option>
                {
                  getAllClass && getAllClass && getAllClass.length > 0 ?
                    getAllClass.map((element, index) => (
                      <option value={element && element.id} key={index}>{element && element.name}</option>
                    )) : null
                }
              </select>
            </div>
          </div>

          <div className="my-5">

            <form autoComplete="off">
              <div className="{otpSent?'disableArea':''}">
                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                  <label htmlFor="resourceList" style={{ color: 'black' }}>Subject</label>

                  <Select2
                    width="full"
                    isMulti
                    onChange={handleChangeInputSubject}
                    defaultValue={fieldsCoin && fieldsCoin.course && fieldsCoin.course ?
                      fieldsCoin.course.map((element) => ({
                        value: element && element.id ? element.id : null,
                        label: element && element.name ? element.name : null,
                        id: element && element.id ? element.id : null
                      })) : []}
                    options={subjectData}
                    isOptionDisabled={(option) => option.disabled}
                    className="basic-multi-select width: 'full'"
                    classNamePrefix="Activities"
                  />

                </Grid>
              </div>






              <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={createCoinSubmit}>Submit</button>
              </div>
            </form>

          </div>
          {/*Footer*/}
        </div>
      </div>
    </div >



  );
}
