import React, { Component } from 'react';
import { connect } from 'react-redux';
import { coinActions, userActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import ViewCoinModal from "./components/ViewCoinModal/ViewCoinModal";
import CreateCoinModal from "./components/CreateCoinModal/CreateCoinModal";
import UpdateCoinModal from "./components/UpdateCoinModal/UpdateCoinModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiOutlineViewGridAdd, HiEye } from "react-icons/hi";
import { RiChatCheckLine, RiChatDeleteLine } from "react-icons/ri";
import { IoDocumentAttachOutline } from "react-icons/io5";

import { authHeader, history } from '../../_helpers';
import { CONST } from '../../_config';


class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fieldskyc: {},
      // errorskyc: {},
      studentData: {},
      fieldsCoin: {},
      errorsCoin: {},
      fieldsUpdateCoin: {},
      errorsUpdateCoin: {},
      viewAddharData: {},
      viewRowData: {},
      coinCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCoinModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      studentAndTutorList: [],
      courseList: [],
      classList: [],
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let reqData = {
      "status": "ACTIVE",
      "pageNo": this.state.pageNo,
    }
    let reqDataTemp = {
      "pageNo": this.state.pageNo,
    }
    this.props.dispatch(userActions.getAssignmentAdminList(data));
    this.getUserActive(reqData);
    this.getTeachCourseList(reqDataTemp);
    this.getClassList(reqDataTemp);
    this.props.dispatch(cityActions.getAllClass(data));
    let reqDataTempRes = {
      "classId": null,
    }
    this.props.dispatch(userActions.getClassIdByTuitionStudent(reqDataTempRes));
  }

  getUserActive = (data) => {
    let header = new Headers({
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    });
    const requestOptions = {
      method: "POST",
      headers: header,
      body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
      .then((response) => response.json())
      .then(response => {
        if (response && response.data && response.data.list) {
          this.setState({ studentAndTutorList: response.data.list })
        }
        console.log('&&&&&&&&&&&&&&&&&&&&&', response);
      });
  }

  getTeachCourseList = (data) => {
    let header = new Headers({
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    });
    const requestOptions = {
      method: "POST",
      headers: header,
      body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTeachCourseList`, requestOptions)
      .then((response) => response.json())
      .then(response => {
        if (response && response.data && response.data.list) {
          this.setState({ courseList: response.data.list })
        }
        console.log('&&&&&&&&&&&&&&&&&&&&&', response);
      });
  }

  getClassList = (data) => {
    let header = new Headers({
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    });
    const requestOptions = {
      method: "POST",
      headers: header,
      body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getClassList`, requestOptions)
      .then((response) => response.json())
      .then(response => {
        if (response && response.data && response.data.list) {
          this.setState({ classList: response.data.list })
        }
        console.log('&&&&&&&&&&&&&&&&&&&&&', response);
      });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccessRes) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsCoin: {},
        errorsCoin: {},
        fieldsUpdateCoin: {},
        errorsUpdateCoin: {},
        coinCreateModal: false,
        UpdateCoinModal: false,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getAssignmentAdminList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getAssignmentAdminList(data));
  }
  handeleResponse = (data) => {
    this.setState({ moreDetailsCreateModal: true, viewAddharData: data });
  }



  deleteCoin = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete assignment?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.deleteAssignmentAdmin(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenCreateModalMoreDetails = (data) => {

    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }
  handleOpenCoinUpdateModal = (data) => {
    this.setState({ UpdateCoinModal: true, fieldsUpdateCoin: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(coinActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleCoinHideModal = () => {
    this.setState({ coinCreateModal: false, fieldsCoin: {}, errorsCoin: {} });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleCoinUpdateHideModal = () => {
    this.setState({ UpdateCoinModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleHideCoinUpdateModal = () => {
    this.setState({ UpdateCoinModal: false });
  }
  handleOpenCreateModal = (items) => {
    // console.log("clicked");
    this.setState({ coinCreateModal: true });
    this.setState({ tutorapplieddemoclass: items });
  }
  inputCoinChange = (e) => {
    e.preventDefault();
    console.log('e_______________________', e.target.value);
    let { name, value } = e.target;
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = this.state.errorsCoin;
    fieldsCoin[name] = value;
    console.log(name, value);
    errorsCoin[name] = "";
    this.setState({ fieldsCoin, errorsCoin });
    if (this.state.fieldsCoin.classId) {
      console.log('1111111111111111222');

      let reqDataTemp = {
        "classId": this.state.fieldsCoin.classId,
      }
      this.props.dispatch(userActions.getClassIdByTuitionStudent(reqDataTemp));
    }
  }
  inputChangeUpdateCoin = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = this.state.errorsUpdateCoin;
    fieldsUpdateCoin[name] = value;
    errorsUpdateCoin[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateCoin, errorsUpdateCoin });
  }

  createCoinSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;

    if (this.handleValidationCoin()) {
      let reqData = {
        "studentId": this.state.studentData["studentId"],
        "name": this.state.fieldsCoin.name,
        "assignmentPdf": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsCoin.assignmentPdf
      }
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>createCoinSubmit>", reqData);
      this.props.dispatch(userActions.createAssignmentAdmin(reqData, data));
    }

  }

  // navigate = (data) => {

  //   this.props.history.push('/app/series/' + data.sportId);
  // }

  updateCoinSubmit = () => {

    let { users } = this.props;
    let { filesDetails } = users;


    // if (this.handleValidationUpdateCoin()) 
    {
      let reqData = {
        "id": this.state.fieldsUpdateCoin.id,
        "studentId": this.state.fieldsUpdateCoin.studentId,
        "tutorId": this.state.fieldsUpdateCoin.tutorId,
        "name": this.state.fieldsUpdateCoin.name,
        "assignmentPdf": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsUpdateCoin.assignmentPdf

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(userActions.updateAssignmentAdmin(reqData, paginationData));
    }

  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status ',
      message: `Are you sure to change status of ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.updateAssignmentAdminStatus(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleValidationUpdateCoin = () => {
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = {};
    let formIsValid = true;

    //Email
    if (!fieldsUpdateCoin["studentId"] || fieldsUpdateCoin["studentId"] === "") {
      formIsValid = false;
      errorsUpdateCoin["studentId"] = "Cannot be empty studentId";
    }

    if (!fieldsUpdateCoin["tutorId"] || fieldsUpdateCoin["tutorId"] === "") {
      formIsValid = false;
      errorsUpdateCoin["tutorId"] = "Cannot be empty tutorId";
    }

    if (!fieldsUpdateCoin["name"] || fieldsUpdateCoin["name"] === "") {
      formIsValid = false;
      errorsUpdateCoin["name"] = "Cannot be empty name";
    }

    this.setState({ errorsUpdateCoin: errorsUpdateCoin });
    return formIsValid;
  }
  handleValidationCoin = () => {
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = {};
    let formIsValid = true;
    let studentId = this.state.studentData["studentId"]



    if (!fieldsCoin["name"] || fieldsCoin["name"] === "") {
      formIsValid = false;
      errorsCoin["name"] = "Cannot be empty name";
    }

    if (!studentId || studentId.length == 0) {
      formIsValid = false;
      errorsCoin["studentId"] = "Cannot be empty student";
    }

    this.setState({ errorsCoin: errorsCoin });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  studentAndTutorList = () => {

    let { users } = this.props;
    let { assignmentTotal, loading, assignedClassesItems, items, total } = users;
    return items;
  }

  handleChangeInputInternal = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["studentId"] = output;
    this.setState({ studentData });

  };

  render() {

    let { users, city } = this.props;
    let { assignmentTotal, loading, assignmentItems, items, total, getClassIdByTuition } = users;
    let { getAllClass } = city;
    // this.setState({ userAndTutorList: items })
    console.log("RENDER_____________________fieldsUpdateCoin::", this.state.studentData["studentId"]);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">Share Assignment To Students ({assignmentTotal})</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600">
                      </div>
                    </div>
                    <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.handleOpenCreateModal(items)}> ADD </button>
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden rounded-lg ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className='py-4'>
                          <h3 className='md:text-2xl text-lg leading-6 md:leading-9  font-semibold capitalize text-dark-400 tracking-wider'>Assignment History</h3>
                        </div>
                        <div className="overflow-x-auto shadow-md ">
                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-[#fff] rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#000] capitalize whitespace-nowrap">shared By</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#000] capitalize whitespace-nowrap">shared to student</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#000] capitalize whitespace-nowrap">Assignment Name</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#000] capitalize whitespace-nowrap">Attachment</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#000] capitalize whitespace-nowrap">Date</th>
                                {/* <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#000] capitalize whitespace-nowrap">Status</th> */}
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-[#000] capitalize whitespace-nowrap">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                assignmentItems && assignmentItems.length > 0 ?
                                  assignmentItems.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <p>{element && element.tutorId && element.tutorId.fullName ? element.tutorId.fullName : 'Admin'}</p>
                                        <p>{element && element.tutorId && element.tutorId.mobNo ? element.tutorId.mobNo : null}</p>
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <p>{element && element.studentId && element.studentId.firstName ? element.studentId.firstName + " " + element.studentId.lastName : '-'}</p>
                                        <p>{element && element.studentId && element.studentId.mobNo ? element.studentId.mobNo : '-'}</p>
                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.name ? element.name : "-"}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <a target="_blank" href={element && element.assignmentPdfLink} className="text-blue-500 hover:underline font-bold">
                                          <IoDocumentAttachOutline size={24} className='w-14 h-14 text-[#9BC9FF]' />
                                        </a>

                                      </td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD")}
                                      </td>
                                      <td className="flex px-2 py-3 text-gray-600 whitespace-nowrap">


                                        <span className="flex justify-center pl-1">
                                          <div class=" px-3 py-1 font-bold tracking-wider text-white bg-[#ED1D2B] border rounded-full shadow-sm hover:shadow-lg cursor-pointer" onClick={() => this.deleteCoin(element)}>
                                            Remove
                                          </div>
                                        </span>
                                      </td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            assignmentTotal && assignmentTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={assignmentTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            assignmentTotal && assignmentTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={assignmentTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        {/* <DialogExample /> */}

        <CreateCoinModal
          coinCreateModal={this.state.coinCreateModal}
          fieldsCoin={this.state.fieldsCoin}
          errorsCoin={this.state.errorsCoin}
          studentAndTutorList={this.state.studentAndTutorList}
          courseList={this.state.courseList}
          classList={this.state.classList}
          // userAndTutorList={this.state.userAndTutorList}
          handleChangeInputInternal={this.handleChangeInputInternal}
          inputCoinChange={this.inputCoinChange}
          createCoinSubmit={this.createCoinSubmit}
          handleCoinHideModal={this.handleCoinHideModal}
          handleFile={this.handleFile}
          getAllClass={getAllClass}
          getClassIdByTuition={getClassIdByTuition}





        />

        <UpdateCoinModal
          UpdateCoinModal={this.state.UpdateCoinModal}
          fieldsUpdateCoin={this.state.fieldsUpdateCoin}
          errorsUpdateCoin={this.state.errorsUpdateCoin}
          studentAndTutorList={this.state.studentAndTutorList}
          courseList={this.state.courseList}
          classList={this.state.classList}
          inputChangeUpdateCoin={this.inputChangeUpdateCoin}
          updateCoinSubmit={this.updateCoinSubmit}
          handleCoinUpdateHideModal={this.handleCoinUpdateHideModal}
          handleFile={this.handleFile}
        />

        <ViewCoinModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewAddharData={this.state.viewAddharData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}

        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { coin, users, city } = state;
  return {
    coin,
    users,
    city
  };
}
export default connect(mapStateToProps)(Assignment);
