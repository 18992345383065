/* eslint-disable no-dupe-class-members */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { tutionActions, userActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert';
import { FaArrowCircleRight } from "react-icons/fa";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { MdOutlineSearch } from "react-icons/md";
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';

class Tuition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldstution: {},
      errorstution: {},
      fieldstutionUpdate: {},
      errorstutionUpdate: {},
      studentData: {},
      allDataValue: {},
      fieldsUpdateCoin: {},
      errorsUpdateCoin: {},
      tutionCreateModal: false,
      tutionUpdateModal: false,
      tutionUpdateModalAddAmount: false,
      moreDetailsHandel: false,
      ManualTutorModal: false,
      assignTutorSuggested: false,
      tutorIdSaveFeildValue: null,
      companyTotalShare: 0,
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      formData: {
        "name": "",
        "days": "",
        "per": "",
      },
      updateformData: {
        "id": "",
        "name": "",
        "days": "",
        "per": "",
      },
      assignTutorMenually: false,
    }
  }
  componentDidMount() {
    let temp1 = {
      "status": "ASSIGN",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getTutionList(temp1));
    let data1 = {
      "userType": "STUDENT",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActiveStudent(data1));
    this.props.dispatch(cityActions.getBoardList());
    let data = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActive(data));
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getAllteacherCouresId(temp));
    this.props.dispatch(tutionActions.getAllStudentId(temp));
    this.props.dispatch(tutionActions.getAllTutorId(temp));
    this.props.dispatch(cityActions.getAllClass(temp));
    this.props.dispatch(cityActions.getAllCity(temp));
  }


  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.tution.addUserSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "key": "",
        },
        assignTutorSuggested: false,
        assignTutorMenually: false,
        tutionCreateModal: false,
        tutionUpdateModal: false,
        ManualTutorModal: false,
        fieldstution: {},
        errorstution: {},
        fieldstutionUpdate: {},
        errorstutionUpdate: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }


  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "classId": this.state.studentData['classId'] && this.state.studentData['classId'].length > 0 ? this.state.studentData['classId'] : null,
      "teacherCouresId": this.state.studentData['teacherCouresId'] && this.state.studentData['teacherCouresId'].length > 0 ? this.state.studentData['teacherCouresId'] : null,
      "tutorId": this.state.studentData['tutorId'] && this.state.studentData['tutorId'].length > 0 ? this.state.studentData['tutorId'] : null,
      "dueDate": this.state.fieldstution && this.state.fieldstution['deuDate'] ? Number(this.state.fieldstution['deuDate']) : null,
      "classReference": this.state.fieldstution && this.state.fieldstution['classReference'] ? this.state.fieldstution['classReference'] : null,
      "status": "ASSIGN",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm that the class is complete ',
      message: `Are you sure this class is complete?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.closeClassStatusUpdate(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  refuseRequestSubmit = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "classId": this.state.studentData['classId'] && this.state.studentData['classId'].length > 0 ? this.state.studentData['classId'] : null,
      "teacherCouresId": this.state.studentData['teacherCouresId'] && this.state.studentData['teacherCouresId'].length > 0 ? this.state.studentData['teacherCouresId'] : null,
      "tutorId": this.state.studentData['tutorId'] && this.state.studentData['tutorId'].length > 0 ? this.state.studentData['tutorId'] : null,
      "dueDate": this.state.fieldstution && this.state.fieldstution['deuDate'] ? Number(this.state.fieldstution['deuDate']) : null,
      "classReference": this.state.fieldstution && this.state.fieldstution['classReference'] ? this.state.fieldstution['classReference'] : null,
      "status": "ASSIGN",
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }

    confirmAlert({


      title: 'Confirm to refuse request ',
      message: `Are you sure to refuse request?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.refuseRequest(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldstution = this.state.fieldstution;
    let errorstution = this.state.errorstution;
    fieldstution[name] = value;
    errorstution[name] = "";
    console.log(name, value);
    this.setState({ fieldstution, errorstution });
  }
  openModelHandel = (data) => {
    this.setState({ moreDetailsHandel: true, allDataValue: data })
  }
  closeModelHandel = () => {
    this.setState({ moreDetailsHandel: false, allDataValue: {} })
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": value,
      "pageNo": this.state.page,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActive(data));
  }


  manualAssginTutor = (data) => {
    let reqData = {
      "id": this.state.allDataValue.id,
      "tutorId": data && data.id ? data.id : data && data.tutorInfo && data.tutorInfo.userId ? data.tutorInfo.userId : null
    }
    let paginationData = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "classId": this.state.studentData['classId'] && this.state.studentData['classId'].length > 0 ? this.state.studentData['classId'] : null,
      "teacherCouresId": this.state.studentData['teacherCouresId'] && this.state.studentData['teacherCouresId'].length > 0 ? this.state.studentData['teacherCouresId'] : null,
      "tutorId": this.state.studentData['tutorId'] && this.state.studentData['tutorId'].length > 0 ? this.state.studentData['tutorId'] : null,
      "dueDate": this.state.fieldstution && this.state.fieldstution['deuDate'] ? Number(this.state.fieldstution['deuDate']) : null,
      "classReference": this.state.fieldstution && this.state.fieldstution['classReference'] ? this.state.fieldstution['classReference'] : null,
      "status": "ASSIGN",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to change tutor?',
      message: `
      Are you sure to ${data && data.fullName ? data.fullName : data && data.user && data.user.userDetails && data.user.userDetails.fullName ? data.user.userDetails.fullName : null} as a tutor??`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(tutionActions.manualTutorChange(reqData, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenhandleOpenAddAttendance = (data) => {
    this.props.history.push('/app/Attendance/' + data.id)
  };
  assignTutorSuggestedOpen = (data) => {
    let resData = {
      id: data.id
    }
    this.props.dispatch(tutionActions.getSuggestedTutorByTuition1(resData));
    this.setState({ assignTutorSuggested: true, allDataValue: data })
  }
  assignTutorSuggestedClose = () => {
    this.setState({ assignTutorSuggested: false, allDataValue: {}, isOpenMannually: false })
  }


  assignTutorMenuallyOpen = (data) => {
    let data2 = {
      "userType": "TUTOR",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "cityId": data && data.studentId && data.studentId.city && data.studentId.city ? [data.studentId.city] : null,
      "pageNo": 1,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActive(data2));
    this.setState({ assignTutorMenually: true, allDataValue: data })
  }
  assignTutorMenuallyClose = () => {
    this.setState({ assignTutorMenually: false, allDataValue: {} })
  }

  handelIsOpenFnctionMannually = (data) => {
    console.log('data___________________data', data);
    this.setState({ isOpenMannually: !this.state.isOpenMannually, tutorDataRes: data })
  }

  resetDataRes = () => {
    this.props.history.push('/app/tutorChangeRequest')
  }
  classReferenceSubmit = () => {
    // e.preventDefault();
    console.log('this.state.fieldstution', this.state.fieldstution);

    let temp1 = {
      "cityId": this.state.studentData['cityId'] && this.state.studentData['cityId'].length > 0 ? this.state.studentData['cityId'] : null,
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "classId": this.state.studentData['classId'] && this.state.studentData['classId'].length > 0 ? this.state.studentData['classId'] : null,
      "teacherCouresId": this.state.studentData['teacherCouresId'] && this.state.studentData['teacherCouresId'].length > 0 ? this.state.studentData['teacherCouresId'] : null,
      "tutorId": this.state.studentData['tutorId'] && this.state.studentData['tutorId'].length > 0 ? this.state.studentData['tutorId'] : null,
      "dueDate": Number(this.state.fieldstution['deuDate']),
      "classReference": this.state.fieldstution['classReference'],
      "status": "ASSIGN",
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getTutionList(temp1));
  }
  handleChangeInputclassId = (event) => {

    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    const { studentData } = this.state;
    studentData["classId"] = output;
    this.setState({ studentData });

  };
  handleChangeInputtutorId = (event) => {

    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    const { studentData } = this.state;
    studentData["tutorId"] = output;
    this.setState({ studentData });

  };
  handleChangeInputCityId = (event) => {

    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    const { studentData } = this.state;
    studentData["cityId"] = output;
    this.setState({ studentData });

  };
  handleChangeInputteacherCouresId = (event) => {

    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    const { studentData } = this.state;
    studentData["teacherCouresId"] = output;
    this.setState({ studentData });

  };
  handleChangeInputInternal = (event) => {

    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    const { studentData } = this.state;
    studentData["studentId"] = output;
    this.setState({ studentData });

  };
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected + 1 });

    let temp1 = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "classId": this.state.studentData['classId'] && this.state.studentData['classId'].length > 0 ? this.state.studentData['classId'] : null,
      "teacherCouresId": this.state.studentData['teacherCouresId'] && this.state.studentData['teacherCouresId'].length > 0 ? this.state.studentData['teacherCouresId'] : null,
      "tutorId": this.state.studentData['tutorId'] && this.state.studentData['tutorId'].length > 0 ? this.state.studentData['tutorId'] : null,
      "dueDate": this.state.fieldstution && this.state.fieldstution['deuDate'] ? Number(this.state.fieldstution['deuDate']) : null,
      "classReference": this.state.fieldstution && this.state.fieldstution['classReference'] ? this.state.fieldstution['classReference'] : null,
      "status": "ASSIGN",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(tutionActions.getTutionList(temp1));

  }

  render() {

    let { tution, city, users } = this.props;
    let { tuitionItemsDashboard, tutionTotal, getAllteacherCouresId, suggestedTutorByTuition1, loading } = tution;
    let { getAllClass, getAllCity } = city;
    let { getUserList, getUserStudentList } = users;

    let NameRes = getUserStudentList && getUserStudentList.length ? getUserStudentList.map((ele, index) => ({ value: ele.id, label: ele.firstName + " " + ele.lastName + " " + ele.mobNo })) : null
    let classRes = getAllClass && getAllClass.length ? getAllClass.map((ele, index) => ({ value: ele.id, label: ele.name })) : null
    let teacherCouresRes = getAllteacherCouresId && getAllteacherCouresId.length ? getAllteacherCouresId.map((ele, index) => ({ value: ele.id, label: ele.name })) : null
    let tutorRes = getUserList && getUserList.length ? getUserList.map((ele, index) => ({ value: ele.id, label: ele.fullName + " " + ele.mobNo })) : null
    let cityName = getAllCity && getAllCity.length ? getAllCity.map((ele, index) => ({ value: ele.id, label: ele.name })) : null
    return (
      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {/* Main Content */}
        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">Change Tutor Request ({tutionTotal})</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">

                    </div>
                  </div>
                  <div className=" rounded-lg overflow-hidden pb-2 mt-4">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">

                          <thead className="bg-[#fff] rounded-t">
                            <tr className=" ">
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>Student name or Number</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputInternal}
                                    options={NameRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>Class</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputclassId}
                                    options={classRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>Subject</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputteacherCouresId}
                                    options={teacherCouresRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>Tutor</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputtutorId}
                                    options={tutorRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>city</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputCityId}
                                    options={cityName}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              </th>
                              <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              </th>
                              <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <label className='text-transparent' for="">Due</label>
                                <div className="relative">
                                  <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                  </div>
                                  <input className="appearance-none sm:w-40 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="deuDate" name='deuDate' placeholder="Due date.." type="text" onChange={this.inputChange} required />
                                </div>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <label className='text-transparent' for="">class</label>
                                <div className="relative">
                                  <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                  </div>
                                  <input className="appearance-none sm:w-40 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="classReference" name='classReference' placeholder="Class reference.." type="text" onChange={this.inputChange} required />
                                </div>
                              </th>
                              <th>
                                <label className='text-transparent' for="">Search</label>
                                <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.classReferenceSubmit()}> Search </button>
                              </th>
                              <th>
                                <label className='text-transparent' for="">Reset</label>
                                <button className="bg-[#25BF63] hover:bg-[#25BF63] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.resetDataRes()}> Reset </button>
                              </th>
                            </tr>
                          </thead>

                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">Name & Mobile Number </th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">City</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Address</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Class & Subject</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Class Reference</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Assigned Tutor</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Tuition Fee</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Start Date</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">due Date</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Tutor's Fee</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Reference Fee</th>
                                <th scope="col" className=" px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                tuitionItemsDashboard && tuitionItemsDashboard.length > 0 ?
                                  tuitionItemsDashboard.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap  text-sm text-gray-600 flex items-center space-x-2 pt-12">
                                        <div className='w-12 h-12'>
                                          <img
                                            class="w-12 h-12 rounded-full"
                                            src={element && element.studentId && element.studentId.imageLinkUrl ? element.studentId.imageLinkUrl : element && element.studentId && element.studentId.gender && element.studentId.gender == "Male" ? "images/boyImageLogo.png" : element && element.studentId && element.studentId.gender && element.studentId.gender == "Female" ? "images/girlImageLogo.png" : "images/maleFemaleIcon.png"}
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <p>{element && element.studentId && element.studentId.firstName ? element.studentId.firstName : null} {element && element.studentId && element.studentId.lastName ? element.studentId.lastName : null}</p>
                                          <p> {element && element.studentId && element.studentId.mobNo ? element.studentId.mobNo : "-"}</p>
                                          <div className='flex items-center justify-end' onClick={() => this.openModelHandel(element)}><span className="font-bold">More</span>  <span className='text-[#FBB00C] pl-1'><FaArrowCircleRight size={16} /> </span></div>
                                        </div>

                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600">
                                        <p className=''>{element && element.cityId && element.cityId.name ? element.cityId.name : "-"}</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600">
                                        <p className=''>{element && element.studentId && element.studentId.studentInfoId && element.studentId.studentInfoId.guardianAddress ? element.studentId.studentInfoId.guardianAddress : "-"}</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600">
                                        <p className=''>{element && element.classId && element.classId.name ? element.classId.name : "-"}</p>
                                        <p className=''>{element && element.teacherCouresId && element.teacherCouresId[0] && element.teacherCouresId[0].name ? element.teacherCouresId[0].name : "-"}</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600">
                                        <p className=''>{element.classReference}</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600">
                                        <p className=''>{element && element.tutorId && element.tutorId.fullName ? element.tutorId.fullName : "-"} </p>
                                        <p className=''>{element && element.tutorId && element.tutorId.mobNo ? element.tutorId.mobNo : "-"}</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600">
                                        <p className=''>{element.fee}/- Monthly</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600">
                                        <p className=''>{element.startDate}</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600">
                                        <p className=''>{element.dueDate}</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600">
                                        <p className=''>{element.tutorFee}/- Monthly</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600">
                                        <p className=''>{element.refFee}/- Monthly</p>
                                      </td>
                                      <td className="px-6 py-3  text-center text-sm text-gray-600 space-y-1">
                                        <div onClick={() => this.refuseRequestSubmit(element)}>
                                          <button type="button" className='bg-[#25BF63] px-2 py-1 w-36 text-white font-bold rounded-full capitalize'>Refuse Request</button>
                                        </div>
                                        <div onClick={() => this.handleOpenhandleOpenAddAttendance(element)}>
                                          <button type="button" className='bg-[#25BF63] px-2 py-1 w-36 text-white font-bold rounded-full capitalize'>View Attendence</button>
                                        </div>
                                        <div >
                                          <span className=" pl-1 flex justify-center items-center">
                                            <button className="bg-[#FBB00C] px-2 py-1 w-36 text-black font-bold rounded-full capitalize"
                                              onClick={() => this.assignTutorMenuallyOpen(element)}
                                            >Change Tutor</button>
                                          </span>

                                        </div>
                                        <div >
                                          <span className=" pl-1 flex justify-center items-center">
                                            <button className="bg-[#FBB00C] px-2 py-1 w-36 text-black font-bold rounded-full capitalize"
                                              onClick={() => this.assignTutorSuggestedOpen(element)}
                                            >Suggested Tutors</button>
                                          </span>

                                        </div>
                                        <div>
                                          <button type="button" className='bg-[#FC0000] px-2 py-1 w-36 text-white font-bold rounded-full capitalize' onClick={() => this.disableUser(element)}
                                          >{element && element.isCloseClass ? "Complete" : "Complete class"}</button>
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }


                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <nav className="relative z-0 flex justify-end mt-5">
                      {
                        tutionTotal && tutionTotal > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={tutionTotal / this.state.size}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>


                  </div>
                </section>
              </div>
            </div>

            {/* Assign mannually  */}
            {this.state.assignTutorMenually ?
              <div className="main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                <div className="border-2 border-black shadow-lg modal-container bg-white w-96 mx-auto rounded-[40px]  z-50 overflow-y-auto">
                  <div className='py-4'>
                    <div className="modal-close cursor-pointer z-50 pl-6">
                      <svg
                        onClick={() => this.assignTutorMenuallyClose()}
                        className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                      </svg>
                    </div>
                    <div className='flex justify-center items-center'>
                      <div className="relative mt-5 lg:mt-0">
                        <div className="absolute top-2.5 left-4 text-sky-500"><MdOutlineSearch /></div>
                        <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-[#fff] placeholder-gray-800  transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search Here" type="amount" required onChange={this.handleSearch} />
                      </div>
                    </div>
                  </div>
                  <div className="modal-content py-4 text-left px-6  h-[550px] overflow-y-auto">
                    {
                      getUserList && getUserList.length > 0 ?
                        getUserList.map((ele, index) => (
                          <>
                            <div className='flex justify-between items-center py-4 border-b-2'>
                              <div className='flex items-center space-x-2'>
                                <div>
                                  <img
                                    class="w-12 h-12 rounded-full"
                                    src={ele && ele.imageLinkUrl ? ele.imageLinkUrl : null}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <p>{ele && ele.fullName ? ele.fullName : null}</p>
                                  <p className='text-[#213360] text-xs'>{ele && ele.mobNo ? ele.mobNo : null}</p>
                                </div>
                              </div>
                              <div className='flex items-center space-x-4'>
                                <div className='flex items-center space-x-1' onClick={() => this.handelIsOpenFnctionMannually({ ...ele, index: index })}><span className='font-bold'>More</span>  <span className='bg-green-600 rounded-full text-white p-1'><AiOutlineDoubleRight size={10} /> </span></div>
                                <button class="bg-green-600  py-1 text-sm shadow-sm px-2 font-bold tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-green-700" onClick={() => this.manualAssginTutor(ele)}>Assign</button>
                              </div>
                            </div>
                            {this.state.isOpenMannually && this.state.tutorDataRes.index === index && (
                              <>
                                <div className='bg-[#E8E8E8] w-full p-4'>
                                  <div className='flex justify-between items-start text-[#213360] w-full'>
                                    <p className='w-full'>Gender :</p>
                                    <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.gender ? this.state.tutorDataRes.gender : 'NA'}</p>
                                  </div>
                                  <div className='flex justify-between items-start text-[#213360] w-full'>
                                    <p className='w-full '>Address :</p>
                                    <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.address ? this.state.tutorDataRes.address : 'NA'}</p>
                                  </div>
                                  <div className='flex justify-between items-start text-[#213360] w-full'>
                                    <p className='w-full '>School :</p>
                                    <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.tutorInfoId && this.state.tutorDataRes.tutorInfoId.schoolName ? this.state.tutorDataRes.tutorInfoId.schoolName : 'NA'}</p>
                                  </div>
                                  <div className='flex justify-between items-start text-[#213360] w-full'>
                                    <p className='w-full '>Graduation :</p>
                                    <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.tutorInfoId && this.state.tutorDataRes.tutorInfoId.ugCourse ? this.state.tutorDataRes.tutorInfoId.ugCourse : 'NA'}</p>
                                  </div>
                                  <div className='flex justify-between items-start text-[#213360] w-full'>
                                    <p className='w-full'>Post Graduation :</p>
                                    <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.tutorInfoId && this.state.tutorDataRes.tutorInfoId.pgClgName ? this.state.tutorDataRes.tutorInfoId.pgClgName : 'NA'}</p>
                                  </div>
                                  <div className='flex justify-between items-start text-[#213360] w-full'>
                                    <p className='w-full'>Experience:</p>
                                    <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.experience ? this.state.tutorDataRes.experience : 'NA'}</p>
                                  </div>

                                </div>
                              </>)
                            }
                          </>
                        ))
                        : null}
                  </div>
                </div>
              </div>
              :
              null
            }
            {/* Assign mannually  */}


            {this.state.assignTutorSuggested ?
              <div className="main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                <div className="border-2 border-black shadow-lg modal-container bg-white w-96 mx-auto rounded-[40px]  z-50 overflow-y-auto">
                  <div className='py-4'>
                    <div className="modal-close cursor-pointer z-50 pl-6">
                      <svg
                        onClick={() => this.assignTutorSuggestedClose()}
                        className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                        </path>
                      </svg>
                    </div>
                  </div>
                  <div className="modal-content py-4 text-left px-6  h-[550px] overflow-y-auto">
                    {
                      suggestedTutorByTuition1 && suggestedTutorByTuition1.length > 0 ?
                        suggestedTutorByTuition1.map((ele, index) => (
                          <>
                            <div className='flex justify-between items-center py-4 border-b-2'>
                              <div className='flex items-center space-x-2'>
                                <div>
                                  <img
                                    class="w-12 h-12 rounded-full"
                                    src={ele && ele.imageUrl ? ele.imageUrl : null}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <p>{ele && ele.user && ele.user.userDetails && ele.user.userDetails.fullName ? ele.user.userDetails.fullName : null}</p>
                                  <p className='text-[#213360] text-xs'>{ele && ele.user && ele.user.userDetails && ele.user.userDetails.mobNo ? ele.user.userDetails.mobNo : null}</p>
                                </div>
                              </div>
                              <div className='flex items-center space-x-4'>
                                <div className='flex items-center space-x-1' onClick={() => this.handelIsOpenFnctionMannually({ ...ele, index: index })}><span className='font-bold'>More</span>  <span className='bg-green-600 rounded-full text-white p-1'><AiOutlineDoubleRight size={10} /> </span></div>
                                <button class="bg-green-600  py-1 text-sm shadow-sm px-2 font-bold tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-green-700" onClick={() => this.manualAssginTutor(ele)}>Assign</button>
                              </div>
                            </div>
                            {this.state.isOpenMannually && this.state.tutorDataRes.index === index && (
                              <>
                                <div className='bg-[#E8E8E8] w-full p-4'>
                                  <div className='flex justify-between items-start  text-[#213360] w-full'>
                                    <p className='w-full'>Gender :</p>
                                    <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.user && this.state.tutorDataRes.user.userDetails && this.state.tutorDataRes.user.userDetails.gender ? this.state.tutorDataRes.user.userDetails.gender : 'NA'}</p>
                                  </div>
                                  <div className='flex justify-between items-start  text-[#213360] w-full'>
                                    <p className='w-full'>Address :</p>
                                    <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.user && this.state.tutorDataRes.user.userDetails && this.state.tutorDataRes.user.userDetails.address ? this.state.tutorDataRes.user.userDetails.address : 'NA'}</p>
                                  </div>
                                  <div className='flex justify-between items-start  text-[#213360] w-full'>
                                    <p className='w-full'>School :</p>
                                    <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.tutorInfo && this.state.tutorDataRes.tutorInfo.schoolName ? this.state.tutorDataRes.tutorInfo.schoolName : 'NA'}</p>
                                  </div>
                                  <div className='flex justify-between items-start  text-[#213360] w-full'>
                                    <p className='w-full '>Graduation :</p>
                                    <p className='w-full '>{this.state.tutorDataRes && this.state.tutorDataRes.tutorInfo && this.state.tutorDataRes.tutorInfo.ugCourse ? this.state.tutorDataRes.tutorInfo.ugCourse : 'NA'}</p>
                                  </div>
                                  <div className='flex justify-between items-start  text-[#213360] w-full'>
                                    <p className='w-full '>Post Graduation :</p>
                                    <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.tutorInfo && this.state.tutorDataRes.tutorInfo.pgClgName ? this.state.tutorDataRes.tutorInfo.pgClgName : 'NA'}</p>
                                  </div>
                                  <div className='flex justify-between items-start  text-[#213360] w-full'>
                                    <p className='w-full '>Experience:</p>
                                    <p className='w-full text-end'>{this.state.tutorDataRes && this.state.tutorDataRes.user && this.state.tutorDataRes.user.userDetails && this.state.tutorDataRes.user.userDetails.experience ? this.state.tutorDataRes.user.userDetails.experience : 'NA'}</p>
                                  </div>

                                </div>
                              </>)
                            }
                          </>
                        ))
                        : null}
                  </div>
                </div>
              </div>
              :
              null
            }


            {this.state.moreDetailsHandel ?
              <>

                <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                  <div className="border border-yellow-500 modal-container bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/3 mx-auto rounded-[40px] shadow-lg z-50 overflow-y-auto">
                    <div className="modal-content ">
                      <div className="flex justify-between items-center modal-close cursor-pointer z-50 py-2 px-6 bg-[#FBB00C]">
                        <p className='font-bold text-md'>More</p>
                        <div className='bg-white rounded-full p-1'>
                          <svg
                            onClick={() => this.closeModelHandel()}
                            className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                            </path>
                          </svg>
                        </div>
                      </div>


                      <div className='py-4 px-6 h-[400px] overflow-y-auto divide-y-2'>
                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Student name :</p>
                          <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.firstName ? this.state.allDataValue.studentId.firstName
                            : "NA"} {"  "}{this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.lastName ? this.state.allDataValue.studentId.lastName
                              : "NA"}</p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Student mob No. : </p>
                          <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.mobNo ? this.state.allDataValue.studentId.mobNo
                            : "NA"} </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Address : </p>
                          <p > {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianAddress ? this.state.allDataValue.studentId.studentInfoId.guardianAddress
                            : "NA"}</p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Guardian Name :</p>
                          <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianName ? this.state.allDataValue.studentId.studentInfoId.guardianName : "-"} </p>
                        </div>
                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Guardian Mob No. :</p>
                          <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianConNo ? this.state.allDataValue.studentId.studentInfoId.guardianConNo : "-"} </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Tutor name :</p>
                          <p > {this.state.allDataValue && this.state.allDataValue.tutorId && this.state.allDataValue.tutorId.fullName ? this.state.allDataValue.tutorId.fullName : "NA"}   </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Tutor mob No. : </p>
                          <p > {this.state.allDataValue && this.state.allDataValue.tutorId && this.state.allDataValue.tutorId.mobNo ? this.state.allDataValue.tutorId.mobNo
                            : "NA"} </p>
                        </div>

                        <div className='flex justify-between items-start w-full'>
                          <div className='w-full'>
                            <p className='text-lg font-bold'>Subject:</p>
                          </div>
                          <div className='w-full'>
                            <ul className='text-right'>
                              {this.state.allDataValue && this.state.allDataValue.teacherCouresId && this.state.allDataValue.teacherCouresId.length > 0 ?
                                this.state.allDataValue.teacherCouresId.map((element) =>
                                  <>
                                    <li className='flex flex-col'>{element && element.name ? element.name : "NA"} </li>
                                  </>
                                ) : 'NA'}
                            </ul>
                          </div>
                        </div>



                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Class:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.classId && this.state.allDataValue.classId.name ? this.state.allDataValue.classId.name : "NA"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Class in month:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.monthInDay ? this.state.allDataValue.monthInDay : "NA"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Total fee:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.fee ? this.state.allDataValue.fee : "0"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Tutor fee:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.tutorFee ? this.state.allDataValue.tutorFee : "0"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Reference fee:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.refFee ? this.state.allDataValue.refFee : "0"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Company share:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.companyShare ? this.state.allDataValue.companyShare : "0"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Start date:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.startDate ? this.state.allDataValue.startDate : "0"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Payment mode:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.paymentMode ? this.state.allDataValue.paymentMode : "NA"}  </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Create tution date:</p>
                          <p >{moment(new Date(parseInt(this.state.allDataValue && this.state.allDataValue.createdAt ? this.state.allDataValue.createdAt : "NA"))).utcOffset("+05:30").format("DD-MMM-YY")}
                          </p>
                        </div>

                        <div className='flex justify-between items-center w-full'>
                          <p className='text-lg font-bold'>Class status:</p>
                          <p >{this.state.allDataValue && this.state.allDataValue.isCloseClass ? "Complete class" : "On going class"}
                          </p>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </>
              :
              null
            }


          </main>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, tution, city } = state;
  return {
    users,
    tution, city,
    authentication
  };
}
export default connect(mapStateToProps)(Tuition);
