export const packageConstants = {

    GETALL_PACKAGE_REQUEST: 'GETALL_PACKAGE_REQUEST',
    GETALL_PACKAGE_SUCCESS: 'GETALL_PACKAGE_SUCCESS',
    GETALL_PACKAGE_FAILURE: 'GETALL_PACKAGE_FAILURE',

    GET_LIST_PACKAGE_REQUEST: 'GET_LIST_PACKAGE_REQUEST',
    GET_LIST_PACKAGE_SUCCESS: 'GET_LIST_PACKAGE_SUCCESS',
    GET_LIST_PACKAGE_FAILURE: 'GET_LIST_PACKAGE_FAILURE',

    // DEPOSIT_USER_BALANCE_REQUEST: 'DEPOSIT_USER_BALANCE_REQUEST',
    // DEPOSIT_USER_BALANCE_SUCCESS: 'DEPOSIT_USER_BALANCE_SUCCESS',
    // DEPOSIT_USER_BALANCE_FAILURE: 'DEPOSIT_USER_BALANCE_FAILURE',

    ADD_PACKAGE_REQUEST: 'ADD_PACKAGE_REQUEST',
    ADD_PACKAGE_SUCCESS: 'ADD_PACKAGE_SUCCESS',
    ADD_PACKAGE_FAILURE: 'ADD_PACKAGE_FAILURE',

    GET_USER_LIST_PACKAGE_REQUEST: 'GET_USER_LIST_PACKAGE_REQUEST',
    GET_USER_LIST_PACKAGE_SUCCESS: 'GET_USER_LIST_PACKAGE_SUCCESS',
    GET_USER_LIST_PACKAGE_FAILURE: 'GET_USER_LIST_PACKAGE_FAILURE',

    DELETE_PACKAGE_REQUEST: 'DELETE_PACKAGE_REQUEST',
    DELETE_PACKAGE_SUCCESS: 'DELETE_PACKAGE_SUCCESS',
    DELETE_PACKAGE_FAILURE: 'DELETE_PACKAGE_FAILURE',

    UPDATE_PACKAGE_REQUEST: 'UPDATE_PACKAGE_REQUEST',
    UPDATE_PACKAGE_SUCCESS: 'UPDATE_PACKAGE_SUCCESS',
    UPDATE_PACKAGE_FAILURE: 'UPDATE_PACKAGE_FAILURE',

    DISABLE_PACKAGE_REQUEST: 'DISABLE_PACKAGE_REQUEST',
    DISABLE_PACKAGE_SUCCESS: 'DISABLE_PACKAGE_SUCCESS',
    DISABLE_PACKAGE_FAILURE: 'DISABLE_PACKAGE_FAILURE',

};
