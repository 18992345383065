import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { HiTrash, HiEye } from "react-icons/hi";
// import { RiMailLockLine, RiMailCloseFill, RiMailCheckFill} from "react-icons/ri";

class UserDeactive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewRowData: {},
      moreDetailsCreateModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
    }
  }

  componentDidMount() {
    let temp = {
      "userType": "DEACTIVE",
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserDeactive(temp));
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "userType": "DEACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserDeactive(datatemp));
    console.log("userTypeuserTypeuserTypeuserTypeuserTypeuserType", datatemp);
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log("value kya aa rhi h::::", value);
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "userType": "DEACTIVE",
      "keyWord": value.toLowerCase(),
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserDeactive(data));
  }

  handleOpenCreateModalMoreDetails = (data) => {
    console.log("table k row se kya data aa rha h:::", data);

    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }

  disableUser = (data) => {
    // console.log("data  ", data);
    // console.log("data.id me kya aa rha h::::  ", data.id);
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "DEACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": 1,
      "size": this.state.size
      //data.selected + 
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.disableUser(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  deleteUser = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "DEACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": 1,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.deleteUser(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  verifyEmail = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "DEACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to verifyEmail?',
      message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.verifyEmail(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }

  render() {

    let { users } = this.props;
    let { items, total, loading } = users;
    console.log("itemsitemsitems=============>", items);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="text-lg font-semibold leading-6 tracking-widest text-center capitalize md:text-2xl md:leading-9 text-dark-400">User Deactive</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-blue-300 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">username</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">email</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">refByCode</th>
                                {/* <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Date</th> */}
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">status</th>
                                <th scope="col" className="px-6 py-3 text-sm font-semibold tracking-wider text-left text-gray-700 capitalize whitespace-nowrap">Actions</th>

                              </tr>
                            </thead>

                            {/* Table Row Section */}
                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element.userName}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element.email}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.mobNo ? element.mobNo : "-"}</td>
                                      {/* <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}
                                      </td> */}

                                      {/* <div className="inline-block px-2 py-3 text-gray-600 whitespace-nowrap">

                                        <span class="inline-block pl-1">
                                          <button class="bg-yellow-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-green-100 rounded-md hover:shadow-lg hover:bg-yellow-400" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>More</button>
                                        </span>

                                        <span className="inline-block pl-1">
                                          <button class="bg-green-700 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-green-400" onClick={() => this.disableUser(element)}>{element && element.isDisabled ? "enable" : "disable"}</button>
                                        </span>

                                        <span className="inline-block pl-1">
                                          <button class={`w-24 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-blue-400 ${element.isVerify ? 'bg-green-700' : 'bg-red-700'}`} onClick={() => this.verifyEmail(element)}>{element && element.isVerify === false ? "  Verify  " : "Verified"}</button>
                                        </span>

                                        <span className="inline-block pl-1">
                                          <button class="bg-red-600 px-4 py-2 shadow-sm font-medium tracking-wider border text-blue-100 rounded-md hover:shadow-lg hover:bg-red-400" onClick={() => this.deleteUser(element)}>Delete</button>
                                        </span>

                                      </div> */}

                                      <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <label class="flex justify-center items-center cursor-pointer targetablepx-4 tooltip">
                                          <div class="relative" onClick={() => this.disableUser(element)}>
                                            <input type="checkbox" id="toggleB" class="sr-only" />
                                            <div class="block bg-gray-600 w-10 h-6 rounded-full"></div>
                                            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>{element && element.isDisabled ? "enable" : "disable"}</span>
                                          </div>
                                        </label>
                                      </td>

                                      <td className="px-2 py-3 text-gray-600 whitespace-nowrap">
                                        <div className="flex">
                                          <span className="pl-1">
                                            <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                              <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>View Details</span>
                                              <HiEye className='block object-contain w-5 h-5' />
                                            </div>
                                          </span>

                                          <span className="flex justify-center pl-1">
                                            <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-500 cursor-pointer" onClick={() => this.deleteUser(element)}>
                                              <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Delete</span>
                                              <HiTrash className='block object-contain w-5 h-5' />
                                            </div>
                                          </span>

                                          {/* <span className="pl-1">
                                            <div class={`tooltip px-3 py-1 font-medium tracking-wider text-blue-100 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-500 cursor-pointer" ${element.isVerify ? 'bg-green-700' : 'bg-blue-700'}`} onClick={() => this.verifyEmail(element)}>
                                              <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>{element && element.isVerify === false ? "Verify" : "Verified"}</span>
                                              {element && element.isVerify === false ? <RiMailCloseFill className='block object-contain w-5 h-5' /> : <RiMailCheckFill className='block object-contain w-5 h-5' />}
                                            </div>
                                          </span> */}
                                        </div>
                                      </td>


                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <ViewMoreDetailsModal

          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={this.state.viewRowData}
          // errorsappsetting={this.state.errorsappsetting}
          // inputChange={this.inputChange}
          // appSettingSubmit={this.appSettingSubmit}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}

        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}
export default connect(mapStateToProps)(UserDeactive);
