import React, { Component } from 'react';
import { connect } from 'react-redux';
import { coinActions, userActions, teachcourseActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import ViewCoinModal from "./components/ViewCoinModal/ViewCoinModal";
import CreateCoinModal from "./components/CreateCoinModal/CreateCoinModal";
import UpdateCoinModal from "./components/UpdateCoinModal/UpdateCoinModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { HiTrash, HiOutlineViewGridAdd, HiEye } from "react-icons/hi";
import { RiChatCheckLine, RiChatDeleteLine } from "react-icons/ri";
import { FaArrowCircleRight } from "react-icons/fa";
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';
class Coin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fieldskyc: {},
      // errorskyc: {},
      fieldsCoin: {},
      errorsCoin: {},
      studentData: {},
      fieldsUpdateCoin: {},
      errorsUpdateCoin: {},
      viewRowData: {},
      elementValue: {},
      elementResData: {},
      coinCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCoinModal: false,
      approveStatus: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "status": 3,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));
    // this.props.dispatch(cityActions.getAllClass());
    let data1 = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(cityActions.getAllCity(data1));
    let data2 = {
      "userType": "STUDENT",
      "status": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": 1000000
    }
    this.props.dispatch(userActions.getUserActiveStudent(data2));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.coin.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsCoin: {},
        errorsCoin: {},
        fieldsUpdateCoin: {},
        errorsUpdateCoin: {},
        coinCreateModal: false,
        UpdateCoinModal: false,

      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "status": "3",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "status": "3",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));
  }
  disableCoin = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.name} coin?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.updateTeachCourseStatus(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  // disableCoinCard = (data) => {
  //   let datatemp = {
  //     "id": data.id,
  //   }
  //   let paginationdata = {
  //     "keyWord": "",
  //     "pageNo": 1,
  //     "size": this.state.size
  //   }
  //   confirmAlert({


  //     title: 'Confirm to disable Card?',
  //     message: `Are you sure to disable ${data.name} Coin Card?`,
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => this.props.dispatch(coinActions.disableCoinCard(datatemp, paginationdata))
  //       },
  //       {
  //         label: 'No'
  //       }
  //     ]
  //   });
  // }

  deleteCoin = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} coin?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.deleteTeachCourse(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenCreateModalMoreDetails = (data) => {

    this.setState({ moreDetailsCreateModal: true, viewRowData: data });
  }
  handleOpenCoinUpdateModal = (data) => {
    this.setState({ UpdateCoinModal: true, fieldsUpdateCoin: data });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(coinActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleCoinHideModal = () => {
    this.setState({ coinCreateModal: false, fieldsCoin: {}, errorsCoin: {} });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleCoinUpdateHideModal = () => {
    this.setState({ UpdateCoinModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleHideCoinUpdateModal = () => {
    this.setState({ UpdateCoinModal: false });
  }
  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ coinCreateModal: true });
  }
  inputCoinChange = (e) => {
    console.log('e______________________', e.target.value);
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = this.state.errorsCoin;
    fieldsCoin[name] = value;
    console.log(name, value);
    errorsCoin[name] = "";
    this.setState({ fieldsCoin, errorsCoin });
  }
  inputChangeUpdateCoin = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = this.state.errorsUpdateCoin;
    fieldsUpdateCoin[name] = value;
    errorsUpdateCoin[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdateCoin, errorsUpdateCoin });
  }

  createCoinSubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;

    if (this.handleValidationCoin()) {
      let reqData = {
        "name": this.state.fieldsCoin.name,
        "shortName": this.state.fieldsCoin.shortName,
      }

      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>createCoinSubmit>", reqData);
      this.props.dispatch(teachcourseActions.createTeachCourse(reqData));
    }

  }

  // navigate = (data) => {

  //   this.props.history.push('/app/series/' + data.sportId);
  // }

  updateCoinSubmit = () => {

    let { users } = this.props;
    let { filesDetails } = users;


    if (this.handleValidationUpdateCoin()) {
      let reqData = {
        "id": this.state.fieldsUpdateCoin.id,
        "name": this.state.fieldsUpdateCoin.name,
        "shortName": this.state.fieldsUpdateCoin.shortName,

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      console.log("updateCoinSubmit_updateCoinSubmit:1:2:3:", reqData);
      this.props.dispatch(teachcourseActions.updateTeachCourse(reqData, paginationData));
    }

  }
  handleValidationUpdateCoin = () => {
    let fieldsUpdateCoin = this.state.fieldsUpdateCoin;
    let errorsUpdateCoin = {};
    let formIsValid = true;

    //name
    if (!fieldsUpdateCoin["name"] || fieldsUpdateCoin["name"] === "") {
      formIsValid = false;
      errorsUpdateCoin["name"] = "Cannot be empty";
    }

    //ticker
    if (!fieldsUpdateCoin["shortName"] || fieldsUpdateCoin["shortName"] === "") {
      formIsValid = false;
      errorsUpdateCoin["shortName"] = "Cannot be empty";
    }
    //m_cap
    // if (!fieldsUpdateCoin["m_cap"] || fieldsUpdateCoin["m_cap"] === "") {
    //   formIsValid = false;
    //   errorsUpdateCoin["m_cap"] = "Cannot be empty m_cap";
    // }

    //volume
    // if (!fieldsUpdateCoin["volume"] || fieldsUpdateCoin["volume"] === "") {
    //   formIsValid = false;
    //   errorsUpdateCoin["volume"] = "Cannot be empty volume";
    // }

    console.log("errorsUpdateCoinerrorsUpdateCoin_errorsUpdateCoinerrorsUpdateCoin:", errorsUpdateCoin);

    this.setState({ errorsUpdateCoin: errorsUpdateCoin });
    return formIsValid;
  }
  handleValidationCoin = () => {
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = {};
    let formIsValid = true;

    //Email
    if (!fieldsCoin["name"] || fieldsCoin["name"] === "") {
      formIsValid = false;
      errorsCoin["name"] = "Cannot be empty name";
    }

    if (!fieldsCoin["shortName"] || fieldsCoin["shortName"] === "") {
      formIsValid = false;
      errorsCoin["shortName"] = "Cannot be empty shortName";
    }



    this.setState({ errorsCoin: errorsCoin });
    return formIsValid;
  }

  handleFile = (event) => {
    // console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  changeStatusDemoClass = (data, status) => {
    let paginationData = {
      "status": "3",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let tempdata = {
      "id": data.id,
      "status": status,
    }
    confirmAlert({
      title: 'Confirm to Accept?',
      message: 'Are you sure to accept demo of phone no.:- ' + data.studentId.mobNo,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teachcourseActions.changeStatusDemoClass(tempdata, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  approveOpenStatusHandel = (data, status) => {
    this.setState({ approveStatus: true, elementValue: data.id })
  }
  approveClodeStatusHandel = (data, status) => {
    this.setState({ approveStatus: false, fieldsCoin: {} })
  }
  approveOpenStatusHandel1 = (data, status) => {
    this.setState({ approveStatus1: true, elementValue: data.id, elementResData: data })
  }
  approveClodeStatusHandel1 = (data, status) => {
    this.setState({ approveStatus1: false, fieldsCoin: {}, elementResData: {} })
  }
  handleValidationUpdateDemoRequesAccept = () => {
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = {};
    let formIsValid = true;

    //inHandFees
    if (!fieldsCoin["inHandFees"] || fieldsCoin["inHandFees"] === "") {
      formIsValid = false;
      errorsCoin["inHandFees"] = "Cannot be empty in hand fees";
    }

    //numberOfClasses
    if (!fieldsCoin["numberOfClasses"] || fieldsCoin["numberOfClasses"] === "") {
      formIsValid = false;
      errorsCoin["numberOfClasses"] = "Cannot be empty number of class";
    }

    //teachingLocation
    if (!fieldsCoin["teachingLocation"] || fieldsCoin["teachingLocation"] === "") {
      formIsValid = false;
      errorsCoin["teachingLocation"] = "Cannot be empty teaching location";
    }

    console.log("errorsCoinerrorsCoin_errorsCoinerrorsCoin:", errorsCoin);

    this.setState({ errorsCoin: errorsCoin });
    return formIsValid;
  }
  changeStatusDemoClassApprove = () => {
    if (this.handleValidationUpdateDemoRequesAccept()) {
      let paginationData = {
        "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
        "cityId": this.state.studentData['cityId'] && this.state.studentData['cityId'].length > 0 ? this.state.studentData['cityId'] : null,
        "requiredGender": this.state.fieldsCoin && this.state.fieldsCoin['requiredGender'] ? this.state.fieldsCoin['requiredGender'] : null,
        "status": 3,
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      let tempdata = {
        "id": this.state.elementValue,
        "status": 1,
        "inHandFees": this.state.fieldsCoin.inHandFees,
        "numberOfClasses": this.state.fieldsCoin.numberOfClasses,
        "teachingLocation": this.state.fieldsCoin.teachingLocation,
        "classReference": this.state.fieldsCoin.classReference,
        "remark": this.state.fieldsCoin.remark,

      }
      this.props.dispatch(teachcourseActions.changeStatusDemoClass(tempdata, paginationData))
      this.approveClodeStatusHandel()
    }
  }


  classReferenceSubmit = () => {
    let data = {
      "studentId": this.state.studentData['studentId'] && this.state.studentData['studentId'].length > 0 ? this.state.studentData['studentId'] : null,
      "cityId": this.state.studentData['cityId'] && this.state.studentData['cityId'].length > 0 ? this.state.studentData['cityId'] : null,
      "requiredGender": this.state.fieldsCoin && this.state.fieldsCoin['requiredGender'] ? this.state.fieldsCoin['requiredGender'] : null,
      "status": 3,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    console.log('data_________________data', data);
    this.props.dispatch(teachcourseActions.getFilteredDemoClassList(data));
  }

  resetDataRes = (data) => {
    this.props.history.push('/app/rejecteddemoclass')
  }

  handleChangeInputInternal = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["studentId"] = output;
    this.setState({ studentData });

  };

  handleChangeInputCityId = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["cityId"] = output;
    this.setState({ studentData });

  };

  render() {

    let { coin, teachcourse, city, users } = this.props;
    let { total, loading, items } = teachcourse;
    let { getAllCity } = city;
    let { getUserStudentList } = users;

    let NameRes = getUserStudentList && getUserStudentList.length ? getUserStudentList.map((ele, index) => ({ value: ele.id, label: ele.firstName + " " + ele.lastName + " " + ele.mobNo })) : null
    let cityName = getAllCity && getAllCity.length ? getAllCity.map((ele, index) => ({ value: ele.id, label: ele.name })) : null



    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="w-full flex flex-wrap  ">
                    <div className="ml-0 mr-auto">
                      <h3 className="md:text-2xl text-lg leading-6 md:leading-9 text-center font-semibold capitalize text-dark-400 tracking-wider">Rejected Demo Requests ({total})</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600">
                      </div>
                    </div>
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">
                          <thead className="bg-[#fff] rounded-t">
                            <tr className=" ">
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>Student name or Number</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputInternal}
                                    options={NameRes}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>
                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>City</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputCityId}
                                    options={cityName}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>


                              <th scope="col" className="whitespace-nowrap py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <div className='flex flex-col items-center'>
                                  <label className='invisible' htmlFor="resourceList" style={{ color: 'black' }}>City</label>
                                  <select class="bg-[#FBB00C]  py-2 px-4 text-sm shadow-sm w-40 font-bold tracking-wider text-white rounded-full hover:shadow-lg" onClick={this.inputCoinChange}
                                    id='requiredGender' name='requiredGender'>

                                    <option className='bg-white text-black' value="">Select Preferred Gender</option>
                                    <option className='bg-white text-black' value="Male">Male</option>
                                    <option className='bg-white text-black' value="Female">Female</option>
                                    <option className='bg-white text-black' value="No Preference">No Preference</option>
                                  </select>
                                </div>
                              </th>


                              <th>
                                <label className='invisible' htmlFor="resourceList" style={{ color: 'black' }}>City</label>
                                <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.classReferenceSubmit()}> Search </button>
                              </th>
                              <th>
                                <label className='invisible' htmlFor="resourceList" style={{ color: 'black' }}>City</label>
                                <button className="bg-[#25BF63] hover:bg-[#25BF63] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.resetDataRes()}> Reset </button>
                              </th>
                            </tr>
                          </thead>
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">Name & Mobile Number </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Address </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> City</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> Class & Subject</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Preferred Gender</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Status</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Action</th>
                              </tr>
                            </thead>

                            {/* Table Row Section */}
                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap  text-sm text-gray-600 flex items-center space-x-2">
                                        <div className='w-12 h-12'>
                                          <img
                                            class="w-12 h-12 rounded-full"
                                            src={element && element.imageLinkUrl ? element.imageLinkUrl :

                                              element && element.studentId && element.studentId.gender && element.studentId.gender == "Male" ? "images/boyImageLogo.png" : element && element.studentId && element.studentId.gender && element.studentId.gender == "Female" ? "images/girlImageLogo.png" : "images/maleFemaleIcon.png"}
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <p> {element && element.studentId && element.studentId.firstName ? element.studentId.firstName : null} {element && element.studentId && element.studentId.lastName ? element.studentId.lastName : null}</p>
                                          <p>{element && element.studentId && element.studentId.mobNo ? element.studentId.mobNo : "-"}</p>
                                          <div className='flex items-center justify-end' onClick={() => this.approveOpenStatusHandel1(element, 1)}><span className="font-bold">More</span>  <span className='text-[#FBB00C] pl-1'><FaArrowCircleRight size={16} /> </span></div>
                                        </div>

                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-600 text-center">
                                        {element && element.fullAddress ? element.fullAddress : null}  </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        {element && element.cityId && element.cityId.name ? element.cityId.name : "NA"}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        {element && element.class && element.class.name ? element.class.name : "-"}/{element && element.course[0] && element.course[0].name ? element.course[0].name : "-"}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        {element && element.requiredGender ? element.requiredGender : "NA"}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        {element.status === 3 ? <span class="inline-block pl-1">
                                          <button class=" text-md font-bold  text-red-500 rounded-full " disabled>Rejected Demo Request</button>
                                        </span> : null}
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        <span class="inline-block pl-1">
                                          <button class="bg-green-600 px-4 py-1.5 text-sm shadow-sm font-bold tracking-wider border text-white rounded-full hover:shadow-lg hover:bg-green-700" onClick={() => this.approveOpenStatusHandel(element, 1)}>Accept</button>
                                        </span>
                                      </td>

                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>

                  {this.state.approveStatus ?
                    <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                      <div className="border border-teal-500  modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                        <div className="modal-content py-4 text-left px-6">
                          <div className="modal-close cursor-pointer z-50">
                            <svg
                              onClick={() => this.approveClodeStatusHandel()}
                              className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                          <div className="my-5">

                            <form autoComplete="off">


                              <div className="{otpSent?'disableArea':''}">
                                <label>Teaching location</label>
                                <div className="mt-1  shadow-sm relative">

                                  <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsCoin && !this.state.errorsCoin["teachingLocation"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="teachingLocation" name="teachingLocation" placeholder="Teaching Location" value={this.state.fieldsCoin.teachingLocation} type="text" onChange={this.inputCoinChange} />
                                  {this.state.errorsCoin && this.state.errorsCoin["teachingLocation"] ?
                                    <div className="invalid-feedback text-yellow-600">
                                      {this.state.errorsCoin["teachingLocation"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="{otpSent?'disableArea':''}">
                                <label>Number of classes in a month.</label>
                                <div className="mt-1  shadow-sm relative">

                                  <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsCoin && !this.state.errorsCoin["numberOfClasses"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="numberOfClasses" name="numberOfClasses" placeholder="Number of classes in a month" value={this.state.fieldsCoin.numberOfClasses} type="number" onChange={this.inputCoinChange} />
                                  {this.state.errorsCoin && this.state.errorsCoin["numberOfClasses"] ?
                                    <div className="invalid-feedback text-yellow-600">
                                      {this.state.errorsCoin["numberOfClasses"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="{otpSent?'disableArea':''}">
                                <label>In-hand fee</label>
                                <div className="mt-1  shadow-sm relative">

                                  <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsCoin && !this.state.errorsCoin["inHandFees"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="inHandFees" name="inHandFees" placeholder="In-hand fee" value={this.state.fieldsCoin.inHandFees} type="number" onChange={this.inputCoinChange} />
                                  {this.state.errorsCoin && this.state.errorsCoin["inHandFees"] ?
                                    <div className="invalid-feedback text-yellow-600">
                                      {this.state.errorsCoin["inHandFees"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="{otpSent?'disableArea':''}">
                                <label>Class reference</label>
                                <div className="mt-1  shadow-sm relative">

                                  <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsCoin && !this.state.errorsCoin["classReference"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="classReference" name="classReference" placeholder="Class reference" value={this.state.fieldsCoin.classReference} type="text" onChange={this.inputCoinChange} />
                                  {this.state.errorsCoin && this.state.errorsCoin["classReference"] ?
                                    <div className="invalid-feedback text-yellow-600">
                                      {this.state.errorsCoin["classReference"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="{otpSent?'disableArea':''}">
                                <label>Remark</label>
                                <div className="mt-1  shadow-sm relative">

                                  <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${this.state.errorsCoin && !this.state.errorsCoin["remark"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="remark" name="remark" placeholder="Remark" value={this.state.fieldsCoin.remark} type="text" onChange={this.inputCoinChange} />
                                  {this.state.errorsCoin && this.state.errorsCoin["remark"] ?
                                    <div className="invalid-feedback text-yellow-600">
                                      {this.state.errorsCoin["remark"]}
                                    </div>
                                    : null}
                                </div>
                              </div>
                              <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                                <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button"
                                  onClick={this.changeStatusDemoClassApprove}
                                >Approve</button>
                              </div>
                            </form>

                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                  {this.state.approveStatus1 ?
                    <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                      <div className="border border-yellow-500 modal-container bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/3 mx-auto rounded-[40px] shadow-lg z-50 overflow-y-auto">
                        <div className="modal-content ">
                          <div className="flex justify-between items-center modal-close cursor-pointer z-50 py-2 px-6 bg-[#FBB00C]">
                            <p className='font-bold text-md'>More</p>
                            <div className='bg-white rounded-full p-1'>
                              <svg
                                onClick={() => this.approveClodeStatusHandel1()}
                                className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                </path>
                              </svg>
                            </div>
                          </div>
                          <div className='py-4 px-6 h-[400px] overflow-y-auto divide-y-2'>
                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>Student Name :</p>
                              <p> {this.state.elementResData && this.state.elementResData.studentId && this.state.elementResData.studentId.firstName ? this.state.elementResData.studentId.firstName
                                : "-"} {"  "}{this.state.elementResData && this.state.elementResData.studentId && this.state.elementResData.studentId.lastName ? this.state.elementResData.studentId.lastName
                                  : "-"}</p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>Student Mob No. : </p>
                              <p > {this.state.elementResData && this.state.elementResData.studentId && this.state.elementResData.studentId.mobNo ? this.state.elementResData.studentId.mobNo
                                : "-"} </p>
                            </div>

                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>Guardian Name :</p>
                              <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianName ? this.state.allDataValue.studentId.studentInfoId.guardianName : "-"} </p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>Guardian Mob No. :</p>
                              <p> {this.state.allDataValue && this.state.allDataValue.studentId && this.state.allDataValue.studentId.studentInfoId && this.state.allDataValue.studentId.studentInfoId.guardianConNo ? this.state.allDataValue.studentId.studentInfoId.guardianConNo : "-"} </p>
                            </div>

                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>Demo Date : </p>
                              <p > {this.state.elementResData && this.state.elementResData.date ? this.state.elementResData.date : "-"}</p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>Prefer Time :</p>
                              <p > {this.state.elementResData && this.state.elementResData.startTime ? this.state.elementResData.startTime : "-"} to  {this.state.elementResData && this.state.elementResData.endTime ? this.state.elementResData.endTime : "-"}  </p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>Teaching Mode :</p>
                              <p > {this.state.elementResData && this.state.elementResData.teachingMode ? this.state.elementResData.teachingMode : "-"}  </p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>Board :</p>
                              <p >{this.state.elementResData && this.state.elementResData.board && this.state.elementResData.board.name ? this.state.elementResData.board.name : "-"}  </p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>City :</p>
                              <p >{this.state.elementResData && this.state.elementResData.cityId && this.state.elementResData.cityId.name ? this.state.elementResData.cityId.name : "-"}  </p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>PinCode :</p>
                              <p >{this.state.elementResData && this.state.elementResData.pinCodeId && this.state.elementResData.pinCodeId.pinNo ? this.state.elementResData.pinCodeId.pinNo : "-"}  </p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>Class :</p>
                              <p >{this.state.elementResData && this.state.elementResData.class && this.state.elementResData.class.name ? this.state.elementResData.class.name : "-"}  </p>
                            </div>
                            <div className='flex justify-between items-start w-full'>
                              <div className='w-full'>
                                <p className='text-lg font-bold'>Subject :</p>
                              </div>
                              <div className='w-full'>
                                <ul className='text-right'>

                                  {this.state.elementResData && this.state.elementResData.course && this.state.elementResData.course.length > 0 ?
                                    this.state.elementResData.course.map((element, index) =>
                                      <>
                                        <li className='flex flex-col'>{element && element.name ? element.name : "NA"} </li>
                                      </>
                                    ) : 'NA'}
                                </ul>
                              </div>
                            </div>

                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>Prefered gender :</p>
                              <p >{this.state.elementResData && this.state.elementResData.requiredGender ? this.state.elementResData.requiredGender : "-"}  </p>
                            </div>

                            <div className='flex justify-between items-center w-full'>
                              <p className='text-lg font-bold'>Address :</p>
                              <p >{this.state.elementResData && this.state.elementResData.studentId && this.state.elementResData.studentId.studentInfoId && this.state.elementResData.studentId.studentInfoId.guardianAddress ? this.state.elementResData.studentId.studentInfoId.guardianAddress : this.state.elementResData && this.state.elementResData.fullAddress ? this.state.elementResData.fullAddress : "NA"}  </p>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                    :
                    null
                  }

                </section>
              </div>
            </div>
          </main>
        </div>

        {/* <DialogExample /> */}

        <CreateCoinModal
          coinCreateModal={this.state.coinCreateModal}
          fieldsCoin={this.state.fieldsCoin}
          errorsCoin={this.state.errorsCoin}
          inputCoinChange={this.inputCoinChange}
          createCoinSubmit={this.createCoinSubmit}
          handleCoinHideModal={this.handleCoinHideModal}
          handleFile={this.handleFile}
        />

        <UpdateCoinModal
          UpdateCoinModal={this.state.UpdateCoinModal}
          fieldsUpdateCoin={this.state.fieldsUpdateCoin}
          errorsUpdateCoin={this.state.errorsUpdateCoin}
          inputChangeUpdateCoin={this.inputChangeUpdateCoin}
          updateCoinSubmit={this.updateCoinSubmit}
          handleCoinUpdateHideModal={this.handleCoinUpdateHideModal}
          handleFile={this.handleFile}
        />

        <ViewCoinModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          viewRowData={this.state.viewRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}

        />

      </>

    );
  }
}
function mapStateToProps(state) {
  const { coin, users, teachcourse, city } = state;
  return {
    coin,
    users,
    teachcourse,
    city
  };
}
export default connect(mapStateToProps)(Coin);
