import {
  kycConstants
} from '../_constants';

export function kyc(state = {}, action) {

  switch (action.type) {


    case kycConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case kycConstants.GETALL_SUCCESS:
      return {
        ...state,   
        loading: false,
        addKycSuccess: false,
        items: action.keyfeatures.getKycList.list,
        total: action.keyfeatures.getKycList.total
      };
    case kycConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case kycConstants.GET_ALL_KYC_REQUEST:
      return {
        ...state,
        loading: true
      };
    case kycConstants.GET_ALL_KYC_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllKyc: action.keyfeatures.getAllKyc
      };
    case kycConstants.GET_ALL_KYC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case kycConstants.GET_TUTTOR_EDUCATION_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case kycConstants.GET_TUTTOR_EDUCATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        tutorEducationById: action.keyfeatures.getTutorEducationById
      };
    case kycConstants.GET_TUTTOR_EDUCATION_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case kycConstants.GETALL_KYC_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case kycConstants.GETALL_KYC_NOTIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        listOfNotification: action.keyfeatures.listOfNotification.list,
        listOfNotificationtotal: action.keyfeatures.listOfNotification.total
      };
    case kycConstants.GETALL_KYC_NOTIFY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case kycConstants.UPDATE_KYC_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case kycConstants.UPDATE_KYC_NOTIFY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case kycConstants.UPDATE_KYC_NOTIFY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case kycConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case kycConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        loading: false,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.keyfeatures.restaurantDetails
      };
    case kycConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case kycConstants.UPDATE_KYC_PASSWORD_REQUEST:
      return {
        ...state
      };
    case kycConstants.UPDATE_KYC_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        addKycSuccess: true
      };
    case kycConstants.UPDATE_KYC_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case kycConstants.ADD_RESTAURANT_KYC_REQUEST:
      return {
        ...state
      };
    case kycConstants.ADD_RESTAURANT_KYC_SUCCESS:
      return {
        ...state,
        loading: false,
        addKycSuccess: true
      };
    case kycConstants.ADD_RESTAURANT_KYC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case kycConstants.ADD_MENU_REQUEST:
      return {
        ...state
      };
    case kycConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        addMenuSuccess: true
      };
    case kycConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case kycConstants.ADD_ITEM_REQUEST:
      return {
        ...state
      };
    case kycConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        addItemSuccess: true
      };
    case kycConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case kycConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case kycConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        updateCategory: true
      };
    case kycConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case kycConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state
      };
    case kycConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        updateitem: true
      };
    case kycConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case kycConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state, filesDetails: action.uploadImage.filesDetails,
      };

    case kycConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };


    // case kycConstants.STATS_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case kycConstants.STATS_SUCCESS:
    //   return {
    //     ...state,
    //     statics: action.keyfeatures.statics
    //   };
    // case kycConstants.STATS_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    default:
      return state
  }
}