import React from "react";

import Modal from 'react-modal';


export default function TransactionCreateModal(props) {

  let { transactionCreateModal, handleTransactionHideModal, errorsTransaction, inputTransactionChange, createTransactionSubmit, fieldsTransaction, userTeamItem, inputTypeChangePackage, userPackageItem } = props;
  console.log("userPackageItem7777777", userPackageItem);

  return (

    <Modal
      isOpen={transactionCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            {/*Title*/}
            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold">Create Transaction</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleTransactionHideModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">

                <div className="{otpSent?'disableArea':''}">
                  <div className="mt-1  shadow-sm relative">
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsTransaction && !errorsTransaction["amount"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="amount" name="amount" placeholder="Amount" value={fieldsTransaction.amount} type="text" onChange={inputTransactionChange} />
                    {errorsTransaction && errorsTransaction["amount"] ?
                      <div className="invalid-feedback text-yellow-600">
                        {errorsTransaction["amount"]}
                      </div>
                      : null}
                    {/* icon email */}
                    {/* <span className="absolute top-4 left-5 text-gray-500"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg></span> */}
                  </div>
                </div>
                <div className="mt-8">
                  <div class="relative lg:w-64 w-full border border-gray-300 text-gray-800 bg-white shadow-lg appearance-none cursor-pointer">
                    <label for="frm-whatever" class="sr-only">Transaction Type</label>
                    <select class="appearance-none w-full p-2 bg-white text-sm font-normal"
                      onChange={inputTransactionChange}
                      id="txType"
                      name="txType">
                      <option value="null">Please choose bonus type</option>
                      <option value="WELCOME">WELCOME</option>
                      <option value="TEAM_MINING">TEAM_MINING</option>

                    </select>
                    <div class="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <div class="relative lg:w-64 w-full border border-gray-300 text-gray-800 bg-white shadow-lg appearance-none cursor-pointer">
                    <label for="frm-whatever" class="sr-only">Transaction Type</label>
                    <select class="appearance-none w-full p-2 bg-white text-sm font-normal"
                      onChange={inputTypeChangePackage}
                      id="givenByUser"
                      name="givenByUser">
                      <option value="null">Please choose user</option>
                      {userTeamItem && userTeamItem.length > 0 ? userTeamItem.map((element) => <>
                        <option key={element.id} value={element.id}> {element.userName
                          ? element.userName
                          : "n/a"}</option>

                      </>)
                        : "No data found"}

                    </select>
                    <div class="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="mt-8">
                  <div class="relative lg:w-64 w-full border border-gray-300 text-gray-800 bg-white shadow-lg appearance-none cursor-pointer">
                    <label for="frm-whatever" class="sr-only">Transaction Type</label>
                    <select class="appearance-none w-full p-2 bg-white text-sm font-normal"
                      onChange={inputTransactionChange}
                      id="packgeId"
                      name="packgeId">
                      <option value="null">Please choose package</option>
                      {userPackageItem && userPackageItem.length > 0 ? userPackageItem.map((element) => <>
                        <option key={element.packageId} value={element.packageId}> {element.amount
                          ? element.amount
                          : "n/a"}</option>

                      </>)
                        : "No data found"}

                    </select>
                    <div class="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>



                <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                  <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 capitalize px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={createTransactionSubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
