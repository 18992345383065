import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, cityActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FaArrowCircleRight } from "react-icons/fa";
import { MdOutlineRemoveRedEye, MdLocationPin } from "react-icons/md";
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';
import "./style.css"

class UserActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elementValue: {},
      approveStatus1: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      page: 1,
      keyWord: '',
    }
  }
  componentDidMount() {
    let data = {
      "userType": "TUTOR",
      "status": "INACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(data));
    this.props.dispatch(cityActions.getAllCity());
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected + 1 });
    let datatemp = {
      // "userType": "ACTIVE", "userType": "TUTOR",
      "userType": "TUTOR",
      "status": "INACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      // "userType": "ACTIVE",
      "userType": "TUTOR",
      "status": "INACTIVE",
      "keyWord": value.toLowerCase(),
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(data));
  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "userType": "TUTOR",
      "status": "INACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to change status ?',
      message: `Are you sure to unblock ${data.fullName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.disableUser(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  approveOpenStatusHandel1 = (data, status) => {
    this.setState({ approveStatus1: true, elementValue: data })
  }
  KycResponseHandel = (data) => {
    this.props.history.push(`/app/TutorKycDetails/${data.id}`)
  }
  moreDetailsPage = (data) => {
    this.props.history.push(`/app/tutorMoreDetials/${data.id}`)
  }
  approveClodeStatusHandel1 = (data, status) => {
    this.setState({ approveStatus1: false, fieldsCoin: {} })
  }

  classReferenceSubmit = () => {


    let data = {
      "cityId": this.state.studentData['cityId'] && this.state.studentData['cityId'].length > 0 ? this.state.studentData['cityId'] : null,
      "userType": "TUTOR",
      "status": "INACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getUserActive(data));


  }
  resetDataRes = (data) => {
    this.props.history.push('/app/inactivetutor')
  }

  handleChangeInputCityId = (event) => {


    console.log("event.value  ", event);


    let output = event && event.length > 0 ? event.map(item => item.value) : [];
    console.log(output);
    console.log('yeezhgs', event);
    const { studentData } = this.state;
    studentData["cityId"] = output;
    this.setState({ studentData });

  };


  render() {

    let { users, city } = this.props;
    let { getUserList, total, loading, loginToThisAccountItems,
    } = users;
    let { getAllCity, getAllClass } = city
    let cityName = getAllCity && getAllCity.length ? getAllCity.map((ele, index) => ({ value: ele.id, label: ele.name })) : null

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">

                  <div className="flex flex-wrap w-full ">
                    <div className="ml-0 mr-auto">
                      <h3 className="text-lg font-semibold leading-6 tracking-widest text-center capitalize md:text-2xl md:leading-9 text-dark-400">Inactive Tutor ({total})</h3>
                    </div>
                    <div className="relative mt-5 lg:mt-0">
                      <div className="absolute top-2.5 left-4 text-gray-600"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </div>
                      <input className="appearance-none sm:w-64 w-full px-4 py-1.5 rounded-full border border-gray-400 bg-gray-200 placeholder-gray-800 text-dark-400 focus:outline-none focus:border-gray-800 focus:bg-gray-300 focus:text-gray-800 transition duration-150 ease-in-out text-sm font-normal sm:leading-5 pl-10" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                    </div>
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">

                          <thead className="bg-[#fff] rounded-t">
                            <tr className=" ">

                              <th scope="col" className="whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">
                                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                                  <label htmlFor="resourceList" style={{ color: 'black' }}>City</label>

                                  <Select2
                                    width="full"
                                    isMulti
                                    name="internalId"
                                    id="internalId"
                                    onChange={this.handleChangeInputCityId}
                                    options={cityName}
                                    className="basic-multi-select width: 'full'"
                                    classNamePrefix="Activities"
                                  />
                                </Grid>
                              </th>



                              <th className='whitespace-nowrap px-4 py-2 text-start text-xs font-semibold text-[#000] capitalize tracking-wider'>
                                <div className='flex'>
                                  <span>
                                    <label className='invisible'>search</label>
                                    <button className="bg-[#FBB00C] hover:bg-[#FBB00C] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#FBB00C] rounded flex justify-end" onClick={() => this.classReferenceSubmit()}> Search </button></span>
                                  <span>
                                    <label className='invisible'>search</label>
                                    <button className="bg-[#25BF63] hover:bg-[#25BF63] px-7 py-1 pt-1 ml-2 text-white font-bold  border border-[#25BF63] rounded flex justify-end" onClick={() => this.resetDataRes()}> Reset </button></span>
                                </div>
                              </th>

                            </tr>
                          </thead>

                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="bg-[#FBB00C] rounded-t">
                              <tr className=" ">
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-start text-xs font-semibold text-[#000] capitalize tracking-wider">Name & Mobile Number </th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Address</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider"> City</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Documents</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Payment History</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Track</th>
                                <th scope="col" className="whitespace-nowrap px-6 py-3 text-center text-xs font-semibold text-[#000] capitalize tracking-wider">Actions</th>
                              </tr>
                            </thead>

                            {/* Table Row Section */}
                            <tbody>
                              {
                                getUserList && getUserList.length > 0 ?
                                  getUserList.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className=" bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 whitespace-nowrap  text-sm text-gray-600 flex items-center space-x-2">
                                        <div className='w-12 h-12'>
                                          <img
                                            class="w-12 h-12 rounded-full"
                                            src={element.imageLinkUrl}
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <p>{element.fullName}</p>
                                          <p> {element.mobNo}</p>
                                          <p>{element.email}</p>
                                          <div className='flex items-center justify-end' onClick={() => this.approveOpenStatusHandel1(element, 1)}><span className='font-bold'
                                          >More</span>  <span className='text-[#FBB00C] pl-1'><FaArrowCircleRight size={16} /> </span></div>
                                        </div>

                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        <p className='text-center flex justify-center items-center'>{element.address}</p>
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        <p className='text-center flex justify-center items-center'>{element && element.city && element.city.name ? element.city.name : "NA"}</p>
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        <p className='text-center flex justify-center items-center cursor-pointer' onClick={() => this.KycResponseHandel(element)}><MdOutlineRemoveRedEye size={24} /></p>
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        <p className='text-center flex justify-center items-center cursor-pointer' onClick={() => this.moreDetailsPage(element)}><MdOutlineRemoveRedEye size={24} /></p>
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-2">
                                        <p className='text-center flex justify-center items-center cursor-pointer bg-black text-white rounded-full h-5 w-5 mx-auto'><MdLocationPin /></p>
                                      </td>
                                      <td className="px-6 py-3 whitespace-nowrap text-center text-sm text-gray-600 space-y-1">


                                        <div onClick={() => this.disableUser(element)}>
                                          <button type="button" className='bg-green-600 px-2 py-1 w-20 text-white font-bold rounded-full'>Unblock</button>
                                        </div>

                                      </td>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 whitespace-nowrap font-medium text-sm text-gray-600">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {this.state.approveStatus1 ?
                      <div className="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
                        <div className="border border-yellow-500 modal-container bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/3 mx-auto rounded-[40px] shadow-lg z-50 overflow-y-auto">
                          <div className="modal-content ">
                            <div className="flex justify-between items-center modal-close cursor-pointer z-50 py-2 px-6 bg-[#FBB00C]">
                              <p className='font-bold text-md'>More</p>
                              <div className='bg-white rounded-full p-1'>
                                <svg
                                  onClick={() => this.approveClodeStatusHandel1()}
                                  className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                  </path>
                                </svg>
                              </div>
                            </div>
                            <div className='py-4 px-6 h-[320px] overflow-y-auto divide-y-2'>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Email Id :</p>
                                <p>{this.state.elementValue && this.state.elementValue.email ? this.state.elementValue.email : '-'}</p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>DOB :</p>
                                <p>{this.state.elementValue && this.state.elementValue.dob ? this.state.elementValue.dob : '-'}</p>
                              </div>
                              <div className='flex justify-between items-start w-full'>
                                <p className='text-lg font-bold'>Pin Code :</p>
                                <ul className="text-right">
                                  {this.state.elementValue &&
                                    this.state.elementValue.pinCode &&
                                    this.state.elementValue.pinCode &&
                                    this.state.elementValue.pinCode.length > 0 ? (
                                    this.state.elementValue.pinCode.map((ele) => (
                                      <li key={ele.id}>{ele && ele.pinNo ? ele.pinNo : '-'}</li>
                                    ))
                                  ) : (
                                    null
                                  )}
                                </ul>

                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Gender :</p>
                                <p>{this.state.elementValue && this.state.elementValue.gender ? this.state.elementValue.gender : '-'}</p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>School :</p>
                                <p> {this.state.elementValue && this.state.elementValue.tutorInfoId.schoolName ? this.state.elementValue.tutorInfoId.schoolName : '-'}</p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Graduation :</p>
                                <p>{this.state.elementValue && this.state.elementValue.tutorInfoId.ugClgName ? this.state.elementValue.tutorInfoId.ugClgName : '-'} / {this.state.elementValue && this.state.elementValue.tutorInfoId.ugCourse ? this.state.elementValue.tutorInfoId.ugCourse : '-'}</p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <p className='text-lg font-bold'>Post Graduation :</p>
                                <p>{this.state.elementValue && this.state.elementValue.tutorInfoId.pgClgName ? this.state.elementValue.tutorInfoId.pgClgName : '-'} / {this.state.elementValue && this.state.elementValue.tutorInfoId.pgCourse ? this.state.elementValue.tutorInfoId.pgCourse : '-'}</p>
                              </div>
                              <div className='flex justify-between items-center w-full'>
                                <div className="w-full">
                                  <p className='text-lg font-bold'>Teaching Mode :</p>
                                </div>
                                <div className="w-full">
                                  <ul className="text-right">
                                    {this.state.elementValue &&
                                      this.state.elementValue.tutorInfoId &&
                                      this.state.elementValue.tutorInfoId.teachingMode &&
                                      this.state.elementValue.tutorInfoId.teachingMode.length > 0 ? (
                                      this.state.elementValue.tutorInfoId.teachingMode.map((ele) => (
                                        <li key={ele.id}>{ele}</li>
                                      ))
                                    ) : (
                                      null
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div className='flex justify-between items-start w-full'>
                                <div className="w-full">
                                  <p className='text-lg font-bold'>Class Teaches :</p>
                                </div>
                                <div className="w-full">
                                  <ul className="text-right">
                                    {this.state.elementValue &&
                                      this.state.elementValue.tutorInfoId &&
                                      this.state.elementValue.tutorInfoId.teachingClasses &&
                                      this.state.elementValue.tutorInfoId.teachingClasses.length > 0 ? (
                                      this.state.elementValue.tutorInfoId.teachingClasses.map((ele) => (
                                        <li key={ele.id}>{ele && ele.name ? ele.name : '-'}</li>
                                      ))
                                    ) : (
                                      null
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div className='flex justify-between items-start w-full'>
                                <div className="w-full">
                                  <p className='text-lg font-bold'>Subject Teaches :</p>
                                </div>
                                <div className="w-full">
                                  <ul className="text-right">
                                    {this.state.elementValue &&
                                      this.state.elementValue.tutorInfoId &&
                                      this.state.elementValue.tutorInfoId.teachingCourse &&
                                      this.state.elementValue.tutorInfoId.teachingCourse.length > 0 ? (
                                      this.state.elementValue.tutorInfoId.teachingCourse.map((ele) => (
                                        <li key={ele.id}>{ele && ele.name ? ele.name : '-'}</li>
                                      ))
                                    ) : (
                                      null
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div className='flex justify-between items-start w-full'>
                                <div className="w-full">
                                  <p className='text-lg font-bold'>Teaching Location PinCode :</p>
                                </div>
                                <div className="w-full">
                                  <ul className="text-right">
                                    {this.state.elementValue &&
                                      this.state.elementValue.tutorInfoId &&
                                      this.state.elementValue.tutorInfoId.teachPincode &&
                                      this.state.elementValue.tutorInfoId.teachPincode.length > 0 ? (
                                      this.state.elementValue.tutorInfoId.teachPincode.map((ele) => (
                                        <li key={ele.id}>{ele && ele.pinNo ? ele.pinNo : '-'}</li>
                                      ))
                                    ) : (
                                      null
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      :
                      null
                    }
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main >
        </div >

        <DialogExample />
      </>

    );
  }
}
function mapStateToProps(state) {
  const { users, city } = state;
  return {
    users,
    city
  };
}
export default connect(mapStateToProps)(UserActive);
