import React from "react";
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { authHeader, history } from '../../_helpers';
import { CONST } from '../../_config';
import axios from 'axios';
import { FaChalkboardTeacher } from "react-icons/fa"
import { RiAdminFill } from "react-icons/ri"
import { useState } from 'react';
import { MdHome, MdSubject, MdOutlineAssignment, MdOutlineAppRegistration, MdOutlineLocationCity, MdOutlineSupportAgent } from "react-icons/md";
import { FaRegAddressCard, FaChevronRight, FaBookReader, FaRupeeSign, FaEdit, FaClipboardCheck, FaChartArea, FaRegNewspaper, FaCalendarAlt } from "react-icons/fa";


function Sidebar({ location, history }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [totalTxList, setTotalTxList] = React.useState(0);
  // console.log('totalTxList_____________', totalTxList);

  useEffect(() => {

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: CONST.BACKEND_URL + `/getAdminProfile`,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
      },
    };
    axios.request(config)
      .then((response) => {
        setTotalTxList(response.data.data)
      })
      .catch((error) => {
        // dispatch(alertActions.error(error))
        console.log('ERROR', error);
      });

  }, []);



  const onClickMenu = (url) => {
    history.push(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen)
    }, 150);

  }

  // const logoutSubmit = () => {

  //   confirmAlert({
  //     title: 'Are you sure want to logout.',
  //     buttons: [
  //       {
  //         label: 'Logout',
  //         onClick: () => logout('/login')
  //       },
  //       {
  //         label: 'Cancel'
  //       }
  //     ]
  //   });
  // }
  // console.log('totalTxList', totalTxList);

  // console.log("location  ", location.pathname);

  const [modalOpen, setModalOpen] = React.useState(0);

  return (
    <>
      <button className={` ${navbarOpen === true ? '' : ''} absolute top-0 left-0 px-5 items-center justify-center border-r border-gray-200 text-black focus:outline-none z-50 h-16`} onClick={() => setNavbarOpen(!navbarOpen)}>
        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7">
          </path>
        </svg>
      </button>

      {/* Navbar transparent Background & close Button */}
      {/* {navbarOpen ?
        <div className={` ${navbarOpen === false ? '' : ''}  lg:flex-shrink-0 lg:static inset-0 z-50 fixed bg-black bg-opacity-50`} onClick={() => setNavbarOpen(!navbarOpen)}>
          <div className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600 absolute left-64 lg:left-80 top-0 z-40   " aria-label="Close sidebar">
            <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12">
              </path>
            </svg>
          </div>
        </div>
        : null} */}

      {/* Main Navbar */}
      <div className={` ${navbarOpen === false ? '' : '-ml-64'} fixed left-0 inset-y-0 lg:static  w-64 z-50 flex-col`} >
        {/* <div className= "fixed left-0 inset-y-0 lg:static lg:flex  w-64 z-50 flex-col"> */}

        <div className="flex flex-col w-64 relative flex-1 h-full max-h-full">
          <div className="cursor-pointer flex items-center h-16 flex-shrink-0 px-4 bg-black border-b border-gray-200 w-full z-50">
            <span className=" rounded-full relative flex justify-center items-center mr-4 " onClick={() => onClickMenu('dashboard')}>
              <img className="w-full" src="dist/img/bookmy-tutor.webp" alt="logo" />
            </span>
            <span onClick={() => setNavbarOpen(!navbarOpen)} className="text-white font-bold text-xs capitalize">Hide</span>
          </div>


          <nav className="overflow-y-auto bg-[#000] flex flex-col flex-wrap items-center justify-between relative w-64 z-10 px-3  h-full overflow-auto scroll">
            <div className=" flex-col px-0 flex flex-wrap items-center justify-between w-full mx-auto flex-1">
              <div className={"relative z-40 flex-1 w-full space-y-1"}>
                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="flex  items-center mt-1 bg-[#FBB00C] p-2 rounded-full " >
                    <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/dashboard" ? " text-[#fff]" : "text-[#000]")} to="/app/dashboard" >
                      <MdHome size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/dashboard") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "} Dashboard
                    </Link>
                  </li>
                </ul>

                {totalTxList && totalTxList.adminType && totalTxList.adminType == 'ADMIN' ?
                  <><div className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer">
                    <h6 className="md:min-w-full text-blueGray-500 text-sm capitalize font-bold  no-underline inline-flex items-center" onClick={() => setModalOpen(modalOpen === 1 ? 0 : 1)}><span className="mr-2 bg-white p-1 rounded-full"><RiAdminFill size={20} /></span> Admin Management</h6>
                  </div>
                    {modalOpen ? (
                      <ul className={`md:flex-col md:min-w-full flex flex-col list-none overflow-hidden transition-[max-height] duration-300 ease-in px-2    ${modalOpen === 1 ? "max-h-24" : "max-h-0"
                        } `} >
                        <li className="flex items-center" >
                          <Link className={" flex items-center text-xs capitalize py-3 font-bold " + (location.pathname === "/app/subAdmin" ? " text-[#FBB00C] " : "flex items-center text-blueGray-700 text-[#fff]")} to="/app/subAdmin" >
                            <FaChevronRight size={16} className={" mr-2 text-base " + (window.location.href.indexOf("/app/subAdmin") !== -1 ? "opacity-75" : "text-blueGray-500")} />
                            {" "}Active Sub Admin
                          </Link>
                        </li>
                        <li className="flex items-center" >
                          <Link className={" flex items-center text-xs capitalize py-3 font-bold " + (location.pathname === "/app/SubAdminDisable" ? " text-[#FBB00C]" : "flex items-center text-blueGray-700 text-[#fff]")} to="/app/SubAdminDisable" >
                            <FaChevronRight size={16} className={" mr-2 text-base " + (window.location.href.indexOf("/app/SubAdminDisable") !== -1 ? "opacity-75" : "text-blueGray-500")} />
                            {" "}InActive Sub Admin
                          </Link>
                        </li>
                      </ul>
                    ) : null}
                  </>
                  :
                  null
                }

                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                    <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/kycpending" ? " text-[#fff]" : "text-[#000]")} to="/app/kycpending" >
                      <FaRegAddressCard size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/kycpending") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "} KYC Management
                    </Link>
                  </li>
                </ul>


                {/* Tutor Management */}

                <div className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer">
                  <h6 className="md:min-w-full text-blueGray-500 text-sm capitalize font-bold  no-underline inline-flex items-center" onClick={() => setModalOpen(modalOpen === 2 ? 0 : 2)}><span className="mr-2 bg-white p-1 rounded-full"><FaChalkboardTeacher size={20} /></span> Tutor Management</h6>
                </div>
                <ul className={`md:flex-col md:min-w-full flex flex-col list-none overflow-hidden transition-[max-height] duration-300 ease-in px-2 space-y-1  ${modalOpen === 2 ? "max-h-44" : "max-h-0"} `}>
                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block" + (location.pathname === "/app/activetutor" ? " text-[#FBB00C]" : "text-[#fff]")} to="/app/activetutor" >
                      <FaChevronRight size={16} className={"fas  fa-person-chalkboard mr-2 text-base " + (window.location.href.indexOf("/app/activetutor") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Active Tutor
                    </Link>
                  </li>
                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block" + (location.pathname === "/app/inactivetutor" ? " text-[#FBB00C]" : "text-[#fff]")} to="/app/inactivetutor" >
                      <FaChevronRight size={16} className={"fas fa-bell-slash mr-2 text-base " + (window.location.href.indexOf("/app/inactivetutor") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} InActive Tutor
                    </Link>
                  </li>
                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block" + (location.pathname === "/app/rejecedtutorInterview" ? " text-[#FBB00C]" : "text-[#fff]")} to="/app/rejecedtutorInterview" >
                      <FaChevronRight size={16} className={"fas fa-bell-slash mr-2 text-base " + (window.location.href.indexOf("/app/rejecedtutorInterview") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Rejected Tutor Interview
                    </Link>
                  </li>
                </ul>

                {/* student Management  */}

                <div className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer">
                  <h6 className="md:min-w-full text-blueGray-500 text-sm capitalize font-bold  no-underline inline-flex items-center" onClick={() => setModalOpen(modalOpen === 3 ? 0 : 3)}><span className="mr-2 bg-white p-1 rounded-full"><FaBookReader size={20} /></span> Student Management</h6>
                </div>
                <ul className={`md:flex-col md:min-w-full flex flex-col list-none overflow-hidden transition-[max-height] duration-300 ease-in px-2 space-y-1  ${modalOpen === 3 ? "max-h-44" : "max-h-0"} `}>
                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block" + (location.pathname === "/app/activestudent" ? " text-[#FBB00C]" : "flex items-center text-[#fff]")} to="/app/activestudent" >
                      <FaChevronRight size={16} className={"fas fa-book-open-reader mr-2 text-base " + (window.location.href.indexOf("/app/activestudent") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Active Student
                    </Link>
                  </li>
                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block" + (location.pathname === "/app/inactivestudent" ? " text-[#FBB00C]" : "flex items-center text-[#fff]")} to="/app/inactivestudent" >
                      <FaChevronRight size={16} className={"fas fa-book-open mr-2 text-base " + (window.location.href.indexOf("/app/inactivestudent") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Inactive Student
                    </Link>
                  </li>
                </ul>

                {/* Tuition Management */}
                <div className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer">
                  <h6 className="md:min-w-full text-blueGray-500 text-sm capitalize font-bold  no-underline inline-flex items-center" onClick={() => setModalOpen(modalOpen === 4 ? 0 : 4)}><span className="mr-2 bg-white p-1 rounded-full"><RiAdminFill size={20} /></span> Tuition Management</h6>
                </div>
                <ul className={`md:flex-col md:min-w-full flex flex-col list-none overflow-hidden transition-[max-height] duration-300 ease-in px-2   ${modalOpen === 4 ? "max-h-80" : "max-h-0"} `}>
                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block " + (location.pathname === "/app/pendingdemoclass" ? " text-[#FBB00C] hover:text-lightBlue-600" : "flex items-center text-blueGray-700 hover:text-[#FBB00C]")} to="/app/pendingdemoclass" >
                      <FaChevronRight size={16} className={"fas fa-user-friends mr-2 text-base " + (window.location.href.indexOf("/app/pendingdemoclass") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Demo Request
                    </Link>
                  </li>
                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block " + (location.pathname === "/app/tutorapplieddemoclass" ? " text-[#FBB00C] hover:text-lightBlue-600" : "flex items-center text-blueGray-700 hover:text-[#FBB00C]")} to="/app/tutorapplieddemoclass" >
                      <FaChevronRight size={16} className={"fas fa-user-friends mr-2 text-base " + (window.location.href.indexOf("/app/tutorapplieddemoclass") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Assign Tutor
                    </Link>
                  </li>

                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block " + (location.pathname === "/app/DemoClassTutorAppliedComplete" ? " text-[#FBB00C] hover:text-lightBlue-600" : "flex items-center text-blueGray-700 hover:text-[#FBB00C]")} to="/app/DemoClassTutorAppliedComplete" >
                      <FaChevronRight size={16} className={"fas fa-person-chalkboard mr-2 text-base " + (window.location.href.indexOf("/app/DemoClassTutorAppliedComplete") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Completed Demo's
                    </Link>
                  </li>

                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block " + (location.pathname === "/app/accepteddemoclass" ? " text-[#FBB00C] hover:text-lightBlue-600" : "flex items-center text-blueGray-700 hover:text-[#FBB00C]")} to="/app/accepteddemoclass" >
                      <FaChevronRight size={16} className={"fas fa-user-friends mr-2 text-base " + (window.location.href.indexOf("/app/accepteddemoclass") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Pending For Registration
                    </Link>
                  </li>


                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block " + (location.pathname === "/app/tuition" ? " text-[#FBB00C] hover:text-lightBlue-600" : "flex items-center text-blueGray-700 hover:text-[#FBB00C]")} to="/app/tuition" >
                      <FaChevronRight size={16} className={"fas fa-person-chalkboard mr-2 text-base " + (window.location.href.indexOf("/app/tuition") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Ongoing Tuitions
                    </Link>
                  </li>


                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block " + (location.pathname === "/app/tutorChangeRequest" ? " text-[#FBB00C] hover:text-lightBlue-600" : "flex items-center text-blueGray-700 hover:text-[#FBB00C]")} to="/app/tutorChangeRequest" >
                      <FaChevronRight size={16} className={"fas fa-person-chalkboard mr-2 text-base " + (window.location.href.indexOf("/app/tutorChangeRequest") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Change Tutor Requests
                    </Link>
                  </li>

                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block " + (location.pathname === "/app/completeTution" ? " text-[#FBB00C] hover:text-lightBlue-600" : "flex items-center text-blueGray-700 hover:text-[#FBB00C]")} to="/app/completeTution" >
                      <FaChevronRight size={16} className={"fas fa-person-chalkboard mr-2 text-base " + (window.location.href.indexOf("/app/completeTution") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Completed Tuitions
                    </Link>
                  </li>

                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block " + (location.pathname === "/app/rejecteddemoclass" ? " text-[#FBB00C] hover:text-lightBlue-600" : "flex items-center text-blueGray-700 hover:text-[#FBB00C]")} to="/app/rejecteddemoclass" >
                      <FaChevronRight size={16} className={"fas fa-user-friends mr-2 text-base " + (window.location.href.indexOf("/app/rejecteddemoclass") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Rejected Demo Requests
                    </Link>
                  </li>
                </ul>

                {/* Assigned Classes Management */}
                <div className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer">
                  <h6 className="md:min-w-full text-blueGray-500 text-sm capitalize font-bold  no-underline inline-flex items-center" onClick={() => setModalOpen(modalOpen === 5 ? 0 : 5)}><span className="mr-2 bg-white p-1 rounded-full"><FaRupeeSign size={20} /></span> Payment Management</h6>
                </div>
                <ul className={`md:flex-col md:min-w-full flex flex-col list-none overflow-hidden transition-[max-height] duration-300 ease-in px-2 space-y-1  ${modalOpen === 5 ? "max-h-44" : "max-h-0"} `}>
                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block" + (location.pathname === "/app/paymentManagementstudent" ? " text-[#FBB00C]" : "flex items-center text-[#fff]")} to="/app/paymentManagementstudent" >
                      <FaChevronRight size={16} className={"fas fa-book-open-reader mr-2 text-base " + (window.location.href.indexOf("/app/paymentManagementstudent") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "}  Student Payment
                    </Link>
                  </li>
                  <li className="items-center text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block" + (location.pathname === "/app/paymentManagement" ? " text-[#FBB00C]" : "flex items-center text-[#fff]")} to="/app/paymentManagement" >
                      <FaChevronRight size={16} className={"fas fa-book-open mr-2 text-base " + (window.location.href.indexOf("/app/paymentManagement") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} Tutor Payment
                    </Link>
                  </li>
                </ul>


                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                    <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/leadManagement" ? " text-[#fff]" : "text-[#000]")} to="/app/leadManagement" >
                      <RiAdminFill size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/leadManagement") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "}  Referral Management
                    </Link>
                  </li>
                </ul>
                {totalTxList && totalTxList.adminType && totalTxList.adminType == 'ADMIN' ? <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                    <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/classManagement" ? " text-[#fff]" : "text-[#000]")} to="/app/classManagement" >
                      <FaEdit size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/classManagement") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "}  Class Management
                    </Link>
                  </li>
                </ul> : null}
                {totalTxList && totalTxList.adminType && totalTxList.adminType == 'ADMIN' ? <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                    <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/board" ? " text-[#fff]" : "text-[#000]")} to="/app/board" >
                      <FaClipboardCheck size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/board") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "}  Board Management
                    </Link>
                  </li>
                </ul>
                  : null}
                {totalTxList && totalTxList.adminType && totalTxList.adminType == 'ADMIN' ? <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                    <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/teachcourse" ? " text-[#fff]" : "text-[#000]")} to="/app/teachcourse" >
                      <MdSubject size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/teachcourse") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "}  Subject Management
                    </Link>
                  </li>
                </ul>
                  : null}
                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                    <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/assignment" ? " text-[#fff]" : "text-[#000]")} to="/app/assignment" >
                      <MdOutlineAssignment size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/assignment") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "}  Assignment Management
                    </Link>
                  </li>
                </ul>



                {/* Package Management */}
                {totalTxList && totalTxList.adminType && totalTxList.adminType == 'ADMIN' ?
                  <>

                    <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                      <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                        <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/packages" ? " text-[#fff]" : "text-[#000]")} to="/app/packages" >
                          <MdOutlineAppRegistration size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/packages") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "}  Registration Package
                        </Link>
                      </li>
                    </ul>
                  </>
                  :
                  null}


                {totalTxList && totalTxList.adminType && totalTxList.adminType == 'ADMIN' ?
                  <>
                    <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                      <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                        <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/banner" ? " text-[#fff]" : "text-[#000]")} to="/app/banner" >
                          <FaChartArea size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/banner") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "}  Banner Management
                        </Link>
                      </li>
                    </ul>
                  </>
                  :
                  null}

                {/* Pincode */}
                {totalTxList && totalTxList.adminType && totalTxList.adminType == 'ADMIN' ? <div className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer">
                  <h6 className="md:min-w-full text-blueGray-500 text-sm capitalize font-bold  no-underline inline-flex items-center" onClick={() => setModalOpen(modalOpen === 6 ? 0 : 6)}><span className="mr-2 bg-white p-1 rounded-full"><MdOutlineLocationCity size={20} /></span> Add / Remove City & Pincode</h6>
                </div>
                  : null}
                {totalTxList && totalTxList.adminType && totalTxList.adminType == 'ADMIN' ? <ul className={`md:flex-col md:min-w-full flex flex-col list-none overflow-hidden transition-[max-height] duration-300 ease-in px-2   ${modalOpen === 6 ? "max-h-28" : "max-h-0"} `}>
                  <li className="inline-flex text-white" >
                    <Link className={"text-xs capitalize py-3 font-semibold block " + (location.pathname === "/app/pincode" ? "text-[#FBB00C] hover:text-lightBlue-600" : "flex items-center text-blueGray-700 hover:text-[#FBB00C]")} to="/app/pincode" >
                      <FaChevronRight size={16} className={"fas fa-file mr-2 text-base " + (window.location.href.indexOf("/app/pincode") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "}Pincode
                    </Link>
                  </li>
                  <li className="inline-flex text-white" >
                    <Link className={"text-xs capitalize py-2 font-bold block " + (location.pathname === "/app/city" ? "text-[#FBB00C] hover:text-lightBlue-600" : "flex items-center text-blueGray-700 hover:text-[#FBB00C]")} to="/app/city" >
                      <FaChevronRight size={16} className={"fas fa-city  mr-2 text-base " + (window.location.href.indexOf("/app/city") !== -1 ? "opacity-75" : "text-blueGray-500")} />{" "} City
                    </Link>
                  </li>

                </ul>
                  : null}


                {totalTxList && totalTxList.adminType && totalTxList.adminType == 'ADMIN' ? <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                    <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/terms&Condition" ? " text-[#fff]" : "text-[#000]")} to="/app/terms&Condition" >
                      <FaRegNewspaper size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/terms&Condition") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "}  Add / Remove T&C's
                    </Link>
                  </li>
                </ul>
                  : null}



                {totalTxList && totalTxList.adminType && totalTxList.adminType == 'ADMIN' ? <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                    <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/holidays" ? " text-[#fff]" : "text-[#000]")} to="/app/holidays" >
                      <FaCalendarAlt size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/holidays") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "}  Holiday Management
                    </Link>
                  </li>
                </ul>
                  : null}
                {totalTxList && totalTxList.adminType && totalTxList.adminType == 'ADMIN' ? <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                    <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/Notification" ? " text-[#fff]" : "text-[#000]")} to="/app/Notification" >
                      <MdOutlineSupportAgent size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/Notification") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "}  Notification
                    </Link>
                  </li>
                </ul> :
                  null}
                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="bg-[#FBB00C] p-2 mt-1 rounded-full flex items-center cursor-pointer" >
                    <Link className={"text-sm capitalize  font-bold block " + (location.pathname === "/app/support" ? " text-[#fff]" : "text-[#000]")} to="/app/support" >
                      <MdOutlineSupportAgent size={28} className={"fas fa-tv mr-2 text-base " + (window.location.href.indexOf("/app/support") !== -1 ? "bg-[#000] p-1 rounded-full" : " bg-white p-1 rounded-full")} />{" "}  Support Management
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );

}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting, user } = overview ? overview : {};
  // console.log("overviewoverviewoverviewoverview", overview);
  return {
    users,
    setting,
    user
  };
}


export default (connect(mapStateToProps)(withRouter(Sidebar)));

// export default withRouter(Sidebar);