export const indexConstants = {

    GETALL_INDEX_REQUEST: 'GETALL_INDEX_REQUEST',
    GETALL_INDEX_SUCCESS: 'GETALL_INDEX_SUCCESS',
    GETALL_INDEX_FAILURE: 'GETALL_INDEX_FAILURE',

    GET_LIST_INDEX_REQUEST: 'GET_LIST_INDEX_REQUEST',
    GET_LIST_INDEX_SUCCESS: 'GET_LIST_INDEX_SUCCESS',
    GET_LIST_INDEX_FAILURE: 'GET_LIST_INDEX_FAILURE',

    ADD_INDEX_REQUEST: 'ADD_INDEX_REQUEST',
    ADD_INDEX_SUCCESS: 'ADD_INDEX_SUCCESS',
    ADD_INDEX_FAILURE: 'ADD_INDEX_FAILURE',

    DELETE_INDEX_REQUEST: 'DELETE_INDEX_REQUEST',
    DELETE_INDEX_SUCCESS: 'DELETE_INDEX_SUCCESS',
    DELETE_INDEX_FAILURE: 'DELETE_INDEX_FAILURE',

    UPDATE_INDEX_REQUEST: 'UPDATE_INDEX_REQUEST',
    UPDATE_INDEX_SUCCESS: 'UPDATE_INDEX_SUCCESS',
    UPDATE_INDEX_FAILURE: 'UPDATE_INDEX_FAILURE',

    DISABLE_INDEX_REQUEST: 'DISABLE_INDEX_REQUEST',
    DISABLE_INDEX_SUCCESS: 'DISABLE_INDEX_SUCCESS',
    DISABLE_INDEX_FAILURE: 'DISABLE_INDEX_FAILURE',

};
